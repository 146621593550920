import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  LIBRARY_CATEGORIES,
  ARTICLE_CATEGORY_ROUTE,
  Url,
} from "../constants/Global";
import { mclOpen } from "../api/Mcl";

const ArticleCategories = () => {
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    mclOpen
      .get(Url.ARTICLE_CATEGORIES.concat("/"))
      .then((res) => {
        setCategories(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="bg-white shadow-lg shadow-black/40 rounded-lg p-8 pb-12 mb-8">
      <h3 className="text-2xl font-bold text-primary-purple mb-8 pb-4 border-solid border-b border-x-0 border-t-0 border-slate-300">
        Categories
      </h3>
      <Link
        to={`${ARTICLE_CATEGORY_ROUTE}`}
        className="text-[1rem] text-primary-purple"
      >
        <span
          className={`cursor-pointer block border-solid border-x-0 border-t-0 border-slate-300 border-b pb-3 mb-3`}
        >
          All
        </span>
      </Link>
      {categories.map((category, index) => (
        <Link
          key={index}
          to={`${ARTICLE_CATEGORY_ROUTE}${category.urlPath}`}
          className="text-[1rem] text-primary-purple"
        >
          <span
            className={`cursor-pointer block border-solid border-x-0 border-t-0 border-slate-300 ${
              index === categories.length - 1 ? "border-b-0" : "border-b"
            } pb-3 mb-3`}
          >
            {category.name}
          </span>
        </Link>
      ))}
    </div>
  );
};

export default ArticleCategories;
