import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useMclAuth from "../../hooks/useMclAuth";
import useAuth from "../../hooks/useAuth";
import { Url } from "../../constants/Global";
import { useNavigate } from "react-router";
import { mclOpen } from "../../api/Mcl";

function GiftSuccessPayment() {
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const { CHECKOUT_SESSION_ID, GIFT_ID } = useParams();

  useEffect(() => {
    setMessage("Order placed! You will receive an email confirmation.");

    console.log(CHECKOUT_SESSION_ID);
    console.log(GIFT_ID);
    mclOpen
      .get(Url.GIFTING.concat(`/successPayment/${CHECKOUT_SESSION_ID}/${GIFT_ID}`))
      .then((res) => {
        console.log("response: ", res.data);
      })
      .catch((error) => {
        console.log(error.message);
      });

    /*setTimeout(function () {
      navigate("/site/login");
    }, 3000);*/
  }, []);

  return (
    <main>
      <p className="w-fill p-8 text-center text-lg">{message}</p>
    </main>
  );
}

export default GiftSuccessPayment;
