import React, { useEffect, useState } from "react";
import { Row, Col, Avatar, Button, Dropdown, Menu, Modal } from "antd";
import {
  PlusCircleFilled,
  MessageFilled,
  EllipsisOutlined,
  UsergroupAddOutlined,
  UsergroupDeleteOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import _ from "lodash";

import { MdOutlineRule } from "react-icons/md";
import CareTeamImage from "../assets/images/careteam/visit-parents.png";
import { v4 as uuidv4 } from "uuid";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const convertAvatarName = (circle) => {
  if (circle.firstName && circle.lastName) {
    return (
      circle.firstName.charAt(0).toUpperCase() +
      circle.lastName.charAt(0).toUpperCase()
    );
  } else if (circle.firstName) {
    return circle.firstName.charAt(0).toUpperCase();
  } else if (circle.lastName) {
    return circle.lastName.charAt(0).toUpperCase();
  } else {
    return "User";
  }
};

const convertTeamName = (circle) => {
  if (circle.firstName) {
    return circle.firstName + "'s Team";
  } else if (circle.lastName) {
    return circle.lastName + "'s Team";
  } else {
    return "User's Team";
  }
};

const convertCareRecipientName = (circle) => {
  if (circle.firstName && circle.lastName) {
    return circle.firstName + " " + circle.lastName;
  } else if (circle.firstName) {
    return circle.firstName;
  } else if (circle.lastName) {
    return circle.lastName;
  } else {
    return "User";
  }
};

const getAvatarBorderColor = (roleInCircle) => {
  switch (roleInCircle) {
    case "primary":
      return "before:border-plan-admin";
    case "secondary":
      return "before:border-backup-admin";
    case "members":
      return "before:border-new-primary-purple";
    case "pendings":
      return "before:border-new-primary-purple/60";
    case "invited":
      return "before:border-invited";
    default:
      return "gray";
  }
};

const countMembers = (circle) => {
  let count = 0;
  if (circle.primary) {
    count += circle.primary.length;
  }
  if (circle.members) {
    count += circle.members.length;
  }
  if (!_.isNil(circle.secondary) && !_.isEmpty(circle.secondary)) {
    count += 1;
  }
  return count;
};

const subTitleText = (member, currentUserId) => {
  let roleText = "";
  if (member.roleInCircle === "primary") {
    roleText = "Plan Admin";
  } else if (member.roleInCircle === "secondary") {
    roleText = "Backup Admin";
  } else if (member.roleInCircle === "pendings") {
    roleText = "Pending Approval";
  } else if (member.roleInCircle === "invited") {
    roleText = "Invited";
  } else if (member.roleInCircle === "members") {
    roleText = "Member";
  }

  let text = `(${roleText})`;
  return text;
};

const confirmModal = (title, content, onOk) => {
  Modal.confirm({
    title: title,
    icon: <ExclamationCircleOutlined />,
    content: content,
    okText: "Yes",
    okType: "danger",
    onOk: onOk,
    onCancel() {},
  });
};

const CareTeamHead = ({
  circle,
  inviteOnClick,
  showComponent,
  currentUserRole,
}) => {
  let isAdmin =
    currentUserRole === "primary" || currentUserRole === "secondary";
  return (
    <div className="flex flex-col justify-center items-center h-full gap-1">
      <div className="w-full bg-white h-[48px] rounded-tl-2xl rounded flex items-center p-3 gap-2">
        <Avatar
          className="text-black font-bold"
          style={{ backgroundColor: "#FFD600", verticalAlign: "middle" }}
        >
          {convertAvatarName(circle)}
        </Avatar>
        <span className="text-new-primary-purple font-bold xl:text-lg">
          {convertTeamName(circle)}
        </span>
        {/* <button className="ml-auto min-w-[157px] h-[32px] text-new-primary-purple font-bold">
        <span className="">
        </span>
        <PlusCircleFilled className="mr-2" />
        <span className="text-xs">Add a new member</span>
      </button> */}
        {isAdmin && (
          <Button
            onClick={inviteOnClick}
            className="rounded-lg ml-auto font-bold hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
          >
            <PlusCircleFilled className="text-new-primary-purple" />
            <span className="text-xs">Add new member</span>
          </Button>
        )}
      </div>
      <div className="w-full bg-white min-h-[300px] rounded-bl-2xl rounded flex flex-col justify-end">
        <img className="" src={CareTeamImage}></img>
        <div className="flex items-center min-h-[65px] p-4">
          <div className="flex flex-col">
            <span className="text-dark-purple/60 font-bold text-xs">
              PERSON IN CARE
            </span>
            <span className="text-dark-purple font-bold text-lg">
              {convertCareRecipientName(circle)}
            </span>
          </div>
          <div className="flex gap-2 ml-auto">
            <div className="flex flex-col">
              <span className="text-dark-purple/60 font-bold text-xs">
                TEAM MEMBERS
              </span>
              <span className="text-dark-purple font-bold text-lg">
                {countMembers(circle)}
              </span>
            </div>
            <Button
              style={{ borderRadius: "10px" }}
              icon={<MessageFilled className="text-dark-purple" />}
              size="large"
              onClick={() => {
                showComponent("chat");
              }}
              className="bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const CareTeamCard = ({
  member,
  currentUserId,
  detailOnClick,
  currentUserRole,
  promoteOnClick,
  demoteOnClick,
  removeOnClick,
  acceptOnClick,
  rejectOnClick,
}) => {
  const [menuItems, setMenuItems] = useState([]);

  const menuOnClick = (e) => {
    if (e.key === "demote") {
      confirmModal(
        "Demote to Member",
        <span>
          Are you sure you want to demote{" "}
          <b>{convertCareRecipientName(member)}</b> to normal member?
        </span>,
        () => {
          demoteOnClick(member.displayingCircle, member);
        }
      );
    } else if (e.key === "makeSecondary") {
      confirmModal(
        "Promote to Backup Admin",
        <span>
          Are you sure you want to promote{" "}
          <b>{convertCareRecipientName(member)}</b> to backup admin? The current
          backup admin will be demoted to normal member, and{" "}
          <b>{convertCareRecipientName(member)}</b> will be able to access all
          the care plan information and make changes to the care plan.
        </span>,
        () => {
          promoteOnClick(member.displayingCircle, member);
        }
      );
    } else if (e.key === "remove") {
      confirmModal(
        "Remove from Care Team",
        <span>
          Are you sure you want to remove{" "}
          <b>{convertCareRecipientName(member)}</b> from care team?
        </span>,
        () => {
          removeOnClick(member.displayingCircle, member);
        }
      );
    }
  };

  useEffect(() => {
    let items = [];
    if (currentUserRole === "primary") {
      if (member.roleInCircle === "secondary") {
        items = [
          {
            key: "demote",
            label: <span className="text-red-500">Demote to Member</span>,
            icon: <UsergroupDeleteOutlined className="text-red-500" />,
          },
        ];
      } else if (member.roleInCircle === "members") {
        items = [
          {
            key: "makeSecondary",
            label: (
              <span className="text-backup-admin">Promote to Backup Admin</span>
            ),
            icon: <UsergroupAddOutlined className="text-backup-admin" />,
          },
          {
            key: "remove",
            label: <span className="text-red-500">Remove Member</span>,
            icon: <CloseCircleOutlined className="text-red-500" />,
          },
        ];
      } else if (member.roleInCircle === "invited") {
        items = [
          {
            key: "remove",
            label: <span className="text-red-500">Remove Member</span>,
            icon: <CloseCircleOutlined className="text-red-500" />,
          },
        ];
      }
    }
    setMenuItems(items);
  }, [currentUserRole, member]);

  return (
    <div className="flex flex-col gap-1 h-full min-w-[200px]">
      <div className="w-full bg-white min-h-[300px] rounded-lg flex flex-col flex-1">
        <div className="flex items-center p-2 min-h-[56px]">
          {/* <Button
            icon={<MessageFilled className="text-white/80" />}
            shape="circle"
            size="large"
            className="bg-new-primary-purple hover:bg-new-primary-purple/30 hover:text-new-primary-purple hover:border-new-primary-purple/30 focus:bg-new-primary-purple/30 focus:border-new-primary-purple/30"
          ></Button> */}
          {/* <Button
            shape="round"
            className="text-xs font-bold text-dark-purple bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
          >
            Pending Acceptance
          </Button> */}
          {member._id !== currentUserId && menuItems.length > 0 && (
            <Dropdown
              overlay={<Menu items={menuItems} onClick={menuOnClick} />}
              trigger={["click"]}
            >
              <Button
                icon={<EllipsisOutlined className="text-dark-purple " />}
                shape="circle"
                size="large"
                className="ml-auto bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
              ></Button>
            </Dropdown>
          )}
        </div>
        <div
          className="flex flex-col flex-1 justify-center items-center p-2 hover:cursor-pointer"
          onClick={() => detailOnClick(member)}
        >
          <Avatar
            className={
              "text-black font-bold before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:border-solid before:border-[3px] " +
              getAvatarBorderColor(member.roleInCircle)
            }
            size={58}
            style={{
              backgroundColor: "rgba(159, 191, 255, 0.3)",
            }}
          >
            {convertAvatarName(member)}
          </Avatar>
          <span className="text-dark-purple/80 text-lg font-bold mt-2 text-center">
            {member._id === currentUserId
              ? "Myself"
              : convertCareRecipientName(member)}
          </span>
          <span className="text-sm">{subTitleText(member, currentUserId)}</span>
        </div>
        {member.roleInCircle === "pendings" &&
          currentUserRole === "primary" && (
            <div className="flex items-center p-2">
              <Button
                shape="round"
                type="text"
                style={{
                  backgroundColor: "rgb(21 128 61)",
                }}
                className="text-xs font-bold text-white"
                onClick={() => {
                  confirmModal(
                    "Accept Pending User",
                    <span>
                      This user will join your care team and be granted the
                      permissions set before.
                    </span>,
                    () => {
                      acceptOnClick(member.displayingCircle, member);
                    }
                  );
                }}
              >
                ACCEPT
              </Button>
              <Button
                shape="round"
                type="text"
                style={{
                  backgroundColor: "rgb(185 28 28)",
                }}
                className="ml-auto text-xs font-bold text-white"
                onClick={() => {
                  confirmModal(
                    "Reject Pending User",
                    <span>
                      This user will remove from the pending list. However, you
                      can still invite the user again.
                    </span>,
                    () => {
                      rejectOnClick(member.displayingCircle, member);
                    }
                  );
                }}
              >
                REJECT
              </Button>
            </div>
          )}
      </div>
    </div>
  );
};

const responsive = {
  xlDesktop: {
    breakpoint: { max: 4096, min: 1920 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1200 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1200, min: 640 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

const CareTeamInfo = ({
  circle,
  currentUserId,
  detailOnClick,
  inviteOnClick,
  showComponent,
  currentUserRole,
  promoteOnClick,
  demoteOnClick,
  removeOnClick,
  acceptOnClick,
  rejectOnClick,
}) => {
  const [members, setMembers] = React.useState([]);
  useEffect(() => {
    let newMembers = [...circle.primary];
    if (!_.isEmpty(circle.secondary)) {
      newMembers.push({
        ...circle.secondary,
      });
    }
    newMembers = [
      ...newMembers,
      ...circle.members,
      ...circle.pendings,
      ...circle.invitations,
    ];
    setMembers(newMembers);
  }, [circle]);

  let showSubtitle = true;

  return (
    <div className="flex flex-col">
      <Row
        gutter={[4, 4]}
        className="p-1.5 min-h-[364px] bg-[#3C3066]/5 rounded-[20px]"
      >
        <Col xs={24} lg={10}>
          <CareTeamHead
            circle={circle}
            inviteOnClick={inviteOnClick}
            showComponent={showComponent}
            currentUserRole={currentUserRole}
          />
        </Col>
        <Col xs={24} lg={14}>
          <Carousel
            arrows
            partialVisible
            className="h-full w-full"
            responsive={responsive}
            itemClass="h-full"
            sliderClass="h-full gap-1"
          >
            {members.map((member) => (
              <CareTeamCard
                key={member._id}
                member={member}
                currentUserId={currentUserId}
                detailOnClick={detailOnClick}
                currentUserRole={currentUserRole}
                promoteOnClick={promoteOnClick}
                demoteOnClick={demoteOnClick}
                removeOnClick={removeOnClick}
                acceptOnClick={acceptOnClick}
                rejectOnClick={rejectOnClick}
              />
            ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
};

export default CareTeamInfo;
export {
  convertAvatarName,
  convertTeamName,
  convertCareRecipientName,
  getAvatarBorderColor,
  subTitleText,
};
