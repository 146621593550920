import React from "react";
import { Col, Row, Modal, Button } from "antd";

import "./TermsOfUse.css";
import { PurpleDivider } from "./Various";

const ToUContent = ({ className = "", style }) => {
  const containerBaseClass =
    "lg:container lg:mx-auto p-5 text-center tou-content";
  const containerClass = className
    ? containerBaseClass + " " + className
    : containerBaseClass;

  return (
    <div style={style} className={containerClass}>
      <h2 className="text-h2">Terms of Use</h2>
      <p>
        These Terms of Use (the “<strong>Terms</strong>”) set forth the terms
        and conditions under which individuals may access and use the website
        offered by My CareLinq, Inc. (“<strong>My CareLinq</strong>”, “
        <strong>we</strong>” or “<strong>us</strong>”) that is located at{" "}
        <a href="/">www.mycarelinq.com</a>, all associated websites linked to{" "}
        <a href="/">www.mycarelinq.com</a> by My CareLinq or its affiliates, any
        other website or mobile app that links to these Terms, and the services
        provided via such websites and/or apps (the “<strong>Services</strong>
        ”). Please read these Terms before using the Services. By indicating
        acceptance of these Terms via click through, electronic signature or
        other electronic means offered by My CareLinq or by otherwise using the
        Services, you are entering into a legal contract with My CareLinq, Inc.
        and you hereby accept the terms of and agree to the Terms. You represent
        and warrant that you have the right, capacity, and authority to enter
        into these Terms. If you object to anything in these Terms, do not use
        the Services.
      </p>
      <p>
        Additional terms and conditions may apply to your use of certain
        features of the Services, including the purchase of any paid
        subscription Services (collectively, “<strong>Additional Terms</strong>
        ”). If there is a conflict between these Terms and any Additional Terms,
        the applicable Additional Terms shall control with respect to your use
        of such portions of the Services. In addition, if you are accessing the
        Services on behalf of an organization or entity, and such organization
        or entity has entered into a separate, written agreement with My
        CareLinq, the terms and conditions of such separate agreement will
        control in the event of any conflicts.
      </p>
      <p>
        <strong>
          IMPORTANT NOTICE: THESE TERMS INCLUDE AN{" "}
          <a href="#tou-arbitration-provision">ARBITRATION PROVISION</a>{" "}
          REQUIRING BINDING ARBITRATION OF DISPUTES AND A WAIVER OF CERTAIN
          RIGHTS TO JURY TRIALS AND/OR CLASS ACTIONS. IF YOU DON’T WANT TO BE
          SUBJECT TO ARBITRATION, YOU CAN OPT OUT OF THE ARBITRATION PROVISION
          BY FOLLOWING THE INSTRUCTIONS PROVIDED AT THE END OF THE ARBITRATION
          PROVISION.
        </strong>
      </p>
      <h3 className="text-h3">
        <strong>1. Our Services</strong>
      </h3>
      <p>
        My CareLinq offers various Services, including access to its specialists
        (“<strong>Care Concierges</strong>”), to help its users assess care and
        other needs, create plans, and find and coordinate care and other
        resources for themselves or on behalf of another individual (a “
        <strong>Care Recipient</strong>”). Family members or other unpaid
        caregivers who access and use our Services on behalf of a Care Recipient
        are referred to herein “<strong>Caregivers</strong>.” The Services we
        offer include, among others, assisting Care Recipients and/or Caregivers
        in locating individuals who provide various types of care, legal
        services, and other personal services, creating plans for end of life
        and after life care such as advance directives, identifying resources
        for support and financial assistance, assisting with purchases of
        medical equipment and other products needed by a Care Recipient,
        providing access to content libraries with resources that may be of use
        to Care Recipients and/or Caregivers, and enabling multiple Caregivers
        to link their accounts to enable them to coordinate care for a Care
        Recipient (a “<strong>Care Circle</strong>”).
      </p>
      <p>
        My CareLinq’s Services do not include any medical, diagnostic, treatment
        or clinical services, nor does My CareLinq engage in any conduct that
        requires a professional license. My CareLinq’s Services are also not
        available to any party acting as a “covered entity” (such as a
        healthcare provider or a health plan), or to any party acting as a
        “business associate” of a covered entity, each as defined under 45 CFR §
        160.103 and other applicable law. You represent and warrant that (i) in
        using My CareLinq’s Services, you are not acting as a covered entity, or
        as a business associate of a covered entity, and (ii) you will not use
        any of our Services to create, receive, maintain or transmit any
        sensitive information or data, including “protected health information”
        (as defined in 45 CFR § 160.103 and other applicable law), or use our
        Services in any manner that would make My CareLinq your or any third
        party’s business associate.
      </p>
      <p>
        My CareLinq reserves the right to modify or update the Services from
        time to time, or to suspend or discontinue our Services, or any part of
        our Services, at any time. My CareLinq will not be liable to you or to
        any third party should it exercise such rights. If any modifications or
        updates to the Services are made available to you by My CareLinq, these
        Terms will govern such updates, unless the update is accompanied by a
        separate license in which case the terms of that license will apply.
      </p>
      <h3 className="text-h3">
        <strong>2. Accounts and Care Circles</strong>
      </h3>
      <p>
        Whether you are a Care Recipient or a Coordinator, you will need to
        register and create an account to use certain of our Services (an “
        <strong>Account</strong>”). You must be at least 18 years old and reside
        in the United States to create an Account and to use our Services.
      </p>
      <p>
        Once you have created an Account, you may elect to create a Care Circle
        and invite others to join such Care Circle. My CareLinq offers two
        different roles for Accounts that are linked to a Care Circle – “
        <strong>Owners</strong>” are responsible for the creation of the Care
        Circle, inviting other Caregivers or Care Recipients, as applicable, to
        join the Care Circle, and for any payments for Services associated with
        such Care Circle, and “<strong>Contributors</strong>” are other
        Caregivers or Care Recipients, as applicable, who accept an Owner’s
        invitation to participant in a Care Circle. For Care Circles that you
        create, you will be deemed the Owner of that Care Circle. If you need to
        transfer the Owner role for a Care Circle to another Contributor of such
        Care Circle, please contact My CareLinq at{" "}
        <a href="mailto: support@mycarelinq.com">support@mycarelinq.com</a>.
      </p>
      <p>In addition, you agree that:</p>
      <ul>
        <li>
          You will register your Account in your own legal name, even if you are
          coordinating care for another individual, and will otherwise provide
          accurate, current and complete information in connection with your
          registration for an Account and use of the Services;
        </li>
        <li>
          If you are registering an Account to coordinate care or otherwise use
          the Services on behalf of another individual (i.e. the Care Recipient
          is not you), you have obtained such Care Recipient’s consent to create
          an Account to coordinate care on their behalf, to invite any other
          Caregivers to join the applicable Care Circle, and to share any
          personal data related to that Care Recipient that you or another
          Caregiver uploads or otherwise shares with My CareLinq or otherwise
          via the Services;
        </li>
        <li>
          You are solely responsible for any and all use of your Account (and
          any Care Circle for which you are the Owner) and all activities that
          occur under or in connection with your Account (and any Care Circle
          for which you are the Owner);
        </li>
        <li>
          You will not allow another person to use our Services with your
          Account and will not access our Services under anyone else’s Account;
        </li>
        <li>
          You will not use your Account for any commercial or promotional
          purpose;
        </li>
        <li>
          If My CareLinq disables your Account, you will not create another{" "}
          Account without our consent; and
        </li>
        <li>
          You are responsible for maintaining the confidentiality and security{" "}
          of your Account credentials.
        </li>
      </ul>
      <p>
        My CareLinq is not responsible for any losses arising from the
        unauthorized use of your Account or access to a Care Circle. You will
        notify us immediately if you find out that your Account is being used
        without your permission. You are solely responsible for any decisions to
        create or join a Care Circle, to invite other users to a Care Circle
        that you have created, and to otherwise share any information or
        communicate with any other users of the Services.
      </p>
      <p>
        My CareLinq reserves the right, but is not obligated, to utilize third
        party service providers to verify on an ongoing basis that Account data
        submitted by you is accurate. You agree that My CareLinq may conduct any
        such verification and take such action in response as it deems
        appropriate in its sole discretion, including without limitation
        suspending and/or terminating your access to the Services.
      </p>
      <h3 className="text-h3">
        <strong>3. Privacy</strong>
      </h3>
      <p>
        Your privacy is important to us. Our Privacy Policy (available at{" "}
        <a href="/privacy-policy">www.mycarelinq.com/privacy-policy</a>)
        describes how we collect, use, and disclose information about you.
        Please read our Privacy Policy carefully so you understand how we use
        and share information we collect about you.
      </p>
      <h3 className="text-h3">4. Your Use of the Services</h3>
      <p>
        You may be able to interact with others through our Services, including
        by viewing content created by others or communicating with others. You
        are solely responsible for your interactions with other people through
        our Services. My CareLinq may, but has no obligation to, monitor your
        (or another user’s) use of the Services to ensure that you are following
        these acceptable use requirements.
      </p>
      <p>You agree that you will not, nor enable or permit others to:</p>
      <ul>
        <li>
          Use our Services in a way that negatively affects others or our
          ability to provide the Services;
        </li>
        <li>
          Use our Services to provide inaccurate information or for any illegal
          or unauthorized purpose;
        </li>
        <li>
          Use any technology or other means not authorized by us to access our
          Services or to extract data;
        </li>
        <li>
          Attempt to gain unauthorized access to any Services, including by
          trying to circumvent any restrictions;
        </li>
        <li>
          Attempt to decipher, decompile, reverse engineer, disassemble,{" "}
          reproduce, modify, copy, distribute, publicly perform, publicly
          display or create derivative works of the Services or the source code
          of the software used to provide the Services (except as and only to
          the extent permitted by applicable law);
        </li>
        <li>
          Use our Services in excess of any applicable technical or capacity
          limitations;
        </li>
        <li>
          Use our Services to create a competitive product or service, or for
          benchmarking or vulnerability testing purposes, except as authorized
          by My CareLinq in writing;
        </li>
        <li>
          Share data regarding another individual using the Services without
          their consent; or
        </li>
        <li>
          Infringe upon or violate the rights of My CareLinq, other people in{" "}
          our community, or any third party.
        </li>
      </ul>
      <p>
        By using our Services, you understand that My CareLinq does not endorse
        or actively review any content posted or sent by others within our
        Services, including content describing potential care services or
        caregivers. My CareLinq will not be responsible for any liability
        incurred as the result of your interactions with others, your use of
        content provided by other people, or your decision to share your
        information with others.{" "}
        <strong>
          Your use of any content provided by other people, any interactions
          with others, and any decision to share your information with others is
          your responsibility and at your own risk
        </strong>
        .
      </p>
      <p>
        In addition, My CareLinq does not employ any caregivers or other service
        providers that it may recommend, and is not responsible for the conduct,
        whether online or offline, of any caregiver, service provider, or other
        user of the Services. My CareLinq does not make any representations
        about the suitability, reliability, timeliness, or accuracy of the
        information or services provided by caregivers, service providers or
        other users of the Services, or their integrity, responsibility or
        suitability to provide care or other services.
        <strong>
          You are solely responsible for, and hereby agree to assume all risks
          associated with, your decision(s) to obtain any care or services, even
          if recommended by My CareLinq, including, without limitation, the risk
          of illness, injury or death. You further agree that you are solely
          responsible for interviewing, vetting, performing any background and
          reference checks you deem appropriate on, verifying information
          provided by, and selecting appropriate caregivers, service providers,
          or other resources for yourself or on behalf of a Care Recipient. Any
          disputes or other issues between you or a Care Recipient on whose
          behalf you are acting and any caregivers or service providers must be
          resolved directly with such caregiver or service provider – My
          CareLinq will not be responsible for resolving any such issues
        </strong>
        .
      </p>
      <p>
        YOU HEREBY REPRESENT, UNDERSTAND, AND EXPRESSLY AGREE TO RELEASE AND
        HOLD MY CARELINQ AND MY CARELINQ’S OFFICERS, DIRECTORS, SHAREHOLDERS,
        EMPLOYEES, PARENTS, SUBSIDIARIES, OTHER AFFILIATES, SUCCESSORS,
        ASSIGNEES, AGENTS, AND REPRESENTATIVES (“<strong>AFFILIATES</strong>”)
        HARMLESS FROM ANY CLAIM OR CONTROVERSY THAT MAY ARISE OUT OF THE ACTIONS
        OR INACTIONS OF, ANY INFORMATION PROVIDED BY, OR THE RELATIONSHIP
        BETWEEN, YOU AND ANY CARE RECIPIENT OR OTHER USER(S) OF OUR SERVICES, AS
        WELL AS ANY CAREGIVER OR OTHER SERVICE PROVIDER. WITHOUT LIMITING ANY
        OTHER PROVISION OF THESE TERMS, MY CARELINQ AND ITS AFFILIATES EXPRESSLY
        DISCLAIM ANY LIABILITY WHATSOEVER FOR ANY DAMAGE, SUIT, OR CLAIM ARISING
        FROM THE FOREGOING.
      </p>
      <h3 className="text-h3">
        <strong>5. Your Content</strong>
      </h3>
      <p>
        Our Services may include features and areas where you can create,
        upload, store, and/or share data, notes, or other content with us or
        others in a Care Circle (“<strong>Your Content</strong>”). My CareLinq
        does not have any ownership rights in Your Content, but you hereby grant
        My CareLinq a worldwide, irrevocable (except as set forth below),
        non-exclusive, transferable, royalty-free and fully sublicensable right
        and license during the periods in which you use the Services to use,
        copy, display, store, adapt, and distribute Your Content so that My
        CareLinq can operate, deliver, and improve our Services. The license
        granted to My CareLinq to display or distribute Your Content is
        revocable by you if and when you choose to independently remove or
        delete any of Your Content uploaded to features of our Services or stop
        sharing Your Content with certain users (such as if you remove a
        Caregiver or Care Recipient from a Care Circle for which you are the
        Owner).
      </p>
      <p>
        You are solely responsible for Your Content. My CareLinq has no
        obligation to review, approve, monitor, endorse, reject, refuse to post,
        screen, edit, move or remove any of Your Content or similar content
        created by other members of our community from our Services, but may
        choose to do so at any time, including if Your Content violates these
        Terms. In addition, My CareLinq may also decide to suspend or remove
        your Account.
      </p>
      <p>
        You represent and warrant that you either control or own Your Content or
        you have obtained all rights necessary to share Your Content in
        compliance with these Terms, and that Your Content, and our use of Your
        Content as permitted under these Terms, will not infringe,
        misappropriate or violate any third party’s intellectual property
        rights, or rights of publicity or privacy, or result in the violation of
        any applicable laws, rules, or regulations.
      </p>
      <p>
        Notwithstanding the foregoing, you acknowledge and agree that My
        CareLinq shall have the right to collect and analyze data related to
        your use of and the performance of the Services (“
        <strong>Usage Data</strong>”) and that My CareLinq shall own all such
        Usage Data, provided that My CareLinq may only publish Usage Data in an
        aggregated or other de-identified form.
      </p>
      <h3 className="text-h3">
        <strong>6. Third-Party Materials and Web Sites</strong>
      </h3>
      <p>
        We may make available through our Services certain additional services,
        features, or content provided by third parties (“
        <strong>Third-Party Materials</strong>”). Your use of Third-Party
        Materials made available through the Services may be subject to
        additional terms and conditions of the third party. My CareLinq has no
        responsibility or liability with respect to your access to, or use of,
        the Third-Party Materials.
      </p>
      <p>
        Our Services may also contain links or provide access to other web sites
        or other online services operated by third parties. Those third-party
        services are not under our control, and we are not responsible for the
        content on any third-party service or any link contained in a
        third-party service. We provide these links only as a convenience and we
        don’t have any obligation to review, approve, monitor, endorse, move,
        remove, warrant, or make any representations with respect to third-party
        services.
      </p>
      <h3 className="text-h3">
        <strong>7. Payments</strong>
      </h3>
      <p>
        If you make any purchases with My CareLinq, such as subscribing to
        receive certain paid Services, you authorize us (or our third-party
        payment processor) to charge your payment method for the total amount of
        your purchase (including any applicable Taxes (as defined below) and
        other charges). We may accept various forms of payment, including credit
        and debit cards. Unless otherwise indicated, the purchase price will be
        billed in full at the time you make your purchase. When you make sure a
        purchase, you represent and warrant that you are authorized to use the
        payment method you use. By providing your payment information, you
        authorize us (or our third-party payment processor) to not only process
        your payment but also store your payment and related information. In the
        event the payment method you designate cannot be verified, is invalid,
        or is not otherwise acceptable, we may suspend or cancel your order. You
        are responsible for resolving any problems we encounter in order to
        proceed with your order.
      </p>
      <p>
        If your purchase is subject to any type of use or sales tax, duty or
        other governmental tax or fee (“<strong>Taxes</strong>”), then we may
        charge you for those Taxes. You are responsible for any Taxes due with
        respect to your purchase.
      </p>
      <h3 className="text-h3">
        <strong>8. Pre-Release Materials and Confidentiality</strong>
      </h3>
      <p>
        You may have the opportunity to receive certain pre-release My CareLinq
        features, functionality, services or other materials, at My CareLinq’s
        option. Your use of any pre-release materials are subject to the
        confidentiality obligations below and may be conditioned on your
        acceptance of Additional Terms that we provide you. In the event of any
        conflict between such Additional Terms and these Terms, the Additional
        Terms will govern your use of the pre-release materials.
      </p>
      <p>
        ou acknowledge that you may receive non-public information relating to
        My CareLinq, its business, products, services, and related intellectual
        property (collectively, “<strong>Confidential Information</strong>”) in
        connection with your use of any pre-release materials. You agree to keep
        the Confidential Information confidential, not to publish or disclose
        any Confidential Information to a third party, and only use such
        information to the extent necessary to use the Services (including any
        such pre-release materials you are provided, under these Terms. You will
        use at least a reasonable standard of care in maintaining the
        confidentiality of the Confidential Information. Your obligations with
        respect to Confidential Information will continue even after you have
        stopped using the Services and/or pre-release materials. You agree to
        destroy or return any Confidential Information at My CareLinq’s request.
        You also acknowledge that if you breach your confidentiality
        obligations, it will cause immediate and irreparable injury to My
        CareLinq and My CareLinq will have the right to seek and obtain
        injunctive relief, and to pursue any other remedies available at law or
        in equity, without being required to show any actual damage or
        irreparable harm, prove the inadequacy of its legal remedies, or post
        any bond or other security.
      </p>
      <h3 className="text-h3">
        <strong>9. Feedback</strong>
      </h3>
      <p>
        You may choose to provide My CareLinq with ideas, feedback, or
        suggestions for us about our existing products and services including
        the Services (“<strong>Feedback</strong>”). If you provide My CareLinq
        with Feedback, you hereby grant My CareLinq a non-exclusive, perpetual
        and irrevocable license to use and exploit such Feedback for any
        purpose, including to incorporate such Feedback within its products and
        services, without providing payment or any other consideration to you.
        My CareLinq has no confidentiality or other obligations with respect to
        your Feedback.
      </p>
      <h3 className="text-h3">
        <strong>10. Suspension/Termination</strong>
      </h3>
      <p>
        We may immediately terminate these Terms and consequently your access to
        our Services at any time if you violate these Terms, create possible
        legal liability, or if your use of the Services poses a health or safety
        risk. If we terminate these Terms: (i) your Account will be deactivated
        and Your Content may be inaccessible, deleted, or deactivated, and (ii)
        your rights and obligations under these Terms will terminate (other than
        those provisions that survive as described below).
      </p>
      <p>
        You may also remove your Account at any time, which will terminate these
        Terms (other than those provisions that survive as described below).
      </p>
      <p>
        We will not have any liability for any termination of these Terms for
        any of the reasons described above.
      </p>
      <h3 className="text-h3">
        <strong>11. Intellectual Property</strong>
      </h3>
      <p>
        The software, code, proprietary methods, systems, documentation, and
        other materials used to provide the Services (“
        <strong>My CareLinq Technology</strong>”) is owned by My CareLinq and/or
        our licensors under United States and international intellectual
        property laws. You must abide by all legal notices, information, or
        restrictions contained in, or attached to, any My CareLinq Technology.
        Nothing in these Terms grants you any rights to receive My CareLinq
        Technology or to obtain access to My CareLinq Technology except as
        generally and ordinarily permitted through the Services, in accordance
        with these Terms. Furthermore, nothing in these Terms will be deemed to
        grant, by implication, estoppel or otherwise, a license to My CareLinq
        Technology. The names, logos, and other business identifiers displayed
        on the Services constitute trademarks, tradenames, service marks or
        logos (“<strong>Marks</strong>”) of My CareLinq. You are not authorized
        to use any Marks. Ownership of all such Marks and the goodwill
        associated with those Marks remain with My CareLinq.
      </p>
      <h3 className="text-h3">
        <strong>12. Indemnity</strong>
      </h3>
      <p>
        You will defend, hold harmless, and indemnify My CareLinq, its
        affiliates and its and their directors, officers, employees, agents,
        successors and assigns, from and against any third-party allegation or
        claim based on, or any loss, damage, settlement, cost, expense and any
        other liability (including reasonable attorneys’ fees incurred and/or
        those necessary to successfully establish the right to indemnification)
        (collectively, “<strong>Claims</strong>”) arising from or related to
        your breach of any provision of these Terms.
      </p>
      <h3 className="text-h3">
        <strong>13. Warranty Disclaimer and Limits on Liability</strong>
      </h3>
      <p>
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE ARE PROVIDING OUR
        SERVICES ON AN “AS IS” BASIS FOR USE AT YOUR OWN RISK. WE DISCLAIM ALL
        WARRANTIES WITH RESPECT TO OUR SERVICES (INCLUDING ANY THIRD-PARTY
        MATERIALS OR CONTENT AVAILABLE THROUGH OUR SERVICES) PROVIDED UNDER
        THESE TERMS, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT
        LIMITATION, ANY WARRANTIES OF NON-INFRINGEMENT OF THIRD-PARTY RIGHTS,
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, QUIET ENJOYMENT,
        NON-INTERFERENCE, SYSTEM INTEGRATION, LOSS OR CORRUPTION OF DATA, OR
        ACCURACY. WE DO NOT WARRANT THAT YOUR USE OF OUR SERVICES (INCLUDING ANY
        THIRD-PARTY MATERIALS OR CONTENT AVAILABLE THROUGH OUR SERVICES)
        PROVIDED UNDER THESE TERMS WILL BE UNINTERRUPTED, ERROR-FREE, VIRUS-FREE
        OR SECURE. WE ALSO MAKE NO REPRESENTATIONS OR WARRANTIES AND DISCLAIM
        ALL WARRANTIES OR LIABILITY, WHETHER EXPRESS, IMPLIED, OR STATUTORY, ON
        BEHALF OF ANY OF OUR AFFILIATES, AGENTS, SUPPLIERS, DISTRIBUTORS,
        PARTNERS, OR LICENSORS. THE FEATURES AND FUNCTIONALITY OF OUR SERVICES
        (INCLUDING ANY THIRD-PARTY MATERIALS OR CONTENT AVAILABLE THROUGH OUR
        SERVICES) MAY CHANGE AT ANY TIME AND MY CARELINQ DISCLAIMS ANY
        RESPONSIBILITY FOR SUCH CHANGES OR ANY STATEMENTS MY CARELINQ MAKES OR
        HAS MADE ABOUT THE EXISTENCE OF SUCH FEATURES OR FUNCTIONALITY. OUR
        SERVICES ARE NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS OR
        ENVIRONMENTS WHERE THE FAILURE OR TIME DELAYS OF, OR ERRORS OR
        INACCURACIES IN THE DATA OR INFORMATION PROVIDED BY OUR SERVICES COULD
        LEAD TO DEATH, PERSONAL INJURY, OR SEVERE PHYSICAL OR ENVIRONMENTAL
        DAMAGE.
      </p>
      <p>
        IN NO EVENT WILL MY CARELINQ BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
        SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN
        CONNECTION WITH OUR SERVICES (INCLUDING ANY THIRD-PARTY MATERIALS OR
        CONTENT AVAILABLE THROUGH OUR SERVICES), WHETHER OR NOT WE HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. MY CARELINQ’S TOTAL
        CUMULATIVE LIABILITY IN CONNECTION WITH THESE TERMS OR OUR SERVICES,
        WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, WILL NOT
        EXCEED THE GREATER OF THE PRICE YOU PAID FOR THE SERVICES GIVING RISE TO
        THE APPLICABLE CLAIM IN THE SIX-MONTH PERIOD PRIOR TO THE DATE SUCH
        CLAIM AROSE (IF ANY) OR ONE HUNDRED DOLLARS ($100).
      </p>
      <p>
        THE FOREGOING LIMITATIONS OF LIABILITY APPLY TO THE FULLEST EXTENT
        ALLOWED BY LAW. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE
        FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN MY CARELINQ AND
        YOU. THE FOREGOING CAP ON LIABILITY WILL NOT APPLY TO LIABILITY OF MY
        CARELINQ FOR DEATH OR PERSONAL INJURY CAUSED BY MY CARELINQ’S GROSS
        NEGLIGENCE IN CONNECTION WITH THE SERVICES; OR FOR ANY DAMAGES CAUSED BY
        MY CARELINQ’S FRAUD OR FRAUDULENT MISREPRESENTATION, INTENTIONAL
        MISCONDUCT OR GROSS NEGLIGENCE.
      </p>
      <h3 className="text-h3">
        <strong>14. Modifications to these Terms</strong>
      </h3>
      <p>
        My CareLinq reserves the right to change and make additions to these
        Terms at any time. If we do, we’ll use reasonable efforts to notify you,
        for example, by sending a notification to your Account and/or giving
        notice through our Services. Unless we say otherwise in our notice, the
        revised Terms will be effective immediately and your continued use of
        your Account after we provide notice of the changes will confirm your
        acceptance of the revised Terms. We encourage you to review the Terms
        from time to time to ensure you understand the terms and conditions that
        apply to your use of the Services.
      </p>
      <h3 className="text-h3">
        <strong>15. Governing Law and Venue</strong>
      </h3>
      <p>
        These Terms will be governed by the laws of the State of California,
        without reference to rules governing choice of laws or the U.N.
        Convention on Contracts for the International Sale of Goods. If either
        of us has a claim against the other, most claims or disputes will be
        resolved by binding arbitration, as described in the Arbitration
        Provision below. However, to the extent these Terms permit litigation in
        court, you hereby irrevocably consent to and waive any objection to the
        exclusive jurisdiction and venue of the federal and state courts located
        at Alameda County, California with respect to any claims, suits or
        proceedings arising out of or in connection with these Terms or the
        Services.
      </p>
      <h3 className="text-h3">
        <strong>16. Compliance</strong>
      </h3>
      <p>
        You will comply with all applicable laws and regulations and will not,
        without prior government authorization, export, re-export, or transfer
        My CareLinq products, services (including the Services) or technology,
        either directly or indirectly, in violation of such laws and
        regulations. You represent and warrant that you (a) are not located in,
        under the control of, or a national or resident of any country to which
        the United States has embargoed goods or services, or that has been
        designated by the U.S. Government as a “terrorist supporting” country;
        (b) have not been identified as a “Specially Designated National” by the
        Office of Foreign Assets Control; (c) have not been placed on the U.S.
        Commerce Department’s Denied Persons List;” and (d) will not use our
        Services if any applicable laws in your country prohibit you from doing
        so in accordance with these Terms.
      </p>
      <h3 className="text-h3">
        <strong>17. Communications</strong>
      </h3>
      <p>
        You hereby consent to receive communications from us about your Account
        in an electronic form, whether via email or posting on our Services or
        other reasonable means; and agree that all terms and conditions,
        agreements, notices, disclosures, and other communications that we send
        to you electronically satisfy any legal requirement that such
        communications must be in writing. If you have any questions about this,
        please reach out to{" "}
        <a href="mailto: questions@mycarelinq.com">questions@mycarelinq.com</a>.
      </p>
      <h3 className="text-h3">
        <strong>18. General Provisions</strong>
      </h3>
      <p>
        These Terms are personal to you, and you may not assign or delegate your
        right and/or duties under these Terms to anyone else. Any attempted
        assignment or delegation is void. You acknowledge that we have the right
        to seek an injunction, if necessary, to stop or prevent your violation
        of these Terms. Any delay or failure by us to exercise or enforce any
        right or provision of these Terms will not constitute a waiver of such
        right or provision. No waiver by us will have effect unless such waiver
        is in writing, signed by us. No waiver of any breach or default in one
        instance will constitute a waiver of any subsequent breach or default.
        If for any reason a court of competent jurisdiction finds any provision
        of these Terms to be unenforceable, that provision of these Terms will
        be enforced to the maximum extent permissible so as to effect the intent
        of the parties, and the remainder of these Terms will continue in full
        force and effect. These Terms, and any Additional Terms you enter into
        with us, constitute the entire agreement between us with regard to your
        access to and use of the Services.
      </p>
      <h3 className="text-h3">
        <strong>19. Survival</strong>
      </h3>
      <p>
        Following any termination of these Terms, My CareLinq has the right to
        continue to the Feedback you have provided to us in the ways permitted
        by these Terms, and the following provisions will also continue to
        apply: Suspension/Termination, Intellectual Property, Warranty
        Disclaimer and Limits on Liability, Governing Law and Venue, Compliance,
        General Provisions, Survival, and the Arbitration Provision.
      </p>
      <h3 id="tou-arbitration-provision" className="text-h3">
        <strong>20. Agreement to Arbitrate and Waiver of Class Action</strong>
      </h3>
      <p>
        <strong>
          Please carefully read the following agreement to arbitrate and waiver
          of class action (“Arbitration Provision”). It requires you to
          arbitrate disputes with My CareLinq and limits the manner in which you
          can seek relief from us. It prevents you from bringing any class,
          collective, or representative action against My CareLinq, it prevents
          you from participating in or recovering any relief in any class,
          collective, or representative action anyone else might bring against
          My CareLinq, and it prevents you from suing My CareLinq in court or
          from having a jury trial in most cases. You can opt out of this
          Arbitration Provision by following the instructions below.
        </strong>
      </p>
      <h6 className="text-h6">
        <i>Applicability of Arbitration Provision</i>
      </h6>
      <p>
        You agree that any dispute or claim relating in any way to these Terms,
        including this Arbitration Provision, to our privacy policy or data
        security generally, or otherwise related to the Services or our apps (“
        <strong>Disputes</strong>”) will be resolved by binding arbitration,
        rather than in court, except that (a) you may assert claims in small
        claims court if your claims qualify, so long as the matter remains in
        such court and advances only on an individual (non-class,
        non-representative) basis; (b) you or My CareLinq may seek equitable
        relief in court for infringement or other misuse of intellectual
        property rights (such as trademarks, trade dress, domain names, trade
        secrets, copyrights, and patents); and (c) My CareLinq may seek
        equitable relief in court (including an injunction), if necessary, to
        stop or prevent your violation of these Terms. This Arbitration
        Provision will apply, without limitation, to all Disputes that arose or
        were asserted before these Terms or any prior version of these Terms
        were in effect, as well as to all Disputes arising thereafter.
      </p>
      <h6 className="text-h6">
        <i>Waiver of Class Relief</i>
      </h6>
      <p>
        WHETHER THE DISPUTE IS HEARD IN ARBITRATION OR IN COURT, YOU AGREE THAT
        YOU AND MY CARELINQ WILL NOT COMMENCE AGAINST THE OTHER, OR PARTICIPATE
        IN, A CLASS ACTION, CLASS ARBITRATION OR OTHER REPRESENTATIVE ACTION OR
        PROCEEDING. YOU AND MY CARELINQ ARE EACH WAIVING RESPECTIVE RIGHTS TO A
        TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION.
      </p>
      <h6 className="text-h6">
        <i>Arbitration Rules and Forum</i>
      </h6>
      <p>
        The Federal Arbitration Act governs the interpretation and enforcement
        of this Arbitration Provision. To begin an arbitration proceeding, you
        must send a letter requesting arbitration and describing your claim to
        our registered agent, Incorporating Services, Ltd., 3500 South DuPont
        Highway, Dover, Delaware 19901. The arbitration will be conducted by
        JAMS, an established alternative dispute resolution provider. Disputes
        will be subject to JAMS’s most current version of the Comprehensive
        Arbitration Rules and Procedures, available{" "}
        <a href="https://www.jamsadr.com/rules-comprehensive-arbitration/">
          here
        </a>
        . JAMS’s rules are also available{" "}
        <a href="https://www.jamsadr.com/">here</a> or by calling JAMS at
        800-352-5267. If JAMS is not available to arbitrate, the parties will
        jointly select an alternative arbitral forum. If the arbitrator finds
        that you cannot afford to pay JAMS’s filing, administrative, hearing
        and/or other fees (“<strong>Arbitration Fees</strong>”) and cannot
        obtain a waiver from JAMS, My CareLinq will pay them for you. My
        CareLinq will not seek attorneys’ fees and costs in arbitration unless
        the arbitrator determines the claims are frivolous. You may choose to
        have the arbitration conducted by telephone, based on written
        submissions, or in person in the country where you live or at another
        mutually agreed location. Any final decision made by the arbitrator may
        be entered in any court of competent jurisdiction.
      </p>
      <h6 className="text-h6">
        <i>Authority of Arbitrator</i>
      </h6>
      <p>
        The arbitrator, and not any federal, state or local court or agency,
        will have exclusive authority to resolve any dispute related to the
        interpretation, applicability, enforceability or formation of these
        Terms, including this Arbitration Provision including, but not limited
        to, any claim that all or any part of these Terms or this Arbitration
        Provision are void or voidable. The arbitrator will decide the rights
        and liabilities, if any, of you and My CareLinq. The arbitration
        proceeding will not be consolidated with any other matters or joined
        with any other cases or parties. The arbitrator will have the authority
        to grant motions dispositive of all or part of any claim. The arbitrator
        will have the authority to award monetary damages and to grant any
        non-monetary remedy or relief available to an individual under
        applicable law, the arbitral forum’s rules, and these Terms (including
        the Arbitration Provision). The arbitrator will issue a written award
        and statement of decision describing the essential findings and
        conclusions on which the award is based, including the calculation of
        any damages awarded. The arbitrator has the same authority to award
        relief on an individual basis that a judge in a court of law would have.
        The award of the arbitrator is final and binding upon you and us.
      </p>
      <h6 className="text-h6">
        <i>Waiver of Jury Trial</i>
      </h6>
      <p>
        YOU AND MY CARELINQ HEREBY WAIVE ANY CONSTITUTIONAL AND STATUTORY RIGHTS
        TO SUE IN COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY. You and
        My CareLinq are instead electing that all claims and disputes will be
        resolved by arbitration under this Arbitration Provision, except as
        specified in the Applicability of Arbitration Provision section above.
        An arbitrator can award on an individual basis the same damages and
        relief as a court and must follow these Terms as a court would. However,
        there is no judge or jury in arbitration, and court review of an
        arbitration award is subject to very limited review.
      </p>
      <h6 className="text-h6">
        <i>Waiver of Class or Consolidated Actions</i>
      </h6>
      <p>
        ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS ARBITRATION PROVISION
        MUST BE ARBITRATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS BASIS, ONLY
        INDIVIDUAL RELIEF IS AVAILABLE, AND CLAIMS OF MORE THAN ONE PERSON
        CANNOT BE ARBITRATED OR CONSOLIDATED WITH THOSE OF ANY OTHER PERSON. In
        the event that this subparagraph is deemed invalid or unenforceable,
        neither you nor My CareLinq are entitled to arbitration and instead
        claims and disputes will be resolved in a court as set forth in the
        Governing Law and Venue section above.
      </p>
      <h6 className="text-h6">
        <i>Right to Opt Out</i>
      </h6>
      <p>
        You have the right to opt out of the provisions of this Arbitration
        Provision by sending written notice of your decision to opt out to the
        following address: 2342 Shattuck Ave, #336 Berkeley CA, 94704, and/or{" "}
        <a href="mailto: support@mycarelinq.com">support@mycarelinq.com</a>
        support@mycarelinq.com within 30 days after first becoming subject to
        this Arbitration Provision. Your notice must include your name and
        address, your Account username, and an unequivocal statement that you
        want to opt out of this Arbitration Provision. If you opt out of this
        Arbitration Provision, all other provisions of these Terms will continue
        to apply to you. Opting out of this Arbitration Provision has no effect
        on your agreement to the rest of these Terms or your agreement to any
        other arbitration agreements that you may currently have, or may enter
        in the future, with us. If you opt out of this Arbitration Provision, My
        CareLinq will likewise not be bound by this Arbitration Provision.
      </p>
      <h6 className="text-h6">
        <i>Severability</i>
      </h6>
      <p>
        If any part or parts of this Arbitration Provision are found under the
        law to be invalid or unenforceable, then such specific part or parts
        will be of no force and effect and will be severed and the remainder of
        the Arbitration Provision will continue in full force and effect.
      </p>
      <h6 className="text-h6">
        <i>Survival of Provision</i>
      </h6>
      <p>
        This Arbitration Provision will survive the termination of your
        relationship with My CareLinq.
      </p>
      <h6 className="text-h6">
        <i>Modification</i>
      </h6>
      <p>
        Notwithstanding any provision in these Terms to the contrary, we agree
        that if we make any future material change to this Arbitration
        Provision, such change will not apply to any individual claims of which
        you had already provided notice to My CareLinq.
      </p>
    </div>
  );
};

const ToU = () => {
  return (
    <div className="ToU-page">
      <section
        className="
                bg-[url('assets/images/about-us/header-bg.png')] 
                min-h-[2rem]
                max-h-[12rem]
                bg-center 
                bg-no-repeat 
                bg-cover 
                overflow-hidden 
                flex
            "
      >
        <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-normal">
          <Row style={{ padding: "0rem" }}>
            <Col xs={24}>
              <h1
                className="text-[4rem] text-white"
                style={{ textShadow: "3px 3px 7px rgb(0 0 0 / 65%)" }}
              >
                TERMS OF USE
              </h1>
            </Col>
          </Row>
        </div>
      </section>
      <PurpleDivider />
      <ToUContent />
      {/* <Container className="mt-3">
                <h1>[TODO: Insert reCAPTCHA here]</h1>
            </Container>
            <Container>
                <Form className="mt-3">
                    <Form.Check
                        type="checkbox"
                        label="I am 18 years or older. I accept the terms in the Terms of Use."
                    />
                </Form>
                <Button>Confirm</Button>
            </Container> */}
    </div>
  );
};

const ToUModal = ({ isVisible, handleOk, handleCancel }) => {
  return (
    <Modal
      title="Terms of Use"
      visible={isVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={[
        <Button key="submit" type="primary" onClick={handleOk}>
          OK
        </Button>,
      ]}
      className="tou-modal"
    >
      <ToUContent className="overflow-hidden" />
    </Modal>
  );
};

export { ToUContent, ToU, ToUModal };
