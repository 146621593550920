import React, { useState, useCallback, useEffect } from "react";
import ReactGA from "react-ga4";
import _ from "lodash";
import { mclOpen } from "../api/Mcl";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Col, Row, Form, Modal, Button } from "antd";
import ContactForm from "./ContactForm";
import { SendOutlined } from "@ant-design/icons";

import { Url } from "../constants/Global";
import "./Footer.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { openNotification } from "./Various";
import { ScheduleModal } from "./ScheduleForm";

import { Contactv2Modal, Contactv2Form } from "./Contactv2Form";
import { PartnerModal, PartnerForm } from "./PartnerForm";


const ContactModal = ({
  setting = {
    title: "Drop Us a Note",
    isModalVisible: false,
    calendlyLink: "",
  },
  isSubmitting = false,
  handleCancel = () => {},
  submitForm = (values) => {
    console.log(values);
  },
  form = undefined,
}) => {
  return (
    <Modal
      title={setting.title}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      width={"80%"}
      onOk={form && form.submit}
      footer={[
        <Button
          key="back"
          shape="round"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          icon={<SendOutlined />}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={form && form.submit}
        >
          Submit
        </Button>,
      ]}
    >
      <ContactForm
        submitContactForm={submitForm}
        isSubmitting={isSubmitting}
        form={form}
      />
    </Modal>
  );
};

// const Footer = ({consultMsg = "Schedule a Free Consultation", showSchedule = false, showContact = false, showPartner = false}) => {
const Footer = ({consultMsg = "Schedule a Free Consultation"}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPath = location.pathname;

  const modal = searchParams.get('modal');
  const showContact = modal === 'contact' ? true : false;
  const showSchedule = modal === 'schedule' ? true : false;
  const showPartner = modal === 'partner' ? true : false;


  const [contactModalSetting, setContactModalSetting] = useState({
    title: "Let's work together",
    // title: consultMsg,
    isModalVisible: false,
  });
  const [scheduleModalSetting, setScheduleModalSetting] = useState({
    // title: "Schedule a Free Consultation",
    title: consultMsg,
    isModalVisible: false,
  });

  const [partnerModalSetting, setPartnerModalSetting] = useState({
    // title: "Partner with My CareLinq - Learn More",
    title: "Let's Work Together",
    isModalVisible: false,
  });


  const [isSubmitting, setSubmitting] = useState(false);
  const [contactForm] = Form.useForm();
  const [scheduleForm] = Form.useForm();
  const [partnerForm] = Form.useForm();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("needHelpForm");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const submitContactForm = async (data) => {
    setSubmitting(true);
    ReactGA.event({
      category: "footer",
      action: "submit_form",
      label: "contact_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.FORMS.concat("/", "need-help-with"), data, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            openNotification({
              message: "Inquiry submitted",
              status: "success",
              title: "Thank you " + data.firstName + "!",
              subTitle:
                "Thank you for your inquiry! My Carelinq will follow up with you soon.",
            });
          } else {
            openNotification({
              message: "Sorry...contact us encountered an error",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setContactModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          contactForm.resetFields();
          setSubmitting(false);
        })
        .catch((error) => {
          openNotification({
            message: "Sorry...Contact Us submission encountered a server error",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Contact Us failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  const submitScheduleForm = async (data) => {
    setSubmitting(true);
    ReactGA.event({
      category: "footer",
      action: "submit_form",
      label: "schedule_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.FORMS.concat("/", "schedule"), data, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            if (
              !_.isEmpty(res.data) &&
              _.includes(res.data, "https://calendly.com")
            ) {
              window.open(res.data, "_blank");
            } else {
              openNotification({
                message: "Sorry",
                status: "500",
                title: "Something goes wrong in the server",
                subTitle: "Please wait for the staff to fix it.",
              });
            }
          } else {
            openNotification({
              message: "Sorry",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setScheduleModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          setSubmitting(false);
          scheduleForm.resetFields();
        })
        .catch((error) => {
          openNotification({
            message: "Sorry",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  const submitPartnerForm = async (data) => {
    console.log(">>>Enter>>> submitPartnerForm");
    setSubmitting(true);
    ReactGA.event({
      category: "home",
      action: "submit_form",
      label: "partner_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.FORMS.concat("/", "partner-inquiry"), data, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            openNotification({
              message: "Partner Inquiry submitted",
              status: "success",
              title: "Thank you " + data.firstName + "!",
              subTitle:
                "Thank you for your inquiry.  My CareLinq will contact you as soon as possible.",
            });
          } else {
            openNotification({
              message: "Sorry...partner inquiry encountered an error",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setPartnerModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          partnerForm.resetFields();
          setSubmitting(false);
        })
        .catch((error) => {
          openNotification({
            message: "Sorry...Partner Inquiry encountered a server error",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Partner Inquiry failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  useEffect(() => {
    if (showSchedule) {
      setScheduleModalSetting((prevState) => ({
        ...prevState,
        isModalVisible: true,
      }));
      // setContact(false);
      navigate(currentPath);
    }
  }, [showSchedule]);

  useEffect(() => {
    if (showContact) {
      setContactModalSetting((prevState) => ({
        ...prevState,
        isModalVisible: true,
      }));
      // setContact(false);
      navigate(currentPath);
    }
  }, [showContact]);

  useEffect(() => {
    if (showPartner) {
      setPartnerModalSetting((prevState) => ({
        ...prevState,
        isModalVisible: true,
      }));
      // setContact(false);
      navigate(currentPath);
    }
  }, [showPartner]);

  return (
    <div className="footer">
      <PartnerModal
        setting={partnerModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "home",
            action: "close_modal",
            label: "partner_modal",
          });
          setPartnerModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitPartnerForm}
        form={partnerForm}
      />


      <Contactv2Modal
        setting={contactModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "footer",
            action: "close_modal",
            label: "contact_modal",
          });
          setContactModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitContactForm}
        form={contactForm}
      />
      {/* <ContactModal
        setting={contactModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "footer",
            action: "close_modal",
            label: "contact_modal",
          });
          setContactModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitContactForm}
        form={contactForm}
      /> */}
      <ScheduleModal
        setting={scheduleModalSetting}
        isSubmitting={isSubmitting}
        handleCancel={() => {
          ReactGA.event({
            category: "footer",
            action: "close_modal",
            label: "schedule_modal",
          });
          setScheduleModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submitForm={submitScheduleForm}
        form={scheduleForm}
      />
      <div className="footer-purple-box">
        <div className="w-full lg:container lg:mx-auto px-3" id="contact-form">
          <Row>
            <Col xs={24}>
              {/* <p className="footer-cta-text">
                Give the{" "}
                <span className="text-highlight-cyan font-bold">
                  Gift of Clarity
                </span>{" "}
                to your care team and survivors when they need it most.
                <br />
                It is how we take care for ourselves and each other.
              </p> */}
              <p
                className="
                pt-3 pr-10 pb-2 pl-10 flex text-center items-center justify-center xs:text-[0.75rem] sm:text-[1rem] md:text-[1.25rem] lg:text-[1.5rem] text-center
                text-white leading-relaxed"
              >
                We are Here to Help. <br />
                <span>
                  <Button
                    type="link"
                    className="text-highlight-cyan font-bold text-2xl"
                    onClick={() => {
                      ReactGA.event({
                        category: "footer",
                        action: "open_modal",
                        label: "schedule_modal",
                      });
                      setScheduleModalSetting((prevState) => ({
                        ...prevState,
                        isModalVisible: true,
                      }));
                    }}
                  >
                    {consultMsg}
                  </Button>
                  today.
                </span>
              </p>
            </Col>
          </Row>
        </div>
      </div>
      {/* <div className="w-full lg:container lg:mx-auto px-3">
        <Row>
          <Col xs={24}>
            <ContactForm
              isSubmitting={isSubmitting}
              submitContactForm={submitContactForm}
            />
          </Col>
        </Row>
      </div> */}
      <div className="w-full lg:container lg:mx-auto px-3 footer-link-section">
        {/* <Row>
                <Col xs={24}>
                    <div className="flex-auto flex-col self-stretch">
                        <Form>
                            <Row>
                                <Col xs={24} sm={12} md={6}>
                                    <Form.Group className="mb-3" controlId="formSearch">
                                        <Form.Label className="footer-link">Search</Form.Label>
                                        <Form.Control type="text" placeholder="" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Col>
            </Row> */}
        <Row>
          <Col xs={24} sm={12} xl={6}>
            <div className="flex-auto flex-col self-stretch">
              <Link to="/faq" className="footer-link">
                FAQ
              </Link>
              <Link to="/how-we-can-help" className="footer-link">
                HOW WE CAN HELP YOU
              </Link>
              <Link to="/pricing" className="footer-link">
                PRICING
              </Link>
            </div>
          </Col>
          <Col xs={24} sm={12} xl={6} className="footer-link-second-col">
            <div className="flex-auto flex-col self-stretch">
              {/* <Link to="/investors" className="footer-link">INVESTORS</Link> */}
              <Button
                className="footer-link h-full"
                type="link"
                onClick={() => {
                  ReactGA.event({
                    category: "footer",
                    action: "open_modal",
                    label: "contact_modal",
                  });
                  setContactModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                  }));
                }}
              >
                GET IN TOUCH
              </Button>
              <Link to="/privacy-policy" className="footer-link">
                PRIVACY POLICY
              </Link>
              <Link to="/terms-of-use" className="footer-link">
                TERMS OF USE
              </Link>
            </div>
          </Col>
          <Col xs={24} sm={12} xl={6}>
            {/* <div className="flex-auto flex-col self-stretch">
              <Link to="/privacy-policy" className="footer-link">
                PRIVACY POLICY
              </Link>
              <Link to="/terms-of-use" className="footer-link">
                TERMS OF USE
              </Link>
            </div> */}
          </Col>
          <Col xs={24} sm={12} xl={6} className="footer-icon-col">
            <div className="flex flex-row self-stretch items-center gap-4 footer-icon-hstack">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.linkedin.com/company/my-carelinq/"
                className="footer-icon"
                onClick={() => {
                  ReactGA.event({
                    category: "footer",
                    action: "click_social_media",
                    label: "linkedin",
                  });
                }}
              >
                <i className="li-icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.facebook.com/mycarelinqservices/"
                className="footer-icon"
                onClick={() => {
                  ReactGA.event({
                    category: "footer",
                    action: "click_social_media",
                    label: "facebook",
                  });
                }}
              >
                <i className="fb-icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/mycarelinq/"
                className="footer-icon"
                onClick={() => {
                  ReactGA.event({
                    category: "footer",
                    action: "click_social_media",
                    label: "instagram",
                  });
                }}
              >
                <i className="insta-icon" />
              </a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.youtube.com/channel/UCgBeGufhw88tl8Lc_kpKRxQ"
                className="footer-icon"
                onClick={() => {
                  ReactGA.event({
                    category: "footer",
                    action: "click_social_media",
                    label: "youtube",
                  });
                }}
              >
                <i className="yt-icon" />
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24}>
            <p className="text-base text-center font-thin text-primary-purple">
              Copyright &copy; 2021, 2022{" "}
              <a href="https://mycarelinq.com">
                <strong>My CareLinq, Inc.</strong>
              </a>{" "}
              All Rights Reserved.
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Footer;
