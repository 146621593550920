import React, { useState, useEffect } from "react";

import { Button, Col, Row, Skeleton, Spin, Space } from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";

import useMclAuth from "../../hooks/useMclAuth";

import {
  Care,
  CareLevel,
  CareLevelToColor,
  CareType,
  CareTypeToStr,
  IsAdmin,
  IsBackupAdmin,
  IsMember,
  IsPendingMember,
  PlanRoleToColor,
  PlanRoleToStr,
  Roles,
  Url,
} from "../../constants/Global";
import _ from "lodash";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";

const Circle = ({
  style = {},
  index,
  setPage,
  cg,
  circle,
  showComponent,
  currentCircle,
  setCurrentCircle,
  isLoading,
  handleClickCancelSub,
}) => {
  const mclAuth = useMclAuth();

  // const [primaryAdmins, setPrimaryAdmins] = useState([]);
  // const [secondaryAdmin, setSecondaryAdmin] = useState({});
  // const [memberUsers, setMemberUsers] = useState([]);
  // const [pendingUsers, setPendingUsers] = useState([]);

  // const [userIsLoading, setUserIsLoading] = useState(false);
  const [error, setError] = useState("");

  const planLabel = () => {
    return circle.care === Care.Care.INCARE
      ? "Care Recipient Plan"
      : "My Care Plan";
  };

  if (isLoading) {
    console.log("isLoading!!!");
    return <Skeleton active />;
  }

  const manageTeam = (e) => {
    // console.log("+#+#+>manageTeam this circle's index:", e.currentTarget.id);
    setCurrentCircle(e.currentTarget.id);
    showComponent("team");
  };

  const chatTeam = (e) => {
    // console.log("+#+#+>chatTeam this circle's index:", e.currentTarget.id);
    setCurrentCircle(e.currentTarget.id);
    showComponent("chat");
  };

  const showKb = (e) => {
    // console.log("+#+#+>showKb this circle's index:", e.currentTarget.id);
    setCurrentCircle(e.currentTarget.id);
    showComponent("kb");
  };

  const showPlan = (e) => {
    // console.log("+#+#+>showPlan this circle's index:", e.currentTarget.id);
    setCurrentCircle(e.currentTarget.id);
    showComponent("planDetails");
  };

  const useFade = () => {
    const [fade, setFade] = useState(false);
    const onMouseEnter = () => {
      setFade(true);
    };
    const onMouseLeave = () => {
      setFade(false);
    };
    const fadeStyle = !fade
      ? {
          opacity: 1,
          transition: "all .2s ease-in-out",
        }
      : {
          opacity: 0.5,
          transition: "all .2s ease-in-out",
        };

    return { fadeStyle, onMouseEnter, onMouseLeave };
  };

  const getPlanAdmin = () => {
    if (isLoading || !circle) {
      return <Spin />;
    }
    // console.log("~~~~~~Circle getPlanAdmin circle.primary:\n", circle.primary);
    if (!circle.primary || circle.primary.length < 1) {
      return "Unknown";
    }
    return circle.primary[0].firstName + " " + circle.primary[0].lastName;
  };

  // const labelTeamAdmin = () => {
  //   if (isLoading || _.isEmpty(cg) || _.isEmpty(circle)) {
  //     return <Spin />;
  //   }
  //   if (!circle.primary) {
  //     return "Unknown";
  //   }
  //   // Currently, we only allow a single primary admin in a circle
  //   return circle.primary[0] === cg._id ? (
  //     <>Myself</>
  //   ) : (
  //     circle.primary[0].firstName
  //   );
  // };

  const getUserFullName = (userId, userCareLevel) => {
    if (isLoading || _.isEmpty(cg) || _.isEmpty(circle)) {
      return <Spin />;
    }
    if (userId === cg._id) {
      return [cg.firstName, cg.lastName];
    }
    let userRecords = [];
    switch (userCareLevel) {
      case CareLevel.INVITED_MEMBER:
        userRecords = circle.members;
        break;
      case CareLevel.PRIMARY_ADMIN:
        userRecords = circle.primary;
        break;
      case CareLevel.BACKUP_ADMIN:
        return !circle.secondary
          ? ["Unknown", "Unknown"]
          : [circle.secondary.firstName, circle.secondary.lastName];
      // break;
      case CareLevel.PENDING_MEMBER:
        userRecords = circle.pendings;
        break;
      default:
        return ["Unknown", "Unknown"];
    }
    for (let i = 0; i < userRecords.length; i++) {
      if (userRecords[i]._id === userId) {
        return [userRecords[i].firstName, userRecords[i].lastName];
      }
    }
    return ["Unknown", "Unknown"];
  };

  const populateAdminMembers = () => {
    // console.log("#^#^#Entering populateAdminMembers");
    // Populate primary admin(s) of this circle first. Currently, there is only
    // a single primary admin of a care circle
    if (!circle.primary) {
      return <>Missing Admin</>;
    }
    return (
      <>
        {circle.primary.map((admin, idx) => {
          return (
            <div
              key={uuidv4()}
              className="text-[0.5rem] text-[#10082d] text-center p-1"
            >
              <Col className="flex flex-col justify-center">
                <Row
                  className="text-[2.5rem] text-[#8159f5] justify-center"
                  // style={{
                  //   fontColor: CareLevelToColor(CareLevel.PRIMARY_ADMIN),
                  //   fontColor: "rgba(244, 85, 27, 1.0)",
                  // }}
                >
                  <UserOutlined />
                </Row>
                <Row className="justify-center">
                  {admin._id === cg._id ? "Myself" : admin.firstName}
                </Row>
                <Row className="justify-center text-[0.4rem]">(Plan Admin)</Row>
              </Col>
            </div>
          );
        })}
      </>
    );
  };
  const populateBackupAdminMember = () => {
    // console.log("#^#^#Entering populateBackupAdminMembers");
    if (!circle.secondary) {
      return <></>;
    }
    // console.log(
    //   "#^#^#\tcircle.secondary._id=" +
    //     circle.secondary._id +
    //     " cg._id=" +
    //     cg._id
    // );
    // Populate backup admin of this circle next. By design, there is only
    // a single backup (secondary) admin of a care circle
    return (
      <div
        key={"admin2nd" + uuidv4()}
        className="text-[0.5rem] text-[#10082d] text-center p-1"
      >
        <Col className="flex flex-col justify-center">
          <Row
            className="text-[2.5rem] text-[#217671] justify-center"
            // style={{
            //   fontColor: CareLevelToColor(CareLevel.BACKUP_ADMIN),
            //   fontColor: "#e0551bc0",
            // }}
          >
            <UserOutlined />
          </Row>
          <Row className="justify-center">
            {circle.secondary._id === cg._id
              ? "Myself"
              : circle.secondary.firstName}
          </Row>
          <Row className="justify-center text-[0.4rem]">(Backup Admin)</Row>
        </Col>
      </div>
    );
  };
  const populateInvitedMembers = () => {
    // console.log("#^#^#Entering populateInvitedMembers");
    if (!circle.members) {
      return <></>;
    }
    // Populate invited members of this circle
    return (
      <>
        {circle.members.map((member, idx) => {
          // Skip this member if he/she is also a backup admin
          if (circle.secondary && circle.secondary._id === member._id) {
            return <></>;
          }
          return (
            <div
              className="text-[0.5rem] text-[#10082d] text-center p-1"
              key={uuidv4()}
            >
              <Col className="flex flex-col justify-center">
                <Row
                  className="text-[2.5rem] text-[#3c3066] justify-center"
                  // style={{
                  //   fontColor: CareLevelToColor(CareLevel.INVITED_MEMBER),
                  //   fontColor: "#8159f5",
                  // }}
                >
                  <UserOutlined />
                </Row>
                <Row className="justify-center">
                  {member._id === cg._id
                    ? "Myself"
                    : getUserFullName(member._id, CareLevel.INVITED_MEMBER)[0]}
                </Row>
                <Row className="justify-center text-[0.4rem]">
                  (Invited Member)
                </Row>
              </Col>
            </div>
          );
        })}
      </>
    );
  };
  const populatePendingMembers = () => {
    // console.log("#^#^#Entering populatePendingMembers");
    if (!circle.pendings) {
      return <></>;
    }
    // Populate pending members of this circle
    circle.pendings.map((pending, idx) => {
      return (
        <div
          className="text-[0.5rem] text-[#10082d] text-center p-1"
          key={uuidv4()}
        >
          <Col className="flex flex-col justify-center">
            <Row
              className="text-[2.5rem] text-[#3c3066]/80 justify-center"
              // style={{
              //   fontColor: CareLevelToColor(CareLevel.PENDING_MEMBER),
              //   fontColor: "#8159f580",
              // }}
            >
              <UserOutlined />
            </Row>
            <Row className="justify-center">
              {pending._id === cg._id
                ? "Err: Myself"
                : getUserFullName(pending._id, CareLevel.PENDING_MEMBER)[0]}
            </Row>
            <Row className="justify-center text-[0.4rem]">(Pending Member)</Row>
          </Col>
        </div>
      );
    });
  };
  const populateTeamMembers = () => {
    // console.log("#^#^#Entered populateTeamMembers");
    if (isLoading || _.isEmpty(cg) || _.isEmpty(circle)) {
      return <Spin />;
    }
    return (
      <>
        {populateAdminMembers()}
        {populateBackupAdminMember()}
        {populateInvitedMembers()}
        {populatePendingMembers()}
      </>
    );
  };

  const styleDefault = {
    height: "24em",
    /* margin: "20px",*/
    // width: "466px",
    // display: "flex",
    backgroundColor: "white",
    borderRadius: "1.5rem",
    // overflow: "hidden",
    overflow: "auto",
  };
  const circleStyle = (currentCircle, index) => {
    if (currentCircle === index) {
      return {
        ...styleDefault,
        ...style,
        border: "5px solid rgba(60, 48, 102, 0.3)",
      };
    }
    return { ...styleDefault, ...style };
  };

  const fillCircleContent = () => {
    return (
      <>
        <Row className="flex flex-row grow justify-between items-center text-[0.5rem] p-2">
          <div>{CareTypeToStr(circle.care)}</div>
          <div
            className="rounded-md text-white p-1"
            style={{ backgroundColor: PlanRoleToColor(cg._id, circle) }}
          >
            You are the {PlanRoleToStr(cg._id, circle)}
          </div>
        </Row>
        <Row>
          <Col span={3}>
            {_.isEmpty(cg) || _.isEmpty(circle) ? (
              <Skeleton active />
            ) : PlanRoleToStr(cg._id, circle) === "PLAN ADMIN" ? (
              <div className="flex flex-row grow h-[3rem] text-[1.75rem] text-white justify-center items-center m-0 px-2">
                <UserOutlined
                  style={{
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: PlanRoleToColor(cg._id, circle),
                    color: "#ffffff",
                  }}
                />
              </div>
            ) : PlanRoleToStr(cg._id, circle) === "BACKUP ADMIN" ? (
              <div className="flex flex-row grow h-[3rem] text-[1.75rem] text-white justify-center items-center m-0 px-2">
                <UserOutlined
                  style={{
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: PlanRoleToColor(cg._id, circle),
                    color: "#ffffff",
                  }}
                />
              </div>
            ) : (
              <div className="flex flex-row grow h-[3rem] text-[1.75rem] text-white justify-center items-center m-0 px-2">
                <UserOutlined
                  style={{
                    borderRadius: "50%",
                    width: "2rem",
                    height: "2rem",
                    backgroundColor: PlanRoleToColor(cg._id, circle),
                    color: "#ffffff",
                  }}
                />
              </div>
            )}
          </Col>
          <Col span={21}>
            <div className="flex flex-row grow items-center h-[3rem] text-[#10082d] pt-2">
              <h2>
                {circle.firstName} {circle.lastName}
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={14} className="p-1">
            <div className="text-[0.5rem]">
              <Row>
                <Col span={9} className="text-right pr-1">
                  Age:
                </Col>
                <Col span={15} className="font-bold"></Col>
              </Row>
              <Row>
                <Col span={9} className="text-right pr-1">
                  Address:
                </Col>
                <Col span={15} className="font-bold"></Col>
              </Row>
              <Row>
                <Col span={9} className="text-right pr-1">
                  Plan Admin:
                </Col>
                <Col span={15} className="font-bold">
                  {getPlanAdmin()}
                </Col>
              </Row>
              <Row>
                <Col span={9} className="text-right pr-1">
                  Account created:
                </Col>
                <Col span={15} className="font-bold">
                  {moment(circle.createdAt).format("MMMM D, YYYY")}
                </Col>
              </Row>
              <Row>
                <Col span={9} className="text-right pr-1">
                  Subscription ends:
                </Col>
                <Col span={15} className="font-bold">
                  {!_.isNil(circle.subExpiration) && circle.subExpiration != 0
                    ? new Intl.DateTimeFormat("en-US").format(
                        circle.subExpiration * 1000
                      )
                    : ""}
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={10}>
            <Row>
              {_.isEmpty(cg) || _.isEmpty(circle) ? (
                <Skeleton active />
              ) : IsAdmin(cg._id, circle) || IsBackupAdmin(cg._id, circle) ? (
                <Button
                  id={index}
                  shape="round"
                  type="primary"
                  size="middle"
                  style={{
                    background: "#4d66ff",
                    border: "none",
                    width: "11rem",
                    whiteSpace: "normal",
                  }}
                  className="m-1 text-[0.75rem] justify-center items-center"
                  onClick={showPlan}
                >
                  Edit Care Plan
                </Button>
              ) : (
                <Button
                  id={index}
                  shape="round"
                  type="primary"
                  size="middle"
                  style={{
                    background: "#3c3066",
                    border: "none",
                    width: "11rem",
                    whiteSpace: "normal",
                  }}
                  className="m-1 text-[0.75rem] justify-center items-center"
                  onClick={showPlan}
                >
                  View Care Plan
                </Button>
              )}
            </Row>
            <Row>
              <Button
                id={index}
                shape="round"
                type="primary"
                size="middle"
                style={{
                  background: "#8159f5",
                  border: "none",
                  width: "11rem",
                  whiteSpace: "normal",
                }}
                className="m-1 text-[0.75rem] justify-center items-center"
                onClick={showKb}
              >
                Resource Library
              </Button>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24} className="mt-1">
            <Row className="flex flex-row grow justify-between items-center text-[0.5rem] p-2">
              Care Team
            </Row>
            <Row>
              <Col span={24}>
                <Space className="flex flex-row justify-center bg-[#eef2f6] mx-2 rounded-md">
                  {populateTeamMembers()}
                </Space>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={12} lg={8}  className="flex justify-center p-2">
                {_.isEmpty(cg) || _.isEmpty(circle) ? (
                  <Skeleton active />
                ) : IsAdmin(cg._id, circle) || IsBackupAdmin(cg._id, circle) ? (
                  <Button
                    id={index}
                    shape="round"
                    type="primary"
                    size="middle"
                    style={{
                      background: "#4d66ff",
                      border: "none",
                      width: "11rem",
                      whiteSpace: "normal",
                    }}
                    className="m-0 text-[0.75rem] justify-center items-center"
                    onClick={manageTeam}
                  >
                    Manage Team
                  </Button>
                ) : (
                  <></>
                )}
              </Col>
              <Col xs={24} md={12} lg={8}  className="flex justify-center p-2">
                <Button
                  id={index}
                  shape="round"
                  type="primary"
                  size="middle"
                  style={{
                    background: "#3c3066",
                    border: "none",
                    width: "11rem",
                    whiteSpace: "normal",
                  }}
                  className="m-0 text-[0.75rem] justify-center items-center"
                  onClick={chatTeam}
                >
                  Chat with Team
                </Button>
              </Col>
              {!_.isEmpty(cg) &&
                !_.isEmpty(circle) &&
                IsAdmin(cg._id, circle) &&
                circle.subscription != 0 && (
                  <Col xs={24} md={12} lg={8} className="flex justify-center p-2">
                    <Button
                      id={index}
                      shape="round"
                      type="primary"
                      size="middle"
                      danger
                      style={{
                        border: "none",
                        width: "11rem",
                        whiteSpace: "nowrap",
                      }}
                      className="m-0 text-[0.75rem] justify-center items-center"
                      onClick={() => {
                        handleClickCancelSub(circle._id);
                      }}
                    >
                      Cancel Paid Subscription
                    </Button>
                  </Col>
                )}
            </Row>
          </Col>
        </Row>
      </>
    );
  };
  if (_.isEmpty(cg) || _.isEmpty(circle)) {
    return <Skeleton active />;
  }
  if (currentCircle == index) {
    return (
      <Col
        style={{
          ...styleDefault,
          ...style,
          border: "5px solid rgba(60, 48, 102, 0.3)",
        }}
      >
        {fillCircleContent()}
      </Col>
    );
  }
  return <Col style={{ ...styleDefault, ...style }}>{fillCircleContent()}</Col>;
};
export default Circle;
