import React, { useEffect, useState, useCallback } from "react";

import useMclAuth from "../../hooks/useMclAuth";
import useCg from "../../hooks/useCg";
import { mclOpen } from "../../api/Mcl";
import FeedbackFormModal from "../../components/FeedbackFormModal";
import _ from "lodash";
import {
  Button,
  Popover,
  Collapse,
  Radio,
  Space,
  Input,
  Divider,
  Checkbox,
  Row,
  Skeleton,
  Tooltip,
  notification,
  Result,
  Popconfirm,
  message,
} from "antd";
import {
  CheckCircleFilled,
  QuestionCircleFilled,
  MessageTwoTone,
  getTwoToneColor,
  setTwoToneColor,
  SmileOutlined,
} from "@ant-design/icons";
import {
  Care,
  Url,
  SAVE_TIME_PERIOD,
  categoryToPage,
  FormDestination,
} from "../../constants/Global";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import sha256 from "crypto-js/sha256";
import { debounce } from "lodash";

import "./GiverPlan.css";

const { Panel } = Collapse;
const { TextArea } = Input;

const openNotification = ({ message, status, title, subTitle }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: <Result status={status} title={title} subTitle={subTitle} />,
    placement: "top",
    duration: 6,
  });
};

const PanelState = ({ plan, setPlan, section }) => {
  const or_arr = (accumulator, curr) => accumulator || curr;

  let done = 0;
  let left = 0;

  // console.log('before done:', done, 'left:', left)
  // console.log('done:', done)

  switch (section) {
    case "c1":
      left = 6;
      done += plan[section]["q1"] || plan[section]["q1_txt"] !== "" ? 1 : 0;
      done += plan[section]["q2"] ? 1 : 0;
      done += plan[section]["q3"] || plan[section]["q3_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4_txt"] !== "" ? 1 : 0;
      done += plan[section]["q5_txt"] !== "" ? 1 : 0;
      done += plan[section]["q6_txt"] !== "" ? 1 : 0;
      break;
    case "c2":
      left = 7;
      done +=
        plan[section]["q1"].reduce(or_arr) || plan[section]["q1_txt"] !== ""
          ? 1
          : 0;
      done += plan[section]["q2_txt"] !== "" ? 1 : 0;
      done += plan[section]["q3_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4_txt"] !== "" ? 1 : 0;
      done +=
        plan[section]["q5"].reduce(or_arr) || plan[section]["q5_txt"] !== ""
          ? 1
          : 0;
      done += plan[section]["q6_txt"] !== "" ? 1 : 0;
      done += plan[section]["q7"] ? 1 : 0;
      break;
    case "c3":
      left = 8;
      done +=
        plan[section]["q1"].reduce(or_arr) || plan[section]["q1_txt"] !== ""
          ? 1
          : 0;
      done += plan[section]["q2"] ? 1 : 0;
      done += plan[section]["q3_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4_txt"] !== "" ? 1 : 0;
      done += plan[section]["q5_txt"] !== "" ? 1 : 0;
      done += plan[section]["q6_txt"] !== "" ? 1 : 0;
      done += plan[section]["q7_txt"] !== "" ? 1 : 0;
      done += plan[section]["q8"] ? 1 : 0;
      break;
    case "c4":
      left = 15;
      done += plan[section]["q1_txt"] !== "" ? 1 : 0;
      done += plan[section]["q2_txt"] !== "" ? 1 : 0;
      done += plan[section]["q3_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4a_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4b_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4c_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4d_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4e_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4f_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4g_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4h_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4i_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4j_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4k_txt"] !== "" ? 1 : 0;
      done += plan[section]["q4l_txt"] !== "" ? 1 : 0;
      break;
    case "c5":
      left = 3;
      done += plan[section]["q1"] || plan[section]["q1_txt"] !== "" ? 1 : 0;
      done +=
        plan[section]["q2"].reduce(or_arr) || plan[section]["q2_txt"] !== ""
          ? 1
          : 0;
      done += plan[section]["q3"] ? 1 : 0;
      break;
    default:
      break;
  }

  left -= done;
  // console.log('after done:', done, 'left:', left)

  return (
    // <div><Space><>{done}</><CheckCircleFilled style={{ color: 'green' }} /><>{left}</><QuestionCircleFilled style={{ color: 'orange' }} /></Space></div>
    <div>
      <Space>
        <Tooltip title="Completed plan items">
          <>{done}</>
          <> </>
          <CheckCircleFilled style={{ color: "green" }} />
        </Tooltip>
        <> </>
        <Tooltip title="Plan items for completion" placement="topRight">
          <>{left}</>
          <> </>
          <QuestionCircleFilled style={{ color: "orange" }} />
        </Tooltip>
      </Space>
    </div>
  );
};

const initialPlan = {
  type: Care.Care.INCARE,
  current: 1,
  c1: {
    q1: 0,
    q1_txt: "",
    q2: 0,
    q3: 0,
    q3_txt: "",
    q4_txt: "",
    q5_txt: "",
    q6_txt: "",
  },
  c2: {
    q1: [false, false, false, false, false],
    q1_txt: "",
    q2_txt: "",
    q3_txt: "",
    q4_txt: "",
    q5: [false, false, false],
    q5_txt: "",
    q6_txt: "",
    q7: 0,
  },
  c3: {
    q1: [false, false, false, false, false],
    q1_txt: "",
    q2: 0,
    q3_txt: "",
    q4_txt: "",
    q5_txt: "",
    q6_txt: "",
    q7_txt: "",
    q8: 0,
  },
  c4: {
    q1_txt: "",
    q2_txt: "",
    q3_txt: "",
    q4a_txt: "",
    q4b_txt: "",
    q4c_txt: "",
    q4d_txt: "",
    q4e_txt: "",
    q4f_txt: "",
    q4g_txt: "",
    q4h_txt: "",
    q4i_txt: "",
    q4j_txt: "",
    q4k_txt: "",
    q4l_txt: "",
  },
  c5: {
    q1: 0,
    q1_txt: "",
    q2: [false, false, false, false],
    q2_txt: "",
    q3: 0,
  },
};

export { initialPlan };

function GiverCare({ setPage }) {
  const mclAuth = useMclAuth();

  const { cg, planSelf, setPlanSelf, panelCurrent, setPanelCurrent } = useCg();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("GiverCare");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const [formSetting, setFormSetting] = useState({
    title: "Feedback form",
    destination: "",
    isModalVisible: false,
    showFirstName: false,
    showLastName: false,
    showEmail: false,
    showPhone: false,
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const submitForm = useCallback(
    async (formValues) => {
      let data = formValues;
      data["firstName"] = cg["firstName"];
      data["lastName"] = cg["lastName"];
      data["email"] = cg["email"];
      data["phone"] = undefined;
      data["destination"] = formSetting["destination"];
      data["fromQuestion"] = formSetting["fromQuestion"];
      console.log(data);
      setSubmitting(true);
      let recaptchaToken = await handleReCaptchaVerify();
      if (!_.isEmpty(recaptchaToken)) {
        // Get the recaptcha token, submit it with the data to the backend
        mclAuth
          .post(Url.FORMS.concat("/", "givercare"), data, {
            headers: { "Content-Type": "application/json" },
            params: {
              token: recaptchaToken,
            },
          })
          .then((res) => {
            if (res.status && res.status === 200) {
              openNotification({
                message: "Inquiry submitted",
                status: "success",
                title: "Thank you " + data.firstName + "!",
                subTitle: "We will respond as soon as we can.",
              });
            } else {
              openNotification({
                message: "Sorry",
                status: res.status ? res.status : "500",
                title: res.response.data,
              });
            }
            setSubmitting(false);
            setFormSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
            }));
          })
          .catch((error) => {
            openNotification({
              message: "Sorry",
              status: error.status ? error.status : "500",
              title: error.response.data,
            });
            setSubmitting(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        openNotification({
          message: "Sorry",
          status: "500",
          title: "Sorry, please try again later",
          subTitle: "Failed to get Recaptcha token",
        });
        setSubmitting(false);
      }
    },
    [cg, formSetting, handleReCaptchaVerify]
  );

  const savePlan = async (plan) => {
    // console.log('Saving....', plan)
    console.log("saving....");
    // plan.current = panelCurrent
    const planSelfStr = JSON.parse(JSON.stringify(plan));
    // console.log('saving to api!', mclAuth.defaults.baseURL)
    setIsSaving(true);
    mclAuth
      .post(Url.CAREGIVERS.concat("/plan"), planSelfStr)
      .then((res) => {
        // console.log('response', res.data)
      })
      .catch((error) => {});
    setIsSaving(false);
  };

  const timedSave = React.useRef(
    debounce(async (plan) => {
      savePlan(plan);
    }, SAVE_TIME_PERIOD)
  ).current;

  const onChange = (e, category, question) => {
    // console.log('checked:', e.target.value, 'category:', category, 'question:', question);
    let newValues = JSON.parse(JSON.stringify(planSelf));
    newValues[category][question] = e.target.value;
    // last worked on panel
    newValues.current = categoryToPage(category);
    setPlanSelf(newValues);
    timedSave(newValues);
    // console.log(JSON.stringify(planValues));
  };

  const onChangeNav = (e, category, question) => {
    onChange(e, category, question);
    if (category === "c5" && question === "q3" && e.target.value === 1) {
      const onClick = () => {
        // console.log(e);
        // message.success('Click on Yes');

        // open recipient plan
        setPage("2");
      };

      notification.open({
        message: "",
        duration: 0.5,
        placement: "bottomLeft",
        //closeIcon : (<div></div>),
        description: (
          <Result
            icon={<SmileOutlined />}
            title="Opening care and support plan template!"
            extra={
              <Button type="primary" onClick={() => onClick()}>
                Continue
              </Button>
            }
          />
        ),
      });
    }
  };

  const onCheck = (e, category, question, idx) => {
    console.log(`checked = ${e.target.checked}`);
    let newValues = JSON.parse(JSON.stringify(planSelf));
    newValues[category][question][idx] = e.target.checked;
    // last worked on panel
    newValues.current = categoryToPage(category);
    setPlanSelf(newValues);
    timedSave(newValues);
  };

  const collapseChange = (key) => {
    if (key !== undefined) {
      // console.log('saving:', key)
      setPanelCurrent(key);
    }
  };

  if (planSelf === undefined) {
    return <Skeleton active />;
  }

  setTwoToneColor("#8159f5");
  getTwoToneColor();

  const benefitsOfJournaling = (
    <ul>
      <li>Clarify thoughts and feelings</li>
      <li>Gain perspective on issues and problems</li>
      <li>Process experiences</li>
      <li>Capture insights</li>
      <li>Explore your intuition</li>
      <li>Bring closure to relationships and events</li>
      <li>Record learning</li>
      <li>Reduce stress, anger and fear</li>
    </ul>
  );

  return (
    <div className="p-6">
      <FeedbackFormModal
        setting={formSetting}
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        handleCancel={() => {
          setFormSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
      />
      <h1
        style={{
          fontSize: "x-large",
          fontWeight: "bold",
          color: "#30225f",
        }}
      >
        My Support Journal
      </h1>
      <div className="panel-item">
        <p />
        <p />
        <h4>
          Welcome to your <strong>private corner</strong> of My CareLinq. Here
          you will find a supportive space and guide to explore, write and
          reflect on your experience as a caregiver.
        </h4>
        <h4>
          We provided some journal prompts to help you begin documenting your
          thoughts, experience and the important work you do.
        </h4>
        <p />
        <p />
        <h4>
          Try to tune into your experience and move through those feelings as
          well as you can. If these topics bring up trauma from an earlier part
          of your life or make you feel overwhelmed, revisit at a later time.
        </h4>
        <p />
        <p />
        <h4>
          At the heart of this work, we encourage you to explore the many rich
          and deeply meaningful opportunities to engage, address unfinished
          business, discover new possibilities, and work through questions about
          how and why we do this work.
        </h4>
        <p />
        <p />
        <h4>
          This is <strong>for your eyes only</strong>. If you have any questions
          or need support, please send us a note to schedule a consultation
          here:{" "}
          <span
            onClick={() => {
              setFormSetting({
                title: "Send us a note",
                destination: FormDestination.QUESTIONS,
                fromQuestion: "GiverCare",
                isModalVisible: true,
                showFirstName: false,
                showLastName: false,
                showEmail: false,
                showPhone: false,
              });
            }}
          >
            <MessageTwoTone twoToneColor={"#8159f5"} />
          </span>
          . To read our Privacy Policy, click{" "}
          <a href="https://mycarelinq.com/privacy-policy" target="_blank">
            {/* <a href="/privacy-policy" target="_blank"> */}
            here
          </a>
          . See some benefits of journaling here{" "}
          <Popover
            color="rgba(68, 207, 242, 95%)"
            content={benefitsOfJournaling}
            title="Some Benefits of Journaling"
          >
            <MessageTwoTone twoToneColor={"#44cff2"} />
          </Popover>
          .
        </h4>
        <p />
        <p />
      </div>

      <Collapse
        onChange={collapseChange}
        accordion={true}
        defaultActiveKey={panelCurrent} /* onChange={onChange}*/
        style={{ maxWidth: "60rem" }}
      >
        <Panel
          header="Your Experience"
          key="1"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planSelf} setPlan={setPlanSelf} section="c1" />
          }
        >
          <div className="panel-item">
            <p /* style={{ fontWeight: 'normal' }} */>
              Whatever your background and circumstances may be that led you to
              this role, it’s important to reflect and document your journey.
            </p>
            <p />
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>How did you come to be a
            caregiver?
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChange(e, "c1", "q1")}
                value={planSelf.c1.q1}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>A Hospitalization (or multiple)</Radio>
                </p>
                <p>
                  <Radio value={2}>Gradual decline</Radio>
                </p>
                <p>
                  <Radio value={3}>Life event</Radio>
                </p>
                <p>
                  <Radio value={4}>Family duty</Radio>
                </p>
              </Radio.Group>
              <p />
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c1", "q1_txt")}
                value={planSelf.c1.q1_txt}
              />
            </div>
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>How long have you been a
            caregiver?
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChange(e, "c1", "q2")}
                value={planSelf.c1.q2}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>I’m preparing to be a caregiver</Radio>
                </p>
                <p>
                  <Radio value={2}>I’m just starting out</Radio>
                </p>
                <p>
                  <Radio value={3}>I've been doing this for 1-2 years</Radio>
                </p>
                <p>
                  <Radio value={4}>I've been doing this for 2-3 years</Radio>
                </p>
                <p>
                  <Radio value={5}>I've been doing this over 5 years</Radio>
                </p>
                <p>
                  <Radio value={6}>I've been doing this over 10 years</Radio>
                </p>
              </Radio.Group>
            </div>
            <p />
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>Are you caring for multiple
            people?
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChange(e, "c1", "q3")}
                value={planSelf.c1.q3}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>One person</Radio>
                </p>
                <p>
                  <Radio value={2}>Two people</Radio>
                </p>
                <p>
                  <Radio value={3}>Three or more people</Radio>
                </p>
              </Radio.Group>
              <p />
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c1", "q3_txt")}
                value={planSelf.c1.q3_txt}
              />
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>What personal life changes
            have you made as a result of being a caregiver?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c1", "q4_txt")}
                value={planSelf.c1.q4_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>What has surprised you about
            being a caregiver?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c1", "q5_txt")}
                value={planSelf.c1.q5_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>What has brought you to
            MyCareLinq?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c1", "q6_txt")}
                value={planSelf.c1.q6_txt}
              />
            </div>
          </div>
        </Panel>
        <Panel
          header="Your Health and Self-care"
          key="2"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planSelf} setPlan={setPlanSelf} section="c2" />
          }
        >
          <div className="panel-item">
            <p /* style={{ fontWeight: 'normal' }} */>
              Self-care involves looking inward and taking time to consider
              yourself to be valuable and present yourself as capable and
              deserving. Caring for others may add unnoticed emotional pain,
              stress, and suffering. This further reinforces the need to engage
              in self-care
            </p>
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>How has caregiving affected
            your health in the past year?
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q1", 0)}
                defaultChecked={planSelf.c2.q1[0]}
              >
                I’ve missed one or more personal doctor’s appointments
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q1", 1)}
                defaultChecked={planSelf.c2.q1[1]}
              >
                I’m more stressed now than in previous years
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q1", 2)}
                defaultChecked={planSelf.c2.q1[2]}
              >
                I’m not getting as much sleep as before
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q1", 3)}
                defaultChecked={planSelf.c2.q1[3]}
              >
                I’m experiencing sudden mood swings
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q1", 4)}
                defaultChecked={planSelf.c2.q1[4]}
              >
                I’m withdrawing from people
              </Checkbox>
              ;
              <p />
              Other (Please explain):
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c2", "q1_txt")}
                value={planSelf.c2.q1_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>Before you were a caregiver,
            what did you do to engage in self-care?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c2", "q2_txt")}
                value={planSelf.c2.q2_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>Today, what has interfered
            with your self-care?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c2", "q3_txt")}
                value={planSelf.c2.q3_txt}
              />
            </div>{" "}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>What do you need to
            re-engage with self-care?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c2", "q4_txt")}
                value={planSelf.c2.q4_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>What can My CareLinq do to
            support you in your self care?
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q5", 0)}
                defaultChecked={planSelf.c2.q5[0]}
              >
                I need someone to talk through my situation
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q5", 1)}
                defaultChecked={planSelf.c2.q5[1]}
              >
                I need help creating a plan for myself
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c2", "q5", 2)}
                defaultChecked={planSelf.c2.q5[2]}
              >
                I need help finding resources for myself
              </Checkbox>
              ;
              <p />
              Other (Please explain):
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c2", "q5_txt")}
                value={planSelf.c2.q5_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>Who do you turn to for
            emotional support?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c2", "q6_txt")}
                value={planSelf.c2.q6_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>Are you interested in taking
            a caregiver burnout survey to learn more about your level of
            burnout?
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChange(e, "c2", "q7")}
                value={planSelf.c2.q7}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
                <p>
                  <Radio value={3}>Maybe</Radio>
                </p>
              </Radio.Group>
              <p />
            </div>
          </div>
        </Panel>
        <Panel
          header="Scope of Work and Responsibilities"
          key="3"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planSelf} setPlan={setPlanSelf} section="c3" />
          }
        >
          <div className="panel-item">
            <p /*style={{ fontWeight: 'normal' }}*/>
              A caregiver’s role and duties vary depending on the circumstances.
              This work is about connecting with humanity and what it means to
              be human. This section is meant to serve as a place where you can
              document your role in more detail and reflect.
            </p>
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>Describe what you do for the
            person you’re caring for. Please check all that apply
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) => onCheck(e, "c3", "q1", 0)}
                defaultChecked={planSelf.c3.q1[0]}
              >
                Grocery shop
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c3", "q1", 1)}
                defaultChecked={planSelf.c3.q1[1]}
              >
                Provide transportation
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c3", "q1", 2)}
                defaultChecked={planSelf.c3.q1[2]}
              >
                Coordinate social engagements
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c3", "q1", 3)}
                defaultChecked={planSelf.c3.q1[3]}
              >
                Coordinate medical appointments
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c3", "q1", 4)}
                defaultChecked={planSelf.c3.q1[4]}
              >
                Purchase personal care items
              </Checkbox>
              ;
              <p />
              Other (Please explain):
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c3", "q1_txt")}
                value={planSelf.c3.q1_txt}
              />
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>How many hours a week are
            you providing care?
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChange(e, "c3", "q2")}
                value={planSelf.c3.q2}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Less than 5 hours per week</Radio>
                </p>
                <p>
                  <Radio value={2}>About 10 hours per week</Radio>
                </p>
                <p>
                  <Radio value={3}>About 20 hours per week</Radio>
                </p>
                <p>
                  <Radio value={4}>More than 20 hours per week</Radio>
                </p>
              </Radio.Group>
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>Is there any aspect of
            caregiving that you wish you didn’t have to do?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c3", "q3_txt")}
                value={planSelf.c3.q3_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>Do you spend your own money
            and resources to care for this person?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c3", "q4_txt")}
                value={planSelf.c3.q4_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>What is the hardest part
            about being a caregiver?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c3", "q5_txt")}
                value={planSelf.c3.q5_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>What are the positive
            aspects of being a caregiver?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c3", "q6_txt")}
                value={planSelf.c3.q6_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>If caregiving was easy, what
            would that look like?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c3", "q7_txt")}
                value={planSelf.c3.q7_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">8.)</div>Do you have a team member to
            help carry out duties and responsibilities?
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChange(e, "c3", "q8")}
                value={planSelf.c3.q8}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
                <p>
                  <Radio value={3}>I need help with this </Radio>
                </p>
              </Radio.Group>
              <p />
            </div>
          </div>
        </Panel>
        <Panel
          header="Questions about being a caregiver"
          key="4"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planSelf} setPlan={setPlanSelf} section="c4" />
          }
        >
          <div className="panel-item">
            <p /*style={{ fontWeight: 'normal' }}*/>
              Some caregivers might seem like they have all the answers. But the
              reality is, a majority of family caregivers have lots of
              questions. In this section, you have the space to write down your
              questions, worries, concerns and wishes.
            </p>
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>Does anything worry you
            about the days ahead?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q1_txt")}
                value={planSelf.c4.q1_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>Are there any fears you have
            about what could, should, or might happen?
            <p />
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q2_txt")}
                value={planSelf.c4.q2_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>As a caregiver, has your
            experience brought up any questions about your mortality and how you
            want to spend your time?
            <div className="panel-subitem">
              Please explain....
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q3_txt")}
                value={planSelf.c4.q3_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>In this section, please
            document any questions or any topics you would like to learn more
            about
            <p />
            <div className="panel-subitem">
              Diagnosis:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4a_txt")}
                value={planSelf.c4.q4a_txt}
              />
              <p />
              <p />
              Placement (Assisted living, SNF, CCRC):
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4b_txt")}
                value={planSelf.c4.q4b_txt}
              />
              <p />
              <p />
              End-of-life:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4c_txt")}
                value={planSelf.c4.q4c_txt}
              />
              <p />
              <p />
              Hospice:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4d_txt")}
                value={planSelf.c4.q4d_txt}
              />
              <p />
              <p />
              Death Doulas:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4e_txt")}
                value={planSelf.c4.q4e_txt}
              />
              <p />
              <p />
              Final arrangements:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4f_txt")}
                value={planSelf.c4.q4f_txt}
              />
              <p />
              <p />
              Alternative burial options:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4g_txt")}
                value={planSelf.c4.q4g_txt}
              />
              <p />
              <p />
              After-life care:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4h_txt")}
                value={planSelf.c4.q4h_txt}
              />
              <p />
              <p />
              Legacy:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4i_txt")}
                value={planSelf.c4.q4i_txt}
              />
              <p />
              <p />
              Processing grief:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4j_txt")}
                value={planSelf.c4.q4j_txt}
              />
              <p />
              <p />
              Legal:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4k_txt")}
                value={planSelf.c4.q4k_txt}
              />
              <p />
              <p />
              Specific questions:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c4", "q4l_txt")}
                value={planSelf.c4.q4l_txt}
              />
              <p />
            </div>
          </div>
        </Panel>
        <Panel
          header="Getting Organized"
          key="5"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planSelf} setPlan={setPlanSelf} section="c5" />
          }
        >
          <div className="panel-item">
            <p /*style={{ fontWeight: 'normal' }}*/>
              Caregivers face a number of responsibilities and duties. If you
              are feeling overwhelmed and lost, creating a structure and getting
              organized is a great first step to seeking relief. These sets of
              questions are designed to help you gain more control over your
              caregiving duties
            </p>
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>How do you organize your
            caregiving tasks, documents, and resources? (i.e. calendars, alerts,
            tasklist)
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChange(e, "c5", "q1")}
                value={planSelf.c5.q1}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>I keep track of everything in my head</Radio>
                </p>
                <p>
                  <Radio value={2}>I keep a separate tracking document</Radio>
                </p>
                <p>
                  <Radio value={3}>
                    I use digital tools and keep hardcopies of documents
                  </Radio>
                </p>
              </Radio.Group>
              <p />
              Other:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c5", "q1_txt")}
                value={planSelf.c5.q1_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>What tools would make this
            process easier for you?
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) => onCheck(e, "c5", "q2", 0)}
                defaultChecked={planSelf.c5.q2[0]}
              >
                Coordinating communications across family, friends, community,
                medical staff, providers
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c5", "q2", 1)}
                defaultChecked={planSelf.c5.q2[1]}
              >
                Sending service requests directly to service providers
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c5", "q2", 2)}
                defaultChecked={planSelf.c5.q2[2]}
              >
                Direct purchasing of personal care products
              </Checkbox>
              ;
              <p />
              <Checkbox
                onChange={(e) => onCheck(e, "c5", "q2", 3)}
                defaultChecked={planSelf.c5.q2[3]}
              >
                Money management tools
              </Checkbox>
              ;
              <p />
              Other (Please explain):
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => onChange(e, "c5", "q2_txt")}
                value={planSelf.c5.q2_txt}
              />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>My CareLinq has provided you
            with a care and support plan template designed for caregivers who
            are managing other people’s care and support. Are you ready to check
            it out?
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => onChangeNav(e, "c5", "q3")}
                value={planSelf.c5.q3}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes, take me there</Radio>
                </p>
                <p>
                  <Radio value={2}>
                    I have some questions before getting started{" "}
                  </Radio>
                </p>
                <p>
                  <Radio value={3}>No, I’m not ready</Radio>
                </p>
                <p>
                  <Radio value={4}>
                    I’m not interested in using the care plan template
                  </Radio>
                </p>
              </Radio.Group>
              <p />
            </div>
          </div>
        </Panel>
      </Collapse>
      {/* <Row>
        <h6
          style={{
            backgroundColor: "rgba(48, 34, 95, 70%)",
            textAlign: "center",
            position: "fixed",
            left: "0",
            bottom: "0",
            height: "1rem",
            width: "100%",
            color: "white",
          }}
        >
          Copyright &copy; 2021, 2022{" "}
          <a href="https://mycarelinq.com" style={{ color: "#e0551b" }}>
            My CareLinq, Inc
          </a>
          . All Rights Reserved
        </h6>
      </Row> */}
    </div>
  );
}

export default GiverCare;
