import React, { useRef, useState, useEffect } from "react";
import _, { last } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Row, Col, Input, Dropdown, Button, Menu } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import MessageItem from "./MessageItem";
import ChatAvatarIcon from "./ChatAvatarIcon";
import MessageContainer from "./MessageContainer";
import {
  DEFAULT_MESSAGE_USER_ICON,
  MESSAGE_CHAT_TYPE,
} from "../constants/Global";
import moment from "moment";

const MessagingSection = ({
  chatListItems,
  selectedChatListId,
  appendMessage,
  showExpandMenu = true,
  toggleRightPanel = () => {
    console.log("toggleRightPanel");
  },
}) => {
  const menuOnClick = (e) => {
    if (e?.key === "participants") {
      toggleRightPanel();
    }
  };
  const menu = (
    <Menu
      items={[{ label: "Participants", key: "participants" }]}
      onClick={menuOnClick}
    />
  );

  const [selectedChatListItem, setSelectedChatListItem] = useState({});
  const [messageItems, setMessageItems] = useState({});
  const [inputText, setInputText] = useState("");
  const scrollRef = useRef();

  const createMessageItem = (user, message, prevMessage, type) => {
    let messageUIItem = {
      _id: message._id,
      messages: message.txt,
      senderId: message.sender._id,
      senderName: message.senderName,
      avatar: message.avatar,
      sentAt: moment(message.sentAt),
      status: message.status,
      showAvatar: true,
      showSenderName: true,
      sentByUser: false,
      showStatus: false,
      showSentAt: true,
    };

    // If is group chat, show sender name
    if (type === MESSAGE_CHAT_TYPE.GROUP) {
      messageUIItem.showSenderName = true;
    }

    // Check if the message is sent by the same person for the previous message
    // and also sent at the same minute, if so, hide the avatar and name
    if (
      !_.isNil(prevMessage) &&
      message.sender._id === prevMessage.sender._id &&
      messageUIItem.sentAt.diff(moment(prevMessage.sentAt), "minute") === 0
    ) {
      messageUIItem.showAvatar = "hidden";
      messageUIItem.showSenderName = false;
    }

    // If the message is sent by current user
    if (message.sender._id === user._id) {
      messageUIItem.showAvatar = false;
      messageUIItem.showSenderName = false;
      messageUIItem.sentByUser = true;
      messageUIItem.showStatus = true;
    }

    return messageUIItem;
  };

  const initializeMessageItems = (chatListItem, type) => {
    if (_.isEmpty(chatListItem)) {
      return {};
    }

    let messages = chatListItem.messages;
    let messageItemsByDate = {};
    // Example:
    // {
    //   "01.04.2022": [...messageitem],
    //   "01.05.2022": [...messageitem],
    // }
    for (let i = 0; i < messages.length; i++) {
      let currentMessage = messages[i];
      let prevMessage = i > 0 ? messages[i - 1] : undefined;
      let messageItem = createMessageItem(
        chatListItem.user,
        currentMessage,
        prevMessage,
        type
      );

      let dateKey = messageItem.sentAt.format("MM.DD.YYYY");
      if (dateKey in messageItemsByDate) {
        messageItemsByDate[dateKey].push(messageItem);
      } else {
        messageItemsByDate[dateKey] = [messageItem];
      }
    }
    return messageItemsByDate;
  };

  useEffect(() => {
    if (!_.isEmpty(chatListItems) && selectedChatListId) {
      let selectedItem = chatListItems.find(
        (item) => item._id === selectedChatListId
      );
      setSelectedChatListItem(selectedItem);
      setMessageItems(initializeMessageItems(selectedItem));
    }
  }, [chatListItems, selectedChatListId]);

  useEffect(() => {
    if (!_.isEmpty(selectedChatListItem)) {
      setMessageItems(initializeMessageItems(selectedChatListItem));
    }
  }, [selectedChatListItem]);

  useEffect(() => {
    if (!_.isEmpty(messageItems)) {
      // Scroll to bottom if the lastest message is sent by user.
      let keys = Object.keys(messageItems).sort(function (b, a) {
        return moment(a).toDate() - moment(b).toDate();
      });
      let latestKey = !_.isEmpty(keys) ? keys[0] : undefined;
      let latestMessage = !_.isEmpty(messageItems[latestKey])
        ? messageItems[latestKey][messageItems[latestKey].length - 1]
        : undefined;
      if (!_.isNil(latestMessage) && latestMessage?.sentByUser) {
        scrollRef.current?.scrollIntoView({ behavior: "auto" });
      }
    }
  }, [messageItems]);

  const sendMessage = (e) => {
    if (_.isEmpty(inputText) || _.isNil(inputText)) {
      return;
    }
    e.preventDefault();
    // Submit message
    appendMessage(inputText);
    setInputText("");
  };

  return (
    <Col flex="4 0 294px">
      {!_.isEmpty(selectedChatListItem) && (
        <>
          {/* Chatbox top */}
          <Row
            className="
                  h-[64px] 
                  gap-3
                  border 
                  bg-white
                  border-[#e2e2e2]
                  border-solid
                  items-center
                  p-4
                "
            style={{
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.05)",
            }}
          >
            <ChatAvatarIcon
              size={32}
              Icon={
                !_.isNil(selectedChatListItem?.icon)
                  ? selectedChatListItem.icon
                  : DEFAULT_MESSAGE_USER_ICON
              }
              iconStyle={{
                color: "#EBEBEB",
              }}
              avatarStyle={{
                background:
                  "linear-gradient(146.51deg, #6D56BE 12.17%, #3C3066 100.04%)",
                border: "1px solid rgba(60, 48, 102, 0.6)",
              }}
            />
            <div className="flex flex-col">
              <span
                className="
                    text-sm
                    leading-4
                    mb-0
                    truncate
                    font-bold
                    text-[#0C0720]
                  "
              >
                {selectedChatListItem.name}
              </span>
              { selectedChatListItem.name === 'Support' ?
                <span>
                  You may send us a message or give us a call at (408) 214-2416
                </span>
                : null
              }
              {/* Circle name */}
              {selectedChatListItem.showGroupName && (
                <span
                  className="
                      text-xs
                      leading-4
                      mb-0
                      truncate
                      font-bold
                      text-[#0F062D]/60
                  "
                >
                  {`${_.get(
                    selectedChatListItem,
                    "circle.firstName",
                    ""
                  )} ${_.get(
                    selectedChatListItem,
                    "circle.lastName",
                    ""
                  )} - Care Team`}
                </span>
              )}
            </div>
            {showExpandMenu && (
              <div className="ml-auto">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomRight"
                  overlayStyle={{ minWidth: 150 }}
                >
                  <Button shape="circle" type="text">
                    <MoreOutlined />
                  </Button>
                </Dropdown>
              </div>
            )}
          </Row>
          {/* Chatbox content */}
          <Row
            className="bg-[#f0f2f5]"
            style={{
              height: "calc(100vh - 210px)",
            }}
          >
            <div className="flex-1 h-full py-6 px-4">
              <div
                ref={scrollRef}
                className="flex flex-col-reverse grow shrink h-full overflow-y-auto"
              >
                {Object.keys(messageItems)
                  .sort(function (b, a) {
                    return moment(a).toDate() - moment(b).toDate();
                  })
                  .map((dateKey) => {
                    return (
                      <MessageContainer key={dateKey} datetime={dateKey}>
                        {messageItems[dateKey].map((item) => (
                          <MessageItem
                            key={item._id}
                            {...item}
                            ref={scrollRef}
                          />
                        ))}
                      </MessageContainer>
                    );
                  })}
              </div>
            </div>
          </Row>
          <Row className="bg-[#f0f2f5] h-[80px] pt-2.5 px-4 pb-6">
            <div className="flex grow h-[46px] rounded-[100px]">
              <Input
                id="messaging-input-box"
                className="rounded-[100px] bg-[#0A1936]/5 px-4 py-2"
                placeholder="Type in your message..."
                style={{
                  color: "rgba(0, 0, 0, 0.6)",
                }}
                value={inputText}
                onChange={(e) => {
                  setInputText(e.target.value);
                }}
                onPressEnter={sendMessage}
              ></Input>
            </div>
          </Row>
        </>
      )}
    </Col>
  );
};

export default MessagingSection;
