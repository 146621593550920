import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";

import { Breadcrumb, Col, Row, Space, Pagination } from "antd";
import { Navbar } from "../../components/Navbar";
import { LibraryArticleSection } from "../../components/LibraryComponents";
import { PurpleDivider } from "../../components/Various";
import ArticleWidget from "../../components/ArticleWidget";
import ArticleCategories from "../../components/ArticleCategories";
import FeatureArticles from "../../components/FeatureArticles";

import {
  LIBRARY_CATEGORIES,
  DEMO_ARTICLES,
  DEMO_GET_ARTICLE_BY_CATEGORY,
  Url,
} from "../../constants/Global";
import { mclOpen } from "../../api/Mcl";

import Footer from "../../components/Footer";
import _ from "lodash";

export const ArticleLibrary = () => {
  const navigate = useNavigate();
  let { category } = useParams();

  const [articles, setArticles] = useState([]);
  const [categoryItem, setCategoryItem] = useState({});
  const [totalArticles, setTotalArticles] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  let scrollToTop = () => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "auto", // <-- The scrolling happens in a single jump
    });
  };

  const getArticlesByCategory = async (category, queryParameters) => {
    let url = !_.isNil(queryParameters)
      ? Url.ARTICLES.concat("/", category, queryParameters)
      : Url.ARTICLES.concat("/" + category);
    mclOpen
      .get(url)
      .then((res) => {
        setTotalArticles(_.isObject(res.data) ? res.data.totalArticles : 0);
        setCategoryItem(_.isObject(res.data) ? res.data.category : {});
        setArticles(_.isObject(res.data) ? res.data.articles : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getArticles = (queryParameters) => {
    let url = !_.isNil(queryParameters)
      ? Url.ARTICLES.concat("/", queryParameters)
      : Url.ARTICLES.concat("/");
    mclOpen
      .get(url)
      .then((res) => {
        setTotalArticles(_.isObject(res.data) ? res.data.totalArticles : 0);
        setArticles(_.isObject(res.data) ? res.data.articles : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setTotalArticles(0);
    setCurrentPage(1);
  }, [category]);

  useEffect(() => {
    if (currentPage) {
      scrollToTop();
    }
  }, [currentPage]);

  useEffect(() => {
    let pageQueryParameter = !_.isNil(currentPage)
      ? "?pg=" + currentPage
      : undefined;
    if (category) {
      getArticlesByCategory(category, pageQueryParameter);
    } else {
      getArticles(pageQueryParameter);
    }
  }, [currentPage, category]);

  return (
    <main>
      <Navbar signin={true} navigate={navigate} />
      <Row className="px-3">
        <Breadcrumb separator=">">
          <Breadcrumb.Item>Library</Breadcrumb.Item>
          <Breadcrumb.Item>Caregiver Resources</Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Row className="p-1">
        <Col
          xs={24}
          className="flex items-center justify-center"
        // style={{ borderBottom: "1.4px solid #8159f580" }}
        >
          <h1 className="xl:text-[3rem] lg:text-[2rem] md:text-[1.5rem] sm:text-[1rem] xs:text-[0.8rem] text-center text-primary-purple">
            Caregiver Resources
          </h1>
        </Col>
      </Row>
      {category == undefined && (
        <div className="mt-8 lg:container lg:mx-auto flex flex-col px-4 justify-center whitespace-normal">
          <h2 className="xl:text-[1.75rem] lg:text-[1.5rem] md:text-[1.25rem] sm:text-[0.8rem] xs:text-[0.75rem] text-center text-primary-purple mt-0">
            Featured Articles
          </h2>
          <FeatureArticles />
        </div>
      )}

      <div className="mt-8 lg:container lg:mx-auto flex flex-col px-4 justify-center whitespace-normal">
        {/* <LibraryFeaturedSection /> */}
        <Row className="mb-32" gutter={[48, 24]}>
          <Col xs={24} lg={16}>
            <LibraryArticleSection articles={articles} />
            <div className="justify-center flex">
              <Pagination
                current={currentPage}
                pageSize={10}
                total={totalArticles}
                onChange={(page, totalArticles) => {
                  setCurrentPage(page);
                }}
              />
            </div>
          </Col>
          <Col xs={24} lg={8}>
            <Space
              direction="vertical"
              size="middle"
              style={{ display: "flex" }}
            >
              <ArticleWidget
                categories={
                  _.isNil(categoryItem?._id) ? undefined : [categoryItem._id]
                }
              />
              <ArticleCategories />
            </Space>
          </Col>
        </Row>
      </div>
      <Footer />
    </main>
  );
};