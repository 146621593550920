import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import ReactGA from "react-ga4";

import {Helmet} from "react-helmet"; 

import { Col, Row, Card, Typography } from "antd";
import { Navbar } from "../../components/Navbar";
import Footer from "../../components/Footer";

import "./AboutUsv2.css";

import BullseyeLogo from "../../assets/images/about-us/bullseye.svg";
import PrivacyLogo from "../../assets/images/about-us/protecting-privacy-image.svg";
import HumanLogo from "../../assets/images/about-us/being-human-image.svg";
import ThoughfulLogo from "../../assets/images/about-us/being-thoughtful-image.svg";
import AuthenticLogo from "../../assets/images/about-us/being-authentic-image.svg";
import DrivenLogo from "../../assets/images/about-us/being-driven-image.svg";
import ConciergesImg1 from "../../assets/images/about-us/concierges-first-image.png";
import ConciergesImg2 from "../../assets/images/about-us/concierges-second-image.png";
import ConciergesImg3 from "../../assets/images/about-us/concierges-third-image.png";

import CEO from "../../assets/images/team/MinPomper_CEOv2.png";
import CTO from "../../assets/images/team/AlexeiCTO.png";
import badgeKaiser from "../../assets/images/team/badge-kaiser.png";
import badgeStanford from "../../assets/images/team/badge-stanford.png";
import badgeFreed from "../../assets/images/team/badge-freed.png";
import badgeVitas from "../../assets/images/team/badge-vitas.png";
import badgeInelda from "../../assets/images/team/badge-inelda.png";
import badgeAkamai from "../../assets/images/team/badge-akamai.png";
import badgeLokker from "../../assets/images/team/badge-lokker.png";
import badgeCisco from "../../assets/images/team/badge-cisco.png";
import badgeCache from "../../assets/images/team/badge-cache.png";
import badgeEricsson from "../../assets/images/team/badge-ericsson.png";
import badgeCdnet from "../../assets/images/team/badge-cdnet.png";

import teamDF from "../../assets/images/team/team-df.jpg";
import teamAS from "../../assets/images/team/team-as.jpg";
import teamJH from "../../assets/images/team/team-jh.jpg";
import teamLA from "../../assets/images/team/team-la.jpg";
import teamMM from "../../assets/images/team/team-mm.jpg";
import teamBW from "../../assets/images/team/team-bw.jpg";
import teamVI from "../../assets/images/team/team-vi.jpg";
import teamEM from "../../assets/images/team/team-em.jpg";


const { Meta } = Card;
const { Title, Text } = Typography;

const LinkedIn = ({ to, children }) => {

  return (
    <Link
      // className="nav-link text-base"
      className="text-highlight-cyan"
      //style={{ color: "black" }}
      to={to}
      onClick={(event) => {
        event.preventDefault();
        window.open(to, "_blank");
        // window.scrollTo({
        //   left: 0,
        //   top: 0,
        //   behavior: "auto",
        // });
      }}
    >
      LinkedIn
    </Link>
  );
};

const BioList = ({children}) =>{
  return (
    <ul class="bio">
      {children}
    </ul>
  )
}

const Bio = ({bio}) =>{
  return (
    <ul class="bio">
    {
      bio.map((desc) => {
        return (<li>{desc}</li>);
      })
    
    }
    </ul>
  )
}


const Member = ({member}) =>{
  const { name, image, linkedin, bio} = member;

  return (
    <Card 
      className="team-newcard xxl:m-24 xl:m-19 m-6 bg-purple-50"
      bordered={false}
      // style={"border-radius: 50%"}
      cover={<img alt={name} src={image} style={{borderRadius: "50%", height: "10em", width: "10em"}}/>}    
    >
      <div>
        <h4>{name}</h4>
        <LinkedIn to={linkedin}/>
        <Bio bio={bio}/>
      </div>
    </Card>
  )
}

const members = [
  {
    name: "David Frazee",
    image: teamDF,
    linkedin: "https://www.linkedin.com/in/david-frazee-592364",
    bio: [
      "Managing Partner at Richmond Global Ventures",
      "CEO at Demystifying Silicon Valley"
    ]
  },
  {
    name: "Arlene Singer",
    image: teamAS,
    linkedin: "https://www.linkedin.com/in/arlene-singer-8b947b3",
    bio: [
      "Entrepreneur",
      "Former VP Wellness",
      "CEO Well Call, Inc."
    ]
  },
  {
    name: "Justin Hammergren",
    image: teamJH,
    linkedin: "https://www.linkedin.com/in/justin-hammergren",
    bio: [
      "Head of FP&A",
      "Google | Looker | Apple | Lightmatter"
    ]
  },
  {
    name: "Lisa Ackerman",
    image: teamLA,
    linkedin: "https://www.linkedin.com/in/lisaackermanprofile",
    bio: [
      "Organization Design, Ops",
      "Specializing in growth early-stage startups to maturity"
    ]
  },
  {
    name: "Maryann McCarthy",
    image: teamMM,
    linkedin: "https://www.linkedin.com/in/maryann-mccarthy-b9188373",
    bio: [
      "Family Caregiver Advocate",
      "Clinical Psychologist",
      "Health Coach"
    ]
  },
  {
    name: "Blake Watts",
    image: teamBW,
    linkedin: "https://www.linkedin.com/in/theblakewatts",
    bio: [
      "Channel Partnerships Leader",
      "Mercer | Buck | Ochsner"
    ]
  },
  {
    name: "Vera Imper",
    image: teamVI,
    linkedin: "https://www.linkedin.com/in/vera-imper-b21a926b",
    bio: [
      "Senior Executive of Corporate Business Development",
    ]
  },
  {
    name: "Eric Mann",
    image: teamEM,
    linkedin: "https://www.linkedin.com/in/ericmann",
    bio: [
      "Head of Marketing | B2B Marketing Expert",
      "Imagen | MedArrive | Vori"
    ]
  },
]


const PrinciplesItem = ({ logo, header = "", text = "" }) => {
  return (
    <Col xs={24} sm={12} md={8}>
      <div className="flex flex-auto flex-col self-stretch gap-2 text-center">
        <div>
          <img
            src={logo}
            className="w-full max-h-[165px]"
            alt="Protecting privacy logo"
          />
        </div>
        <h4 className="text-[1.7rem] font-normal mt-2 leading-none text-primary-purple">
          {header}
        </h4>
        <p className="text-base text-primary-purple">{text}</p>
      </div>
    </Col>
  );
};

export const AboutUs = () => {
  const navigate = useNavigate();

  // let principleItemLine = [
  //   {
  //     key: "privacy-item",
  //     logo: PrivacyLogo,
  //     header: (
  //       <span>
  //         PROTECTING <br /> PRIVACY
  //       </span>
  //     ),
  //     text: (
  //       <span>
  //         We are committed to our customers’ privacy, and back that up with{" "}
  //         <strong>end-to-end encryption and a unique system </strong> where the
  //         access keys are created and controlled on your own devices. Nobody but
  //         you – and the people you personally invite and authenticate – can
  //         access your data.
  //       </span>
  //     ),
  //   },
  //   {
  //     key: "human-item",
  //     logo: HumanLogo,
  //     header: (
  //       <span>
  //         BEING <br /> HUMAN
  //       </span>
  //     ),
  //     text: (
  //       <span>
  //         We connect on a human level. We embrace the rhythms and complexity of
  //         the world, seeking to understand the diverse cultures we live in. We
  //         build
  //         <strong>
  //           {" "}
  //           relationship through respectful personal connections.
  //         </strong>{" "}
  //         By caring for and journeying with caregivers, we protect each other,
  //         and learn with and from one another.
  //       </span>
  //     ),
  //   },
  //   {
  //     key: "thoughtful-item",
  //     logo: ThoughfulLogo,
  //     header: (
  //       <span>
  //         BEING <br /> THOUGHTFUL
  //       </span>
  //     ),
  //     text: (
  //       <span>
  //         We{" "}
  //         <strong>pay close attention to our customers and their needs.</strong>{" "}
  //         We are focused, data driven and continually analyze and evaluate our
  //         work. We are aware of institutional barriers and work to find
  //         openings. We also recognize that we do not have all the answers and
  //         welcome different perspectives to promote new ideas and understanding.{" "}
  //       </span>
  //     ),
  //   },
  //   {
  //     key: "authentic-item",
  //     logo: AuthenticLogo,
  //     header: (
  //       <span>
  //         BEING <br /> AUTHENTIC
  //       </span>
  //     ),
  //     text: (
  //       <span>
  //         We build trust. We do this work because it is human and deeply
  //         important. We encourage dialogue about sensitive or controversial
  //         topics with sincerity and with an open mind.{" "}
  //         <strong>
  //           Being authentic is a journey and trust is a destination.
  //         </strong>{" "}
  //         We will meet you where you are and journey with you.
  //       </span>
  //     ),
  //   },
  //   {
  //     key: "driven-item",
  //     logo: DrivenLogo,
  //     header: (
  //       <span>
  //         BEING <br /> DRIVEN
  //       </span>
  //     ),
  //     text: (
  //       <span>
  //         We are high achievers. We
  //         <strong>
  //           {" "}
  //           work as a team to deliver maximum value to customers.
  //         </strong>{" "}
  //         We focus our time and attention to our teammates, partners, and allies
  //         that are most important to our collective success.
  //       </span>
  //     ),
  //   },
  // ];

  return (
    <main>
      <Helmet>
          <meta charSet="utf-8" />
          <title>My CareLinq - About Us</title>
          <meta name="description" content="My CareLinq is dedicated to empowering employees who serve as family caregivers to take control of their needs, now and in the future,by delivering expert, connected, next-generation caregiving solutions with a powerfully human touch." />
          <link rel="canonical" href="https://mycarelinq.com/about-us" />
      </Helmet>
      <Navbar signin={true} navigate={navigate} />
      {/* Mission */}
      <section className="bg-[url('assets/images/about-us/background.png')] min-h-[300px] bg-center bg-no-repeat bg-cover overflow-hidden flex">
        <div className="lg:container lg:mx-auto my-2 mt-10">
          <Row>
            {/* <Col xs={24} sm={8} className="flex items-center justify-center">
              <div>
                <img
                  src={BullseyeLogo}
                  className="w-full max-w-[190px] max-h-[190px]"
                  alt="Mission"
                />
              </div>
            </Col> */}
            {/* <Col xs={24} sm={16}> */}
            <Col xs={8}>
              <h1 className="text-primary-purple text-center xs:text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] lg:text-[3rem] mt-5 pt-0 mb-0 pb-0">
                Mission
              </h1>
            </Col>
            <Col xs={16}>
              <div className="flex flex-auto flex-col self-stretch my-8 text-center items-center justify-center">
                <p
                  className="
                    xs:px-3 sm:px-5 md:px-16 lg:px-20 xl:px-24
                    text-xl
                    font-light
                    text-primary-purple"
                >
                  My CareLinq is dedicated to empowering employees who serve as 
                  family caregivers to take control of their needs, now and in the future, 
                  by delivering expert, connected, next-generation{" "} 
                  <strong className="font-bold">
                  caregiving solutions with a powerfully human touch.
                  </strong>
                  <br />
                  <br />
                  We want to make it all easier for anyone travelling the emotionally, 
                  physically and logistically complex path of receiving or giving care.{" "} 
                  <strong className="font-bold">
                  That’s our why.
                  </strong>
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      {/* Management Team */}
      <section className="bg-purple-50 pb-20">
      <Row className="ml-10 mb-10 pb-10 justify-center">
              <h1 className="mt-10 pt-20 text-primary-purple text-center xs:text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] lg:text-[3rem] mt-5 pt-0 mb-0 pb-0">
                Meet Our Team
              </h1>
      </Row>
      <Row className="pb-15">
        <Col md={2}></Col>
        <Col md={9}>
          <Card
            className="team-newcard xxl:m-24 xl:m-19 m-6 bg-purple-50"
            // style={{
            //   margin: "0",
            //   padding: "0",
            // }}
            // hoverable
            // onMouseEnter={() => {
            // onClick={() => {
            //   ReactGA.event({
            //     category: "team",
            //     action: "click_management_bio",
            //     label: "test",
            //   });
            //   // showBio(name, title, desc, bio, placement, calendar);
            // }}
            bordered={false}
            // style={"border-radius: 50%"}
            cover={
              <div>
              <Row>
                <Col md={10}>
                <img alt={"Min"} src={CEO} style={{borderRadius: "50%", height: "12em"}}/>
                </Col>
                <Col md={14}>
                  <Row>
                    <h3><strong>Min Pomper</strong></h3>
                  </Row>
                  <Row>
                    <h4>CEO & Founder</h4>
                  </Row>    
                  <Row className="mt-2 sm:text-[0.15rem] sm:text-[0.3rem] md:text-[0.55rem] lg:text-[0.8rem]">
                    Min is a health care operations leader with expertise in care delivery systems 
                    and care delivery innovation. She founded My CareLinq after being a family caregiver 
                    while also working a full-time job.  She's also a trained Death Doula. 
                  </Row>             
                </Col>
              </Row>
              </div>
            }
          >
            <div className="mt-3">
            <Row>
              <Col md={8}>
                <img src={badgeKaiser} style={{width: "8em"}}/>
              </Col>
              <Col md={8}>
                <img src={badgeStanford} style={{width: "8em"}}/>
              </Col>
              <Col md={8}>
                <img src={badgeFreed} style={{width: "8em"}}/>
              </Col>
            </Row>
            <Row>
              <Col md={4}></Col>
              <Col md={8}>
                <img src={badgeVitas} style={{width: "8em"}}/>
              </Col>
              <Col md={8}>
                <img src={badgeInelda} style={{width: "8em"}}/>
              </Col>
              <Col md={4}></Col>
            </Row>
            </div>
            {/* <Meta
              style={{ textAlign: "center" }}
              title={
                <Title style={{ color: "black" }} level={5}>
                  {"Min"}
                </Title>
              }
              description={
                <Text style={{ color: "black" }} level={6}>
                  {"CEO & Founder"}
                </Text>
              }
            /> */}
          </Card>
        </Col>
        <Col md={1}></Col>
        <Col md={9}>
          <Card
            className="team-newcard xxl:m-24 xl:m-19 m-6 bg-purple-50"
            // style={{
            //   margin: "0",
            //   padding: "0",
            // }}
            // hoverable
            // onMouseEnter={() => {
            onClick={() => {
              ReactGA.event({
                category: "team",
                action: "click_management_bio",
                label: "test",
              });
              // showBio(name, title, desc, bio, placement, calendar);
            }}
            bordered={false}
            // style={"border-radius: 50%"}
            cover={
              <div>
              <Row>
                <Col md={10}>
                <img alt={"Alexei"} src={CTO} style={{borderRadius: "50%", height: "12em"}}/>
                </Col>
                <Col md={14}>
                  <Row>
                    <h3><strong>Alexei Tumarkin</strong></h3>
                  </Row>
                  <Row>
                    <h4>CTO, Co-Founder</h4>
                  </Row>    
                  <Row className="mt-2 sm:text-[0.15rem] sm:text-[0.3rem] md:text-[0.55rem] lg:text-[0.8rem]">
                    Dr. Alexei Tumarkin is an engineering leader with a proven track record of building teams 
                    and delivering enterprise software. He is known for his contributions to Internet technologies 
                    and security as well as extensive research in AI/ML applications.  
                  </Row>             
                </Col>
              </Row>
              </div>
            }
          >
            <div className="mt-3">
            <Row>
              <Col md={8}>
                <img src={badgeAkamai} style={{width: "8em"}}/>
              </Col>
              <Col md={8}>
                <img src={badgeLokker} style={{width: "8em"}}/>
              </Col>
              <Col md={8}>
                <img src={badgeCisco} style={{width: "8em"}}/>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <img src={badgeCache} style={{width: "8em"}}/>
              </Col>
              <Col md={8}>
                <img src={badgeEricsson} style={{width: "8em"}}/>
              </Col>
              <Col md={8}>
                <img src={badgeCdnet} style={{width: "8em"}}/>
              </Col>
            </Row>
            </div>
            {/* <Meta
              style={{ textAlign: "center" }}
              title={
                <Title style={{ color: "black" }} level={5}>
                  {"Min"}
                </Title>
              }
              description={
                <Text style={{ color: "black" }} level={6}>
                  {"CEO & Founder"}
                </Text>
              }
            /> */}
          </Card>
        </Col>
        <Col md={2}></Col>
      </Row>
      </section>
      <section className="bg-purple-50 pb-20">
        <Row>
          <Col md={2}/>
          <Col md={5}>
            <Member member={members[0]}/>
          </Col>
          <Col md={5}>
            <Member member={members[1]}/>
          </Col>
          <Col md={5}>              
            <Member member={members[2]}/>
          </Col>
          <Col md={5}>
            <Member member={members[3]}/>
          </Col>
          <Col md={2}></Col>
        </Row>
        <Row>
          <Col md={2}/>
          <Col md={5}>
            <Member member={members[4]}/>
          </Col>
          <Col md={5}>
            <Member member={members[5]}/>
          </Col>
          <Col md={5}>              
            <Member member={members[6]}/>
          </Col>
          <Col md={5}>
            <Member member={members[7]}/>
          </Col>
          <Col md={2}></Col>
        </Row>
      </section>
      {/* <section>
        <div className="lg:container lg:mx-auto px-4">
          <Row className="min-h-[180px] p-4">
            <Col
              xs={24}
              className="flex items-center pl-0"
              style={{ borderBottom: "1.4px solid #30225F" }}
            >
              <h2 className="text-5xl font-normal text-primary-purple">
                Our <strong className="text-bold">Values</strong>
              </h2>
            </Col>
          </Row>
          <Row className="justify-center" gutter={24}>
            {principleItemLine.map((item) => (
              <PrinciplesItem
                key={item.key}
                logo={item.logo}
                header={item.header}
                text={item.text}
              ></PrinciplesItem>
            ))}
          </Row>
        </div>
      </section> */}
      {/* Another Purple Box */}
      {/* <section className="min-h-[72px] bg-transparant-purple pt-2 pb-1">
        <div className="lg:container lg:mx-auto px-2">
          <Row>
            <Col xs={24}>
              <p
                className="
                                text-[1.25rem]
                                // font-light
                                text-center
                                text-white
                                leading-relaxed"
              >
                <strong>Sign Up</strong> Today to Get Support, Answer, and
                Assistance. We Get Stuff Done.
              </p>
            </Col>
          </Row>
        </div>
      </section> */}
      {/* Care concierges section */}
      {/* <section className="bg-[url('assets/images/background-bubbles.png')] min-h-[400px] bg-center bg-no-repeat bg-cover overflow-hidden flex">
        <div className="lg:container lg:mx-auto px-4">
          <Row gutter={18}>
            <Col xs={16}>
              <div className="flex flex-auto flex-col self-stretch my-12">
                <p className="text-[4rem] font-normal text-primary-purple pl-5 leading-[1.1]">
                  Our <strong className="font-bold">Care Concierges</strong>
                </p>
                <Row gutter={18}>
                  <Col xs={2} className="flex justify-end pr-[6px]">
                    <span className="dot-blue"></span>
                  </Col>
                  <Col xs={22}>
                    <p className="text-2xl font-light text-justify text-primary-purple">
                      We{" "}
                      <strong className="font-semibold">
                        select our Care Concierges based on their life
                        experience, professional expertise, caregiving
                        knowledge, and desire to help others.
                      </strong>{" "}
                      We provide an inclusive and supportive work environment
                      for our front-line teams who are dedicated to listening
                      and helping customers navigate some of the most
                      challenging issues in life.
                    </p>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col xs={2} className="flex justify-end pr-[6px]">
                    <span className="dot-purple"></span>
                  </Col>
                  <Col xs={22}>
                    <p className="text-2xl font-light text-justify text-primary-purple">
                      <strong className="font-semibold">Service:</strong> We
                      strive to deliver
                      <strong className="font-semibold">
                        {" "}
                        value added service through each interaction.
                      </strong>{" "}
                      Build trust based on mutual respect, empathy, follow
                      through, and by doing the right thing.
                    </p>
                  </Col>
                </Row>
                <Row gutter={18}>
                  <Col xs={2} className="flex justify-end pr-[6px]">
                    <span className="dot-green"></span>
                  </Col>
                  <Col xs={22}>
                    <p className="text-2xl font-light text-justify text-primary-purple">
                      <strong className="font-semibold">Inclusiveness:</strong>{" "}
                      We value diversity, equality, equity, community and
                      participation to foster meaningful relationships. We will
                      lead and model inclusive behaviors throughout the
                      organization and{" "}
                      <strong className="font-semibold">
                        encourage individuals to engage in efforts to build
                        compassion, respect, caring, and self-esteem.
                      </strong>
                    </p>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col xs={8}>
              <div className="flex flex-auto flex-col self-stretch my-12">
                <div className="w-full">
                  <img
                    src={ConciergesImg1}
                    className="w-full max-w-[360px]"
                    alt="Concierges 1"
                  />
                </div>
                <div className="w-full">
                  <img
                    src={ConciergesImg2}
                    className="w-full max-w-[360px]"
                    alt="Concierges 2"
                  />
                </div>
                <div className="w-full">
                  <img
                    src={ConciergesImg3}
                    className="w-full max-w-[360px]"
                    alt="Concierges 3"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section> */}
      <Footer />
    </main>
  );
};
