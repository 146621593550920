import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Col, Skeleton, Button, Modal, Space, List, Spin } from "antd";
import ChatAvatarIcon from "./ChatAvatarIcon";
import { DEFAULT_MESSAGE_USER_ICON } from "../constants/Global";

const MessagingContactModal = ({
  chatListItems,
  open,
  confirmLoading,
  handleOk,
  handleCancel,
}) => {
  const [contactList, setContactList] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(chatListItems)) {
      let contacts = [];
      for (let i in chatListItems) {
        let chatItem = chatListItems[i];

        if (_.isEmpty(chatItem)) {
          return;
        }
        let user = chatItem.user;
        if (chatItem.type === "group") {
          if (!_.isNil(chatItem.circle?.primary)) {
            contacts.push(
              ...chatItem.circle.primary.flatMap((item) => {
                if (item._id === user._id) {
                  return [];
                }
                return [
                  {
                    ...item,
                    isPrimary: true,
                    circle: {
                      _id: chatItem.circle._id,
                      name: chatItem.name,
                    },
                  },
                ];
              })
            );
          }
          if (!_.isNil(chatItem.circle?.members)) {
            contacts.push(
              ...chatItem.circle.members.flatMap((item) => {
                if (item._id === user._id) {
                  return [];
                }
                return [
                  {
                    ...item,
                    isPrimary: false,
                    circle: {
                      _id: chatItem.circle._id,
                      name: chatItem.name,
                    },
                  },
                ];
              })
            );
          }
        }
      }
      setContactList(contacts);
    }
  }, [chatListItems]);

  return (
    <Modal
      title="Start a 1-on-1 conversation"
      visible={open}
      onCancel={handleCancel}
      footer={null}
    >
      <Spin spinning={confirmLoading}>
        <List
          className="w-full"
          itemLayout="vertical"
          dataSource={contactList}
          renderItem={(item, index) => (
            <List.Item
              style={{
                borderBottom:
                  _.isArray(contactList) && index < contactList?.length - 1
                    ? "1px solid #3c30661a"
                    : "none",
              }}
              className="hover:bg-new-primary-purple/5 px-2 cursor-pointer"
              onClick={() => {
                handleOk(item);
              }}
            >
              <Space>
                <ChatAvatarIcon
                  size={32}
                  Icon={
                    !_.isNil(item?.icon) ? item.icon : DEFAULT_MESSAGE_USER_ICON
                  }
                  iconStyle={{
                    color: "#EBEBEB",
                  }}
                  avatarStyle={{
                    background:
                      "linear-gradient(146.51deg, #6D56BE 12.17%, #3C3066 100.04%)",
                    border: "1px solid rgba(60, 48, 102, 0.6)",
                  }}
                />
                <Space size={0} direction="vertical">
                  <p className="mb-0 text-text-color text-sm leading-5 font-bold">
                    {item.firstName}
                    {item.isPrimary && <span> (Primary)</span>}
                  </p>
                  <p className="mb-0 text-text-color text-xs leading-5">
                    {<span>{item.circle.name}</span>}
                  </p>
                </Space>
              </Space>
            </List.Item>
          )}
        />
      </Spin>
    </Modal>
  );
};

export default MessagingContactModal;
