import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import useMclAuth from "../../hooks/useMclAuth";
import useAuth from "../../hooks/useAuth";
import { Url } from "../../constants/Global";
import { useNavigate } from "react-router";
import { mclOpen } from "../../api/Mcl";

import { AUTH_KEY } from "../../constants/Global";

function SuccessPayment() {
  const mclAuth = useMclAuth();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const { auth, setAuth } = useAuth();
  const { CHECKOUT_SESSION_ID } = useParams();

  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      let authState = localStorage.getItem(AUTH_KEY);
      if (authState) {
        const user = JSON.parse(authState);
        setAuth({
          id: user?.id,
          email: user?.email,
          password: "",
          care: user?.care,
          roles: user?.roles,
          // accessToken: localStorage.getItem("accessToken"),
          accessToken: user?.accessToken,
        });
      }

      if (auth.accessToken) {
        setMessage("Order placed! You will receive an email confirmation.");

        mclAuth
          .get(Url.PAYMENT.concat(`/successPayment/${CHECKOUT_SESSION_ID}`))
          .then((res) => {
            console.log("response: ", res.data);
            //localStorage.removeItem("accessToken");
            //localStorage.removeItem("tempEmail");
          })
          .catch((error) => {
            console.log(error.message);
          });

        setTimeout(function () {
          // navigate("/site/login");
          navigate("/caregiver/dashboard");
        }, 3000);
      } else {
        setMessage("Missing login token.");
        //localStorage.removeItem("accessToken");
        //localStorage.removeItem("tempEmail");
      }
    } else {
      if (localStorage.getItem("tempEmail")) {
        setMessage("Order placed! You will receive an email confirmation.");

        mclOpen
          .get(Url.PAYMENT.concat(`/successPaymentTemp/${CHECKOUT_SESSION_ID}`))
          .then((res) => {
            //localStorage.removeItem("accessToken");
            //localStorage.removeItem("tempEmail");
            console.log(res);
            setTimeout(function () {
              navigate("/survey/1/" + res.data._id);
            }, 3000);
          })
          .catch((error) => {
            console.log(error.message);
          });
      } else {
        setMessage("Missing email address.");
        //localStorage.removeItem("accessToken");
        //localStorage.removeItem("tempEmail");
      }
    }
  }, [auth.id]);

  return (
    <main>
      <p className="w-fill p-8 text-center text-lg">{message}</p>
    </main>
  );
}

export default SuccessPayment;
