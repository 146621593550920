import React, { useEffect, useState, useContext } from "react";

import {
  Skeleton,
  Menu,
  Space,
  Tooltip,
  Button,
  Drawer,
  Row,
  Col,
  Form,
  Checkbox,
  Select,
} from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import _, { debounce } from "lodash";

import useMclAuth from "../../hooks/useMclAuth";
import { Url, Care, SubscriptionToStr } from "../../constants/Global";

const { Option } = Select;

const accessInitial = {
  default: { medical: false, financial: false, legal: false },
  // exceptions: [],
  exceptions: [
    /*{member:'923824302', medical:false, financial:false, legal:true}*/
  ],
};

const NONE_SECONDARY = "none";

const CircleSettings = ({ cg, circle, circleId }) => {
  const [access, setAccess] = useState(/* circle?.access  ||*/ accessInitial);
  const [secondary, setSecondary] = useState(/*circle?.secondary ||*/ "");
  const [members, setMembers] = useState([]);
  const [primary, setPrimary] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");

  const mclAuth = useMclAuth();

  //   console.log("circleId:", circleId);
  useEffect(() => {
    // console.log('start use effect')
    setIsLoading(true);
    mclAuth
      .get(Url.CIRCLES.concat("/", circleId, "/permissions"))
      .then((res) => {
        setPrimary(res.data.primary);
        if (res.data.secondary) {
          setSecondary(res.data.secondary);
        } else {
          setSecondary(NONE_SECONDARY);
        }
        if (res.data?.access) {
          setAccess(JSON.parse(res.data.access));
        } else {
          setAccess(accessInitial);
        }
        // setAccess(res.data?.access || accessInitial)
        setMembers(res.data.members);
        setIsLoading(false);
        // console.log('response', res.data)
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
        console.log("permissions load error:", error.message);
      });
    // console.log('end use effect')
  }, [circleId /* auth.id, visible, mclAuth*/]);

  // const onClick = ({ key }) => {
  //         console.log('member chosen!', key)
  // }

  const saveAccess = async (access) => {
    // console.log('Saving....', plan)
    // console.log("saving access....", access);
    // plan.current = panelCurrent
    const accessStr = JSON.stringify(access);
    // console.log('saving to api!', mclAuth.defaults.baseURL)
    setIsSaving(true);
    mclAuth
      .post(Url.CIRCLES.concat("/", circle._id, "/access"), accessStr)
      .then((res) => {
        console.log("response access:", res.data);
      })
      .catch((error) => {
        setError(error);
      });
    setIsSaving(false);
  };

  const doAccess = (category, section, value) => {
    let newValues = JSON.parse(JSON.stringify(access));
    // console.log('new:', newValues)
    if (section === "") {
      newValues[category] = value;
    } else {
      newValues[category][section] = value;
    }
    setAccess(newValues);
    saveAccess(newValues);
    // console.log('access new:', newValues)
  };

  const saveSecondary = async (value) => {
    // console.log('Saving....', plan)
    // console.log("saving secondary....", value);
    let saveValue = value;
    if (value === "none") {
      saveValue = "";
    }

    // console.log('saving to api!', mclAuth.defaults.baseURL)
    setIsSaving(true);
    mclAuth
      .post(Url.CIRCLES.concat("/", circle._id, "/secondary"), {
        secondary: saveValue,
      })
      .then((res) => {
        console.log("response secondary:", res.data);
      })
      .catch((error) => {
        setError(error);
      });
    setIsSaving(false);
  };

  const doSelect = (value) => {
    setSecondary(value);
    saveSecondary(value);
  };

  if (isLoading || isSaving) {
    return <Skeleton active />;
  }

  return (
    <div className="w-full">
      <h3>Plan Access Permissions</h3>
      <Row>
        <Col span={12}>
          <Row>
            <h4>Primary:</h4>
          </Row>
          <Row>
            <>
              {
                /* circle.primary */ primary[0]?.firstName +
                  " " +
                  primary[0]?.lastName
              }
            </>
          </Row>
          <Row>
            <div style={{ height: "1em" }} />
          </Row>
          <Row>
            <h4>Backup Primary:</h4>
          </Row>
          <Row>
            <Select
              defaultValue={secondary || ""}
              style={{
                width: 220,
                fontSize: "0.75rem",
              }}
              onChange={(value, option) => doSelect(value)}
            >
              <Option key={"none"} value={NONE_SECONDARY}>
                None
              </Option>
              {members.map((member, idx) => (
                <Option key={idx} value={member._id}>
                  {member.firstName + " " + member.lastName}
                </Option>
              ))}
            </Select>
          </Row>
        </Col>
        <Col span={12}>
          <Row>
            <h4>Default Circle Permissions:</h4>
          </Row>
          <Row>
            <Col span={8}>
              <Checkbox
                style={{ fontSize: "0.75rem" }}
                onChange={(e) =>
                  doAccess("default", "medical", e.target.checked)
                }
                defaultChecked={access?.default?.medical || false}
              >
                Medical
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox
                style={{ fontSize: "0.75rem" }}
                onChange={(e) =>
                  doAccess("default", "financial", e.target.checked)
                }
                defaultChecked={access?.default?.financial || false}
              >
                Financial
              </Checkbox>
            </Col>
            <Col span={8}>
              <Checkbox
                style={{ fontSize: "0.75rem" }}
                onChange={(e) => doAccess("default", "legal", e.target.checked)}
                defaultChecked={access?.default?.legal || false}
              >
                Legal
              </Checkbox>
            </Col>
            {/* <Col span={12} /> */}
          </Row>
          <Row style={{ height: "1em" }}></Row>
          {/* </Col>
            <Col span={10}> */}
          <Row>
            <h4>Exceptions:</h4>
          </Row>
          <Row>
            <Form
              className="mb-1"
              name="home_properties_list"
              onFinish={(values) =>
                doAccess("exceptions", "", values?.exceptions)
              }
              autoComplete="off"
            >
              <Form.List name="exceptions" initialValue={access.exceptions}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                        size="small"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "member"]}
                          rules={[
                            {
                              required: true,
                              message: "Please select member",
                            },
                          ]}
                        >
                          <Select
                            style={{
                              width: 220,
                            }}
                          >
                            {members.map((member, idx) => (
                              <Option key={idx} value={member._id}>
                                {member.firstName + " " + member.lastName}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "medical"]}
                          valuePropName="checked"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                        >
                          <Checkbox /* onChange={(e) =>  doChange("c6", "communicate", e.target.checked, 1)} */
                            /* defaultChecked={false}*/
                            style={{ fontSize: "0.75rem" }}
                          >
                            Medical
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "financial"]}
                          valuePropName="checked"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                        >
                          <Checkbox /* onChange={(e) => doChange("c6", "communicate", e.target.checked, 1)} */
                            /* defaultChecked={false} */
                            style={{ fontSize: "0.75rem" }}
                          >
                            Financial
                          </Checkbox>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "legal"]}
                          valuePropName="checked"
                          rules={[
                            {
                              required: false,
                              message: "",
                            },
                          ]}
                        >
                          <Checkbox /* onChange={(e) => doChange("c6", "communicate", e.target.checked, 1)} */
                            /* defaultChecked={false} */
                            style={{ fontSize: "0.75rem" }}
                          >
                            Legal
                          </Checkbox>
                        </Form.Item>
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>
                    ))}
                    <Form.Item className="mb-1">
                      {/* add({name: 'test1', phone: 'phone 2' }) */}
                      <Button
                        size="small"
                        type="dashed"
                        onClick={() =>
                          add({
                            medical: false,
                            financial: false,
                            legal: false,
                          })
                        }
                        block
                        icon={<PlusOutlined />}
                      >
                        Add Exception
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item className="mb-1">
                <Button
                  size="small"
                  shape="round"
                  type="primary"
                  htmlType="submit"
                >
                  Save Exceptions
                </Button>
              </Form.Item>
            </Form>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default CircleSettings;
