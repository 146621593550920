import React, { useState, useCallback } from "react";
import ReactGA from "react-ga4";

import {Helmet} from "react-helmet"; 

import _ from "lodash";
import { mclOpen } from "../../api/Mcl";

import { Url } from "../../constants/Global";

import { useNavigate, useLocation } from "react-router";

import { Carousel, Card, Col, Row, 
         Divider, Button, Modal, Form,
         Collapse, Space } from "antd";
import Icon, { CloudDownloadOutlined, MailOutlined, PlusOutlined, 
               MinusOutlined, MehOutlined, SnippetsOutlined } from "@ant-design/icons";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Navbar } from "../../components/Navbar";

import { openNotification } from "../../components/Various";

import { SectionBanner } from "../../components/HomeComponents";

import Footer from "../../components/Footer";




export const Product = () => {
  const navigate = useNavigate();
  const currentPath = useLocation().pathname;

  const {Panel } = Collapse;
  
  return (
    <main>
      <Helmet>
          <meta charSet="utf-8" />
          <title>My CareLinq - Enterprise</title>
          <meta name="description" content="My CareLinq employee benefit solution helps employers retain talent, reduce absenteeism, and improve employee satisfaction." />
          <link rel="canonical" href="https://mycarelinq.com/enterprise" />
      </Helmet>
      <Navbar signin={true} navigate={navigate} />
      <div
        className={
          "w-full mt-0 mx-0 px-0 bg-purple-50 px-5 sm:px-8 md:px-10 lg:px-5 xl:px-15"
        }
        id="our-service"
      >   
        <Row>
          <Col md={2}></Col>
          <Col md={10}>
            <Row className="ml-10 mt-10 mb-0 pb-0">
              <h1 className="text-primary-purple text-left xs:text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] lg:text-[3rem] mt-5 pt-0 mb-0 pb-0">
                Our Service
              </h1><br/>
            </Row>
            <Row  className="ml-10 mt-5">
              <Col md={18}>
              <h4 className="text-primary-purple text-[1.25rem]">
                My CareLinq employee benefit solution helps employers retain talent, reduce absenteeism, and improve employee satisfaction. 
              </h4>
              </Col>
              <Col md={6}></Col>
            </Row>
            {/* <Row className="ml-10 mt-0 pt-0">
              <h1 className="text-primary-purple text-left xs:text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] lg:text-[3rem] mt-0 pt-0">
                Service
              </h1>
            </Row> */}
            <Row className="ml-10 mt-5">          
              <Button
                className="ml-20 pl-2 pr-2"
                type="primary"
                shape="round"
                style={{
                  background: "#8159f5",
                  borderColor: "#8159f5",
                  color: "white",
                }}
                icon={<MailOutlined />}
                onClick={() => {
                  // ReactGA.event({
                  //   category: "home",
                  //   action: "button_click",
                  //   label: "download_checklist",
                  // });
                  // // navigate("/article/my-carelinq-caregiver-checklist");
                  // // setDownloadModalSetting((prevState) => ({
                  // //   ...prevState,
                  // //   isModalVisible: true,
                  // // }));
                  // setScheduleModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));

                  // window.open("https://calendly.com/mycarelinq", "_blank");
                  navigate(currentPath+"?modal=partner");
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="large"
              >
                Contact Us
              </Button>
            </Row>
          </Col>
          <Col md={10}>
            <Row className="mt-10 mb-0 pb-0">
              <Col md={24}
              // className="py-5 lg:px-8 xl:px-15 px-10 text-primary-purple"
              >
                <Collapse accordion size="large" bordered={false} expandIconPosition="end"
                          expandIcon={({ isActive }) => (
                            <Icon
                              component={isActive ? MinusOutlined : PlusOutlined}
                              width="2em"
                              height="2em"
                            />
                          )}
                          className="bg-purple-50 text-primary-purple text-left xs:text-[0.25rem] sm:text-[0.5rem] md:text-[0.75rem] lg:text-[1.25rem] mt-0 pt-0"
                >
                  <Panel header={<h3>Care Concierges</h3>} key="3">
                    <div className={"text-primary-purple bg-purple-50 text-left pl-10 pr-10 pt-5 pb-5"}>
                      <h5>
                        Our service starts with Care Concierges who use their 
                        experience and our platform to build the right family care plan 
                        to help your employee give their loved one the best care with built in caregiver support.
                      </h5>
                    </div>
                  </Panel>                
                  <Panel header={<h3>Curated Sourcing</h3>} key="4">
                    <div className={"text-primary-purple bg-purple-50 text-left pl-10 pr-10 pt-5 pb-5"}>
                      <h5>
                        Once the plan is built, My CareLinq can help your employee source and hire the 
                        right people, equipment, and other services to best care for their family. We even help 
                        them budget, find funding from local programs, analyze benefits, and complete paperwork.
                      </h5>
                    </div>
                  </Panel>                
                  <Panel header={<h3>Marketplace</h3>} key="1">
                    <div  className={"text-primary-purple bg-purple-50 text-left pl-10 pr-10 pt-5 pb-5"}>
                      <h5>
                        We vet and source reputable service providers across all types of needs.
                      </h5>
                    </div>
                  </Panel>
                  <Panel header={<h3>Coordination & Management</h3>} key="2">
                    <div className={"text-primary-purple bg-purple-50 text-left pl-10 pr-10 pt-5 pb-5"}>
                      <h5>
                        Let us coordinate and manage the care plan so that you can focus on what matters most.
                      </h5>
                    </div>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </Col>
          <Col md={2}></Col>
        </Row>
        <Row className="mt-20"></Row>
      </div>
      <section className="overflow-hidden">
        <SectionBanner message="" />
      </section>

      <div
        // className={
        //   "w-full mt-0 mx-0 px-0 bg-purple-50 px-5 sm:px-8 md:px-10 lg:px-5 xl:px-15"
        // }
        className="bg-purple-50"
        id="what-we-offer"
      >
        {/* <Row className="platform-header"></Row> */}
        {/* <h1 className="text-primary-purple text-center xs:text-[1rem] sm:text-[1.25rem] md:text-[1.5rem] lg:text-[2rem] mt-0 pt-5"> */}
        <h1 className="text-primary-purple text-center xs:text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] lg:text-[3rem] pt-20 pt-5">
          My CareLinq in Action
        </h1>
        {/* <div className="w-full mx-0 xs:px-2 px-0"> */}
        <div>
          <Row className="text-center justify-center">
          <Col md={2}/>
          <Col
              // xs={8}
              // sm={8}
              md={6}
              // lg={8}
              // className="py-5 lg:px-8 xl:px-15 px-10 text-primary-purple"
              id="for-employers"
            >
              <h3
                className="text-[#8159f5] text-[1.75rem] pb-2.5 mb-2"
                style={{ borderBottom: "#8159f555 solid 2px" }}
              >
                <strong>Step 1</strong>
              </h3>
              <h3 className="text-primary-purple text-[1.5rem]">
                <strong>
                  The Plan
                </strong>
              </h3>
              {/* <p>
                We offer a new way to support employees to give the best care
                to family members while still being present and engaged at work.
              </p> */}
              <h4 className="text-primary-purple text-[1.25rem] mb-5">
                It all begins with a conversion with one of our Care Concierges.  They 
                will create a plan for you and your loved one.               
              </h4>
              <Button
                className="pl-2 pr-2 mt-2 text-[1rem]"
                type="primary"
                shape="round"
                style={{
                  background: "#8159f5",
                  borderColor: "#8159f5",
                  color: "white",
                }}
                icon={<MehOutlined />}
                onClick={() => {
                  // ReactGA.event({
                  //   category: "home",
                  //   action: "button_click",
                  //   label: "download_checklist",
                  // });
                  // // navigate("/article/my-carelinq-caregiver-checklist");
                  // // setDownloadModalSetting((prevState) => ({
                  // //   ...prevState,
                  // //   isModalVisible: true,
                  // // }));
                  // setScheduleModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));

                  // window.open("https://calendly.com/mycarelinq", "_blank");
                  navigate(currentPath+"?modal=schedule");
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="large"
              >
                Schedule a free Consultation
              </Button>
            </Col>
            <Col md={1}/>
            <Col
              // xs={8}
              // sm={8}
              md={6}
              // lg={8}
              // className="py-5 lg:px-15 xl:px-20 px-10 text-primary-purple"
              // className="py-5 px-10"
              id="for-healthcare"
            >
              <h3
                className="text-[#8159f5] text-[1.75rem] pb-2.5 mb-2"
                style={{ borderBottom: "#8159f555 solid 2px" }}
              >
                <strong>Step 2</strong>
              </h3>
              {/* <h3 className="text-primary-purple">
                <strong>Expert Services</strong>
              </h3> */}
              <h3 className="text-primary-purple text-[1.5rem]">
                <strong>Prioritize and Source</strong>
              </h3>
              {/* <p>
                We provide expert care provisioning and care coordination
                services across the care continuum.
              </p> */}
              <h4 className="text-primary-purple text-[1.25rem] mb-5">
                We get to work.  Complete tasks and paperwork, source service providers, 
                and refine the care plan along the way. 
              </h4>
              <Button
                className="pl-2 pr-2 mt-2 text-[1rem]"
                type="primary"
                shape="round"
                style={{
                  background: "#8159f5",
                  borderColor: "#8159f5",
                  color: "white",
                }}
                icon={<SnippetsOutlined />}
                onClick={() => {
                  // ReactGA.event({
                  //   category: "home",
                  //   action: "button_click",
                  //   label: "download_checklist",
                  // });
                  // // navigate("/article/my-carelinq-caregiver-checklist");
                  // // setDownloadModalSetting((prevState) => ({
                  // //   ...prevState,
                  // //   isModalVisible: true,
                  // // }));
                  // setScheduleModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));

                  // window.open("https://calendly.com/mycarelinq", "_blank");
                  navigate("/#testimonials");
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="large"
              >
                Case Studies
              </Button>
            </Col>
            <Col md={1}/>
            <Col
              // xs={8}
              // sm={8}
              md={6}
              // lg={8}
              // className="py-5 lg:px-15 xl:px-20 px-10 text-primary-purple"
              id="for-partners"
            >
              <h3
                className="text-[#8159f5] text-[1.75rem] pb-2.5 mb-2"
                style={{ borderBottom: "#8159f555 solid 2px" }}
              >
                <strong>Third step</strong>
              </h3>
              <h3 className="text-primary-purple text-[1.5rem]">
                <strong>Coordinate and Manage</strong>
              </h3>
              <h4 className="text-primary-purple text-[1.25rem] mb-5">
                Let us handle all the coordination and management of the family care plan, 
                saving you time, money, and effort. 
              </h4>
             <Button
                className="pl-2 pr-2 mt-2 text-[1rem]"
                type="primary"
                shape="round"
                style={{
                  background: "#8159f5",
                  borderColor: "#8159f5",
                  color: "white",
                }}
                icon={<MailOutlined />}
                onClick={() => {
                  // ReactGA.event({
                  //   category: "home",
                  //   action: "button_click",
                  //   label: "download_checklist",
                  // });
                  // // navigate("/article/my-carelinq-caregiver-checklist");
                  // // setDownloadModalSetting((prevState) => ({
                  // //   ...prevState,
                  // //   isModalVisible: true,
                  // // }));
                  // setScheduleModalSetting((prevState) => ({
                  //   ...prevState,
                  //   isModalVisible: true,
                  // }));
                  // window.open("https://calendly.com/mycarelinq", "_blank");
                  navigate(currentPath+"?modal=partner");
                  
                }}
                ghost
                aria-controls="download-caregiver-plan"
                aria-expanded={1}
                size="large"
              >
                Contact Us
              </Button>
            </Col>
            <Col md={2}/>
          </Row>
          <Row className="mt-20" ></Row>
        </div>
        <section className="overflow-hidden">
          <SectionBanner message="" />
        </section>

        <div className="w-full mx-0 xs:px-2 px-0 mt-20 bg-purple-50">
          <Row>
            <Col md={12}>
              <h1 className="text-primary-purple text-center xs:text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] lg:text-[3rem] mt-0 pt-5">
                Partnerships
              </h1>
            </Col>
            <Col md={10}>
              <h3 className="text-primary-purple text-[1.75rem]"><strong>Employers</strong></h3>
              <h4 className="text-primary-purple text-[1.25rem]">
                Full service employee benefit solution for your employees so that they can be present 
                and engaged at work. Contact us to learn more about services and pricing. 
              </h4>
              <p></p>
              <h3 className="text-primary-purple text-[1.75rem]"><strong>Healthcare</strong></h3>
              <h4 className="text-primary-purple text-[1.25rem]">
                We offer full service solutions to reduce hospitalizations, enhance patient satisfaction.  
                My CareLinq enables better outcomes with enhanced care planning and coordination for patients 
                and their family caregivers across the care continuum. Contact us to learn more about how we 
                partner with providers to optimize transitions to home. 
              </h4>
              <p></p>
              <h3 className="text-primary-purple text-[1.75rem]"><strong>Partners</strong></h3>
              <h4 className="text-primary-purple text-[1.25rem]">
                We want to partner with local and specialized community resources, vendors, providers, 
                and referral networks.  Join us. 
              </h4>
              <p></p>
            </Col>
            <Col md={2}/>
          </Row>
          <Row className="mt-20"></Row>
        </div>
        <section className="overflow-hidden">
          <SectionBanner message="" />
        </section>

        <div className="mt-20 bg-purple-50">
          <Row>
            <Col md={3}></Col>
            <Col md={9}>
              <h1 className="text-primary-purple text-left xs:text-[2rem] sm:text-[2.25rem] md:text-[2.5rem] lg:text-[3rem] mt-0 pt-5">
                Testimonial
              </h1>
            </Col>
            <Col md={10}>
            {/* <section className="bg-[url('assets/images/about-us/background.png')] min-h-[300px] bg-center bg-no-repeat bg-cover overflow-hidden flex"> */}
            <section className="rounded-xl overflow-hidden flex"
                            style={{
                              background: "#8159f555",
                              borderColor: "#8159f555",
                              color: "white",
                            }}            
            >
            <div  className="text-primary-purple font-weight-bold m-10 text-[1.25rem]">
              <h3 className="text-left">
              "As HR Leaders, we view My Carelinq as a solution to solve real in-house situations.
              <br/><br/> 
              I have experienced scrambling for a solution to provide high performers challenged with home 
              life/ family caregiving. 
              <br/><br/>
              We have had CEO’s inquire about resources to ramp up our level of support. 
              <br/><br/>
              We do find ourselves inadequately prepared to address these concerns.
              <br/><br/>
              I know this will resonate with other HR leaders as well."
              <br/><br/>
              </h3>
              <h3 className="text-right ">— People Leader in Tech Sector</h3>
              </div>
              </section>
            </Col>
            <Col md={2}></Col>           
          </Row>
          <Row className="mt-20"/>
        </div>
      </div>


      <Footer consultMsg="Schedule a Meeting with Us"/>
    </main>
  );

// const onChange = (event) => {}
//   return (
//     <>
//       {/* <Navbar signin={true} navigate={navigate} />       */}
//       {/* <Space direction="vertical"> */}
//       <Collapse accordion size="large" items={items} bordered={true} defaultActiveKey={['1']} />
//       <Collapse  >
//   <Panel header="This is panel header 1" key="1">
//     <p>{text}</p>
//   </Panel>
//   <Panel header="This is panel header 2" key="2">
//     <p>{text}</p>
//   </Panel>
//   <Panel header="This is panel header 3" key="3">
//     <p>{text}</p>
//   </Panel>
// </Collapse>;
//       {/* <Card title="Card title">Card content</Card> */}
//       {/* </Space> */}
//     </>
//   );
};
