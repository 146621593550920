import React, { useState, useEffect } from "react";
import moment from "moment";
import { Col, Row, Space, Typography, Divider, Image } from "antd";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { mclOpen } from "../api/Mcl";
import _ from "lodash";
import {
  ARTICLE_IMAGE_PLACEHOLDER,
  AUTHOR_IMAGE_PLACEHOLDER,
  ARTICLE_ROUTE,
  DEMO_ARTICLES,
  Url,
} from "../constants/Global";
import { getDefaultLibraryImage, getLibraryImage } from "./Various";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1024 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1024, min: 768 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 768, min: 640 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 640, min: 0 },
    items: 1,
  },
};

const FeatureArticleCard = ({ article }) => {
  let defaultImageLink = getDefaultLibraryImage(article.categories);

  return (
    <div className="relative h-72">
      <div
        className="absolute rounded-lg bg-center bg-no-repeat bg-cover shadow-md inline-block w-full h-72"
        style={{
          backgroundImage: `url('${
            _.isEmpty(article.featuredImage)
              ? defaultImageLink
              : getLibraryImage(article.featuredImage, "article")
          }')`,
        }}
      />
      <div className="absolute rounded-lg bg-center bg-gradient-to-b opacity-50 from-gray-400 via-gray-700 to-black w-full h-72" />
      <div className="flex flex-col rounded-lg p-4 items-center justify-center absolute w-full h-full">
        {/* <p className="text-white mb-4 text-shadow font-semibold text-xs">
          {moment(article.createdAt).format("MMM DD, YYYY")}
        </p> */}
        <p className="text-white mb-4 text-shadow font-semibold text-2xl text-center">
          {article.title}
        </p>
        <div className="flex items-center absolute bottom-5 w-full justify-center">
          <Image
            preview={false}
            alt={""}
            height={30}
            width={30}
            className="align-middle drop-shadow-lg rounded-full"
            draggable="false"
            src={
              _.isNil(article.author) || _.isEmpty(article.author.photo)
                ? AUTHOR_IMAGE_PLACEHOLDER
                : article.author.photo
            }
          />
          <p className="inline align-middle text-white text-shadow ml-2 font-medium mt-0 mb-0">
            {article.author?.name}
          </p>
        </div>
      </div>
      <Link to={`${ARTICLE_ROUTE}${article.url}`}>
        <span className="cursor-pointer absolute w-full h-full"></span>
      </Link>
    </div>
  );
};

const getFeaturedArticles = async () => {
  return new Promise((resolve, reject) => {
    resolve(DEMO_ARTICLES);
  });
};

const FeaturedArticles = () => {
  const [featuredArticles, setFeaturedArticles] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    // getFeaturedArticles().then((result) => {
    //     setFeaturedArticles(result);
    //     setDataLoaded(true);
    // });
    mclOpen
      .get(Url.ARTICLES.concat("/get-featured"))
      .then((res) => {
        setFeaturedArticles(res.data);
        setDataLoaded(true);
      })
      .catch((error) => {
        console.log(error);
        setDataLoaded(false);
      });
  }, []);

  // customLeftArrow={<Arrow>{customLeftArrow}</Arrow>} customRightArrow={<Arrow>{customRightArrow}</Arrow>}
  return (
    <Carousel infinite responsive={responsive} itemClass="px-4">
      {dataLoaded &&
        featuredArticles.map((article, index) => (
          <FeatureArticleCard key={index} article={article} />
        ))}
    </Carousel>
  );
};

export default FeaturedArticles;
