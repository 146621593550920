import React, { useEffect, useState, useCallback } from 'react'
import { Card, Row, Col, List, Breadcrumb, Button, Table, notification, Result, Spin, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { EditOutlined } from '@ant-design/icons';

import _ from "lodash";
import useMclAuth from '../../hooks/useMclAuth';
import useAuth from '../../hooks/useAuth';
import { Url, Roles } from '../../constants/Global';
import MyCCDetail from './MyCCDetail';
import { AssignModal } from "../../components/SConciergeComponents"


const columns = [
    {
        title: 'Name',
        dataIndex: 'name',
        width: 150,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        width: 150,
    },
    {
        title: 'Phone',
        dataIndex: 'phone',
        width: 150,
    },
];

const openNotification = ({ message, status, title, subTitle }) => {
    // console.log(message, title, status, subTitle)
    notification.open({
        message: message,
        description:
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
            /* extra={<Button type="primary">Back Home</Button>}*/
            />
    });
};

const MyCC = ({ setPage }) => {

    const mclAuth = useMclAuth()
    const { auth } = useAuth()

    const [cg, setCg] = useState({})
    const [currentCC, setCurrentCC] = useState({})
    const [CCDetail, setCCDetail] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState('')

    const [ccs, setCCs] = useState([])
    const [selectedClients, setSelectedClients] = useState([])
    const [assignedCCId, setAssignedCCId] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);

    const radioOnChange = (e) => {
        setAssignedCCId(e.target.value);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = useCallback(() => {
        if (_.isEmpty(assignedCCId) || _.isEmpty(selectedClients) || _.isEmpty(CCDetail._id)) {
            openNotification({ message: "Data not valid", status: "400", title: "400", subTitle: "Not selected concierge or clients yet" })
            return;
        }
        let reqData = {}
        reqData.assignTo = assignedCCId;
        reqData.clientIds = selectedClients;
        reqData.originalCC = CCDetail._id;

        mclAuth.post(Url.SUPER_CONCIERGES.concat("/", auth?.id, "/reassignCC"), reqData)
            .then(res => {
                // Update new client data
                if (res.status && res.status === 200) {
                    openNotification({
                        message: "Update Succeed",
                        status: "success",
                        title: "Update Succeed",
                        subTitle: "Successfully reassign clients"
                    })
                }
                if (!_.isEmpty(res.data)) {
                    let resData = res.data;
                    if (_.includes(resData.roles, Roles.CONCIERGE)) {
                        let concierges = _.get(resData, "concierges", []);
                        concierges.push({
                            firstName: resData.firstName,
                            lastName: resData.lastName,
                            roles: resData.roles,
                            _id: resData._id,
                            clients: _.get(resData, "clients", [])
                        });
                    }
                    for (let concierge in resData.concierges) {
                        const clientData = [...resData.concierges[concierge].clients.map((item) => {
                            let client = item.clientId;
                            let circle = item.circleId;
                            return {
                                id: item._id,
                                key: circle._id,
                                clientId: client._id,
                                name: client.firstName + " " + client.lastName,
                                firstName: item.firstName,
                                lastName: item.lastName,
                                email: client.email,
                                phone: client.phone,
                                disposition: client.__disposition ? client.__disposition : 0,
                                consultDate: client.__lastConsultDate,
                                survey: client.survey,
                                roles: client.roles,
                                primaryCare: circle.primary ? circle.primary : [],
                                subscription: circle.subscription ? circle.subscription : 0,
                            }
                        })]
                        resData.concierges[concierge].clients = clientData

                        if (!_.isEmpty(CCDetail) && CCDetail._id === resData.concierges[concierge]._id) {
                            setCCDetail({ ...resData.concierges[concierge] });
                        }
                        if (!_.isEmpty(currentCC) && currentCC._id === resData.concierges[concierge]._id) {
                            setCurrentCC({ ...resData.concierges[concierge] });
                        }
                    }
                    setCg(resData)
                }
                setIsLoading(false)
            })
            .catch(error => {
                openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to update note" })
                setError(error.message)
                setIsLoading(false)
            })
        setIsModalVisible(false);
    }, [assignedCCId, selectedClients, CCDetail, currentCC]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (!_.isEmpty(CCDetail) && !_.isEmpty(cg)) {
            // Exclude the selected CC in assign modal
            let ccList = cg.concierges.reduce((ccList, client) => {
                if (client._id !== CCDetail._id) {
                    ccList.push(_.pick(client, ["_id", "firstName", "lastName", "roles"]));
                }
                return ccList
            }, [])
            setCCs(ccList)
        }
    }, [CCDetail, cg])

    useEffect(() => {
        // Get cc
        setIsLoading(true)
        mclAuth.get(Url.SUPER_CONCIERGES.concat('/', auth?.id, '/concierges'))
            .then(res => {
                let data = res.data;
                // Add self as care concierge
                if (_.includes(data.roles, Roles.CONCIERGE)) {
                    let concierges = _.get(data, "concierges", []);
                    concierges.push({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        roles: data.roles,
                        _id: data._id,
                        clients: _.get(data, "clients", [])
                    });
                }

                for (let concierge in data.concierges) {
                    const clientData = [...data.concierges[concierge].clients.map((item) => {
                        let client = item.clientId;
                        let circle = item.circleId;
                        return {
                            id: item._id,
                            key: circle._id,
                            clientId: client._id,
                            name: client.firstName + " " + client.lastName,
                            firstName: item.firstName,
                            lastName: item.lastName,
                            email: client.email,
                            phone: client.phone,
                            disposition: client.__disposition ? client.__disposition : 0,
                            consultDate: client.__lastConsultDate,
                            survey: client.survey,
                            roles: client.roles,
                            primaryCare: circle.primary ? circle.primary : [],
                            subscription: circle.subscription ? circle.subscription : 0,
                        }
                    })]
                    data.concierges[concierge].clients = clientData
                }
                setCg(data)
                setIsLoading(false)
            })
            .catch(error => {
                openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to get care concierge data" })
                setError(error.message)
                setIsLoading(false)
            })

    }, [auth.id, mclAuth])

    const homeBreadCrumbOnClick = (e) => {
        e.preventDefault();
        setPage("0")
    };

    const myCCBreadCrumbOnClick = (e) => {
        e.preventDefault();
        setCCDetail({})
    };

    const breadCrumbOnClick = (e) => {
        e.preventDefault();
    };


    return (
        <>
            <AssignModal
                titleText={"Reassign"}
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                clientNumber={_.isArray(selectedClients) ? selectedClients.length : 0}
                cc={ccs}
                radioOnChange={radioOnChange}
                value={assignedCCId}
            />
            <Row>
                <Col xs={24}>
                    <Breadcrumb separator=">" className="text-h6">
                        <Breadcrumb.Item>
                            <a href="#" onClick={homeBreadCrumbOnClick}>Home</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="#" onClick={myCCBreadCrumbOnClick}>My Care Concierges</a>
                        </Breadcrumb.Item>
                        {!_.isEmpty(CCDetail) &&
                            <Breadcrumb.Item>
                                <a href="#" onClick={breadCrumbOnClick}>{CCDetail.firstName}</a>
                            </Breadcrumb.Item>
                        }
                    </Breadcrumb>
                </Col>
            </Row>
            <Spin spinning={isLoading}>
                {_.isEmpty(CCDetail) ?
                    <Card className='mt-4'>
                        <Row gutter={[20, 20]}>
                            <Col xs={24} xl={8}>
                                {
                                    (!_.isEmpty(cg) && _.isArray(cg.concierges)) &&
                                    <List
                                        size="large"
                                        header={<div className='text-xl font-bold'>Concierges</div>}
                                        bordered
                                        dataSource={cg.concierges}
                                        renderItem={item =>
                                            <List.Item
                                                actions={[
                                                    <Button
                                                        type='link'
                                                        onClick={() => {
                                                            setCCDetail(item);
                                                        }}
                                                    >
                                                        Detail
                                                    </Button>,
                                                    <Button
                                                        type='link'
                                                        icon={<CaretRightOutlined />}
                                                        onClick={() => {
                                                            setCurrentCC(item);
                                                        }}
                                                    >
                                                    </Button>]
                                                }
                                            >
                                                {item.firstName}
                                            </List.Item>
                                        }
                                    />
                                }
                            </Col>
                            <Col xs={24} xl={16}>
                                {
                                    !_.isEmpty(currentCC) &&
                                    <Table
                                        columns={columns}
                                        dataSource={currentCC.clients}
                                        bordered
                                        scroll={{ x: 450 }}
                                        title={() => <div className='text-xl font-bold w-full'>{currentCC.firstName}'s Clients and Lead-gen</div>}
                                    />
                                }
                            </Col>
                        </Row>
                    </Card>
                    :
                    <Card
                        className='mt-4'
                        title={CCDetail.firstName + "'s Clients"}
                        extra={
                            <Button
                                type="primary"
                                icon={<EditOutlined />}
                                onClick={() => {
                                    if (_.isArray(selectedClients) && selectedClients.length > 0) {
                                        showModal();
                                    } else {
                                        message.warning('Please select a client', 5);
                                    }
                                }}
                            >
                                Reassign Client
                            </Button>
                        }
                    >
                        <MyCCDetail
                            currentCC={CCDetail}
                            setSelectedClients={setSelectedClients}
                        />
                    </Card>
                }
            </Spin>
        </>
    )
}

export default MyCC