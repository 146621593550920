import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router";

import { Navbar } from "../../components/Navbar";
import { PurpleDivider } from "../../components/Various";
import moment from "moment";
import { Col, Row, Space, Typography, Divider, Image } from "antd";
import { Link } from "react-router-dom";
import { CalendarOutlined } from "@ant-design/icons";
import {
    AUTHOR_IMAGE_PLACEHOLDER,
    Url
} from "../../constants/Global";
import ReactGA from "react-ga4";
import { getDefaultLibraryImage, getLibraryImage } from "../../components/Various";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { openNotification } from "../../components/Various";
import GetStartedButton from "../../components/GetStartedButton";

import Footer from "../../components/Footer";
import { mclOpen } from "../../api/Mcl";
import _ from "lodash";

export const Download = () => {

    const navigate = useNavigate();
    let { type, url } = useParams();
    const [article, setArticle] = useState({});

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        const token = await executeRecaptcha("articleDownloadForm");
        // Send this token to the server in the following request
        return token;
    }, [executeRecaptcha]);

    const downloadOnClick = async (url, article) => {
        ReactGA.event({
            category: "download",
            action: "download_onclick",
            label: "download_button",
        });

        let data = {
            downloadId: url
        }

        let recaptchaToken = await handleReCaptchaVerify();
        if (!_.isEmpty(recaptchaToken)) {
            // Get the recaptcha token, submit it with the data to the backend
            mclOpen
                .post(Url.DOWNLOAD.concat(`/${type}`), data, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        token: recaptchaToken,
                    },
                    responseType: 'blob'
                })
                .then((response) => {
                    const tempUrl = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = tempUrl;
                    link.setAttribute('download', `${article.title}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((error) => {
                    console.log(error)
                    openNotification({
                        message: "Sorry...Download request encountered a server error",
                        status: error.response.status && error.response.status !== 500 ? "error" : "500",
                        title: error.message ? error.message : "",
                    });
                })
        } else {
            openNotification({
                message: "Sorry",
                status: "500",
                title: "Sorry, please try again later",
                subTitle: "Download article form failed to get Recaptcha token",
            });
        }
    };

    const getArticle = async (url, type) => {
        // Get donwload article thumbnail and link
        mclOpen.get(Url.DOWNLOAD.concat(`/${type}/${url}`))
            .then((res) => {
                let data = res.data;
                setArticle(data);
            })
            .catch((error) => {
                console.log(error)
            });
    }

    useEffect(() => {
        if (type == "library") {
            getArticle(url, type);
        }
    }, [url, type])

    return (
        <main>
            <Navbar signin={true} navigate={navigate} />
            <PurpleDivider />
            <div className="mt-8 lg:container lg:mx-auto flex flex-col px-4 justify-center whitespace-normal">
                {/* <LibraryFeaturedSection /> */}
                <Row className="mt-8 mb-32" gutter={[48, 24]} justify="center">
                    <Col xs={24} lg={16}>
                        <div className="bg-white shadow-lg rounded-lg p-3 md:p-5 lg:p-8 xl:p-12 pb-12 mb-8">
                            <h1 className="transition duration-700 text-center mb-8 text-3xl font-semibold">
                                Download
                            </h1>
                            <div className="relative overflow-hidden shadow-md pb-80 mb-6">
                                <img
                                    src={_.isEmpty(article.featuredImage) ? getDefaultLibraryImage(article.categories) : getLibraryImage(article.featuredImage, "article")}
                                    alt=""
                                    className="object-top absolute h-80 w-full object-cover  shadow-lg rounded-t-lg lg:rounded-lg"
                                />
                            </div>
                            <h1 className="transition duration-700 text-center mb-8 text-3xl font-semibold">
                                {article.title}
                            </h1>
                            <div className="block lg:flex text-center items-center justify-center mb-8 w-full">
                                <div className="flex items-center justify-center mb-4 lg:mb-0 w-full lg:w-auto mr-8">
                                    <Image
                                        alt={""}
                                        height={30}
                                        width={30}
                                        className="align-middle rounded-full"
                                        src={(_.isNil(article.author) || _.isEmpty(article.author.photo)) ? AUTHOR_IMAGE_PLACEHOLDER : article.author.photo}
                                    />
                                    <p className="inline align-middle text-gray-700 ml-2 font-medium text-lg mt-0 mb-0">
                                        {article.author?.name}
                                    </p>
                                </div>
                                <div className="flex items-center justify-center font-medium text-gray-700">
                                    <CalendarOutlined className="text-[30px] text-primary-purple mr-2" />
                                    <span className="align-middle inline-flex items-center">
                                        {moment(article.createdAt).format("MMM DD, YYYY")}
                                    </span>
                                </div>
                            </div>
                            <div className="text-center">
                                <GetStartedButton
                                    onClick={() => { downloadOnClick(url, article) }}
                                    className="text-lg font-medium rounded-full text-white px-8 py-3 cursor-pointer"
                                >
                                    Download
                                </GetStartedButton>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <Footer />
        </main>
    )
}