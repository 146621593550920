import { createContext, useState } from "react";

const AnswerContext = createContext({});

const AnswerProvider = ({ children }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [phone, setPhone] = useState('');
    const [b2b, setB2b] = useState(null);
    const [answer, setAnswer] = useState({
        lastName: '',
        firstName: '',
        reason: 0,
        needs: { q1: false, q2: false, q3: false, q4: false, q5: false },
        needsTxt: '',
        otherInfo: '',
        incareRelation: 0,
        incareFirstName: '',
        incareLastName: '',
        plan: 0,
        v: '2'
    });

    const setReason = (value) => {
        setAnswer({ ...answer, reason: value });
    };

    const setNeeds = (newNeeds) => {
        // const newAnswer = { ...answer, needs: value }
        let newAnswer = answer;
        newAnswer.needs['q1'] = newNeeds['q1'];
        newAnswer.needs['q2'] = newNeeds['q2'];
        newAnswer.needs['q3'] = newNeeds['q3'];
        newAnswer.needs['q4'] = newNeeds['q4'];
        newAnswer.needs['q5'] = newNeeds['q5'];
        setAnswer(newAnswer);
        // console.log('ctx new answer:' + JSON.stringify(newAnswer));
        // console.log('ctx answer:' + JSON.stringify(answer));
        // console.log('ctx value:' + JSON.stringify(newNeeds));
    };

    const setNeed = (need, value) => {
        answer.needs[need] = value;
        setAnswer({ ...answer })
    }

    const setNeedsTxt = (value) => {
        let newAnswer = { ...answer }
        newAnswer.needsTxt = value
        //setAnswer({ ...answer, needsTxt: value });
        setAnswer(newAnswer);
    };

    const setOtherInfo = (value) => {
        let newAnswer = { ...answer }
        newAnswer.otherInfo = value
        // setAnswer({ ...answer, otherInfo: value });
        setAnswer(newAnswer);
    }

    const setFirstName = (value) => {
        // console.log('before', answer)
        answer.firstName = value;
        // setAnswer({ ...answer, firstName: value });
        setAnswer({ ...answer });
        // console.log('after', answer)
    };

    const setLastName = (value) => {
        setAnswer({ ...answer, lastName: value });
    };

    const setIncareRelation = (value) => {
        setAnswer({ ...answer, incareRelation: value });
    };

    const setIncareFirstName = (name) => {
        answer.incareFirstName = name
        // setAnswer({ ...answer, incareFirstName: name });
        setAnswer({ ...answer });
    };

    const setIncareLastName = (name) => {
        setAnswer({ ...answer, incareLastName: name });
    };

    const setPlan = (plan) => {
        setAnswer({ ...answer, plan: plan });
    };


    return (
        <AnswerContext.Provider value={
            {
                answer, email, password, phone, b2b,
                setAnswer, setReason, setFirstName, setLastName,
                setIncareFirstName, setIncareLastName, setPlan, setEmail,
                setPassword, setNeeds, setNeed, setNeedsTxt, setIncareRelation,
                setOtherInfo, setPhone, setB2b
            }
        }>
            {children}
        </AnswerContext.Provider>
    )
}

export { AnswerProvider };
export default AnswerContext;