import React, { useState, useContext, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import _ from "lodash";
import moment from "moment";

import "antd/dist/antd.css";
import "../components/Navbar.css";

import { Badge, Spin } from "antd";

import {
  Roles,
  SubscriptionToColor,
  SubscriptionToStr,
} from "../constants/Global";

const ProfileRibbon = ({ cg, role, subscription }) => {
  // console.log(
  //   "***" +
  //     ProfileRibbon.name +
  //     " role=" +
  //     role +
  //     " subscription=" +
  //     subscription
  // );
  if (_.isEmpty(cg)) {
    return <Spin />;
  }
  // console.log("   cg\n", cg);
  switch (role) {
    case Roles.SUPER_CONCIERGE:
      return (
        <Badge.Ribbon
          text="Super CC"
          title="Role: Super Concierge"
          color="#217671c0"
          style={{ top: "-1rem" }}
        >
          {cg.firstName} {cg.lastName}
        </Badge.Ribbon>
      );
      break;
    case Roles.CONCIERGE:
      return (
        <Badge.Ribbon
          text="Care Concierge"
          title="Role: Care Concierge"
          color="#e0551bc0"
          style={{ top: "-1rem" }}
        >
          {cg.firstName} {cg.lastName}
        </Badge.Ribbon>
      );
      break;
    case Roles.CAREGIVER:
      return (
        <Badge.Ribbon
          text={SubscriptionToStr(subscription)}
          title="Current Subscription tier"
          color={SubscriptionToColor(subscription)}
          style={{ top: "-1rem" }}
        >
          {cg.firstName} {cg.lastName}
        </Badge.Ribbon>
      );
      break;
    default:
      return <>UNKNOWN</>;
  }
};

export default ProfileRibbon;
