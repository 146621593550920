import React, { forwardRef } from "react";
import _ from "lodash";
import moment from "moment";
import { RiUserFill } from "react-icons/ri";
import ChatAvatarIcon from "./ChatAvatarIcon";
import {
  MESSAGE_DELIVERY_STATUS,
  DEFAULT_MESSAGE_USER_ICON,
} from "../constants/Global";

const MessageStatus = ({
  status,
  sentAt,
  showStatus = true,
  showSentAt = true,
  sentByUser = false,
}) => {
  return (
    <div
      className={
        "flex flex-col-reverse text-black/60 text-xs" +
        (sentByUser ? " text-end" : "")
      }
    >
      {showSentAt && <span>{moment(sentAt).format("hh:mmA")}</span>}
      {showStatus && <span>{MESSAGE_DELIVERY_STATUS[status]}</span>}
    </div>
  );
};

const MessageBox = ({ txt, sentByUser = false }) => {
  return (
    <div
      className={
        "flex p-4 rounded-lg" +
        (sentByUser ? " bg-message-box-color" : " bg-white")
      }
    >
      <span className={sentByUser ? "text-white" : "text-new-primary-purple"}>
        {txt}
      </span>
    </div>
  );
};

const MessageItem = forwardRef(({
  messages,
  senderId,
  senderName,
  avatar,
  sentAt,
  status,
  showAvatar = false, // true, false, hidden
  showSenderName = false,
  sentByUser = false,
  showStatus = false,
  showSentAt = false,
}, ref) => {
  return (
    <div
      ref={ref}
      className={
        "flex min-h-[54px] gap-2" + (sentByUser ? " flex-row-reverse" : "")
      }
    >
      {showAvatar != false && (
        <ChatAvatarIcon
          size={32}
          Icon={!_.isNil(avatar) ? avatar : DEFAULT_MESSAGE_USER_ICON}
          iconStyle={{
            color: "#EBEBEB",
          }}
          avatarStyle={{
            background:
              "linear-gradient(146.51deg, #6D56BE 12.17%, #3C3066 100.04%)",
            border: "1px solid rgba(60, 48, 102, 0.6)",
            visibility: showAvatar === "hidden" ? "hidden" : "visible",
          }}
        />
      )}

      {_.isArray(messages) ? (
        <div
          className={
            "flex max-w-[75%] flex-1" + (sentByUser ? " flex-row-reverse" : "")
          }
        >
          <div
            className={
              "flex gap-2 break-all" +
              (sentByUser
                ? " flex-col-reverse items-end"
                : " flex-col items-start")
            }
          >
            {showSenderName && <span>{senderName}</span>}
            {messages.map((message, index) => {
              return (
                <div
                  key={message._id}
                  className={
                    "flex gap-2" +
                    (sentByUser ? " flex-row-reverse items-end" : " flex-row")
                  }
                >
                  <MessageBox txt={message.txt} sentByUser={sentByUser} />
                  <MessageStatus
                    status={status}
                    sentAt={sentAt}
                    showSentAt={showSentAt}
                    showStatus={showStatus}
                    sentByUser={sentByUser}
                  />
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className={
            "flex flex-1 gap-2" + (sentByUser ? " flex-row-reverse" : "")
          }
        >
          <div
            className={
              "flex max-w-[75%] gap-2 break-all" +
              (sentByUser
                ? " flex-col-reverse items-end"
                : " flex-col items-start")
            }
          >
            {showSenderName && <span>{senderName}</span>}
            <MessageBox txt={messages} sentByUser={sentByUser} />
          </div>
          <MessageStatus
            status={status}
            sentAt={sentAt}
            showSentAt={showSentAt}
            showStatus={showStatus}
            sentByUser={sentByUser}
          />
        </div>
      )}
    </div>
  );
});

export default MessageItem;
