import React, { useEffect } from "react";
import {
  Row,
  Col,
  Drawer,
  Avatar,
  Button,
  Divider,
  Checkbox,
  Input,
  Form,
  Modal,
  Switch,
  message,
  Spin,
  Tooltip,
} from "antd";
import CareTeamInfo, {
  convertAvatarName,
  convertCareRecipientName,
  subTitleText,
  getAvatarBorderColor,
} from "../../components/CareTeamInfo";
import _ from "lodash";
import moment from "moment";
import { openNotification } from "../../components/Various";
import {
  MessageFilled,
  EditFilled,
  CheckOutlined,
  CheckCircleOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { RiCoinsFill } from "react-icons/ri";
import {
  FaBriefcaseMedical,
  FaUserAlt,
  FaHome,
  FaUsers,
  FaChurch,
  FaHandshake,
} from "react-icons/fa";
import { GoLaw } from "react-icons/go";
import { BsFillCloudArrowUpFill } from "react-icons/bs";
import useMclAuth from "../../hooks/useMclAuth";
import useAuth from "../../hooks/useAuth";
import {
  Url,
  CAREPLAN_PERMISSIONS,
  PERMISSION_ICON_MAPPING,
  PERMISSION_ICON_COLOR_MAPPING,
  DEFAULT_CAREPLAN_PERMISSIONS,
  CAREPLAN_PERMISSIONS_ALL_ON,
} from "../../constants/Global";

import "./CareCircleTeam.css";
import { useForm } from "rc-field-form";

const PermissionModal = ({ permissionData, open, onOk, onCancel }) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    onOk(permissionData.permissionType, values, false);
  };

  const onApplyAll = () => {
    onOk(permissionData.permissionType, form.getFieldsValue(), true);
  };

  useEffect(() => {
    if (!_.isEmpty(permissionData)) {
      form.setFieldsValue(permissionData.permissions);
    }
  }, [permissionData, form]);

  return (
    <Modal
      title={`Edit ${_.startCase(
        _.camelCase(permissionData?.permissionType)
      )} Permissions`}
      open={open}
      onOk={form && form.submit}
      onCancel={onCancel}
      footer={[
        <Button
          key="back"
          onClick={onCancel}
          style={{
            borderRadius: "0.5rem",
            height: "40px",
          }}
          autoFocus={false}
          className="bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
        >
          Cancel
        </Button>,
        <Button
          key="save"
          style={{
            borderRadius: "0.5rem",
            height: "40px",
          }}
          autoFocus={false}
          className="text-xs font-bold text-white bg-[#4761C0] hover:bg-[#95a1d9]  hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
          onClick={form && form.submit}
        >
          Ok
        </Button>,
        <Button
          key="save-all"
          style={{
            borderRadius: "0.5rem",
            height: "40px",
          }}
          autoFocus={false}
          className="text-xs font-bold text-white bg-[#4761C0] hover:bg-[#95a1d9]  hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
          onClick={onApplyAll}
        >
          Apply to All Sections
        </Button>,
      ]}
    >
      <div className="mb-4">
        {!_.isNil(permissionData?.userName) &&
          `${permissionData?.userName} will be granted the following permissions:`}
      </div>
      <div className="flex gap-2 mb-4">
        <Button
          onClick={() => {
            form.setFieldsValue({
              read: false,
              write: false,
              delete: false,
              docUpload: false,
              docDownload: false,
              docDelete: false,
              docReplace: false,
            });
          }}
          style={{
            borderRadius: "0.5rem",
          }}
          autoFocus={false}
          className="bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
        >
          Turn All Off
        </Button>
        <Button
          onClick={() => {
            form.setFieldsValue({
              read: true,
              write: true,
              delete: true,
              docUpload: true,
              docDownload: true,
              docDelete: true,
              docReplace: true,
            });
          }}
          style={{
            borderRadius: "0.5rem",
          }}
          autoFocus={false}
          className="bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
        >
          Turn All On
        </Button>
      </div>
      <Form
        form={form}
        layout="horizontal"
        initialValues={permissionData?.permissions}
        onFinish={onFinish}
      >
        <Form.Item name="read" label="Read" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="write" label="Write" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item name="delete" label="Delete" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          name="docUpload"
          label="Document Upload"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="docDownload"
          label="Document Download"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="docDelete"
          label="Document Delete"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
        <Form.Item
          name="docReplace"
          label="Document Replace"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </Form>
    </Modal>
  );
};

const PermissionListItem = ({
  permissions,
  showDetail = true,
  onEdit,
  isEditingPermission,
  backgroundColor,
  permissisonIcon,
  permissisonText,
}) => {
  return (
    <div className="flex flex-col gap-2 border-solid rounded-lg border border-new-primary-purple/10 py-2 pl-2 pr-4">
      <div className="flex h-[64px] items-center">
        <Avatar
          size={40}
          className={
            "text-black font-bold flex justify-center items-center " +
            backgroundColor
          }
          icon={permissisonIcon}
        ></Avatar>
        <span className="text-base font-bold text-black ml-2">
          {permissisonText}
        </span>
        {isEditingPermission && (
          <Button
            shape="round"
            onClick={onEdit}
            className="ml-auto text-xs font-bold   hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
          >
            Edit
          </Button>
        )}
      </div>
      {!_.isNil(permissions) && showDetail && (
        <div className="flex flex-col gap-1 ">
          <div className="">
            Read:{" "}
            {permissions.read ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseOutlined className="text-red-700" />
            )}
          </div>
          <div className="">
            Write:{" "}
            {permissions.write ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseOutlined className="text-red-700" />
            )}
          </div>
          <div className="">
            Delete:{" "}
            {permissions.delete ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseOutlined className="text-red-700" />
            )}
          </div>
          <div className="">
            Document Upload:{" "}
            {permissions.docUpload ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseOutlined className="text-red-700" />
            )}
          </div>
          <div className="">
            Document Download:{" "}
            {permissions.docDownload ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseOutlined className="text-red-700" />
            )}
          </div>
          <div className="">
            Document Delete:{" "}
            {permissions.docDelete ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseOutlined className="text-red-700" />
            )}
          </div>
          <div className="">
            Document Replace:{" "}
            {permissions.docReplace ? (
              <CheckCircleOutlined className="text-green-700" />
            ) : (
              <CloseOutlined className="text-red-700" />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const InvitePermissionListItem = ({
  isPermitted,
  onChange,
  backgroundColor,
  permissisonIcon,
  permissisonText,
}) => {
  return (
    <div
      className={
        isPermitted
          ? "flex h-[56px] border-solid rounded-full border py-2 pl-2 pr-4 items-center cursor-pointer border-dark-purple/60"
          : "flex h-[56px] border-solid rounded-full border py-2 pl-2 pr-4 items-center cursor-pointer border-new-primary-purple/10"
      }
      onClick={onChange}
    >
      <Avatar
        size={36}
        className={
          isPermitted
            ? "flex justify-center items-center bg-new-primary-purple"
            : "flex justify-center items-center bg-transparent border-solid border border-new-primary-purple/10"
        }
        icon={isPermitted ? <CheckOutlined /> : null}
      ></Avatar>
      <span className="text-base font-bold text-black ml-2">
        {permissisonText}
      </span>
      <div className="ml-auto">
        <Avatar
          size={36}
          className={
            "text-black font-bold flex justify-center items-center " +
            backgroundColor
          }
          icon={permissisonIcon}
        ></Avatar>
      </div>
    </div>
  );
};

const getPermissionData = (circle, userId) => {
  let permissionArray = circle.permissions;
  if (_.isEmpty(circle.permissions)) {
    return DEFAULT_CAREPLAN_PERMISSIONS;
  }

  let permissionData = permissionArray.find((p) => {
    return p.user === userId;
  });

  if (_.isNil(permissionData)) {
    return DEFAULT_CAREPLAN_PERMISSIONS;
  } else {
    return permissionData.access;
  }
};

const convertMember = (member, circle, roleType) => {
  switch (roleType) {
    case "primary":
      return {
        ...member,
        displayingCircle: circle._id,
        roleInCircle: "primary",
        permissions: CAREPLAN_PERMISSIONS_ALL_ON, // For primary, have all permissions
      };
    case "secondary":
      return {
        ...member,
        displayingCircle: circle._id,
        roleInCircle: "secondary",
        permissions: CAREPLAN_PERMISSIONS_ALL_ON, // For secondary, have all permissions
      };
    case "pendings":
      return {
        ...member,
        displayingCircle: circle._id,
        roleInCircle: "pendings",
        permissions: getPermissionData(circle, member._id),
      };
    case "members":
      return {
        ...member,
        displayingCircle: circle._id,
        roleInCircle: "members",
        permissions: getPermissionData(circle, member._id),
      };
    case "invitations":
      return {
        ...member,
        displayingCircle: circle._id,
        roleInCircle: "invited",
      };
    default:
      throw new Error("Invalid role type");
  }
};

const convertCircle = (circle) => {
  return {
    ...circle,
    primary: !_.isNil(circle.primary)
      ? circle.primary.map((m) => {
          return convertMember(m, circle, "primary");
        })
      : [],
    secondary: !_.isNil(circle.secondary)
      ? convertMember(circle.secondary, circle, "secondary")
      : {},
    pendings: !_.isNil(circle.pendings)
      ? circle.pendings.map((m) => {
          return convertMember(m, circle, "pendings");
        })
      : [],
    members: !_.isNil(circle.members)
      ? circle.members.map((m) => {
          return convertMember(m, circle, "members");
        })
      : [],
    invitations: !_.isNil(circle.invitations)
      ? circle.invitations.map((m) => {
          return convertMember(m, circle, "invitations");
        })
      : [],
  };
};

const processMemberType = (circles) => {
  let newCircles = [];
  for (let c in circles) {
    let circle = circles[c];
    let newCircle = convertCircle(circle);
    newCircles.push(newCircle);
  }
  return newCircles;
};

const InviteDrawer = ({
  form = undefined,
  onFinish,
  onClose,
  open,
  success,
}) => {
  const [permissionModalData, setPermissionModalData] = React.useState({});
  const [permissions, setPermissions] = React.useState(
    DEFAULT_CAREPLAN_PERMISSIONS
  );

  const permissionListItemGenerator = (permission) => {
    return (
      <PermissionListItem
        key={permission}
        onEdit={() => {
          setPermissionModalData({
            permissionType: permission,
            permissions: permissions[permission],
          });
        }}
        isEditingPermission={true}
        backgroundColor={PERMISSION_ICON_COLOR_MAPPING[permission]}
        permissisonIcon={PERMISSION_ICON_MAPPING[permission]}
        permissisonText={_.startCase(_.camelCase(permission))}
      />
    );
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={onClose}
      open={open}
      contentWrapperStyle={{
        backgroundColor: "white",
      }}
      forceRender
    >
      <PermissionModal
        permissionData={permissionModalData}
        open={!_.isEmpty(permissionModalData)}
        onOk={(permissionType, permissions, applyAll) => {
          if (applyAll) {
            let newPermissions = {};
            for (let p in CAREPLAN_PERMISSIONS) {
              newPermissions[CAREPLAN_PERMISSIONS[p]] = permissions;
            }
            setPermissions(newPermissions);
            success("Applied to all sections");
          } else {
            setPermissions((prevState) => {
              return {
                ...prevState,
                [permissionType]: permissions,
              };
            });
          }
          setPermissionModalData({});
        }}
        onCancel={() => setPermissionModalData({})}
      />
      <Form
        form={form}
        name="invite-form"
        className="invite-form flex flex-col h-full"
        layout="vertical"
        onFinish={(values) => {
          onFinish(values, permissions);
        }}
        initialValues={{ permissions: [] }}
      >
        <div className="flex flex-col">
          <span className="text-lg font-semibold">Invite a member for </span>
          <span className="text-lg font-bold text-primary-purple">
            Min's Team
          </span>
        </div>
        <div className="flex flex-col mt-6">
          <span className="text-lg font-semibold text-new-primary-purple">
            Who are you inviting ?
          </span>
          <div className="flex flex-col flex-1 mt-4">
            <Form.Item
              name="fnameInvite"
              label={<span className="text-black/60 text-xs">FIRST NAME</span>}
              rules={[
                {
                  required: true,
                  message: "Please input the invitee's first name",
                },
              ]}
              style={{
                marginBottom: "8px",
              }}
            >
              <Input
                className="hover:border-primary-purple/60 focus:border-primary-purple/60"
                placeholder="Enter the first name"
                size={"large"}
                type="text"
                style={{
                  borderRadius: "0.5rem",
                }}
              />
            </Form.Item>
            <Form.Item
              name="lnameInvite"
              label={<span className="text-black/60 text-xs">LAST NAME</span>}
              rules={[
                {
                  required: true,
                  message: "Please input the invitee's last name",
                },
              ]}
              style={{
                marginBottom: "8px",
              }}
            >
              <Input
                className="hover:border-primary-purple/60 focus:border-primary-purple/60"
                placeholder="Enter the last name"
                size={"large"}
                type="text"
                style={{
                  borderRadius: "0.5rem",
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <span className="text-lg font-semibold text-new-primary-purple">
            How can we contact them ?
          </span>
          <div className="flex flex-col flex-1 mt-4">
            <Form.Item
              name="phoneInvite"
              label={
                <span className="text-black/60 text-xs">PHONE NUMBER (+1)</span>
              }
              rules={[
                {
                  pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                  message: "Please check the format of phone number",
                },
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
              style={{
                marginBottom: "8px",
              }}
            >
              <Input
                className="hover:border-primary-purple/60 focus:border-primary-purple/60"
                placeholder="555-555-5555"
                size={"large"}
                type="text"
                style={{
                  borderRadius: "0.5rem",
                }}
              />
            </Form.Item>
            <Form.Item
              name="emailInvite"
              label={<span className="text-black/60 text-xs">EMAIL</span>}
              rules={[
                {
                  type: "email",
                  message: "Please make sure the email address is valid",
                },
                {
                  required: true,
                  message: "Please input your email address",
                },
              ]}
              style={{
                marginBottom: "8px",
              }}
            >
              <Input
                className="hover:border-primary-purple/60 focus:border-primary-purple/60"
                placeholder="Enter the email"
                size={"large"}
                type="email"
                style={{
                  borderRadius: "0.5rem",
                }}
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex flex-col mt-4">
          <span className="text-lg font-semibold text-new-primary-purple">
            What can they do or see ?
          </span>
          <span className="text-xs text-new-primary-purple">
            You will select what sections from the Min’s Care plan can they see
            and if they can upload files
          </span>
          <div className="flex flex-col gap-3 mt-4 mb-4">
            {CAREPLAN_PERMISSIONS.map((permission) => {
              return permissionListItemGenerator(permission);
            })}
          </div>
        </div>
        <div className="flex flex-1 mt-auto gap-2">
          <Button
            style={{
              borderRadius: "0.5rem",
              height: "44px",
            }}
            onClick={onClose}
            className="flex-none rounded-lg mt-auto mb-4 font-medium border-new-primary-purple/30 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
          >
            Cancel
          </Button>
          <Button
            style={{
              borderRadius: "0.5rem",
              height: "44px",
            }}
            onClick={form && form.submit}
            className="flex-1 text-xs mt-auto mb-4 font-bold text-white bg-[#4761C0] hover:bg-[#95a1d9]  hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
          >
            Send Invitation
          </Button>
        </div>
      </Form>
    </Drawer>
  );
};

const DetailDrawer = ({
  cg,
  currentUserRole,
  detailDrawerUser,
  isEditingPermission,
  startEditingPermission,
  onFinishEditing,
  isSavingPermission,
  onSavePermission,
  onClose,
  open,
  success,
}) => {
  const [permissionModalData, setPermissionModalData] = React.useState({});
  const [permissions, setPermissions] = React.useState(
    DEFAULT_CAREPLAN_PERMISSIONS
  );
  const [hasChanged, setHasChanged] = React.useState(false);

  let isAdmin =
    currentUserRole === "primary" || currentUserRole === "secondary";

  let detailUserIsAdmin =
    detailDrawerUser.roleInCircle === "primary" ||
    detailDrawerUser.roleInCircle === "secondary";

  let isSelf = cg._id === detailDrawerUser._id;

  useEffect(() => {
    if (detailDrawerUser) {
      setHasChanged(false);
      setPermissions(detailDrawerUser.permissions);
    }
  }, [detailDrawerUser]);

  const permissionListItemGenerator = (user, permissionType) => {
    return (
      <PermissionListItem
        key={permissionType}
        onEdit={() => {
          setPermissionModalData({
            userName: convertCareRecipientName(user),
            permissionType: permissionType,
            permissions: _.get(permissions, permissionType),
          });
        }}
        permissions={_.get(permissions, permissionType)}
        isEditingPermission={isEditingPermission}
        backgroundColor={_.get(PERMISSION_ICON_COLOR_MAPPING, permissionType)}
        permissisonIcon={_.get(PERMISSION_ICON_MAPPING, permissionType)}
        permissisonText={_.startCase(_.camelCase(permissionType))}
      />
    );
  };

  const warning = () => {
    Modal.confirm({
      title: "Are you sure you want to discard the changes?",
      icon: <ExclamationCircleOutlined />,
      content: "The changes will not be saved",
      okType: "danger",
      onOk() {
        onClose();
      },
      onCancel() {},
    });
  };

  return (
    <Drawer
      placement="right"
      closable={false}
      onClose={() => {
        if (hasChanged) {
          warning();
        } else {
          onClose();
        }
      }}
      open={open}
      contentWrapperStyle={{
        backgroundColor: "white",
      }}
    >
      {isAdmin && (
        <PermissionModal
          userName={permissionModalData.userName}
          permissionData={permissionModalData}
          open={!_.isEmpty(permissionModalData)}
          onOk={(permissionType, permissions, applyAll) => {
            if (applyAll) {
              let newPermissions = {};
              for (let p in CAREPLAN_PERMISSIONS) {
                newPermissions[CAREPLAN_PERMISSIONS[p]] = permissions;
              }
              setHasChanged(true);
              setPermissions(newPermissions);
              success("Applied to all sections");
            } else {
              setHasChanged(true);
              setPermissions((prevState) => {
                return {
                  ...prevState,
                  [permissionType]: permissions,
                };
              });
            }
            setPermissionModalData({});
          }}
          onCancel={() => setPermissionModalData({})}
        />
      )}
      <div className="mt-8 flex flex-col flex-1 justify-center items-center p-2 hover:cursor-pointer">
        <Avatar
          className={
            "text-black font-bold before:content-[''] before:absolute before:top-0 before:left-0 before:w-full before:h-full before:rounded-full before:border-solid before:border-[3px] " +
            getAvatarBorderColor(detailDrawerUser.roleInCircle)
          }
          size={68}
          style={{
            backgroundColor: "rgba(159, 191, 255, 0.3)",
          }}
        >
          {convertAvatarName(detailDrawerUser)}
        </Avatar>
        <span className="text-dark-purple/80 text-lg font-bold mt-2">
          {convertCareRecipientName(detailDrawerUser)}
        </span>
        {subTitleText(detailDrawerUser, cg._id)}
      </div>
      <div className="mt-4 flex flex-1 justify-center items-center gap-2">
        {detailDrawerUser.roleInCircle === "pendings" && (
          <span className="rounded-xl py-2 px-4 text-xs font-bold text-dark-purple bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple">
            Pending Acceptance
          </span>
        )}
        {detailDrawerUser.roleInCircle === "invited" && (
          <span className="rounded-xl py-2 px-4 text-xs font-bold text-dark-purple bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple">
            Invited
          </span>
        )}
        {/* <Button
          shape="round"
          icon={<MessageFilled className="text-new-primary-purple" />}
          className="text-xs font-bold text-dark-purple bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
        >
          Message Reid
        </Button> */}
      </div>
      {detailDrawerUser.roleInCircle === "invited" && (
        <div className="mt-4 flex flex-col flex-1 justify-center gap-2 p-4 bg-dark-purple/5 text-dark-purple/8">
          <span className="text-xs font-bold">INVITATION SENT ON</span>
          <span className="text-base font-bold">
            {!_.isEmpty(detailDrawerUser.createdAt) &&
              moment(detailDrawerUser.createdAt).format(
                "YYYY/MM/DD, h:mm:ss a"
              )}
          </span>
          <span className="mt-2 text-xs font-bold">EMAIL SENT TO</span>
          <span className="text-base font-bold">{detailDrawerUser.email}</span>
        </div>
      )}

      <div className="mt-4 flex items-center py-2">
        {(isAdmin || isSelf) && (
          <div className="flex-1 mr-2">
            <Divider
              orientation="left"
              orientationMargin="0"
              plain
              className="messaging-divider"
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "12px",
                lineHeight: "20px",
                textAlign: "center",
                textTransform: "uppercase",
                color: "rgba(0, 0, 0, 0.6)",
                borderTopColor: "rgba(60, 48, 102, 0.3)",
                margin: 0,
                paddingLeft: 0,
                paddingTop: 8,
                paddingRight: 8,
                paddingBottom: 8,
              }}
            >
              Permissions
            </Divider>
          </div>
        )}
        {isAdmin && (
          <div className="ml-auto">
            {isEditingPermission ? (
              <Button
                style={{
                  borderRadius: "0.5rem",
                  height: "40px",
                }}
                onClick={onFinishEditing}
                className="rounded-lg mt-auto w-full font-medium bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
              >
                Cancel
              </Button>
            ) : (
              <Tooltip
                title={
                  detailUserIsAdmin || isSelf
                    ? "Sorry, you cannot change other admin's or your own permissions"
                    : "Edit user's careplan permissions"
                }
              >
                <Button
                  style={{
                    borderRadius: "0.5rem",
                    width: "40px",
                    height: "40px",
                  }}
                  autoFocus={false}
                  disabled={detailUserIsAdmin || isSelf}
                  icon={<EditFilled className="text-new-primary-purple" />}
                  className="text-xs font-bold text-dark-purple bg-dark-purple/10 hover:text-new-primary-purple hover:border-new-primary-purple focus:text-new-primary-purple focus:border-new-primary-purple"
                  onClick={startEditingPermission}
                ></Button>
              </Tooltip>
            )}
          </div>
        )}
      </div>

      {(cg._id === detailDrawerUser._id || isAdmin) && (
        <div className="mt-4 flex flex-col py-2">
          <span className="text-base font-bold text-black/80">Documents</span>
          <span className="text-sm text-black/80 mt-1">
            The team member can perform actions based on their permissions in
            these sections.
          </span>
          <div className="mt-2 flex flex-col gap-1">
            {CAREPLAN_PERMISSIONS.map((permission) => {
              return permissionListItemGenerator(detailDrawerUser, permission);
            })}
          </div>
        </div>
      )}
      {isEditingPermission && isAdmin && (
        <div className="mt-8 flex py-2">
          {/* <Button
        size="large"
        className="rounded-lg mt-auto w-full font-medium text-button-danger bg-button-danger/5 border-button-danger-light"
        danger
      >
        Remove Team Member
      </Button> */}
          <Button
            style={{
              borderRadius: "0.5rem",
              height: "40px",
            }}
            autoFocus={false}
            className="text-xs w-full font-bold text-white bg-[#4761C0] hover:bg-[#95a1d9]  hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
            onClick={() => {
              setHasChanged(false);
              onSavePermission(permissions, detailDrawerUser);
            }}
            loading={isSavingPermission}
          >
            Save Changes
          </Button>
        </div>
      )}
    </Drawer>
  );
};

const showUserRole = (currentUser, detailDrawerUser) => {
  if (currentUser._id === detailDrawerUser._id) {
    return <span className="text-sm">(You)</span>;
  } else if (detailDrawerUser.roleInCircle === "primary") {
    return <span className="text-sm">(Admin)</span>;
  } else {
    return <div></div>;
  }
};

const CareCircleTeam = ({ cg, showComponent }) => {
  const [detailDrawerUser, setDetailDrawerUser] = React.useState({});
  const [permissionModalData, setPermissionModalData] = React.useState({});
  const [isEditingPermission, setEditingPermission] = React.useState(false);
  const [isSavingPermission, setSavingPermission] = React.useState(false);
  const [circles, setCircles] = React.useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const successMessage = (content) => {
    messageApi.open({
      type: "success",
      content: content,
    });
  };
  const mclAuth = useMclAuth();
  const { auth } = useAuth();

  const [inviteDrawerCircle, setInviteDrawerCircle] = React.useState({});
  const [inviteForm] = Form.useForm();

  const submitInvitation = (values, permissions) => {
    let formData = {
      ...values,
      permissionInvite: permissions,
    };
    onSubmitInvitation(formData, inviteDrawerCircle._id);
  };

  useEffect(() => {
    update();
  }, []);

  // useEffect(() => {
  //   console.log(circles);
  // }, [circles]);

  const update = () => {
    mclAuth
      .get(Url.CAREGIVERS.concat("/", cg._id, "/careteams"))
      .then((res) => {
        if (!_.isNil(res.data.circles)) {
          setCircles(processMemberType(res.data.circles));
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const startEditingPermission = () => {
    setEditingPermission(true);
  };

  const onFinishEditing = () => {
    setEditingPermission(false);
  };

  const updateCircleInvitations = (
    circles,
    circleId,
    invitation,
    roleInCircle
  ) => {
    return circles.map((circle) => {
      if (circle._id === circleId) {
        const index = circle.invitations.findIndex(
          (inv) => inv._id === invitation._id
        );
        const updatedInvitations = Array.from(circle.invitations);
        if (index === -1) {
          // No existing invitation found, add the new invitation to the end of the array
          updatedInvitations.push({
            ...invitation,
            displayingCircle: circleId,
            roleInCircle,
          });
        } else {
          // An existing invitation was found, replace it with the new invitation
          updatedInvitations[index] = {
            ...invitation,
            displayingCircle: circleId,
            roleInCircle,
          };
        }
        return {
          ...circle,
          invitations: updatedInvitations,
        };
      }
      return circle;
    });
  };

  const updateCircle = (circles, newCircle) => {
    return circles.map((circle) => {
      if (circle._id === newCircle._id) {
        return convertCircle(newCircle);
      }
      return circle;
    });
  };

  const onSubmitInvitation = async (values, circleId) => {
    const submission = {
      ...values,
      circleInvite: circleId,
      roleInvite: "caregiver",
    };

    try {
      const res = await mclAuth.post(Url.CIRCLES.concat("/invite"), submission);

      if (res.data?._id !== undefined) {
        const newCircles = updateCircleInvitations(
          circles,
          circleId,
          res.data,
          "invited"
        );
        setCircles(newCircles);
        successMessage("Invitation sent!");
        inviteForm.resetFields();
        setInviteDrawerCircle({});
      }
    } catch (error) {
      console.log(error.message);
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Failed to send invitation",
      });
    }
  };

  const onSavePermission = (values, targetUser) => {
    // userId and invitationId will only have one value.
    let isInvite = targetUser.roleInCircle === "invited";
    let requestData = {
      userId:
        targetUser.roleInCircle !== "invited" ? targetUser._id : undefined,
      newPermissions: values,
      isInvite: isInvite,
      invitationId: isInvite ? targetUser._id : undefined,
    };
    setSavingPermission(true);
    mclAuth
      .post(
        Url.CIRCLES.concat("/", targetUser.displayingCircle, "/permissions"),
        requestData
      )
      .then((res) => {
        if (res.data) {
          if (isInvite) {
            const newCircles = updateCircleInvitations(
              circles,
              targetUser.displayingCircle,
              {
                ...targetUser,
                permissions: res.data,
              },
              "invited"
            );
            setCircles(newCircles);
          } else {
            const newCircles = circles.map((circle) => {
              if (circle._id === targetUser.displayingCircle) {
                const index = circle[targetUser.roleInCircle].findIndex(
                  (member) => member._id === targetUser._id
                );
                const updatedMembers = Array.from(circle[targetUser.roleInCircle]);
                updatedMembers[index] = {
                  ...targetUser,
                  permissions: res.data,
                };
                return {
                  ...circle,
                  [targetUser.roleInCircle]: updatedMembers,
                };
              }
              return circle;
            });
            setCircles(newCircles);
          }
          successMessage("Permissions updated!");
          onFinishEditing();
        }
      })
      .catch((error) => {
        console.log(error.message);
      })
      .finally(() => {
        setSavingPermission(false);
      });
  };

  const promoteOnClick = (circleId, targetUser) => {
    let data = {
      secondary: targetUser._id,
    };
    mclAuth
      .post(Url.CIRCLES.concat("/", circleId, "/secondary"), data)
      .then((res) => {
        // Update the circle
        const newCircles = updateCircle(circles, res.data);
        setCircles(newCircles);
        successMessage("The user has been promoted!");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const demoteOnClick = (circleId, targetUser) => {
    let data = {
      targetUser: targetUser._id,
    };
    mclAuth
      .post(Url.CIRCLES.concat("/", circleId, "/demoteSecondary"), data)
      .then((res) => {
        const newCircles = updateCircle(circles, res.data);
        setCircles(newCircles);
        successMessage("The user has been demoted!");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const removeOnClick = (circleId, targetUser) => {
    let data = {
      targetUser: targetUser._id,
      isInvite: targetUser.roleInCircle === "invited",
    };
    mclAuth
      .post(Url.CIRCLES.concat("/", circleId, "/remove"), data)
      .then((res) => {
        const newCircles = updateCircle(circles, res.data);
        setCircles(newCircles);
        successMessage("The user has been removed!");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const acceptOnClick = (circleId, targetUser) => {
    let data = {
      targetUser: targetUser._id,
    };
    mclAuth
      .post(Url.CIRCLES.concat("/", circleId, "/accept"), data)
      .then((res) => {
        const newCircles = updateCircle(circles, res.data);
        setCircles(newCircles);
        successMessage("The user has been accepted!");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const rejectOnClick = (circleId, targetUser) => {
    let data = {
      targetUser: targetUser._id,
    };
    mclAuth
      .post(Url.CIRCLES.concat("/", circleId, "/reject"), data)
      .then((res) => {
        const newCircles = updateCircle(circles, res.data);
        setCircles(newCircles);
        successMessage("The user has been rejected!");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  return (
    <div className="flex flex-col p-8">
      {contextHolder}
      <h1 className="text-2xl font-bold">Care Circle Teams</h1>
      <h4 className="text-text-color/60">
        The place where you manage all your care plans
      </h4>
      {/* Care circle teams */}
      <div className="mt-6 flex flex-col gap-8">
        {circles.map((circle) => {
          return (
            <CareTeamInfo
              key={circle._id}
              circle={circle}
              currentUserId={cg._id}
              currentUserRole={circle.currentUserRole}
              detailOnClick={setDetailDrawerUser}
              inviteOnClick={() => setInviteDrawerCircle(circle)}
              showComponent={showComponent}
              promoteOnClick={promoteOnClick}
              demoteOnClick={demoteOnClick}
              removeOnClick={removeOnClick}
              acceptOnClick={acceptOnClick}
              rejectOnClick={rejectOnClick}
            />
          );
        })}
      </div>
      <DetailDrawer
        cg={cg}
        onClose={() => {
          setDetailDrawerUser({});
        }}
        open={!_.isEmpty(detailDrawerUser)}
        success={successMessage}
        currentUserRole={
          _.find(circles, ["_id", detailDrawerUser.displayingCircle])
            ?.currentUserRole
        }
        detailDrawerUser={detailDrawerUser}
        isEditingPermission={isEditingPermission}
        isSavingPermission={isSavingPermission}
        onFinishEditing={onFinishEditing}
        startEditingPermission={startEditingPermission}
        onSavePermission={onSavePermission}
      />
      <InviteDrawer
        form={inviteForm}
        onFinish={submitInvitation}
        onClose={() => {
          setInviteDrawerCircle({});
        }}
        open={!_.isEmpty(inviteDrawerCircle)}
        success={successMessage}
      />
    </div>
  );
};

export default CareCircleTeam;
