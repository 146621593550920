import react, { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import _ from "lodash";

import {
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Space,
  Divider,
  Input,
  Button,
  Form,
  Steps,
  Select,
  DatePicker,
} from "antd";
import useAuth from "../../hooks/useAuth";
import useCg from "../../hooks/useCg";
import useMclAuth from "../../hooks/useMclAuth";

import { UsState, Url } from "../../constants/Global";
import moment from "moment";
import { openNotification } from "../../components/Various";

import InvitationSideCard from "../../components/InvitationSideCard";
import "./InvitationUserInfo.css";

const steps = [
  {
    tag: "info",
    title: "Is this your name?",
    subTitle: "Let us get to know you better",
    content: "First-content",
  },
  {
    tag: "contact",
    title: "How may we contact you?",
    subTitle: `Also {}, as we want to keep in touch with you`,
    content: "Second-content",
  },
];

const InvitationUserInfo = () => {
  const [loading, setLoading] = useState(false);

  const { auth } = useAuth();
  const mclAuth = useMclAuth();

  const [cg, setCg] = useState({});
  const navigate = useNavigate();
  const { state } = useLocation();
  const [user, setUser] = useState({});
  const [infoForm] = Form.useForm();
  const [contactForm] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [inviter, setInviter] = useState({});
  const [circle, setCircle] = useState({});
  const { USER_ID } = useParams();

  const next = () => {
    setCurrent(Math.min(steps.length - 1, current + 1));
  };

  const prev = () => {
    setCurrent(Math.max(0, current - 1));
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: "",
  }));

  useEffect(() => {
    console.log(auth?.id);
    console.log(state);
    if (!_.isNil(state.inviter)) {
      setInviter(state.inviter);
    }
    if (!_.isNil(state.circle)) {
      setCircle(state.circle);
    }
    mclAuth
      .get(Url.CAREGIVERS.concat("/", auth?.id))
      .then((res) => {
        console.log(res.data);
        setCg({
          firstName: res.data?.firstName,
          lastName: res.data?.lastName,
          phone: res.data?.phone,
        });
        // console.log('response', res.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (cg && cg.firstName && cg.lastName) {
      infoForm.setFieldsValue({
        firstName: cg.firstName,
        lastName: cg.lastName,
      });
    }
    if (cg && cg.phone) {
      contactForm.setFieldsValue({ phone: cg.phone });
    }
  }, [cg, infoForm, contactForm]);

  const onFinish = (values) => {
    let submissions = {
      ...values,
      birthYyyyMm: values.birthYyyyMm.format("YYYY-MM"),
    };
    console.log(submissions);
    setLoading(true);
    mclAuth
      .post(Url.CAREGIVERS.concat("/profile"), submissions)
      .then((res) => {
        console.log(res.data);
        openNotification({
          message: "Succeed!",
          status: "success",
          title:
            "Thank you for fill up the information! You will be redirected in a few seconds.",
          onClose: () => {
            navigate("/caregiver/dashboard", {
              replace: true,
            });
          },
        });
        // console.log('response', res.data)
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const OnChangeBirthYearMonth = (date, dateStr) => {
    console.log("+++++>date:", date);
    console.log("+++++>dateStr:", dateStr);
    if (_.isNil(date)) {
      infoForm.setFieldsValue({ birthYyyyMm: "" });
    } else {
      infoForm.setFieldsValue({ birthYyyyMm: moment(dateStr, "YYYY-MM") });
    }
  };
  const populateStateOptions = () => {
    const usStates = Object.keys(UsState);
    return (
      <>
        {usStates.map((state) => {
          // console.log("+++++>UsState[state]:", UsState[state]);
          return (
            <Select.Option
              key={state}
              value={state}
              style={{ color: "rgbA(16, 8, 47, 0.6)", fontWeight: 700 }}
            >
              {state}
            </Select.Option>
          );
        })}
        ;
      </>
    );
  };
  const onChangeZipCode = (e) => {
    // console.log("+++++>ZipCodeStr:", e.target.value);
    const zipCode = parseInt(e.target.value);
    // setCurrentZipCode(zipCodeStr);
    contactForm.setFieldsValue({ zipCode: e.target.value });
    // Need to handle special cases for Mississippi (Warren) and U.S. Virgin Island
    if (zipCode === 71233) {
      // setCurrentZipCode("71233");
      // setCurrentUsState("MS");
      contactForm.setFieldsValue({ state: "MS", zipCode: "71233" });
    } else {
      if (zipCode >= UsState["VI"].zipMin && zipCode <= UsState["VI"].zipMax) {
        // setCurrentZipCode(zipCodeStr);
        // setCurrentUsState("VI");
        contactForm.setFieldsValue({ state: "VI", zipCode: e.target.value });
      } else {
        Object.keys(UsState).map((state, idx) => {
          if (
            zipCode >= UsState[state].zipMin &&
            zipCode <= UsState[state].zipMax
          ) {
            // setCurrentZipCode(zipCodeStr);
            // setCurrentUsState(state);
            contactForm.setFieldsValue({
              state: state,
              zipCode: e.target.value,
            });
          }
        });
      }
    }
  };

  return (
    <div className="bg-dashboard-bg">
      <Row className="min-h-screen  2xl:container 2xl:mx-auto">
        <Col className="py-8 pl-8 xl:pr-8" xs={0} lg={10}>
          <div className="h-full">
            <InvitationSideCard inviter={inviter} circle={circle} />
          </div>
        </Col>
        <Col xs={24} lg={14}>
          <div className="flex flex-col h-full ant-lg:justify-center">
            <div className="w-full p-4  ant-lg:pl-16">
              <Steps
                size="mini"
                type="inline"
                current={current}
                items={items}
                style={{ paddingRight: 48 }}
              />
              <div className="mt-2">
                <span className="text-dark-purple/60 text-base leading-5">
                  {steps[current].tag === "contact"
                    ? steps[current].subTitle.replace("{}", user.firstName)
                    : steps[current].subTitle}
                </span>
              </div>
              <div className="mt-2">
                <span className="text-new-primary-purple text-3xl leading-9">
                  <b className="text-3xl">{steps[current].title}</b>
                </span>
              </div>
              <div className="mt-2">
                <Form
                  form={infoForm}
                  initialValues={{
                    firstName: user?.firstName,
                    lastName: user?.lastName,
                  }}
                >
                  <Space className="w-full" direction="vertical" size={8}>
                    {/* Name section */}
                    <div
                      className={
                        steps[current].tag !== "info"
                          ? "max-w-[450px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                          : "max-w-[450px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                      }
                    >
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          FIRST NAME{" "}
                          <span className="text-xs text-red-500">*</span>
                        </span>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your firstName",
                            },
                            {
                              max: 100,
                              message: "Please input less than 100 characters",
                            },
                          ]}
                          name="firstName"
                          hidden={steps[current].tag !== "info"}
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Enter your first name"
                            size={"large"}
                            bordered={false}
                            type="text"
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className={
                        steps[current].tag !== "info"
                          ? "max-w-[450px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                          : "max-w-[450px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                      }
                    >
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          LAST NAME
                        </span>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your firstName",
                            },
                            {
                              max: 100,
                              message: "Please input less than 100 characters",
                            },
                          ]}
                          name="lastName"
                          hidden={steps[current].tag !== "info"}
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Enter your last name"
                            size={"large"}
                            bordered={false}
                            type="text"
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className={
                        steps[current].tag !== "info"
                          ? "max-w-[450px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                          : "max-w-[450px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                      }
                    >
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          BIRTH YEAR & MONTH{" "}
                          <span className="text-xs text-red-500">*</span>
                        </span>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message:
                                "Please select your birth year and month",
                            },
                          ]}
                          name="birthYyyyMm"
                          hidden={steps[current].tag !== "info"}
                          style={{ marginBottom: 0 }}
                        >
                          <DatePicker
                            onChange={OnChangeBirthYearMonth}
                            picker="month"
                            bordered={false}
                            size="large"
                            placeholder="YYYY-MM"
                            className="flex grow userinfoDatePicker"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Space>
                </Form>
                <Form form={contactForm}>
                  <Space className="w-full" direction="vertical" size={8}>
                    {/* Contact section */}
                    <div
                      className={
                        steps[current].tag !== "contact"
                          ? "max-w-[350px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                          : "max-w-[350px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                      }
                    >
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          PHONE NUMBER (555-555-5555)
                        </span>
                        <Form.Item
                          rules={[
                            {
                              pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                              message:
                                "Please check the format of phone number",
                            },
                          ]}
                          name="phone"
                          hidden={steps[current].tag !== "contact"}
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Optional"
                            size={"large"}
                            bordered={false}
                            type="text"
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <Row gutter={[16, 16]} className="pr-4">
                      <Col xs={24} lg={10}>
                        <div
                          className={
                            steps[current].tag !== "contact"
                              ? "max-w-[350px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                              : "max-w-[350px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                          }
                        >
                          <div className="flex flex-col flex-1">
                            <span className="text-xs font-bold text-black/60">
                              CITY{" "}
                              <span className="text-xs text-red-500">*</span>
                            </span>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input the city you live in",
                                },
                                {
                                  max: 50,
                                  message:
                                    "Please input less than 50 characters",
                                },
                              ]}
                              name="city"
                              hidden={steps[current].tag !== "contact"}
                              style={{ marginBottom: 0 }}
                            >
                              <Input
                                placeholder="Plesae enter your city"
                                size={"large"}
                                bordered={false}
                                type="text"
                                style={{
                                  padding: 0,
                                  color: "rgbA(16, 8, 47, 0.6)",
                                  fontWeight: 700,
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={7}>
                        <div
                          className={
                            steps[current].tag !== "contact"
                              ? "max-w-[250px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                              : "max-w-[250px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                          }
                        >
                          <div className="flex flex-col flex-1">
                            <span className="text-xs font-bold text-black/60">
                              ZIPCODE{" "}
                              <span className="text-xs text-red-500">*</span>
                            </span>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message: "Please input your zip code",
                                },
                                {
                                  pattern: /^\d{5}$/,
                                  message: "Zip code must be 5 digits",
                                },
                              ]}
                              name="zipCode"
                              hidden={steps[current].tag !== "contact"}
                              style={{ marginBottom: 0 }}
                            >
                              <Input
                                placeholder="5 digits"
                                size={"large"}
                                bordered={false}
                                onChange={onChangeZipCode}
                                type="text"
                                style={{
                                  padding: 0,
                                  color: "rgbA(16, 8, 47, 0.6)",
                                  fontWeight: 700,
                                }}
                              />
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={7}>
                        <div
                          className={
                            steps[current].tag !== "contact"
                              ? "max-w-[250px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                              : "max-w-[250px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                          }
                        >
                          <div className="flex flex-col flex-1">
                            <span className="text-xs font-bold text-black/60">
                              STATE{" "}
                              <span className="text-xs text-red-500">*</span>
                            </span>
                            <Form.Item
                              rules={[
                                {
                                  required: true,
                                  message:
                                    "Please choose the stata where you reside",
                                },
                              ]}
                              name="state"
                              hidden={steps[current].tag !== "contact"}
                              style={{ marginBottom: 0 }}
                            >
                              <Select
                                bordered={false}
                                className="userInfoStateSelect"
                                placeholder="State"
                              >
                                {populateStateOptions()}
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div
                      className={
                        steps[current].tag !== "contact"
                          ? "max-w-[450px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                          : "max-w-[450px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                      }
                    >
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          ADDRESS LINE 1
                        </span>
                        <Form.Item
                          rules={[
                            {
                              max: 100,
                              message: "Please input less than 100 characters",
                            },
                          ]}
                          name="addr1"
                          hidden={steps[current].tag !== "contact"}
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Optional"
                            size={"large"}
                            bordered={false}
                            type="text"
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div
                      className={
                        steps[current].tag !== "contact"
                          ? "max-w-[450px] items-center min-h-[64px] px-4 py-3 rounded-lg bg-white hidden"
                          : "max-w-[450px]  items-center min-h-[64px] px-4 py-3 rounded-lg bg-white flex"
                      }
                    >
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          ADDRESS LINE 2
                        </span>
                        <Form.Item
                          rules={[
                            {
                              max: 100,
                              message: "Please input less than 100 characters",
                            },
                          ]}
                          name="addr2"
                          hidden={steps[current].tag !== "contact"}
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Optional"
                            size={"large"}
                            bordered={false}
                            type="text"
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                            }}
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </Space>
                </Form>
                <div className="flex gap-2 mt-6">
                  {current > 0 && (
                    <Button
                      loading={loading}
                      style={{
                        borderRadius: "0.5rem",
                        height: "44px",
                        paddingTop: "14px",
                        paddingBottom: "14px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                      }}
                      className="text-xs font-bold text-new-primary-purple border-new-primary-purple hover:bg-[#95a1d9]    focus:border-[#95a1d9] focus:text-[#4761C0]"
                      onClick={prev}
                    >
                      Go Back
                    </Button>
                  )}
                  {current < steps.length - 1 && (
                    <Button
                      style={{
                        borderRadius: "0.5rem",
                        height: "44px",
                        paddingTop: "14px",
                        paddingBottom: "14px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                      }}
                      className="text-xs font-bold text-white bg-[#4761C0] hover:bg-[#95a1d9]  hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
                      onClick={() => {
                        if (steps[current].tag === "info") {
                          infoForm
                            .validateFields()
                            .then(() => {
                              setUser(() => {
                                return {
                                  ...user,
                                  ...infoForm.getFieldsValue(),
                                };
                              });
                              next();
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      }}
                    >
                      Continue
                    </Button>
                  )}
                  {current === steps.length - 1 && (
                    <Button
                      style={{
                        borderRadius: "0.5rem",
                        height: "44px",
                        paddingTop: "14px",
                        paddingBottom: "14px",
                        paddingLeft: "40px",
                        paddingRight: "40px",
                      }}
                      loading={loading}
                      className="text-xs font-bold text-white bg-[#4761C0] hover:bg-[#95a1d9]  hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
                      onClick={() => {
                        if (steps[current].tag === "contact") {
                          contactForm
                            .validateFields()
                            .then(() => {
                              onFinish({
                                ...user,
                                ...contactForm.getFieldsValue(),
                              });
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        }
                      }}
                    >
                      Confirm
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InvitationUserInfo;
