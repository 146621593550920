import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Col, Row, Button } from 'antd';

import { Navbar } from "../../components/Navbar";
import { ToU, ToUModal } from "../../components/TermsOfUse"
import Footer from "../../components/Footer"

//import { Link } from "react-router-dom";

export const TermsOfUsePage = () => {
    const navigate = useNavigate();
    const [isModalVisible, setModalVisible] = useState(false);

    const handleOk = () => {
        console.log("Ok");
        setModalVisible(false);
    }
    const handleCancel = () => {
        console.log("Cancel");
        setModalVisible(false);
    }
    return (
        <main>
            <Navbar signin={true} navigate={navigate} />
            <section>
                <ToU />
            </section>
            <ToUModal
                isVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
            />
            {/* <Row className="mb-5">
                <Col xs={24} className="flex justify-center">
                    <Button
                        type="primary"
                        onClick={() => { setModalVisible(true); }}
                    >
                        Show modal
                    </Button>
                </Col>
            </Row> */}
            <Footer />
        </main >
    )
}