import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Link } from "react-router-dom";
import { Col, Row, Space } from "antd";
import _ from "lodash";
import {
  ARTICLE_IMAGE_PLACEHOLDER,
} from "../constants/Global";
import { getDefaultLibraryImage, getLibraryImage } from "./Various";

const AdjacentLibraryResourceCard = ({ content, position, type = "article" }) => {
  let defaultImageLink = getDefaultLibraryImage(content.categories);

  return (
    <>
      <div className="absolute rounded-lg bg-center bg-no-repeat bg-cover shadow-md inline-block w-full h-72"
        style={{ backgroundImage: `url('${_.isEmpty(content.featuredImage) ? defaultImageLink : getLibraryImage(content.featuredImage, type)}')` }}
      />
      <div className="absolute rounded-lg bg-center bg-gradient-to-b opacity-50 from-gray-400 via-gray-700 to-black w-full h-72" />
      <div className="flex flex-col rounded-lg p-4 items-center justify-center absolute w-full h-full">
        {
          type == "article" &&
          <p className="text-white text-shadow font-semibold text-xs">{moment(content.date).format('MMM DD, YYYY')}</p>
        }
        {
          (type == "video" && !_.isNil(content.duration)) &&
          <p className="text-white text-shadow font-semibold text-xs">Duration: {content.duration}</p>
        }
        <p className="text-white text-shadow font-semibold text-2xl text-center">{content.title}</p>
      </div>
      <Link to={`/${type}/${content.url}`}><span className="z-10 cursor-pointer absolute w-full h-full" /></Link>
      {position === 'LEFT' && (
        <div className="absolute arrow-btn bottom-5 text-center px-3 py-2 cursor-pointer bg-button-orange left-4 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M10 19l-7-7m0 0l7-7m-7 7h18" />
          </svg>
        </div>
      )}
      {position === 'RIGHT' && (
        <div className="absolute arrow-btn bottom-5 text-center px-3 py-2 cursor-pointer bg-button-orange right-4 rounded-full">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white w-full" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
          </svg>
        </div>
      )}
    </>
  )
};

const AdjacentLibraryResource = ({ adjacentLibraryResource, type = "article" }) => {
  // useEffect(() => {
  //   getAdjacentPosts(createdAt, category).then((result) => {
  //     setAdjacentPost(result);
  //     setDataLoaded(true);
  //   });
  // }, [createdAt, category]);

  return (
    <Row gutter={[32, 32]}>
      {!_.isEmpty(adjacentLibraryResource) && (
        <>
          {adjacentLibraryResource.prev && (
            <Col flex={1}>
              <div className={`${adjacentLibraryResource.next ? 'col-span-1 lg:col-span-4' : 'col-span-1 lg:col-span-8'} adjacent-post rounded-lg relative h-72`}>
                <AdjacentLibraryResourceCard content={adjacentLibraryResource.prev} position="LEFT" type={type} />
              </div>
            </Col>
          )}
          {adjacentLibraryResource.next && (
            <Col flex={1}>
              <div className={`${adjacentLibraryResource.prev ? 'col-span-1 lg:col-span-4' : 'col-span-1 lg:col-span-8'} adjacent-post rounded-lg relative h-72`}>
                <AdjacentLibraryResourceCard content={adjacentLibraryResource.next} position="RIGHT" type={type} />
              </div>
            </Col>
          )}
        </>
      )}
    </Row>
  );
};


export default AdjacentLibraryResource;