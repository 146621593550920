import React, { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, Form, Input, Row, Select } from "antd";

import useMclAuth from "../../hooks/useMclAuth";
import useAuth from "../../hooks/useAuth";
import { Url } from "../../constants/Global";
import CircleSettings from "./CircleSettings";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";

function CareTeam({ cg, currentCircle, myCircles }) {
  const [carename, setCarename] = useState("Anonymous");
  const [primaryNames, setPrimaryNames] = useState([]);
  const [memberNames, setMemberNames] = useState([]);
  const [pendingNames, setPendingNames] = useState([]);
  const [pendingId, setPendingId] = useState([]);
  const [form] = Form.useForm();

  const mclAuth = useMclAuth();
  const { auth, setAuth } = useAuth();

  useEffect(() => {
    update();
  }, []);

  const update = () => {
    mclAuth
      .get(Url.CAREGIVERS.concat("/", auth?.id))
      .then((res) => {
        mclAuth
          .get(
            Url.CIRCLES.concat(
              "/circle/",
              _.isUndefined(myCircles) ||
                myCircles.length < 1 ||
                _.isNil(currentCircle)
                ? res.data.circles[0]._id
                : myCircles[currentCircle]._id
            )
          )
          .then((res) => {
            setCarename(res.data.firstName);
            let getPrimaryName = [];
            let count1 = res.data.primary.length;
            if (count1 == 0) {
              setPrimaryNames(getPrimaryName);
            }
            res.data.primary.map((primaryCaregiver, index) => {
              mclAuth
                .get(Url.CIRCLES.concat("/username/", primaryCaregiver))
                .then((res) => {
                  getPrimaryName[index] = res.data;
                  count1 -= 1;
                  if (count1 == 0) {
                    setPrimaryNames(getPrimaryName);
                  }
                });
            });

            let getMemberNames = [];
            let count2 = res.data.members.length;
            if (count2 == 0) {
              setMemberNames(getMemberNames);
            }
            res.data.members.map((memberCaregiver, index) => {
              mclAuth
                .get(Url.CIRCLES.concat("/username/", memberCaregiver))
                .then((res) => {
                  getMemberNames[index] = res.data;
                  count2 -= 1;
                  if (count2 == 0) {
                    setMemberNames(getMemberNames);
                  }
                });
            });

            let getPendingNames = [];
            let getPendingIDs = [];
            let count3 = res.data.pendings.length;
            if (count3 == 0) {
              setPendingNames(getPendingNames);
              setPendingId(getPendingIDs);
            }
            res.data.pendings.map((pendingCaregiver, index) => {
              mclAuth
                .get(Url.CIRCLES.concat("/username/", pendingCaregiver))
                .then((res) => {
                  getPendingNames[index] = res.data;
                  getPendingIDs[index] = pendingCaregiver;
                  count3 -= 1;
                  if (count3 == 0) {
                    setPendingNames(getPendingNames);
                    setPendingId(getPendingIDs);
                  }
                });
            });
          })
          .catch((error) => {
            console.log(error.message);
          });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const onFinish = (value) => {
    mclAuth
      .post(Url.CIRCLES.concat("/invite"), {
        fnameInvite: value.fname,
        lnameInvite: value.fname,
        emailInvite: value.email,
        phoneInvite: value.phone,
        roleInvite: value.role,
        messageInvite: value.message,
      })
      .then((res) => {
        alert("invitation send to " + value.email);
        form.resetFields();
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const onFinishFailed = (errorInfo) => {
    alert("Failed:", errorInfo);
  };

  const acceptPending = (pendingCaregiver) => {
    mclAuth
      .get(Url.CIRCLES.concat("/accept/", pendingCaregiver))
      .then((res) => {
        alert(res.data);
        update();
      });
  };

  const rejectPending = (pendingCaregiver) => {
    mclAuth
      .get(Url.CIRCLES.concat("/reject/", pendingCaregiver))
      .then((res) => {
        alert(res.data);
        update();
      });
  };

  return (
    <div className="p-6">
      <h1
        style={{
          fontSize: "x-large",
          fontWeight: "bold",
          color: "#30225f",
        }}
      >
        Care Team for <strong>{carename}</strong>
      </h1>
      <Row
        className="m-0 border-2 border-solid	"
        style={{ maxWidth: "75rem", marginRight: "5rem" }}
      >
        <Col md={6} className="p-2 border-2 border-solid">
          <strong className="text-lg">Caregivers</strong>
          {primaryNames.map((primaryName) => {
            return (
              <div key={uuidv4()}>
                <p>{primaryName} (primary)</p>
              </div>
            );
          })}
          {memberNames.map((memberCaregiver) => {
            return (
              <div key={uuidv4()}>
                <p>{memberCaregiver}</p>
              </div>
            );
          })}
          {pendingNames.map((pendingCaregiver, index) => {
            return (
              <div key={uuidv4()}>
                <p>{pendingCaregiver} (pending)</p>
                <Button onClick={() => acceptPending(pendingId[index])}>
                  Accept
                </Button>
                <Button onClick={() => rejectPending(pendingId[index])}>
                  Reject
                </Button>
              </div>
            );
          })}
        </Col>
        <Col md={6} className="p-2 border-2 border-solid">
          <strong className="text-lg">Service Providers</strong>
        </Col>
        <Col md={12} className="p-2 border-2 border-solid">
          <strong className="text-lg">Invite Guests</strong>
          <Form
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              label="First name"
              name="fname"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Last name"
              name="lname"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>

            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input />
            </Form.Item>

            <Form.Item label="Cell phone" name="phone" rules={[{}]}>
              <Input />
            </Form.Item>

            <Form.Item label="Role" name="role" rules={[{ required: true }]}>
              <Select>
                <Select.Option value="caregiver">Caregiver</Select.Option>
                <Select.Option value="service provider">
                  Service Provider
                </Select.Option>
              </Select>
            </Form.Item>

            <Form.Item
              label="Leave a message:"
              name="message"
              rules={[{ required: true }]}
            >
              <Input.TextArea />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 18, span: 6 }}>
              <Button type="primary" htmlType="submit" className="rounded-md	">
                Invite
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <Row
        className="m-0 text-[0.75rem]"
        style={{ maxWidth: "75rem", marginRight: "5rem" }}
      >
        <CircleSettings
          cg={cg}
          circle={myCircles[currentCircle]}
          circleId={myCircles[currentCircle]._id}
        />
      </Row>
      <Row style={{ marginTop: "1rem" }}>
        <h5>
          To read our Privacy Policy, click{" "}
          {/* <a href="https://mycarelinq.com/privacy-policy" target="_blank"> */}
          <a href="/privacy-policy" target="_blank">
            here
          </a>
          .
        </h5>
      </Row>
      {/* <Row>
        <h6
          style={{
            backgroundColor: "rgba(48, 34, 95, 70%)",
            textAlign: "center",
            position: "fixed",
            left: "0",
            bottom: "0",
            height: "1rem",
            width: "100%",
            color: "white",
          }}
        >
          Copyright &copy; 2021, 2022{" "}
          <a href="https://mycarelinq.com" style={{ color: "#e0551b" }}>
            My CareLinq, Inc
          </a>
          . All Rights Reserved
        </h6>
      </Row> */}
    </div>
  );
}

export default CareTeam;
