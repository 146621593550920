import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Card, Button, Table, Tag, Breadcrumb, notification, Result } from 'antd';
import _ from "lodash";
import moment from 'moment';
import useMclAuth from '../../hooks/useMclAuth';
import useAuth from '../../hooks/useAuth';
import { Url, SubscriptionToColor, SubscriptionToStr, DispositionCode, Subscription } from '../../constants/Global';

const openNotification = ({ message, status, title, subTitle }) => {
    // console.log(message, title, status, subTitle)
    notification.open({
        message: message,
        description:
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
            /* extra={<Button type="primary">Back Home</Button>}*/
            />

    });
};

const ClientsTable = ({ data, setSelectedClients }) => {
    const renderTier = (subscription) => {
        return (
            <Tag color={SubscriptionToColor(subscription)}>
                {SubscriptionToStr(subscription).toUpperCase()}
            </Tag>
        )
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelectedClients(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
        }),
    };

    const columns = [
        {
            title: 'Type',
            key: 'subscription',
            dataIndex: 'subscription',
            render: (subscription) => (
                <>
                    {renderTier(subscription)}
                </>
            ),
            filters:
                Object.entries(Subscription).map((item) => {
                    return ({
                        text: SubscriptionToStr(item[1]),
                        value: item[1],
                    })
                })
            ,
            onFilter: (value, record) => record.subscription === value,
        },
        {
            title: 'Primary Caregiver',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Disposition',
            dataIndex: 'disposition',
            key: 'disposition',
            render: (disposition) => DispositionCode[disposition]
        },
        {
            title: 'Last Consultation Date',
            key: 'consultDate',
            dataIndex: 'consultDate',
            render: (consultDate) => consultDate ? moment(consultDate).format('MMMM Do YYYY, h:mm:ss a') : "",
            sorter: (a, b) => moment(a.consultDate).unix() - moment(b.consultDate).unix()
        },
    ];
    return (
        <Table
            rowSelection={{
                type: "checkbox",
                ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
        />
    )
}

const MyCCDetail = ({ currentCC, setSelectedClients }) => {

    const mclAuth = useMclAuth()
    const { auth } = useAuth()

    const [cg, setCg] = useState({})

    return (
        <>
            <ClientsTable
                data={currentCC.clients}
                setSelectedClients={setSelectedClients}
            />
        </>
    )
}

export default MyCCDetail