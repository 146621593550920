import React, { useEffect, useState, useCallback } from "react";

import {
  Collapse,
  Radio,
  Space,
  Input,
  Divider,
  Checkbox,
  DatePicker,
  Upload,
  Button,
  Form,
  Tooltip,
  Row,
  Skeleton,
  Result,
  notification,
} from "antd";
import {
  CheckCircleFilled,
  QuestionCircleFilled,
  MinusCircleOutlined,
  UploadOutlined,
  DeleteOutlined,
  PlusOutlined,
  MessageTwoTone,
} from "@ant-design/icons";

import moment from "moment";

import {
  CareType,
  Subscription,
  Url,
  SAVE_TIME_PERIOD,
  categoryToPage,
  FormDestination,
  DATE_FORMAT,
  DATE_RANGE_SEP,
} from "../../constants/Global";

import useCg from "../../hooks/useCg";
import useMclAuth from "../../hooks/useMclAuth";

import { mclOpen } from "../../api/Mcl";
import FeedbackFormModal from "../../components/FeedbackFormModal";
import _, { pullAllWith } from "lodash";

import { debounce } from "lodash";

import "./GiverPlan.css";
import { Link } from "react-router-dom";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const { Panel } = Collapse;
const { TextArea } = Input;
const { RangePicker } = DatePicker;

const openNotification = ({ message, status, title, subTitle }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: <Result status={status} title={title} subTitle={subTitle} />,
    placement: "top",
    duration: 6,
  });
};

const PanelState = ({ plan, setPlan, section }) => {
  const or_arr = (accumulator, curr) => accumulator || curr;

  let done = 0;
  let left = 0;

  // console.log('before done:', done, 'left:', left)
  // console.log('done:', done)

  switch (section) {
    case "c1":
      left = 11;
      done +=
        plan[section]["firstName"] !== "" && plan[section]["lastName"] !== ""
          ? 1
          : 0;

      done += plan[section]["birthDate"] !== "" ? 1 : 0;

      done +=
        (plan[section]["addr_street"] !== "" ||
          plan[section]["addr_street2"] !== "") &&
        plan[section]["addr_city"] !== "" &&
        plan[section]["addr_state"] !== "" &&
        plan[section]["addr_postal"] !== "" &&
        plan[section]["addr_country"] !== ""
          ? 1
          : 0;

      done += plan[section]["phone"] !== "" ? 1 : 0;

      done += plan[section]["primary_language"] !== "" ? 1 : 0;

      done += plan[section]["formal_titles"] !== "" ? 1 : 0;

      done += plan[section]["pronouns"] !== "" ? 1 : 0;

      done +=
        (plan[section]["partner"] === 1 &&
          plan[section]["partnerFirst"] !== "" &&
          plan[section]["partnerLast"] !== "") ||
        plan[section]["partner"] > 1 ||
        plan[section]["partnerOther"] !== ""
          ? 1
          : 0;

      done +=
        plan[section]["independent"] || plan[section]["independentOther"] !== ""
          ? 1
          : 0;

      done +=
        plan[section]["work"] || plan[section]["workOther"] !== "" ? 1 : 0;

      done += plan[section]["situation"] !== "" ? 1 : 0;
      break;
    case "c2":
      left = 7;
      done += plan[section]["birthCert"] !== "" ? 1 : 0;
      done += plan[section]["marriageCert"] !== "" ? 1 : 0;
      done += plan[section]["deathCert"] !== "" ? 1 : 0;
      done += plan[section]["divorceDoc"] !== "" ? 1 : 0;

      if (
        plan[section]["militaryBranch"] !== "" ||
        plan[section]["militaryIdVa"] !== "" ||
        plan[section]["militaryVetDoc"] ||
        plan[section]["militaryDates"] !== "" ||
        plan[section]["militaryDoc"] !== ""
      ) {
        done += 1;
        left += 1;
      } /* optional */

      if (
        plan[section]["driverState"] !== "" ||
        plan[section]["driverLicense"] !== "" ||
        plan[section]["driverDate"] !== ""
      ) {
        left += 1;
        if (
          plan[section]["driverState"] !== "" &&
          plan[section]["driverLicense"] !== "" &&
          plan[section]["driverDate"] !== ""
        ) {
          done += 1;
        }
      }

      if (plan[section]["donorInstruct"] !== "") {
        done += 1;
        left += 1;
      }

      if (
        plan[section]["passportNumber"] !== "" ||
        plan[section]["passportDate"] !== ""
      ) {
        left += 1;
        if (
          plan[section]["passportNumber"] !== "" &&
          plan[section]["passportDate"] !== ""
        ) {
          done += 1;
        }
      }

      if (
        plan[section]["contact"] === 2 ||
        (plan[section]["contact"] === 1 &&
          plan[section]["contactAddress"] !== "") ||
        (plan[section]["contact"] === 3 && plan[section]["contactOther"] !== "")
      ) {
        done += 1;
      }

      done += plan[section]?.contactEmergency?.length ? 1 : 0;

      done += plan[section]["detailsPersonal"] !== "" ? 1 : 0;

      // done += plan[section]['q1'].reduce(or_arr) || plan[section]['q1_txt'] !== '' ? 1 : 0
      // done += plan[section]['q2_txt'] !== '' ? 1 : 0
      // done += plan[section]['q3_txt'] !== '' ? 1 : 0
      // done += plan[section]['q4_txt'] !== '' ? 1 : 0
      // done += plan[section]['q5'].reduce(or_arr) || plan[section]['q5_txt'] !== '' ? 1 : 0
      // done += plan[section]['q6_txt'] !== '' ? 1 : 0
      // done += plan[section]['q7'] ? 1 : 0
      break;
    case "c3":
      left += 1;
      done += plan[section]?.propertyHome?.length ? 1 : 0;
      if (plan[section]["propertyOwn"]) {
        if (plan[section]["propertyOwn"] === 3) {
          if (plan[section]["propertyDetails"] !== "") done += 1;
        } else {
          done += 1;
        }
      }

      left += 1;
      if (plan[section]["rentDetails"] !== "") {
        left += 1;
        done += 1;
      }

      left += 1;
      done +=
        plan[section]["utilPowerName"] !== "" &&
        plan[section]["utilPowerNumber"] !== ""
          ? 1
          : 0;
      left += 1;
      done +=
        plan[section]["utilGasName"] !== "" &&
        plan[section]["utilGasNumber"] !== ""
          ? 1
          : 0;
      left += 1;
      done +=
        plan[section]["utilCableName"] !== "" &&
        plan[section]["utilCableNumber"] !== ""
          ? 1
          : 0;
      left += 1;
      done +=
        plan[section]["utilWaterName"] !== "" &&
        plan[section]["utilWaterNumber"] !== ""
          ? 1
          : 0;
      left += 1;
      done += plan[section]["utilReceive"] ? 1 : 0;
      left += 1;
      done += plan[section]["utilPerson"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["utilPlan"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["phoneTeleName"] !== "" &&
        plan[section]["phoneTeleNumber"] !== ""
          ? 1
          : 0;
      left += 1;
      done +=
        plan[section]["phoneMobileName"] !== "" &&
        plan[section]["phoneMobileNumber"] !== ""
          ? 1
          : 0;
      left += 1;
      done += plan[section]["phoneReceive"] ? 1 : 0;
      left += 1;
      done += plan[section]["phonePerson"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["phonePlan"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["homeInsureAgent"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["homeOwnerPolicy"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["homeQuakePolicy"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["homeFloodPolicy"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["homeFirePolicy"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["homeCost"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["personalPolicy"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["personalPremium"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["garbageDay"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["housekeepName"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["housekeepNumber"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["housekeepFrequency"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["housekeepCost"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["gardenerName"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["gardenerNumber"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["gardenerFrequency"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["gardenerCost"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["homeConcern"] !== "" ? 1 : 0;

      // done += plan[section]['q1'].reduce(or_arr) || plan[section]['q1_txt'] !== '' ? 1 : 0
      // done += plan[section]['q2'] ? 1 : 0
      // done += plan[section]['q3_txt'] !== '' ? 1 : 0
      // done += plan[section]['q4_txt'] !== '' ? 1 : 0
      // done += plan[section]['q5_txt'] !== '' ? 1 : 0
      // done += plan[section]['q6_txt'] !== '' ? 1 : 0
      // done += plan[section]['q7_txt'] !== '' ? 1 : 0
      // done += plan[section]['q8'] ? 1 : 0
      break;
    case "c4":
      left += 1;
      done += plan[section]?.pets?.length ? 1 : 0;

      left += 1;
      done += plan[section]["vetName"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["vetLocation"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["vetNumber"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["petInsurance"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["petInstruct"] !== "" ? 1 : 0;

      break;
    case "c5":
      left += 1;
      done += plan[section]?.vehicles?.length ? 1 : 0;

      left += 1;
      if (plan[section]["drive"]) {
        if (plan[section]["drive"] === 2) {
          done += 1;
        } else if (plan[section]["driveCircumstance"] !== "") {
          done += 1;
        }
      }

      left += 1;
      done += plan[section]?.transports?.length ? 1 : 0;

      break;
    case "c6":
      left += 1;
      done +=
        plan[section]["communicate"].reduce(or_arr) ||
        plan[section]["communicateOther"] !== ""
          ? 1
          : 0;
      left += 1;
      done +=
        plan[section]["converse"].reduce(or_arr) ||
        plan[section]["converseOther"] !== ""
          ? 1
          : 0;

      break;
    case "c7":
      left += 1;
      done += plan[section]["insureName"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["insureNumber"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["insurePolicy"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["medicalGroup"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["medicalNumber"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["medicareNumber"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["medicareSavings"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["medicaidNumber"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["credentials"] || plan[section]["credentialsText"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]?.plans?.length ? 1 : 0;

      left += 1;
      done += plan[section]["ss"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["survivor"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["disability"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["employerPostRetire"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["other"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["physicianName"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["physicianPhone"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["physicianEmail"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]?.specialists?.length ? 1 : 0;

      left += 1;
      done += plan[section]?.conditions?.length ? 1 : 0;

      left += 1;
      done += plan[section]?.integratives?.length ? 1 : 0;

      left += 1;
      done += plan[section]?.hospitalclinics?.length ? 1 : 0;

      left += 1;
      done +=
        plan[section]["hipaa"] || plan[section]["hipaaInstruct"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["sustain"] || plan[section]["sustainInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["directive"] || plan[section]["directiveInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["dnr"] || plan[section]["dnrInstruct"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["responsibleName"] !== "" &&
        plan[section]["responsibleContact"] !== ""
          ? 1
          : 0;
      left += 1;
      done +=
        plan[section]["responsibleDelivery"] ||
        plan[section]["responsibleOther"] !== ""
          ? 1
          : 0;

      break;
    case "c8":
      left += 1;
      done += plan[section]?.medicines?.length ? 1 : 0;

      left += 1;
      done += plan[section]["pharmacyName"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["pharmacyAddress"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["pharmacyPhone"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["pharmacyHours"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["alternateName"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["alternateAddress"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["alternatePhone"] !== "" ? 1 : 0;
      left += 1;
      done += plan[section]["alternateHours"] !== "" ? 1 : 0;

      left += 1;
      if (plan[section]["manage"]) {
        if (
          plan[section]["manage"] === 1 &&
          plan[section]["manageName"] !== ""
        ) {
          done += 1;
        } else if (
          plan[section]["manage"] === 3 &&
          plan[section]["manageOther"] !== ""
        ) {
          done += 1;
        }
      }

      left += 1;
      done += plan[section]["medicineInstruct"] !== "" ? 1 : 0;

      break;
    case "c9":
      left += 1;
      done += plan[section]?.personals?.length ? 1 : 0;

      left += 1;
      done += plan[section]["personalInstruct"] !== "" ? 1 : 0;

      break;
    case "c10":
      left += 1;
      done += plan[section]["equipment"].reduce(or_arr) ? 1 : 0;

      left += 1;
      done +=
        plan[section]["ownership"] || plan[section]["ownershipDetails"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["acquire"] || plan[section]["acquireOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["dmeInstruct"] !== "" ? 1 : 0;

      break;
    case "c11":
      left += 1;
      done += plan[section]["activities"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]?.communities?.length ? 1 : 0;

      left += 1;
      done += plan[section]?.online?.length ? 1 : 0;

      left += 1;
      done +=
        plan[section]["spiritual"] || plan[section]["spiritualOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["therapist"] || plan[section]["therapistOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["activityFuture"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["tools"] !== "" ? 1 : 0;

      break;
    case "c12":
      left += 1;
      done +=
        plan[section]["goals"].reduce(or_arr) ||
        plan[section]["goalsOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["healthStatus"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["communityServices"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["activities"].reduce(or_arr) ||
        plan[section]["activitiesOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["challenges"].reduce(or_arr) ||
        plan[section]["challengesOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["services"].reduce(or_arr) ||
        plan[section]["servicesOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["dietaryNeeds"] !== "" ? 1 : 0;

      break;
    case "c13":
      left += 1;
      done += plan[section]?.pets?.length ? 1 : 0;

      left += 1;
      done +=
        plan[section]["lawyerName"] !== "" &&
        plan[section]["lawyerPhone"] !== "" &&
        plan[section]["lawyerEmail"] !== "" &&
        plan[section]["lawyerInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["documents"].reduce(or_arr) &&
        plan[section]["documentsInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["topics"].reduce(or_arr) ||
        plan[section]["topicsOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["medicalAttyName"] !== "" &&
        plan[section]["medicalAttyPhone"] !== "" &&
        plan[section]["medicalAttyEmail"] !== "" &&
        plan[section]["medicalAltName"] !== "" &&
        plan[section]["medicalAltPhone"] !== "" &&
        plan[section]["medicalAltEmail"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["financeAttyName"] !== "" &&
        plan[section]["financeAttyPhone"] !== "" &&
        plan[section]["financeAttyEmail"] !== "" &&
        plan[section]["financeAltName"] !== "" &&
        plan[section]["financeAltPhone"] !== "" &&
        plan[section]["financeAltEmail"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["locationOther"] !== "" &&
        plan[section]["locationInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["legalInstruct"] !== "" ? 1 : 0;

      break;
    case "c14":
      left += 1;
      done +=
        plan[section]["primaryInstitution"] &&
        plan[section]["primaryAccounts"] !== "" &&
        plan[section]["primaryAccessName"] !== "" &&
        plan[section]["primaryAccessPhone"] !== "" &&
        plan[section]["primaryAccessEmail"] !== "" &&
        plan[section]["primaryInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["other1Institution"] !== "" &&
        plan[section]["other1Accounts"] !== "" &&
        plan[section]["other1AccessName"] !== "" &&
        plan[section]["other1AccessPhone"] !== "" &&
        plan[section]["other1AccessEmail"] !== "" &&
        plan[section]["other1Instruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["other2Institution"] !== "" &&
        plan[section]["other2Accounts"] !== "" &&
        plan[section]["other2AccessName"] !== "" &&
        plan[section]["other2AccessPhone"] !== "" &&
        plan[section]["other2AccessEmail"] !== "" &&
        plan[section]["other2Instruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["retire1Institution"] !== "" &&
        plan[section]["retire1Accounts"] !== "" &&
        plan[section]["retire1AccessName"] !== "" &&
        plan[section]["retire1AccessPhone"] !== "" &&
        plan[section]["retire1AccessEmail"] !== "" &&
        plan[section]["retire1Instruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["retire2Institution"] !== "" &&
        plan[section]["retire2Accounts"] !== "" &&
        plan[section]["retire2AccessName"] !== "" &&
        plan[section]["retire2AccessPhone"] !== "" &&
        plan[section]["retire2AccessEmail"] !== "" &&
        plan[section]["retire2Instruct"] !== ""
          ? 1
          : 0;

      left += 1;
      if (plan[section]["advisor"]) {
        if (plan[section]["advisor"] === 2) {
          done += 1;
        } else if (
          plan[section]["advisorName"] !== "" &&
          plan[section]["advisorPhone"] !== "" &&
          plan[section]["advisorEmail"] !== ""
        ) {
          done += 1;
        }
      }

      left += 1;
      if (plan[section]["life"]) {
        if (plan[section]["life"] === 2) {
          done += 1;
        } else if (
          plan[section]["lifeInstitution"] !== "" &&
          plan[section]["lifePhone"] !== "" &&
          plan[section]["lifePolicy"] !== "" &&
          plan[section]["lifeInstruct"] !== ""
        ) {
          done += 1;
        }
      }

      left += 1;
      if (plan[section]["disability"]) {
        if (plan[section]["disability"] === 2) {
          done += 1;
        } else if (
          plan[section]["disabilityInstitution"] !== "" &&
          plan[section]["disabilityPhone"] !== "" &&
          plan[section]["disabilityPolicy"] !== "" &&
          plan[section]["disabilityInstruct"] !== ""
        ) {
          done += 1;
        }
      }

      left += 1;
      if (plan[section]["annuity"]) {
        if (plan[section]["annuity"] === 2) {
          done += 1;
        } else if (
          plan[section]["annuityInstitution"] !== "" &&
          plan[section]["annuityPhone"] !== "" &&
          plan[section]["annuityPolicy"] !== "" &&
          plan[section]["annuityInstruct"] !== ""
        ) {
          done += 1;
        }
      }

      left += 1;
      if (plan[section]["trust"]) {
        if (plan[section]["trust"] === 2) {
          done += 1;
        } else if (
          plan[section]["trustName"] !== "" &&
          plan[section]["trustContact"] !== "" &&
          plan[section]["trustInstruct"] !== ""
        ) {
          done += 1;
        }
      }

      left += 1;
      done +=
        plan[section]["ssOnline"] &&
        plan[section]["ssNumber"] !== "" &&
        plan[section]["ssInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      if (plan[section]["ssdisability"]) {
        if (plan[section]["ssdisability"] === 2) {
          done += 1;
        } else if (plan[section]["ssdisabilityOther"] !== "") {
          done += 1;
        }
      }

      left += 1;
      if (plan[section]["rental"]) {
        if (plan[section]["rental"] === 2) {
          done += 1;
        } else if (plan[section]["rentalOther"] !== "") {
          done += 1;
        }
      }

      left += 1;
      done +=
        plan[section]["recordsDetail"] &&
        plan[section]["recordsContact"] !== "" &&
        plan[section]["recordsKept"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["accountantName"] &&
        plan[section]["accountantPhone"] !== "" &&
        plan[section]["accountantEmail"] !== "" &&
        plan[section]["accountantRecords"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["carepayConcerns"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["careteamInstruct"] !== "" ? 1 : 0;

      break;
    case "c15":
      left += 1;
      done +=
        (plan[section]["spoken1Name"] && plan[section]["spoken1Role"] !== "") ||
        (plan[section]["spoken2Name"] !== "" &&
          plan[section]["spoken2Role"] !== "") ||
        plan[section]["spokenInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["culturalInstruct"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["worryDetails"] !== "" &&
        plan[section]["worryInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["concernDetails"] !== "" &&
        plan[section]["concernInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["wishesDetails"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["messageDetails"] !== "" &&
        plan[section]["messageList"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        (plan[section]["formats"].reduce(or_arr) ||
          plan[section]["formatsOther"] !== "") &&
        plan[section]["formatHelp"]
          ? 1
          : 0;

      left += 1;
      done += plan[section]["nothappenDetails"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["frightenDetails"] !== "" ? 1 : 0;

      left += 1;
      done += plan[section]["eolCare"].reduce(
        or_arr
      ) /* || plan[section]['eolCareOther'] !== '' */
        ? 1
        : 0;

      left += 1;
      done += plan[section]["surroundingDetails"] !== "" ? 1 : 0;

      break;
    case "c16":
      left += 1;
      done +=
        plan[section]["ritual"].reduce(or_arr) ||
        plan[section]["ritualOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["notifyOrgs"] !== "" &&
        plan[section]["notifyPeople"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["personUsps"] !== "" &&
        plan[section]["personIrs"] !== "" &&
        plan[section]["personSsa"] !== "" &&
        plan[section]["personDmv"] !== "" &&
        plan[section]["personOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["delivery"] || plan[section]["deliveryInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["personFileCert"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["final"].reduce(or_arr) ||
        plan[section]["finalOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["funeral"].reduce(or_arr) ||
        plan[section]["funeralOther"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["obituary"] ? 1 : 0;

      left += 1;
      done +=
        plan[section]["vendorName"] !== "" &&
        plan[section]["vendorAddress"] !== "" &&
        plan[section]["vendorPhone"] !== "" &&
        plan[section]["vendorInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done += plan[section]["paymentInstruct"] !== "" ? 1 : 0;

      left += 1;
      done +=
        plan[section]["dispose"] || plan[section]["disposeInstruct"] !== ""
          ? 1
          : 0;

      left += 1;
      done +=
        plan[section]["socmed"] || plan[section]["socmedOther"] !== "" ? 1 : 0;

      break;
    default:
      break;
  }

  left -= done;
  // console.log('after done:', done, 'left:', left)

  return (
    <div>
      <Space>
        <Tooltip title="Completed plan items">
          <>{done}</>
          <> </>
          <CheckCircleFilled style={{ color: "green" }} />
        </Tooltip>
        <> </>
        <Tooltip title="Plan items for completion" placement="topRight">
          <>{left}</>
          <> </>
          <QuestionCircleFilled style={{ color: "orange" }} />
        </Tooltip>
      </Space>
    </div>
  );
};

const initialPlan = {
  current: 1,
  c1: {
    firstName: "",
    lastName: "",
    birthDate: "",
    addr_street: "",
    addr_street2: "",
    addr_city: "",
    addr_state: "",
    addr_postal: "",
    addr_country: "USA",
    phone: "",
    primary_language: "",
    formal_titles: "",
    pronouns: "",
    partner: 0,
    partnerFirst: "",
    partnerLast: "",
    partnerOther: "",
    independent: 0,
    independentOther: "",
    work: 0,
    workOther: "",
    situation: "",
  },
  c2: {
    birthCert: "",
    marriageCert: "",
    deathCert: "",
    divorceDoc: "",
    militaryBranch: "",
    militaryIdVa: "",
    militaryVetDoc: 0,
    militaryDates: "",
    militaryDoc: "",
    driverState: "",
    driverLicense: "",
    driverDate: "",
    passportNumber: "",
    passportDate: "",
    donorInstruct: "",

    contact: 0,
    contactAddress: "",
    contactOther: "",

    contactEmergency: [],
    detailsPersonal: "",
  },
  c3: {
    propertyHome: [],
    propertyOwn: 0,
    propertyDetails: "",
    rentDetails: "",

    utilPowerName: "",
    utilPowerNumber: "",
    utilGasName: "",
    utilGasNumber: "",
    utilCableName: "",
    utilCableNumber: "",
    utilWaterName: "",
    utilWaterNumber: "",
    utilReceive: 0,
    utilPerson: "",
    utilPlan: "",

    phoneTeleName: "",
    phoneTeleNumber: "",
    phoneMobileName: "",
    phoneMobileNumber: "",
    phoneReceive: 0,
    phonePerson: "",
    phonePlan: "",

    homeInsureAgent: "",
    homeOwnerPolicy: "",
    homeQuakePolicy: "",
    homeFloodPolicy: "",
    homeFirePolicy: "",
    homeCost: "",

    personalPolicy: "",
    personalPremium: "",

    garbageDay: "",
    housekeepName: "",
    housekeepNumber: "",
    housekeepFrequency: "",
    housekeepCost: "",
    gardenerName: "",
    gardenerNumber: "",
    gardenerFrequency: "",
    gardenerCost: "",
    homeConcern: "",
  },
  c4: {
    pets: [],
    vetName: "",
    vetLocation: "",
    vetNumber: "",
    petInsurance: "",
    petInstruct: "",
  },
  c5: {
    drive: 0,
    driveCircumstance: "",
    vehicles: [],
    transports: [],
  },
  c6: {
    communicate: [false, false, false, false, false, false],
    communicateOther: "",
    converse: [false, false, false, false, false, false],
    converseOther: "",
  },
  c7: {
    insureName: "",
    insureNumber: "",
    insurePolicy: "",
    medicalGroup: "",
    medicalNumber: "",

    medicareNumber: "",
    medicareSavings: "",

    medicaidNumber: "",

    credentials: 0,
    credentialsText: "",

    plans: [],

    ss: "",
    survivor: "",
    disability: "",
    employerPostRetire: "",
    other: "",

    physicianName: "",
    physicianPhone: "",
    physicianEmail: "",

    specialists: [],

    conditions: [],

    integratives: [],

    hospitalclinics: [],

    hipaa: 0,
    hipaaInstruct: "",

    sustain: 0,
    sustainInstruct: "",

    directive: 0,
    directiveInstruct: "",
    directiveHelp: 0,

    dnr: 0,
    dnrInstruct: "",

    responsibleName: "",
    responsibleContact: "",
    responsibleDelivery: 0,
    responsibleOther: "",
  },
  c8: {
    medicines: [],

    pharmacyName: "",
    pharmacyAddress: "",
    pharmacyPhone: "",
    pharmacyHours: "",
    alternateName: "",
    alternateAddress: "",
    alternatePhone: "",
    alternateHours: "",

    manage: 0,
    manageName: "",
    manageOther: "",

    medicineInstruct: "",
  },
  c9: {
    personals: [],
    personalInstruct: "",
  },
  c10: {
    equipment: [false, false, false, false, false, false, false, false],
    ownership: 0,
    ownershipDetails: "",
    acquire: 0,
    acquireOther: "",
    dmeInstruct: "",
  },
  c11: {
    activities: "",
    communities: [],
    online: [],

    spiritual: 0,
    spiritualOther: "",

    therapist: 0,
    therapistOther: "",

    activityFuture: "",
    tools: "",
  },
  c12: {
    goals: [
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
      false,
    ],
    goalsOther: "",
    healthStatus: "",
    communityServices: "",
    activities: [false, false, false, false, false, false, false, false, false],
    activitiesOther: "",
    challenges: [false, false, false, false, false],
    challengesOther: "",
    services: [false, false, false, false],
    servicesOther: "",
    dietaryNeeds: "",
  },
  c13: {
    lawyerName: "",
    lawyerPhone: "",
    lawyerEmail: "",
    lawyerInstruct: "",

    documents: [false, false, false, false, false, false, false],
    documentsInstruct: "",

    topics: [false, false, false, false, false],
    topicsOther: "",

    medicalAttyName: "",
    medicalAttyPhone: "",
    medicalAttyEmail: "",
    medicalAltName: "",
    medicalAltPhone: "",
    medicalAltEmail: "",

    financeAttyName: "",
    financeAttyPhone: "",
    financeAttyEmail: "",
    financeAltName: "",
    financeAltPhone: "",
    financeAltEmail: "",

    locationOther: "",
    locationInstruct: "",

    legalInstruct: "",
  },
  c14: {
    primaryInstitution: "",
    primaryAccounts: "",
    primaryAccessName: "",
    primaryAccessPhone: "",
    primaryAccessEmail: "",
    primaryInstruct: "",

    other1Institution: "",
    other1Accounts: "",
    other1AccessName: "",
    other1AccessPhone: "",
    other1AccessEmail: "",
    other1Instruct: "",

    other2Institution: "",
    other2Accounts: "",
    other2AccessName: "",
    other2AccessPhone: "",
    other2AccessEmail: "",
    other2Instruct: "",

    retire1Institution: "",
    retire1Accounts: "",
    retire1AccessName: "",
    retire1AccessPhone: "",
    retire1AccessEmail: "",
    retire1Instruct: "",

    retire2Institution: "",
    retire2Accounts: "",
    retire2AccessName: "",
    retire2AccessPhone: "",
    retire2AccessEmail: "",
    retire2Instruct: "",

    advisor: "",
    advisorName: "",
    advisorPhone: "",
    advisorEmail: "",

    life: "",
    lifeInstitution: "",
    lifePhone: "",
    lifePolicy: "",
    lifeInstruct: "",

    disability: "",
    disabilityInstitution: "",
    disabilityPhone: "",
    disabilityPolicy: "",
    disabilityInstruct: "",

    annuity: "",
    annuityInstitution: "",
    annuityPhone: "",
    annuityPolicy: "",
    annuityInstruct: "",

    trust: "",
    trustName: "",
    trustContact: "",
    trustInstruct: "",

    ssOnline: "",
    ssNumber: "",
    ssInstruct: "",

    ssdisability: "",
    ssdisabilityOther: "",

    rental: "",
    rentalOther: "",

    recordsDetail: "",
    recordsContact: "",
    recordsKept: "",

    accountantName: "",
    accountantPhone: "",
    accountantEmail: "",
    accountantRecords: "",

    carepayConcerns: "",

    careteamInstruct: "",
  },
  c15: {
    spoken1Name: "",
    spoken1Role: "",
    spoken2Name: "",
    spoken2Role: "",
    spokenInstruct: "",

    culturalInstruct: "",

    worryDetails: "",
    worryInstruct: "",

    concernDetails: "",
    concernInstruct: "",

    wishesDetails: "",

    messageDetails: "",
    messageList: "",

    formats: [false, false, false, false],
    formatsOther: "",
    formatHelp: 0,

    nothappenDetails: "",

    frightenDetails: "",

    eolCare: [false, false, false, false, false, false],
    eolCareOther: "",

    surroundingDetails: "",
  },
  c16: {
    ritual: [false, false, false, false],
    ritualOther: "",

    notifyOrgs: "",
    notifyPeople: "",

    personUsps: "",
    personIrs: "",
    personSsa: "",
    personDmv: "",
    personOther: "",

    delivery: 0,
    deliveryInstruct: "",

    personFileCert: "",

    final: [false, false, false, false, false, false, false, false],
    finalOther: "",

    funeral: [false, false, false, false, false, false],
    funeralOther: "",

    obituary: 0,

    vendorName: "",
    vendorAddress: "",
    vendorPhone: "",
    vendorInstruct: "",

    paymentInstruct: "",

    dispose: 0,
    disposeInstruct: "",

    socmed: 0,
    socmedOther: "",
  },
};

export { initialPlan };

const GiverPlan = ({ setPage, circle /* care, plan*/ }) => {
  const mclAuth = useMclAuth();

  // console.log('circle:', circle)
  const care = circle.care;
  const plan = circle.subscription;
  const ic = care === CareType.INCARE ? true : false;
  const sf = plan === Subscription.FREE; /* is free subscription? */

  // console.log('ic:', ic)
  // console.log('subscription:', plan)

  const { cg, planRcpt, setPlanRcpt, panelRcptCurrent, setPanelRcptCurrent } =
    useCg();
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState("");

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("GiverCare");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const [formSetting, setFormSetting] = useState({
    title: "Feedback form",
    destination: "",
    isModalVisible: false,
    showFirstName: false,
    showLastName: false,
    showEmail: false,
    showPhone: false,
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const submitForm = useCallback(
    async (formValues) => {
      let data = formValues;
      data["firstName"] = cg["firstName"];
      data["lastName"] = cg["lastName"];
      data["email"] = cg["email"];
      data["phone"] = undefined;
      data["destination"] = formSetting["destination"];
      data["fromQuestion"] = formSetting["fromQuestion"];
      setSubmitting(true);
      let recaptchaToken = await handleReCaptchaVerify();
      if (!_.isEmpty(recaptchaToken)) {
        // Get the recaptcha token, submit it with the data to the backend
        mclAuth
          .post(Url.FORMS.concat("/", "givercare"), data, {
            headers: { "Content-Type": "application/json" },
            params: {
              token: recaptchaToken,
            },
          })
          .then((res) => {
            if (res.status && res.status === 200) {
              openNotification({
                message: "Inquiry submitted",
                status: "success",
                title: "Thank you " + data.firstName + "!",
                subTitle: "We will respond as soon as we can.",
              });
            } else {
              openNotification({
                message: "Sorry",
                status: res.status ? res.status : "500",
                title: res.response.data,
              });
            }
            setSubmitting(false);
            setFormSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
            }));
          })
          .catch((error) => {
            openNotification({
              message: "Sorry",
              status: error.status ? error.status : "500",
              title: error.response.data,
            });
            setSubmitting(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        openNotification({
          message: "Sorry",
          status: "500",
          title: "Sorry, please try again later",
          subTitle: "Failed to get Recaptcha token",
        });
        setSubmitting(false);
      }
    },
    [cg, formSetting, handleReCaptchaVerify]
  );

  const savePlan = async (plan) => {
    // console.log('Saving....', plan)
    console.log("saving circle plan....", circle._id);
    // plan.current = panelCurrent
    const planStr = JSON.parse(JSON.stringify(plan));
    // console.log('saving to api!', mclAuth.defaults.baseURL)
    setIsSaving(true);
    mclAuth
      .post(Url.CIRCLES.concat("/", circle._id, "/plan"), planStr)
      .then((res) => {
        console.log("response" /*, res.data*/);
      })
      .catch((error) => {
        setError(error);
      });
    setIsSaving(false);
  };

  const timedSave = React.useRef(
    debounce(async (plan) => {
      savePlan(plan);
    }, SAVE_TIME_PERIOD)
  ).current;

  const doChange = (category, question, value, idx = null) => {
    // console.log('doChange:', category, question, value)
    // categoryToPage(category)
    let newValues = JSON.parse(JSON.stringify(planRcpt));
    if (idx == null) {
      newValues[category][question] = value;
    } else {
      newValues[category][question][idx] = value;
    }
    // last worked on panel
    newValues.current = categoryToPage(category);
    setPlanRcpt(newValues);
    timedSave(newValues);
    // console.log(JSON.stringify(planRcpt[category]))
    // console.log('current:', planRcpt.current)
  };

  const StrToRange = (str) => {
    // console.log('str:', str)
    const arrStr =
      str === undefined || str === null || str === ""
        ? ["", ""]
        : str.split(DATE_RANGE_SEP);
    // console.log('arrStr[0]:',arrStr[0], arrStr[1])
    const dtBegin = moment(arrStr[0], DATE_FORMAT).isValid()
      ? moment(arrStr[0], DATE_FORMAT)
      : "";
    const dtEnd = moment(arrStr[1], DATE_FORMAT).isValid()
      ? moment(arrStr[1], DATE_FORMAT)
      : "";
    // console.log('begin:', moment(undefined).isValid(), ' end:', moment(undefined))
    return [dtBegin, dtEnd];
  };

  const RangeToStr = (arrStr) => {
    //console.log('[0]:', arrStr[0], ' [1]:', arrStr[1])
    let value = "";
    if (arrStr[0] !== "" && arrStr[1] !== "") {
      value = "".concat(arrStr[0], DATE_RANGE_SEP, arrStr[1]);
    }
    //console.log(value)
    return value;
  };

  // const onDate = (date, dateString) => {
  //     console.log(date, dateString);
  // };

  const props = {
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    onChange({ file, fileList }) {
      if (file.status !== "uploading") {
        console.log(file, fileList);
      }
    },

    defaultFileList: [
      // {
      //     uid: '1',
      //     name: 'xxx.png',
      //     status: 'done',
      //     response: 'Server Error 500',
      //     // custom error message to show
      //     url: 'http://www.baidu.com/xxx.png',
      // },
      // {
      //     uid: '2',
      //     name: 'yyy.png',
      //     status: 'done',
      //     url: 'http://www.baidu.com/yyy.png',
      // },
      // {
      //     uid: '3',
      //     name: 'zzz.png',
      //     status: 'error',
      //     response: 'Server Error 500',
      //     // custom error message to show
      //     url: 'http://www.baidu.com/zzz.png',
      // },
    ],
    showUploadList: {
      showDownloadIcon: true,
      downloadIcon: "Download",
      showRemoveIcon: true,
      removeIcon: (
        <DeleteOutlined
          onClick={(e) => console.log(e, "custom removeIcon event")}
        />
      ),
    },
  };

  // const onSaveContacts = (category, question, values) => {
  //     console.log('contacts:', category, 'question:', question, 'values:', JSON.stringify(values));
  // };

  const collapseChange = (key) => {
    if (key !== undefined) {
      // console.log('saving:', key)
      setPanelRcptCurrent(key);
    }
  };

  let default_perms = {
    all_w: false,
    othr_r: false,
    med_r: false,
    fin_r: false,
    leg_r: false,
  };

  const [perms, setPerms] = useState({});
  const [isPerms, setIsPerms] = useState(false);

  useEffect(() => {
    setIsPerms(true);
    let p = { ...default_perms };
    const access = JSON.parse(circle?.access || "{}");
    const members_write = circle.primary.concat(
      circle?.secondary ? [circle.secondary] : []
    );
    // console.log('members_write:', members_write, ' primary:', circle.primary, ' secondary:', circle.secondary)
    // let except = access?.exceptions[cg._id]
    let exceptions = access?.exceptions || [];
    let except = exceptions.filter((exc) => {
      return exc.member === cg._id;
    });
    if (except.length > 0) {
      except = except[0];
    } else {
      except = null;
    }

    let def = access?.default;
    // console.log('access:', access)
    // console.log('default:', def, ' except', except)
    // if (members_write.includes(cg._id)) {
    console.log("members_write:", members_write);
    let primaryOrBackup = false;
    for (let i = 0; i < members_write.length; i++) {
      if (members_write[i]._id == cg._id) {
        p.all_w = true;
        p.other_r = true;
        p.med_r = true;
        p.fin_r = true;
        p.leg_r = true;
        primaryOrBackup = true;
        break;
      }
    }
    if (!primaryOrBackup) {
      p.other_r = true;
      if (except) {
        p.med_r = except.medical;
        p.fin_r = except.financial;
        p.leg_r = except.legal;
        // console.log('except p:', p)
      } else {
        if (def) {
          p.med_r = def.medical;
          p.fin_r = def.financial;
          p.leg_r = def.legal;
          // console.log('default p:', p)
        }
      }
    }
    setPerms(p);
    console.log("perms:", p);
    setIsPerms(false);
  }, [circle]);

  if (planRcpt === undefined || isPerms) {
    return <Skeleton active />;
  }

  return (
    <>
      <FeedbackFormModal
        setting={formSetting}
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        handleCancel={() => {
          setFormSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
      />
      {ic ? (
        <>
          <h1
            style={{
              fontSize: "large",
              // fontWeight: "bold",
              color: "#30225f",
            }}
          >
            Care Plan for{" "}
            <strong>
              {circle.firstName}{" "}
              {!_.isNil(circle.lastName) ? circle.lastName : ""}
            </strong>
          </h1>
          <div className="panel-item">
            <p />
            <p />
            <h4>
              Here you will find a care and support plan template for the person
              you are caring for. Don’t expect to have all the answers. We
              encourage you to use this plan to engage in conversations over
              time to document wishes, preferences, instructions, and keep
              digital back-ups of important documents.
            </h4>
            <p />
            <p />
            <h4>
              These questions are designed to help you and the care team
              centralize key pieces of information and instructions for the
              future. This document serves as a road map to provide clear
              instructions and maybe even inspire you to have conversations
              about other topics. As life circumstances change, we encourage you
              to revisit this document to ensure it’s up to date and reflects
              the latest changes.
            </h4>
            <p />
            <p />

            <h4>
              This is <strong>for your eyes only</strong>. If you have any
              questions or need support, please send us a note to schedule a
              consultation here:{" "}
              <span
                onClick={() => {
                  setFormSetting({
                    title: "Send us a note",
                    destination: FormDestination.QUESTIONS,
                    fromQuestion: "GiverCare",
                    isModalVisible: true,
                    showFirstName: false,
                    showLastName: false,
                    showEmail: false,
                    showPhone: false,
                  });
                }}
              >
                <MessageTwoTone twoToneColor={"#8159f5"} />
              </span>
              . To read our Privacy Policy, click{" "}
              <a href="https://mycarelinq.com/privacy-policy" target="_blank">
                {/* <a href="/privacy-policy" target="_blank"> */}
                here
              </a>
            </h4>
            <p />
            <p />
          </div>
        </>
      ) : (
        <>
          <h1>
            Self-Care Plan for{" "}
            <strong>
              {circle.firstName}{" "}
              {!_.isNil(circle.lastName) ? circle.lastName : ""}
            </strong>
          </h1>
          <div className="panel-item">
            <p />
            <p />
            <h4>
              Here you will find a care plan template where you can document
              your wishes, preferences, instructions, and keep digital back-ups
              of important documents.
            </h4>
            <p />
            <p />
            <h4>
              These questions and your answers are designed to communicate with
              future caregivers about what you want and any instructions that
              would help them implement your wishes.
            </h4>
            <p />
            <p />
            <h4>
              You are in control of your plan. As life circumstances change, we
              encourage you to revisit this document to ensure it’s up to date
              and reflects the latest changes.
            </h4>
            <p />
            <p />
            <h4>
              Please contact us if you have any questions or need assistance
              working through this document.
            </h4>
            <p />
            <p />
          </div>
        </>
      )}
      <Collapse
        onChange={collapseChange}
        accordion={true}
        defaultActiveKey={
          /*planRcpt.current*/ panelRcptCurrent
        } /* activeKey={planRcpt.current} */
        style={{ maxWidth: "60rem" }}
      >
        <Panel
          header="Current Situation"
          key="1"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c1" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            {ic ? <>Who is this plan for?</> : <></>}
            <div className="panel-subitem">
              <p />
              First Name: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c1", "firstName", e.target.value)}
                value={planRcpt.c1.firstName}
                disabled={!perms.all_w}
              />
              <p />
              Last Name: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c1", "lastName", e.target.value)}
                value={planRcpt.c1.lastName}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
            <div className="panel-subitem">
              Date of Birth: <p />
              <DatePicker
                format={DATE_FORMAT}
                /* onChange={onDate} */ onChange={
                  (date, dateString) =>
                    doChange(
                      "c1",
                      "birthDate",
                      dateString
                    ) /* console.log(dateString.toString()) */
                }
                value={
                  planRcpt.c1.birthDate !== "" &&
                  moment(planRcpt.c1.birthDate, DATE_FORMAT).isValid()
                    ? moment(planRcpt.c1.birthDate, DATE_FORMAT)
                    : ""
                }
                disabled={!perms.all_w}
              />
            </div>
            <div className="panel-subitem">
              <p />
              Street Address:
              <Input
                maxLength={255}
                onChange={(e) => doChange("c1", "addr_street", e.target.value)}
                value={planRcpt.c1.addr_street}
                disabled={!perms.all_w}
              />
              <p />
              Address Line 2:
              <Input
                maxLength={150}
                onChange={(e) => doChange("c1", "addr_street2", e.target.value)}
                value={planRcpt.c1.addr_street2}
                disabled={!perms.all_w}
              />
              <p />
              City:{" "}
              <Input
                maxLength={255}
                onChange={(e) => doChange("c1", "addr_city", e.target.value)}
                value={planRcpt.c1.addr_city}
                disabled={!perms.all_w}
              />
              <p />
              State:{" "}
              <Input
                maxLength={255}
                onChange={(e) => doChange("c1", "addr_state", e.target.value)}
                value={planRcpt.c1.addr_state}
                disabled={!perms.all_w}
              />
              <p />
              Postal Code:{" "}
              <Input
                maxLength={15}
                onChange={(e) => doChange("c1", "addr_postal", e.target.value)}
                value={planRcpt.c1.addr_postal}
                disabled={!perms.all_w}
              />
              <p />
              Country:{" "}
              <Input
                maxLength={150}
                onChange={(e) => doChange("c1", "addr_country", e.target.value)}
                value={planRcpt.c1.addr_country}
                // disabled={true}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <div className="panel-subitem">
              <p />
              Phone:{" "}
              <Input
                maxLength={15}
                onChange={(e) => doChange("c1", "phone", e.target.value)}
                value={planRcpt.c1.phone}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <div className="panel-subitem">
              <p />
              Primary Language:{" "}
              <Input
                maxLength={150}
                onChange={(e) =>
                  doChange("c1", "primary_language", e.target.value)
                }
                value={planRcpt.c1.primary_language}
                disabled={!perms.all_w}
              />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            {ic ? (
              <>How do they want to be addressed?</>
            ) : (
              <>How do I want to be addressed?</>
            )}
            <Input
              maxLength={150}
              onChange={(e) => doChange("c1", "formal_titles", e.target.value)}
              value={planRcpt.c1.formal_titles}
              disabled={!perms.all_w}
            />
            <p />
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>
            Preferred pronouns:{" "}
            <Input
              maxLength={150}
              onChange={(e) => doChange("c1", "pronouns", e.target.value)}
              value={planRcpt.c1.pronouns}
              disabled={!perms.all_w}
            />
            <p />
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>
            {ic ? (
              <>Do they currently have a partner?</>
            ) : (
              <>Do you currently have a partner?</>
            )}
            <p />
            <Radio.Group
              size="large"
              onChange={(e) => doChange("c1", "partner", e.target.value)}
              value={planRcpt.c1.partner}
              disabled={!perms.all_w}
            >
              <p>
                <Radio value={0} style={{ display: "none" }}>
                  None
                </Radio>
              </p>
              <p>
                <Radio value={1}>Yes</Radio>
              </p>
              <p>
                <Radio value={2}>No</Radio>
              </p>
              <p>
                <Radio value={3}>It's complicated</Radio>
              </p>
              <p>
                <Radio value={4}>I don't know</Radio>
              </p>
            </Radio.Group>
            {planRcpt.c1.partner === 1 ? (
              <>
                <p />
                If Yes, Partner's Name:
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c1", "partnerFirst", e.target.value)
                  }
                  value={planRcpt.c1.partnerFirst}
                  placeholder={"First Name"}
                  disabled={!perms.all_w}
                />
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c1", "partnerLast", e.target.value)
                  }
                  value={planRcpt.c1.partnerLast}
                  placeholder={"Last Name"}
                  disabled={!perms.all_w}
                />
                <p />
              </>
            ) : (
              <></>
            )}
            <p />
            Other:
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) => doChange("c1", "partnerOther", e.target.value)}
              value={planRcpt.c1.partnerOther}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>
            {ic ? (
              <>Do they live independently?</>
            ) : (
              <>Do you live independently?</>
            )}
            <p />
            <Radio.Group
              size="large"
              onChange={(e) => doChange("c1", "independent", e.target.value)}
              value={planRcpt.c1.independent}
              disabled={!perms.all_w}
            >
              <p>
                <Radio value={0} style={{ display: "none" }}>
                  None
                </Radio>
              </p>
              <p>
                <Radio value={1}>
                  {ic ? <>They live alone</> : <>I live alone</>}
                </Radio>
              </p>
              <p>
                <Radio value={2}>With others</Radio>
              </p>
              <p>
                <Radio value={3}>With a partner/spouse</Radio>
              </p>
              <p>
                <Radio value={4}>With family</Radio>
              </p>
              <p>
                <Radio value={5}>In assisted living</Radio>
              </p>
              <p>
                <Radio value={6}>In a nursing home</Radio>
              </p>
            </Radio.Group>
            <p />
            Other:
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) =>
                doChange("c1", "independentOther", e.target.value)
              }
              value={planRcpt.c1.independentOther}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>
            {ic ? (
              <>Are they currently working or volunteering?</>
            ) : (
              <>Are you currently working or volunteering?</>
            )}
            <p />
            <Radio.Group
              size="large"
              onChange={(e) => doChange("c1", "work", e.target.value)}
              value={planRcpt.c1.work}
              disabled={!perms.all_w}
            >
              <p>
                <Radio value={0} style={{ display: "none" }}>
                  None
                </Radio>
              </p>
              <p>
                <Radio value={1}>Yes</Radio>
              </p>
              <p>
                <Radio value={2}>No</Radio>
              </p>
            </Radio.Group>
            <p />
            Other:
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) => doChange("c1", "workOther", e.target.value)}
              value={planRcpt.c1.workOther}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
          </div>
          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>
            {ic ? (
              <>What do they want you to know about their current situation?</>
            ) : (
              <>
                What do you want your care team to know about your current
                situation?
              </>
            )}
            <p />
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) => doChange("c1", "situation", e.target.value)}
              value={planRcpt.c1.situation}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
          </div>
        </Panel>
        <Panel
          header="Personal Information Checklist"
          key="2"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c2" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            {ic ? (
              <>Where is their birth certificate located?</>
            ) : (
              <>Where is your birth certificate located?</>
            )}
            <p />
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) => doChange("c2", "birthCert", e.target.value)}
              value={planRcpt.c2.birthCert}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
            {!sf ? (
              <>
                <Upload {...props} disabled={!perms.all_w}>
                  <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                    Upload Document
                  </Button>
                </Upload>
              </>
            ) : (
              <></>
            )}
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            {ic ? (
              <>Where is their marriage certificate located?</>
            ) : (
              <>Where is your marriage certificate located?</>
            )}
            <p />
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) => doChange("c2", "marriageCert", e.target.value)}
              value={planRcpt.c2.marriageCert}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
            {!sf ? (
              <>
                <Upload {...props} disabled={!perms.all_w}>
                  <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                    Upload Document
                  </Button>
                </Upload>
              </>
            ) : (
              <></>
            )}
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>
            {ic ? (
              <>
                Where is the death certificate located (for deceased spouse(s))?
              </>
            ) : (
              <>
                Where is the death certificate located (for deceased spouse(s))?
              </>
            )}
            <p />
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) => doChange("c2", "deathCert", e.target.value)}
              value={planRcpt.c2.deathCert}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
            {!sf ? (
              <>
                <Upload {...props} disabled={!perms.all_w}>
                  <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                    Upload Document
                  </Button>
                </Upload>
              </>
            ) : (
              <></>
            )}
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>
            {ic ? (
              <>Where are their divorce papers located?</>
            ) : (
              <>Where are your divorce papers located?</>
            )}
            <p />
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) => doChange("c2", "divorceDoc", e.target.value)}
              value={planRcpt.c2.divorceDoc}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
            {!sf ? (
              <>
                <Upload {...props} disabled={!perms.all_w}>
                  <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                    Upload Document
                  </Button>
                </Upload>
              </>
            ) : (
              <></>
            )}
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>
            {ic ? (
              <>Where are their military records located?</>
            ) : (
              <>Where are your military records located?</>
            )}
            <p />
            <div className="panel-subitem">
              Branch of service: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c2", "militaryBranch", e.target.value)
                }
                value={planRcpt.c2.militaryBranch}
                disabled={!perms.all_w}
              />
              <p />
              VA ID#: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c2", "militaryIdVa", e.target.value)}
                value={planRcpt.c2.militaryIdVa}
                disabled={!perms.all_w}
              />
              <p />
              Veterans Military Service record (DD-214): <p />
              <Radio.Group
                size="large"
                onChange={(e) =>
                  doChange("c2", "militaryVetDoc", e.target.value)
                }
                value={planRcpt.c2.militaryVetDoc}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
              </Radio.Group>
              <p />
              Dates of service:: <p /> <p />
              <RangePicker
                format={DATE_FORMAT}
                onChange={(dates, dateStrings) =>
                  doChange("c2", "militaryDates", RangeToStr(dateStrings))
                }
                value={StrToRange(planRcpt.c2.militaryDates)}
                disabled={!perms.all_w}
              />
              <p />
              Records Location:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c2", "militaryDoc", e.target.value)}
                value={planRcpt.c2.militaryDoc}
                placeholder={"Please elaorate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
            </div>
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>
            Driver’s license/State Identification Card
            <p />
            <div className="panel-subitem">
              State: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c2", "driverState", e.target.value)}
                value={planRcpt.c2.driverState}
                disabled={!perms.all_w}
              />
              <p />
              License number: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c2", "driverLicense", e.target.value)
                }
                value={planRcpt.c2.driverLicense}
                disabled={!perms.all_w}
              />
              <p />
              Expiration date: <p />
              <DatePicker
                format={DATE_FORMAT}
                /* onChange={onDate} */ onChange={
                  (date, dateString) =>
                    doChange(
                      "c2",
                      "driverDate",
                      dateString
                    ) /* console.log(dateString.toString()) */
                }
                value={
                  planRcpt.c2.driverDate !== "" &&
                  moment(planRcpt.c2.driverDate, DATE_FORMAT).isValid()
                    ? moment(planRcpt.c2.driverDate, DATE_FORMAT)
                    : ""
                }
                disabled={!perms.all_w}
              />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
            </div>
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>
            Organ Donor Card
            <p />
            <div className="panel-subitem">
              Instructions for Care Team:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c2", "donorInstruct", e.target.value)
                }
                value={planRcpt.c2.donorInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">8.)</div>
            Passport / Citizenship papers
            <p />
            <div className="panel-subitem">
              Passport number: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c2", "passportNumber", e.target.value)
                }
                value={planRcpt.c2.passportNumber}
                disabled={!perms.all_w}
              />
              <p />
              Expiration date: <p />
              <DatePicker
                format={DATE_FORMAT}
                /* onChange={onDate} */ onChange={
                  (date, dateString) =>
                    doChange(
                      "c2",
                      "passportDate",
                      dateString
                    ) /* console.log(dateString.toString()) */
                }
                value={
                  planRcpt.c2.passportDate !== "" &&
                  moment(planRcpt.c2.passportDate, DATE_FORMAT).isValid()
                    ? moment(planRcpt.c2.passportDate, DATE_FORMAT)
                    : ""
                }
                disabled={!perms.all_w}
              />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
            </div>
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">9.)</div>
            {ic ? (
              <>
                Where do they keep contact information (names, addresses, phone
                numbers)?
              </>
            ) : (
              <>
                Where do you keep your contacts (names, addresses, phone
                numbers)?
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c2", "contact", e.target.value)}
                value={planRcpt.c2.contact}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Address book</Radio>
                </p>
                <p>
                  <Radio value={2}>Cell phone</Radio>
                </p>
                <p>
                  <Radio value={3}>Other</Radio>
                </p>
              </Radio.Group>
              {planRcpt.c2.contact === 1 ? (
                <>
                  <p />
                  Address Book Location:
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c2", "contactAddress", e.target.value)
                    }
                    value={planRcpt.c2.contactAddress}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </>
              ) : (
                <></>
              )}
              <p />
              {planRcpt.c2.contact === 3 ? (
                <>
                  <p />
                  Other:
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c2", "contactOther", e.target.value)
                    }
                    value={planRcpt.c2.contactOther}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </>
              ) : (
                <></>
              )}
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">10.)</div>
            {ic ? (
              <>Please list their emergency contacts</>
            ) : (
              <>Please list your emergency contacts</>
            )}
            <p />
            <div className="panel-subitem">
              <Form
                name="emergency_contact_list"
                onFinish={(values) =>
                  doChange("c2", "contactEmergency", values?.contacts)
                }
                autoComplete="off"
                disabled={!perms.all_w}
              >
                <Form.List
                  name="contacts"
                  initialValue={planRcpt.c2.contactEmergency}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing name",
                              },
                            ]}
                          >
                            <Input
                              disable={true}
                              placeholder="Name"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "phone"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing phone",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Phone"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          {perms.all_w ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : (
                            <></>
                          )}
                        </Space>
                      ))}
                      <Form.Item>
                        {/* add({name: 'test1', phone: 'phone 2' }) */}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          disabled={!perms.all_w}
                        >
                          Add emergency contact
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!perms.all_w}
                  >
                    Save emergency contacts
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">11.)</div>
            {ic ? (
              <>
                Are there any other personal details they want to share with
                their future care team?
              </>
            ) : (
              <>
                Are there any other personal details you want to share with your
                future care team?
              </>
            )}
            <p />
            <TextArea
              showCount
              maxLength={255}
              onChange={(e) =>
                doChange("c2", "detailsPersonal", e.target.value)
              }
              value={planRcpt.c2.detailsPersonal}
              placeholder={"Please elaborate"}
              disabled={!perms.all_w}
            />
            <p />
            <p />
          </div>
        </Panel>
        <Panel
          header="Home Maintenance"
          key="3"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c3" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            {ic ? (
              <>Please list all their home properties (Addresses)</>
            ) : (
              <>Please list all your home properties (Addresses)</>
            )}
            <p />
            <div className="panel-subitem">
              <Form
                name="home_properties_list"
                onFinish={(values) =>
                  doChange("c3", "propertyHome", values?.properties)
                }
                autoComplete="off"
                disabled={!perms.all_w}
              >
                <Form.List
                  name="properties"
                  initialValue={planRcpt.c3.propertyHome}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "address"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing address",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Property Address"
                              style={{ width: "40rem" }}
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          {perms.all_w ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : (
                            <></>
                          )}
                        </Space>
                      ))}
                      <Form.Item>
                        {/* add({name: 'test1', phone: 'phone 2' }) */}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          disabled={!perms.all_w}
                        >
                          Add property
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!perms.all_w}
                  >
                    Save properties
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            {ic ? (
              <>Do they own these properties outright?</>
            ) : (
              <>Do you own these properties outright?</>
            )}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c3", "propertyOwn", e.target.value)}
                value={planRcpt.c3.propertyOwn}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
                <p>
                  <Radio value={3}>
                    {ic ? <>They have a mortgage</> : <>I have a mortgage</>}
                  </Radio>
                </p>
              </Radio.Group>
              {planRcpt.c3.propertyOwn === 3 ? (
                <>
                  <p />
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c3", "propertyDetails", e.target.value)
                    }
                    value={planRcpt.c3.propertyDetails}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                </>
              ) : (
                <></>
              )}
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>
            {ic ? (
              <>If they rent, where are their rental documents located?</>
            ) : (
              <>If you rent, where are your rental documents located?</>
            )}
            <p />
            <div className="panel-subitem">
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c3", "rentDetails", e.target.value)}
                value={planRcpt.c3.rentDetails}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>
            Utility bills
            <p />
            <div className="panel-subitem">
              Power Company <p />
              <div className="panel-subitem">
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilPowerName", e.target.value)
                  }
                  value={planRcpt.c3.utilPowerName}
                  disabled={!perms.all_w}
                />
                <p />
                Account number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilPowerNumber", e.target.value)
                  }
                  value={planRcpt.c3.utilPowerNumber}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Gas Company <p />
              <div className="panel-subitem">
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilGasName", e.target.value)
                  }
                  value={planRcpt.c3.utilGasName}
                  disabled={!perms.all_w}
                />
                <p />
                Account number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilGasNumber", e.target.value)
                  }
                  value={planRcpt.c3.utilGasNumber}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Cable / Internet company <p />
              <div className="panel-subitem">
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilCableName", e.target.value)
                  }
                  value={planRcpt.c3.utilCableName}
                  disabled={!perms.all_w}
                />
                <p />
                Account number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilCableNumber", e.target.value)
                  }
                  value={planRcpt.c3.utilCableNumber}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Water District <p />
              <div className="panel-subitem">
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilWaterName", e.target.value)
                  }
                  value={planRcpt.c3.utilWaterName}
                  disabled={!perms.all_w}
                />
                <p />
                Account number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "utilWaterNumber", e.target.value)
                  }
                  value={planRcpt.c3.utilWaterNumber}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              <p />
              {ic ? (
                <>
                  Do they receive these bills via USPS or digitally via email?
                </>
              ) : (
                <>Do you receive these bills via USPS or digitally via email?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) =>
                    doChange("c3", "utilReceive", e.target.value)
                  }
                  value={planRcpt.c3.utilReceive}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>USPS</Radio>
                  </p>
                  <p>
                    <Radio value={2}>Email</Radio>
                  </p>
                </Radio.Group>
              </div>
              <p />
              <p />
              {ic ? (
                <>Who is designated to collect and pay bills on their behalf?</>
              ) : (
                <>Who is designated to collect and pay bills on your behalf?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) => doChange("c3", "utilPerson", e.target.value)}
                  value={planRcpt.c3.utilPerson}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
              {ic ? (
                <>How do they plan to collect and pay bills if required?</>
              ) : (
                <>How do you plan to collect and pay bills if required?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) => doChange("c3", "utilPlan", e.target.value)}
                  value={planRcpt.c3.utilPlan}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>
            <p />
            <p />
            {/* TODO:?? Upload document  [Yes]  [No] */}
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>
            Phone Bills
            <p />
            <div className="panel-subitem">
              Telephone Company <p />
              <div className="panel-subitem">
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "phoneTeleName", e.target.value)
                  }
                  value={planRcpt.c3.phoneTeleName}
                  disabled={!perms.all_w}
                />
                <p />
                Account number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "phoneTeleNumber", e.target.value)
                  }
                  value={planRcpt.c3.phoneTeleNumber}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Mobile Phone Service Provider <p />
              <div className="panel-subitem">
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "phoneMobileName", e.target.value)
                  }
                  value={planRcpt.c3.phoneMobileName}
                  disabled={!perms.all_w}
                />
                <p />
                Account number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "phoneMobileNumber", e.target.value)
                  }
                  value={planRcpt.c3.phoneMobileNumber}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              <p />
              {ic ? (
                <>
                  Do they receive these bills via USPS or digitally via email?
                </>
              ) : (
                <>Do you receive these bills via USPS or digitally via email?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) =>
                    doChange("c3", "phoneReceive", e.target.value)
                  }
                  value={planRcpt.c3.phoneReceive}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>USPS</Radio>
                  </p>
                  <p>
                    <Radio value={2}>Email</Radio>
                  </p>
                </Radio.Group>
              </div>
              <p />
              <p />
              {ic ? (
                <>Who is designated to collect and pay bills on their behalf?</>
              ) : (
                <>Who is designated to collect and pay bills on your behalf?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c3", "phonePerson", e.target.value)
                  }
                  value={planRcpt.c3.phonePerson}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
              {ic ? (
                <>How do they plan to collect and pay bills if required?</>
              ) : (
                <>How do you plan to collect and pay bills if required?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) => doChange("c3", "phonePlan", e.target.value)}
                  value={planRcpt.c3.phonePlan}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>
            Homeowners Insurance and Special Homeowner Policies
            <p />
            <div className="panel-subitem">
              Insurance <p />
              <div className="panel-subitem">
                Agent: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "homeInsureAgent", e.target.value)
                  }
                  value={planRcpt.c3.homeInsureAgent}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Homeowner's Insurance <p />
              <div className="panel-subitem">
                Policy: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "homeOwnerPolicy", e.target.value)
                  }
                  value={planRcpt.c3.homeOwnerPolicy}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Earthquake Insurance <p />
              <div className="panel-subitem">
                Policy: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "homeQuakePolicy", e.target.value)
                  }
                  value={planRcpt.c3.homeQuakePolicy}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Flood Insurance <p />
              <div className="panel-subitem">
                Policy: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "homeFloodPolicy", e.target.value)
                  }
                  value={planRcpt.c3.homeFloodPolicy}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Fire Insurance <p />
              <div className="panel-subitem">
                Policy: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c3", "homeFirePolicy", e.target.value)
                  }
                  value={planRcpt.c3.homeFirePolicy}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              Cost: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c3", "homeCost", e.target.value)}
                value={planRcpt.c3.homeCost}
                disabled={!perms.all_w}
              />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>
            Personal Property Insurance
            <p />
            <div className="panel-subitem">
              <p />
              Policy Number: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "personalPolicy", e.target.value)
                }
                value={planRcpt.c3.personalPolicy}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              Premium: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "personalPremium", e.target.value)
                }
                value={planRcpt.c3.personalPremium}
                disabled={!perms.all_w}
              />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">8.)</div>
            Garage and Recycling Pick Up
            <p />
            <div className="panel-subitem">
              <p />
              Garbage Day(s): <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c3", "garbageDay", e.target.value)}
                value={planRcpt.c3.garbageDay}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">9.)</div>
            Housekeeping Services
            <p />
            <div className="panel-subitem">
              <p />
              Name: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "housekeepName", e.target.value)
                }
                value={planRcpt.c3.housekeepName}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Phone Number: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "housekeepNumber", e.target.value)
                }
                value={planRcpt.c3.housekeepNumber}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Frequency: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "housekeepFrequency", e.target.value)
                }
                value={planRcpt.c3.housekeepFrequency}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Cost: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "housekeepCost", e.target.value)
                }
                value={planRcpt.c3.housekeepCost}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">10.)</div>
            Gardening or Landscaping Services
            <p />
            <div className="panel-subitem">
              <p />
              Name: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c3", "gardenerName", e.target.value)}
                value={planRcpt.c3.gardenerName}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Phone Number: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "gardenerNumber", e.target.value)
                }
                value={planRcpt.c3.gardenerNumber}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Frequency: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c3", "gardenerFrequency", e.target.value)
                }
                value={planRcpt.c3.gardenerFrequency}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Cost: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c3", "gardenerCost", e.target.value)}
                value={planRcpt.c3.gardenerCost}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">11.)</div>
            Concerns:
            <p />
            <div className="panel-subitem">
              <p />
              {ic ? (
                <>
                  Do they have any concerns about maintaining their home now or
                  in the future?
                </>
              ) : (
                <>
                  Do you have any concerns about maintaining your home now or in
                  the future?
                </>
              )}
              <p />
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c3", "homeConcern", e.target.value)}
                value={planRcpt.c3.homeConcern}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
          </div>
        </Panel>
        <Panel
          header="Family Pets"
          key="4"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c4" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            Please List All Family Pets
            <p />
            <Form
              name="family_pets_list"
              onFinish={(values) => doChange("c4", "pets", values?.pets)}
              autoComplete="off"
              disabled={!perms.all_w}
            >
              <Form.List name="pets" initialValue={planRcpt.c4.pets}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space
                        key={key}
                        style={{
                          display: "flex",
                          marginBottom: 8,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "details"]}
                          rules={[
                            {
                              required: true,
                              message: "Missing details",
                            },
                          ]}
                        >
                          <Input
                            placeholder="Pet Details"
                            style={{ width: "40rem" }}
                            disabled={!perms.all_w}
                          />
                        </Form.Item>
                        {perms.all_w ? (
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        ) : (
                          <></>
                        )}
                      </Space>
                    ))}
                    <Form.Item>
                      {/* add({name: 'test1', phone: 'phone 2' }) */}
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        block
                        icon={<PlusOutlined />}
                        disabled={!perms.all_w}
                      >
                        Add Pet
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!perms.all_w}
                >
                  Save Pet's Name
                </Button>
              </Form.Item>
            </Form>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            Vet information
            <p />
            <div className="panel-subitem">
              <p />
              Name: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c4", "vetName", e.target.value)}
                value={planRcpt.c4.vetName}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Location: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c4", "vetLocation", e.target.value)}
                value={planRcpt.c4.vetLocation}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Phone Number: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c4", "vetNumber", e.target.value)}
                value={planRcpt.c4.vetNumber}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Pet insurance: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c4", "petInsurance", e.target.value)}
                value={planRcpt.c4.petInsurance}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              Instructions for care team:
              <p />
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c4", "petInstruct", e.target.value)}
                value={planRcpt.c4.petInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Document
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>
        </Panel>
        <Panel
          header="Transportation"
          key="5"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c5" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            {ic ? <>Do they currently drive?</> : <>Do you currently drive?</>}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c5", "drive", e.target.value)}
                value={planRcpt.c5.drive}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
              </Radio.Group>
              {ic && planRcpt.c5.drive === 1 ? (
                <>
                  <p />
                  If they do drive, have you had conversations about what
                  circumstances would mark the need to stop driving?
                  <p />
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c5", "driveCircumstance", e.target.value)
                    }
                    value={planRcpt.c5.driveCircumstance}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </>
              ) : (
                <></>
              )}
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            {ic ? (
              <>
                List any Cars or Recreation Vehicles they currently own or
                lease.
              </>
            ) : (
              <>
                List any Cars or Recreation Vehicles you currently own or lease.
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Form
                name="vehicle_list"
                onFinish={(values) =>
                  doChange("c5", "vehicles", values?.vehicles)
                }
                autoComplete="off"
                disabled={!perms.all_w}
              >
                <Form.List name="vehicles" initialValue={planRcpt.c5.vehicles}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "model"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing vehicle model",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Model"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "loan"]}
                            rules={[
                              {
                                required: false,
                              },
                            ]}
                          >
                            <Input
                              placeholder="Loan info"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "insurance"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing insurance information",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Insurance"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "title"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing title info",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Title"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          {perms.all_w ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : (
                            <></>
                          )}
                        </Space>
                      ))}
                      <Form.Item>
                        {/* add({name: 'test1', phone: 'phone 2' }) */}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          disabled={!perms.all_w}
                        >
                          Add Car/Vehicle
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!perms.all_w}
                  >
                    Save Cars/Vehicles
                  </Button>
                </Form.Item>
              </Form>

              <p />
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload Vehicle Documents
                    </Button>
                  </Upload>
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>
            {ic ? (
              <>
                List any Public Transportation or Private Transportation Vendors
                they use.
              </>
            ) : (
              <>
                List any Public Transportation or Private Transportation Vendors
                you use.
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Form
                name="transport_list"
                onFinish={(values) =>
                  doChange("c5", "transports", values?.transports)
                }
                autoComplete="off"
                disabled={!perms.all_w}
              >
                <Form.List
                  name="transports"
                  initialValue={planRcpt.c5.transports}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing name",
                              },
                            ]}
                          >
                            <Input
                              disable={true}
                              placeholder="Transport Name"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "phone"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing phone number",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Phone Number"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "cost"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing cost",
                              },
                            ]}
                          >
                            <Input placeholder="Cost" disabled={!perms.all_w} />
                          </Form.Item>
                          {perms.all_w ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : (
                            <></>
                          )}
                        </Space>
                      ))}
                      <Form.Item>
                        {/* add({name: 'test1', phone: 'phone 2' }) */}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          disabled={!perms.all_w}
                        >
                          Add Transport
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!perms.all_w}
                  >
                    Save Transportation
                  </Button>
                </Form.Item>
              </Form>

              <p />
              <p />
            </div>
          </div>
        </Panel>
        <Panel
          header="Conversations About Care And Support"
          key="6"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c6" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            {ic ? (
              <>
                Have they communicated their wishes/ instructions about the
                following? [Check all that apply]{" "}
              </>
            ) : (
              <>
                I’ve had the following conversations with people I trust. [Check
                all that apply]
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "communicate", e.target.checked, 0)
                }
                defaultChecked={planRcpt.c6.communicate[0]}
                disabled={!perms.all_w}
              >
                Life-sustaining wishes and/or DNR
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "communicate", e.target.checked, 1)
                }
                defaultChecked={planRcpt.c6.communicate[1]}
                disabled={!perms.all_w}
              >
                Long Term Care Placement into senior living such as ILF, ALF, or
                nursing home
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "communicate", e.target.checked, 2)
                }
                defaultChecked={planRcpt.c6.communicate[2]}
                disabled={!perms.all_w}
              >
                Aging in the home
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "communicate", e.target.checked, 3)
                }
                defaultChecked={planRcpt.c6.communicate[3]}
                disabled={!perms.all_w}
              >
                End-of-life care
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "communicate", e.target.checked, 4)
                }
                defaultChecked={planRcpt.c6.communicate[4]}
                disabled={!perms.all_w}
              >
                Final arrangements
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "communicate", e.target.checked, 5)
                }
                defaultChecked={planRcpt.c6.communicate[5]}
                disabled={!perms.all_w}
              >
                None of these
              </Checkbox>
              <p />
              Other (Please explain):
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c6", "communicateOther", e.target.value)
                }
                value={planRcpt.c6.communicateOther}
                disabled={!perms.all_w}
              />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            {ic ? (
              <>
                We need to have the following conversations. [Check all that
                apply]
              </>
            ) : (
              <>
                {" "}
                I need to have the following conversations with people I trust.
                [Check all that apply]
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "converse", e.target.checked, 0)
                }
                defaultChecked={planRcpt.c6.converse[0]}
                disabled={!perms.all_w}
              >
                Life-sustaining wishes and/or DNR
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "converse", e.target.checked, 1)
                }
                defaultChecked={planRcpt.c6.converse[1]}
                disabled={!perms.all_w}
              >
                Long Term Care Placement into senior living such as ILF, ALF, or
                nursing home
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "converse", e.target.checked, 2)
                }
                defaultChecked={planRcpt.c6.converse[2]}
                disabled={!perms.all_w}
              >
                Aging in the home
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "converse", e.target.checked, 3)
                }
                defaultChecked={planRcpt.c6.converse[3]}
                disabled={!perms.all_w}
              >
                End-of-life care
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "converse", e.target.checked, 4)
                }
                defaultChecked={planRcpt.c6.converse[4]}
                disabled={!perms.all_w}
              >
                Final arrangements
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c6", "converse", e.target.checked, 5)
                }
                defaultChecked={planRcpt.c6.converse[5]}
                disabled={!perms.all_w}
              >
                None of these
              </Checkbox>
              <p />
              Other (Please explain):
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c6", "converseOther", e.target.value)
                }
                value={planRcpt.c6.converseOther}
                disabled={!perms.all_w}
              />
              <p />
            </div>
          </div>
        </Panel>
        {perms.med_r ? (
          <Panel
            header="Healthcare Information"
            key="7"
            style={{ fontWeight: "600" }}
            extra={
              <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c7" />
            }
          >
            <div className="panel-item">
              <p />
              <div className="panel-item-no">1.)</div>
              Healthcare insurance
              <p />
              <div className="panel-subitem">
                <p />
                Insurer Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) => doChange("c7", "insureName", e.target.value)}
                  value={planRcpt.c7.insureName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Insurer Contact Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "insureNumber", e.target.value)
                  }
                  value={planRcpt.c7.insureNumber}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Policy Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "insurePolicy", e.target.value)
                  }
                  value={planRcpt.c7.insurePolicy}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Medical group: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "medicalGroup", e.target.value)
                  }
                  value={planRcpt.c7.medicalGroup}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Member or Medical Record Number:
                <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "medicalNumber", e.target.value)
                  }
                  value={planRcpt.c7.medicalNumber}
                  disabled={!perms.all_w}
                />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        {ic ? (
                          <>Upload copy of their ID card</>
                        ) : (
                          <>Upload copy of your ID card</>
                        )}
                      </Button>
                    </Upload>
                    <p />
                    <p />
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload benefit documents
                      </Button>
                    </Upload>
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">2.)</div>
              Medicare
              <p />
              <div className="panel-subitem">
                <p />
                Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "medicareNumber", e.target.value)
                  }
                  value={planRcpt.c7.medicareNumber}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Medicare Savings Program? <p />
                <div className="panel-subitem">
                  <Radio.Group
                    size="large"
                    onChange={(e) =>
                      doChange("c7", "medicareSavings", e.target.value)
                    }
                    value={planRcpt.c7.medicareSavings}
                    disabled={!perms.all_w}
                  >
                    <p>
                      <Radio value={0} style={{ display: "none" }}>
                        None
                      </Radio>
                    </p>
                    <p>
                      <Radio value={1}>Yes</Radio>
                    </p>
                    <p>
                      <Radio value={2}>No</Radio>
                    </p>
                  </Radio.Group>
                  <p />
                </div>
                <p />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        {ic ? (
                          <>Upload copy of their ID card</>
                        ) : (
                          <>Upload copy of your ID card</>
                        )}
                      </Button>
                    </Upload>
                    <p />
                    <p />
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload benefit documents
                      </Button>
                    </Upload>
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">3.)</div>
              Medicaid
              <p />
              <div className="panel-subitem">
                <p />
                Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "medicaidNumber", e.target.value)
                  }
                  value={planRcpt.c7.medicaidNumber}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        {ic ? (
                          <>Upload copy of their ID card</>
                        ) : (
                          <>Upload copy of your ID card</>
                        )}
                      </Button>
                    </Upload>
                    <p />
                    <p />
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload benefit documents
                      </Button>
                    </Upload>
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">4.)</div>
              Do they have online credentials to access their medical
              information online?
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) =>
                    doChange("c7", "credentials", e.target.value)
                  }
                  value={planRcpt.c7.credentials}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                {planRcpt.c7.credentials === 1 ? (
                  <>
                    <p />
                    Credentials:
                    <TextArea
                      showCount
                      maxLength={255}
                      onChange={(e) =>
                        doChange("c7", "credentialsText", e.target.value)
                      }
                      value={planRcpt.c7.credentialsText}
                      disabled={!perms.all_w}
                    />
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">5.)</div>
              Supplemental Coverage
              <p />
              <div className="panel-subitem">
                <Form
                  name="supplemental_plan_list"
                  onFinish={(values) => doChange("c7", "plans", values?.plans)}
                  autoComplete="off"
                  disabled={!perms.all_w}
                >
                  <Form.List name="plans" initialValue={planRcpt.c7.plans}>
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing plan name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Plan Name"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "id"]}
                              rules={[
                                {
                                  required: false,
                                },
                              ]}
                            >
                              <Input
                                placeholder="Plan ID"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            {perms.all_w ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          {/* add({name: 'test1', phone: 'phone 2' }) */}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={!perms.all_w}
                          >
                            Add Plan
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!perms.all_w}
                    >
                      Save Plans
                    </Button>
                  </Form.Item>
                </Form>

                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">6.)</div>
              Other Benefits
              <p />
              <div className="panel-subitem">
                <p />
                Social Security: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) => doChange("c7", "ss", e.target.value)}
                  value={planRcpt.c7.ss}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Survivor’s Benefits: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) => doChange("c7", "survivor", e.target.value)}
                  value={planRcpt.c7.survivor}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Disability: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) => doChange("c7", "disability", e.target.value)}
                  value={planRcpt.c7.disability}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Employer post retirement medical benefits: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "employerPostRetire", e.target.value)
                  }
                  value={planRcpt.c7.employerPostRetire}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Other:
                <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) => doChange("c7", "other", e.target.value)}
                  value={planRcpt.c7.other}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">7.)</div>
              Primary Care Physician
              <p />
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "physicianName", e.target.value)
                  }
                  value={planRcpt.c7.physicianName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "physicianPhone", e.target.value)
                  }
                  value={planRcpt.c7.physicianPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Email: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "physicianEmail", e.target.value)
                  }
                  value={planRcpt.c7.physicianEmail}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">8.)</div>
              Specialty Care Physicians
              <p />
              <div className="panel-subitem">
                <Form
                  name="specialty_physician_list"
                  onFinish={(values) =>
                    doChange("c7", "specialists", values?.specialists)
                  }
                  autoComplete="off"
                  disabled={!perms.all_w}
                >
                  <Form.List
                    name="specialists"
                    initialValue={planRcpt.c7.specialists}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing specialist name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Specialist Name"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "phone"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing phone number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Phone Number"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              rules={[
                                {
                                  // required: true,
                                  type: "email",
                                  message: "Missing email",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Email"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            {perms.all_w ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          {/* add({name: 'test1', phone: 'phone 2' }) */}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={!perms.all_w}
                          >
                            Add Specialist
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!perms.all_w}
                    >
                      Save Physician(s)
                    </Button>
                  </Form.Item>
                </Form>

                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">9.)</div>
              Medical conditions or diagnosis
              <p />
              <div className="panel-subitem">
                <Form
                  name="conditions_list"
                  onFinish={(values) =>
                    doChange("c7", "conditions", values?.conditions)
                  }
                  autoComplete="off"
                  disabled={!perms.all_w}
                >
                  <Form.List
                    name="conditions"
                    initialValue={planRcpt.c7.conditions}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "diagnosis"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing diagnosis",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Diagnosis"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "details"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing diagnosis details",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Details"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            {perms.all_w ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          {/* add({name: 'test1', phone: 'phone 2' }) */}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={!perms.all_w}
                          >
                            Add Diagnosis/Condition
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!perms.all_w}
                    >
                      Save Medical Condition(s)
                    </Button>
                  </Form.Item>
                </Form>

                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">10.)</div>
              Integrative Medicine Providers
              <p />
              <div className="panel-subitem">
                <Form
                  name="integrative_provider_list"
                  onFinish={(values) =>
                    doChange("c7", "integratives", values?.integratives)
                  }
                  autoComplete="off"
                  disabled={!perms.all_w}
                >
                  <Form.List
                    name="integratives"
                    initialValue={planRcpt.c7.integratives}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing specialist name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Specialist Name"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "phone"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing phone number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Phone Number"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              rules={[
                                {
                                  // required: true,
                                  type: "email",
                                  message: "Missing email",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Email"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            {perms.all_w ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          {/* add({name: 'test1', phone: 'phone 2' }) */}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={!perms.all_w}
                          >
                            Add Specialist
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!perms.all_w}
                    >
                      Save Physician(s)
                    </Button>
                  </Form.Item>
                </Form>

                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">11.)</div>
              Preferred Hospital and Clinic information
              <p />
              <div className="panel-subitem">
                <Form
                  name="hospital_clinic_list"
                  onFinish={(values) =>
                    doChange("c7", "hospitalclinics", values?.hospitalclinics)
                  }
                  autoComplete="off"
                  disabled={!perms.all_w}
                >
                  <Form.List
                    name="hospitalclinics"
                    initialValue={planRcpt.c7.hospitalclinics}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing hospital/clinic name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Hospital/Clinic Name"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "phone"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing phone number",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Phone Number"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "email"]}
                              rules={[
                                {
                                  // required: true,
                                  type: "email",
                                  message: "Missing email",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Email"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            {perms.all_w ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          {/* add({name: 'test1', phone: 'phone 2' }) */}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={!perms.all_w}
                          >
                            Add Hospital/Clinic
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!perms.all_w}
                    >
                      Save Hospital(s)/Clinic(s)
                    </Button>
                  </Form.Item>
                </Form>

                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">12.)</div>
              HIPAA release form
              <p />
              <div className="panel-subitem">
                <p />
                {ic ? (
                  <>Have they completed a HIPAA release form?</>
                ) : (
                  <>Have you completed a HIPAA release form?</>
                )}
                <p />
                <div className="panel-subitem">
                  <Radio.Group
                    size="large"
                    onChange={(e) => doChange("c7", "hipaa", e.target.value)}
                    value={planRcpt.c7.hipaa}
                    disabled={!perms.all_w}
                  >
                    <p>
                      <Radio value={0} style={{ display: "none" }}>
                        None
                      </Radio>
                    </p>
                    <p>
                      <Radio value={1}>Yes</Radio>
                    </p>
                    <p>
                      <Radio value={2}>No</Radio>
                    </p>
                    <p>
                      <Radio value={3}>Working on it</Radio>
                    </p>
                    <p>
                      <Radio value={4}>Need more information</Radio>
                    </p>
                  </Radio.Group>
                  <p />
                </div>
                <p />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload copy of document
                      </Button>
                    </Upload>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
                Instruction for caregiver(s):
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c7", "hipaaInstruct", e.target.value)
                  }
                  value={planRcpt.c7.hipaaInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">13.)</div>
              Life-Sustaining Treatment Order Document
              <p />
              <div className="panel-subitem">
                <p />
                {ic ? (
                  <>
                    Have they completed Physician Orders for Life-Sustaining
                    Treatment?
                  </>
                ) : (
                  <>
                    Have you completed Physician Orders for Life-Sustaining
                    Treatment?
                  </>
                )}
                <p />
                <div className="panel-subitem">
                  <Radio.Group
                    size="large"
                    onChange={(e) => doChange("c7", "sustain", e.target.value)}
                    value={planRcpt.c7.sustain}
                    disabled={!perms.all_w}
                  >
                    <p>
                      <Radio value={0} style={{ display: "none" }}>
                        None
                      </Radio>
                    </p>
                    <p>
                      <Radio value={1}>Yes</Radio>
                    </p>
                    <p>
                      <Radio value={2}>No</Radio>
                    </p>
                    <p>
                      <Radio value={3}>Working on it</Radio>
                    </p>
                    <p>
                      <Radio value={4}>Need more information</Radio>
                    </p>
                  </Radio.Group>
                  <p />
                </div>
                <p />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload copy of document
                      </Button>
                    </Upload>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
                Instruction for caregiver(s):
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c7", "sustainInstruct", e.target.value)
                  }
                  value={planRcpt.c7.sustainInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">14.)</div>
              Advanced Directive
              <p />
              <div className="panel-subitem">
                <p />
                {ic ? (
                  <>Have they completed Advanced Directive form?</>
                ) : (
                  <>Have you completed Advanced Directive form?</>
                )}
                <p />
                <div className="panel-subitem">
                  <Radio.Group
                    size="large"
                    onChange={(e) =>
                      doChange("c7", "directive", e.target.value)
                    }
                    value={planRcpt.c7.directive}
                    disabled={!perms.all_w}
                  >
                    <p>
                      <Radio value={0} style={{ display: "none" }}>
                        None
                      </Radio>
                    </p>
                    <p>
                      <Radio value={1}>Yes</Radio>
                    </p>
                    <p>
                      <Radio value={2}>No</Radio>
                    </p>
                    <p>
                      <Radio value={3}>Working on it</Radio>
                    </p>
                    <p>
                      <Radio value={4}>Need more information</Radio>
                    </p>
                  </Radio.Group>
                  <p />
                </div>
                <p />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload copy of document
                      </Button>
                    </Upload>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
                Instruction for caregiver(s):
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c7", "directiveInstruct", e.target.value)
                  }
                  value={planRcpt.c7.directiveInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              {planRcpt.c7.directive > 1 && planRcpt.c7.directive < 5 ? (
                <div className="panel-subitem">
                  <p />
                  {ic ? (
                    <>
                      If they haven’t completed this form yet, do they need help
                      navigating this?
                    </>
                  ) : (
                    <>
                      If you haven’t completed this form yet, do you need help
                      navigating this?
                    </>
                  )}
                  <p />
                  <div className="panel-subitem">
                    <Radio.Group
                      size="large"
                      onChange={(e) =>
                        doChange("c7", "directiveHelp", e.target.value)
                      }
                      value={planRcpt.c7.directiveHelp}
                      disabled={!perms.all_w}
                    >
                      <p>
                        <Radio value={0} style={{ display: "none" }}>
                          None
                        </Radio>
                      </p>
                      <p>
                        <Radio value={1}>Yes</Radio>
                      </p>
                      <p>
                        <Radio value={2}>No</Radio>
                      </p>
                    </Radio.Group>
                    <p />
                  </div>
                </div>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">15.)</div>
              Do Not Resuscitate (DNR) Order
              <p />
              <div className="panel-subitem">
                <p />
                {ic ? (
                  <>Have they completed a DNR form? (state specific)</>
                ) : (
                  <>Have you completed a DNR form? (state specific)</>
                )}
                <p />
                <div className="panel-subitem">
                  <Radio.Group
                    size="large"
                    onChange={(e) => doChange("c7", "dnr", e.target.value)}
                    value={planRcpt.c7.dnr}
                    disabled={!perms.all_w}
                  >
                    <p>
                      <Radio value={0} style={{ display: "none" }}>
                        None
                      </Radio>
                    </p>
                    <p>
                      <Radio value={1}>Yes</Radio>
                    </p>
                    <p>
                      <Radio value={2}>No</Radio>
                    </p>
                    <p>
                      <Radio value={3}>Working on it</Radio>
                    </p>
                    <p>
                      <Radio value={4}>Need more information</Radio>
                    </p>
                  </Radio.Group>
                  <p />
                </div>
                <p />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload copy of document
                      </Button>
                    </Upload>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
                Instruction for caregiver(s):
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c7", "dnrInstruct", e.target.value)
                  }
                  value={planRcpt.c7.dnrInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">16.)</div>
              {ic ? (
                <>
                  In the event they are hospitalized, who is responsible to
                  provide any of the documents listed above?
                </>
              ) : (
                <>
                  In the event you are hospitalized, who is responsible to
                  provide any of the documents listed above?
                </>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "responsibleName", e.target.value)
                  }
                  value={planRcpt.c7.responsibleName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Contact: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c7", "responsibleContact", e.target.value)
                  }
                  value={planRcpt.c7.responsibleContact}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Method of Delivery:
                <div className="panel-subitem">
                  <Radio.Group
                    size="large"
                    onChange={(e) =>
                      doChange("c7", "responsibleDelivery", e.target.value)
                    }
                    value={planRcpt.c7.responsibleDelivery}
                    disabled={!perms.all_w}
                  >
                    <p>
                      <Radio value={0} style={{ display: "none" }}>
                        None
                      </Radio>
                    </p>
                    <p>
                      <Radio value={1}>Digital</Radio>
                    </p>
                    <p>
                      <Radio value={2}>Hardcopy</Radio>
                    </p>
                  </Radio.Group>
                  <p />
                  <p />
                  Other:
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c7", "responsibleOther", e.target.value)
                    }
                    value={planRcpt.c7.responsibleOther}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                </div>
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>
          </Panel>
        ) : (
          <></>
        )}
        {perms.med_r ? (
          <Panel
            header="Prescriptions"
            key="8"
            style={{ fontWeight: "600" }}
            extra={
              <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c8" />
            }
          >
            <div className="panel-item">
              <p />
              <div className="panel-item-no">1.)</div>
              {ic ? (
                <>List the medicine(s) that they require or need.</>
              ) : (
                <>List medicine(s) that you require or need.</>
              )}
              <p />
              <div className="panel-subitem">
                <Form
                  name="medicine_list"
                  onFinish={(values) =>
                    doChange("c8", "medicines", values?.medicines)
                  }
                  autoComplete="off"
                  disabled={!perms.all_w}
                >
                  <Form.List
                    name="medicines"
                    initialValue={planRcpt.c8.medicines}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing medicine name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Name of Medicine"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "dosage"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing dosage",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Dosage and Instructions"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "cost"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing cost",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Cost"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "physician"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing physician",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Prescribing Physician"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            {perms.all_w ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          {/* add({name: 'test1', phone: 'phone 2' }) */}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={!perms.all_w}
                          >
                            Add Medicine
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!perms.all_w}
                    >
                      Save Medicines
                    </Button>
                  </Form.Item>
                </Form>

                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">2.)</div>
              [Coming Soon]{" "}
              <u>Upload picture of prescription bottle label(s) here: ...</u>
              <p />
              <div className="panel-subitem">
                <p />
                <p />

                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload Picture
                      </Button>
                    </Upload>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">3.)</div>
              Preferred pharmacy
              <p />
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "pharmacyName", e.target.value)
                  }
                  value={planRcpt.c8.pharmacyName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Location: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "pharmacyAddress", e.target.value)
                  }
                  value={planRcpt.c8.pharmacyAddress}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "pharmacyPhone", e.target.value)
                  }
                  value={planRcpt.c8.pharmacyPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Hours of Operation: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "pharmacyHours", e.target.value)
                  }
                  value={planRcpt.c8.pharmacyHours}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">4.)</div>
              Alternate pharmacy
              <p />
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "alternateName", e.target.value)
                  }
                  value={planRcpt.c8.alternateName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Location: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "alternateAddress", e.target.value)
                  }
                  value={planRcpt.c8.alternateAddress}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "alternatePhone", e.target.value)
                  }
                  value={planRcpt.c8.alternatePhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Hours of Operation: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c8", "alternateHours", e.target.value)
                  }
                  value={planRcpt.c8.alternateHours}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">5.)</div>
              {ic ? (
                <>Does anyone help them manage their prescriptions?</>
              ) : (
                <>Does anyone help you manage your prescriptions?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c8", "manage", e.target.value)}
                  value={planRcpt.c8.manage}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                  <p>
                    <Radio value={3}>I’ll need help soon</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                {planRcpt.c8.manage === 1 ? (
                  <>
                    <p />
                    Name of Person : <p />{" "}
                    <Input
                      maxLength={100}
                      onChange={(e) =>
                        doChange("c8", "manageName", e.target.value)
                      }
                      value={planRcpt.c8.manageName}
                      disabled={!perms.all_w}
                    />
                    <p />
                  </>
                ) : (
                  <></>
                )}
                {planRcpt.c8.manage === 3 ? (
                  <>
                    <p />
                    Please Explain: <p />
                    <TextArea
                      showCount
                      maxLength={255}
                      onChange={(e) =>
                        doChange("c8", "manageOther", e.target.value)
                      }
                      value={planRcpt.c8.manageOther}
                      placeholder={"Please elaborate"}
                      disabled={!perms.all_w}
                    />
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">6.)</div>
              Instruction for caregiver(s):
              <p />
              <p />
              <div className="panel-subitem">
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c8", "medicineInstruct", e.target.value)
                  }
                  value={planRcpt.c8.medicineInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>
          </Panel>
        ) : (
          <></>
        )}
        {perms.med_r ? (
          <Panel
            header="Over the Counter Products"
            key="9"
            style={{ fontWeight: "600" }}
            extra={
              <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c9" />
            }
          >
            <div className="panel-item">
              <p />
              <div className="panel-item-no">1.)</div>
              {ic ? (
                <>
                  List key personal items that they require or need on a
                  recurring basis.
                </>
              ) : (
                <>
                  List key personal items that you require or need on a
                  recurring basis.
                </>
              )}
              <p />
              <div className="panel-subitem">
                <Form
                  name="medicine_list"
                  onFinish={(values) =>
                    doChange("c9", "personals", values?.medicines)
                  }
                  autoComplete="off"
                  disabled={!perms.all_w}
                >
                  <Form.List
                    name="personals"
                    initialValue={planRcpt.c9.personals}
                  >
                    {(fields, { add, remove }) => (
                      <>
                        {fields.map(({ key, name, ...restField }) => (
                          <Space
                            key={key}
                            style={{
                              display: "flex",
                              marginBottom: 8,
                            }}
                            align="baseline"
                          >
                            <Form.Item
                              {...restField}
                              name={[name, "name"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing product name",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Product Name"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "dosage"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing dosage",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Dosage and Instructions"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "brand"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing brand",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Brand"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            <Form.Item
                              {...restField}
                              name={[name, "cost"]}
                              rules={[
                                {
                                  required: true,
                                  message: "Missing cost",
                                },
                              ]}
                            >
                              <Input
                                placeholder="Cost"
                                disabled={!perms.all_w}
                              />
                            </Form.Item>
                            {perms.all_w ? (
                              <MinusCircleOutlined
                                onClick={() => remove(name)}
                              />
                            ) : (
                              <></>
                            )}
                          </Space>
                        ))}
                        <Form.Item>
                          {/* add({name: 'test1', phone: 'phone 2' }) */}
                          <Button
                            type="dashed"
                            onClick={() => add()}
                            block
                            icon={<PlusOutlined />}
                            disabled={!perms.all_w}
                          >
                            Add Product
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form.List>
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      disabled={!perms.all_w}
                    >
                      Save Products
                    </Button>
                  </Form.Item>
                </Form>

                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">2.)</div>
              [Coming Soon]{" "}
              <u>Upload picture of prescription bottle label(s) here: ...</u>
              <p />
              <div className="panel-subitem">
                <p />
                <p />

                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload Picture
                      </Button>
                    </Upload>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">3.)</div>
              Instruction for caregiver(s):
              <p />
              <p />
              <div className="panel-subitem">
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c9", "personalInstruct", e.target.value)
                  }
                  value={planRcpt.c9.personalInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>
          </Panel>
        ) : (
          <></>
        )}
        {perms.med_r ? (
          <Panel
            header="Durable Medical Equipment or Home Medical Equipment"
            key="10"
            style={{ fontWeight: "600" }}
            extra={
              <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c10" />
            }
          >
            <div className="panel-item">
              <p />
              <div className="panel-item-no">1.)</div>
              {ic ? (
                <>
                  What home equipment do they currently use? (check all that
                  apply)
                </>
              ) : (
                <>
                  What home equipment do you currently use? (check all that
                  apply)
                </>
              )}
              <p />
              <div className="panel-subitem">
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 0)
                  }
                  defaultChecked={planRcpt.c10.equipment[0]}
                  disabled={!perms.all_w}
                >
                  Wheelchair
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 1)
                  }
                  defaultChecked={planRcpt.c10.equipment[1]}
                  disabled={!perms.all_w}
                >
                  Walker/Rollator
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 2)
                  }
                  defaultChecked={planRcpt.c10.equipment[2]}
                  disabled={!perms.all_w}
                >
                  Cane
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 3)
                  }
                  defaultChecked={planRcpt.c10.equipment[3]}
                  disabled={!perms.all_w}
                >
                  Hospital Bed
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 4)
                  }
                  defaultChecked={planRcpt.c10.equipment[4]}
                  disabled={!perms.all_w}
                >
                  Bedside Commode
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 5)
                  }
                  defaultChecked={planRcpt.c10.equipment[5]}
                  disabled={!perms.all_w}
                >
                  Manual/Automatic Lift
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 6)
                  }
                  defaultChecked={planRcpt.c10.equipment[6]}
                  disabled={!perms.all_w}
                >
                  Other
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c10", "equipment", e.target.checked, 7)
                  }
                  defaultChecked={planRcpt.c10.equipment[7]}
                  disabled={!perms.all_w}
                >
                  None of these
                </Checkbox>
                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">2.)</div>
              Is the equipment owned or rented?
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c10", "ownership", e.target.value)}
                  value={planRcpt.c10.ownership}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Owned</Radio>
                  </p>
                  <p>
                    <Radio value={2}>Rented</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                Details: <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c10", "ownershipDetails", e.target.value)
                  }
                  value={planRcpt.c10?.ownershipDetails || ""}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">3.)</div>
              {ic ? (
                <>
                  Is there home equipment that they need but haven’t acquired
                  yet?
                </>
              ) : (
                <>
                  Is there home equipment that you need but haven’t acquired
                  yet?
                </>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c10", "acquire", e.target.value)}
                  value={planRcpt.c10.acquire}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                <p />
                Please Explain: <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c10", "acquireOther", e.target.value)
                  }
                  value={planRcpt.c10.acquireOther}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">4.)</div>
              {ic ? (
                <>
                  Have they left or stated instructions for future care team
                  members regarding DME needs and purchasing?
                </>
              ) : (
                <>
                  Instructions for future care team regarding DME needs and
                  purchasing
                </>
              )}
              <p />
              <div className="panel-subitem">
                Please Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c10", "dmeInstruct", e.target.value)
                  }
                  value={planRcpt.c10.dmeInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>
          </Panel>
        ) : (
          <></>
        )}
        <Panel
          header="Social Support and Engagement"
          key="11"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c11" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            {ic ? (
              <>List all social activities that they enjoy</>
            ) : (
              <>List all social activities that you enjoy</>
            )}
            <p />
            <p />
            <div className="panel-subitem">
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c11", "activities", e.target.value)}
                value={planRcpt.c11.activities}
                placeholder={"Social activities"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            List any community memberships
            <p />
            <div className="panel-subitem">
              <Form
                name="community_list"
                onFinish={(values) =>
                  doChange("c11", "communities", values?.communities)
                }
                autoComplete="off"
                disabled={!perms.all_w}
              >
                <Form.List
                  name="communities"
                  initialValue={planRcpt.c11.communities}
                >
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "name"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing name",
                              },
                            ]}
                          >
                            <Input placeholder="Name" disabled={!perms.all_w} />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "contact"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing contact info",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Contact Info"
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, "cost"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing cost",
                              },
                            ]}
                          >
                            <Input placeholder="Cost" disabled={!perms.all_w} />
                          </Form.Item>
                          {perms.all_w ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : (
                            <></>
                          )}
                        </Space>
                      ))}
                      <Form.Item>
                        {/* add({name: 'test1', phone: 'phone 2' }) */}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          disabled={!perms.all_w}
                        >
                          Add Membership
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!perms.all_w}
                  >
                    Save Memberships
                  </Button>
                </Form.Item>
              </Form>

              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>
            List online memberships, accounts, or subscriptions
            <p />
            <div className="panel-subitem">
              <Form
                name="online_list"
                onFinish={(values) => doChange("c11", "online", values?.online)}
                autoComplete="off"
                disabled={!perms.all_w}
              >
                <Form.List name="online" initialValue={planRcpt.c11.online}>
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space
                          key={key}
                          style={{
                            display: "flex",
                            marginBottom: 8,
                          }}
                          align="baseline"
                        >
                          <Form.Item
                            {...restField}
                            name={[name, "details"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing details",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Details"
                              style={{ width: "40rem" }}
                              disabled={!perms.all_w}
                            />
                          </Form.Item>
                          {perms.all_w ? (
                            <MinusCircleOutlined onClick={() => remove(name)} />
                          ) : (
                            <></>
                          )}
                        </Space>
                      ))}
                      <Form.Item>
                        {/* add({name: 'test1', phone: 'phone 2' }) */}
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          block
                          icon={<PlusOutlined />}
                          disabled={!perms.all_w}
                        >
                          Add Online Membership/Account/Subscription
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!perms.all_w}
                  >
                    Save Memberships/Accounts/Subscriptions
                  </Button>
                </Form.Item>
              </Form>

              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>
            {ic ? (
              <>Do they have a spiritual practice or religious advisor?</>
            ) : (
              <>Do you have a spiritual practice or religious advisor?</>
            )}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c11", "spiritual", e.target.value)}
                value={planRcpt.c11.spiritual}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
              </Radio.Group>
              <p />
              <p />
              <p />
              Please Explain: <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c11", "spiritualOther", e.target.value)
                }
                value={planRcpt.c11.spiritualOther}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>
            {ic ? <>Do they see a therapist?</> : <>Do you see a therapist?</>}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c11", "therapist", e.target.value)}
                value={planRcpt.c11.therapist}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
              </Radio.Group>
              <p />
              <p />
              <p />
              Please Explain: <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c11", "therapistOther", e.target.value)
                }
                value={planRcpt.c11.therapistOther}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>
            {ic ? (
              <>
                Are there any activities now or in the future that they are
                interested in pursuing?
              </>
            ) : (
              <>
                Are there any activities now or in the future that you are
                interested in pursuing?
              </>
            )}
            <p />
            <p />
            <div className="panel-subitem">
              Please Explain:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c11", "activityFuture", e.target.value)
                }
                value={planRcpt.c11.activityFuture}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>
            {ic ? (
              <>What tools do they use to organize their social time?</>
            ) : (
              <>What tools do you use to organize your social time?</>
            )}
            <p />
            <p />
            <div className="panel-subitem">
              Please Explain:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c11", "tools", e.target.value)}
                value={planRcpt.c11.tools}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
          </div>
        </Panel>
        <Panel
          header="Health and Wellness Status"
          key="12"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c12" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            Health and Wellness Goals
            <p />
            (check all that apply)
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 0)}
                defaultChecked={planRcpt.c12.goals[0]}
                disabled={!perms.all_w}
              >
                To remain as independent as possible for as long as possible
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 1)}
                defaultChecked={planRcpt.c12.goals[1]}
                disabled={!perms.all_w}
              >
                To remain healthy and active
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 2)}
                defaultChecked={planRcpt.c12.goals[2]}
                disabled={!perms.all_w}
              >
                To remain in my own home for as long as possible
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 3)}
                defaultChecked={planRcpt.c12.goals[3]}
                disabled={!perms.all_w}
              >
                To focus on a hobby
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 4)}
                defaultChecked={planRcpt.c12.goals[4]}
                disabled={!perms.all_w}
              >
                To work for as long as possible
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 5)}
                defaultChecked={planRcpt.c12.goals[5]}
                disabled={!perms.all_w}
              >
                To become involved in the community
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 6)}
                defaultChecked={planRcpt.c12.goals[6]}
                disabled={!perms.all_w}
              >
                To remain as financially independent as possible
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 7)}
                defaultChecked={planRcpt.c12.goals[7]}
                disabled={!perms.all_w}
              >
                To take classes
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 8)}
                defaultChecked={planRcpt.c12.goals[8]}
                disabled={!perms.all_w}
              >
                To create a safety net in the event of an emergency or crisis
                situation
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 9)}
                defaultChecked={planRcpt.c12.goals[9]}
                disabled={!perms.all_w}
              >
                To start a business
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 10)}
                defaultChecked={planRcpt.c12.goals[10]}
                disabled={!perms.all_w}
              >
                To buy a second home
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 11)}
                defaultChecked={planRcpt.c12.goals[11]}
                disabled={!perms.all_w}
              >
                To move closer to family
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 12)}
                defaultChecked={planRcpt.c12.goals[12]}
                disabled={!perms.all_w}
              >
                To relocate to a smaller home
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c12", "goals", e.target.checked, 13)}
                defaultChecked={planRcpt.c12.goals[13]}
                disabled={!perms.all_w}
              >
                To travel
              </Checkbox>
              <p />
              Other:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c12", "goalsOther", e.target.value)}
                value={planRcpt.c12.goalsOther}
                placeholder="Please elaborate"
                disabled={!perms.all_w}
              />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            {ic ? (
              <>
                Is there anything they’ve shared about their health and wellness
                status that would be helpful to know?
              </>
            ) : (
              <>
                Is there anything caregivers need to know in now or in the
                future about your health and wellness status?
              </>
            )}
            <p />
            <p />
            <div className="panel-subitem">
              Please Explain:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c12", "healthStatus", e.target.value)
                }
                value={planRcpt.c12.healthStatus}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>
            {ic ? (
              <>
                Do they currently receive any community based services in their
                home?
              </>
            ) : (
              <>
                Do you currently receive any community based services in your
                home?
              </>
            )}
            <p />
            <p />
            <div className="panel-subitem">
              Please Explain:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c12", "communityServices", e.target.value)
                }
                value={planRcpt.c12.communityServices}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>
            {ic ? (
              <>
                Which of these everyday activities do they require assistance
                with (check all that apply)?
              </>
            ) : (
              <>
                Which of these everyday activities do you require assistance
                with (check all that apply)?
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 0)
                }
                defaultChecked={planRcpt.c12.activities[0]}
                disabled={!perms.all_w}
              >
                Bathing
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 1)
                }
                defaultChecked={planRcpt.c12.activities[1]}
                disabled={!perms.all_w}
              >
                Dressing
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 2)
                }
                defaultChecked={planRcpt.c12.activities[2]}
                disabled={!perms.all_w}
              >
                Grooming
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 3)
                }
                defaultChecked={planRcpt.c12.activities[3]}
                disabled={!perms.all_w}
              >
                Toileting
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 4)
                }
                defaultChecked={planRcpt.c12.activities[4]}
                disabled={!perms.all_w}
              >
                Cooking
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 5)
                }
                defaultChecked={planRcpt.c12.activities[5]}
                disabled={!perms.all_w}
              >
                Eating
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 6)
                }
                defaultChecked={planRcpt.c12.activities[6]}
                disabled={!perms.all_w}
              >
                Getting into/out of bed/chair
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 7)
                }
                defaultChecked={planRcpt.c12.activities[7]}
                disabled={!perms.all_w}
              >
                Walking
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "activities", e.target.checked, 8)
                }
                defaultChecked={planRcpt.c12.activities[8]}
                disabled={!perms.all_w}
              >
                None
              </Checkbox>
              <p />
              Other:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c12", "activitiesOther", e.target.value)
                }
                value={planRcpt.c12.activitiesOther}
                placeholder="Please elaborate"
                disabled={!perms.all_w}
              />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>
            {ic ? (
              <>They have challenges with</>
            ) : (
              <>I have challenges with my</>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "challenges", e.target.checked, 0)
                }
                defaultChecked={planRcpt.c12.challenges[0]}
                disabled={!perms.all_w}
              >
                Vision
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "challenges", e.target.checked, 1)
                }
                defaultChecked={planRcpt.c12.challenges[1]}
                disabled={!perms.all_w}
              >
                Hearing
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "challenges", e.target.checked, 2)
                }
                defaultChecked={planRcpt.c12.challenges[2]}
                disabled={!perms.all_w}
              >
                Speech
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "challenges", e.target.checked, 3)
                }
                defaultChecked={planRcpt.c12.challenges[3]}
                disabled={!perms.all_w}
              >
                Mobility
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "challenges", e.target.checked, 4)
                }
                defaultChecked={planRcpt.c12.challenges[4]}
                disabled={!perms.all_w}
              >
                Memory
              </Checkbox>
              <p />
              Other:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c12", "challengesOther", e.target.value)
                }
                value={planRcpt.c12.challengesOther}
                placeholder="Please elaborate"
                disabled={!perms.all_w}
              />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>
            {ic ? (
              <>
                If needed, what services would they want provided in their home?
              </>
            ) : (
              <>
                If needed, what services would you want provided in the home?{" "}
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "services", e.target.checked, 0)
                }
                defaultChecked={planRcpt.c12.services[0]}
                disabled={!perms.all_w}
              >
                Self-care services (e.g. mobile nail technician, mobile
                hairdresser, pet care, etc.)
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "services", e.target.checked, 1)
                }
                defaultChecked={planRcpt.c12.services[1]}
                disabled={!perms.all_w}
              >
                Meal delivery services
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "services", e.target.checked, 2)
                }
                defaultChecked={planRcpt.c12.services[2]}
                disabled={!perms.all_w}
              >
                Transportation
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c12", "services", e.target.checked, 3)
                }
                defaultChecked={planRcpt.c12.services[3]}
                disabled={!perms.all_w}
              >
                Medical care
              </Checkbox>
              <p />
              Other:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c12", "servicesOther", e.target.value)
                }
                value={planRcpt.c12.servicesOther}
                placeholder="Please elaborate"
                disabled={!perms.all_w}
              />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>
            {ic ? (
              <>Do they have any special dietary needs?</>
            ) : (
              <>Special Dietary needs:</>
            )}
            <p />
            <p />
            <div className="panel-subitem">
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c12", "dietaryNeeds", e.target.value)
                }
                value={planRcpt.c12.dietaryNeeds}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
          </div>
        </Panel>
        {perms.leg_r ? (
          <Panel
            header="Legal"
            key="13"
            style={{ fontWeight: "600" }}
            extra={
              <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c13" />
            }
          >
            <div className="panel-item">
              <p />
              <div className="panel-item-no">1.)</div>
              Family Lawyer Contact
              <p />
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "lawyerName", e.target.value)
                  }
                  value={planRcpt.c13.lawyerName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "lawyerPhone", e.target.value)
                  }
                  value={planRcpt.c13.lawyerPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Email: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "lawyerEmail", e.target.value)
                  }
                  value={planRcpt.c13.lawyerEmail}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Instructions for care team:
                <p />
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c13", "lawyerInstruct", e.target.value)
                  }
                  value={planRcpt.c13.lawyerInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">2.)</div>
              {ic ? (
                <>Which of the following legal documents have they completed?</>
              ) : (
                <>Which of the following legal documents have you completed?</>
              )}
              <p />
              <div className="panel-subitem">
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "documents", e.target.checked, 0)
                  }
                  defaultChecked={planRcpt.c13.documents[0]}
                  disabled={!perms.all_w}
                >
                  Power of Attorney
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "documents", e.target.checked, 1)
                  }
                  defaultChecked={planRcpt.c13.documents[1]}
                  disabled={!perms.all_w}
                >
                  Living Will
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "documents", e.target.checked, 2)
                  }
                  defaultChecked={planRcpt.c13.documents[2]}
                  disabled={!perms.all_w}
                >
                  Advanced Directive
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "documents", e.target.checked, 3)
                  }
                  defaultChecked={planRcpt.c13.documents[3]}
                  disabled={!perms.all_w}
                >
                  Trust
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "documents", e.target.checked, 4)
                  }
                  defaultChecked={planRcpt.c13.documents[4]}
                  disabled={!perms.all_w}
                >
                  Will
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "documents", e.target.checked, 5)
                  }
                  defaultChecked={planRcpt.c13.documents[5]}
                  disabled={!perms.all_w}
                >
                  Other
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "documents", e.target.checked, 6)
                  }
                  defaultChecked={planRcpt.c13.documents[6]}
                  disabled={!perms.all_w}
                >
                  I don’t have any of these
                </Checkbox>
                <p />
                Instructions for care team:
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c13", "documentsInstruct", e.target.value)
                  }
                  value={planRcpt.c13.documentsInstruct}
                  placeholder="Please elaborate"
                  disabled={!perms.all_w}
                />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">3.)</div>
              {ic ? (
                <>
                  If you’re just starting out and are interested in learning
                  more, please indicate which topics are relevant for your
                  situation.
                </>
              ) : (
                <>
                  If you’re just starting out and are interested in learning
                  more, please indicate which topics are relevant for your
                  situation.
                </>
              )}
              <p />
              <div className="panel-subitem">
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "topics", e.target.checked, 0)
                  }
                  defaultChecked={planRcpt.c13.topics[0]}
                  disabled={!perms.all_w}
                >
                  Power of Attorney
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "topics", e.target.checked, 1)
                  }
                  defaultChecked={planRcpt.c13.topics[1]}
                  disabled={!perms.all_w}
                >
                  Living Will
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "topics", e.target.checked, 2)
                  }
                  defaultChecked={planRcpt.c13.topics[2]}
                  disabled={!perms.all_w}
                >
                  Advanced Directive
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "topics", e.target.checked, 3)
                  }
                  defaultChecked={planRcpt.c13.topics[3]}
                  disabled={!perms.all_w}
                >
                  Trust
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c13", "topics", e.target.checked, 4)
                  }
                  defaultChecked={planRcpt.c13.topics[4]}
                  disabled={!perms.all_w}
                >
                  Will
                </Checkbox>
                <p />
                Other:
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c13", "topicsOther", e.target.value)
                  }
                  value={planRcpt.c13.topicsOther}
                  placeholder="Please elaborate"
                  disabled={!perms.all_w}
                />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">4.)</div>
              {ic ? (
                <>Who is their medical power of attorney?</>
              ) : (
                <>Who is your medical power of attorney?</>
              )}
              <p />
              <div className="panel-subitem">
                Primary Medical Power of Attorney
                <p />
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "medicalAttyName", e.target.value)
                  }
                  value={planRcpt.c13.medicalAttyName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "medicalAttyPhone", e.target.value)
                  }
                  value={planRcpt.c13.medicalAttyPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Email: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "medicalAttyEmail", e.target.value)
                  }
                  value={planRcpt.c13.medicalAttyEmail}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
              </div>
              <p />
              <p />
              <div className="panel-subitem">
                Alternate Medical Power of Attorney
                <p />
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "medicalAltName", e.target.value)
                  }
                  value={planRcpt.c13.medicalAltName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "medicalAltPhone", e.target.value)
                  }
                  value={planRcpt.c13.medicalAltPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Email: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "medicalAltEmail", e.target.value)
                  }
                  value={planRcpt.c13.medicalAltEmail}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">5.)</div>
              {ic ? (
                <>Who is their financial power of attorney?</>
              ) : (
                <>Who is your financial power of attorney?</>
              )}
              <p />
              <div className="panel-subitem">
                Primary Financial Power of Attorney
                <p />
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "financeAttyName", e.target.value)
                  }
                  value={planRcpt.c13.financeAttyName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "financeAttyPhone", e.target.value)
                  }
                  value={planRcpt.c13.financeAttyPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Email: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "financeAttyEmail", e.target.value)
                  }
                  value={planRcpt.c13.financeAttyEmail}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
              </div>
              <p />
              <p />
              <div className="panel-subitem">
                Alternate Financial Power of Attorney
                <p />
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "financeAltName", e.target.value)
                  }
                  value={planRcpt.c13.financeAltName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Phone Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "financeAltPhone", e.target.value)
                  }
                  value={planRcpt.c13.financeAltPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Email: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c13", "financeAltEmail", e.target.value)
                  }
                  value={planRcpt.c13.financeAltEmail}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">6.)</div>
              {ic ? (
                <>Where do they keep their documents located?</>
              ) : (
                <>Where are these documents located?</>
              )}
              <p />
              <p />
              <div className="panel-subitem">
                Please Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c13", "locationOther", e.target.value)
                  }
                  value={planRcpt.c13.locationOther}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Instructions for care team:
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c13", "locationInstruct", e.target.value)
                  }
                  value={planRcpt.c13.locationInstruct}
                  placeholder="Please elaborate"
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                {!sf ? (
                  <>
                    <Upload {...props} disabled={!perms.all_w}>
                      <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                        Upload digital copies
                      </Button>
                    </Upload>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">7.)</div>
              {ic ? (
                <>Are there any additional legal settlements or instructions?</>
              ) : (
                <>
                  Are there any additional legal settlements or instructions to
                  your caregivers?
                </>
              )}
              <p />
              <div className="panel-subitem">
                Please Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c13", "legalInstruct", e.target.value)
                  }
                  value={planRcpt.c13.legalInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
            </div>
          </Panel>
        ) : (
          <></>
        )}
        {perms.fin_r ? (
          <Panel
            header="Finances"
            key="14"
            style={{ fontWeight: "600" }}
            extra={
              <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c14" />
            }
          >
            <div className="panel-item">
              <p />
              <div className="panel-item-no">1.)</div>
              Primary Banking
              <p />
              <div className="panel-subitem">
                <p />
                Institution: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "primaryInstitution", e.target.value)
                  }
                  value={planRcpt.c14.primaryInstitution}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Account(s): <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "primaryAccounts", e.target.value)
                  }
                  value={planRcpt.c14.primaryAccounts}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                <p />
                Who has permission to access accounts?
                <p />
                <div className="panel-subitem">
                  <p />
                  Name: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "primaryAccessName", e.target.value)
                    }
                    value={planRcpt.c14.primaryAccessName}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Phone Number: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "primaryAccessPhone", e.target.value)
                    }
                    value={planRcpt.c14.primaryAccessPhone}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Email: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "primaryAccessEmail", e.target.value)
                    }
                    value={planRcpt.c14.primaryAccessEmail}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </div>
              </div>
              <p />
              Instructions for care team:
              <p />
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c14", "primaryInstruct", e.target.value)
                }
                value={planRcpt.c14.primaryInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">2.)</div>
              Other Banking (1)
              <p />
              <div className="panel-subitem">
                <p />
                Institution: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "other1Institution", e.target.value)
                  }
                  value={planRcpt.c14.other1Institution}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Account(s): <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "other1Accounts", e.target.value)
                  }
                  value={planRcpt.c14.other1Accounts}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                <p />
                Who has permission to access accounts?
                <p />
                <div className="panel-subitem">
                  <p />
                  Name: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "other1AccessName", e.target.value)
                    }
                    value={planRcpt.c14.other1AccessName}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Phone Number: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "other1AccessPhone", e.target.value)
                    }
                    value={planRcpt.c14.other1AccessPhone}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Email: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "other1AccessEmail", e.target.value)
                    }
                    value={planRcpt.c14.other1AccessEmail}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </div>
              </div>
              <p />
              Instructions for care team:
              <p />
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c14", "other1Instruct", e.target.value)
                }
                value={planRcpt.c14.other1Instruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">3.)</div>
              Other Banking (2)
              <p />
              <div className="panel-subitem">
                <p />
                Institution: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "other2Institution", e.target.value)
                  }
                  value={planRcpt.c14.other2Institution}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Account(s): <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "other2Accounts", e.target.value)
                  }
                  value={planRcpt.c14.other2Accounts}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                <p />
                Who has permission to access accounts?
                <p />
                <div className="panel-subitem">
                  <p />
                  Name: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "other2AccessName", e.target.value)
                    }
                    value={planRcpt.c14.other2AccessName}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Phone Number: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "other2AccessPhone", e.target.value)
                    }
                    value={planRcpt.c14.other2AccessPhone}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Email: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "other2AccessEmail", e.target.value)
                    }
                    value={planRcpt.c14.other2AccessEmail}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </div>
              </div>
              <p />
              Instructions for care team:
              <p />
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c14", "other2Instruct", e.target.value)
                }
                value={planRcpt.c14.other2Instruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">4.)</div>
              Retirement Account (1)
              <p />
              <div className="panel-subitem">
                <p />
                Institution: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "retire1Institution", e.target.value)
                  }
                  value={planRcpt.c14.retire1Institution}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Account(s): <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "retire1Accounts", e.target.value)
                  }
                  value={planRcpt.c14.retire1Accounts}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                <p />
                Who has permission to access accounts?
                <p />
                <div className="panel-subitem">
                  <p />
                  Name: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "retire1AccessName", e.target.value)
                    }
                    value={planRcpt.c14.retire1AccessName}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Phone Number: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "retire1AccessPhone", e.target.value)
                    }
                    value={planRcpt.c14.retire1AccessPhone}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Email: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "retire1AccessEmail", e.target.value)
                    }
                    value={planRcpt.c14.retire1AccessEmail}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </div>
              </div>
              <p />
              Instructions for care team:
              <p />
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c14", "retire1Instruct", e.target.value)
                }
                value={planRcpt.c14.retire1Instruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">5.)</div>
              Retirement Account (2)
              <p />
              <div className="panel-subitem">
                <p />
                Institution: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "retire2Institution", e.target.value)
                  }
                  value={planRcpt.c14.retire2Institution}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                Account(s): <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "retire2Accounts", e.target.value)
                  }
                  value={planRcpt.c14.retire2Accounts}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                <p />
                <p />
                Who has permission to access accounts?
                <p />
                <div className="panel-subitem">
                  <p />
                  Name: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "retire2AccessName", e.target.value)
                    }
                    value={planRcpt.c14.retire2AccessName}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Phone Number: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "retire2AccessPhone", e.target.value)
                    }
                    value={planRcpt.c14.retire2AccessPhone}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  Email: <p />{" "}
                  <Input
                    maxLength={100}
                    onChange={(e) =>
                      doChange("c14", "retire2AccessEmail", e.target.value)
                    }
                    value={planRcpt.c14.retire2AccessEmail}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </div>
              </div>
              <p />
              Instructions for care team:
              <p />
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c14", "retire2Instruct", e.target.value)
                }
                value={planRcpt.c14.retire2Instruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">6.)</div>
              {ic ? (
                <>Do they have a financial advisor?</>
              ) : (
                <>Do you have a financial advisor?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c14", "advisor", e.target.value)}
                  value={planRcpt.c14.advisor}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                {planRcpt.c14.advisor === 1 ? (
                  <>
                    {" "}
                    Financial Advisor <p />
                    <div className="panel-subitem">
                      <p />
                      Name: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "advisorName", e.target.value)
                        }
                        value={planRcpt.c14.advisorName}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      <p />
                      Phone Number: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "advisorPhone", e.target.value)
                        }
                        value={planRcpt.c14.advisorPhone}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      <p />
                      Email: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "advisorEmail", e.target.value)
                        }
                        value={planRcpt.c14.advisorEmail}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">7.)</div>
              {ic ? (
                <>Do they have a life insurance policy?</>
              ) : (
                <>Do you have a life insurance policy?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c14", "life", e.target.value)}
                  value={planRcpt.c14.life}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                {planRcpt.c14.life === 1 ? (
                  <>
                    {" "}
                    Life Insurance <p />
                    <div className="panel-subitem">
                      <p />
                      Institution: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "lifeInstitution", e.target.value)
                        }
                        value={planRcpt.c14.lifeInstitution}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Contact Phone Number: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "lifePhone", e.target.value)
                        }
                        value={planRcpt.c14.lifePhone}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Policy Number: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "lifePolicy", e.target.value)
                        }
                        value={planRcpt.c14.lifePolicy}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Instructions for care team:
                      <p />
                      <p />
                      <TextArea
                        showCount
                        maxLength={255}
                        onChange={(e) =>
                          doChange("c14", "lifeInstruct", e.target.value)
                        }
                        value={planRcpt.c14.lifeInstruct}
                        placeholder={"Please elaborate"}
                        disabled={!perms.all_w}
                      />
                    </div>
                    <p />
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">8.)</div>
              {ic ? (
                <>Are they collecting any disability insurance?</>
              ) : (
                <>Are you collecting any disability insurance?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) =>
                    doChange("c14", "disability", e.target.value)
                  }
                  value={planRcpt.c14.disability}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                {planRcpt.c14.disability === 1 ? (
                  <>
                    {" "}
                    Disabiity Insurance <p />
                    <div className="panel-subitem">
                      <p />
                      Institution: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange(
                            "c14",
                            "disabilityInstitution",
                            e.target.value
                          )
                        }
                        value={planRcpt.c14.disabilityInstitution}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Contact Phone Number: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "disabilityPhone", e.target.value)
                        }
                        value={planRcpt.c14.disabilityPhone}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Policy Number: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "disabilityPolicy", e.target.value)
                        }
                        value={planRcpt.c14.disabilityPolicy}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Instructions for care team:
                      <p />
                      <p />
                      <TextArea
                        showCount
                        maxLength={255}
                        onChange={(e) =>
                          doChange("c14", "disabilityInstruct", e.target.value)
                        }
                        value={planRcpt.c14.disabilityInstruct}
                        placeholder={"Please elaborate"}
                        disabled={!perms.all_w}
                      />
                    </div>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">9.)</div>
              {ic ? (
                <>Are they collecting any annuities?</>
              ) : (
                <>Are you collecting any annuities?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c14", "annuity", e.target.value)}
                  value={planRcpt.c14.annuity}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                {planRcpt.c14.annuity === 1 ? (
                  <>
                    {" "}
                    Annuity <p />
                    <div className="panel-subitem">
                      <p />
                      Institution: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "annuityInstitution", e.target.value)
                        }
                        value={planRcpt.c14.annuityInstitution}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Contact Phone Number: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "annuityPhone", e.target.value)
                        }
                        value={planRcpt.c14.annuityPhone}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Policy Number: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "annuityPolicy", e.target.value)
                        }
                        value={planRcpt.c14.annuityPolicy}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Instructions for care team:
                      <p />
                      <p />
                      <TextArea
                        showCount
                        maxLength={255}
                        onChange={(e) =>
                          doChange("c14", "annuityInstruct", e.target.value)
                        }
                        value={planRcpt.c14.annuityInstruct}
                        placeholder={"Please elaborate"}
                        disabled={!perms.all_w}
                      />
                    </div>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">10.)</div>
              {ic ? (
                <>Are they part of a trust?</>
              ) : (
                <>Are you part of a trust?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c14", "trust", e.target.value)}
                  value={planRcpt.c14.trust}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                {planRcpt.c14.trust === 1 ? (
                  <>
                    {" "}
                    Trust <p />
                    <div className="panel-subitem">
                      <p />
                      Name of Trust: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "trustName", e.target.value)
                        }
                        value={planRcpt.c14.trustName}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Contact Information: <p />{" "}
                      <Input
                        maxLength={100}
                        onChange={(e) =>
                          doChange("c14", "trustContact", e.target.value)
                        }
                        value={planRcpt.c14.trustContact}
                        disabled={!perms.all_w}
                      />
                      <p />
                      <p />
                      Instructions for care team:
                      <p />
                      <p />
                      <TextArea
                        showCount
                        maxLength={255}
                        onChange={(e) =>
                          doChange("c14", "trustInstruct", e.target.value)
                        }
                        value={planRcpt.c14.trustInstruct}
                        placeholder={"Please elaborate"}
                        disabled={!perms.all_w}
                      />
                    </div>
                    <p />
                  </>
                ) : (
                  <></>
                )}
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">11.)</div>
              Social Security Statement Benefits
              <p />
              <div className="panel-subitem">
                <p>Online Statements?</p>
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c14", "ssOnline", e.target.value)}
                  value={planRcpt.c14.ssOnline}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
                Social Security Number: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) => doChange("c14", "ssNumber", e.target.value)}
                  value={planRcpt.c14.ssNumber}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Instructions for care team:
                <p />
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c14", "ssInstruct", e.target.value)
                  }
                  value={planRcpt.c14.ssInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">12.)</div>
              {ic ? (
                <>Are they receiving Social Security Disability benefits?</>
              ) : (
                <>Are you receiving Social Security Disability benefits?</>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) =>
                    doChange("c14", "ssdisability", e.target.value)
                  }
                  value={planRcpt.c14.ssdisability}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
              </div>
              {planRcpt.c14.ssdisability === 1 ? (
                <>
                  Explain:
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c14", "ssdisabilityOther", e.target.value)
                    }
                    value={planRcpt.c14.ssdisabilityOther}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                </>
              ) : (
                <></>
              )}
              <p />
              {!sf ? (
                <>
                  <Upload {...props} disabled={!perms.all_w}>
                    <Button icon={<UploadOutlined />} disabled={!perms.all_w}>
                      Upload documents
                    </Button>
                  </Upload>
                  <p />
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">13.)</div>
              {ic ? (
                <>
                  Are there any rental agreements or business contracts that
                  should be disclosed?
                </>
              ) : (
                <>
                  Are there any rental agreements or business contracts that
                  should be disclosed?
                </>
              )}
              <p />
              <div className="panel-subitem">
                <Radio.Group
                  size="large"
                  onChange={(e) => doChange("c14", "rental", e.target.value)}
                  value={planRcpt.c14.rental}
                  disabled={!perms.all_w}
                >
                  <p>
                    <Radio value={0} style={{ display: "none" }}>
                      None
                    </Radio>
                  </p>
                  <p>
                    <Radio value={1}>Yes</Radio>
                  </p>
                  <p>
                    <Radio value={2}>No</Radio>
                  </p>
                </Radio.Group>
                <p />
                <p />
              </div>
              {planRcpt.c14.rental === 1 ? (
                <>
                  Explain:
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c14", "rentalOther", e.target.value)
                    }
                    value={planRcpt.c14.rentalOther}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                </>
              ) : (
                <></>
              )}
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">14.)</div>
              Records of any personal loans made to others?
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c14", "recordsDetail", e.target.value)
                  }
                  value={planRcpt.c14.recordsDetail}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Contact:
                <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "recordsContact", e.target.value)
                  }
                  value={planRcpt.c14.recordsContact}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Where are the records kept?
                <div className="panel-subitem">
                  <p />
                  Explain:
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c14", "recordsKept", e.target.value)
                    }
                    value={planRcpt.c14.recordsKept}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                  <p />
                  <p />
                  <p />
                  {!sf ? (
                    <>
                      <Upload {...props} disabled={!perms.all_w}>
                        <Button
                          icon={<UploadOutlined />}
                          disabled={!perms.all_w}
                        >
                          Upload documents
                        </Button>
                      </Upload>
                      <p />
                    </>
                  ) : (
                    <></>
                  )}
                  <p />
                  <p />
                </div>
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">15.)</div>
              Tax Accountant
              <p />
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "accountantName", e.target.value)
                  }
                  value={planRcpt.c14.accountantName}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Phone: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "accountantPhone", e.target.value)
                  }
                  value={planRcpt.c14.accountantPhone}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Email: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c14", "accountantEmail", e.target.value)
                  }
                  value={planRcpt.c14.accountantEmail}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                Where are the documents located?
                <div className="panel-subitem">
                  <p />
                  <p />
                  Explain:
                  <TextArea
                    showCount
                    maxLength={255}
                    onChange={(e) =>
                      doChange("c14", "accountantRecords", e.target.value)
                    }
                    value={planRcpt.c14.accountantRecords}
                    placeholder={"Please elaborate"}
                    disabled={!perms.all_w}
                  />
                </div>
              </div>
              <p />
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">16.)</div>
              {ic ? (
                <>
                  Do they have any worries or concerns about paying for care in
                  the future?
                </>
              ) : (
                <>
                  Do you have any worries or concerns about paying for care in
                  the future?
                </>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                <p />
                Explain:
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c14", "carepayConcerns", e.target.value)
                  }
                  value={planRcpt.c14.carepayConcerns}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">17.)</div>
              {ic ? (
                <>Do they have any wishes or instructions for your care team?</>
              ) : (
                <>Do you have any wishes or instructions for your care team?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                <p />
                Explain:
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c14", "careteamInstruct", e.target.value)
                  }
                  value={planRcpt.c14.careteamInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>
          </Panel>
        ) : (
          <></>
        )}
        {perms.med_r ? (
          <Panel
            header="End-of-Life Wishes and Instructions"
            key="15"
            style={{ fontWeight: "600" }}
            extra={
              <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c15" />
            }
          >
            <div className="panel-item">
              End-of-life planning refers to the steps a person takes to get
              their affairs in order and determine how they want to spend their
              last days.
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">1.)</div>
              {ic ? (
                <>
                  Who have they spoken to about how they want to spend their
                  last days?
                </>
              ) : (
                <>
                  Who have you spoken with about how you want to spend your last
                  days?
                </>
              )}
              <p />
              <p />
              Person (A)
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c15", "spoken1Name", e.target.value)
                  }
                  value={planRcpt.c15.spoken1Name}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                What is their role: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c15", "spoken1Role", e.target.value)
                  }
                  value={planRcpt.c15.spoken1Role}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
              <p />
              Person (B)
              <div className="panel-subitem">
                <p />
                Name: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c15", "spoken2Name", e.target.value)
                  }
                  value={planRcpt.c15.spoken2Name}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                What is their role: <p />{" "}
                <Input
                  maxLength={100}
                  onChange={(e) =>
                    doChange("c15", "spoken2Role", e.target.value)
                  }
                  value={planRcpt.c15.spoken2Role}
                  disabled={!perms.all_w}
                />
                <p />
                <p />
              </div>
              <p />
              <p />
              {ic ? (
                <>Any instructions they want to share?</>
              ) : (
                <>Any instructions you want to share?</>
              )}
              <p />
              <div className="panel-subitem">
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "spokenInstruct", e.target.value)
                  }
                  value={planRcpt.c15.spokenInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">2.)</div>
              {ic ? (
                <>
                  Are there religious or cultural themes that are important to
                  acknowledge at end-of-life?
                </>
              ) : (
                <>
                  How can your care and support team honor your culture or faith
                  at end-of-life?
                </>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Instructions for the care team:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "culturalInstruct", e.target.value)
                  }
                  value={planRcpt.c15.culturalInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">3.)</div>
              {ic ? (
                <>Does anything worry them about the days ahead?</>
              ) : (
                <>Does anything worry you about the days ahead?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "worryDetails", e.target.value)
                  }
                  value={planRcpt.c15.worryDetails}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                Instructions for the care team:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "worryInstruct", e.target.value)
                  }
                  value={planRcpt.c15.worryInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">4.)</div>
              {ic ? (
                <>Do they have any concerns about any family issues?</>
              ) : (
                <>Do you have any concerns about any family issues?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "concernDetails", e.target.value)
                  }
                  value={planRcpt.c15.concernDetails}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                Instructions for the care team:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "concernInstruct", e.target.value)
                  }
                  value={planRcpt.c15.concernInstruct}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">5.)</div>
              {ic ? (
                <>Do they have any wishes for their caregivers and survivors?</>
              ) : (
                <>Do you have any wishes for your caregivers and survivors?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "wishesDetails", e.target.value)
                  }
                  value={planRcpt.c15.wishesDetails}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">6.)</div>
              {ic ? (
                <>
                  Are they interested in leaving messages to share with their
                  survivors?
                </>
              ) : (
                <>
                  Do you have any messages you would like to share with your
                  survivors?
                </>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "messageDetails", e.target.value)
                  }
                  value={planRcpt.c15.messageDetails}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
                List who they want to share messages with:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "messageList", e.target.value)
                  }
                  value={planRcpt.c15.messageList}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">7.)</div>
              {ic ? (
                <>
                  Would they be interested in developing messages using
                  different media formats?
                </>
              ) : (
                <>Do you want to share messages via various media formats?</>
              )}
              <p />
              <div className="panel-subitem">
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "formats", e.target.checked, 0)
                  }
                  defaultChecked={planRcpt.c15.formats[0]}
                  disabled={!perms.all_w}
                >
                  Video message
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "formats", e.target.checked, 1)
                  }
                  defaultChecked={planRcpt.c15.formats[1]}
                  disabled={!perms.all_w}
                >
                  Audio message
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "formats", e.target.checked, 2)
                  }
                  defaultChecked={planRcpt.c15.formats[2]}
                  disabled={!perms.all_w}
                >
                  Written message
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "formats", e.target.checked, 3)
                  }
                  defaultChecked={planRcpt.c15.formats[3]}
                  disabled={!perms.all_w}
                >
                  In-person meetings
                </Checkbox>
                <p />
                Other:
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "formatsOther", e.target.value)
                  }
                  value={planRcpt.c15.formatsOther}
                  placeholder="Please elaborate"
                  disabled={!perms.all_w}
                />
                <p />
                <p />
                {ic ? (
                  <>Do you want to help them with this legacy project?</>
                ) : (
                  <>Do you want to help with this legacy project?</>
                )}
                <p />
                <div className="panel-subitem">
                  <Radio.Group
                    size="large"
                    onChange={(e) =>
                      doChange("c15", "formatHelp", e.target.value)
                    }
                    value={planRcpt.c15.formatHelp}
                    disabled={!perms.all_w}
                  >
                    <p>
                      <Radio value={0} style={{ display: "none" }}>
                        None
                      </Radio>
                    </p>
                    <p>
                      <Radio value={1}>Yes</Radio>
                    </p>
                    <p>
                      <Radio value={2}>No</Radio>
                    </p>
                  </Radio.Group>
                  <p />
                  <p />
                </div>
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">8.)</div>
              {ic ? (
                <>What don’t they want to happen during their last days?</>
              ) : (
                <>What don’t you want to happen during your last days?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "nothappenDetails", e.target.value)
                  }
                  value={planRcpt.c15.nothappenDetails}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">9.)</div>
              {ic ? (
                <>
                  Does anything frighten them about dying or the dying process?
                </>
              ) : (
                <>Does anything frighten you about dying?</>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "frightenDetails", e.target.value)
                  }
                  value={planRcpt.c15.frightenDetails}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              <p />
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">10.)</div>
              {ic ? (
                <>
                  Please detail how they want to receive care and be supported
                  at end-of-life? [check all that apply]
                </>
              ) : (
                <>
                  How do you envision your care and support at end-of-life?
                  [check all that apply]
                </>
              )}
              <p />
              <div className="panel-subitem">
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "eolCare", e.target.checked, 0)
                  }
                  defaultChecked={planRcpt.c15.formats[0]}
                  disabled={!perms.all_w}
                >
                  {ic ? (
                    <>They want hospice services during their last days</>
                  ) : (
                    <>I want hospice services during my last days</>
                  )}
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "eolCare", e.target.checked, 1)
                  }
                  defaultChecked={planRcpt.c15.eolCare[1]}
                  disabled={!perms.all_w}
                >
                  {ic ? (
                    <>They want to die at home</>
                  ) : (
                    <>I want to die at home</>
                  )}
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "eolCare", e.target.checked, 2)
                  }
                  defaultChecked={planRcpt.c15.eolCare[2]}
                  disabled={!perms.all_w}
                >
                  {ic ? (
                    <>They want to be comfortable and pain free</>
                  ) : (
                    <>I want to be comfortable and pain free</>
                  )}
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "eolCare", e.target.checked, 3)
                  }
                  defaultChecked={planRcpt.c15.eolCare[3]}
                  disabled={!perms.all_w}
                >
                  {ic ? (
                    <>They want to die in the hospital</>
                  ) : (
                    <>I want to die in the hospital</>
                  )}
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "eolCare", e.target.checked, 4)
                  }
                  defaultChecked={planRcpt.c15.eolCare[4]}
                  disabled={!perms.all_w}
                >
                  {ic ? (
                    <>They won't talk about it</>
                  ) : (
                    <>I won't talk about it</>
                  )}
                </Checkbox>
                <p />
                <Checkbox
                  onChange={(e) =>
                    doChange("c15", "eolCare", e.target.checked, 5)
                  }
                  defaultChecked={planRcpt.c15.eolCare[5]}
                  disabled={!perms.all_w}
                >
                  {ic ? (
                    <>
                      I, the caregiver, am making a plan to the best of my
                      ability. There may be some gaps
                    </>
                  ) : (
                    <>
                      I'm making a plan to the best of my ability. There may be
                      some gaps
                    </>
                  )}
                </Checkbox>
                <p />
                {/* Other:
                            <TextArea showCount maxLength={255} onChange={(e) => doChange('c15', 'eolCareOther', e.target.value)} value={planRcpt.c15.eolCareOther} placeholder='Please elaborate' />
                            <p /> */}
                <p />
              </div>
            </div>

            <div className="panel-item">
              <p />
              <div className="panel-item-no">11.)</div>
              {ic ? (
                <>
                  Do they have any wishes or instructions they want their care
                  team members to know about?
                  <p />
                  (Consider their surroundings, level of comfort, music,
                  visitors, rituals etc.)
                </>
              ) : (
                <>
                  Do you have any wishes or instructions you want your care team
                  to know about?
                  <p />
                  (Consider your surroundings, level of comfort, music,
                  visitors, rituals etc.)
                </>
              )}
              <p />
              <div className="panel-subitem">
                <p />
                Explain:
                <p />
                <TextArea
                  showCount
                  maxLength={255}
                  onChange={(e) =>
                    doChange("c15", "surroundingDetails", e.target.value)
                  }
                  value={planRcpt.c15.surroundingDetails}
                  placeholder={"Please elaborate"}
                  disabled={!perms.all_w}
                />
                <p />
              </div>
              <p />
              <p />
            </div>
          </Panel>
        ) : (
          <></>
        )}
        <Panel
          header="After-Life Care and Final Arrangements"
          key="16"
          style={{ fontWeight: "600" }}
          extra={
            <PanelState plan={planRcpt} setPlan={setPlanRcpt} section="c16" />
          }
        >
          <div className="panel-item">
            <p />
            <div className="panel-item-no">1.)</div>
            {ic ? (
              <>What would they like to happen upon their death?</>
            ) : (
              <>What would you like to happen upon your death?</>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) => doChange("c16", "ritual", e.target.checked, 0)}
                defaultChecked={planRcpt.c16.ritual[0]}
                disabled={!perms.all_w}
              >
                {ic ? (
                  <>Follow cultural death ritual</>
                ) : (
                  <>Follow cultural death ritual</>
                )}
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "ritual", e.target.checked, 1)}
                defaultChecked={planRcpt.c16.ritual[1]}
                disabled={!perms.all_w}
              >
                {ic ? (
                  <>Body to be washed and dressed</>
                ) : (
                  <>I wish that my body be washed and dressed</>
                )}
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "ritual", e.target.checked, 2)}
                defaultChecked={planRcpt.c16.ritual[2]}
                disabled={!perms.all_w}
              >
                {ic ? (
                  <>Have their closes family and friends around them</>
                ) : (
                  <>I want my closest family and friends around me</>
                )}
              </Checkbox>
              <p />
              {ic ? <>They want:</> : <>I want:</>}
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c16", "ritualOther", e.target.value)}
                value={planRcpt.c16.ritualOther}
                placeholder="Please elaborate"
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">2.)</div>
            {ic ? (
              <>
                Upon death, do they want any organizations or people to be
                notified?{" "}
              </>
            ) : (
              <>
                Upon death, do you want any organizations or people to be
                notified?
              </>
            )}
            <p />
            <div className="panel-subitem">
              <p />
              List Organizations:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c16", "notifyOrgs", e.target.value)}
                value={planRcpt.c16.notifyOrgs}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              List People:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "notifyPeople", e.target.value)
                }
                value={planRcpt.c16.notifyPeople}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              Instructions for Care Team:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "notifyInstruct", e.target.value)
                }
                value={planRcpt.c16.notifyInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">3.)</div>
            Who is responsible for notifying Federal and State agencies?
            <p />
            <div className="panel-subitem">
              <p />
              USPS: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c16", "personUsps", e.target.value)}
                value={planRcpt.c16.personUsps}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              IRS: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c16", "personIrs", e.target.value)}
                value={planRcpt.c16.personIrs}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              Social Security Administration: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c16", "personSsa", e.target.value)}
                value={planRcpt.c16.personSsa}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              DMV: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c16", "personDmv", e.target.value)}
                value={planRcpt.c16.personDmv}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              Other: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c16", "personOther", e.target.value)}
                value={planRcpt.c16.personOther}
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
            <p />
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">4.)</div>
            {ic ? (
              <>How do you want to notify them?</>
            ) : (
              <>How do you want to notify them?</>
            )}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c16", "delivery", e.target.value)}
                value={planRcpt.c16.delivery}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Pre-prepared letter</Radio>
                </p>
                <p>
                  <Radio value={2}>Obituary</Radio>
                </p>
                <p>
                  <Radio value={3}>Phone call</Radio>
                </p>
                <p>
                  <Radio value={4}>Email</Radio>
                </p>
                <p>
                  <Radio value={5}>Death certificate</Radio>
                </p>
              </Radio.Group>
              <p />
              <p />
              Instructions for Care Team:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "deliveryInstruct", e.target.value)
                }
                value={planRcpt.c16.deliveryInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">5.)</div>
            {ic ? (
              <>Who is designated to complete and file the death certificate?</>
            ) : (
              <>Who is designated to complete and file the death certificate?</>
            )}
            <p />
            <div className="panel-subitem">
              <p />
              Explain:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "personFileCert", e.target.value)
                }
                value={planRcpt.c16.personFileCert}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">6.)</div>
            {ic ? (
              <>
                What type of preparations and final arrangements do they want?
              </>
            ) : (
              <>What type of preparations and final arrangements do you want?</>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 0)}
                defaultChecked={planRcpt.c16.final[0]}
                disabled={!perms.all_w}
              >
                Organ donation
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 1)}
                defaultChecked={planRcpt.c16.final[1]}
                disabled={!perms.all_w}
              >
                Donate body to science
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 2)}
                defaultChecked={planRcpt.c16.final[2]}
                disabled={!perms.all_w}
              >
                Green burial
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 3)}
                defaultChecked={planRcpt.c16.final[3]}
                disabled={!perms.all_w}
              >
                Cremation
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 4)}
                defaultChecked={planRcpt.c16.final[4]}
                disabled={!perms.all_w}
              >
                In ground burial
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 5)}
                defaultChecked={planRcpt.c16.final[5]}
                disabled={!perms.all_w}
              >
                Above ground burial (Mausoleum or Crypt)
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 6)}
                defaultChecked={planRcpt.c16.final[6]}
                disabled={!perms.all_w}
              >
                Alkaline hydrolysis
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) => doChange("c16", "final", e.target.checked, 7)}
                defaultChecked={planRcpt.c16.final[7]}
                disabled={!perms.all_w}
              >
                Human composting
              </Checkbox>
              <p />
              Other:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c16", "finalOther", e.target.value)}
                value={planRcpt.c16.finalOther}
                placeholder="Please elaborate"
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">7.)</div>
            {ic ? (
              <>What type of funeral event do they want?</>
            ) : (
              <>What type of funeral event do you want</>
            )}
            <p />
            <div className="panel-subitem">
              <Checkbox
                onChange={(e) =>
                  doChange("c16", "funeral", e.target.checked, 0)
                }
                defaultChecked={planRcpt.c16.funeral[0]}
                disabled={!perms.all_w}
              >
                Traditional service (religious)
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c16", "funeral", e.target.checked, 1)
                }
                defaultChecked={planRcpt.c16.funeral[1]}
                disabled={!perms.all_w}
              >
                Graveside service
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c16", "funeral", e.target.checked, 2)
                }
                defaultChecked={planRcpt.c16.funeral[2]}
                disabled={!perms.all_w}
              >
                Viewing service
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c16", "funeral", e.target.checked, 3)
                }
                defaultChecked={planRcpt.c16.funeral[3]}
                disabled={!perms.all_w}
              >
                Visitation service
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c16", "funeral", e.target.checked, 4)
                }
                defaultChecked={planRcpt.c16.funeral[4]}
                disabled={!perms.all_w}
              >
                Memorial service /Celebration of life
              </Checkbox>
              <p />
              <Checkbox
                onChange={(e) =>
                  doChange("c16", "funeral", e.target.checked, 5)
                }
                defaultChecked={planRcpt.c16.funeral[5]}
                disabled={!perms.all_w}
              >
                Wake
              </Checkbox>
              <p />
              Alternate Service:
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "funeralOther", e.target.value)
                }
                value={planRcpt.c16.funeralOther}
                placeholder="Please elaborate"
                disabled={!perms.all_w}
              />
              <p />
              <p />
            </div>
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">8.)</div>
            {ic ? (
              <>Do they want an Obituary written?</>
            ) : (
              <>Do you want an Obituary?</>
            )}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c16", "obituary", e.target.value)}
                value={planRcpt.c16.obituary}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Yes</Radio>
                </p>
                <p>
                  <Radio value={2}>No</Radio>
                </p>
              </Radio.Group>
              <p />
              <p />
              Instructions for Care Team:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "obituaryInstruct", e.target.value)
                }
                value={planRcpt.c16.obituaryInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">9.)</div>
            {ic ? (
              <>
                If they‘ve chosen to be buried or cremated, please leave
                instructions here or upload and pre-planning documents.
              </>
            ) : (
              <>
                If you’ve selected to be buried or cremated, please leave
                instructions here or upload documents
              </>
            )}
            <p />
            <div className="panel-subitem">
              <p />
              Name of vendor (Crematory and/or funeral home): <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c16", "vendorName", e.target.value)}
                value={planRcpt.c16.vendorName}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              Address: <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) =>
                  doChange("c16", "vendorAddress", e.target.value)
                }
                value={planRcpt.c16.vendorAddress}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              Phone number:
              <p />{" "}
              <Input
                maxLength={100}
                onChange={(e) => doChange("c16", "vendorPhone", e.target.value)}
                value={planRcpt.c16.vendorPhone}
                disabled={!perms.all_w}
              />
              <p />
              <p />
              Instructions:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "vendorInstruct", e.target.value)
                }
                value={planRcpt.c16.vendorInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">10.)</div>
            {ic ? (
              <>Instructions for the care team about payment of services</>
            ) : (
              <>
                Leave instructions for your care team about payment of services
              </>
            )}
            <p />
            <div className="panel-subitem">
              <p />
              Instructions:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "paymentInstruct", e.target.value)
                }
                value={planRcpt.c16.paymentInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">11.)</div>
            {ic ? (
              <>How do they want their personal belongings to be disposed of?</>
            ) : (
              <>How do you want your personal belongings to be disposed of?</>
            )}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c16", "dispose", e.target.value)}
                value={planRcpt.c16.dispose}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Sell everything</Radio>
                </p>
                <p>
                  <Radio value={2}>
                    {ic ? (
                      <>They’ve outlined what goes to who in my will</>
                    ) : (
                      <>I've outlined what goes to who in my will</>
                    )}
                  </Radio>
                </p>
                <p>
                  <Radio value={3}>
                    {ic ? (
                      <>They want family and friends to take what they want</>
                    ) : (
                      <>I want my family and friends to take what they want</>
                    )}
                  </Radio>
                </p>
              </Radio.Group>
              <p />
              <p />
              Instructions for Care Team:
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) =>
                  doChange("c16", "disposeInstruct", e.target.value)
                }
                value={planRcpt.c16.disposeInstruct}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <p />
            <div className="panel-item-no">12.)</div>
            {ic ? (
              <>
                How do they want their social media and digital accounts to be
                managed?
              </>
            ) : (
              <>
                How do you want your social media and digital accounts to be
                managed?
              </>
            )}
            <p />
            <div className="panel-subitem">
              <Radio.Group
                size="large"
                onChange={(e) => doChange("c16", "socmed", e.target.value)}
                value={planRcpt.c16.socmed}
                disabled={!perms.all_w}
              >
                <p>
                  <Radio value={0} style={{ display: "none" }}>
                    None
                  </Radio>
                </p>
                <p>
                  <Radio value={1}>Save it all</Radio>
                </p>
                <p>
                  <Radio value={2}>Delete it all</Radio>
                </p>
                <p>
                  <Radio value={3}>
                    Transfer account ownership to be maintained by someone else
                  </Radio>
                </p>
                <p>
                  <Radio value={4}>
                    {ic ? <>They don't care</> : <>I don't care</>}
                  </Radio>
                </p>
                <p>
                  <Radio value={5}>
                    {ic ? (
                      <>They do not want anyone to have access</>
                    ) : (
                      <>I do not want anyone to have access</>
                    )}
                  </Radio>
                </p>
              </Radio.Group>
              <p />
              <p />
              {ic ? (
                <>They have designated someone to manage online accounts:</>
              ) : (
                <>I have designated someone to manage online accounts:</>
              )}
              <p />
              <TextArea
                showCount
                maxLength={255}
                onChange={(e) => doChange("c16", "socmedOther", e.target.value)}
                value={planRcpt.c16.socmedOther}
                placeholder={"Please elaborate"}
                disabled={!perms.all_w}
              />
              <p />
            </div>
            <p />
          </div>

          <div className="panel-item">
            <h4>
              Note: There are many logistical details involved in after-life
              care and final arrangements. If you have any questions or would
              like to work with an expert to make plans,{" "}
              <span
                className="text-[#1890ff] cursor-pointer hover:text-[#40a9ff]"
                onClick={() => {
                  setFormSetting({
                    title: "Contact MyCareLinq",
                    destination: FormDestination.QUESTIONS,
                    fromQuestion: "AfterLifePlan",
                    isModalVisible: true,
                    showFirstName: false,
                    showLastName: false,
                    showEmail: false,
                    showPhone: false,
                  });
                }}
              >
                contact MyCareLinq
              </span>
            </h4>
          </div>
        </Panel>
        <Panel
          header="My Care Team Summary"
          key="17"
          style={{ fontWeight: "600" }}
        >
          <div className="panel-item">
            <h4>
              If you like to invite others to manage this care plan, you may
              invite someone else to the{" "}
              <Button type="link" onClick={() => setPage("3")}>
                Care Circle
              </Button>
            </h4>
          </div>
        </Panel>
      </Collapse>
      {/* <Row>
        <h6
          style={{
            backgroundColor: "rgba(48, 34, 95, 70%)",
            textAlign: "center",
            position: "fixed",
            left: "0",
            bottom: "0",
            height: "1rem",
            width: "100%",
            color: "white",
          }}
        >
          Copyright &copy; 2021, 2022{" "}
          <a href="https://mycarelinq.com" style={{ color: "#e0551b" }}>
            My CareLinq, Inc
          </a>
          . All Rights Reserved
        </h6>
      </Row> */}
    </>
  );
};

export default GiverPlan;
