import React from "react";
import _ from "lodash";
import moment from "moment";
import ChatAvatarIcon from "./ChatAvatarIcon";

const ChatListItem = ({
  chatId,
  name,
  Icon,
  lastMessageText,
  messageTime,
  selected = false,
  isNew = false,
  setSelected = () => {},
}) => {
  let cssStateProperties = {
    selectedBackgroundColor: "bg-new-primary-purple/5",
    selectedIconBackground:
      "linear-gradient(146.51deg, #6D56BE 12.17%, #3C3066 100.04%)",
    selectedIconColor: "#EBEBEB",
    nameTextColor: "#0C0720",
    nameFontWeight: "700",
    newMessageFontWeight: "700",
  };

  let dateFormatter = (datetime) => {
    const messageSentTime = moment(datetime);
    const now = moment();

    const yearDiff = now.diff(messageSentTime, "years");
    const monthDiff = now.diff(messageSentTime, "months");
    const weekDiff = now.diff(messageSentTime, "weeks");
    const dayDiff = now.diff(messageSentTime, "days");

    if (yearDiff > 0) {
      return `${yearDiff}y`;
    } else if (monthDiff > 0) {
      return `${monthDiff}m`;
    } else if (weekDiff > 0) {
      return `${weekDiff}w`;
    } else if (dayDiff > 0) {
      return `${dayDiff}d`;
    } else {
      return moment(messageTime).format("hh:mm A");
    }
  };

  return (
    <div
      className={`
        flex
        flex-row
        items-center
        py-0
        pr-4
        pl-0
        w-full
        h-[56px]
        rounded-lg
        hover:bg-new-primary-purple/5
        ${selected && cssStateProperties.selectedBackgroundColor}
        `}
      onClick={setSelected}
    >
      {/* Title */}
      <div
        className="
              flex
              flex-row
              grow
              items-center
              py-2
              pr-0
              pl-2
              gap-[15px]
              w-full
          "
      >
        {/* Icon */}
        <div className="grow-0 shrink-0 basis-[32px]">
          {!_.isNil(Icon) && (
            <ChatAvatarIcon
              size={32}
              Icon={Icon}
              iconStyle={{
                color: selected
                  ? cssStateProperties.selectedIconColor
                  : "rgba(60, 48, 102, 0.6)",
              }}
              avatarStyle={{
                background: selected
                  ? cssStateProperties.selectedIconBackground
                  : "#EBEBEB",
                border: "1px solid rgba(60, 48, 102, 0.6)",
              }}
            />
          )}
        </div>
        {/* Info */}
        <div
          className="
            flex
            grow
            shrink
            basis-auto
            min-w-[0]
            items-center
          "
        >
          <div
            className="
              flex
              grow
              flex-col
              items-start
              p-0
              basis-auto
              min-w-[0]
            "
          >
            <div
              className="
                  flex
                  p-0
                  leading-5
                  mb-0
                  items-start
                  text-sm
                  w-full
              "
            >
              <span
                className="truncate
              "
                style={{
                  fontWeight:
                    selected || isNew
                      ? cssStateProperties.nameFontWeight
                      : "400",
                  color: selected
                    ? cssStateProperties.nameTextColor
                    : "rgba(12, 7, 32, 0.8)",
                }}
              >
                {name}
              </span>
            </div>
            {/* Message and Time */}
            <div
              className="
                  flex
                  p-0
                  gap-2
                  leading-5
                  mb-0
                  items-start
                  text-xs
                  text-text-color
                  w-full
              "
            >
              <span
                className="truncate"
                style={{
                  fontWeight: isNew
                    ? cssStateProperties.newMessageFontWeight
                    : "400",
                }}
              >
                {!_.isNil(lastMessageText) ? lastMessageText : ""}
              </span>
              <span
                className="whitespace-nowrap"
                style={{
                  fontWeight: "400",
                }}
              >
                {!_.isNil(messageTime) ? dateFormatter(messageTime) : ""}
              </span>
            </div>
          </div>
          {isNew && (
            <div
              className="
                ml-4
                shrink-0
                w-[10px]
                h-[10px]
                bg-[#6DCDEE]
                rounded-lg
              "
            ></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ChatListItem;
