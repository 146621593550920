import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

import {Helmet} from "react-helmet"; 

import { Breadcrumb, Col, Row, Space, Empty } from "antd";
import { Navbar } from "../../components/Navbar";
import { PurpleDivider } from "../../components/Various";
import ArticleWidget from "../../components/ArticleWidget";
import ArticleCategories from "../../components/ArticleCategories";
import ArticleDetail from "../../components/ArticleDetail";
import Author from "../../components/Author";
import AdjacentLibraryResource from "../../components/AdjacentLibraryResource";
import { DEMO_ARTICLES, Url } from "../../constants/Global";
import Footer from "../../components/Footer";
import { mclOpen } from "../../api/Mcl";
import _ from "lodash";

export const Article = () => {

    const navigate = useNavigate();
    let { url } = useParams();
    const [articleContent, setArticleContent] = useState({});
    const [adjacentArticle, setAdjacentArticles] = useState({});
    const [notFoundError, setNotFoundError] = useState(false);

    const convertContentToString = (contentArray) => {
        // Do two space followed by \n to break line in markdown
        return contentArray.join("  \n");
    }

    const setAdjacent = (prev, next) => {
        let adjacents = {};
        // Set prev
        if (prev && prev.length > 0) {
            adjacents.prev = prev[0];
        }
        // Set next
        if (next && next.length > 0) {
            adjacents.next = next[0];
        }
        setAdjacentArticles(adjacents);
    }

    const getArticle = async () => {
        mclOpen.get(Url.ARTICLES.concat('/content/' + url))
            .then((res) => {
                setNotFoundError(false)
                let data = res.data[0];
                data.content = convertContentToString(data.content);
                setAdjacent(data.prev, data.next);
                setArticleContent(_.omit(data, ["prev", "next"]));
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status == 404) {
                    setNotFoundError(true)
                }
            });
    }

    useEffect(() => {
        getArticle();
    }, [url])

    return (
        <main>
            <Helmet>
                <meta charSet="utf-8" />
                {/* <title>My CareLinq - Caregiver Resources</title> */}
                <meta name="description" content="My CareLinq caregiver resources." />
                <link rel="canonical" href="https://mycarelinq.com/article" />
            </Helmet>
            <Navbar signin={true} navigate={navigate} />
            <PurpleDivider />
            <Row className="px-3">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>Library</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/library/article">Caregiver Resources</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{!_.isEmpty(articleContent) ? articleContent.title : "Error"}</Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <div className="mt-8 lg:container lg:mx-auto flex flex-col px-4 justify-center whitespace-normal">
                {/* <LibraryFeaturedSection /> */}
                <Row className="mt-8 mb-32" gutter={[48, 24]}>
                    <Col xs={24} lg={16}>
                        {notFoundError ?
                            <Empty
                                className="mt-16"
                                description={"Articles not found"}
                            >
                            </Empty>
                            :
                            <>
                                <ArticleDetail
                                    article={
                                        articleContent
                                    }
                                />
                                {/* <Author author={articleContent.author} /> */}
                                <AdjacentLibraryResource adjacentLibraryResource={adjacentArticle} type="article" />
                            </>
                        }
                    </Col>
                    <Col xs={24} lg={8}>
                        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                            <ArticleWidget
                                categories={articleContent.categories?.map((element) => element._id)}
                                tags={articleContent.tags}
                            />
                            <ArticleCategories />
                        </Space>
                    </Col>
                </Row>
            </div>
            <Footer />
        </main>
    )
}