import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import { Breadcrumb, Col, Row, Space, Empty } from "antd";
import { Navbar } from "../../components/Navbar";
import { PurpleDivider } from "../../components/Various";
import AdjacentLibraryResource from "../../components/AdjacentLibraryResource";
import { DEMO_ARTICLES, Url } from "../../constants/Global";
import Footer from "../../components/Footer";
import { mclOpen } from "../../api/Mcl";
import _ from "lodash";
import VideoDetail from "../../components/VideoDetail";

export const Video = () => {

    const navigate = useNavigate();
    let { url } = useParams();
    const [videoContent, setVideoContent] = useState({});
    const [adjacentVideo, setAdjacentVideos] = useState({});
    const [notFoundError, setNotFoundError] = useState(false);

    const convertContentToString = (contentArray) => {
        // Do two space followed by \n to break line in markdown
        return contentArray.join("  \n");
    }

    const setAdjacent = (prev, next) => {
        let adjacents = {};
        // Set prev
        if (prev && prev.length > 0) {
            adjacents.prev = prev[0];
        }
        // Set next
        if (next && next.length > 0) {
            adjacents.next = next[0];
        }
        setAdjacentVideos(adjacents);
    }

    const getVideo = async () => {
        mclOpen.get(Url.VIDEOS.concat('/content/' + url))
            .then((res) => {
                setNotFoundError(false)
                let data = res.data[0];
                data.content = convertContentToString(data.content);
                setAdjacent(data.prev, data.next);
                setVideoContent(_.omit(data, ["prev", "next"]));
            })
            .catch((error) => {
                console.log(error)
                if (error.response.status == 404) {
                    setNotFoundError(true)
                }
            });
    }

    useEffect(() => {
        getVideo();
    }, [url])

    return (
        <main>
            <Navbar signin={true} navigate={navigate} />
            <PurpleDivider />
            <Row className="px-3">
                <Breadcrumb separator=">">
                    <Breadcrumb.Item>Library</Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/library/video"> Video</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>{!_.isEmpty(videoContent) ? videoContent.title : "Error"}</Breadcrumb.Item>
                </Breadcrumb>
            </Row>
            <div className="mt-8 lg:container lg:mx-auto flex flex-col px-4 justify-center whitespace-normal">
                <Row className="mt-8 mb-32" gutter={[48, 24]}>
                    <Col xs={24}>
                        {notFoundError ?
                            <Empty
                                className="mt-16"
                                description={"Articles not found"}
                            >
                            </Empty>
                            :
                            <>
                                <VideoDetail
                                    video={
                                        videoContent
                                    }
                                />
                                {/* <Author author={articleContent.author} /> */}
                                <AdjacentLibraryResource adjacentLibraryResource={adjacentVideo} type="video" />
                            </>
                        }
                    </Col>
                </Row>
            </div>
            <Footer />
        </main >
    )
}