import React, { useState, useEffect } from "react";
import { Col, Row, Space, Typography, Divider, Image } from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import { mclOpen } from "../api/Mcl";
import _ from "lodash";
import {
  ARTICLE_ROUTE,
  DEMO_ARTICLES,
  Url,
  AUTHOR_IMAGE_PLACEHOLDER,
} from "../constants/Global";

const ArticleWidget = ({ categories, tags }) => {
  const [recommendedArticles, setRecommendedArticles] = useState([]);

  const getSimilarArticles = (categories, tags) => {
    let queryParameters = "";
    if (_.isArray(categories)) {
      for (let cat in categories) {
        if (!_.isNil(categories[cat])) {
          queryParameters += `&cat=${categories[cat]}`;
        }
      }
    }
    if (_.isArray(tags)) {
      for (let tag in tags) {
        if (!_.isNil(tags[tag])) {
          queryParameters += `&tag=${tags[tag]}`;
        }
      }
    }
    mclOpen
      .get(Url.ARTICLES.concat("/get-similar?li=1" + queryParameters))
      .then((res) => {
        setRecommendedArticles(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRecentArticles = async () => {
    mclOpen
      .get(Url.ARTICLES.concat("/?li=1"))
      .then((res) => {
        setRecommendedArticles(_.isObject(res.data) ? res.data.articles : []);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (_.isArray(categories) || _.isArray(tags)) {
      getSimilarArticles(categories, tags);
    } else {
      getRecentArticles();
    }
  }, [categories, tags]);

  return (
    <div className="bg-white shadow-lg shadow-black/40 rounded-lg p-8 pb-12 mb-8">
      <h3 className="text-2xl font-bold text-primary-purple mb-8 pb-4 border-solid border-b border-x-0 border-t-0 border-slate-300">
        {_.isArray(categories) || _.isArray(tags)
          ? "Related Articles"
          : "Recent Articles"}
      </h3>
      {/* <hr className="border-b-[1px] border-solid border-slate-300" /> */}
      {recommendedArticles.map((article, index) => (
        <div key={index} className="flex items-center w-full mb-8">
          <div className="w-14 flex-none">
            <Image
              preview={false}
              alt={""}
              height={32}
              width={32}
              className="align-middle rounded-full"
              src={
                _.isNil(article.author) || _.isEmpty(article.author.photo)
                  ? AUTHOR_IMAGE_PLACEHOLDER
                  : article.author.photo
              }
            />
          </div>
          <div className="flex-grow ml-4">
            <p className="text-gray-500 text-[0.75rem] lg:text-[0.7rem] m-0">
              {moment(article.date).format("MMM DD, YYYY")}
            </p>
            <Link
              to={`${ARTICLE_ROUTE}${article.url}`}
              className="text-[1rem] lg:text-[0.8rem] text-primary-purple"
              key={index}
            >
              {article.title}
            </Link>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ArticleWidget;
