import axios from 'axios'

import { Url } from '../constants/Global'


export const mclOpen = axios.create({
    baseURL: Url.BASE
})

export const mclAuth = axios.create({
    baseURL: Url.BASE,
    headers: { 'Content-Type': 'application/json' },
    withCredentials: true
});

