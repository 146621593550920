import React from "react";
import {
  Col,
  Row,
  Form,
  Checkbox,
  Button,
  Input,
  InputNumber,
  Modal,
  Spin,
} from "antd";
import { FundProjectionScreenOutlined } from "@ant-design/icons";
import "./DemoRequestForm.css";
import GAText from "./GAText";

const DemoRequestForm = ({
  isSubmitting = false,
  submitForm = () => {},
  form = undefined,
}) => {
  const onFinish = (values) => {
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Spin spinning={isSubmitting}>
      <Form
        className="demoRequestForm"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <Row className="mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  First Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your first name" },
              ]}
              name="firstName"
            >
              <Input type="text" placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Last Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please enter your last name" },
              ]}
              name="lastName"
            >
              <Input type="text" placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Email
                </label>
              }
              required
              rules={[
                {
                  type: "email",
                  message: "Please enter a valid email address",
                },
                {
                  required: true,
                  message: "Enter your email",
                },
              ]}
              name="email"
            >
              <Input type="email" placeholder="user@example.com" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Phone (+1)
                </label>
              }
              required
              rules={[
                {
                  pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                  message: "Please check the format of phone number",
                },
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
              name="phone"
            >
              <Input type="text" placeholder="555-555-5555" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          label={
            <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
              Message <br />
              (Please let us know when we can contact you)
            </label>
          }
          rules={[
            { max: 300, message: "Maximum message length is 300 characters." },
          ]}
          name="message"
          className="mb-3"
        >
          <Input.TextArea rows={5} showCount maxLength={300} />
        </Form.Item>
        <GAText></GAText>
      </Form>
    </Spin>
  );
};

const DemoRequestModal = ({
  setting = {
    title: "Request a Product Demo",
    isModalVisible: false,
    calendlyLink: "",
  },
  isSubmitting = false,
  handleCancel = () => {},
  submitForm = (values) => {
    console.log(values);
  },
  form = undefined,
}) => {
  return (
    <Modal
      title={setting.title}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      width={"80%"}
      onOk={form && form.submit}
      footer={[
        <Button
          key="back"
          shape="round"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          icon={<FundProjectionScreenOutlined />}
          // style={{
          //   borderColor: "#8159f5",
          //   color: "#8159f5",
          // }}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={form && form.submit}
        >
          Request Demo
        </Button>,
      ]}
    >
      <DemoRequestForm
        submitForm={submitForm}
        isSubmitting={isSubmitting}
        form={form}
      />
    </Modal>
  );
};

export { DemoRequestModal, DemoRequestForm };
