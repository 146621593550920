// import logo from './logo.svg';
import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import ReactGA from "react-ga4";
import "antd/dist/antd.css";
import "./App.css";

// import { Register /* , Register1stQ */ } from './pages/survey/Register'
import { SurveyRoutes } from "./pages/survey/Survey";

import { AuthProvider } from "./context/AuthProvider";
import { AnswerProvider } from "./context/AnswerProvider";

import { Home } from "./pages/site/Home";
import { Homev2 } from "./pages/site/Homev2";
// import { AboutUs } from "./pages/site/AboutUs";
import { HowWeCanHelp } from "./pages/site/HowWeCanHelp";
import { Login } from "./pages/site/Login";
//import { Caregiver } from "./pages/caregiver/Dashboard";
import { Caregiver } from "./pages/caregiver/MyHome";
import { PrivacyPolicyPage } from "./pages/site/PrivacyPolicyPage";
import { TermsOfUsePage } from "./pages/site/TermsOfUsePage";
import { ArticleLibrary } from "./pages/site/ArticleLibrary";
import { VideoLibrary } from "./pages/site/VideoLibrary";
import { Article } from "./pages/site/Article";
import { Video } from "./pages/site/Video";
import { Download } from "./pages/site/Download";
import Concierge from "./pages/concierge/Concierge";
import Supercierge from "./pages/supercierge/Supercierge";
import Super from "./pages/super/Super";
import Pricing from "./pages/pricing/Pricing";
import Pricingv2 from "./pages/pricing/Pricingv2";
import GiftingForm from "./pages/pricing/Gifting";
import SuccessPayment from "./pages/pricing/Success";
import GiftSuccessPayment from "./pages/pricing/GiftSuccess";
import CancelPayment from "./pages/pricing/Cancel";
import FAQ from "./pages/site/Faq";
import Team from "./pages/site/Team";
import Verify from "./pages/site/Verify";
import ForgotPassword from "./pages/site/ForgotPassword";
import ResetPassword from "./pages/site/ResetPassword";
import ResetPasswordConfirm from "./pages/site/ResetPasswordConfirm";
import Invitation from "./pages/site/Invitation";
import InvitationUserInfo from "./pages/site/InvitationUserInfo";
// import Referral from './pages/referral/Referral';
import Missing from "./pages/site/Missing";
import Unauthorized from "./pages/site/Unauthorized";
import AuthCheck from "./components/AuthCheck";
import { Roles } from "./constants/Global";
import { SA } from "./components/SA";
import { Enterprise } from "./pages/site/Enterprise";
import {Product } from "./pages/site/Product";
import { AboutUs } from "./pages/site/AboutUsv2";

import { CalendlyRoutes } from "./pages/site/Calendly";

import useAuth from "./hooks/useAuth";

import { AUTH_KEY, AUTH_KEY_TTL } from "./constants/Global";  

const isTest =
  !process.env.REACT_APP_TEST || process.env.REACT_APP_TEST === "yes";

function LoadAuth() {
  const { auth, setAuth } = useAuth();
  const [ redir, setRedir ] = useState(false)
  const [ redirTo, setRedirTo ] = useState('')
  const allowedPaths = ['/caregiver/dashboard', 
                        '/concierge/dashboard', 
                        '/supercierge/dashboard', 
                        '/super/dashboard']
  
  let location = useLocation();

  useEffect(() => {
    // console.log('*** useEffect app -> auth called on ', location.pathname);
    // console.log('*** current auth =', auth);

    if (redir) {setRedir(false)};
    
    if (auth && Object.keys(auth).length === 0 ) {
      // console.log('*** location.pathname=', location.pathname);
      if (allowedPaths.includes(location.pathname)) {
      // if (location.pathname === "/caregiver/dashboard" ) {
        const authState = localStorage.getItem(AUTH_KEY);
        // console.log('*** authState=', JSON.stringify(authState));
        if (authState) {
            // console.log('*** restoring auth!!!')
            const user = JSON.parse(authState);
            // console.log('*** user=', JSON.stringify(user));
            const now = new Date();
            let expired = (now.getTime() > user?.expiry)
            if (expired) {
              localStorage.removeItem(AUTH_KEY)
            } else {
              // const user = {authState.id, };
              const authCurr = {'id':user.id, 'email':user?.email, 'password':user?.password, 
                  'care':user.care, 'roles':user.roles, 'accessToken':user.accessToken, 'b2b':user?.b2b}
              setAuth(authCurr);
              // console.log('*** restored auth =', authCurr);
              setRedir(true);
              setRedirTo(location.pathname);
            }
        }   
      }
    }

  // }, [auth]);
  }, [auth]);

  return (
    (redir ? <Navigate to={redirTo} replace /> : <></>)
  )
}

function App() {
  const { auth, setAuth } = useAuth();

  let location = useLocation();

  useEffect(() => {
    ReactGA.initialize(isTest ? "G-J4GHVGT64H" : "G-VMGVVL4W8N");
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname + location.search,
    });
  }, [location]);

//   useEffect(() => {
//     console.log('*** useEffect app -> auth called!!!')
//     if (!auth || Object.keys(auth).length === 0) {
//         const authState = localStorage.getItem("user");
//         console.log('*** authState=', JSON.stringify(authState));
//         if (authState) {
//             console.log('*** restoring auth!!!')
//             const user = JSON.parse(authState);
//             console.log('*** user=', JSON.stringify(user));
//             // const user = {authState.id, };
//             const authCurr = {'id':user.id, 'email':user?.email, 'password':user?.password, 
//                 'care':user.care, 'roles':user.roles, 'accessToken':user.accessToken}
//             setAuth(authCurr);
//         }   
//     }
// }, [auth]);

  // useEffect(() => {
  //   ReactGA.initialize(isTest ? "G-J4GHVGT64H" : "G-VMGVVL4W8N");
  //   ReactGA.send({
  //     hitType: "pageview",
  //     page: window.location.pathname + window.location.search,
  //   });
  // }, [window.location]);

  return (
    <AuthProvider>
      <LoadAuth />
      <Routes>
        <Route exact path="/" element={<Homev2 />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/how-we-can-help" element={<HowWeCanHelp />} />
        {/* <Route exact path="/pricing" element={<Pricing />} /> */}ß
        {/* <Route exact path="/pricing" element={<Pricingv2 />} /> */}
        <Route exact path="/pricing" element={<Product />} />
        {/* <Route exact path="/team" element={<Team />} /> */}
        <Route exact path="/team" element={<AboutUs />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/gifting" element={<GiftingForm />} />
        {/* <Route exact path="/enterprise" element={<Enterprise />} /> */}
        <Route exact path="/enterprise" element={<Product />} />
        <Route exact path="/product" element={<Product />} />
        <Route exact path="/product-consumer" element={<Home />} />
        <Route path="/library/article/:category" element={<ArticleLibrary />} />
        <Route path="/library/article" element={<ArticleLibrary />} />
        <Route path="/library/video/:category" element={<VideoLibrary />} />
        <Route path="/library/video" element={<VideoLibrary />} />
        <Route path="/video/:url" element={<Video />} />
        <Route path="/article/:url" element={<Article />} />
        <Route path="/download/:type/:url" element={<Download />} />
        <Route path="/calendly/*" element={<CalendlyRoutes />} />
        <Route
          exact
          path="/success/:CHECKOUT_SESSION_ID"
          element={<SuccessPayment />}
        />
        <Route
          exact
          path="/success/1/:CHECKOUT_SESSION_ID/:GIFT_ID"
          element={<GiftSuccessPayment />}
        />
        <Route exact path="/cancel" element={<CancelPayment />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route exact path="/terms-of-use" element={<TermsOfUsePage />} />
        <Route exact path="/subscription-agreement" element={<SA />} />
        <Route exact path="/site/login/" element={<Login />} />
        <Route exact path="/site/login/:INVITATION_ID" element={<Login />} />
        <Route exact path="/site/verify/:VALIDATION_ID" element={<Verify />} />
        <Route exact path="/site/forgot-password/" element={<ForgotPassword />} />
        <Route exact path="/site/reset-password/:RESET_TOKEN" element={<ResetPassword />} />
        <Route exact path="/site/reset-password-confirm/" element={<ResetPasswordConfirm />} />
        <Route exact path="/site/invitation/:INVITATION_ID" element={<Invitation />} />
        <Route exact path="/site/invitation-userinfo/:USER_ID" element={<InvitationUserInfo />} />
        <Route element={<AuthCheck allowedRoles={[Roles.CAREGIVER]} />}>
          <Route exact path="/caregiver/dashboard/*" element={<Caregiver />} />
        </Route>
        <Route path="/survey/*" element={<SurveyRoutes />} />
        <Route path="/survey/1/:TEMP_USER_ID" element={<SurveyRoutes />} />
        <Route element={<AuthCheck allowedRoles={[Roles.CONCIERGE]} />}>
          <Route path="/concierge/dashboard/*" element={<Concierge />} />
        </Route>
        <Route element={<AuthCheck allowedRoles={[Roles.SUPER_CONCIERGE]} />}>
          <Route path="/supercierge/dashboard/*" element={<Supercierge />} />
        </Route>
        <Route element={<AuthCheck allowedRoles={[Roles.SUPER_CONCIERGE]} />}>
          <Route path="/super/dashboard/*" element={<Super />} />
        </Route>
        <Route exact path="/site/unauthorized" element={<Unauthorized />} />
        {/* <Route path='/referral/:id' element={<Referral />} /> */}
        <Route path="*" element={<Missing />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
