import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  //Result,
} from "antd";

import { mclOpen } from "../../api/Mcl";
import { Url } from "../../constants/Global";
import { useParams, useNavigate } from "react-router";
import { useEffect, useState, useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";


const openNotification = ({ message, status, title, subTitle, duration }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    duration: duration,
    description: (
      /*<Result
        status={status}
        title={title}
        subTitle={subTitle}
      />*/
      <span>
        <p>{status}</p>
        <p>{title}</p>
        <p>{subTitle}</p>
      </span>
    ),
  });
};

const ResetPassword = () => {
  const { RESET_TOKEN } = useParams();
  const navigate = useNavigate();
  const [ showPanel, setShowPanel ] = useState(false);
  const [ showResult, setShowResult ] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
    }

    const token = await executeRecaptcha('resetPassword');
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  useEffect(() => {
    (async () => {
      try {
        let recaptchaToken = await handleReCaptchaVerify();
        const response = await mclOpen.post(
          Url.RESET_PASSWORD,
          JSON.stringify({
            token: RESET_TOKEN
          }),
          {
            headers: { "Content-Type": "application/json" },
            params: { token: recaptchaToken },
            withCredentials: true,
          });
        setShowPanel(true);
        //navigate("/home");
      }
      catch (err){
        if (!err?.response) {
          openNotification({
            message: "No Server Response",
            status: "500",
            title: "500",
            subTitle: "",
            duration: 0
          });
        }
        else if (err.response?.status === 400 && err.response?.data === "Invaild Token"){
          openNotification({
            message: "Invalid Reset Token",
            status: "400",
            title: "Invalid Reset Token",
            subTitle: "",
            duration: 0
          });
        }
        else if (err.response?.status === 400 && err.response?.data === "Invaild Password"){
          openNotification({
            message: "Invalid Password",
            status: "400",
            title: "Invalid Password",
            subTitle: "",
            duration: 0
          });
        }
        else {
          openNotification({
            message: "Something went Wrong",
            status: "500",
            title: "Something went Wrong",
            subTitle: "",
            duration: 0
          });
        }
      }
    })();
  }, [RESET_TOKEN, handleReCaptchaVerify]);

  const onFinish = async (values) => {
    try {
      let recaptchaToken = await handleReCaptchaVerify();
      const response = await mclOpen.post(
        Url.RESET_PASSWORD,
        JSON.stringify({
          token: RESET_TOKEN,
          password: values.password
        }),
        {
          headers: { "Content-Type": "application/json" },
          params: { token: recaptchaToken },
          withCredentials: true,
        });
      setShowPanel(false);
      setShowResult(true);
      //navigate("/home");
    }
    catch (err){
      if (!err?.response) {
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
          duration: 0
        });
      }
      else if (err.response?.status === 400){
        if(err.response?.data === "Invaild Token") {
          openNotification({
            message: "Invalid Reset Token",
            status: "400",
            title: "Invalid Reset Token",
            subTitle: "",
          });
        }
      }
      else {
        openNotification({
          message: "Something went Wrong",
          status: "500",
          title: "Something went Wrong",
          subTitle: "",
          duration: 0
        });
      }
    }
    };
  
  return(
    <Form
      name="basic"
      labelCol={{
        span: 24,
      }}
      wrapperCol={{
        span: 24,
      }}
      colon={false}
      onFinish={onFinish}
      autoComplete="off"
    >
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          minHeight: "100vh",
          display: showResult? "": "none"
        }}
      >
        <h1>Your password has been reset. You may now log in with your new password</h1>
        <Button type="primary" shape="round" href={"/site/login"}>
          Log In
        </Button>
      </Row>
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{
          minHeight: "100vh",
          display: showPanel? "": "none"
        }}
      >
        <Col
        span={8}
        >
          <h3>Reset Password</h3>
          <Form.Item
            name="password"
            label="Password"
            rules={[
              {
                required: true,
                message: "Must be at least 10 charaters long!",
                min: 10,
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }

                  return Promise.reject(
                    new Error("The two passwords that you entered do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password />
          </Form.Item>
          <Form.Item>
            <Button type="primary" shape="round" htmlType="submit">
              Reset
            </Button>
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
}

export default ResetPassword;