import React from "react";
import { Col, Row } from "antd";

import "./SA.css";
import { PurpleDivider } from "./Various";

const SAContent = ({ className = "", style }) => {
  const containerBaseClass =
    "lg:container lg:mx-auto p-5 text-center sa-content";
  const containerClass = className
    ? containerBaseClass + " " + className
    : containerBaseClass;
  const importantNoticeStyle = {
    fontSize: "large",
    fontWeight: "bold",
  };

  return (
    <div style={style} className={containerClass}>
      <h3 className="text-h3">MYCARELINQ, INC.</h3>
      <h3 className="text-h3">SUBSCRIPTION AGREEMENT</h3>
      <p>
        This Subscription Agreement (this “<strong>Agreement</strong>”) sets
        forth the terms and conditions under which you (“
        <strong>Customer</strong>”) may subscribe to and use the paid Services
        (defined below) of MyCareLinq, Inc. (“<strong>MyCareLinq</strong>”).
        This Agreement supplements the Terms of Use (available at:{" "}
        <a href="./terms-of-use">https://mycarelinq.com/terms-of-use</a>) (the “
        <strong>General Terms</strong>”), which are hereby incorporated by
        reference.
      </p>
      <p>
        Please read this Agreement (including the General Terms) before
        subscribing to the Services. By indicating acceptance of this Agreement
        via click through, electronic signature or other electronic means
        offered by MyCareLinq or by otherwise using the Services, you are
        entering into a legal contract with MyCareLinq, Inc. and Customer hereby
        accepts the terms of and agree to this Agreement. Any individual
        accepting this Agreement on behalf of a Customer which is an
        organization or other entity represents and warrants that he or she has
        the authority to bind Customer to this Agreement. If Customer does not
        agree with all of the terms and conditions set forth in this Agreement,
        Customer is not permitted to use the Services. Notwithstanding the
        foregoing, if Customer has entered into a separate, written negotiated
        version of this Agreement, the terms and conditions of such separate
        Agreement will control.
      </p>
      <p style={importantNoticeStyle}>
        IMPORTANT NOTICE: THE GENERAL TERMS REFERENCED ABOVE, WHICH ARE
        INCORPORATED BY REFERENCE HEREIN, INCLUDE AN ARBITRATION PROVISION
        REQUIRING BINDING ARBITRATION OF DISPUTES AND A WAIVER OF CERTAIN RIGHTS
        TO JURY TRIALS AND/OR CLASS ACTIONS. IF CUSTOMER DOESN’T WANT TO BE
        SUBJECT TO ARBITRATION, CUSTOMER CAN OPT OUT OF THE ARBITRATION
        PROVISION BY FOLLOWING THE INSTRUCTIONS PROVIDED AT THE END OF THE
        ARBITRATION PROVISION IN THE GENERAL TERMS.
      </p>
      <ol>
        <li>
          <u>
            <strong>Definitions</strong>
          </u>
          . The following definitions will apply in this Agreement, and any
          reference to the singular includes a reference to the plural and vice
          versa.
          <p>
            "
            <u>
              <strong>Affiliate</strong>
            </u>
            " means, with respect to a party, any entity that directly or
            indirectly controls, is controlled by or is under common control
            with that party. For purposes of this Agreement, “control” means an
            economic or voting interest of at least fifty percent (50%) or, in
            the absence of such economic or voting interest, the power to direct
            or cause the direction of the management and set the policies of
            such entity.
          </p>
          <p>
            "
            <u>
              <strong>Initial Subscription Term</strong>
            </u>
            " means thirty (30) calendar days, commencing on the Service
            Effective Date.
          </p>
          <p>
            "
            <u>
              <strong>Laws</strong>
            </u>
            " means all U.S. or non-U.S. national, regional, state, provincial
            or local laws, statutes, rules, regulations, ordinances,
            administrative rulings, judgments, decrees, orders, directives,
            policies, or treaties applicable to MyCareLinq’s provision and
            Customer’s use of the Services.
          </p>
          <p>
            "
            <strong>
              <u>Service Effective Date</u>
            </strong>
            " means the date that Customer subscribes to the Services.
          </p>
          <p>
            "
            <strong>
              <u>Subscription Fee</u>
            </strong>
            " shall be the fee paid by Customer for the Services, as reflected
            on Customer’s order confirmation.
          </p>
          <p>
            "
            <strong>
              <u>Subscription Fee</u>
            </strong>
            " shall be the fee paid by Customer for the Services, as reflected
            on Customer’s order confirmation.
          </p>
          <p>
            "
            <strong>
              <u>Renewal Term</u>
            </strong>
            " means the renewal subscription term for the Services commencing
            after the expiration of the Initial Subscription Term.
          </p>
          <p>
            "
            <strong>
              <u>Services</u>
            </strong>
            " means the MyCareLinq the digital content services to which
            Customer has entered into a paid subscription. For the avoidance of
            doubt, the Services hereunder do not include use of MyCareLinq’s
            free services and general availability websites, which will continue
            to be governed solely by the General Terms.
          </p>
          <p>
            "
            <strong>
              <u>Taxes and Fees</u>
            </strong>
            " and "
            <strong>
              <u>Taxes or Fees</u>
            </strong>
            " means all applicable sales, use, value-added or regulatory taxes,
            fees, duties, charges, surcharges or assessments levied on the
            provision of Services to Customer (exclusive of any income tax
            imposed on MyCareLinq).
          </p>
          <p>
            "
            <strong>
              <u>Usage Data</u>
            </strong>
            " means data and information regarding Customer’s use of and the
            performance of the MyCareLinq Service and related systems and
            technologies generated through the provision of the MyCareLinq
            Service.
          </p>
        </li>
        <li>
          <strong>
            <u>Access, Use, Customer Responsibility</u>
          </strong>
          .
          <ol>
            <li>
              <strong>
                <u>Right to Use</u>
              </strong>
              . MyCareLinq hereby grants to Customer a limited, non-exclusive,
              worldwide, revocable, non-transferable right for Customer to
              access and use the Services, subject to the terms and conditions
              of this Agreement for the Initial Subscription Term and any
              Renewal Term. Customer shall be solely responsible for obtaining
              and maintaining appropriate equipment and ancillary services
              needed to connect to, access or otherwise use the Services,
              including, without limitation, computers, computer operating
              system and web browser. MyCareLinq reserves the right to enhance
              or modify features of the Services but will not materially reduce
              the core functionality or discontinue any Services without
              providing prior written notice to Customer. All rights not
              expressly granted herein are reserved by MyCareLinq and its
              licensors.
            </li>
            <li>
              <strong>
                <u>Customer Use and Responsibility</u>
              </strong>
              . Customer may only use the Services pursuant to the terms of this
              Agreement and to the use limits imposed by the purchased
              subscription. Customer is solely responsible for its use of the
              Services and shall abide by, and ensure compliance with, all Laws
              in connection with its use of the Services, including but not
              limited to Laws related to recording, intellectual property,
              privacy and export control/economic sanctions.
            </li>
            <li>
              <strong>
                <u>Prohibited Use; Notification of Unauthorized Use</u>
              </strong>
              . Customer shall not use the Services to: (a) modify, disassemble,
              decompile, prepare derivative works of, reverse engineer or
              otherwise attempt to gain access to the source code of the
              Services; (b) knowingly or negligently use the Services in a way
              that abuses, interferes with, or disrupts MyCareLinq’s networks,
              Customer accounts, or the Services; (c) engage in activity that is
              illegal, fraudulent, false, or misleading, (d) transmit through
              the Services any material that may infringe the intellectual
              property or other rights of third parties; (e) build or benchmark
              a competitive product or service, or copy any features, functions
              or graphics of the Services; or (f) use the Services in violation
              of any policy referenced herein, or any applicable Law. Customer
              shall notify MyCareLinq immediately if it becomes aware of any
              unauthorized use of any password or account or any other known or
              suspected breach of security or misuse of the Services. If
              Customer becomes aware of any violation of this Agreement in
              connection with use of the Services by any person, Customer may
              contact MyCareLinq at{" "}
              <a href="mailto: support@mycarelinq.com">
                support@MyCareLinq.com
              </a>
              . MyCareLinq will investigate any complaints of violations that
              come to its attention and may take any action that it believes is
              appropriate, in its sole discretion, including, but not limited
              to, issuing warnings, removing content, suspending services, or
              terminating accounts. MyCareLinq will investigate any complaints
              of violations that come to its attention and may take any action
              that it believes is appropriate, in its sole discretion,
              including, but not limited to, issuing warnings, removing content,
              suspending services, or terminating accounts and/or profiles.
            </li>
            <li>
              <strong>
                <u>Beta Versions</u>
              </strong>
              . MyCareLinq may, from time to time, offer access to services that
              are classified as Beta version (i.e., a version that is not
              generally available). Access to and use of Beta versions may be
              subject to additional agreements. MyCareLinq makes no
              representations that a Beta version will ever be made generally
              available and reserves the right to discontinue or modify a Beta
              version at any time without notice. Beta versions are provided AS
              IS, may contain bugs, errors or other defects, and Customer’s use
              of a Beta version is at the sole risk of the Customer.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>Intended Use; No Commercial Transfer</u>
          </strong>
          . The Services are intended for personal use. Customer may not
          sublicense, sell, resell, transfer, assign, distribute, use on a
          timeshare or service bureau basis, or charge fees to other parties for
          use of the Services. The Services are not intended for use by
          individuals under the age of 18. Individuals under the age of 18 may
          not create accounts or use the Services.
        </li>
        <li>
          <strong>
            <u>No Medical Use</u>
          </strong>
          . The Services do not include any medical, diagnostic, treatment or
          clinical services, nor does MyCareLinq engage in any conduct that
          requires a professional license. The Services are also not available
          to any parties acting as a “covered entity” (such as a healthcare
          provider or a health plan), or to any party acting as a “business
          associate” of a covered entity, each as defined under 45 CFR § 160.103
          and other applicable law. Customer represents and warrants that (i) in
          using the Services, Customer is not acting as a covered entity, or as
          a business associate of a covered entity, and (ii) Customer will not
          use any of the Services to create, receive, maintain or transmit any
          sensitive information or data, including “protected health
          information” (as defined in 45 CFR § 160.103 and other applicable
          law), or use the Services in any manner that would make MyCareLinq
          Customer’s or any third party’s business associate.
        </li>
        <li>
          <strong>
            <u>Customer Data</u>
          </strong>
          .
          <ol>
            <li>
              <strong>
                <u>Customer Data</u>
              </strong>
              . Customer owns any data, notes, or other content that Customer
              creates, uploads, stores and/or shares in the course of using the
              Services (“Customer Data”). Customer is solely responsible for the
              accuracy, quality, and content of Customer Data, the means by
              which Customer Data is acquired and for obtaining consent for use
              thereof by MyCareLinq, as contemplated herein, in full compliance
              with all applicable laws. Customer hereby grants MyCareLinq a
              worldwide, irrevocable (except as set forth below), non-exclusive,
              transferable, royalty-free and fully sublicensable right and
              license during the Initial Term and any Renewal Terms to use,
              copy, display, store, adapt, and distribute Customer Data so that
              MyCareLinq can operate, deliver, and improve the Services. The
              license granted to MyCareLinq to display or distribute Customer
              Data is revocable by Customer if and when Customer chooses to
              independently remove or delete any Customer Data uploaded to
              features of the Services or stop sharing Customer Data with
              certain other users of the Services.
            </li>
            <li>
              <strong>
                <u>Usage Data</u>
              </strong>
              . MyCareLinq shall have the right to collect and analyze Usage
              Data and that MyCareLinq shall own all such Usage Data, provided
              that MyCareLinq may only publish Usage Data in an aggregated or
              other de-identified form.
            </li>
            <li>
              <strong>
                <u>Safeguards</u>
              </strong>
              . MyCareLinq will maintain commercially appropriate
              administrative, physical, and technical safeguards that are
              designed to protect Customer Data.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>Training</u>
          </strong>
          . MyCareLinq will provide reasonable training to Customer’s personnel
          relating to the Services at appropriate times without additional
          charge.
        </li>
        <li>
          <strong>
            <u>Prices and Fulfillment</u>
          </strong>
          . For each Service subscription that MyCareLinq provisions to
          Customer, MyCareLinq will bill Customer certain non-recurring and
          recurring charges for such Services.
          <ol>
            <li>
              <strong>
                <u>Price Changes</u>
              </strong>
              . The subscription fee for the Services will remain fixed during
              the relevant term unless Customer: (i) exceeds its maximum
              contacts and user limit activity, (ii) upgrade products or base
              packages, and/or (iii) subscribes to additional features or
              products. MyCareLinq may change prices for the Services from time
              to time, in its sole discretion. Any price changes will be
              effective upon the commencement of Customer’s next Renewal Term;
              provided, that MyCareLinq shall provide Customer with reasonable
              notice of any such fee increase.
            </li>
            <li>
              <strong>
                <u>Discounts and Promotional Pricing</u>
              </strong>
              . Prices offered to Customer by MvCareLinq may include discounts
              or promotional pricing. These discounts or promotional pricing
              amounts may be temporary and may expire upon the commencement of a
              Renewal Term, without additional notice. MyCareLinq reserves the
              right to discontinue or modify any promotion, sale or special
              offer at its sole and reasonable discretion.
            </li>
            <li>
              <strong>
                <u>Invoices and Payments</u>
              </strong>
              . Customer shall pay all invoices within thirty (30) days receipt
              of such invoice. Invoices may be emailed to the address specified
              by the Customer. Customer is responsible for providing complete
              and accurate billing and contact information to MyCareLinq and
              notifying MyCareLinq of any changes to such information. Except as
              explicitly provided in this Agreement, all payment obligations are
              non-cancelable and all amounts paid are non-refundable.
            </li>
            <li>
              <strong>
                <u>Taxes</u>
              </strong>
              . The prices include all charges for the right to use the Services
              and are exclusive of all Taxes and Fees. Subscriber shall be
              responsible for paying all Taxes and Fees associated with
              Customer’s use of the Services hereunder. If MyCareLinq has the
              legal obligation to pay or collect Taxes and Fees for which
              Customer is responsible under this Section, MyCareLinq will
              invoice Customer and Customer will pay such Taxes and Fees, unless
              Customer provides MyCareLinq with a valid tax exemption
              certificate authorized by the appropriate taxing authority.
            </li>
            <li>
              <strong>
                <u>Billing and Contract Information; Billing Disputes</u>
              </strong>
              . Customer represents and warrants that the customer billing
              information provided to MyCareLinq is complete and accurate. If
              Customer believes an invoice is incorrect, Customer must contact
              MyCareLinq in writing within thirty (30) days of the date of the
              invoice, and identify the amount in question, to be eligible to
              receive an adjustment or credit, which adjustment or credit, if
              any, shall be determined by MyCareLinq in MyCareLinq’s reasonable
              discretion after reviews all relevant information.
            </li>
            <li>
              <strong>
                <u>Outstanding Balance</u>
              </strong>
              . Unpaid amounts are subject to a finance charge of 1.5% per month
              on any outstanding, remaining balance, or the maximum permitted by
              law, whichever is lower, plus all reasonable expenses associated
              with collection efforts. If any amount owing by Customer under
              this Agreement is thirty (30) or more days overdue, MyCareLinq
              may, without limiting its other rights and remedies, suspend the
              provision of the Services until such amounts are paid in full.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>MyCareLinq Proprietary Rights</u>
          </strong>
          . MyCareLinq or its licensors own and shall retain all proprietary
          rights, including all copyright, patent, trade secret, trademark,
          trade name and all other intellectual property rights, in and to the
          Services. MyCareLinq shall retain ownership of any suggestions, ideas,
          enhancement requests, feedback, recommendations or other information
          provided by Customer or any other party relating to the Services. The
          Services are protected by copyright laws and international copyright
          treaties, as well as other U.S. federal, state and international
          intellectual property laws and treaties. Customer acknowledges that
          the rights granted under this Agreement do not provide Customer with
          title to or ownership of the Services, but only a right to use under
          the terms and conditions of this Agreement.
        </li>
        <li>
          <strong>
            <u>Confidentiality</u>
          </strong>
          . Each party agrees to regard and preserve as confidential all
          non-public information provided by the other party relating to the
          business, systems, operations, strategic plans, clients, pricing
          (including, but not limited to, the pricing terms herein), methods,
          processes, financial data, programs, and/or products of the other
          party in any form, that are designated as “confidential,” or a
          reasonable person knows or reasonably should understand to be
          confidential (herein “
          <strong>
            <u>Confidential Information</u>
          </strong>
          ”). For purposes of this Agreement, Customer’s Confidential
          Information shall include Customer Data, and any information disclosed
          to MyCareLinq by the Customer relating to the benefits, care and
          preparations required by Customer. Each party agrees to limit its
          disclosure of the other party’s Confidential Information to as few
          persons as possible and only to those persons with a need to know that
          are its or its Affiliates’ personnel and subject to an obligation to
          keep such information confidential. Except as needed to fulfill their
          respective obligations under this Agreement, neither party shall,
          without first obtaining the other party’s prior written consent,
          disclose to any person, firm or enterprise, except as expressly
          permitted herein, or use for its own benefit, or the benefit of a
          third party, the Confidential Information of the other party.
          <ol>
            <li>
              <strong>
                <u>Exclusions</u>
              </strong>
              . “
              <strong>
                <u>Confidential Information</u>
              </strong>
              ” shall not include Customer Content or information that (a) is
              already rightfully known to a party at the time it is obtained
              from the other party, free from any obligation to keep such
              information confidential; (b) is or becomes publicly known or
              available through no wrongful act of a party; (c) is rightfully
              received from a third party without restriction and without breach
              of any obligations such third party has to the disclosing party;
              or (d) is developed by a party without the use of any proprietary,
              non-public information provided by the other party under this
              Agreement.
            </li>
            <li>
              <strong>
                <u>Exception</u>
              </strong>
              . Either party may disclose Confidential Information where
              required by law, regulation, or court order, provided that the
              party subject to such law, regulation or court order shall, where
              permitted, notify the other party of any such use or requirement
              prior to disclosure in order to afford such other party an
              opportunity to seek a protective order to prevent or limit
              disclosure of the information to third parties.
            </li>
            <li>
              <strong>
                <u>Confidentiality Period and Obligations</u>
              </strong>
              . The confidentiality obligations set forth in this section of the
              Agreement shall remain in effect for a period of five (5) years
              from the disclosure of the information. Both parties agree (a) to
              take reasonable steps to protect the other party’s Confidential
              Information, and these steps must be at least as protective as
              those the receiving party takes to protect its own Confidential
              Information, and no less than a reasonable standard of care; (b)
              to notify the disclosing party promptly upon discovery of any
              unauthorized use or disclosure of Confidential Information; and
              (c) in the event of any unauthorized disclosure by a receiving
              party, to cooperate with the disclosing party to help regain
              control of the Confidential Information and prevent further
              unauthorized use or disclosure of it.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>Term and Termination</u>
          </strong>
          .
          <ol>
            <li>
              <strong>
                <u>Term and Renewal; Early Termination</u>
              </strong>
              . This Agreement will commence on the Service Effective Date and
              commence until terminated in accordance with this Section 10. A
              Renewal Term will commence automatically upon conclusion of the
              Initial Subscription Term or prior Renewal Term unless either
              party sends written notice of termination at least thirty (30)
              days prior to the commencement of the next Renewal Term.
            </li>
            <li>
              <strong>
                <u>Termination by Either Party</u>
              </strong>
              . A party may terminate this Agreement by providing written notice
              of termination for cause if the other party has materially
              breached the Agreement and has not cured such breach within thirty
              (30) days of written notice of such breach. MyCareLinq may further
              terminate this Agreement by providing written notice of
              termination without cause to Customer.
            </li>
            <li>
              <strong>
                <u>Cessation of Use</u>
              </strong>
              . Upon any termination of this Agreement, Customer shall
              immediately cease any further use of the Services.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>Warranties</u>
          </strong>
          .
          <ol>
            <li>
              <strong>
                <u>Services</u>
              </strong>
              . MyCareLinq warrants to Customer that MyCareLinq will perform the
              Services in a commercially reasonable manner.
            </li>
            <li>
              <strong>
                <u>Warranty Disclaimer</u>
              </strong>
              . EXCEPT AS EXPLICITLY PROVIDED IN SECTION 11.1, MYCARELINQ AND
              ITS LICENSORS EXPRESSLY DISCLAIM ANY AND ALL OTHER REPRESENTATIONS
              AND WARRANTIES, EITHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE
              WITH RESPECT THERETO, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED
              WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
              TITLE, NON-INFRINGEMENT, OR THE CONTINUOUS, UNINTERRUPTED,
              ERROR-FREE, VIRUS-FREE, OR SECURE ACCESS TO OR OPERATION OF THE
              SERVICES AND/OR MYCARELINQ SERVICES. MYCARELINQ EXPRESSLY
              DISCLAIMS ANY WARRANTY AS TO THE ACCURACY OR COMPLETENESS OF ANY
              INFORMATION OR DATA ACCESSED OR USED IN CONNECTION WITH THE
              SERVICES. TO THE EXTENT MYCARELINQ CANNOT DISCLAIM ANY SUCH
              WARRANTY AS A MATTER OF APPLICABLE LAW, THE SCOPE AND DURATION OF
              SUCH WARRANTY SHALL BE LIMITED TO THE FULLEST EXTENT PERMITTED BY
              LAW.
            </li>
            <li>
              <strong>
                <u>Third Party Materials and Data</u>
              </strong>
              . All third-party materials are the responsibility of their
              respective authors, creators, and/or owners. MyCareLinq is not
              responsible for third-party materials. The information reflects
              the opinion of its authors, creators, and/or owners at the time of
              its issuance, and opinions and information are subject to change
              at any time without notice and without notification. These
              materials were obtained from sources believed to be reliable and
              presented in good faith. Nevertheless, MyCareLinq has not
              independently verified the information contained therein, and does
              not guarantee its accuracy or completeness. This information
              should not be considered as a solicitation for any third party
              services, nor is the third-party content seen as any endorsement
              or testimonial. Customer is solely responsible for use of
              third-party data and services.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>Indemnification</u>
          </strong>
          .
          <ol>
            <li>
              <strong>
                <u>Indemnification by MyCareLinq</u>
              </strong>
              . Provided that Customer complies with the terms of Section 12.3
              below, MyCareLinq agrees to indemnify, defend and hold harmless
              Customer from any third party suits, claims or demands and
              associated liabilities, costs, damages and expenses (including,
              but not limited to, attorneys’ fees, expert fees and court costs)
              (collectively, “
              <strong>
                <u>Claims</u>
              </strong>
              ”) that Customer may sustain or incur arising from infringement by
              the Services of any copyright, trademark or trade secret of a
              third party, or any U.S. patent. This indemnity will not apply to
              any Claim that the infringement arose from the combination of the
              Services with software, hardware, content, data or other items not
              supplied by MyCareLinq. In the event that the licensed Services
              are, or in MyCareLinq’s sole opinion are likely to be, enjoined
              due to the type of infringement described in this Section 12,
              MyCareLinq, at its option and expense, may (a) replace the
              applicable Services with functionally equivalent non-infringing
              technology or (b) obtain a license for Customer’s continued use of
              the applicable Services, or, if the foregoing alternatives are not
              reasonably available to MyCareLinq (c) terminate this Agreement
              and refund any sums prepaid for Services not provided as a result
              of such termination.
            </li>
            <li>
              <strong>
                <u>Indemnification by Customer</u>
              </strong>
              . Provided that MyCareLinq complies with the terms of Section 12.3
              below, Customer agrees to indemnify, defend and hold harmless
              MyCareLinq and its Affiliates and their respective officers,
              directors, members, employees, consultants, agents, suppliers and
              resellers from any Claims arising from (a) Customer’s use of the
              Services in violation of this Agreement; (b) any infringement or
              violation by Customer of any intellectual property or other right
              of any person; and (c) Customer’s violation of any Law, including
              without limitation, any privacy Laws.
            </li>
            <li>
              <strong>
                <u>Indemnification Procedures</u>
              </strong>
              . In claiming any indemnification under this Section 12, the
              indemnified party shall promptly provide the indemnifying party
              with notice of any claim that the indemnified party believes is
              within the scope of the obligation to indemnify. The indemnified
              party may, at its own expense, participate in the defense if it so
              chooses, but the indemnifying party shall control the defense and
              all negotiations relative to the settlement of any such claim. Any
              settlement intended to bind the indemnified party shall not be
              final without the indemnified party’s written consent, which
              consent shall not be unreasonably withheld or delayed.
            </li>
            <li>
              <strong>
                <u>Exclusive Remedy</u>
              </strong>
              . This Section 12 states the indemnifying party’s sole liability
              to, and the indemnified party’s exclusive remedy against, the
              other party for any type of claim described in this Section 12.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>Limitation on Liability</u>
          </strong>
          .
          <ol>
            <li>
              <strong>
                <u>EXCLUSIONS</u>
              </strong>
              . MYCARELINQ SHALL NOT BE LIABLE TO CUSTOMER OR ANY THIRD PARTY
              FOR (a) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES;
              (b) ANY UNAUTHORIZED ACCESS TO, OR ALTERATION, THEFT OR
              DESTRUCTION OF THE WEB SITE, ANY CONTENT, CUSTOMER DATA, SYSTEM
              DATA, OTHER DATA FILES, PROGRAMS OR INFORMATION THROUGH ERROR,
              OMISSION, ACCIDENT OR FRAUDULENT MEANS OR DEVICES NOT DIRECTLY
              ATTRIBUTABLE TO MYCARELINQ’S NEGLIGENT ACTS OR OMISSIONS, OR FOR
              OTHER CIRCUMSTANCES OUTSIDE OF MYCARELINQ’S REASONABLE CONTROL, OR
              (c) ANY MALFUNCTION OR CESSATION OF INTERNET SERVICES BY INTERNET
              SERVICE PROVIDERS OR OF ANY OF THE NETWORKS THAT FORM THE INTERNET
              WHICH MAY AFFECT THE OPERATION OF THE SERVICES.
            </li>
            <li>
              <strong>
                <u>NO INDIRECT DAMAGES</u>
              </strong>
              . IN NO EVENT SHALL EITHER PARTY OR ITS LICENSORS BE LIABLE FOR
              ANY INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR
              DAMAGES FOR LOSS OF REVENUES OR PROFITS, LOSS OF USE, BUSINESS
              INTERRUPTION, LOSS OF DATA, BREACH OF DATA, OR THE COST OF
              SUBSTITUTE PRODUCTS OR SERVICES, WHETHER IN AN ACTION IN CONTRACT
              OR TORT, EVEN IF THE OTHER PARTY HAS BEEN ADVISED OF THE
              POSSIBILITY OF SUCH DAMAGES. EACH PARTY AGREES TO TAKE REASONABLE
              ACTION TO MITIGATE ITS DAMAGES.
            </li>
            <li>
              <strong>
                <u>AGGREGATE LIABILITY CAP</u>
              </strong>
              . IN NO EVENT SHALL MYCARELINQ’S LIABILITY FOR ANY DAMAGES EXCEED
              AN AMOUNT EQUAL TO THE TOTAL CHARGES PAID TO MYCARELINQ UNDER THIS
              AGREEMENT IN THE PRIOR SIX (6) MONTHS PRECEDING THE INCIDENT
              GIVING RISE TO THE CLAIM. THIS LIMITATION APPLIES TO ALL CAUSES OF
              ACTION IN THE AGGREGATE, INCLUDING, WITHOUT LIMITATION, BREACH OF
              CONTRACT, MISREPRESENTATIONS, NEGLIGENCE, STRICT LIABILITY AND
              OTHER TORTS. THESE LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY
              FAILURE OF ESSENTIAL PURPOSE OF ANY REMEDY.
            </li>
          </ol>
        </li>
        <li>
          <strong>
            <u>Force Majeure</u>
          </strong>
          . Neither party hereto will be liable for defaults or delays (other
          than the non-payment of fees owed by Customer for the Services) due to
          Acts of God, or the public enemy, acts or demands of any government or
          governmental agency, fires, floods, accidents, or other unforeseeable
          causes beyond its control and not due to its fault or negligence.
        </li>
        <li>
          <strong>
            <u>Miscellaneous</u>
          </strong>
          .
          <ol>
            <li>
              <strong>
                <u>Choice of Law and Arbitration</u>
              </strong>
              . This Agreement shall be governed by and construed under the laws
              of the State of California, without regard to conflict of laws
              principles. If there is any dispute under this Agreement or
              otherwise relating to your use of the Services described herein,
              you expressly agree that all such disputes will be solely governed
              by the venue and Agreement to Arbitrate and Waiver of Class Action
              provisions in the General Terms, which are located at{" "}
              <a href="./terms-of-use">https://mycarelinq.com/terms-of-use</a>,
              except to the extent provided therein.
            </li>
            <li>
              <strong>
                <u>Requirement to File Within One Year</u>
              </strong>
              . Notwithstanding any other statute of limitations, a claim or
              dispute under this Agreement must be filed within one year of when
              it could first be filed, or such claim will be permanently barred.
            </li>
            <li>
              <strong>
                <u>Export Restrictions</u>
              </strong>
              . Customer acknowledges that the Services, or a portion thereof,
              may be subject to the export control laws of the United States and
              other applicable country export control and trade sanctions laws
              (“
              <strong>
                <u>Export Control Laws</u>
              </strong>
              ”). Customer may not access, use, export, re-export, divert,
              transfer or disclose any portion of the Services or any related
              technical information or materials, directly or indirectly, in
              violation of any applicable export control or trade sanctions law
              or regulation. Customer represents and warrants that (i) Customer
              is not a citizen of, or located within, a country or territory
              that is subject to U.S. trade sanctions or other significant trade
              restrictions (including without limitation Cuba, Iran, North
              Korea, Syria, and the Crimea) and that Customer will not access or
              use the Services, or export, re-export, divert, or transfer the
              Services, in or to such countries or territories; (ii) Customer is
              not identified on any U.S. government restricted party lists
              (including without limitation the U.S. Treasury Department’s List
              of Specially Designated Nationals and Blocked Persons and Foreign
              Sanctions Evaders List, the U.S. Department of Commerce’s Denied
              Parties List, Entity List, and Unverified List, and the U.S.
              Department of State proliferation-related lists); and (iii) that
              no Customer Content created or submitted by Customer is subject to
              any restriction on disclosure, transfer, download, export or
              re-export under the Export Control Laws. Customer is solely
              responsible for complying with the Export Control Laws and
              monitoring them for any modifications.
            </li>
            <li>
              <strong>
                <u>Waiver and Severability</u>
              </strong>
              . Failure by either party to exercise any of its rights under, or
              to enforce any provision of, this Agreement will not be deemed a
              waiver or forfeiture of such rights or ability to enforce such
              provision. If any provision of this Agreement is held by a court
              of competent jurisdiction to be illegal, invalid or unenforceable,
              that provision will be amended to achieve as nearly as possible
              the same economic effect of the original provision and the
              remainder of this Agreement will remain in full force and effect.
            </li>
            <li>
              <strong>
                <u>General Provisions</u>
              </strong>
              . This Agreement, including the General Terms embody the entire
              understanding and agreement between the parties respecting the
              subject matter of this Agreement and supersede any and all prior
              understandings and agreements between the parties respecting such
              subject matter. In the event of a conflict between the General
              Terms and this Agreement, the terms of this Agreement will
              control. Any modification to this Agreement must be in writing and
              signed by both parties. Unless specified otherwise herein, any and
              all rights and remedies of either parties upon breach or other
              default under this Agreement will be deemed cumulative and not
              exclusive of any other right or remedy conferred by this Agreement
              or by Law or equity on either party, and the exercise of any one
              remedy will not preclude the exercise of any other. The captions
              and headings appearing in this Agreement are for reference only
              and will not be considered in construing this Agreement. No text
              or information set forth on any other purchase order, preprinted
              form or document shall add to or vary the terms and conditions of
              this Agreement. No joint venture, partnership, employment, or
              agency relationship exists between the parties as a result of this
              agreement or use of the Services.
            </li>
            <li>
              <strong>
                <u>Assignment</u>
              </strong>
              . This Agreement may not be assigned by either party without the
              prior written consent of the other party (which consent shall not
              be unreasonably withheld, conditioned or delayed). Notwithstanding
              the foregoing, MyCareLinq may assign or transfer all or any of its
              rights and obligations under this Agreement to a subsidiary,
              affiliate, or successor entity (including, but not limited to, an
              entity that is the subject of a merger or a third party purchaser)
              without the consent of Customer. Any assignment or transfer made
              without the required consent or compliance with the foregoing
              provisions of this Section 15.6 shall be void and of no effect.
              Subject to the foregoing, the provisions of this Agreement shall
              be binding upon and inure to the benefit of the respective
              successors and permitted assigns of each Party.
            </li>
            <li>
              <strong>
                <u>Notice</u>
              </strong>
              . MyCareLinq may give notice by electronic mail to Customer’s
              e-mail address on record in Customer’s account information, or by
              written communication sent by first class mail or pre-paid post to
              Customer’s address on record in Customer’s account information.
              Such notice shall be deemed to have been given upon the expiration
              of forty-eight (48) hours after mailing or posting (if sent by
              first class mail or pre-paid post) or twelve (12) hours after
              sending (if sent by email). Customer may give notice to MyCareLinq
              (such notice shall be deemed given when received by MyCareLinq) at
              any time by any of the following: letter delivered by nationally
              recognized overnight delivery service or first class postage
              prepaid mail to MyCareLinq at the following: 2342 Shattuck Ave,
              #336, Berkeley CA, 94708, or by email to{" "}
              <a href="mailto: contactus@mycarelinq.com">
                contactus@MyCareLinq.com
              </a>
              .
            </li>
            <li>
              <strong>
                <u>Survival</u>
              </strong>
              . All sections of the Agreement which by their nature should
              survive termination will survive termination, including, without
              limitation, accrued rights to payment, confidentiality
              obligations, warranty, disclaimers, indemnification and
              limitations of liability.
            </li>
            <li>
              <strong>
                <u>Counterparts</u>
              </strong>
              . This Agreement may be executed in counterparts manually or by
              facsimile, portable document format (pdf) or similar electronic
              image-based format, each of which counterparts shall be deemed an
              original, and all of which, when taken together, shall constitute
              one and the same instrument.
            </li>
            <li>
              <strong>
                <u>Relationship of the Parties</u>
              </strong>
              . The parties are independent contractors. This Agreement does not
              create a partnership, franchise, joint venture, agency, fiduciary
              or employment relationship between the parties.
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

const SA = () => {
  return (
    <>
      <section
        className="
                bg-[url('assets/images/about-us/header-bg.png')] 
                min-h-[2rem]
                max-h-[12rem]
                bg-center 
                bg-no-repeat 
                bg-cover 
                overflow-hidden 
                flex
            "
      >
        <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-normal">
          <Row style={{ padding: "5rem" }}>
            <Col xs={24}>
              <h1
                className="text-[4rem] text-white"
                style={{ textShadow: "3px 3px 7px rgb(0 0 0 / 65%)" }}
              >
                SUBSCRIPTION AGREEMENT
              </h1>
            </Col>
          </Row>
        </div>
      </section>
      <PurpleDivider />
      <SAContent />
      <Row>
        <Col xs={24}>
          <p className="text-base text-center font-thin text-primary-purple">
            Copyright &copy; 2021, 2022{" "}
            <a href="/">
              <strong>My CareLinq, Inc.</strong>
            </a>{" "}
            All Rights Reserved.
          </p>
        </Col>
      </Row>
    </>
  );
};

export { SAContent, SA };
