import React, { useState, useCallback } from "react";
import ReactGA from "react-ga4";
import "./Product.css";
import { ToUContent } from "../../components/TermsOfUse";
import { SAContent } from "../../components/SA";
import useAuth from "../../hooks/useAuth";
import useMclAuth from "../../hooks/useMclAuth";
import { useNavigate } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Col, Row, Button, Modal, Checkbox, Collapse } from "antd";
import { Url } from "../../constants/Global";
import { mclOpen } from "../../api/Mcl";

const { Panel } = Collapse;

const isTest = process.env.REACT_APP_TEST === "yes";

function ServiceDiv(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [tempEmail, setTempEmail] = useState("");
  const [touChecked, setTouChecked] = useState(false);
  const [saChecked, setSaChecked] = useState(false);
  const [subscribeNew, setSubscribeNew] = useState(true);
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();
  const navigate = useNavigate();

  const buttonLabel = props.buttonLabel ? props.buttonLabel : "Get Started"
  const priceLabel = props.priceLabel ? props.priceLabel : (<>$<br/>MONTHLY</>)
  const buttonAction = props.buttonAction;

  // reCAPTCHA
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute reCAPTCHA not yet available");
      return;
    }

    const token = await executeRecaptcha("checkoutSession");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  // features style
  const listFeatures = props.product_features.map((feature, index) => {
    if (index === props.enhanced) {
      return (
        <li key={index} className="m-0 mb-2 p-0 flex features-none">
          <strong className="m-0 p-0 text-[#ca212c]">{feature}</strong>
        </li>
      );
    } else if (index < props.marker) {
      return (
        <li
          key={index}
          className={
            props.level === "diy"
              ? "m-0 mb-2 p-0 flex features-orange"
              : "m-0 mb-2 p-0 flex features-red"
          }
        >
          <strong className="m-0 p-0 xs:text-[1rem] sm:text-[1rem] md:text-[0.8rem] lg:text-[1.25rem] xl:text-[1.25rem]">
            {feature}
          </strong>
        </li>
      );
    } else if (props.indent && props.indent.includes(index)) {
      return (
        <li
          key={index}
          className={
            props.level === "diy"
              ? "ml-8 mb-2 p-0 flex features-orange"
              : "ml-8 mb-2 p-0 flex features-red"
          }
        >
          <p className="m-0 p-0 xs:text-[1rem] sm:text-[1rem] md:text-[0.8rem] lg:text-[1.25rem] xl:text-[1.25rem]">
            {feature}
          </p>
        </li>
      );
    } else {
      return (
        <li
          key={index}
          className={
            props.level === "diy"
              ? "m-0 mb-2 p-0 flex features-orange"
              : "m-0 mb-2 p-0 flex features-red"
          }
        >
          <p className="m-0 p-0 xs:text-[1rem] sm:text-[1rem] md:text-[0.8rem] lg:text-[1.25rem] xl:text-[1.25rem]">
            {feature}
          </p>
        </li>
      );
    }
  });

  const productButtonClick = (event) => {
    console.log('got here!!!', props.buttonAction);
  }

  const startButtonClick = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'pricing',
      action: 'click_button',
      label: 'start_' + props.inSub
    });
    if (!tempEmail && !auth.accessToken) {
      setIsEmailModalVisible(true);
      ReactGA.event({
        category: 'pricing',
        action: 'open_modal',
        label: 'email_modal',
        nonInteraction: true,
      });
    } else if (auth.accessToken) {
      mclAuth.get(Url.CIRCLES.concat("/checkPrimary")).then((res) => {
        if (res.data === "yes") {
          if (props.inSub != "None" && props.inSub != "FREE") {
            // upgrading
            setSubscribeNew(false);
          } else {
            // first time subscription
            setSubscribeNew(true);
          }
          setIsModalVisible(true);
          ReactGA.event({
            category: 'pricing',
            action: 'open_modal',
            label: 'confirm_modal',
            nonInteraction: true,
          });
        } else {
          alert("Only primary caregiver can make payment.");
        }
      });
    }
  };

  const handleModalSubmit = (event) => {
    event.preventDefault();
    setIsModalVisible(false);
    if (!subscribeNew) {
      ReactGA.event({
        category: 'pricing',
        action: 'submit_subscribe',
        label: 'upgrade',
        nonInteraction: true,
      });
      mclAuth
        .get(Url.PAYMENT.concat("/upgradeStripeSubs/" + props.product_ID))
        .then((res) => {
          alert(res.data);
          mclAuth
            .get(Url.PAYMENT.concat("/currentPlan"))
            .then((res) => {
              if (res.data) {
                props.setCurrentPlan(res.data);
                mclAuth
                  .get(Url.PAYMENT.concat("/subExpiration"))
                  .then((res) => {
                    if (res) {
                      props.setExpiration(
                        res.data === 0
                          ? ""
                          : ", expiration:" +
                          new Intl.DateTimeFormat("en-US").format(
                            res.data * 1000
                          )
                      );
                    }
                  });
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      const recaptchaFirst = async () => {
        ReactGA.event({
          category: 'pricing',
          action: 'submit_subscribe',
          label: 'new_subscribe',
          nonInteraction: true,
        });
        let recaptchaToken = await handleReCaptchaVerify();

        if (recaptchaToken) {
          if (auth.accessToken) {
            // Get the recaptcha token, submit it with the data to the backend
            mclAuth.get(Url.PAYMENT.concat("/stripeId")).then((res) => {
              // if stripe id exists
              if (res.data) {
                mclOpen
                  .post(
                    Url.PAYMENT.concat("/createStripeSubs"),
                    {
                      customerID: res.data,
                      productID: props.product_ID,
                      priceID: props.product_priceID,
                      price: props.product_price,
                    },
                    {
                      params: {
                        token: recaptchaToken,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("tempEmail");
                    localStorage.setItem("accessToken", auth.accessToken);
                    window.location.href = res.data;
                  });
              } else {
                mclAuth.get(Url.PAYMENT.concat("/username")).then((res) => {
                  mclOpen
                    .post(
                      Url.PAYMENT.concat("/createStripeSubs"),
                      {
                        firstName: res.data[0],
                        lastName: res.data[1],
                        email: auth.email,
                        productID: props.product_ID,
                        priceID: props.product_priceID,
                        price: props.product_price,
                      },
                      {
                        params: {
                          token: recaptchaToken,
                        },
                      }
                    )
                    .then((res) => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("tempEmail");
                      localStorage.setItem("accessToken", auth.accessToken);
                      window.location.href = res.data;
                    });
                });
              }
            });
          } else {
            // for non login users
            ReactGA.event({
              category: 'pricing',
              action: 'submit_subscribe',
              label: 'for_non_login_users',
              nonInteraction: true,
            });
            mclOpen
              .post(
                Url.PAYMENT.concat("/createStripeSubs"),
                {
                  email: tempEmail,
                  productID: props.product_ID,
                  priceID: props.product_priceID,
                  price: props.product_price,
                },
                {
                  params: {
                    token: recaptchaToken,
                  },
                }
              )
              .then((res) => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("tempEmail");
                localStorage.setItem("tempEmail", tempEmail);
                window.location.href = res.data;
              });
          }
        } else {
          ReactGA.event({
            category: 'pricing',
            action: 'submit_subscribe',
            label: 'subscribe_failed_missing_recaptcha',
            nonInteraction: true,
          });
          alert("Missing recaptcha token.");
        }
      };

      recaptchaFirst();
    }
  };

  return (
    <div className="p-0 m-0 flex flex-col h-full">
      <Modal
        title={
          "You are about to " +
          (subscribeNew ? "subscribe to a new plan" : "change plan") +
          ". Please read through the following contents."
        }
        visible={isModalVisible}
        centered
        onCancel={() => {
          setIsModalVisible(false);
          setTempEmail("");
        }}
        className="checkoutModal"
        footer={(null, null)}
      >
        <Collapse
          accordion
          defaultActiveKey={["1"]}
          className="checkoutCollapse"
        >
          <Panel header="Terms of Use" key="1" className="max-h-fit">
            <ToUContent className="mt-5" />
          </Panel>
          <Panel header="SUBSCRIPTION AGREEMENT" key="2" className="max-h-fit">
            <SAContent className="mt-5" />
          </Panel>
        </Collapse>
        <Row className="my-5 justify-center">
          <Checkbox
            onChange={() => {
              setTouChecked(!touChecked);
            }}
          >
            I acknowledge the <strong>term of use</strong>
          </Checkbox>
          <Checkbox
            onChange={() => {
              setSaChecked(!saChecked);
            }}
          >
            I acknowledge the <strong>subscription agreement</strong>
          </Checkbox>
        </Row>
        <Row className="mb-5 justify-center">
          <form
            action={Url.BASE.concat(Url.PAYMENT.concat("/createStripeSubs"))}
            method="POST"
            onSubmit={handleModalSubmit}
          >
            <input type="hidden" name="productID" value={props.product_ID} />
            <input type="hidden" name="priceID" value={props.product_priceID} />
            <input type="hidden" name="price" value={props.product_price} />
            <Button disabled={!touChecked || !saChecked} htmlType="submit">
              continue
            </Button>
          </form>
        </Row>
      </Modal>
      <Modal
        title={"You have not logged in."}
        visible={isEmailModalVisible}
        centered
        onCancel={() => {
          ReactGA.event({
            category: 'pricing',
            action: 'close_modal',
            label: 'email_modal'
          });
          setIsEmailModalVisible(false);
          setTempEmail("");
        }}
        footer={(null, null)}
      >
        <p>
          Please provide your <strong>email address</strong> below then you can
          continue!
        </p>
        <p>
          or simply{" "}
          <Button
            className={
              "mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white h-fit"
            }
            onClick={() => {
              ReactGA.event({
                category: 'pricing',
                action: 'click_button',
                label: 'login_email_modal'
              });
              navigate("/site/login")
            }}
          >
            log in
          </Button>{" "}
          if you have an account.
        </p>
        <input
          className="mx-4 my-0 w-1/2 rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0"
          value={tempEmail}
          onChange={(event) => {
            setTempEmail(event.target.value);
          }}
        />
        <Button
          disabled={!tempEmail}
          onClick={() => {
            ReactGA.event({
              category: 'pricing',
              action: 'click_button',
              label: 'continue_email_modal'
            });
            setIsEmailModalVisible(false);
            mclOpen
              .get(Url.PAYMENT.concat("/userFromEmail/", tempEmail))
              .then((result) => {
                if (result.data) {
                  alert(
                    "Looks like you already have an account! Please login to continue."
                  );
                  navigate("/site/login");
                } else {
                  setIsModalVisible(true);
                }
              });
          }}
        >
          continue
        </Button>
      </Modal>
      <Row className="m-0 p-0">
        <Col xs={24} className="m-0 mt-3 p-0 flex">
          <strong className="mx-auto mt-2 xl:text-[2.5rem] lg:text-[2rem] md:text-[1.5rem] sm:text-[2rem] xs:text-[2rem] text-center">
            {props.product_name}
          </strong>
        </Col>
      </Row>
      <Row
        className={
          props.level === "diy"
            ? "text-[#e0551b] p-0 m-0"
            : "text-[#c80b29] p-0 m-0"
        }
      >
        {/* <Col xs={12} className="p-0"> */}
        <Col xs={ props.product_priceLabel ? 24 : 12} className="p-0">
          {/* <p className="my-3 text-right xs:text-[3] sm:text-[3rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem]"> */}
          <p className={props.product_priceLabel ? "my-3 text-center xs:text-[2] sm:text-[2.5 rem] md:text-[1.5rem] lg:text-[2.5rem] xl:text-[3.5rem]" 
            : "my-3 text-right xs:text-[3] sm:text-[3rem] md:text-[2rem] lg:text-[3rem] xl:text-[4rem]"}>
            {props.product_priceLabel ? props.product_priceLabel : props.product_price}
          </p>
        </Col>
        <Col xs={12} className="p-0">
          <p className="my-3 xs:text-[1.25rem] sm:text-[1.25rem] md:text-[1rem] lg:text-[1.5rem] xl:text-[1.5rem]">
            {props.product_priceLabel ? "" : <>$<br />/MONTH</>}
            {/* $<br />
            /MONTH */}
          </p>
        </Col>
      </Row>
      <Row className="m-0 p-0 xs:h-[7.5rem] sm:h-[7.5rem] md:h-[7.5rem] lg:h-[7.5rem] xl:h-[7.5rem]">
        <Col xs={24}>
          <p className="px-5 m-0 text-center xs:text-[1.5rem] sm:text-[1.5rem] md:text-[1rem] lg:text-[1.25rem] xl:text-[1.5rem]">
            {props.product_des}
          </p>
        </Col>
      </Row>
      <Row className="my-2 p-0">
        <Button
          className={
            "mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white text-xl h-fit"
          }
          // onClick={productButtonClick}
          onClick={(event) => {buttonAction === "subscribe" ? navigate("/survey") : 
            buttonAction === "chat" ? window.open("https://calendly.com/mycarelinq", "_blank") :startButtonClick(event) }}
        >
          {buttonLabel}
        </Button> 
      </Row>
      <ul className="mx-8 pl-4 list-none grow text-lg">{listFeatures}</ul>
    </div>
  );
}

class ServiceProductv2 extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      // names: ["FREE SIGN-UP", "STARTER", "PREMIUM", "ALL ACCESS" /*"GOLD"*/],
      names: ["STARTER", "PREMIUM", "ALL ACCESS", "ENTERPRISE"],
      action: ["subscribe","subscribe","subscribe","chat"],
      productIDs: isTest
        // start test
        ? props?.b2b 
        ? [
          // "prod_LpXJrSJrXWQRhm",
          "prod_MEBLgKzU9gNfk9",
          "prod_MEBLgKzU9gNfk9",
          "prod_LqCkJmOg0adAL9",
          "prod_LqCkG09S50YCDU",
        ]
        : [
          // "prod_LpXJrSJrXWQRhm",
          "prod_MEBLgKzU9gNfk9",
          "prod_LqCkJmOg0adAL9",
          "prod_LqCkG09S50YCDU",
          "prod_LtDQqJplbT7t7z",
        ]
        // end test
        : props?.b2b 
        ? [
          // "prod_M484rJD13IpFWf",
          "prod_PE7D3MIAzzjNOq",
          "prod_PE7GEtFQfsqUvT",
          "prod_PE7KMf361FV8Wc",
          "prod_PE7KMf361FV8Wc",
        ]
        :
        [
          "prod_M484rJD13IpFWf",
          "prod_MFnUyMNJ6kEnZl",
          "prod_LpYMvSiTKutfIu",
          "prod_LpYSCWsU8s7Tv2",
          "",
        ]
        ,
      descriptions: [
        // "Get Started on Your Own DIY Tools",
        "Support Built in When you Need it",
        "Continuous Support",
        // "Full Service & More Care Concierge hours and enhanced services",
        "Best for more intensive support and assistance",
        "For Employers, Healthcare providers, Government Agencies"
      ],
      features: [
        // [
        //   "Free 30-minute onboarding support",
        //   "Create your life-care plan and leave instructions for your future care team",
        //   "Full access to support journal",
        //   "Curate your own knowledge base",
        // ],
        [
          "30-minute consultation",
          "Care Planning Tool",
          "Chat with Care Concierge",
          "Option to purchase Care Coordination services"
        ],
        // [
        //   "Designated Care Concierge",
        //   "Free 30-minute onboarding support",
        //   "Caregiver journey roadmap orientation",
        //   "Option to purchase appointments for $120/hour",
        //   "Chat with Care Concierge",
        //   "Access to check-lists and FAQs upon request",
        //   "Selected Free Webinars",
        // ],
        [
          "30-minute consultation",
          "Monthly 1:1 meetings",
          "Care Coordination services",
          "Create and manage your care plan",
          "Chat, email, video conferencing"
        ],
        // [
        //   "1 Free 60-minute consultation",
        //   "Monthly 30-minute appointments",
        //   "Develop goals and customize plan and strategy to resolve challenges",
        //   "Curate the right resources based on need",
        //   "Chat and Email ",
        //   "Upload documents",
        // ],
        [
          "Everything in Premium plus the following:",
          "Unlimited chat, video conferencing",
          "Full-Service provisioning of services and products",
          "4 hours of Care Coordination services"
        ],
        // [
        //   "1 Free 60-minute consultation",
        //   "2x 60-minute appointments per month",
        //   "Full-service navigating:",
        //   "Benefits (cost and coverage)",
        //   "Insurance (costs, claims)",
        //   "Legal & financial help",
        //   "Evaluate and connect providers ",
        //   "Personal shopping",
        //   "Home care support",
        //   "Social needs",
        //   "End-of-Life support",
        //   "After-Life care support",
        //   "All Tools",
        // ],
        [
          "Custom benefit solutions and care coordination programs",
          "Speaking engagements",
          "Extended support",
          "Provide insights on utilization, member satisfaction and outcomes"
        ],
        [
          /*"Weekly 45-minute eMeetings",
                    "Guided Planning Tools",
                    "Priority Email & Text",
                    "24 x 7 Escalation hotline",
                    "Up to 4 hours of specialist consultation",
                    "Enhanced / Expanded offers in each area:",
                    "Benefits care and coverage analysis",
                    "Money management",
                    "Solutions to help cover costs of care",
                    "Special learning programs",
                    "Manage communication and legacy work",
                    "Access to in-house legal and financial counsellors (additional fees may apply)",*/
        ],
      ],
      priceIDs: Array(5).fill(""),
      // prices: props?.b2b ? isTest ? [0, 0, 0, 0, 0]  : [0, 1400, 5900, 19900, 100000] : [0, 1400, 5900, 19900, 100000],
      // prices: props?.b2b ? [0, 1000, 4200, 14000, 100000] : [0, 1400, 5900, 19900, 100000],
      prices: props?.b2b ? [1200, 5900, 19900, 14000, 100000] : [1200, 5900, 19900, 14000, 100000],
    };

    // console.log('!@$*&*^^% props auth:', props?.b2b);
    // console.log('!@$*&*^^% props productIDs:', this.state?.productIDs);

    var count = this.state.productIDs.length;
    const pricesIDCopy = this.state.priceIDs.slice();
    const pricesCopy = this.state.priceIDs.slice();
    this.state.productIDs.map((productID, index) => {
      mclOpen
        .get(Url.PAYMENT.concat("/product/", productID))
        .then((result) => {
          pricesIDCopy[index] = result.data.default_price;
        })
        .then(() => {
          if (pricesIDCopy[index] === null) {
            pricesCopy[index] = 0;
            count -= 1;

            if (count === 0) {
              this.setState({
                priceIDs: pricesIDCopy,
              });
            }
          } else {
            mclOpen
              .get(Url.PAYMENT.concat("/price/", pricesIDCopy[index]))
              .then((result) => {
                pricesCopy[index] = result.data.unit_amount;
                count -= 1;
              })
              .then(() => {
                if (count === 0) {
                  this.setState({
                    // priceIDs: pricesIDCopy,
                    prices: pricesCopy,
                  });
                }
              });
          }
        });
    });
  }

  render() {
    return (
      <div className="lg:container lg:mx-auto w-full p-0 bg-[url('assets/images/pricing/products-bg.png')] bg-center bg-no-repeat bg-cover overflow-hidden">
        <Row className="p-0 m-0">
          <Col
            // xs={24}
            // md={12}
            xs={18}
            md={18}
            className="p-0 m-0 vertical-divider flex flex-col"
          >
            <Row className="p-0 m-0">
              {/*<Col
                xs={12}
                className="p-0 m-0 h-28 text-center bg-[#618bb5] flex flex-col justify-center vertical-divider"
              >
                <p className="p-0 m-0 lg:text-[1.75rem] md:text-[1.25rem] sm:text-[1.75rem] xs:text-[1.75rem] text-white">
                  THE <strong>DIY</strong> LEVEL
                </p>
                <p className="p-0 m-0 lg:text-[1.25rem] md:text-[0.8rem] sm:text-[1rem] xs:text-[1rem] text-white">
                  KNOWLEDGE, INFORMATION &amp; TOOLS
                </p>
    </Col>*/}
              {/* <Col
                xs={12}
                className="p-0 m-0 h-28 text-center bg-[#618bb5] flex flex-col justify-center vertical-divider"
              >
                <p className="p-1 m-0 xxl:text-[1.75rem] xl:text-[1.5rem] lg:text-[1.25rem] md:text-[1rem] sm:text-[1.5rem] text-white">
                  Get Started On Your Own with DIY Tools
                </p>
              </Col> */}
              <Col
                xs={24}
                // xs={36}
                className="p-0 m-0 h-28 text-center bg-[#618bb5] flex flex-col justify-center"
              >
                <p className="p-1 m-0 xxl:text-[1.75rem] xl:text-[1.5rem] lg:text-[1.25rem] md:text-[1rem] sm:text-[1.5rem] text-white">
                  {/* Support Built In When you need it */}
                  For Individuals
                </p>
              </Col>
            </Row>
            <Row className="p-0 m-0 grow">
              <Col xs={8} className="p-0 vertical-divider">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[0]}
                  product_name={this.state.names[0]}
                  product_priceID={this.state.priceIDs[0]}
                  product_price={this.state.prices[0] / 100}
                  product_des={this.state.descriptions[0]}
                  product_features={this.state.features[0]}
                  marker={1}
                  level={"diy"}
                  buttonLabel="Subscribe"
                  buttonAction="subscribe"
                />
              </Col>
              <Col xs={8} className="p-0 vertical-divider">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[1]}
                  product_name={this.state.names[1]}
                  product_priceID={this.state.priceIDs[1]}
                  product_price={this.state.prices[1] / 100}
                  product_des={this.state.descriptions[1]}
                  product_features={this.state.features[1]}
                  marker={2}
                  level={"diy"}
                  buttonLabel="Subscribe"
                  buttonAction="subscribe"
                />
              </Col>
              {/* <Col xs={12} className="p-0 vertical-divider"> */}
              <Col xs={8} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[2]}
                  product_name={this.state.names[2]}
                  product_priceID={this.state.priceIDs[2]}
                  product_price={this.state.prices[2] / 100}
                  product_des={this.state.descriptions[2]}
                  product_features={this.state.features[2]}
                  marker={1}
                  level={"diy"}
                  buttonLabel="Subscribe"
                  buttonAction="subscribe"
                />
              </Col>

            </Row>
          </Col>
          {/* <Col xs={24} md={12} className="p-0 m-0"> */}
          <Col xs={6} md={6} className="p-0 m-0">
            <Row className="p-0 m-0">
              <Col
                // xs={24}
                xs={24}
                className="h-28 p-3 text-center bg-[#ea916b] flex flex-col justify-center"
              >
                <p className="p-1 m-0 xxl:text-[1.75rem] xl:text-[1.5rem] lg:text-[1.25rem] md:text-[1rem] sm:text-[1.5rem] text-white">
                  {/* Professional Services and Continuous Support Plus Be An
                  Administrator of Multiple Plans */}
                  For Enterprise
                </p>
              </Col>
            </Row>
            <Row className="p-0 m-0">
              {/* <Col xs={12} className="p-0 vertical-divider">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[2]}
                  product_name={this.state.names[2]}
                  product_priceID={this.state.priceIDs[2]}
                  product_price={this.state.prices[2] / 100}
                  product_des={this.state.descriptions[2]}
                  product_features={this.state.features[2]}
                  marker={2}
                  level={"concierge"}
                />
              </Col> */}
              <Col xs={24} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[3]}
                  product_name={this.state.names[3]}
                  product_priceID={this.state.priceIDs[3]}
                  product_price={this.state.prices[3] / 100}
                  product_des={this.state.descriptions[3]}
                  product_features={this.state.features[3]}
                  marker={1}
                  // indent={[3, 4, 5, 6, 7, 8, 9, 10, 11]}
                  level={"diy"}
                  buttonLabel="Contact Sales"
                  buttonAction="chat"
                  product_priceLabel="Let's Talk"
                />
              </Col>
              {/*<Col xs={12} className="p-0">
                                <ServiceDiv
                                inSub={this.props.inSub}
                                setCurrentPlan={this.props.setCurrentPlan}
                                setExpiration={this.props.setExpiration}
                                product_ID={this.state.productIDs[3]}
                                product_name={this.state.names[3]}
                                product_priceID={this.state.priceIDs[3]}
                                product_price={this.state.prices[3] / 100}
                                product_des={this.state.descriptions[3]}
                                product_features={this.state.features[3]}
                                marker={5}
                                enhanced={5}
                                level={"concierge"}
                                />
                            </Col>*/}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ServiceProductv2;
