import React, { useEffect } from "react";
import {
  Col,
  Row,
  Form,
  Space,
  Button,
  Input,
  Avatar,
  Modal,
  Spin,
  Select,
  Radio,
} from "antd";
import { ScheduleOutlined } from "@ant-design/icons";

import Min from "../assets/images/team/MinPomper_CEO.png";
// import Sonia from "../assets/images/team/Sonia.png";
// import Kaleigh from "../assets/images/team/Kaleigh.png";
// import Bayane from "../assets/images/team/Bayane.png";
import GAText from "./GAText";

const { Option } = Select;

const ScheduleForm = ({
  isSubmitting = false,
  submitForm = () => {},
  form = undefined,
  CC = [],
}) => {
  const onFinish = (values) => {
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const avatarOnClick = (value) => {
    form.setFieldsValue({
      ["cc"]: value,
    });
  };

  const CCItem = ({ value, label, src }) => {
    return (
      <Col xs={12} md={6}>
        <Space direction="vertical" size="middle">
          <Avatar
            className="cursor-pointer"
            src={src}
            size={80}
            onClick={() => {
              avatarOnClick(value);
            }}
          />
          <Radio value={value}>{label}</Radio>
        </Space>
      </Col>
    );
  };

  return (
    <Spin spinning={isSubmitting}>
      <Form
        className="scheduleForm"
        layout="vertical"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        form={form}
      >
        <h2 className="text-h2 text-primary-purple">
          Schedule a Free Consultation
        </h2>
        <Row className="mt-3 mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  First Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please input your first name" },
              ]}
              name="firstName"
            >
              <Input type="text" placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Last Name
                </label>
              }
              required
              rules={[
                { required: true, message: "Please input your last name" },
              ]}
              name="lastName"
            >
              <Input type="text" placeholder="Last Name" />
            </Form.Item>
          </Col>
        </Row>

        <Row className="mb-3" gutter={16}>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Email
                </label>
              }
              required
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
              name="email"
            >
              <Input type="email" placeholder="user@example.com" />
            </Form.Item>
          </Col>
          <Col md={12}>
            <Form.Item
              label={
                <label
                  className="text-primary-purple"
                  style={{ fontSize: "1rem" }}
                >
                  Phone (+1)
                </label>
              }
              required
              rules={[
                {
                  pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                  message: "Please check the format of phone number",
                },
                {
                  required: true,
                  message: "Please input your phone number",
                },
              ]}
              name="phone"
            >
              <Input type="text" placeholder="555-555-5555" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="concern"
          label="Area of Concern"
          rules={[
            {
              required: true,
              message: "Please select the area of concern!",
            },
          ]}
        >
          <Select defaultValue=""  >
            <Option value="I have some questions about My CareLinq's services">
              I have some questions about My CareLinq's services
            </Option>
            <Option value="I need help with...">
              I need help with...
            </Option>
            <Option value="I need to talk to a Care Concierge">
              I need to talk to a Care Concierge
            </Option>
            <Option value="I don't know where to start..">
              I don't know where to start..
            </Option>
            <Option value="I'm caring for someone who has ...">
              I'm caring for someone who has ...
            </Option>
            <Option value="Please contact me at your earliest convenience...">
              Please contact me at your earliest convenience...
            </Option>
            <Option value="How much do services cost?">
              How much do services cost?
            </Option> 
           </Select>
        </Form.Item>

        {/* <Form.Item
          label={
            <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
              Message <br />
              (Please let us know when we can contact you)
            </label>
          }
          rules={[
            { max: 300, message: "Message length cannot over 300 characters." },
          ]}
          name="message"
          className="mb-3"
        >
          <Input.TextArea rows={5} showCount maxLength={300} />
        </Form.Item> */}
        <h3 className="mt-6 text-h5 text-primary-purple">
          Select one of the care concierge:
        </h3>

        <Form.Item
          required
          rules={[
            {
              required: true,
              message: "Please select one concierge",
            },
          ]}
          name="cc"
          className="mb-3"
        >
          <Radio.Group className="w-full">
            <Row className="mt-3 mb-3" gutter={16}>
              {CC &&
                CC.map((item) => {
                  return (
                    <CCItem
                      key={item.value}
                      value={item.value}
                      label={item.label}
                      src={item.src}
                    />
                  );
                })}
            </Row>
          </Radio.Group>
        </Form.Item>
        <GAText></GAText>
      </Form>
    </Spin>
  );
};

const ScheduleModal = ({
  setting = {
    title: "Drop Us a Note",
    isModalVisible: false,
    calendlyLink: "",
  },
  isSubmitting = false,
  handleCancel = () => {},
  submitForm = (values) => {
    console.log(values);
  },
  form = undefined,
}) => {
  const CC = [
    {
      value: "min",
      label: "Min",
      src: Min,
    },
    // {
    //   value: "sonia",
    //   label: "Sonia",
    //   src: Sonia,
    // },
    // {
    //   value: "kaleigh",
    //   label: "Kaleigh",
    //   src: Kaleigh,
    // },
    // {
    //   value: "bayane",
    //   label: "Bayane",
    //   src: Bayane,
    // },
  ];

  return (
    <Modal
      title={setting.title}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      width={"80%"}
      onOk={form && form.submit}
      footer={[
        <Button
          key="back"
          shape="round"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          icon={<ScheduleOutlined />}
          onClick={form && form.submit}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Schedule Appt
        </Button>,
      ]}
    >
      <ScheduleForm
        submitForm={submitForm}
        isSubmitting={isSubmitting}
        form={form}
        CC={CC}
      />
    </Modal>
  );
};

export { ScheduleModal, ScheduleForm };
