import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { RightOutlined, UserOutlined } from "@ant-design/icons";

import { Button, Col, Row, Spin, Tooltip } from "antd";

import useMclAuth from "../../hooks/useMclAuth";
import {
  Care,
  CareTypeToColor,
  PlanRoleToColor,
  PlanRoleToStr,
  Url,
} from "../../constants/Global";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

// Return an array of care plan synopsis
const CircleCarePlanSynopsis = ({
  // style = {},
  // setPage,
  cg,
  showComponent,
  setCurrentCircle,
  setOpenProfileDrawer,
  myCircles,
}) => {
  if (_.isEmpty(cg) || !myCircles || myCircles.length < 1) {
    return <Spin />;
  }
  // console.log("+~+~+CircleCarePlan myCircles:\n", myCircles);
  return (
    <>
      {myCircles.map((circle, index) => {
        // For each circle the user is in, get the synopsis of the care plan
        // console.log("+~+~+CircleCarePlan circle:\n", circle);
        // console.log("+~+~+~+~+~+~+~+~+~+ index:", index);
        const showPlan = (e) => {
          console.log("***showPlan current circle index:", e.currentTarget.id);
          const sider = document.querySelector("#client-dash-sider");
          const siderChild = sider.querySelector(".ant-menu-item");
          const siderCarePlanItem = siderChild.querySelector(
            ".ant-menu-title-content"
          );
          // console.log(
          //   "siderCarePlanItem.textContent=",
          //   siderCarePlanItem.textContent
          // );
          if (siderCarePlanItem.textContent === "Care Plan") {
            siderChild.className += " ant-menu-item-selected";
          }
          setCurrentCircle(e.currentTarget.id);
          showComponent("planDetails");
          setOpenProfileDrawer(false);
        };

        return (
          <Row
            id="client-profile-drawer-circle"
            className="flex flex-row grow items-center gap-1 bg-white text-[#10082d] rounded-md px-2 py-1 mb-1"
          >
            <Col span={20}>
              <Row className="text-[0.75rem]">
                <Col span={24}>
                  <strong>
                    {circle.firstName} {circle.lastName}
                  </strong>
                </Col>
              </Row>
              <Row>
                <div
                  className="text-white text-[0.5rem] px-1 py-0.5 rounded-md"
                  style={{
                    backgroundColor: PlanRoleToColor(cg._id, circle),
                  }}
                >
                  You are the {PlanRoleToStr(cg._id, circle)}
                </div>
              </Row>
            </Col>
            <Col span={3}>
              {PlanRoleToStr(cg._id, circle) === "PLAN ADMIN" ? (
                <Tooltip title="Edit Plan">
                  <Button
                    id={index}
                    type="link"
                    shape="circle"
                    size="small"
                    icon={<RightOutlined style={{ color: "white" }} />}
                    onClick={showPlan}
                    style={{ backgroundColor: PlanRoleToColor(cg._id, circle) }}
                    className="ml-auto text-white items-center gap-x-1 hover:bg-[#e0551bc0] hover:text-white focus:bg-[#e0551ba0] focus:text-white"
                  />
                </Tooltip>
              ) : PlanRoleToStr(cg._id, circle) === "BACKUP ADMIN" ? (
                <Tooltip title="Edit Plan">
                  <Button
                    id={index}
                    type="link"
                    shape="circle"
                    size="small"
                    icon={<RightOutlined style={{ color: "white" }} />}
                    onClick={showPlan}
                    style={{ backgroundColor: PlanRoleToColor(cg._id, circle) }}
                    className="ml-auto text-white items-center gap-x-1 hover:bg-[#217671c0] hover:text-white focus:bg-[#217671a0] focus:text-white"
                  />
                </Tooltip>
              ) : (
                <Tooltip title="View Plan">
                  <Button
                    id={index}
                    type="link"
                    shape="circle"
                    size="small"
                    icon={<RightOutlined style={{ color: "white" }} />}
                    onClick={showPlan}
                    style={{ backgroundColor: PlanRoleToColor(cg._id, circle) }}
                    className="ml-auto text-white items-center gap-x-1 hover:bg-[#8159f5c0] hover:text-white focus:bg-[#8159f5a0] focus:text-white"
                  />
                </Tooltip>
              )}
            </Col>
          </Row>
        );
      })}
    </>
  );
};

export default CircleCarePlanSynopsis;
