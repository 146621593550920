import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Row,
  notification,
  Result,
  Card,
  Button
} from "antd";
import { Url } from "../../constants/Global";
import _ from "lodash";
import useAuth from '../../hooks/useAuth';
import useMclAuth from '../../hooks/useMclAuth';
import {
  PlusOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  EditOutlined,
  ArrowLeftOutlined
} from "@ant-design/icons";

import {
  CircleSelectionTable,
  CCKnowledgeBase
} from "../../components/KBComponents";

import {
  openNotification
} from "../../components/Various";

const CCKBOverview = ({ setPage, cg }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [circles, setCircles] = useState([]);
  const [currentKB, setCurrentKB] = useState({});
  const mclAuth = useMclAuth()

  useEffect(() => {
    // Get the circles of cc's clients' 
    setIsLoading(true);
    mclAuth.get(Url.CONCIERGES.concat('/', cg?._id, '/circles'))
      .then(res => {
        const result = _.flatMap(res.data, (item) => item.circleId);
        setCircles(result);
      })
      .catch(error => {
        openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to get care concierge clients" })
      }).finally(() => {
        setIsLoading(false);
      })
  }, [cg, mclAuth])

  return (
    <>
      <h1
        style={{
          fontSize: "x-large",
          fontWeight: "bold",
          color: "#30225f",
        }}
      >
        Knowledge Base
      </h1>
      {
        _.isEmpty(currentKB) ?
          <>
            <Card
              className='mt-4'
              title="Client's Knowledge Base"
              extra={[
              ]}
            >
              <Row>
                <Col xs={24}>
                  <CircleSelectionTable
                    data={circles}
                    isLoading={isLoading}
                    setCurrentCircle={setCurrentKB}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={24}>
                <Card
                  className='mt-4'
                  title="Care concierge Knowledge Base"
                  extra={[
                  ]}
                >
                      <Button type="primary" onClick={() => {
                        setCurrentKB({
                          _id: "care_concierge"
                        })
                      }}>
                        Enter Care Concierge Knowledge Base
                      </Button>
                </Card>
                </Col>
              </Row>
            </Card>
          </>
          :
          <>
            <CCKnowledgeBase
              setPage={setCurrentKB}
              circleId={currentKB._id}
              circles={circles}
            />
          </>
      }
    </>
  );
};

export default CCKBOverview