import React from "react";
import { Divider } from "antd";

const SidePanelDivider = ({ title }) => {
  return (
    <Divider
      orientation="left"
      orientationMargin="0"
      plain
      className="messaging-divider"
      style={{
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "20px",
        textAlign: "center",
        textTransform: "uppercase",
        color: "rgba(0, 0, 0, 0.6)",
        borderTopColor: "rgba(60, 48, 102, 0.3)",
        margin: 0,
        paddingLeft: 0,
        paddingTop: 8,
        paddingRight: 8,
        paddingBottom: 8,
      }}
    >
      {title}
    </Divider>
  );
};

export default SidePanelDivider;
