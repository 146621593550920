import React, { useState, useCallback } from 'react'
import _ from "lodash";
import moment from 'moment';
import { Row, Col, Card, Button, Descriptions, Collapse, List, Tag, Empty } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { SurveyQuestions, RoleToStr, SubscriptionToStr, SubscriptionToColor, DispositionCode, RelationToStr, WorkflowItems, NextStepsItems } from '../../constants/Global';
import { v4 as uuidv4 } from 'uuid';

import { NoteModal, DeleteModal, EditCaseNoteModal, CaseNoteTable } from "../../components/ConciergeComponents"

import "./ClientDetail.css"

const ClientDetail = ({
    className,
    style,
    CCId = "",
    CCName = "",
    clientDetail = {},
    updateNote = () => { },
    deleteNote = () => { },
}) => {
    const EMPTY_TEXT = "Empty";
    const initialModalNoteData = {
        modalShow: false,
        modalTitle: "Add",
        field: "",
        description: "",
        relationshipId: "",
        noteId: "",
        CCId: ""
    };
    const initialModalCaseNoteData = {
        modalShow: false,
        modalTitle: "",
        caseNoteId: "",
        primaryCare: "",
        CCId: "",
        CCName: "",
        clientId: "",
        consultCC: "",
        consultDate: "",
        accountType: 0,
        disposition: 0,
        subject: "",
        description: "",
        isIntake: false,
        prevNote: {},
    };
    const [modalNoteData, setModalNoteData] = useState(initialModalNoteData)

    const [modalCaseNoteData, setModalCaseNoteData] = useState(initialModalCaseNoteData)
    const [modalPostConsultActions, setModalPostConsultActions] = useState([]);

    const closeNoteModal = () => {
        setModalNoteData((prevState) => ({
            ...prevState,
            modalShow: false,
        }));
    }
    const closeCaseNoteModal = () => {
        setModalCaseNoteData((prevState) => ({
            ...prevState,
            modalShow: false,
        }));
    }
    const handleNoteModalOk = useCallback(
        () => {
            let requestPath = ""
            // Check if the field value is valid
            const availableFields = ["backgrounds", "goals", "scopeOfWorks"];
            if (!_.includes(availableFields, modalNoteData.field)) {
                console.error("Request not valid");
                return;
            }
            // Check whether is editing or creating new note
            if (modalNoteData.noteId) {
                requestPath = requestPath.concat(
                    '/relationships/',
                    modalNoteData.relationshipId,
                    `/${modalNoteData.field}/`,
                    modalNoteData.noteId)
            } else {
                requestPath = requestPath.concat(
                    '/relationships/',
                    modalNoteData.relationshipId,
                    `/${modalNoteData.field}`)
            }
            let data = {
                description: modalNoteData.description,
                CCId: modalNoteData.CCId
            }
            updateNote(requestPath, data);
            closeNoteModal();
        },
        [modalNoteData, updateNote],
    );
    const handleNoteModalDelete = useCallback(
        () => {
            let requestPath = ""
            // Check if the field value is valid
            const availableFields = ["backgrounds", "goals", "scopeOfWorks"];
            if (!_.includes(availableFields, modalNoteData.field)) {
                console.error("Request not valid");
                return;
            }
            if (!modalNoteData.noteId) {
                console.error("Request not valid");
                return;
            }
            requestPath = requestPath.concat(
                '/relationships/',
                modalNoteData.relationshipId,
                `/${modalNoteData.field}/`,
                modalNoteData.noteId)
            let data = {
                description: modalNoteData.description,
                CCId: modalNoteData.CCId
            }
            deleteNote(requestPath, data);
            closeNoteModal();
        },
        [modalNoteData, deleteNote],
    );

    const openNoteModal = (show = "edit", field = "", description = "", relationshipId = "", noteId = "", CCId = "") => {
        if (!CCId) {
            return;
        }

        let newModalNoteData = {
            modalShow: show,
            modalTitle: "Add",
            field: field,
            description: description,
            relationshipId: relationshipId,
            noteId: noteId,
            CCId: CCId
        }
        if (show === "edit") {
            if (noteId) {
                newModalNoteData.modalTitle = "Edit " + _.startCase(field) + " Note"
            } else {
                newModalNoteData.modalTitle = "Add " + _.startCase(field) + " Note"
            }
        } else {
            newModalNoteData.modalTitle = "Delete " + _.startCase(field) + " Note"
        }
        setModalNoteData(newModalNoteData);
    }

    const openCaseNoteModal = (show = "edit", isIntake = false, caseNote = false) => {
        if (!CCId) {
            return;
        }

        let postConsultActions;
        if (caseNote) {
            postConsultActions = [...caseNote.postConsultActions];
        } else {
            postConsultActions = [];
        }
        for (let i in postConsultActions) {
            postConsultActions[i]["isEditing"] = false;
            postConsultActions[i]["id"] = uuidv4();
        }
        setModalPostConsultActions(postConsultActions);

        // Find values in previous casenote
        let lastConsultDate = "";
        let lastActionItems = [];
        if (!isIntake && show === "edit") {
            // No case note, so is adding new item
            if (!caseNote) {
                if (clientDetail.caseNotes && clientDetail.caseNotes.length > 0) {
                    lastConsultDate = clientDetail.caseNotes[0].createdAt;
                    lastActionItems = clientDetail.caseNotes[0].postConsultActions;
                } else {
                    lastConsultDate = clientDetail.intakeConsult.createdAt;
                    lastActionItems = clientDetail.intakeConsult.postConsultActions;
                }
            } else {
                // Is editing item
                // Because case notes is sorted by date, so the next one of the caseNote is what we want
                for (let i = 0; i < clientDetail.caseNotes.length; i++) {
                    if (clientDetail.caseNotes[i]._id === caseNote._id &&
                        i + 1 < clientDetail.caseNotes.length) {
                        let newIndex = parseInt(i) + 1;
                        lastConsultDate = clientDetail.caseNotes[newIndex].createdAt;
                        lastActionItems = clientDetail.caseNotes[newIndex].postConsultActions;
                    } else if (clientDetail.caseNotes[i]._id === caseNote._id &&
                        (clientDetail.caseNotes.length === 1 || i === clientDetail.caseNotes.length - 1)
                    ) {
                        lastConsultDate = clientDetail.intakeConsult.createdAt;
                        lastActionItems = clientDetail.intakeConsult.postConsultActions;
                    }
                }
            }
        }

        // Fill value with previous casenote data, if not exist, fill with current data 
        let newCaseModalNoteData = {
            modalShow: show,
            primaryCare: clientDetail.primaryCare,
            relationshipId: clientDetail._id,
            CCId: CCId,
            caseNoteId: caseNote ? caseNote._id : "",
            CCName: caseNote &&
                caseNote.consultCC ?
                (caseNote.consultCC.firstName + " " + caseNote.consultCC.lastName) : CCName,
            clientId: clientDetail.clientId,
            consultCC: caseNote ? caseNote.consultCC : CCId,
            consultDate: caseNote ? moment(caseNote.createdAt) : moment(),
            accountType: caseNote ? caseNote.accountType : clientDetail.subscription,
            disposition: caseNote ? caseNote.disposition : 0,
            subject: caseNote ? caseNote.subject : "",
            description: caseNote ? caseNote.description : "",
            isIntake: isIntake,
            isEditing: caseNote ? true : false,
            lastConsultDate: lastConsultDate,
            lastActionItems: lastActionItems,
        }

        let caseNoteType = isIntake ? "Intake Consultation" : "Consultation"
        if (show === "edit") {
            if (caseNote) {
                newCaseModalNoteData.modalTitle = "Edit " + caseNoteType + " Note"
            } else {
                newCaseModalNoteData.modalTitle = "Add " + caseNoteType + " Note"
            }
        } else {
            newCaseModalNoteData.modalTitle = "Delete " + caseNoteType + " Note"
        }
        setModalCaseNoteData(newCaseModalNoteData);
    }

    const handleCaseNoteModalOk = (newCaseNoteData) => {
        let requestPath = ""
        // If case note id exist, do update. Otherwise create a new case note
        if (newCaseNoteData.caseNoteId) {
            requestPath = requestPath.concat(
                '/relationships/',
                newCaseNoteData.relationshipId,
                `/caseNote/`,
                newCaseNoteData.caseNoteId)
        } else {
            requestPath = requestPath.concat(
                '/relationships/',
                newCaseNoteData.relationshipId,
                `/caseNote`)
        }

        let data = _.pick(newCaseNoteData, [
            "clientId",
            "CCId",
            "isIntake",
            "consultCC",
            "disposition",
            "accountType",
            "subject",
            "description",
        ])

        data.postConsultActions = modalPostConsultActions
        updateNote(requestPath, data);
        closeCaseNoteModal();
    }

    const handleCaseNoteModalDelete = useCallback(
        () => {
            let requestPath = ""
            if (!modalCaseNoteData.caseNoteId) {
                console.error("Request not valid");
                return;
            }
            requestPath = requestPath.concat(
                '/relationships/',
                clientDetail._id,
                `/caseNote/`,
                modalCaseNoteData.caseNoteId)

            let data = {
                clientId: modalCaseNoteData.clientId,
                CCId: modalCaseNoteData.CCId
            }

            deleteNote(requestPath, data);
            closeCaseNoteModal();
        }, [clientDetail, modalCaseNoteData, modalPostConsultActions, deleteNote]);

    return (
        <div
            className={className}
            style={style}
        >
            <NoteModal
                modalTitle={modalNoteData && modalNoteData.modalTitle}
                isModalVisible={modalNoteData && modalNoteData.modalShow === "edit"}
                onChange={(e) => {
                    setModalNoteData((prevState) => ({
                        ...prevState,
                        description: e.target.value,
                    }));
                }}
                handleOk={handleNoteModalOk}
                handleCancel={closeNoteModal}
                value={modalNoteData.description}
            />
            <DeleteModal
                modalTitle={modalNoteData && modalNoteData.modalTitle}
                isModalVisible={modalNoteData && modalNoteData.modalShow === "delete"}
                handleOk={handleNoteModalDelete}
                handleCancel={closeNoteModal}
            />
            <DeleteModal
                modalTitle={"Delete Case Note"}
                isModalVisible={modalCaseNoteData && modalCaseNoteData.modalShow === "delete"}
                handleOk={handleCaseNoteModalDelete}
                handleCancel={closeCaseNoteModal}
            />
            <EditCaseNoteModal
                modalTitle={modalCaseNoteData && modalCaseNoteData.modalTitle}
                isModalVisible={modalCaseNoteData && modalCaseNoteData.modalShow === "edit"}
                value={modalCaseNoteData}
                handleOk={handleCaseNoteModalOk}
                handleCancel={closeCaseNoteModal}
                updateValue={setModalCaseNoteData}
                postConsultActions={modalPostConsultActions}
                updatePostConsultActions={setModalPostConsultActions}
            />
            <Row gutter={[16, 16]}>
                <Col xs={24}>
                    <Card
                        className="h-full"
                        title="Intake Consultation (First Encounter)"
                    >
                        {
                            clientDetail.intakeConsult ?
                                <Descriptions bordered>
                                    <Descriptions.Item label="Primary Care/Account Owner" span={3}>{clientDetail ? clientDetail.primaryCare : EMPTY_TEXT}</Descriptions.Item>
                                    <Descriptions.Item label="Care Concierge" span={3}>
                                        {clientDetail && clientDetail.intakeConsult &&
                                            clientDetail.intakeConsult.consultCC ?
                                            (clientDetail.intakeConsult.consultCC.firstName + " " + clientDetail.intakeConsult.consultCC.lastName) : EMPTY_TEXT}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Consultation Date" span={3}>
                                        {clientDetail && clientDetail.intakeConsult && clientDetail.intakeConsult.createdAt
                                            ? moment(clientDetail.intakeConsult.createdAt).toLocaleString() : EMPTY_TEXT}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Account Type" span={3}>
                                        {clientDetail && clientDetail.intakeConsult ?
                                            <Tag color={SubscriptionToColor(clientDetail.intakeConsult.accountType)}>
                                                {SubscriptionToStr(clientDetail.intakeConsult.accountType).toUpperCase()}
                                            </Tag> : EMPTY_TEXT}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Disposition" span={3}>
                                        {clientDetail && clientDetail.intakeConsult ? DispositionCode[clientDetail.intakeConsult.disposition] : EMPTY_TEXT}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Subject" span={3}>
                                        {clientDetail && clientDetail.intakeConsult ? clientDetail.intakeConsult.subject : EMPTY_TEXT}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Description" span={3}>
                                        {clientDetail && clientDetail.intakeConsult ? clientDetail.intakeConsult.description : EMPTY_TEXT}
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Post Consultation Action Items" span={3}>
                                        <ul>
                                            {
                                                clientDetail &&
                                                clientDetail.intakeConsult &&
                                                clientDetail.intakeConsult.postConsultActions &&
                                                clientDetail.intakeConsult.postConsultActions.map((item, index) => {
                                                    return (
                                                        <li key={item._id}>
                                                            {item.description}
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </Descriptions.Item>
                                </Descriptions> :
                                <Empty
                                    description={
                                        <span>
                                            No intake consultation yet
                                        </span>
                                    }
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => {
                                            openCaseNoteModal(
                                                "edit",
                                                true,
                                            )
                                        }}
                                        icon={<PlusOutlined />}
                                    >
                                        Create Now
                                    </Button>
                                </Empty>
                        }
                    </Card>
                </Col>
                <Col xs={24}>
                    <Card className="h-full" title="Pre-Intake Questionnaire Results">
                        <Descriptions bordered>
                            <Descriptions.Item label="Role" span={2}>
                                {clientDetail.roles && clientDetail.roles.length > 0 ? RoleToStr(clientDetail.roles[0]) : EMPTY_TEXT}
                            </Descriptions.Item>
                            <Descriptions.Item label="Relationship" span={1}>
                                {clientDetail.survey ? RelationToStr(clientDetail.survey.incareRelation) : EMPTY_TEXT}
                            </Descriptions.Item>
                            <Descriptions.Item label="Name of person in care" span={3}>
                                {clientDetail.survey ? clientDetail.survey.incareFirstName + " " + clientDetail.survey.incareLastName : EMPTY_TEXT}
                            </Descriptions.Item>
                            <Descriptions.Item label="What we can help you with" span={3}>
                                {
                                    clientDetail.survey && clientDetail.survey.needs &&
                                    <ul className="list-disc list-inside pl-0">
                                        {
                                            Object.keys(clientDetail.survey.needs).reduce(function (filtered, question) {
                                                if (clientDetail.survey.needs[question]) {
                                                    filtered.push(
                                                        <li key={question}>
                                                            {SurveyQuestions[question]}
                                                        </li>);
                                                }
                                                return filtered;
                                            }, [])
                                        }
                                    </ul>
                                }
                            </Descriptions.Item>
                            <Descriptions.Item label="Relevant Information" span={3}>
                                {clientDetail.survey ? clientDetail.survey.needsTxt : EMPTY_TEXT}
                            </Descriptions.Item>
                            <Descriptions.Item label="Other Information" span={3}>
                                {clientDetail.survey ? clientDetail.survey.otherInfo : EMPTY_TEXT}
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                </Col>
            </Row>
            <Card className="mt-4" title="Contact Notes">
                <Row gutter={[16, 16]}>
                    {/* Workflow reminders */}
                    <Col xs={24}>
                        <Card type="inner" className="h-full" title="Workflow Reminders">
                            <List
                                itemLayout="horizontal"
                                dataSource={WorkflowItems}
                                renderItem={item => (
                                    <List.Item>
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                    {/* Backgrounds notes */}
                    <Col xs={24}>
                        <Card
                            type="inner"
                            className="h-full"
                            title="Background"
                            extra={
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        openNoteModal(
                                            "edit",
                                            "backgrounds",
                                            "",
                                            clientDetail._id,
                                            "",
                                            CCId,
                                        )
                                    }}
                                >
                                    Add
                                </Button>
                            }
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={clientDetail.backgrounds}
                                renderItem={item => (
                                    <List.Item
                                        actions={[
                                            <Button
                                                key="list-backgrounds-edit"
                                                type="link"
                                                onClick={() => {
                                                    openNoteModal(
                                                        "edit",
                                                        "backgrounds",
                                                        item.description,
                                                        clientDetail._id,
                                                        item._id,
                                                        CCId,
                                                    )
                                                }}
                                            >
                                                Edit
                                            </Button>,
                                            <Button
                                                key="list-backgrounds-delete"
                                                type="link"
                                                danger
                                                icon={<DeleteOutlined />}
                                                onClick={() => {
                                                    openNoteModal(
                                                        "delete",
                                                        "backgrounds",
                                                        item.description,
                                                        clientDetail._id,
                                                        item._id,
                                                        CCId,
                                                    )
                                                }}
                                            >
                                                Delete
                                            </Button>
                                        ]}
                                    >
                                        {item.description}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                    {/* Goal notes */}
                    <Col xs={24}>
                        <Collapse className="h-full">
                            <Collapse.Panel header="Collaboration Goals" key="cb">
                                <Card
                                    bordered={false}
                                    extra={
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={() => {
                                                openNoteModal(
                                                    "edit",
                                                    "goals",
                                                    "",
                                                    clientDetail._id,
                                                    "",
                                                    CCId,
                                                )
                                            }}
                                        >
                                            Add
                                        </Button>
                                    }
                                >
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={clientDetail.goals}
                                        renderItem={item => (
                                            <List.Item
                                                actions={[
                                                    <Button
                                                        key="list-goals-edit"
                                                        type="link"
                                                        onClick={() => {
                                                            openNoteModal(
                                                                "edit",
                                                                "goals",
                                                                item.description,
                                                                clientDetail._id,
                                                                item._id,
                                                                CCId,
                                                            )
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>,
                                                    <Button
                                                        key="list-goals-delete"
                                                        type="link"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            openNoteModal(
                                                                "delete",
                                                                "goals",
                                                                item.description,
                                                                clientDetail._id,
                                                                item._id,
                                                                CCId,
                                                            )
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                ]}
                                            >
                                                {item.description}
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                    {/* Scope of works */}
                    <Col xs={24}>
                        <Collapse className="h-full">
                            <Collapse.Panel header="Scope of Work" key="sow">
                                <Card
                                    bordered={false}
                                    extra={
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={() => {
                                                openNoteModal(
                                                    "edit",
                                                    "scopeOfWorks",
                                                    "",
                                                    clientDetail._id,
                                                    "",
                                                    CCId,
                                                )
                                            }}
                                        >
                                            Add
                                        </Button>
                                    }
                                >
                                    <List
                                        itemLayout="horizontal"
                                        dataSource={clientDetail.scopeOfWorks}
                                        renderItem={item => (
                                            <List.Item
                                                actions={[
                                                    <Button
                                                        key="list-scopeOfWorks-edit"
                                                        type="link"
                                                        onClick={() => {
                                                            openNoteModal(
                                                                "edit",
                                                                "scopeOfWorks",
                                                                item.description,
                                                                clientDetail._id,
                                                                item._id,
                                                                CCId,
                                                            )
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>,
                                                    <Button
                                                        key="list-scopeOfWorks-delete"
                                                        type="link"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            openNoteModal(
                                                                "delete",
                                                                "scopeOfWorks",
                                                                item.description,
                                                                clientDetail._id,
                                                                item._id,
                                                                CCId,
                                                            )
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                ]}
                                            >
                                                {item.description}
                                            </List.Item>
                                        )}
                                    />
                                </Card>
                            </Collapse.Panel>
                        </Collapse>
                    </Col>
                    {/* Case notes */}
                    <Col xs={24}>
                        <Card
                            type="inner"
                            className="h-full"
                            title="Case notes"
                            extra={
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                        openCaseNoteModal(
                                            "edit",
                                            false,
                                        )
                                    }}
                                    disabled={_.isEmpty(clientDetail.intakeConsult)}
                                >
                                    Add
                                </Button>
                            }
                        >
                            <CaseNoteTable
                                data={clientDetail.caseNotes}
                                actionFunc={openCaseNoteModal}
                            />
                            {/* <List
                                itemLayout="vertical"
                                dataSource={clientDetail.caseNotes}
                                renderItem={item => (

                                    <List.Item
                                        actions={
                                            [
                                                <Button
                                                    key="list-caseNotes-edit"
                                                    icon={<EditOutlined />}
                                                    type="primary"
                                                    onClick={() => {
                                                        openCaseNoteModal(
                                                            "edit",
                                                            false,
                                                            item
                                                        )
                                                    }}
                                                >
                                                    Edit
                                                </Button>,
                                                <Button
                                                    key="list-caseNotes-delete"
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    onClick={() => {
                                                        openCaseNoteModal(
                                                            "delete",
                                                            false,
                                                            item
                                                        )
                                                    }}
                                                >
                                                    Delete
                                                </Button>
                                            ]}>
                                        <List.Item.Meta
                                            title={item.subject}
                                            description={moment(item.consultDate).toLocaleString()}
                                        />
                                        {
                                            <Descriptions layout="horizontal" bordered>
                                                <Descriptions.Item label="Primary Care/Account Owner" span={3}>{clientDetail ? clientDetail.primaryCare : EMPTY_TEXT}</Descriptions.Item>
                                                <Descriptions.Item label="First Name" span={3}>{item.firstName}</Descriptions.Item>
                                                <Descriptions.Item label="Last Name" span={3}>{item.lastName}</Descriptions.Item>
                                                <Descriptions.Item label="Care Concierge" span={3}>
                                                    {item.consultCC ?
                                                        item.consultCC.firstName + " " + item.consultCC.lastName : " "}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Account Type">
                                                    <span>
                                                        <Tag color={SubscriptionToColor(item && item.accountType)}>
                                                            {SubscriptionToStr(item && item.accountType).toUpperCase()}
                                                        </Tag>
                                                    </span>
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Disposition" span={3}>
                                                    {DispositionCode[item.disposition]}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Description" span={3}>
                                                    {item.description}
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Post Consultation Action Items" span={3}>
                                                    <ul>
                                                        {
                                                            item.postConsultActions && item.postConsultActions.map((item, index) => {
                                                                return (
                                                                    <li key={item._id}>
                                                                        {item.description}
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </Descriptions.Item>
                                            </Descriptions>
                                        }
                                    </List.Item>
                                )}
                            /> */}
                        </Card>
                    </Col>
                    {/* Next step items */}
                    <Col xs={24}>
                        <Card type="inner" className="h-full" title="(Workflow) Next steps">
                            <List
                                itemLayout="horizontal"
                                dataSource={NextStepsItems}
                                renderItem={item => (
                                    <List.Item>
                                        {item}
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Col>
                </Row >
            </Card >
        </div >
    )
}

export default ClientDetail;