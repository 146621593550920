import React, { useState, useEffect, useCallback } from "react";
import ReactGA from "react-ga4";
import { useNavigate } from "react-router";

import {Helmet} from "react-helmet"; 

import { Col, Row, notification, Result } from "antd";
import { getTwoToneColor, setTwoToneColor } from "@ant-design/icons";
import { Navbar } from "../../components/Navbar";
import { PurpleDivider } from "../../components/Various";
import Footer from "../../components/Footer";
import FeedbackFormModal from "../../components/FeedbackFormModal";
import { Url, FormDestination } from "../../constants/Global";
import _ from "lodash";
import { mclOpen } from "../../api/Mcl";
import {
  MailTwoTone,
  MessageTwoTone,
  RightCircleTwoTone,
} from "@ant-design/icons";
import { Collapse, Layout } from "antd";
import wellnessContinuum from "../../assets/images/faq/care-and-wellness-continuum.jpg";
import "./Faq.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const { Header, Content } = Layout;
const { Panel } = Collapse;

const openNotification = ({ message, status, title, subTitle }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: <Result status={status} title={title} subTitle={subTitle} />,
    placement: "top",
    duration: 6,
  });
};

const Faq = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("needHelpForm");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const [faqSetting, setFaqSetting] = useState({
    title: "Feedback form",
    destination: "",
    isModalVisible: false,
    showFirstName: true,
    showLastName: true,
    showEmail: true,
    showPhone: true,
  });
  const [isSubmitting, setSubmitting] = useState(false);

  const submitForm = useCallback(
    async (formValues) => {
      ReactGA.event({
        category: 'faq',
        action: 'submit_form',
        label: 'faq_form'
      });
      let data = formValues;
      data["destination"] = faqSetting["destination"];
      data["fromQuestion"] = faqSetting["fromQuestion"];
      setSubmitting(true);
      let recaptchaToken = await handleReCaptchaVerify();
      if (!_.isEmpty(recaptchaToken)) {
        // Get the recaptcha token, submit it with the data to the backend
        mclOpen
          .post(Url.FORMS.concat("/", "faq"), data, {
            headers: { "Content-Type": "application/json" },
            params: {
              token: recaptchaToken,
            },
          })
          .then((res) => {
            console.log(res);
            if (res.status && res.status === 200) {
              openNotification({
                message: "Inquiry submitted",
                status: "success",
                title: "Thank you " + data.firstName + "!",
                subTitle: "Thank you for your precious feedback!",
              });
            } else {
              openNotification({
                message: "Sorry",
                status: res.status ? res.status : "500",
                title: res.response.data,
              });
            }
            setSubmitting(false);
            setFaqSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
            }));
          })
          .catch((error) => {
            console.log(error);
            openNotification({
              message: "Sorry",
              status: error.status ? error.status : "500",
              title: error.response.data,
            });
            setSubmitting(false);
          })
          .finally(() => {
            setSubmitting(false);
          });
      } else {
        openNotification({
          message: "Sorry",
          status: "500",
          title: "Sorry, please try again later",
          subTitle: "Failed to get Recaptcha token",
        });
        setSubmitting(false);
      }
    },
    [faqSetting, handleReCaptchaVerify]
  );

  setTwoToneColor("#8159f5");
  getTwoToneColor();
  const faqQStyle = {
    background: "rgba(221, 204, 238, 25%)",
    overflow: "hidden",
    marginBottom: 24,
    borderRadius: 4,
    border: 0,
    fontWeight: "bold",
    fontSize: "x-large",
    color: "#30225f",
  };
  const faqAStyle = {
    overflow: "hidden",
    marginBottom: 12,
    borderRadius: 4,
    padding: "0rem 4rem",
    border: 0,
    fontWeight: "normal",
    fontSize: "medium",
    color: "#30225f",
  };

  return (
    <main>
      <Helmet>
          <meta charSet="utf-8" />
          <title>My CareLinq - Frequently Asked Questions</title>
          <meta name="description" content="My CareLinq frequently asked questions." />
          <link rel="canonical" href="https://mycarelinq.com/faq" />
      </Helmet>
      <Navbar signin={true} navigate={navigate} />
      <FeedbackFormModal
        setting={faqSetting}
        isSubmitting={isSubmitting}
        submitForm={submitForm}
        handleCancel={() => {
          ReactGA.event({
            category: 'faq',
            action: 'close_modal',
            label: 'faq_form'
          });
          setFaqSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
      />
      <section
        className="bg-[url('assets/images/about-us/header-bg.png')]
                min-h-[2rem]
                max-h-[12rem]
                bg-center
                bg-no-repeat
                bg-cover
                overflow-hidden
                flex
            "
      >
        <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-normal">
          <Row>
            <Col xs={24}>
              <h1
                className="text-[4rem] text-white"
                style={{ textShadow: "3px 3px 7px rgb(0 0 0 / 65%)" }}
              >
                Frequently Asked Questions
              </h1>
            </Col>
          </Row>
        </div>
      </section>
      <PurpleDivider />
      <section>
        <Row justify="center">
          <Col xs={24} lg={20}>
            <Collapse
              ghost
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <RightCircleTwoTone
                  style={{ fontSize: "2rem" }}
                  rotate={isActive ? 90 : 0}
                />
              )}
              className="faq-collapse text-xl p-4"
            >
              <Panel
                header='1. Who qualifies as a "caregiver?"'
                key="1"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    A "caregiver" is anyone who provides help to another person
                    in need. Caregivers can be spouses, partners, children,
                    siblings, cousins, aunts, uncles, other family members,
                    friends, neighbors, co-workers, and anyone who wants to lend
                    a hand. It’s reported that 1 out of 3 adults fit this
                    definition.
                  </p>
                  <p>
                    Caregivers provide a wide variety of support and hands on
                    care. Caregivers provide emotional support, friendship, help
                    with errands, help with <i>activities of daily living</i>,
                    cook, clean, provide transportation, check-in, assist with
                    home maintenance, keeping records, planning for life events,
                    and implementing instructions or wishes
                  </p>
                </div>
              </Panel>
              <Panel
                header="2. What services does My CareLinq offer?"
                key="2"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    My CareLinq offers non-medical case management to caregivers
                    and self-managing adults. Our services cover a large portion
                    of supportive care across the care continuum.
                  </p>
                  <p>
                    My CareLinq supports caregivers and patients through all
                    stages of life regardless of age, location and disease.
                  </p>
                  <h2>Care and Wellness Continuum</h2>
                  <img
                    src={wellnessContinuum}
                    height="200"
                    weign="20"
                    className="faq-wellness-continuum"
                    alt=""
                  />
                  <p>
                    Our services are designed to identify needs, problem solve,
                    assist you with planning, curate strategy and tactics, find
                    and line up the right resources at the right time.
                  </p>
                </div>
              </Panel>
              <Panel
                header="3. What makes My CareLinq different from other services?"
                key="3"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    We acknowledge there are a lot of resources in our
                    communities and online. You can spend hours researching,
                    cross -referencing, pricing, making decisions, but you never
                    really know what service, price, or outcome will be
                    delivered. Advice is helpful, but we can do better. My
                    CareLinq offers a new approach.
                  </p>
                  <p>
                    My CareLinq integrates all the necessary tools and services
                    to create, document, plan, back-up, change, implement and
                    find what you need. No more notebooks, sticky notes, person
                    dependent knowledge or having to remember what that person
                    said.
                  </p>
                  <p>
                    Being a caregiver is really hard work. The personal and
                    professional environments are complex. We understand and
                    will work to support you to make your journey easier.
                  </p>
                </div>
              </Panel>
              <Panel
                header="4. I’ve already done some planning. Should I sign up for this?"
                key="4"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    First, it’s great that you’ve already done some planning.
                    There are many functional and emotional dimensions
                    associated with care, support and legacy planning. My
                    CareLinq is more than an Advanced Directive, Trust or Will
                    and we encourage you to sign-up and check out our tools and
                    services. Remember, this isn’t just about you. It’s about
                    the people around you that will form your care team in the
                    future.
                  </p>
                  <p>
                    My CareLinq can serve as an anchor for you, your care team
                    and survivors. Centralize documents, instructions, share
                    communications, coordinate services and provide a little
                    more peace to those who the matter the most.
                  </p>
                </div>
              </Panel>
              <Panel
                header="5. What is the benefit of My CareLinq's platform and services?"
                key="5"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    One comprehensive solution to meet your needs now and in the
                    future. Caregivers can recoup time and know that they have a
                    partner who they can turn to for help.
                  </p>
                  <p>
                    Families, close friends, communities can chip in, and all
                    participate and support care using next generation tools and
                    technology.
                  </p>
                  <p>
                    Independent adults (future care recipients) can plan and
                    provide for their own care, staying home & engaged with
                    loved ones longer with less burden.
                  </p>
                </div>
              </Panel>
              <Panel
                header="6. What is a Care Circle?"
                key="6"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    At My CareLinq, a “Care Circle” is a private, secure,
                    invite-only care and support collaboration space controlled
                    by the account owner. An authenticated caregiver who joins a
                    Care Circle is granted permission to view private,
                    protected, and proprietary content and communications, and
                    to collaborate with other members of the Care Circle or on
                    behalf of another individual. The account owner controls
                    permission levels, invites, and revocation.
                  </p>
                  <p>
                    There are many benefits creating a Care Circle for yourself
                    or for the person requiring care, including documenting
                    wishes, centralizing documents, creating plans, and leaving
                    instructions for care teams and survivors. Other benefits
                    include, sharing caregiving responsibilities and duties,
                    feeling less alone, coordinating services or help, and
                    established mechanism to ask for help or respite.
                  </p>
                  <p>
                    As circumstances change, members of the Care Circle may
                    change and all of that can be managed by the account holder.
                    By creating a Care Circle and a support network, caregivers
                    can find support and ease the stress and burden of their
                    caregiving duties and responsibilities.
                  </p>
                </div>
              </Panel>
              <Panel
                header="7. What does a Care Concierge do?"
                key="7"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    My CareLinq’s Care Concierges are experienced professionals
                    who have walked in a caregiver’s shoes. They have been
                    caregivers themselves and understand some of the challenges
                    that comes with caring for someone else no matter the age or
                    health status.
                  </p>
                  <p>
                    Our dedicated team will leverage their experiences and
                    knowledge of the care delivery system, community, and home
                    resources to bring more peace in your home.Their primary
                    duty is to assist and ensure communication and collaboration
                    between all caregivers and all of the people and
                    organizations that can help. They help caregivers to manage
                    healthcare, insurance, and other resources that can
                    alleviate burden and stresses. They can also guide
                    caregivers to potential sources of alternative funding to
                    close financial gaps, and help onboard and support
                    caregivers with the right tools and services that fit their
                    needs.
                  </p>
                  <p>
                    Read more about our team{" "}
                    <a href="./Team#care-concierge">here</a>.
                  </p>
                </div>
              </Panel>
              <Panel
                header="8. What to expect when meeting with a Care Concierge?"
                key="8"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    An initial meeting with your Care Concierge will be
                    scheduled based on your availability. Care consultations are
                    scheduled for 60 minutes via video conference. The goal of
                    the initial consultation is to establish rapport, identify
                    needs and create a plan.
                  </p>
                  <p>
                    If you choose to continue working with your Care Concierge,
                    you may need to upgrade your subscription. Your Care
                    Concierge will guide you and establish on-going meetings and
                    support strategies that fit your lifestyle and needs.
                  </p>
                </div>
              </Panel>
              <Panel
                header="9. How do I give feedback to My CareLinq?"
                key="9"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    As a new service, we welcome constructive feedback so that
                    we can improve our product and services for all of us.
                  </p>
                  <p>
                    Send feedback to My CareLinq, please click{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'send_feedback'
                        });
                        setFaqSetting({
                          title: "Send feedback",
                          destination: FormDestination.PRODUCT,
                          fromQuestion: "9",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>
                    .
                  </p>
                </div>
              </Panel>
              <Panel
                header="10. How do I request a feature or additional services?"
                key="10"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    Send us your product ideas, click here{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'send_product_ideas'
                        });
                        setFaqSetting({
                          title: "Send product ideas",
                          destination: FormDestination.PRODUCT,
                          fromQuestion: "10",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>
                    .
                  </p>
                  <p>
                    Soon we will publish a wish list and customers can vote
                    which features they want most.
                  </p>
                </div>
              </Panel>
              <Panel
                header="11. How does My CareLinq protect my data?"
                key="11"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    At My CareLinq, we take your privacy and the security of
                    your data seriously. Your privacy is important to us. Our
                    Privacy Policy is available at{" "}
                    <a href="https://mycarelinq.com/privacy-policy">
                      https://mycarelinq.com/privacy-policy
                    </a>{" "}
                    and describes how we collect, use, and disclose information
                    about you. Please read our Privacy Policy carefully so you
                    understand how we use and share information we collect about
                    you.
                  </p>
                  <p>
                    The My CareLinq platform is being built from the ground up
                    with security in mind. The My CareLinq platform will
                    incorporate end-to-end encryption and other industry
                    best-practices designed to protect and enable you to control
                    your data.
                  </p>
                </div>
              </Panel>
              <Panel
                header="12. What is End-to-end Encryption?"
                key="12"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    End-to-end Encryption (E2EE) is a method of secure
                    communications that prevents third parties from accessing
                    data while it’s transferred from one end system or device to
                    another. In E2EE, the data is encrypted on the sender’s
                    system or device, and the only intended recipient can
                    decrypt it. As the data travels to its destination, the
                    message cannot be read or tampered with by an internet
                    service provider (ISP), application service, hacker or any
                    other entity or service.
                  </p>
                  <p>
                    My CareLinq uses NIST FIPS 140-2 compliant AES256 encryption
                    for its end-to-end encryption capabilities.
                  </p>
                  <p>
                    My CareLinq's encryption features are not yet available and
                    are scheduled for release later in 2022.
                  </p>
                </div>
              </Panel>
              <Panel
                header="13. How does this work in practice?"
                key="13"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    When an account is created, a set of encryption keys are
                    created for you, the account owner. Encryption keys for
                    end-to-end encryption are derived from your devices and your
                    account password, which only you know. This means that the
                    system is designed so that only you can decrypt and access
                    your information, and only on trusted devices where you’re
                    signed in; no one else, not even My CareLinq, should be able
                    access your end-to-end encrypted data. You may choose to
                    invite others into the Care Circle you created on My
                    CareLinq. You may also share the records and documents with
                    others in the Care Circle. The system is designed such that
                    all of these records and documents are end-to-end encrypted
                    and only you can grant access to others. Because both your
                    device and your account password are used to generate
                    encryption keys, losing your device or forgetting your
                    password will mean that you cannot decrypt your data – My
                    CareLinq will not be able to recover your data if this
                    happens.
                  </p>
                  <p>
                    Please also review our
                    <a href="https://mycarelinq.com/privacy-policy">
                      Privacy Policy
                    </a>{" "}
                    statement.
                  </p>
                  <p>
                    Questions? Click here{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'send_privacy_question'
                        });
                        setFaqSetting({
                          title: "Send privacy question",
                          destination: FormDestination.PRIVACY,
                          fromQuestion: "13",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>{" "}
                    to send us a message.
                  </p>
                </div>
              </Panel>
              <Panel
                header="14. What if I lose my device or forget my password? How do I recover my encrypted data and reset my password?"
                key="14"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    My CareLinq, because it has designed the services with
                    end-to-end encryption, is not able to reset your password or
                    otherwise restore access to the data in your account if you
                    lose your encryption key.
                  </p>
                  <p>
                    We strongly suggest that you safely store your account
                    password outside the My CareLinq service. You may wish to
                    consider employing a digital secret keeping service to store
                    your encryption keys off the My CareLinq platform for safe
                    keeping. Certain digital secret keeping services employ
                    “circles of trust” and multi-factor authentication (MFA) to
                    help you restore the encryption keys if they are lost.
                  </p>
                  <p>
                    My CareLinq’s Vault service offers such independent key
                    storing and recovery that can help you in case of loss of
                    device or forgetting your password. My CareLinq’s Vault’s
                    service is not designed to be able to access the data within
                    the My CareLinq SaaS service. It is designed only to store
                    your encryption keys that are encrypted with your device’s
                    secret key.
                  </p>
                  <p>
                    You may also use an account recovery service that is not
                    provided by My CareLinq if you would prefer.
                  </p>
                  <p>
                    If a private key or password is lost, the reset and key
                    recovery process involves multi-factor authentication and
                    possible human intervention. Contact My Carelinq, click{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'send_help_request'
                        });
                        setFaqSetting({
                          title: "Send help request",
                          destination: FormDestination.HELP,
                          fromQuestion: "14",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>
                    .
                  </p>
                </div>
              </Panel>
              <Panel
                header="15. My CareLinq and HIPAA"
                key="15"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    My CareLinq’s Services are not available to parties acting
                    as Covered Entities, or Business Associates of Covered
                    Entities, as defined by HIPAA. When you use My CareLinq, you
                    are representing that you are not a Covered Entity or a
                    Business Associate of one, and you thus are not placing
                    Protected Health Information on My CareLinq.
                  </p>
                </div>
              </Panel>
              <Panel
                header="16. I can’t afford My CareLinq subscription. Do you offer discounts?"
                key="16"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    My CareLinq aims to support family caregivers no matter what
                    your financial status may be. If you are facing financial
                    hardship, click{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'contact_mycarelinq_16'
                        });
                        setFaqSetting({
                          title: "Contact MyCareLinq",
                          destination: FormDestination.QUESTIONS,
                          fromQuestion: "16",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>{" "}
                    to contact My CareLinq.
                  </p>
                </div>
              </Panel>
              <Panel
                header="17. How do I upgrade my account?"
                key="17"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    You can upgrade your subscription by logging into your
                    account, go to My Profile, and click on "Upgrade."
                  </p>
                  {/* TODO: Create a form for user to send us a message */}
                  <p>
                    Contact My CareLinq's billing, click here{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'contact_mycarelinq_billing_17'
                        });
                        setFaqSetting({
                          title: "Contact MyCareLinq's billing",
                          destination: FormDestination.BILLING,
                          fromQuestion: "17",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>
                    .
                  </p>
                </div>
              </Panel>
              <Panel
                header="18. How do I cancel my account?"
                key="18"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    You can cancel your subscription or downgrade your service
                    package at any time. Log into your account, go to My
                    Profile, click on subscription to cancel your subscription.
                  </p>
                  <p>
                    Contact My CareLinq's billing, click here{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'contact_mycarelinq_18'
                        });
                        setFaqSetting({
                          title: "Contact MyCareLinq's billing",
                          destination: FormDestination.BILLING,
                          fromQuestion: "18",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>
                    .
                  </p>
                </div>
              </Panel>
              <Panel
                header="19. How do I delete my account?"
                key="19"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    You can delete your account at any time. Please click here{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'account_delete_request'
                        });
                        setFaqSetting({
                          title: "Account delete request",
                          destination: FormDestination.QUESTIONS,
                          fromQuestion: "19",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>{" "}
                    to make the request. We will need to confirm your identity
                    to authenticate the request. Please note that even once you
                    request deletion, your data may still be kept for 90 days.
                    Also, My CareLinq will continue to keep copies of your data
                    as necessary to meet its legal and other record keeping
                    requirements.
                  </p>
                </div>
              </Panel>
              <Panel
                header="20. How can I donate or invest in My CareLinq?"
                key="20"
                style={faqQStyle}
              >
                <div style={faqAStyle}>
                  <p>
                    Yes. If you are interested in becoming an investor in My
                    CareLinq, please click{" "}
                    <span
                      onClick={() => {
                        ReactGA.event({
                          category: 'faq',
                          action: 'open_modal',
                          label: 'become_an_investor'
                        });
                        setFaqSetting({
                          title: "Become an investor",
                          destination: FormDestination.INFO,
                          fromQuestion: "20",
                          isModalVisible: true,
                          showFirstName: true,
                          showLastName: true,
                          showEmail: true,
                          showPhone: true,
                        });
                      }}
                    >
                      <MessageTwoTone twoToneColor={"#8159f5"} />
                    </span>{" "}
                    to contact My CareLinq.
                  </p>
                  <p>
                    If you would like to donate to our cause, you may do so on
                    our <a href="./Pricing">Pricing</a> page.
                  </p>
                </div>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </section>
      {/* <Row>
                <Col xs={24} style={{ padding: "0rem 8rem" }}>
                    <p className="text-center text-xl">Please subscribe to our e-mail list to receive product updates and news from My CareLinq. You can unsubscribe at any time. To subscribe, click here <span><MessageTwoTone twoToneColor={"#8159f5"} /></span>.</p>
                </Col>
            </Row> */}
      <Footer style={{ textAlign: "center" }}></Footer>≈{" "}
    </main>
  );
};

export default Faq;
