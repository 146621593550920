import React, { useState } from 'react'

import useMclAuth from '../../hooks/useMclAuth';
import useCg from '../../hooks/useCg';

import { Collapse, Radio, Space, Input, Divider, Checkbox, Tooltip, Skeleton } from 'antd';
import { CheckCircleFilled, QuestionCircleFilled } from '@ant-design/icons';
import { Care, Url, SAVE_TIME_PERIOD, categoryToPage } from '../../constants/Global'

import { debounce } from 'lodash'

import './GiverPlan.css'

const { Panel } = Collapse;
const { TextArea } = Input;

// const PanelState = ({ done, left }) => {
//     return (
//         <div><Space><>{done}</><CheckCircleFilled style={{ color: 'green' }} /><>{left}</><QuestionCircleFilled style={{ color: 'orange' }} /></Space></div>
//     )
// }

const PanelState = ({ plan, setPlan, section }) => {

    const or_arr = (accumulator, curr) => accumulator || curr;

    let done = 0
    let left = 0

    // console.log('before done:', done, 'left:', left)
    // console.log('done:', done)

    switch (section) {
        case 'c1':
            left = 6;
            done += plan[section]['q1'] || plan[section]['q1_txt'] !== '' ? 1 : 0
            // done += plan[section]['q2'] ? 1 : 0
            done += plan[section]['q2_txt'] !== '' ? 1 : 0
            done += plan[section]['q3_txt'] !== '' ? 1 : 0
            done += plan[section]['q4_txt'] !== '' ? 1 : 0
            done += plan[section]['q5_txt'] !== '' ? 1 : 0
            done += plan[section]['q6'].reduce(or_arr) ? 1 : 0
            break
        case 'c2':
            left = 5;
            done += plan[section]['q1_txt'] !== '' ? 1 : 0
            done += plan[section]['q2_txt'] !== '' ? 1 : 0
            done += plan[section]['q3_txt'] !== '' ? 1 : 0
            done += plan[section]['q4_txt'] !== '' ? 1 : 0
            done += plan[section]['q5_txt'] !== '' ? 1 : 0
            break
        case 'c3':
            left = 7;
            done += plan[section]['q1_txt'] !== '' ? 1 : 0
            done += plan[section]['q2_txt'] !== '' ? 1 : 0
            done += plan[section]['q3_txt'] !== '' ? 1 : 0
            done += plan[section]['q4_txt'] !== '' ? 1 : 0
            done += plan[section]['q5_txt'] !== '' ? 1 : 0
            done += plan[section]['q6_txt'] !== '' ? 1 : 0
            done += plan[section]['q7_txt'] !== '' ? 1 : 0
            break
        case 'c4':
            left = 7;
            done += plan[section]['q1_txt'] !== '' ? 1 : 0
            done += plan[section]['q2_txt'] !== '' ? 1 : 0
            done += plan[section]['q3_txt'] !== '' ? 1 : 0
            done += plan[section]['q4_txt'] !== '' ? 1 : 0
            done += plan[section]['q5_txt'] !== '' ? 1 : 0
            done += plan[section]['q6_txt'] !== '' ? 1 : 0
            done += plan[section]['q7_txt'] !== '' ? 1 : 0
            break
        case 'c5':
            left = 7;
            done += plan[section]['q1_txt'] !== '' ? 1 : 0
            done += plan[section]['q2_txt'] !== '' ? 1 : 0
            done += plan[section]['q3'] || plan[section]['q3_txt'] !== '' ? 1 : 0
            done += plan[section]['q4_txt'] !== '' ? 1 : 0
            done += plan[section]['q5a_txt'] !== '' || plan[section]['q5b_txt'] !== '' ? 1 : 0
            done += plan[section]['q6_txt'] !== '' ? 1 : 0
            done += plan[section]['q7_txt'] !== '' ? 1 : 0
            break
        case 'c6':
            left = 3;
            done += plan[section]['q1'].reduce(or_arr) || plan[section]['q1_txt'] !== '' ? 1 : 0
            done += plan[section]['q2'].reduce(or_arr) || plan[section]['q2_txt'] !== '' ? 1 : 0
            done += plan[section]['q3_txt'] !== '' ? 1 : 0
            break
        default:
            break
    }

    left -= done
    // console.log('after done:', done, 'left:', left)

    return (
        // <div><Space><>{done}</><CheckCircleFilled style={{ color: 'green' }} /><>{left}</><QuestionCircleFilled style={{ color: 'orange' }} /></Space></div>
        <div><Space><Tooltip title='Completed plan items'><>{done}</><> </><CheckCircleFilled style={{ color: 'green' }} /></Tooltip><> </><Tooltip title='Plan items for completion' placement="topRight" ><>{left}</><> </><QuestionCircleFilled style={{ color: 'orange' }} /></Tooltip></Space></div>
    )
}

const initialPlan = {
    'type': Care.Care.SELFCARE,
    'current': 1,
    'c1': {
        'q1': 0,
        'q1_txt': '',
        // 'q2': 0,
        // 'q3': 0,
        'q2_txt': '',
        'q3_txt': '',
        'q4_txt': '',
        'q5_txt': '',
        'q6': [false, false, false, false, false, false],
    },
    'c2': {
        'q1_txt': '',
        'q2_txt': '',
        'q3_txt': '',
        'q4_txt': '',
        'q5_txt': '',
    },
    'c3': {
        'q1_txt': '',
        'q2_txt': '',
        'q3_txt': '',
        'q4_txt': '',
        'q5_txt': '',
        'q6_txt': '',
        'q7_txt': '',
    },
    'c4': {
        'q1_txt': '',
        'q2_txt': '',
        'q3_txt': '',
        'q4_txt': '',
        'q5_txt': '',
        'q6_txt': '',
        'q7_txt': '',
    },
    'c5': {
        'q1_txt': '',
        'q2_txt': '',
        'q3': 0,
        'q3_txt': '',
        'q4_txt': '',
        'q5a_txt': '',
        'q5b_txt': '',
        'q6_txt': '',
        'q7_txt': '',
    },
    'c6': {
        'q1': [false, false, false],
        'q1_txt': '',
        'q2': [false, false, false, false],
        'q2_txt': '',
        'q3_txt': '',
        'q4': 0,
    }
};

export { initialPlan }

function AdultCare({ setPage }) {
    const mclAuth = useMclAuth()

    const { planSelf, setPlanSelf, panelCurrent, setPanelCurrent } = useCg()

    const [isSaving, setIsSaving] = useState(false)
    const [error, setError] = useState('')

    const savePlan = async (plan) => {
        // console.log('Saving....', plan)
        console.log('saving....')
        // plan.current = panelCurrent
        const planSelfStr = JSON.parse(JSON.stringify(plan));
        // console.log('saving to api!', mclAuth.defaults.baseURL)
        setIsSaving(true)
        mclAuth.post(Url.CAREGIVERS.concat('/plan'), planSelfStr)
            .then(res => {
                // console.log('response', res.data)
            })
            .catch(error => {
            })
        setIsSaving(false)
    }

    const timedSave = React.useRef(
        debounce(async (plan) => {
            savePlan(plan)
        }, SAVE_TIME_PERIOD)
    ).current;

    const onChange = (e, category, question) => {
        // console.log('checked:', e.target.value, 'category:', category, 'question:', question);
        let newValues = JSON.parse(JSON.stringify(planSelf));
        newValues[category][question] = e.target.value;
        // last worked on panel
        newValues.current = categoryToPage(category)
        setPlanSelf(newValues);
        timedSave(newValues)
        // console.log(JSON.stringify(planValues));
    };

    const onCheck = (e, category, question, idx) => {
        // console.log(`checked = ${e.target.checked}`);
        let newValues = JSON.parse(JSON.stringify(planSelf));
        newValues[category][question][idx] = e.target.checked;
        // last worked on panel
        newValues.current = categoryToPage(category)
        setPlanSelf(newValues);
        timedSave(newValues)
    };

    const onChangeNav = (e, category, question) => {
        onChange(e, category, question);
        if (category === 'c6' && question === 'q4' && e.target.value === 1) {
          // open recipient plan
          setPage('2')
        }
      }
    
    const collapseChange = (key) => {
        if (key !== undefined) {
            // console.log('saving:', key)
            setPanelCurrent(key)
        }
    };

    if (planSelf === undefined) {
        return <Skeleton active />
    }

    return (
        <>
            <h1>My Life Care Journal</h1>
            <div className='panel-item'>
                <p />

                <h4>Welcome to your private corner of My CareLinq.  Here you will find a supportive space and guide to explore, write, reflect on your goals, wishes, support team and much more .
                </h4>
                <p></p>
                <h4>Below are some prompts to help you begin documenting your thoughts and experience.
                    Try to tune into your experience, motives, and curiosities and move through those feelings as well as you can. If these topics bring up trauma from an earlier part of your life or make you feel overwhelmed, refocus on your breath and revisit at a later time.
                </h4>
                <h4>
                    We encourage our users to explore rich and meaningful opportunities to engage with certain topics and people so that your future caregivers and support networks can, in turn, best support you and carry out your wishes.
                </h4>

                <h4>
                    <Tooltip /* arrowPointAtCenter={true} placement="right"*/ color={'blue'} title={
                        <div>
                            Clarify thoughts and feelings<br />
                            Gain perspective on issues and problems<br />
                            Process experiences<br />
                            Capture insights<br />
                            Explore your intuition<br />
                            Bring closure to relationships and events<br />
                            Record learning<br />
                            Reduce stress, anger and fear<br />
                        </div>

                    }>
                        <span style={{ color: 'blue' }}>The benefits of journaling</span>
                    </Tooltip>

                </h4>
                <h4>
                    This is for your eyes only.  If you have any questions or need support, please give us a call to schedule an appointment.
                </h4>

            </div>
            <Collapse onChange={collapseChange} accordion={true} defaultActiveKey={panelCurrent} >
                <Panel header="Your current situation" key="1" style={{ fontWeight: '600' }}
                    extra={<PanelState plan={planSelf} setPlan={setPlanSelf} section='c1' />}>
                    <div className='panel-item'>
                        <p>
                            Your background, lived experiences and current circumstances are important reference points as you begin to process what could be in the future.  Whatever has led you to explore this path of preparing for care, it is our hope that you will find this experience worthwhile.
                        </p>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>1.)</div>What brought you to My CareLinq?
                        <p />
                        <div className='panel-subitem'>
                            <Radio.Group size='large' onChange={(e) => onChange(e, 'c1', 'q1')} value={planSelf.c1.q1}>
                                <p><Radio value={0} style={{ display: 'none' }}>None</Radio></p>
                                <p><Radio value={1}>
                                    I’ve had a health related event and need to start planning for care and support
                                </Radio>
                                </p>
                                <p><Radio value={2}>
                                    I’m at a place in my life where I’m motivated to start thinking about my care needs
                                </Radio>
                                </p>
                                <p><Radio value={3}>I’ve witnessed others around me experience a loss</Radio></p>
                                <p><Radio value={4}>
                                    I want to make my wishes known to my future care team
                                </Radio>
                                </p>
                            </Radio.Group>
                            <p />
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c1', 'q1_txt')} value={planSelf.c1.q1_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>2.)</div>Does anything worry you about the days ahead?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c1', 'q2_txt')} value={planSelf.c1?.q2_txt || ""} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>3.)</div>Do you have any concerns about any family issues?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c1', 'q3_txt')} value={planSelf.c1.q3_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>4.)</div>Are there any regrets that trouble you?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c1', 'q4_txt')} value={planSelf.c1.q4_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>5.)</div>How would you like to spend your time now?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c1', 'q5_txt')} value={planSelf.c1.q5_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>6.)</div>Have you thought about any of these topics in the past year? (check all that apply)
                        <p />
                        <div className='panel-subitem'>
                            <Checkbox onChange={(e) => onCheck(e, 'c1', 'q6', 0)} defaultChecked={planSelf.c1.q6[0]}>
                                Life-sustaining wishes and/or DNR
                            </Checkbox>;
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c1', 'q6', 1)} defaultChecked={planSelf.c1.q6[1]}>
                                Long Term Care Placement into senior living such as ILF, ALF, or nursing home
                            </Checkbox>;
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c1', 'q6', 2)} defaultChecked={planSelf.c1.q6[2]}>
                                Aging in the home
                            </Checkbox>;
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c1', 'q6', 3)} defaultChecked={planSelf.c1.q6[3]}>
                                End-of-life care
                            </Checkbox>;
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c1', 'q6', 4)} defaultChecked={planSelf.c1.q6[4]}>
                                Final arrangements
                            </Checkbox>;
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c1', 'q6', 5)} defaultChecked={planSelf.c1.q6[5]}>
                                None of these
                            </Checkbox>;
                            <p />
                        </div>
                    </div>

                </Panel>
                <Panel header="Your Health and Self-care" key="2" style={{ fontWeight: '600' }}
                    extra={<PanelState plan={planSelf} setPlan={setPlanSelf} section='c2' />}>

                    <div className='panel-item'>
                        <p>
                            There are many ways to think about health and wellness. Every person’s process is unique, regardless of how the signs and symptoms appear.
                        </p>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>1.)</div>What activities do you love and what do you enjoy about them?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c2', 'q1_txt')} value={planSelf.c2.q1_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>2.)</div>How would you characterize your physical health?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c2', 'q2_txt')} value={planSelf.c2.q2_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>3.)</div>How would you characterize your mental health and overall wellness?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c2', 'q3_txt')} value={planSelf.c2.q3_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>4.)</div>Have you experienced any changes in the last year?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c2', 'q4_txt')} value={planSelf.c2.q4_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>5.)</div>What worries you about your health and well-being?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c2', 'q5_txt')} value={planSelf.c2.q5_txt} />
                        </div>
                    </div>

                </Panel>
                <Panel header="Your wishes" key="3" style={{ fontWeight: '600' }}
                    extra={<PanelState plan={planSelf} setPlan={setPlanSelf} section='c3' />}>

                    <div className='panel-item'>
                        <p>
                            As life events unfold and circumstances change, it’s important to take the time to document and update how you want to spend your time.  We encourage our users to reflect and explore these topics.
                        </p>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>1.)</div>What patterns have you noticed in your life?  Over the last year?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c3', 'q1_txt')} value={planSelf.c3.q1_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>2.)</div>What values are important to you?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c3', 'q2_txt')} value={planSelf.c3.q2_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>3.)</div>How can we honor your culture or faith?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c3', 'q3_txt')} value={planSelf.c3.q3_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>4.)</div>Does anything frighten you about dying?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c3', 'q4_txt')} value={planSelf.c3.q4_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>5.)</div>What wishes do you have for the last days?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c3', 'q5_txt')} value={planSelf.c3.q5_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>6.)</div>What do you think happens after death?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c3', 'q6_txt')} value={planSelf.c3.q6_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>7.)</div>What kind of after-death care do you want?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c3', 'q7_txt')} value={planSelf.c3.q7_txt} />
                        </div>
                    </div>

                </Panel>
                <Panel header="Your legacy" key="4" style={{ fontWeight: '600' }}
                    extra={<PanelState plan={planSelf} setPlan={setPlanSelf} section='c4' />}>

                    <div className='panel-item'>
                        <p>
                        At the heart of this work we are exploring life's meaning and your legacy.  Questions of meaning are natural and can lead to other opportunities to engage with the people you care about and open doors to legacy work and projects.
                        </p>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>1.)</div>What are your identities, privileges, and positions of power  you’ve held or hold today?
                        <p />
                        <div className='panel-subitem'>
                            Record thoughts here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c4', 'q1_txt')} value={planSelf.c4.q1_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>2.)</div>What major events have most impacted your life and how?
                        <p />
                        <div className='panel-subitem'>
                            Record thoughts here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c4', 'q2_txt')} value={planSelf.c4.q2_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>3.)</div>Is there someone who has been of great importance to you?
                        <div className='panel-subitem'>
                            <p />
                            Record thoughts here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c4', 'q6_txt')} value={planSelf.c4?.q6_txt || ""} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>4.)</div>What motivated you today?
                        <div className='panel-subitem'>
                            <p />
                            Record thoughts here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c4', 'q3_txt')} value={planSelf.c4.q3_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>5.)</div>What emotional or physical patterns of behavior have you notices in your life?
                        <p />
                        <div className='panel-subitem'>
                            Record thoughts here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c4', 'q4_txt')} value={planSelf.c4.q4_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>6.)</div>How do you wish to be remembered?  How do you think others will remember you?
                        <div className='panel-subitem'>
                            <p />
                            Record thoughts here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c4', 'q7_txt')} value={planSelf.c4?.q7_txt || ""} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>7.)</div>List any questions or topics you have about legacy and meaning
                        <p />
                        <div className='panel-subitem'>
                            Record thoughts here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c4', 'q5_txt')} value={planSelf.c4.q5_txt} />
                        </div>
                    </div>

                </Panel>
                <Panel header="Your care and support team" key="5" style={{ fontWeight: '600' }}
                    extra={<PanelState plan={planSelf} setPlan={setPlanSelf} section='c5' />}>

                    <div className='panel-item'>
                        <p>
                            Choosing a care and support team is a personal decision.  We encourage you to select your caregivers based on their skill sets and strengths.  Care team members do not need to live nearby or have time to add value.  People with limited time can pitch in behind the scenes.
                        </p>
                        <p>
                            Pulling together a supportive team can help deal with issues that emerge.
                        </p>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>1.)</div>What characteristics come to mind that would be valuable as a team member?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q1_txt')} value={planSelf.c5.q1_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>2.)</div>What skill sets are important to have as a team member?
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q2_txt')} value={planSelf.c5.q2_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>3.)</div>How does geographic proximity to others influence your decision making?
                        <p />
                        <div className='panel-subitem'>
                            <Radio.Group size='large' onChange={(e) => onChange(e, 'c5', 'q3')} value={planSelf.c5.q3}>
                                <p><Radio value={0} style={{ display: 'none' }}>None</Radio></p>
                                <p><Radio value={1}>Very important</Radio></p>
                                <p><Radio value={2}>Somewhat important</Radio></p>
                                <p><Radio value={3}>Not important</Radio></p>
                                <p><Radio value={4}>Other</Radio></p>
                            </Radio.Group>
                            <p />
                            <p />
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q3_txt')} value={planSelf.c5.q3_txt} />
                            <p />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>4.)</div>The composition of your care and support team can be broad. If you have paid caregiver(s), licensed medical staff, lawyers, financial advisors or other specialists involved in the management of your well-being, we encourage you to think about including them in your care and support team.
                        <p />
                        <div className='panel-subitem'>
                            Record your thoughts here...
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q4_txt')} value={planSelf.c5.q4_txt} />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>5.)</div>List any people that come to mind.  This is just a brainstorming list.
                        <p />
                        <div className='panel-subitem'>
                            Once you have selected a team, you have to identify them in your care and support plan, here....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q5a_txt')} value={planSelf.c5.q5a_txt} />
                            <p />
                            <p />
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q5b_txt')} value={planSelf.c5.q5b_txt} />
                            <p />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p />
                        <div className='panel-item-no'>6.)</div>There may come a point when you are no longer able to manage, update, or implement your plan.  Sharing your care  plan is a critical thought exercise to ensure your wishes, instructions, and care for your survivors is what you intend.  Describe what you require to share or hand-off your plan?
                        <p />
                        <p />
                        <div className='panel-subitem'>
                            Please explain...
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q6_txt')} value={planSelf.c5.q6_txt} />
                            <p />
                        </div>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>7.)</div>What concerns, worries, or emotions come up for you when thinking about transitioning your care and support plan to a caregiver?
                        <p />
                        <div className='panel-subitem'>
                            Please explain...
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c5', 'q7_txt')} value={planSelf.c5.q7_txt} />
                            <p />
                        </div>
                    </div>


                </Panel>
                <Panel header="Getting Organized" key="6" style={{ fontWeight: '600' }}
                    extra={<PanelState plan={planSelf} setPlan={setPlanSelf} section='c6' />}>
                    <div className='panel-item'>
                        <p style={{ fontWeight: 'normal' }}>
                            Caregivers face a number of responsibilities and duties. If you are feeling overwhelmed and lost, creating a structure and getting organized is a great first step to seeking relief. My CareLinq offers organizational tools that can facilitate sharing and documenting what you want.
                        </p>
                    </div>

                    <div className='panel-item'>
                        <p/>
                        <div className='panel-item-no'>1.)</div>How do you organize your caregiving tasks, documents, and resources? (i.e. calendars, alerts, tasklist)
                        <p />
                        <div className='panel-subitem'>
                            <Checkbox onChange={(e) => onCheck(e, 'c6', 'q1', 0)} defaultChecked={planSelf.c6.q1[0]}>
                                I  keep track of everything in my head
                            </Checkbox>
                            <p />
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c6', 'q1', 1)} defaultChecked={planSelf.c6.q1[1]}>
                                I keep a separate tracking document
                            </Checkbox>
                            <p />
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c6', 'q1', 2)} defaultChecked={planSelf.c6.q1[2]}>
                                I use digital tools to track and
                            </Checkbox>
                            <p />
                            <p />
                            Other:
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c6', 'q1_txt')} value={planSelf.c6.q1_txt} />
                            <p />
                        </div>
                    </div>

                    <div className='panel-subitem'>
                        <p/>
                        <div className='panel-item-no'>2.)</div>What tools would make this process easier for you?
                        <p />
                        <div className='panel-subitem'>
                            <Checkbox onChange={(e) => onCheck(e, 'c6', 'q2', 0)} defaultChecked={planSelf.c6.q2[0]}>
                                Coordinating communications across family, friends, community, medical staff, providers
                            </Checkbox>
                            <p />
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c6', 'q2', 1)} defaultChecked={planSelf.c6.q2[1]}>
                                Sending service requests directly to providers
                            </Checkbox>
                            <p />
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c6', 'q2', 2)} defaultChecked={planSelf.c6.q2[2]}>
                                Directing purchasing
                            </Checkbox>
                            <p />
                            <p />
                            <Checkbox onChange={(e) => onCheck(e, 'c6', 'q2', 3)} defaultChecked={planSelf.c6.q2[2]}>
                                Money management tools
                            </Checkbox>
                            <p />
                            <p />
                            Other:
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c6', 'q2_txt')} value={planSelf.c6.q2_txt} />
                            <p />
                        </div>
                    </div>

                    <div className='panel-subitem'>
                        <p />
                        <div className='panel-item-no'>3.)</div>If planning was easy, what would that look like?
                        <p />
                        <p />
                        <div className='panel-subitem'>
                            Please explain....
                            <TextArea showCount maxLength={255} onChange={(e) => onChange(e, 'c6', 'q3_txt')} value={planSelf.c6.q3_txt} />
                            <p />
                        </div>
                    </div>

                    <div className="panel-item">
                        <p />
                        <div className="panel-item-no">4.)</div>My CareLinq has provided you
                        with a care and support plan template designed for caregivers who
                        are managing other people’s care and support. Are you ready to check
                        it out?
                        <p />
                        <div className="panel-subitem">
                        <Radio.Group
                            size="large"
                            onChange={(e) => onChangeNav(e, "c6", "q4")}
                            value={planSelf.c6?.q4 || 0}
                        >
                            <p>
                            <Radio value={0} style={{ display: "none" }}>
                                None
                            </Radio>
                            </p>
                            <p>
                            <Radio value={1}>Yes, take me there</Radio>
                            </p>
                            <p>
                            <Radio value={2}>
                                I have some questions before getting started{" "}
                            </Radio>
                            </p>
                            <p>
                            <Radio value={3}>No, I’m not ready</Radio>
                            </p>
                            <p>
                            <Radio value={4}>
                                I’m not interested in using the care plan template
                            </Radio>
                            </p>
                        </Radio.Group>
                        <p />
                        </div>
                    </div>
                </Panel>
            </Collapse >
        </>
    )
}

export default AdultCare