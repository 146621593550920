import React from "react";
import { useNavigate } from "react-router";
import { Col, Row, Space, Typography, Divider, Empty } from 'antd';
import ArticleCard from './ArticleCard';
import VideoCard from './VideoCard';

const LibraryArticleSection = ({ articles }) => {

    return (
        <section>
            <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                {articles.length > 0 ? articles.map((article, index) =>
                    <ArticleCard
                        key={index}
                        article={article}
                    />) : (
                    <Empty
                        className="mt-16"
                        description={"No articles found in this category"}
                    >
                    </Empty>
                )
                }
            </Space>
        </section>
    )
}

const LibraryVideoSection = ({ videos }) => {

    const navigate = useNavigate();

    return (
        <section>
            <Row gutter={[16, 16]}>
                {videos.length > 0 ? videos.map((video, index) =>
                    <Col xs={24} sm={12} md={8} lg={6}  key={video.url}>
                        <VideoCard
                            key={index}
                            video={video}
                            navigate={navigate}
                        />
                    </Col>
                ) : (
                    <Col span={24}>
                        <Empty
                            className="mt-16"
                            description={"No video found in this category"}
                        >
                        </Empty>
                    </Col>
                )
                }
            </Row>
        </section>
    )
}

export { LibraryArticleSection, LibraryVideoSection };