import React from "react";
import { Card, Row, Col } from "antd";

import clientsIcon from "../../assets/images/cc/myClients.svg";
import referralsIcon from "../../assets/images/cc/referrals.svg";
import actionIcon from "../../assets/images/cc/actionItems.svg";
// import templatesIcon from "../../assets/images/cards/templates_tools.png";
import blogIcon from "../../assets/images/cc/blog.svg";
import calendarIcon from "../../assets/images/cc/calendar.svg";
import kbIcon from "../../assets/images/cc/kb.svg";
import chatIcon from "../../assets/images/cc/chat.svg";

import "./Dashboard.css";

const { Meta } = Card;

const Dashboard = ({ setPage, getLabel }) => {
  return (
    <>
      <Row>
        <Col flex={1}>
          <div onClick={() => setPage("myClients")}>
            <Card
              className="dash-card"
              hoverable
              style={{
                width: 200,
                height: 290,
              }}
              cover={<img alt="My Client List" src={clientsIcon} />}
            >
              <Meta
                title={getLabel("myClients")}
                description="Clients under your management"
              />
            </Card>
          </div>
        </Col>
        <Col flex={1}>
          <div onClick={() => setPage("referrals")}>
            <Card
              className="dash-card"
              hoverable
              style={{
                width: 200,
                height: 290,
              }}
              cover={<img alt="Referrals" src={referralsIcon} />}
            >
              <Meta
                title={getLabel("referrals")}
                description="Referrals from providers and partners"
              />
            </Card>
          </div>
        </Col>
        <Col flex={1}>
          <div onClick={() => setPage("action")}>
            <Card
              className="dash-card"
              hoverable
              style={{
                width: 200,
                height: 290,
              }}
              cover={<img alt="Action Items" src={actionIcon} />}
            >
              <Meta
                title={getLabel("action")}
                description="Your outstanding action items"
              />
            </Card>
          </div>
        </Col>
      </Row>
      <Row style={{ height: 50 }} />
      <Row>
        <Col flex={1}>
          <div onClick={() => setPage("blog")}>
            <Card
              className="dash-card"
              hoverable
              style={{
                width: 200,
                height: 290,
              }}
              cover={<img alt="Templates" src={blogIcon} />}
            >
              <Meta
                title={getLabel("blog")}
                // description="Tools and templates library"
                description="Library Content Management"
              />
            </Card>
          </div>
        </Col>
        <Col flex={1}>
          <div onClick={() => setPage("kb")}>
            <Card
              className="dash-card"
              hoverable
              style={{
                width: 200,
                height: 290,
              }}
              cover={<img alt="Knowledge Base" src={kbIcon} />}
            >
              <Meta
                title={getLabel("kb")}
                description="Concierge resources &amp; knowledge base items"
              />
            </Card>
          </div>
        </Col>
        <Col flex={1}>
          <div onClick={() => setPage("calendar")}>
            <Card
              className="dash-card"
              hoverable
              style={{
                width: 200,
                height: 290,
              }}
              cover={<img alt="Calendar" src={calendarIcon} />}
            >
              <Meta title={getLabel("calendar")} description="Your calendar" />
            </Card>
          </div>
        </Col>
        <Col flex={1}>
          <div onClick={() => setPage("chat")}>
            <Card
              className="dash-card"
              hoverable
              style={{
                width: 200,
                height: 290,
              }}
              cover={<img alt="Messaging" src={chatIcon} />}
            >
              <Meta title={getLabel("chat")} description="Chat with Team" />
            </Card>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Dashboard;
