import React, { useState, useEffect, useCallback } from "react";
import {
  Col,
  Row,
  notification,
  Result,
  Form,
  Button,
  Card,
  message,
} from "antd";
import { Url } from "../../constants/Global";
import _ from "lodash";
import useMclAuth from "../../hooks/useMclAuth";
import {
  PlusOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  EditOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";

import {
  KnowledgeTable,
  KnowledgeBaseShareModal,
  KnowledgeBaseModal,
  KnowledgeBaseDeleteModal,
  KnowledgeBaseDetail,
} from "../../components/KBComponents";

import { openNotification } from "../../components/Various";

const KnowledgeBase = ({
  setPage,
  cg,
  circleId = "",
  circleName = ["", ""],
}) => {
  const [createModalSetting, setCreateModalSetting] = useState({});
  const [editModalsetting, setEditModalSetting] = useState({});
  const [shareModalsetting, setShareModalSetting] = useState({});
  const [deleteModalsetting, setDeleteModalSetting] = useState({});
  const mclAuth = useMclAuth();
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [currentKnowledge, setCurrentKnowledge] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedKnowledge, setSelectedKnowledge] = useState([]);
  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [shareForm] = Form.useForm();

  const createKnowledge = useCallback(
    async (values) => {
      if (_.isEmpty(circleId)) {
        return;
      }
      const data = _.pick(values, [
        "address",
        "category",
        "description",
        "email",
        "keywords",
        "link",
        "local",
        "name",
        "national",
        "phone",
        "pricing",
        "shareCircle",
        "shareCC",
        "specialty1",
        "specialty2",
        "state",
      ]);

      if (_.isEmpty(circleId)) {
        openNotification({
          message: "Error",
          status: "500",
          title: "No circle found",
        });
        return;
      }

      data["circleId"] = circleId;
      data["createdByCC"] = false;
      setSubmitting(true);
      mclAuth
        .post(Url.KNOWLEDGE_BASE.concat("/"), data, {
          headers: { "Content-Type": "application/json" },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            openNotification({
              message: "Succeed!",
              status: "success",
              title: "Successfully created a new knowledge",
            });
            setCreateModalSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
            }));
            setKnowledgeData([...knowledgeData, res.data]);
          } else {
            openNotification({
              message: "Sorry",
              status: res.status ? res.status : "500",
              title: res.response.data,
            });
          }
          setSubmitting(false);
        })
        .catch((error) => {
          openNotification({
            message: "Sorry",
            status: error.status ? error.status : "500",
            title: error.response.data,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [circleId, mclAuth, createForm, knowledgeData]
  );

  const editKnowledge = useCallback(
    async (targetKnowledge, values, onFinished = () => {}) => {
      if (_.isEmpty(circleId)) {
        return;
      }
      try {
        setSubmitting(true);
        let updateResults = [];
        for (let i in targetKnowledge) {
          // Check changes before submit
          // const changes = _.differenceWith(_.toPairs(_.pick(targetKnowledge[i], Object.keys(values))), _.toPairs(values), _.isEqual)
          // if (_.isEmpty(_.fromPairs(changes))) {
          //   continue;
          // }
          let res = await mclAuth.put(
            Url.KNOWLEDGE_BASE.concat("/knowledge/", targetKnowledge[i]._id),
            {
              circleId: targetKnowledge[i].circleId,
              ...values,
            },
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (res.status && res.status === 200) {
            updateResults.push(res.data);
          }
        }

        // Update new knowledge data
        openNotification({
          message: "Succeed!",
          status: "success",
          title: "Successfully update the selected knowledge",
        });

        // Start to compare and update
        let newKnowledgeArr = [];
        // newKnowledgeArr = knowledgeData.map(item => {
        //   const newItem = updateResults.find(newItem => newItem._id === item._id);
        //   return newItem ? { ...item, ...newItem } : item;
        // });

        for (let index in knowledgeData) {
          let item = knowledgeData[index];
          let newItem = updateResults.find(
            (newItem) => newItem._id === item._id
          );
          newKnowledgeArr.push(newItem ? { ...item, ...newItem } : item);
        }

        onFinished();
        setKnowledgeData(newKnowledgeArr);
        setSubmitting(false);
      } catch (err) {
        console.error(err);
        openNotification({
          message: "Error!",
          status: "500",
          title: "Failed to update the selected knowledge",
        });
        setSubmitting(false);
      }
    },
    [circleId, mclAuth, knowledgeData]
  );

  const deleteKnowledge = useCallback(
    async (targetKnowledge, onFinished = () => {}) => {
      if (_.isEmpty(circleId)) {
        return;
      }
      try {
        setSubmitting(true);
        let updateResults = [];
        for (let i in targetKnowledge) {
          let res = await mclAuth.delete(
            Url.KNOWLEDGE_BASE.concat(
              "/knowledge/",
              targetKnowledge[i].circleId,
              "/",
              targetKnowledge[i]._id
            ),
            {
              headers: { "Content-Type": "application/json" },
            }
          );
          if (res.status && res.status === 200) {
            updateResults.push(res.data);
          }
        }

        // Update new knowledge data
        openNotification({
          message: "Succeed!",
          status: "success",
          title: "Successfully delete the selected knowledge",
        });

        // Start to compare and update, remove item from data if it is successfully deleted
        let newKnowledgeArr = knowledgeData.filter((item) => {
          const found = updateResults.find(
            (newItem) => newItem._id === item._id
          );
          return _.isNil(found);
        });

        setKnowledgeData(newKnowledgeArr);
        setSelectedKnowledge([]);
        setCurrentKnowledge(undefined);
        onFinished();
        setSubmitting(false);
      } catch (err) {
        console.error(err);
        openNotification({
          message: "Error!",
          status: "500",
          title: "Failed to delete the selected knowledge ",
        });
        setSubmitting(false);
      }
    },
    [circleId, mclAuth, knowledgeData]
  );

  useEffect(() => {
    // Get knoweledge of the circle
    if (!_.isEmpty(circleId)) {
      setIsLoading(true);
      mclAuth
        .get(Url.KNOWLEDGE_BASE.concat("/", circleId))
        .then((res) => {
          setKnowledgeData(res.data);
          setIsLoading(false);
        })
        .catch((error) => {
          openNotification({
            message: error.message,
            status: "500",
            title: "500",
            subTitle: "Failed to get knowledge data",
          });
          setIsLoading(false);
        });
    }
  }, [circleId, mclAuth]);

  useEffect(() => {
    if (!_.isEmpty(shareModalsetting) && !_.isNil(shareForm)) {
      shareForm.resetFields();
    }
  }, [shareForm, shareModalsetting]);

  useEffect(() => {
    if (!_.isEmpty(editModalsetting) && !_.isNil(editForm)) {
      editForm.resetFields();
    }
  }, [editForm, editModalsetting]);

  useEffect(() => {
    if (!_.isEmpty(createModalSetting) && !_.isNil(createForm)) {
      createForm.resetFields();
    }
  }, [createForm, createModalSetting]);

  return (
    <div className="p-6">
      <h1
        style={{
          fontSize: "large",
          fontWeight: "bold",
          color: "#30225f",
        }}
      >
        Resource Library for{" "}
        <strong>{circleName[0] + " " + circleName[1]}</strong>'s Care
      </h1>
      {/* Create */}
      <KnowledgeBaseModal
        title="Create new knowledge"
        setting={createModalSetting}
        handleCancel={() =>
          setCreateModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }))
        }
        submitForm={createKnowledge}
        isSubmitting={isSubmitting}
        form={createForm}
      />
      {/* Edit */}
      <KnowledgeBaseModal
        title="Edit knowledge"
        setting={editModalsetting}
        handleCancel={() =>
          setEditModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }))
        }
        submitForm={(target, value) => {
          editKnowledge(
            target,
            value,
            setEditModalSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
              initialValues: {
                national: !_.isNil(value.national) ? value.national : true,
                shareCircle: !_.isNil(value.shareCircle)
                  ? value.shareCircle
                  : false,
                shareCC: !_.isNil(value.shareCC) ? value.shareCC : false,
              },
            }))
          );
        }}
        isSubmitting={isSubmitting}
        form={editForm}
      />
      <KnowledgeBaseShareModal
        setting={shareModalsetting}
        handleCancel={() => {
          setShareModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
          shareForm.resetFields();
        }}
        submitForm={(target, value) => {
          editKnowledge(
            target,
            value,
            setShareModalSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
              shareCircle: !_.isNil(value.shareCircle)
                ? value.shareCircle
                : false,
              shareCC: !_.isNil(value.shareCC) ? value.shareCC : false,
            }))
          );
        }}
        isSubmitting={isSubmitting}
        form={shareForm}
      />
      <KnowledgeBaseDeleteModal
        setting={deleteModalsetting}
        handleCancel={() => {
          setDeleteModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }));
        }}
        submit={(target) => {
          deleteKnowledge(
            target,
            setDeleteModalSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
            }))
          );
        }}
        isSubmitting={isSubmitting}
      />
      {_.isNil(currentKnowledge) ? (
        <Card
          className="mt-4"
          extra={[
            <Button
              key="delete"
              icon={<DeleteOutlined />}
              onClick={() => {
                if (
                  _.isArray(selectedKnowledge) &&
                  selectedKnowledge.length > 0
                ) {
                  setDeleteModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                    selectedKnowledge: selectedKnowledge,
                  }));
                } else {
                  message.warning("Please select a knowledge", 5);
                }
              }}
              className="mr-4"
            >
              Delete
            </Button>,
            <Button
              key="share"
              icon={<ShareAltOutlined />}
              onClick={() => {
                if (
                  _.isArray(selectedKnowledge) &&
                  selectedKnowledge.length > 0
                ) {
                  setShareModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                    selectedKnowledge: selectedKnowledge,
                    shareCC: false,
                    shareCircle: false,
                    canToggleCC: true,
                  }));
                } else {
                  message.warning("Please select a knowledge", 5);
                }
              }}
              className="mr-4"
            >
              Share
            </Button>,
            <Button
              key="create-new"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                setCreateModalSetting((prevState) => ({
                  ...prevState,
                  isModalVisible: true,
                  canToggleCC: true,
                  canShare: true,
                  initialValues: {
                    national: true,
                    shareCircle: false,
                    shareCC: false,
                  },
                }));
              }}
            >
              Create new
            </Button>,
          ].filter((button) => {
            if (button.key === "delete") {
              if (selectedKnowledge && selectedKnowledge.length > 0) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          })}
        >
          <Row>
            <Col xs={24}>
              <KnowledgeTable
                data={knowledgeData}
                showDetail={false}
                isLoading={isLoading}
                canToggleCC={true}
                selectedKnowledge={selectedKnowledge}
                setCurrentKnowledge={setCurrentKnowledge}
                setSelectedRows={setSelectedKnowledge}
                setEditModalSetting={setEditModalSetting}
                setShareModalSetting={setShareModalSetting}
                setDeleteModalSetting={setDeleteModalSetting}
              />
            </Col>
          </Row>
        </Card>
      ) : (
        <Card
          className="mt-4"
          title={
            <Button
              key="back"
              type="text"
              icon={<ArrowLeftOutlined />}
              onClick={() => {
                setCurrentKnowledge(undefined);
              }}
            ></Button>
          }
          extra={[
            <Button
              key="delete"
              icon={<DeleteOutlined />}
              disabled={
                _.isNil(knowledgeData[currentKnowledge].__permission) ||
                !knowledgeData[currentKnowledge].__permission.delete
              }
              onClick={() => {
                setDeleteModalSetting((prevState) => ({
                  ...prevState,
                  isModalVisible: true,
                  selectedKnowledge: [knowledgeData[currentKnowledge]],
                }));
              }}
              className="mr-4"
            >
              Delete
            </Button>,
            <Button
              key="edit"
              type="primary"
              icon={<EditOutlined />}
              disabled={
                _.isNil(knowledgeData[currentKnowledge].__permission) ||
                !knowledgeData[currentKnowledge].__permission.update
              }
              onClick={() => {
                setEditModalSetting((prevState) => ({
                  ...prevState,
                  isModalVisible: true,
                  selectedKnowledge: [knowledgeData[currentKnowledge]],
                  canToggleCC: !knowledgeData[currentKnowledge].createdByCC,
                  canShare: knowledgeData[currentKnowledge].__permission.share,
                  initialValues: _.pick(
                    _.isArray(knowledgeData) &&
                      knowledgeData.length > currentKnowledge
                      ? knowledgeData[currentKnowledge]
                      : {},
                    [
                      "address",
                      "category",
                      "description",
                      "email",
                      "keywords",
                      "link",
                      "local",
                      "name",
                      "national",
                      "phone",
                      "pricing",
                      "shareCircle",
                      "shareCC",
                      "specialty1",
                      "specialty2",
                      "state",
                    ]
                  ),
                }));
              }}
            >
              Edit
            </Button>,
          ]}
        >
          <Row>
            <Col xs={24}>
              <KnowledgeBaseDetail
                knowledgeData={knowledgeData[currentKnowledge]}
              />
            </Col>
          </Row>
        </Card>
      )}
    </div>
  );
};

export default KnowledgeBase;
