const GetStartedButton = ({ className = "", style, children, onClick, ...props }) => {
    return (
        <button
            className={`
              bg-button-orange 
                rounded-xl
                border
                border-transparent
                border-solid
                text-white
                px-8
                py-2
                m-0
                text-lg
                inline-block
                whitespace-nowrap
                transition-colors
                cursor-pointer
                hover:bg-button-orange-active
                duration-300
                active:bg-button-orange-active
            `
                +
                className
            }
            onClick={onClick}
            style={style}
            {...props}
        >
            {children}
        </button>
    )
};

export default GetStartedButton;