import React from "react";
import _ from "lodash";
import { Col, Skeleton, Button } from "antd";
import SidePanelDivider from "./SidePanelDivider";
import ChatListItem from "./ChatListItem";
import {
  DEFAULT_MESSAGE_USER_ICON,
  DEFAULT_MESSAGE_GROUP_ICON,
} from "../constants/Global";
import { CommentOutlined } from "@ant-design/icons";

const ChatLodaingSkeleton = ({ loading }) => {
  return (
    <div
      className="flex
        flex-row
        items-center
        py-0
        pr-4
        pl-0
        w-full
        h-[56px]
      "
    >
      <div
        className="
        flex
        flex-row
        grow
        items-center
        py-2
        pr-0
        pl-2
        gap-[15px]
        w-full
    "
      >
        <Skeleton.Avatar loading={loading} active={loading} shape="circle" />
        <div
          className="
            flex
            grow
            shrink
            basis-auto
            min-w-[0]
            items-center
          "
        >
          <div
            className="
          flex
          grow
          flex-col
          items-start
          p-0
          basis-auto
          min-w-[0]
          gap-2
        "
          >
            <Skeleton.Input loading={loading} active={loading} size="small" />
            <Skeleton.Input
              loading={loading}
              active={loading}
              size="small"
              block={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const OneToOneChatListItem = (props) => {
  const {
    _id,
    name,
    user = {},
    talkTo = {},
    messages = {},
    selected = false,
    isNew = false,
    setSelected = () => {},
  } = props;

  return (
    <ChatListItem
      {...props}
      Icon={
        !_.isNil(talkTo?.avatar) ? talkTo.avatar : DEFAULT_MESSAGE_USER_ICON
      }
      lastMessageText={
        !_.isNil(messages) &&
        messages.length > 0 &&
        (!_.isNil(user?._id) &&
        user._id === messages[messages.length - 1].sender?._id
          ? `You: ${messages[messages.length - 1].txt}`
          : `${messages[messages.length - 1].txt}`)
      }
    />
  );
};

const GroupChatListItem = (props) => {
  const {
    name,
    circle = {},
    user = {},
    messages = [],
    selected = false,
    isNew = false,
    setSelected = () => {},
  } = props;

  return (
    <ChatListItem
      {...props}
      Icon={
        !_.isNil(circle?.avatar) ? circle.avatar : DEFAULT_MESSAGE_GROUP_ICON
      }
      lastMessageText={
        !_.isNil(messages) &&
        messages.length > 0 &&
        (!_.isNil(user?._id) &&
        user._id === messages[messages.length - 1].sender?._id
          ? `You: ${messages[messages.length - 1].txt}`
          : `${messages[messages.length - 1].senderName}: ${
              messages[messages.length - 1].txt
            }`)
      }
    />
  );
};

const MCLChatListItem = (props) => {
  const {
    _id,
    name,
    icon,
    user = {},
    messages = [],
    messageTime,
    selected = false,
    isNew = false,
    setSelected = () => {},
  } = props;

  return (
    <ChatListItem
      {...props}
      Icon={icon}
      lastMessageText={
        !_.isNil(messages) &&
        messages.length > 0 &&
        (!_.isNil(user?._id) &&
        user._id === messages[messages.length - 1].sender?._id
          ? `You: ${messages[messages.length - 1].txt}`
          : `${messages[messages.length - 1].senderName}: ${
              messages[messages.length - 1].txt
            }`)
      }
    />
  );
};

const MessagingSidePanel = ({
  isLoading,
  selectListItem,
  selectedChatListId,
  groupChatListItems,
  directChatListItems,
  mclChatListItems,
  showGroupChat = true,
  showDirectChat = true,
  showMCLChat = true,
  showContactModal = () => {},
}) => {
  return (
    <Col
      flex="1 0 294px"
      className="
                      bg-message-bg-color
                        px-4 
                        py-6 
                        border 
                        border-[#e2e2e2]
                        border-solid
                        flex 
                        flex-col 
                        items-start
                        gap-4
                    "
    >
      <div className="flex w-full items-center">
        <h1 className="text-2xl font-bold leading-5 m-0">Messaging</h1>
        <Button
          type="text"
          shape="circle"
          className="ml-auto"
          size="large"
          icon={<CommentOutlined />}
          onClick={showContactModal}
        />
      </div>
      {showGroupChat && (
        <div className="w-full">
          <SidePanelDivider title={"CARE CIRCLE - GROUP CHATS"} />
          <div className="w-full">
            <div
              className="flex grow shrink basis-[294px] gap-2
                        flex-col 
                        items-start"
            >
              {isLoading && <ChatLodaingSkeleton loading={isLoading} />}
              {groupChatListItems.map((groupChatListItem) => {
                return (
                  <GroupChatListItem
                    {...groupChatListItem}
                    key={groupChatListItem._id}
                    selected={selectedChatListId === groupChatListItem._id}
                    setSelected={() => {
                      selectListItem(groupChatListItem);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {showDirectChat && (
        <div className="w-full">
          <SidePanelDivider title={"DIRECT MESSAGES"} />
          <div className="w-full">
            <div
              className="flex grow shrink basis-[294px] gap-2
                        flex-col 
                        items-start"
            >
              {isLoading && <ChatLodaingSkeleton loading={isLoading} />}
              {directChatListItems.map((directChatListItem) => {
                return (
                  <OneToOneChatListItem
                    {...directChatListItem}
                    key={directChatListItem._id}
                    selected={selectedChatListId === directChatListItem._id}
                    setSelected={() => {
                      selectListItem(directChatListItem);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
      {showMCLChat && (
        <div className="w-full">
          <SidePanelDivider title={"MY CARE LINQ"} />
          <div className="w-full">
            <div
              className="flex grow shrink basis-[294px] gap-2
                        flex-col 
                        items-start"
            >
              {isLoading && <ChatLodaingSkeleton loading={isLoading} />}
              {mclChatListItems.map((mclChatListItem) => {
                return (
                  <MCLChatListItem
                    {...mclChatListItem}
                    key={mclChatListItem._id}
                    icon={mclChatListItem.icon}
                    selected={selectedChatListId === mclChatListItem._id}
                    setSelected={() => {
                      selectListItem(mclChatListItem);
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      )}
    </Col>
  );
};

export default MessagingSidePanel;
