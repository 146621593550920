import React from 'react'
import { Button, Result, Typography } from 'antd';

const Unauthorized = () => {
    return (
        <Result
            status="error"
            title="Unauthorized"
            // subTitle="Please check and modify the following information before resubmitting."
            extra={[
                <Button type="primary" key="console">
                    MyCareLinq Home
                </Button>,
                <Button key="buy">Buy Again</Button>,
            ]}
        >
        </Result>)
}

export default Unauthorized