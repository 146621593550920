

import React from 'react'
import { Modal, Radio, Space } from 'antd';
import _ from "lodash";

const AssignModal = ({ titleText, isModalVisible, handleOk, handleCancel, clientNumber, cc, radioOnChange, value }) => {

    let title = `${titleText} selected ${clientNumber} ${clientNumber > 1 ? "clients" : "client"} to`

    return (
        <Modal title={title} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
            <Radio.Group onChange={radioOnChange} value={value}>
                <Space direction="vertical">
                    {
                        _.isArray(cc) && cc.map((item) => {
                            return (
                                <Radio key={item._id} value={item._id}>
                                    {item.firstName + " " + item.lastName}
                                </Radio>)
                        })
                    }
                </Space>
            </Radio.Group>
        </Modal>
    )
}

export { AssignModal }