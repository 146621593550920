import React, { useState, useEffect } from "react";
import _ from "lodash";
import { CloseOutlined } from "@ant-design/icons";
import { Col, Divider, List, Space, Button } from "antd";
import ChatAvatarIcon from "./ChatAvatarIcon";
import {
  DEFAULT_MESSAGE_USER_ICON,
  MESSAGE_CHAT_TYPE,
} from "../constants/Global";

const MessagingRightPanel = ({
  chatListItems,
  selectedChatListId,
  toggleRightPanel = () => {
    console.log("toggleRightPanel");
  },
}) => {
  const [memberList, setMemberList] = useState([]);
  const [currentUser, setCurrentUser] = useState({});

  useEffect(() => {
    if (!_.isEmpty(chatListItems) && selectedChatListId) {
      let selectedItem = chatListItems.find(
        (item) => item._id === selectedChatListId
      );

      if (_.isEmpty(selectedItem)) {
        return;
      }
      let user = selectedItem.user;
      setCurrentUser(user);
      let members = [];
      if (selectedItem.type === MESSAGE_CHAT_TYPE.GROUP) {
        members = [user];
        if (!_.isNil(selectedItem.circle?.primary)) {
          members.push(
            ...selectedItem.circle.primary.flatMap((item) => {
              if (item._id === user._id) {
                return [];
              }
              return [
                {
                  ...item,
                  isPrimary: true,
                  circle: {
                    _id: selectedItem.circle._id,
                    name: selectedItem.name,
                  },
                },
              ];
            })
          );
        }
        if (!_.isNil(selectedItem.circle?.members)) {
          members.push(
            ...selectedItem.circle.members.flatMap((item) => {
              if (item._id === user._id) {
                return [];
              }
              return [
                {
                  ...item,
                  isPrimary: false,
                  circle: {
                    _id: selectedItem.circle._id,
                    name: selectedItem.name,
                  },
                },
              ];
            })
          );
        }
      } else if (selectedItem.type === MESSAGE_CHAT_TYPE.DIRECT) {
        members = [user];
        if (selectedItem.userA?._id === user._id) {
          members = [...members, selectedItem.userB];
        } else if (selectedItem.userB?._id === user._id) {
          members = [...members, selectedItem.userA];
        }
      } else if (selectedItem.type === MESSAGE_CHAT_TYPE.MCL.CONCIERGE) {
        if (selectedItem.chatOwner) {
          members = [selectedItem.chatOwner];
        }
        if (_.isArray(selectedItem.concierges?.currentCC)) {
          members = [
            ...members,
            ...selectedItem.concierges.currentCC.map((item) => {
              return {
                ...item,
                isCC: true,
              };
            }),
          ];
        }
      }
      console.log(selectedItem);
      setMemberList(members);
    }
  }, [chatListItems, selectedChatListId]);

  return (
    <Col
      flex="1 0 240px"
      className="
                        bg-message-bg-color
                        px-4 
                        py-6 
                        border 
                        border-[#e2e2e2]
                        border-solid
                        flex 
                        flex-col 
                        items-start
                        gap-4
                    "
    >
      <Space>
        <Button shape="circle" type="text" onClick={toggleRightPanel}>
          <CloseOutlined />
        </Button>
        <p className="mb-0 text-text-color text-base leading-5">
          Chat participants
          {_.isArray(memberList) && memberList.length > 0 && (
            <strong className="font-bold">{` ${memberList.length}`}</strong>
          )}
        </p>
      </Space>
      <Divider
        style={{
          borderTopColor: "rgba(60, 48, 102, 0.3)",
          margin: 0,
        }}
      />
      <List
        className="w-full"
        itemLayout="vertical"
        dataSource={memberList}
        renderItem={(item, index) => (
          <List.Item
            style={{
              borderBottom:
                _.isArray(memberList) && index < memberList?.length - 1
                  ? "1px solid #3c30661a"
                  : "none",
            }}
          >
            <Space>
              <ChatAvatarIcon
                size={24}
                Icon={
                  !_.isNil(item?.icon) ? item.icon : DEFAULT_MESSAGE_USER_ICON
                }
                iconStyle={{
                  color: "#EBEBEB",
                }}
                avatarStyle={{
                  background:
                    "linear-gradient(146.51deg, #6D56BE 12.17%, #3C3066 100.04%)",
                  border: "1px solid rgba(60, 48, 102, 0.6)",
                }}
              />
              <p className="mb-0 text-text-color text-xs leading-5">
                {item.firstName}
                {item._id === currentUser?._id && <span> (You)</span>}
              </p>
            </Space>
          </List.Item>
        )}
      />
    </Col>
  );
};

export default MessagingRightPanel;
