import React, { useRef, useEffect } from 'react'
import _ from "lodash";
import moment from 'moment';
import { Card, Button, Descriptions, Collapse, List, Modal, Input, Form, DatePicker, Select, Tag, message, Table, Space } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { SubscriptionToStr, SubscriptionToColor, DispositionCode } from '../constants/Global';
import { v4 as uuidv4 } from 'uuid';

const NoteModal = ({ modalTitle = "Add", isModalVisible, onChange, handleOk, handleCancel, value }) => {
    return (
        <Modal
            title={modalTitle}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={"Save"}
            width={1000}
        >
            <Input.TextArea
                showCount
                maxLength={3000}
                rows={30}
                style={{ height: "100%" }}
                onChange={onChange}
                value={value}
                placeholder="Type here."
            />
        </Modal>
    )
}
const DeleteModal = ({ modalTitle = "Delete", isModalVisible, handleOk, handleCancel }) => {
    return (
        <Modal
            title={modalTitle}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
            okText={"Delete"}
            okType={"danger"}
            width={1000}
        >
            <p>Are you sure to delete this note?</p>
        </Modal>
    )
}
const EditCaseNoteModal = ({
    modalTitle = "Edit Intake Consultation",
    isModalVisible = false,
    handleOk = () => { console.log("handle Ok") },
    handleCancel = () => { console.log("handle Cancel") },
    value = {},
    updateValue = () => { console.log("Update Value") },
    postConsultActions = [],
    updatePostConsultActions = () => { console.log("Update PostConsultActions Value") },
}) => {

    const inputRef = useRef(null);

    const notSavedActionMessage = () => {
        message.error('There are some consultation item not saved yet', 5);
    };

    const onFinish = (values) => {
        let newCaseNoteData = {
            ...value,
            ...values
        }

        // Check whether if there is any action is still being edit
        for (let i in postConsultActions) {
            if (postConsultActions[i].isEditing === true) {
                notSavedActionMessage();
                return;
            }
        }
        handleOk(newCaseNoteData);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const [form] = Form.useForm();

    // Update Antd form when the react state is updated
    useEffect(() => {
        form.setFieldsValue(value);
    }, [value, form])


    const addActionItem = () => {
        let newItem = {
            description: "",
            isEditing: true,
            CCId: value.CCId,
            id: uuidv4(),
        }

        // When add a new action item, set all other action to not editing
        let items = [...postConsultActions];
        for (let i = 0; i < items.length; i++) {
            items[i].isEditing = false;
        }
        items.push(newItem)
        updatePostConsultActions(items);
    };

    const editActionItemOnClick = (id) => {
        let items = [...postConsultActions];
        for (let i in items) {
            if (items[i].id === id) {
                items[i].isEditing = true;
            } else {
                items[i].isEditing = false;
            }
        }
        updatePostConsultActions(items);
    };

    const saveActionItem = (id) => {
        let newDescription = "";
        // Get the current input value by ref
        if (inputRef.current && inputRef.current.input) {
            newDescription = inputRef.current.input.value
        }
        let items = [...postConsultActions];
        for (let i in items) {
            if (items[i].id === id) {
                items[i].description = newDescription;
            }
            items[i].isEditing = false;
        }
        updatePostConsultActions(items);
    };

    const deleteActionItem = (id) => {
        let items = postConsultActions.filter(item => item.id !== id);
        updatePostConsultActions(items);
    };

    return (
        <Form
            id="intake-consultation-form"
            name="basic"
            form={form}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={value}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            labelAlign="left"
        >
            <Modal
                title={modalTitle}
                visible={isModalVisible}
                onCancel={handleCancel}
                okText={"Delete"}
                okType={"danger"}
                width={1000}
                footer={[
                    <Button key="back" onClick={handleCancel}>
                        Cancel
                    </Button>,
                    <Button form="intake-consultation-form" key="submit" type="primary" htmlType="submit">
                        Save
                    </Button>
                ]}
            >
                <Descriptions className="mb-8" title="User Info" layout="vertical" bordered>
                    <Descriptions.Item label="Primary Care/Account Owner">
                        {value && value.primaryCare}
                    </Descriptions.Item>
                    <Descriptions.Item label="Account Type">
                        <span>
                            <Tag color={SubscriptionToColor(value && value.accountType)}>
                                {SubscriptionToStr(value && value.accountType).toUpperCase()}
                            </Tag>
                        </span>
                    </Descriptions.Item>
                    <Descriptions.Item label="Care Concierge Name">
                        {value && value.CCName}
                    </Descriptions.Item>
                    <Descriptions.Item label="Last consultation time" span={3}>
                        {value && value.lastConsultDate ? moment(value.lastConsultDate).toLocaleString() : "No consultation before"}
                    </Descriptions.Item>
                    <Descriptions.Item label="Post Consultation Action of Last time" span={3}>
                        {
                            value &&
                                value.lastActionItems &&
                                value.lastActionItems.length > 0 ?
                                <ul>
                                    {
                                        value.lastActionItems.map((item, index) => {
                                            return (
                                                <li key={item._id}>
                                                    {item.description}
                                                </li>
                                            )
                                        })}
                                </ul>
                                :
                                <p>
                                    {"None"}
                                </p>
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Form.Item
                    label="Disposition"
                    name="disposition"
                    rules={[{ required: true }]}
                >
                    <Select
                        placeholder="Select a disposition"
                    >
                        {Object.keys(DispositionCode).map((key) =>
                            <Select.Option
                                key={key}
                                value={parseInt(key)}>
                                {DispositionCode[key]}
                            </Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Please input the subject of this consultation" }]}

                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Description"
                    name="description"
                    rules={[{ message: "Please input the description of this consultation" }]}

                >
                    <Input.TextArea
                        showCount
                        maxLength={3000}
                        rows={10}
                    />
                </Form.Item>
                <Collapse className="h-full" defaultActiveKey={["postConsultActions"]}>
                    <Collapse.Panel header="Post Consultation Actions" key="postConsultActions">
                        <Card
                            bordered={false}
                            extra={
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={addActionItem}
                                >
                                    Add
                                </Button>
                            }
                        >
                            <List
                                itemLayout="horizontal"
                                dataSource={postConsultActions}
                                renderItem={item => (
                                    <List.Item
                                        actions={
                                            item.isEditing ?
                                                [<Button
                                                    key="list-postConsultActions-edit"
                                                    type="link"
                                                    onClick={() => {
                                                        saveActionItem(item.id)
                                                    }}
                                                >
                                                    Save
                                                </Button>] : [
                                                    <Button
                                                        key="list-postConsultActions-edit"
                                                        type="link"
                                                        onClick={() => {
                                                            editActionItemOnClick(item.id)
                                                        }}
                                                    >
                                                        Edit
                                                    </Button>,
                                                    <Button
                                                        key="list-postConsultActions-delete"
                                                        type="link"
                                                        danger
                                                        icon={<DeleteOutlined />}
                                                        onClick={() => {
                                                            deleteActionItem(item.id)
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                ]
                                        }
                                    >
                                        {
                                            item.isEditing ?
                                                <Input
                                                    ref={inputRef}
                                                    defaultValue={item.description}
                                                    placeholder="Input post consultation action here"
                                                    onKeyDown={(event) => {
                                                        if (event.key === 'Enter') {
                                                            saveActionItem(item.id)
                                                        }
                                                    }}
                                                />
                                                :
                                                <p>
                                                    {item.description}
                                                </p>

                                        }
                                    </List.Item>
                                )}
                            />
                        </Card>
                    </Collapse.Panel>
                </Collapse>
            </Modal >
        </Form >
    )
}
const CaseNoteTable = ({ data, actionFunc }) => {
    const columns = [
        {
            title: 'Subject',
            key: 'subject',
            dataIndex: 'subject',
        },
        {
            title: 'Care Concierge',
            key: 'consultCC',
            dataIndex: 'consultCC',
            render: (consultCC) => (
                <p>
                    {consultCC && consultCC.firstName + " " + consultCC.lastName}
                </p>
            ),
        },
        {
            title: 'Date',
            key: 'createdAt',
            dataIndex: 'createdAt',
            render: (createdAt) => (
                <p>
                    {moment(createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                </p>
            ),
        },
    ];
    return (
        <Table
            columns={columns}
            dataSource={data}
            rowKey="_id"
            expandable={{
                expandedRowRender: (item) =>
                    <Descriptions layout="horizontal" bordered>
                        <Descriptions.Item label="Care Concierge" span={3}>
                            {item.consultCC ?
                                item.consultCC.firstName + " " + item.consultCC.lastName : " "}
                        </Descriptions.Item>
                        <Descriptions.Item label="Account Type" span={3}>
                            <span>
                                <Tag color={SubscriptionToColor(item && item.accountType)}>
                                    {SubscriptionToStr(item && item.accountType).toUpperCase()}
                                </Tag>
                            </span>
                        </Descriptions.Item>
                        <Descriptions.Item label="Disposition" span={3}>
                            {DispositionCode[item.disposition]}
                        </Descriptions.Item>
                        <Descriptions.Item label="Description" span={3}>
                            {item.description}
                        </Descriptions.Item>
                        <Descriptions.Item label="Post Consultation Action Items" span={3}>
                            <ul>
                                {
                                    item.postConsultActions && item.postConsultActions.map((item, index) => {
                                        return (
                                            <li key={item._id}>
                                                {item.description}
                                            </li>
                                        )
                                    })
                                }
                            </ul>
                        </Descriptions.Item>
                    </Descriptions>
            }}
        />
    )
}

export { NoteModal, DeleteModal, EditCaseNoteModal, CaseNoteTable };