import React from "react";
import moment from "moment";
import { Col, Row, Space, Typography, Divider, Image } from "antd";
import { Link } from "react-router-dom";
import { CalendarOutlined } from "@ant-design/icons";
import { AUTHOR_IMAGE_PLACEHOLDER, ARTICLE_ROUTE } from "../constants/Global";
import { getDefaultLibraryImage, getLibraryImage } from "./Various";

import _ from "lodash";

const ArticleCard = ({ article }) => {
  let defaultImageLink = getDefaultLibraryImage(article.categories);

  return (
    <div className="bg-white shadow-lg shadow-black/40 rounded-lg p-3 md:p-5 lg:p-8 xl:p-12 pb-12 mb-8">
      <div className="relative overflow-hidden shadow-md pb-80 mb-6">
        <img
          src={
            _.isEmpty(article.featuredImage)
              ? defaultImageLink
              : getLibraryImage(article.featuredImage, "article")
          }
          alt=""
          className="object-top absolute h-80 w-full object-cover  shadow-lg rounded-t-lg lg:rounded-lg"
        />
      </div>

      <h1 className="transition duration-700 text-center mb-8 cursor-pointer hover:text-pink-600 text-3xl font-semibold">
        <Link
          className="text-primary-purple"
          to={`${ARTICLE_ROUTE}${article.url}`}
        >
          {article.title}
        </Link>
      </h1>
      <div className="block lg:flex text-center items-center justify-center mb-8 w-full">
        <div className="flex items-center justify-center mb-4 lg:mb-0 w-full lg:w-auto mr-8">
          <Image
            alt={""}
            height={30}
            width={30}
            className="align-middle rounded-full"
            src={
              _.isNil(article.author) || _.isEmpty(article.author.photo)
                ? AUTHOR_IMAGE_PLACEHOLDER
                : article.author.photo
            }
          />
          <p className="inline align-middle text-gray-700 ml-2 font-medium text-lg mt-0 mb-0">
            {article.author?.name}
          </p>
        </div>
        <div className="flex items-center justify-center font-medium text-gray-700">
          <CalendarOutlined className="text-[30px] text-primary-purple mr-2" />
          <span className="align-middle inline-flex items-center">
            {moment(article.date).format("MMM DD, YYYY")}
          </span>
        </div>
      </div>
      <p className="text-center text-lg text-gray-700 leading-tight text-[0.75rem] sm:text-[0.8rem] md:text-[0.9rem] font-normal px-5 py-2 lg:px-20 mb-8">
        {article.excerpt}
      </p>
      <div className="text-center">
        <Link to={`${ARTICLE_ROUTE}${article.url}`}>
          <span className="transition duration-500 ease transform hover:-translate-y-1 inline-block bg-purple-800 text-lg font-medium rounded-full text-white px-8 py-3 cursor-pointer">
            Continue Reading
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ArticleCard;
