// import React from 'react';
// import { Image } from 'antd';
import logoB2b from "../assets/images/b2b/b2b-hands.png";
import logoLuna from "../assets/images/b2b/luna.jpg";
import logoInnovets from "../assets/images/b2b/innovets.png";

const b2bToLogo = (b2b) => { return b2b === '6564801e1d8541c2352c4c6d' ? logoLuna : 
                                    b2b === '65648a36093affb1dd3dbf6c' ? logoInnovets : 
                                                                         logoB2b } 

const b2bToName = (b2b) => { return b2b === '6564801e1d8541c2352c4c6d' ? "Luna Massage and Wellness" :  
b2b === '65648a36093affb1dd3dbf6c' ? "Innovets" :  
                                     "" } 

const B2bLogo = ({ b2b, banner=false }) => {
  if (b2b) {
    if (!banner) {
    return (
    <img
      className="nav-logo-inline-block"
      src={b2bToLogo(b2b)}
      height="20"
      weign="20"
      alt="B2B icon"
    />
    ) } else {
      return (
      <div>
      <div style={{margin: "0 auto", height: "60px", textAlign: "center", width: "300px", verticalAlign: "middle"}} >
        <img
          src={b2bToLogo(b2b)}
          height="40px"
          width="200px"
          alt="B2B icon"
        />
      </div>
      <div style={{fontSize: "1.25em", margin: "0 auto", textAlign: "center", verticalAlign: "middle", height: "2em"}} >
        Welcome {b2bToName(b2b)} employees and family members. Please follow the prompts to create your account. 
      </div>
      </div>
      )
    }
  } else {
    return null;
  }
}

export default B2bLogo;