import react, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import _ from "lodash";

import {
  EditOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Row, Col, Space, Spin, Input, Button, Form } from "antd";
import { mclOpen } from "../../api/Mcl";
import { Url } from "../../constants/Global";
import { openNotification } from "../../components/Various";
import useAuth from "../../hooks/useAuth";

import PasswordStrengthBar from "react-password-strength-bar";
import InvitationSideCard from "../../components/InvitationSideCard";

const Invitation = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [pw, setPW] = useState("");
  const [editEmailDisabled, setEmailEditDisabled] = useState(true);
  const [showPW, setShowPW] = useState(false);
  const [showPWConfirm, setShowPWConfirm] = useState(false);
  const [inviter, setInviter] = useState({});
  const [circle, setCircle] = useState({});
  const [form] = Form.useForm();
  const { setAuth } = useAuth();
  const { INVITATION_ID } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetuchInvitationInfo = async () => {
      try {
        if (!_.isNil(INVITATION_ID)) {
          // fetch invitation info
          console.log(INVITATION_ID);
          const response = await mclOpen.get(
            Url.INVITATION.concat("/", INVITATION_ID),
            JSON.stringify({
              invitationID: INVITATION_ID,
            }),
            {
              headers: { "Content-Type": "application/json" },
              withCredentials: true,
            }
          );
          if (response.status === 200) {
            setInviter(response.data.inviter);
            setEmail(response.data.email);
            setCircle(response.data.circleId);
          }
        }
      } catch (error) {
        openNotification({
          message: "Failed",
          status: "500",
          title: "500",
          subTitle: "Unable to get invitation info",
        });
      }
    };
    fetuchInvitationInfo();
  }, [INVITATION_ID]);

  useEffect(() => {
    //companyInfo -> dynamic obj fetched from API
    form.setFieldsValue({ email: email });
  }, [email, form]);

  const loginAfterSignUp = async (email, password) => {
    try {
      if (!_.isNil(INVITATION_ID)) {
        // fetch invitation info
        const response = await mclOpen.post(
          Url.LOGIN,
          JSON.stringify({
            email: email,
            password: password,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        const accessToken = response?.data?.accessToken;
        const roles = response?.data?.roles;
        const care = response?.data?.care;
        const id = response?.data?.id;
        setAuth({ id, email, password, care, roles, accessToken });

        // setEmail('');
        // setPwd('');
        //setSuccess(true);
        // ?? TODO: navigate to dashboard depending on role
        //navigate('/caregiver/dashboard', { replace: true });
        openNotification({
          message: "Succeed!",
          status: "success",
          title:
            "Successfully created a account! You will be redirected in a few seconds.",
          onClose: () => {
            navigate("/site/invitation-userinfo/".concat(id), {
              replace: true,
              state: { inviter: inviter, circle: circle },
            });
          },
        });
      }
    } catch (err) {
      if (!err?.response) {
        // setErrMsg('No Server Response');
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      } else if (err.response?.status === 400) {
        // setErrMsg('Missing Username or Password');
        openNotification({
          message: "",
          status: "error",
          title: "Login failed",
          subTitle: "Missing Email or Password",
        });
      } else if (err.response?.status === 401) {
        // setErrMsg('Unauthorized');
        openNotification({
          message: "",
          status: "401",
          title: "402",
          subTitle: "Email or password may be incorrect.",
        });
      } else {
        // setErrMsg('Login Failed');
        openNotification({
          message: "Login failed",
          status: "500",
          title: "500",
          subTitle: "Sorry, your login attempt failed.",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const signUpWithInvitation = async (values) => {
    try {
      if (!_.isNil(INVITATION_ID)) {
        // fetch invitation info
        console.log(INVITATION_ID);
        const response = await mclOpen.post(
          Url.INVITATION.concat("/", INVITATION_ID),
          JSON.stringify({
            email: values.email,
            password: values.password,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        if (response.status === 200) {
          await loginAfterSignUp(values.email, values.password);
        }
        console.log(response);
      }
    } catch (err) {
      if (!err?.response) {
        // setErrMsg('No Server Response');
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      } else if (err.response?.status === 400) {
        // setErrMsg('Missing Username or Password');
        openNotification({
          message: "",
          status: "error",
          title: "Login failed",
          subTitle: "Missing Email or Password",
        });
      } else if (err.response?.status === 409) {
        //setErrMsg('Username Taken');
        // setErrMsg('Account with the same email already exists');
        openNotification({
          message: "",
          status: "error",
          title: "Submission failed",
          subTitle:
            "Account with the same email already exists, please click I already manage a plan and login directly.",
        });
      } else {
        // setErrMsg('Login Failed');
        openNotification({
          message: "Failed",
          status: "500",
          title: "500",
          subTitle: "Unable to signup with invitation",
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const onFinish = async (values) => {
    console.log(values);
    if (
      values &&
      values.password === values.confirm &&
      !_.isEmpty(values.email)
    ) {
      setLoading(true);
      signUpWithInvitation(values);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  let emailInputDisabledStyle =
    "max-w-[450px] flex items-center min-h-[64px] px-4 py-3 rounded-lg border-solid border border-black/5";
  let emailInputEnabledStyle =
    "max-w-[450px] flex items-center min-h-[64px] px-4 py-3 rounded-lg bg-white";

  return (
    <div className="bg-dashboard-bg">
      <Row className="min-h-screen  2xl:container 2xl:mx-auto">
        <Col className="py-8 pl-8 xl:pr-8" xs={0} lg={10}>
          <div className="h-full">
            <InvitationSideCard inviter={inviter} circle={circle} />
          </div>
        </Col>
        <Col xs={24} lg={14}>
          <div className="flex flex-col h-full ant-lg:justify-center">
            <div className="w-full p-4 ant-lg:pl-16">
              <div>
                <span className="text-dark-purple/60 text-base leading-5">
                  To join the team
                </span>
              </div>
              <div className="mt-2">
                <span className="text-new-primary-purple text-3xl leading-9">
                  <b className="text-3xl">
                    Create your free MyCareLinq Account
                  </b>
                </span>
              </div>
              <div className="mt-2">
                <Form
                  form={form}
                  initialValues={{
                    email: email,
                    password: "",
                    passwordConfirm: "",
                  }}
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
                  onValuesChange={(changedValue) => {
                    if (!_.isNil(changedValue["password"])) {
                      setPW(changedValue["password"]);
                    }
                  }}
                >
                  <Space className="w-full" direction="vertical" size={8}>
                    <div
                      className={
                        editEmailDisabled
                          ? emailInputDisabledStyle
                          : emailInputEnabledStyle
                      }
                    >
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          Your Email{" "}
                          <span className="text-xs text-red-500">*</span>
                        </span>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Please input your email address",
                            },
                            {
                              type: "email",
                              message: "Please enter a valid email address",
                            },
                          ]}
                          name="email"
                          style={{ marginBottom: 0 }}
                        >
                          <Input
                            placeholder="Enter your email"
                            // value={email}
                            size={"large"}
                            disabled={editEmailDisabled}
                            bordered={false}
                            required
                            type="email"
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                            }}
                          />
                        </Form.Item>
                      </div>
                      {/* Uncomment these to enable email editing */}
                      {/* <Button
                          size={"small"}
                          className="text-primary-purple 
                          rounded-lg 
                          font-medium 
                          border-transparent 
                          hover:border-primary-purple 
                          hover:text-primary-purple 
                          focus:border-primary-purple
                          focus:text-primary-purple
                        "
                          icon={<EditOutlined></EditOutlined>}
                          onClick={() => {
                            setEmailEditDisabled(!editEmailDisabled);
                          }}
                        >
                          Edit
                        </Button> */}
                    </div>
                    <div className="max-w-[450px] flex items-center min-h-[64px] px-4 py-3 rounded-lg bg-white">
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          Password{" "}
                          <span className="text-xs text-red-500">*</span>
                        </span>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                              message: "Must be at least 10 charaters long!",
                              min: 10,
                            },
                          ]}
                          name="password"
                          style={{ marginBottom: 0 }}
                        >
                          <Input.Password
                            placeholder="Enter your password"
                            required
                            size={"large"}
                            bordered={false}
                            type="password"
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                              fontSize: 16,
                            }}
                            iconRender={() => {
                              return <></>;
                            }}
                            visibilityToggle={{
                              visible: showPW,
                              onVisibleChange: setShowPW,
                            }}
                          />
                        </Form.Item>
                      </div>
                      <Button
                        className="text-primary-purple 
                        rounded-lg 
                        font-medium 
                        border-transparent 
                        hover:text-primary-purple 
                        focus:text-primary-purple
                        border-0
                        "
                        icon={
                          showPW ? <EyeInvisibleOutlined /> : <EyeOutlined />
                        }
                        onClick={() => {
                          setShowPW(!showPW);
                        }}
                      ></Button>
                    </div>

                    <div className="max-w-[450px] flex items-center min-h-[64px] px-4 py-3 rounded-lg bg-white">
                      <div className="flex flex-col flex-1">
                        <span className="text-xs font-bold text-black/60">
                          Confirm Password{" "}
                          <span className="text-xs text-red-500">*</span>
                        </span>
                        <Form.Item
                          name="confirm"
                          dependencies={["password"]}
                          rules={[
                            {
                              required: true,
                              message: "Please confirm your password!",
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue("password") === value
                                ) {
                                  return Promise.resolve();
                                }

                                return Promise.reject(
                                  new Error(
                                    "The two passwords that you entered do not match!"
                                  )
                                );
                              },
                            }),
                          ]}
                          style={{ marginBottom: 0 }}
                        >
                          <Input.Password
                            placeholder="Enter your password again"
                            // value={pwConfirm}
                            size={"large"}
                            required
                            bordered={false}
                            type="password"
                            // onChange={(e) => {
                            //   setPWConfirm(e.target.value);
                            // }}
                            style={{
                              padding: 0,
                              color: "rgbA(16, 8, 47, 0.6)",
                              fontWeight: 700,
                              fontSize: 16,
                            }}
                            iconRender={() => {
                              return <></>;
                            }}
                            visibilityToggle={{
                              visible: showPWConfirm,
                              onVisibleChange: setShowPWConfirm,
                            }}
                          />
                        </Form.Item>
                      </div>
                      <Button
                        className="text-primary-purple 
                        rounded-lg 
                        font-medium 
                        border-transparent 
                        hover:text-primary-purple 
                        focus:text-primary-purple
                        border-0
                        "
                        icon={
                          showPWConfirm ? (
                            <EyeInvisibleOutlined />
                          ) : (
                            <EyeOutlined />
                          )
                        }
                        onClick={() => {
                          setShowPWConfirm(!showPWConfirm);
                        }}
                      ></Button>
                    </div>
                    <PasswordStrengthBar
                      className="max-w-[450px]"
                      password={pw}
                    />
                  </Space>
                </Form>
                <Button
                  style={{
                    borderRadius: "0.5rem",
                    height: "44px",
                    paddingTop: "14px",
                    paddingBottom: "14px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}
                  loading={loading}
                  className="mt-8 text-xs font-bold text-white bg-[#4761C0] hover:bg-[#95a1d9]  hover:text-[#4761C0] hover:border-[#95a1d9] focus:border-[#95a1d9] focus:text-[#4761C0]"
                  onClick={form && form.submit}
                >
                  Create and Continue
                </Button>
              </div>
              <div className="mt-40">
                <Button
                  style={{
                    borderRadius: "0.5rem",
                    height: "44px",
                    paddingTop: "14px",
                    paddingBottom: "14px",
                    paddingLeft: "40px",
                    paddingRight: "40px",
                  }}
                  className="mt-16 text-xs font-bold text-new-primary-purple border-new-primary-purple hover:bg-[#95a1d9]    focus:border-[#95a1d9] focus:text-[#4761C0]"
                  onClick={() => {
                    navigate("/site/login/".concat(INVITATION_ID));
                  }}
                >
                  I already manage a care plan
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Invitation;
