import React from "react";
import ReactGA from "react-ga4";
import { useState, useCallback } from "react";
import "./Gifting.css";
import "./Prepaid.css";
import { useNavigate } from "react-router";
import { Navbar } from "../../components/Navbar";
import Footer from "../../components/Footer";
import { Col, Row, Button } from "antd";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Url } from "../../constants/Global";
import useAuth from "../../hooks/useAuth";
import useMclAuth from "../../hooks/useMclAuth";
import { mclOpen } from "../../api/Mcl";

const isTest = process.env.REACT_APP_TEST === "yes";

function GiftingForm() {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("checkoutSession");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const [giftPlan, setGiftPlan] = useState("");
  const [giverFName, setGiverFName] = useState("");
  const [giverLName, setGiverLName] = useState("");
  const [giverEmail, setGiverEmail] = useState("");
  const [giverPhone, setGiverPhone] = useState("");
  const [recipientFName, setRecipientFName] = useState("");
  const [recipientLName, setRecipientLName] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientPhone, setRecipientPhone] = useState("");

  const [planSelectCheck, setPlanSelectCheck] = useState(false);
  const [giverFNameCheck, setGiverFNameCheck] = useState(false);
  const [giverLNameCheck, setGiverLNameCheck] = useState(false);
  const [giverEmailCheck, setGiverEmailCheck] = useState(false);
  const [giverPhoneCheck, setGiverPhoneCheck] = useState(false);
  const [recipientFNameCheck, setRecipientFNameCheck] = useState(false);
  const [recipientLNameCheck, setRecipientLNameCheck] = useState(false);
  const [recipientEmailCheck, setRecipientEmailCheck] = useState(false);

  const handleSubmit = (event) => {
    ReactGA.event({
      category: 'pricing',
      action: 'click_button',
      label: 'submit_gift'
    });
    event.preventDefault();
    if (!planSelectCheck) {
      alert("Please select a plan.");
    } else if (!giverFNameCheck) {
      alert("Please your first name.");
    } else if (!giverLNameCheck) {
      alert("Please your last name.");
    } else if (!giverEmailCheck) {
      alert("Please your email.");
    } else if (!giverPhoneCheck) {
      alert("Please your phone number.");
    } else if (!recipientFNameCheck) {
      alert("Please recipient first name.");
    } else if (!recipientLNameCheck) {
      alert("Please recipient last name.");
    } else if (!recipientEmailCheck) {
      alert("Please recipient email.");
    } else {
      const recaptchaFirst = async () => {
        let recaptchaToken = await handleReCaptchaVerify();

        mclAuth.get(Url.PAYMENT.concat("/stripeId")).then((res) => {
          if (res.data) {
            mclOpen
              .post(
                Url.GIFTING.concat("/giftCheckout"),
                {
                  customerID: res.data,
                  giftPlan: giftPlan,
                  giverFName: giverFName,
                  giverLName: giverLName,
                  giverEmail: giverEmail,
                  giverPhone: giverPhone,
                  recipientFName: recipientFName,
                  recipientLName: recipientLName,
                  recipientEmail: recipientEmail,
                  recipientPhone: recipientPhone,
                },
                {
                  params: {
                    token: recaptchaToken,
                  },
                }
              )
              .then((res) => {
                localStorage.setItem("accessToken", auth.accessToken);
                window.location.href = res.data;
              });
          } else {
            mclAuth.get(Url.PAYMENT.concat("/username")).then((res) => {
              mclOpen
                .post(
                  Url.GIFTING.concat("/giftCheckout"),
                  {
                    firstName: res.data[0],
                    lastName: res.data[1],
                    email: auth.email,
                    giftPlan: giftPlan,
                    giverFName: giverFName,
                    giverLName: giverLName,
                    giverEmail: giverEmail,
                    giverPhone: giverPhone,
                    recipientFName: recipientFName,
                    recipientLName: recipientLName,
                    recipientEmail: recipientEmail,
                    recipientPhone: recipientPhone,
                  },
                  {
                    params: {
                      token: recaptchaToken,
                    },
                  }
                )
                .then((res) => {
                  localStorage.setItem("accessToken", auth.accessToken);
                  window.location.href = res.data;
                });
            });
          }
        });
      };

      recaptchaFirst();
    }
  };

  return (
    <main>
      <Navbar signin={true} navigate={navigate} />
      <div className="my-10 lg:mx-28">
        <form method="POST" onSubmit={handleSubmit} className="flex flex-col text-2xl leading-6">
          <strong className="mx-8 mb-8 p-0 text-2xl">* Which plan do you want to gift?</strong>
          <label className="my-3 mx-10 px-10 radio-input relative">
            <input
              type="radio"
              name="productID"
              value={isTest ? "prod_LvO8CuvXs7kt0m" : "prod_M9JhV0ONNGluFZ"}
              className="cursor-pointer radio-button"
              onChange={(e) => {
                setPlanSelectCheck(true);
                setGiftPlan(isTest ? "prod_LvO8CuvXs7kt0m" : "prod_M9JhV0ONNGluFZ");
              }}
            />
            <span className="inset-0 top-0.5 check-mark cursor-pointer"></span>
            <strong className="m-0 p-0 cursor-pointer">3 Months PREMIUM plan</strong>
          </label>
          <label className="my-3 mx-10 px-10 radio-input relative">
            <input
              type="radio"
              name="productID"
              value={isTest ? "prod_LvO8BAvsdMn1QG" : "prod_M9JgFabhzIUA0p"}
              className="cursor-pointer radio-button"
              onChange={(e) => {
                setPlanSelectCheck(true);
                setGiftPlan(isTest ? "prod_LvO8BAvsdMn1QG" : "prod_M9JgFabhzIUA0p");
              }}
            />
            <span className="inset-0 top-0.5 check-mark cursor-pointer"></span>
            <strong className="m-0 p-0 cursor-pointer">3 Months SILVER plan</strong>
          </label>
          <strong className="mx-8 my-8 p-0 text-2xl">Your information:</strong>
          <Row>
            <Col md={4}>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">* First Name: </strong>
              </Row>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">* Email: </strong>
              </Row>
            </Col>
            <Col md={8}>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="fname"
                  value={giverFName}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => {
                    setGiverFName(e.target.value);
                    setGiverFNameCheck(e.target.value ? true : false);
                  }}
                />
              </Row>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="email"
                  value={giverEmail}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => {
                    setGiverEmail(e.target.value);
                    setGiverEmailCheck(e.target.value ? true : false);
                  }}
                />
              </Row>
            </Col>
            <Col md={4}>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">* Last Name: </strong>
              </Row>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">* Phone number: </strong>
              </Row>
            </Col>
            <Col md={8}>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="lname"
                  value={giverLName}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => {
                    setGiverLName(e.target.value);
                    setGiverLNameCheck(e.target.value ? true : false);
                  }}
                />
              </Row>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="phone"
                  value={giverPhone}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => {
                    setGiverPhone(e.target.value);
                    setGiverPhoneCheck(e.target.value ? true : false);
                  }}
                />
              </Row>
            </Col>
          </Row>
          <strong className="mx-8 my-8 p-0 text-2xl">Recipient information:</strong>
          <Row>
            <Col md={4}>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">* First Name: </strong>
              </Row>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">* Email: </strong>
              </Row>
            </Col>
            <Col md={8}>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="fname"
                  value={recipientFName}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => {
                    setRecipientFName(e.target.value);
                    setRecipientFNameCheck(e.target.value ? true : false);
                  }}
                />
              </Row>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="email"
                  value={recipientEmail}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => {
                    setRecipientEmail(e.target.value);
                    setRecipientEmailCheck(e.target.value ? true : false);
                  }}
                />
              </Row>
            </Col>
            <Col md={4}>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">* Last Name: </strong>
              </Row>
              <Row className="my-6">
                <strong className="leading-8 w-full text-right">Phone number: </strong>
              </Row>
            </Col>
            <Col md={8}>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="lname"
                  value={recipientLName}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => {
                    setRecipientLName(e.target.value);
                    setRecipientLNameCheck(e.target.value ? true : false);
                  }}
                />
              </Row>
              <Row className="my-6 mx-4">
                <input
                  type="text"
                  name="phone"
                  value={recipientPhone}
                  className="rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 "
                  onChange={(e) => setRecipientPhone(e.target.value)}
                />
              </Row>
            </Col>
          </Row>

          <Button
            htmlType="submit"
            className="py-2 px-4 mx-auto my-3 border-0 rounded-lg bg-[#e15726] text-white text-xl h-fit"
          >
            Checkout
          </Button>
        </form>
      </div>

      <Footer />
    </main>
  );
}

export default GiftingForm;
