import {
    Form,
    Input,
    Button,
    //Checkbox,
    Row,
    Col,
    notification,
    //Result,
    //Modal,
  } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { mclOpen } from "../../api/Mcl";
import { Url } from "../../constants/Global";
import { useNavigate } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { useCallback } from "react";


const openNotification = ({ message, status, title, subTitle }) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: (
      /*<Result
        status={status}
        title={title}
        subTitle={subTitle}
      />*/
      <span>
        <p>{status}</p>
        <p>{title}</p>
        <p>{subTitle}</p>
      </span>
    ),
  });
};

const ForgotPassword = () => {
  const navigate = useNavigate();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
        console.log('Execute recaptcha not yet available');
        return;
    }

    const token = await executeRecaptcha('resetPasswordRequest');
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const onFinish = async (values) => {
    const email = values.email;
    try {
      let recaptchaToken = await handleReCaptchaVerify();
      const response = await mclOpen.post(
        Url.FORGOT_PASSWORD,
        JSON.stringify({
          email: email
        }),
        {
          headers: { "Content-Type": "application/json" },
          params: { token: recaptchaToken },
          withCredentials: true,
        }
      );
      navigate("/site/reset-password-confirm");
    }
    catch (err){
      console.log(err);
      if (!err?.response) {
        openNotification({
          message: "No Server Response",
          status: "500",
          title: "500",
          subTitle: "",
        });
      }
      else if (err.response?.status === 400){
        openNotification({
          message: "Invalid Email",
          status: "400",
          title: "Please enter a valid email",
          subTitle: "",
        });
      }
      else {
        openNotification({
          message: "Something went Wrong",
          status: "500",
          title: "Something went Wrong",
          subTitle: "",
        });
      }
    }
  };
  return (
    <Row
      type="flex"
      justify="center"
      align="middle"
      style={{ minHeight: "100vh" }}
    >
      <Col>
        <Form
          id="login"
          name="normal_login"
          className="login-form"
          initialValues={{
            email: "",
            password: "",
            remember: true,
          }}
          onFinish={onFinish}
        >
          <h1
            align="middle"
          >
            Account Recovery
          </h1>
          <p
            align="middle"
          >
            Enter your email to recover your account
          </p>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your Email address!",
              },
            ]}
          >
            <Input
              prefix={<MailOutlined className="site-form-item-icon" />}
              placeholder="Email"
            />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
            >
              Reset
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};


export default ForgotPassword;