import React from "react";
import _ from "lodash";

import { Link } from "react-router-dom";
import { Divider, Space } from "antd";
import logo from "../assets/images/logos/mcl-logo-color.svg";
import logoText from "../assets/images/logos/mcl-logo-purple-horizontal-text-only-new.png";
import signInImage from "../assets/images/sign-in.png";

const InvitationSideCard = ({ inviter, circle }) => {
  return (
    <div className="flex flex-col h-full justify-center">
      <div className="w-full rounded-2xl bg-white/90 drop-shadow">
        <div className="flex flex-col">
          <div className="p-8">
            <span className="whitespace-nowrap">
              <Link
                to="/"
                onClick={() => {
                  window.scrollTo({
                    left: 0,
                    top: 0,
                    behavior: "auto",
                  });
                }}
              >
                <img
                  className="inline-block"
                  src={logo}
                  height="50"
                  weignt="50"
                  alt="My CareLinq Icon"
                />
                <img
                  className="inline-block ml-4"
                  src={logoText}
                  height="40"
                  alt="My CareLinq"
                />
              </Link>
            </span>
            <div className="mt-20">
              <span className="text-new-primary-purple text-3xl leading-9">
                {!_.isNil(inviter?.firstName)
                  ? `${inviter.firstName}, has invited you to join`
                  : "Welcome to"}
                {!_.isNil(circle?.firstName) ? (
                  <>
                    <br></br>
                    <b className="text-3xl">{`${circle.firstName} ${circle.lastName}'s`}</b>{" "}
                    care circle
                  </>
                ) : (
                  <b className="text-3xl">{" "} MyCareLinq</b>
                )}
              </span>
            </div>
            <div className="mt-4">
              <span className="text-dark-purple/60 text-base leading-5">
                Joining MyCareLinq will centralise all your care efforts and
                have all the information you need
              </span>
            </div>
          </div>
          <div className="mt-20 flex flex-col justify-end align-bottom flex-1 overflow-hidden rounded-br-2xl rounded-bl-2xl">
            <img
              className="inline-block max-h-[417px]"
              src={signInImage}
              alt="My CareLinq"
            />
          </div>
        </div>
      </div>
      <div className="w-full flex mt-4 text-sm font-medium text-dark-purple/80">
        <span>
          Copyright© <b>MyCarelinq</b> 2023
        </span>
        <Space
          className="text-center ml-auto"
          split={
            <Divider
              style={{ backgroundColor: "rgba(60, 48, 102, 0.3)" }}
              type="vertical"
            />
          }
          size={0}
        >
          <Link
            className="text-dark-purple/80"
            to="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          <Link
            className="text-dark-purple/80"
            to="/privacy-policy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms and Conditions
          </Link>
        </Space>
      </div>
    </div>
  );
};

export default InvitationSideCard;
