import React, { useState, useEffect, useCallback } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Table,
  Tag,
  Breadcrumb,
  notification,
  Result,
} from "antd";
import _ from "lodash";
import useMclAuth from "../../hooks/useMclAuth";
import useAuth from "../../hooks/useAuth";
import {
  Url,
  SubscriptionToColor,
  SubscriptionToStr,
  DispositionCode,
  Subscription
} from "../../constants/Global";
import ClientDetail from "./ClientDetail";
import { openNotification } from "../../components/Various";

const ClientsTable = ({ data, setCurrentClientId }) => {
  const renderTier = (subscription) => {
    return (
      <Tag color={SubscriptionToColor(subscription)}>
        {SubscriptionToStr(subscription).toUpperCase()}
      </Tag>
    );
  };

  const columns = [
    {
      title: "Type",
      key: "subscription",
      dataIndex: "subscription",
      render: (subscription) => <>{renderTier(subscription)}</>,
      filters:
                Object.entries(Subscription).map((item) => {
                    return ({
                        text: SubscriptionToStr(item[1]),
                        value: item[1],
                    })
                })
            ,
            onFilter: (value, record) => record.subscription === value,
    },

    {
      title: "Primary Caregiver",
      key: "primaryCaregiver",
      render: (text, record) => {
        let name = "Name Error";
        if (record.primaryCare?.length > 0) {
          name = `${record.primaryCare[0].firstName} ${record.primaryCare[0].lastName}`;
        }
        return (
          <Button
            type="link"
            className="text-start p-0 m-0"
            block
            onClick={() => setCurrentClientId(record.id)}
          >
            {name}
          </Button>
        );
      },
    },
    {
      title: "Care Recipient",
      dataIndex: "name",
      key: "name",
      render: (text, { id }, index) => text,
    },
    {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
    {
      title: "Disposition",
      dataIndex: "disposition",
      key: "disposition",
      render: (disposition) => <p>{DispositionCode[disposition]}</p>,
    },
  ];
  return <Table columns={columns} dataSource={data} />;
};

const MyClient = ({ setPage }) => {
  const mclAuth = useMclAuth();
  const { auth } = useAuth();

  const [cg, setCg] = useState({});
  const [clients, setClients] = useState([]);
  const [currentClientId, setCurrentClientId] = useState(undefined);
  const [currentClientDetail, setCurrentClientDetail] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    mclAuth
      .get(Url.CONCIERGES.concat("/", auth?.id))
      .then((res) => {
        setCg(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        openNotification({
          message: error.message,
          status: "500",
          title: "500",
          subTitle: "Failed to get care concierge clients",
        });
        setError(error.message);
        setIsLoading(false);
      });
  }, [auth.id, mclAuth]);

  useEffect(() => {
    if (cg && cg.relationships) {
      const clientData = [
        ...cg.relationships.map((item) => {
          let client = item.clientId;
          let circle = item.circleId;
          return {
            ...item,
            key: item._id,
            id: item._id,
            clientId: client._id,
            name: (circle.firstName || circle.lastName) ? circle.firstName + " " + circle.lastName : "Name is empty",
            firstName: circle.firstName ? circle.firstName : "",
            lastName: circle.lastName ? circle.lastName : "",
            disposition: client.__disposition ? client.__disposition : 0,
            consultDate: client.__lastConsultDate,
            survey: client.survey,
            roles: client.roles,
            email: client.email,
            primaryCare: circle.primary ? circle.primary : [],
            subscription: !_.isNil(circle.subscription)
              ? circle.subscription
              : 0,
          };
        }),
      ];
      setClients(clientData);
    }
  }, [cg]);

  useEffect(() => {
    if (auth.id && mclAuth && !_.isNil(currentClientId)) {
      let clientData = clients.find((item) => item.id === currentClientId);

      clientData.background = _.sortBy(
        clientData.background,
        "updatedAt"
      ).reverse();
      clientData.scopeOfWorks = _.sortBy(
        clientData.scopeOfWorks,
        "updatedAt"
      ).reverse();
      clientData.caseNotes = _.sortBy(
        clientData.caseNotes,
        "createdAt"
      ).reverse();
      clientData.goals = _.sortBy(clientData.goals, "updatedAt").reverse();

      let clientItem = {
        ...clientData,
        primaryCare:
          clientData.primaryCare[0].firstName +
          " " +
          clientData.primaryCare[0].lastName,
      };

      setCurrentClientDetail(clientItem);
    } else if (_.isNil(currentClientId)) {
      setCurrentClientDetail({});
    }
  }, [auth.id, mclAuth, clients, currentClientId]);

  const updateNote = useCallback(
    (url, data) => {
      mclAuth
        .post(Url.CONCIERGES.concat(url), data)
        .then((res) => {
          let newData = res.data;
          newData.background = _.sortBy(
            newData.background,
            "updatedAt"
          ).reverse();
          newData.scopeOfWorks = _.sortBy(
            newData.scopeOfWorks,
            "updatedAt"
          ).reverse();
          newData.caseNotes = _.sortBy(
            newData.caseNotes,
            "createdAt"
          ).reverse();
          newData.goals = _.sortBy(newData.goals, "updatedAt").reverse();
          setCurrentClientDetail((prevState) => ({
            ...prevState,
            ...res.data,
          }));
          mclAuth
            .get(Url.CONCIERGES.concat("/", auth?.id))
            .then((res) => {
              setCg(res.data);
              setIsLoading(false);
            })
            .catch((error) => {
              openNotification({
                message: error.message,
                status: "500",
                title: "500",
                subTitle: "Failed to get care concierge clients",
              });
              setError(error.message);
              setIsLoading(false);
            });
        })
        .catch((error) => {
          openNotification({
            message: error.message,
            status: "500",
            title: "500",
            subTitle: "Failed to update note",
          });
          setError(error.message);
          setIsLoading(false);
        });
    },
    [auth.id, mclAuth]
  );

  const deleteNote = useCallback(
    (url, data) => {
      mclAuth
        .delete(Url.CONCIERGES.concat(url), data)
        .then((res) => {
          let newData = res.data;
          newData.background = _.sortBy(
            newData.background,
            "updatedAt"
          ).reverse();
          newData.scopeOfWorks = _.sortBy(
            newData.scopeOfWorks,
            "updatedAt"
          ).reverse();
          newData.caseNotes = _.sortBy(
            newData.caseNotes,
            "updatedAt"
          ).reverse();
          newData.goals = _.sortBy(newData.goals, "updatedAt").reverse();
          setCurrentClientDetail((prevState) => ({
            ...prevState,
            ...res.data,
          }));
          setIsLoading(false);
        })
        .catch((error) => {
          openNotification({
            message: error.message,
            status: "500",
            title: "500",
            subTitle: "Failed to delete note",
          });
          setError(error.message);
          setIsLoading(false);
        });
    },
    [mclAuth]
  );

  const homeBreadCrumbOnClick = (e) => {
    e.preventDefault();
    setPage("0");
  };

  const clientBreadCrumbOnClick = (e) => {
    e.preventDefault();
    setCurrentClientId(undefined);
  };

  return (
    <div>
      <Row>
        <Col xs={24}>
          <Breadcrumb separator=">" className="text-h6">
            <Breadcrumb.Item>
              <a href="#" onClick={homeBreadCrumbOnClick}>
                My Dashboard
              </a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="#" onClick={clientBreadCrumbOnClick}>
                My Clients
              </a>
            </Breadcrumb.Item>
            {!_.isEmpty(currentClientDetail) && (
              <Breadcrumb.Item>
                {currentClientDetail.firstName +
                  " " +
                  currentClientDetail.lastName}
              </Breadcrumb.Item>
            )}
          </Breadcrumb>
        </Col>
      </Row>
      {!_.isEmpty(currentClientDetail) ? (
        <ClientDetail
          className="mt-4"
          clientID={currentClientDetail.clientId._id}
          clientDetail={currentClientDetail}
          CCId={auth.id}
          CCName={cg ? cg.firstName + " " + cg.lastName : ""}
          updateNote={updateNote}
          deleteNote={deleteNote}
        />
      ) : (
        <Card className="mt-4">
          <ClientsTable
            data={clients}
            setCurrentClientId={setCurrentClientId}
          />
        </Card>
      )}
    </div>
  );
};

export default MyClient;
