import React, { useState, useEffect } from "react";
import {TeamOutlined} from '@ant-design/icons'
import {RiTeamLine, RiUserHeartLine} from 'react-icons/ri'


import imgAvatar from "../assets/images/messaging/avatar.png"
import { Badge } from "antd";


const Chat = ({chatName, isSingle, hasNew}) => {
  const isOne = (typeof isSingle !== "undefined") && isSingle
  const isNew = (typeof hasNew !== "undefined") && hasNew 

  return (
    <div className="chat">
        {/* {(typeof isSingle !== "undefined") && isSingle  ? <RiUserHeartLine className="chatImg" /> : <RiTeamLine className="chatImg"/>} */} 
        {/* {(typeof hasNew !== "undefined") && hasNew  ? <Badge dot>{icon}</Badge> : {icon}} */}
        { isOne  ? (isNew ? <Badge dot  className="chatImg" ><RiUserHeartLine/></Badge> : <RiUserHeartLine className="chatImg" />) 
          : (isNew ? <Badge dot className="chatImg"><RiTeamLine/></Badge> : <RiTeamLine className="chatImg"/>)
        }
        
        {/* <TeamOutlined className="chatImg"/> */}
{/*       <img
        className="chatImg"
        src={imgAvatar }
        // src={
        //   user?.profilePicture
        //     ? PF + user.profilePicture
        //     : PF + "person/noAvatar.png"
        // }
        alt=""
      />
 */}      <span className="chatName">{chatName}</span>
    </div>
  )
}
export default Chat