import React from "react";
import { Card, Row, Col } from "antd";
import ccImg from "../../assets/images/supercc/cc.svg";
import clientLeadsImg from "../../assets/images/supercc/clientleads.svg";
import reportsImg from "../../assets/images/supercc/reports.svg";
import kbImg from "../../assets/images/supercc/kb.svg";
import chatImg from "../../assets/images/supercc/chat.svg";

const { Meta } = Card;

const Dashboard = ({ setPage, getLabel }) => {
  return (
    <>
      <Row>
        <Col flex={1}>
          <div onClick={() => setPage("cc")}>
            <Card
              hoverable
              style={{
                width: 200,
                height: 200,
              }}
              cover={<img alt="Care Concierges" src={ccImg} />}
            >
              {/* <Meta title="My Support Journal" description="" /> */}
              <Meta title={getLabel("cc")} description="" />
            </Card>
          </div>
        </Col>
        <Col flex={1}>
          <div onClick={() => setPage("clientLeads")}>
            <Card
              hoverable
              style={{
                width: 200,
                height: 200,
              }}
              cover={<img alt="CC List" src={clientLeadsImg} />}
            >
              <Meta title={getLabel("clientLeads")} description="" />
            </Card>
          </div>
        </Col>
        <Col flex={1}>
          <div onClick={() => setPage("reports")}>
            <Card
              hoverable
              style={{
                width: 200,
                height: 200,
              }}
              cover={<img alt="Reports" src={reportsImg} />}
            >
              <Meta title={getLabel("reports")} description="" />
            </Card>
          </div>
        </Col>
      </Row>
      <Row style={{ height: 50 }} />
      <Row>
        <Col flex={1}>
          <div onClick={() => setPage("kb")}>
            <Card
              hoverable
              style={{
                width: 200,
                height: 200,
              }}
              cover={<img alt="Knowledge Base" src={kbImg} />}
            >
              <Meta title={getLabel("kb")} description="" />
            </Card>
          </div>
        </Col>
        <Col flex={1}></Col>
        <Col flex={1}></Col>
      </Row>
      <Row>
        <Col flex={1}>
          <div onClick={() => setPage("chat")}>
            <Card
              hoverable
              style={{
                width: 200,
                height: 200,
              }}
              cover={<img alt="Messaging" src={chatImg} />}
            >
              <Meta title={getLabel("chat")} description="" />
            </Card>
          </div>
        </Col>
        <Col flex={1}></Col>
        <Col flex={1}></Col>
      </Row>
    </>
  );
};

export default Dashboard;
