import React, { useState, useCallback } from "react";
import ReactGA from "react-ga4";
//import "./Product.css";
import "./Prepaid.css";
import { ToUContent } from "../../components/TermsOfUse";
import { SAContent } from "../../components/SA";
import useAuth from "../../hooks/useAuth";
import useMclAuth from "../../hooks/useMclAuth";
import { useNavigate } from "react-router";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Col, Row, Button, Modal, Checkbox, Collapse } from "antd";
import { Url } from "../../constants/Global";
import { mclOpen } from "../../api/Mcl";

const { Panel } = Collapse;

const isTest = process.env.REACT_APP_TEST === "yes";

function ServiceDiv(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEmailModalVisible, setIsEmailModalVisible] = useState(false);
  const [tempEmail, setTempEmail] = useState("");
  const [touChecked, setTouChecked] = useState(false);
  const [saChecked, setSaChecked] = useState(false);
  const [subscribeNew, setSubscribeNew] = useState(true);
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();
  const navigate = useNavigate();

  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("checkoutSession");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const listFeatures = props.product_features.map((feature, index) => {
    if (index < props.marker) {
      return (
        <li key={index} className="m-0 mb-2 p-0 flex features-orange">
          <strong className="m-0 p-0">{feature}</strong>
        </li>
      );
    } else {
      return (
        <li key={index} className="m-0 mb-2 p-0 flex features-orange">
          <p className="m-0 p-0">{feature}</p>
        </li>
      );
    }
  });

  const startButtonClick = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'pricing',
      action: 'click_button',
      label: 'start_prepaid_' + props.inSub
    });
    if (!tempEmail && !auth.accessToken) {
      setIsEmailModalVisible(true);
    } else if (auth.accessToken) {
      mclAuth.get(Url.CIRCLES.concat("/checkPrimary")).then((res) => {
        if (res.data === "yes") {
          if (props.inSub != "None" && props.inSub != "FREE") {
            // upgrading
            setSubscribeNew(false);
          } else {
            // first time subscription
            setSubscribeNew(true);
          }
          setIsModalVisible(true);
          ReactGA.event({
            category: 'pricing',
            action: 'open_modal',
            label: 'confirm_modal',
            nonInteraction: true,
          });
        } else {
          alert("Only primary caregiver can make payment.");
        }
      });
    }
  };

  const handleModalSubmit = (event) => {
    event.preventDefault();

    setIsModalVisible(false);
    if (!subscribeNew) {
      ReactGA.event({
        category: 'pricing',
        action: 'submit_subscribe',
        label: 'upgrade',
        nonInteraction: true,
      });
      mclAuth
        .get(Url.PAYMENT.concat("/upgradeStripeSubs/" + props.product_ID))
        .then((res) => {
          alert(res.data);
          mclAuth
            .get(Url.PAYMENT.concat("/currentPlan"))
            .then((res) => {
              if (res.data) {
                props.setCurrentPlan(res.data);
                mclAuth
                  .get(Url.PAYMENT.concat("/subExpiration"))
                  .then((res) => {
                    if (res) {
                      props.setExpiration(
                        res.data === 0
                          ? ""
                          : ", expiration:" +
                          new Intl.DateTimeFormat("en-US").format(
                            res.data * 1000
                          )
                      );
                    }
                  });
              }
            })
            .catch((error) => {
              console.log(error.message);
            });
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      const recaptchaFirst = async () => {
        ReactGA.event({
          category: 'pricing',
          action: 'submit_subscribe',
          label: 'new_subscribe',
          nonInteraction: true,
        });
        let recaptchaToken = await handleReCaptchaVerify();

        if (recaptchaToken) {
          if (auth.accessToken) {
            mclAuth.get(Url.PAYMENT.concat("/stripeId")).then((res) => {
              // Get the recaptcha token, submit it with the data to the backend
              if (res.data) {
                mclOpen
                  .post(
                    Url.PAYMENT.concat("/createStripeSubs"),
                    {
                      customerID: res.data,
                      productID: props.product_ID,
                      priceID: props.product_priceID,
                      price: props.product_price,
                    },
                    {
                      params: {
                        token: recaptchaToken,
                      },
                    }
                  )
                  .then((res) => {
                    localStorage.removeItem("accessToken");
                    localStorage.removeItem("tempEmail");
                    localStorage.setItem("accessToken", auth.accessToken);
                    window.location.href = res.data;
                  });
              } else {
                mclAuth.get(Url.PAYMENT.concat("/username")).then((res) => {
                  mclOpen
                    .post(
                      Url.PAYMENT.concat("/createStripeSubs"),
                      {
                        firstName: res.data[0],
                        lastName: res.data[1],
                        email: auth.email,
                        productID: props.product_ID,
                        priceID: props.product_priceID,
                        price: props.product_price,
                      },
                      {
                        params: {
                          token: recaptchaToken,
                        },
                      }
                    )
                    .then((res) => {
                      localStorage.removeItem("accessToken");
                      localStorage.removeItem("tempEmail");
                      localStorage.setItem("accessToken", auth.accessToken);
                      window.location.href = res.data;
                    });
                });
              }
            });
          } else {
            // for non login users
            ReactGA.event({
              category: 'pricing',
              action: 'submit_subscribe',
              label: 'for_non_login_users',
              nonInteraction: true,
            });
            mclOpen
              .post(
                Url.PAYMENT.concat("/createStripeSubs"),
                {
                  email: tempEmail,
                  productID: props.product_ID,
                  priceID: props.product_priceID,
                  price: props.product_price,
                },
                {
                  params: {
                    token: recaptchaToken,
                  },
                }
              )
              .then((res) => {
                localStorage.removeItem("accessToken");
                localStorage.removeItem("tempEmail");
                localStorage.setItem("tempEmail", tempEmail);
                window.location.href = res.data;
              });
          }
        } else {
          ReactGA.event({
            category: 'pricing',
            action: 'submit_subscribe',
            label: 'subscribe_failed_missing_recaptcha',
            nonInteraction: true,
          });
          alert("Missing recaptcha token.");
        }
      };

      recaptchaFirst();
    }
  };

  return (
    <div className="p-0 m-0 h-full flex flex-col">
      <Modal
        title={
          "You are about to " +
          (subscribeNew ? "subscribe to a new plan" : "change plan") +
          ". Please read through the following contents."
        }
        visible={isModalVisible}
        centered
        className="checkoutModal"
        onCancel={() => {
          setIsModalVisible(false);
          setTempEmail("");
        }}
        footer={(null, null)}
      >
        <Collapse
          accordion
          defaultActiveKey={["1"]}
          className="checkoutCollapse"
        >
          <Panel header="Terms of Use" key="1" className="max-h-fit">
            <ToUContent className="mt-5" />
          </Panel>
          <Panel header="SUBSCRIPTION AGREEMENT" key="2" className="max-h-fit">
            <SAContent className="mt-5" />
          </Panel>
        </Collapse>
        <Row className="my-5 justify-center">
          <Checkbox
            onChange={() => {
              setTouChecked(!touChecked);
            }}
          >
            I acknowledge the <strong>term of use</strong>
          </Checkbox>
          <Checkbox
            onChange={() => {
              setSaChecked(!saChecked);
            }}
          >
            I acknowledge the <strong>subscription agreement</strong>
          </Checkbox>
        </Row>
        <Row className="mb-5 justify-center">
          <form
            action={Url.BASE.concat(Url.PAYMENT.concat("/createStripeSubs"))}
            method="POST"
            onSubmit={handleModalSubmit}
          >
            <input type="hidden" name="productID" value={props.product_ID} />
            <input type="hidden" name="priceID" value={props.product_priceID} />
            <input type="hidden" name="price" value={props.product_price} />
            <Button disabled={!touChecked || !saChecked} htmlType="submit">
              continue
            </Button>
          </form>
        </Row>
      </Modal>
      <Modal
        title={"You have not logged in."}
        visible={isEmailModalVisible}
        centered
        onCancel={() => {
          ReactGA.event({
            category: 'pricing',
            action: 'close_modal',
            label: 'email_modal'
          });
          setIsEmailModalVisible(false);
          setTempEmail("");
        }}
        footer={(null, null)}
      >
        <p>
          Please provide your <strong>email address</strong> below then you can
          continue!
        </p>
        <p>
          or simply{" "}
          <Button
            className={
              "mx-auto mb-3 border-0 rounded-lg bg-[#44cff2] text-white h-fit"
            }
            onClick={() => {
              ReactGA.event({
                category: 'pricing',
                action: 'click_button',
                label: 'login_email_modal'
              });
              navigate("/site/login")
            }}
          >
            log in
          </Button>{" "}
          if you have an account.
        </p>
        <input
          className="mx-4 my-0 w-1/2 rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0"
          value={tempEmail}
          onChange={(event) => {
            setTempEmail(event.target.value);
          }}
        />
        <Button
          disabled={!tempEmail}
          onClick={() => {
            ReactGA.event({
              category: 'pricing',
              action: 'click_button',
              label: 'continue_email_modal'
            });
            setIsEmailModalVisible(false);
            mclOpen
              .get(Url.PAYMENT.concat("/userFromEmail/", tempEmail))
              .then((result) => {
                if (result.data) {
                  alert(
                    "Looks like you already have an account! Please login to continue."
                  );
                  navigate("/site/login");
                } else {
                  setIsModalVisible(true);
                }
              });
          }}
        >
          continue
        </Button>
      </Modal>
      <Row className="p-0 m-0 text-[#e0551b]">
        <Col xs={12} className="p-0">
          <p className="mt-5 mb-3 text-right text-6xl">{props.product_price}</p>
        </Col>
        <Col xs={12} className="p-0">
          <p className="mt-5 mb-3 xs:text-[1.25rem] sm:text-[1.25rem] md:text-[1rem] lg:text-[1.5rem] xl:text-[1.5rem]">
            $<br />/ 3 MONTHS
          </p>
        </Col>
      </Row>
      <Row className="m-0 mb-3 p-0 h-24">
        <Col xs={24}>
          <p className="px-10 m-0 text-center xs:text-[1.5rem] sm:text-[1.5rem] md:text-[1rem] lg:text-[1.25rem] xl:text-[1.5rem]">
            <span className="text-[#e0551b]">10% off</span>, {props.product_des}
          </p>
        </Col>
      </Row>
      <Row className="my-3 p-0 h-fit">
        <Button
          className="mx-auto mb-3 py-2 border-0 rounded-lg bg-[#44cff2] text-white text-xl h-fit"
          onClick={startButtonClick}
        >
          Get Started
        </Button>
      </Row>
      <ul className="mx-12 pl-4 list-none grow xs:text-[1.5rem] sm:text-[1.5rem] md:text-[1rem] lg:text-[1.25rem] xl:text-[1.5rem]">
        {listFeatures}
      </ul>
    </div>
  );
}

function ContributeForm() {
  const [value, setValue] = useState(0);
  const [customValue, setCustomValue] = useState(0);
  const [choosingCustom, setChoosingCustom] = useState(false);

  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("checkoutSession");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const handleChange = (event) => {
    setValue(event.target.value);
    setChoosingCustom(false);
  };

  const handleCustom = (event) => {
    setValue(event.target.value);
    setChoosingCustom(true);
  };

  const handleTextChange = (event) => {
    if (choosingCustom) {
      setValue(event.target.value);
      setCustomValue(event.target.value);
    } else {
      setCustomValue(event.target.value);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    ReactGA.event({
      category: 'pricing',
      action: 'click_button',
      label: 'submit_contribute'
    });
    if (!auth.accessToken) {
      navigate("/site/login");
    } else {
      if (!value || value <= 0) {
        alert("Please enter a valid number.");
      } else {
        const recaptchaFirst = async () => {
          let recaptchaToken = await handleReCaptchaVerify();

          if (recaptchaToken) {
            // Get the recaptcha token, submit it with the data to the backend
            mclAuth.get(Url.PAYMENT.concat("/stripeId")).then((res) => {
              if (res.data) {
                mclOpen
                  .post(
                    Url.PAYMENT.concat("/createStripePayment"),
                    {
                      customerID: res.data,
                      amount: value,
                    },
                    {
                      params: {
                        token: recaptchaToken,
                      },
                    }
                  )
                  .then((res) => {
                    window.location.href = res.data;
                  });
              } else {
                mclAuth.get(Url.PAYMENT.concat("/username")).then((res) => {
                  mclOpen
                    .post(
                      Url.PAYMENT.concat("/createStripePayment"),
                      {
                        firstName: res.data[0],
                        lastName: res.data[1],
                        email: auth.email,
                        amount: value,
                      },
                      {
                        params: {
                          token: recaptchaToken,
                        },
                      }
                    )
                    .then((res) => {
                      window.location.href = res.data;
                    });
                });
              }
            });
          }
        };

        recaptchaFirst();
      }
    }
  };

  return (
    <form
      method="POST"
      onSubmit={handleSubmit}
      action={Url.BASE.concat(Url.PAYMENT.concat("/createStripePayment"))}
      className="flex flex-col xs:text-[2rem] sm:text-[2rem] md:text-[0.8rem] lg:text-[1.75rem] xl:text-[1.5rem] leading-6"
    >
      <label className="my-3 mx-10 px-10 radio-input relative">
        <input
          type="radio"
          name="amount"
          value={1000}
          className="cursor-pointer radio-button"
          onChange={handleChange}
        />
        <span className="inset-0 check-mark cursor-pointer"></span>
        <strong className="m-0 p-0 cursor-pointer">1000</strong>
      </label>
      <label className="my-3 mx-10 px-10 radio-input relative">
        <input
          type="radio"
          name="amount"
          value={2000}
          className="cursor-pointer radio-button relative"
          onChange={handleChange}
        />
        <span className="inset-0 check-mark cursor-pointer"></span>
        <strong className="m-0 p-0 cursor-pointer">2000</strong>
      </label>
      <label className="my-3 mx-10 px-10 radio-input relative">
        <input
          type="radio"
          name="amount"
          value={3000}
          className="cursor-pointer radio-button"
          onChange={handleChange}
        />
        <span className="inset-0 check-mark cursor-pointer"></span>
        <strong className="m-0 p-0 cursor-pointer">3000</strong>
      </label>
      <label className="my-2 mx-10 px-10 radio-input relative flex items-center">
        <input
          type="radio"
          name="amount"
          value={customValue}
          className="cursor-pointer radio-button"
          onChange={handleCustom}
        />
        <span className="inset-0 top-1 check-mark cursor-pointer"></span>
        <strong className="m-0 p-0 cursor-pointer">OWN AMOUNT:</strong>
        <input
          type="number"
          value={customValue}
          className="mx-4 my-0 w-[5rem] rounded-md border-2 border-solid border-[#211c40] focus:border-[#e15726] focus:outline-0 no-arrow"
          onChange={handleTextChange}
        />
      </label>
      <Button
        htmlType="submit"
        className="py-2 px-4 mx-auto my-3 border-0 rounded-lg bg-[#e0551b] hover:bg-[#a82400] hover:text-white text-white text-xl h-fit"
      >
        PAY NOW
      </Button>
    </form>
  );
}

function ContactButton() {
  const navigate = useNavigate();
  const { auth, setAuth } = useAuth();

  return (
    <Button
      className="mx-auto my-3 py-2 border-0 rounded-lg bg-[#44cff2] text-white text-xl h-fit"
      onClick={() => {
        if (auth.accessToken) {
          navigate("/gifting");
        } else {
          navigate("/site/login");
        }
      }}
    >
      CONTACT US
    </Button>
  );
}

class PrepaidProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productIDs: isTest
      ? props?.b2b 
      ? ["prod_LtEMincivjOiZJ", "prod_LtEMincivjOiZJ"] : ["prod_LtEL03zgzg94T0", "prod_LtEMincivjOiZJ"]
        : ["prod_M2KEKoFNAnVWDG", "prod_M2KFqS9srP8k6u"],
      descriptions: [
        "Continuous Support",
        "Full Service & More Care Concierge hours and enhanced services",
        "Contribute a Different Dollar Amount",
      ],
      features: [
        [
          "1 Free 60-minute consultation",
          "Monthly 30-minute appointments",
          "Develop goals and customize plan and strategy to resolve challenges",
          "Curate the right resources based on need",
          "Chat and Email ",
          "Upload documents",
        ],
        [
          "1 Free 60-minute consultation",
          "2x 60-minute appointments per month",
          "Full-service navigating:",
          "Benefits (cost and coverage)",
          "Insurance (costs, claims)",
          "Legal & financial help",
          "Evaluate and connect providers ",
          "Personal shopping",
          "Home care support",
          "Social needs",
          "End-of-Life support",
          "After-Life care support",
          "All Tools",
        ],
      ],
      priceIDs: Array(2).fill(""),
      prices: props?.b2b ? [15900, 53700] : [15900, 53700],
    };

    var count = this.state.productIDs.length;
    const pricesIDCopy = this.state.priceIDs.slice();
    const pricesCopy = this.state.priceIDs.slice();
    this.state.productIDs.map((productID, index) => {
      mclOpen
        .get(Url.PAYMENT.concat("/product/", productID))
        .then((result) => {
          pricesIDCopy[index] = result.data.default_price;
        })
        .then(() => {
          if (pricesIDCopy[index] === null) {
            pricesCopy[index] = 0;
            count -= 1;

            if (count === 0) {
              this.setState({
                priceIDs: pricesIDCopy,
                //prices: pricesCopy,
              });
            }
          } else {
            mclOpen
              .get(Url.PAYMENT.concat("/price/", pricesIDCopy[index]))
              .then((result) => {
                pricesCopy[index] = result.data.unit_amount;
                count -= 1;
              })
              .then(() => {
                if (count === 0) {
                  this.setState({
                    // priceIDs: pricesIDCopy,
                    prices: pricesCopy,
                  });
                }
              });
          }
        });
    });
  }

  render() {
    return (
      <div className="p-0 lg:container lg:mx-auto bg-[url('assets/images/pricing/products-bg.png')] bg-center bg-no-repeat bg-cover overflow-hidden flex">
        <Row className="p-0 m-0">
          <Col
            xs={24}
            md={8}
            className="p-0 m-0 vertical-divider flex flex-col"
          >
            <Row className="p-0 m-0">
              <Col
                xs={24}
                className="p-0 m-0 h-28 text-center bg-[#618bb5] flex flex-col justify-center"
              >
                <p className="p-1 m-0 xs:text-[2rem] sm:text-[2rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[1.5rem] text-white">
                  <strong>PREMIUM</strong>
                </p>
              </Col>
            </Row>
            <Row className="p-0 m-0 grow">
              <Col xs={24} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[0]}
                  product_priceID={this.state.priceIDs[0]}
                  product_price={this.state.prices[0] / 100}
                  product_des={this.state.descriptions[0]}
                  product_features={this.state.features[0]}
                  marker={1}
                />
              </Col>
            </Row>
          </Col>
          <Col
            xs={24}
            md={8}
            className="p-0 m-0 vertical-divider flex flex-col"
          >
            <Row className="p-0 m-0">
              <Col
                xs={24}
                className="p-0 m-0 h-28 text-center bg-[#786f97] flex flex-col justify-center"
              >
                <p className="m-0 p-1 xs:text-[2rem] sm:text-[2rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[1.5rem] text-white">
                  <strong>ALL ACCESS</strong>
                </p>
              </Col>
            </Row>
            <Row className="p-0 m-0 grow">
              <Col xs={24} className="p-0">
                <ServiceDiv
                  inSub={this.props.inSub}
                  setCurrentPlan={this.props.setCurrentPlan}
                  setExpiration={this.props.setExpiration}
                  product_ID={this.state.productIDs[1]}
                  product_priceID={this.state.priceIDs[1]}
                  product_price={this.state.prices[1] / 100}
                  product_des={this.state.descriptions[1]}
                  product_features={this.state.features[1]}
                  marker={2}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={8} className="p-0 m-0 flex flex-col">
            <Row className="p-0 m-0">
              <Col
                xs={24}
                className="p-0 m-0 h-28 text-center bg-[#ea916b] flex flex-col justify-center"
              >
                <p className="m-0 p-1 xs:text-[2rem] sm:text-[2rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[1.5rem] text-white">
                  <strong>INVEST</strong>
                </p>
                <p className="m-0 p-1 xs:text-[2rem] sm:text-[2rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[1.5rem] text-white">
                  IN OUR CAUSE
                </p>
              </Col>
            </Row>
            <Row className="p-0 m-0 grow">
              <Col xs={24} className="p-0 flex flex-col">
                <Row className="m-0 my-3 p-0 align-middle">
                  <Col xs={24} className="mt-5">
                    <p className="px-10 m-0 text-center text-2xl">
                      {this.state.descriptions[2]}
                    </p>
                  </Col>
                </Row>
                {<ContributeForm />}
                <Row className="mx-5 my-3 p-0 grow bg-[rgba(255,208,255,0.33)] bg-center bg-no-repeat bg-cover overflow-hidden">
                  <div className="m-0 p-0">
                    <Row className="m-0 p-0">
                      <Col xs={24} className="flex justify-center">
                        <p className="mx-12 my-6 p-0 text-center xs:text-[2rem] sm:text-[2rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[1.5rem]">
                          GIVE THE <strong>GIFT</strong> OF CARE
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 p-0">
                      <Col xs={24}>
                        <p className="mx-12 p-0 text-center xs:text-[2rem] sm:text-[2rem] md:text-[1.5rem] lg:text-[1.75rem] xl:text-[1.5rem]">
                          You may also{" "}
                          <span className="text-[#e15726]">
                            gift My CareLinq
                          </span>{" "}
                          to someone in need.
                        </p>
                      </Col>
                    </Row>
                    <Row className="my-3 p-0 h-fit bg-[url('assets/images/pricing/gift.png')] bg-center bg-no-repeat bg-contain overflow-hidden">
                      <Col xs={24}>
                        <img
                          className="invisible h-fit"
                          src={require("../../assets/images/pricing/gift.png")}
                        />
                      </Col>
                    </Row>
                    <Row className="m-0 p-0">
                      <ContactButton></ContactButton>
                    </Row>
                  </div>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export default PrepaidProduct;
