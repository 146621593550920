import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Form,
  Input,
  Button,
  Spin,
  Table,
  Menu,
  Dropdown,
  Tag,
  Modal,
  Radio,
  Select,
  Checkbox,
  Descriptions,
  Space,
  Col,
  Row,
  notification,
  Result,
  Card,
  message,
} from "antd";
import { Url, KB_CATEGORY, KB_SPECIALTY, US_STATES } from "../constants/Global";
import _ from "lodash";
import {
  MoreOutlined,
  SearchOutlined
} from "@ant-design/icons";
import useMclAuth from '../hooks/useMclAuth';
import {
  PlusOutlined,
  ShareAltOutlined,
  DeleteOutlined,
  EditOutlined,
  ArrowLeftOutlined,
  CopyOutlined
} from "@ant-design/icons";
import {
  openNotification
} from "../components/Various";
import { NIL } from "uuid";
import confirm from "antd/lib/modal/confirm";

const KnowledgeTable = ({
  data = [],
  isLoading = false,
  inCCKB = false,
  canToggleCC = false,
  selectedKnowledge = [],
  setCurrentKnowledge = () => { },
  setSelectedRows = () => { },
  setEditModalSetting = () => { },
  setShareModalSetting = () => { },
  setDeleteModalSetting = () => { },

  searchText,
  setSearchText,
  searchedColumn,
  setSearchedColumn
}) => {

  // const [searchText, setSearchText] = useState('');
  // const [searchedColumn, setSearchedColumn] = useState('');

  const [pageCurrent, setPageCurrent] = useState(0);
  const [pageSize, setPageSize] = useState(10);


  const searchInput = useRef(null);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
    setSearchText('');
  };

  const onPage = (page, size) => {
    setPageCurrent(page)
    setPageSize(size)
    // console.log(page, size)
  }

  const onClickName = (index) => {
    if (pageCurrent > 0) {
      setCurrentKnowledge((pageSize*(pageCurrent - 1) + index))
    } else {
      setCurrentKnowledge(index)
    }
  } 

  const global_search_props = 
          [
          "address",
          "category",
          "description",
          "email",
          "keywords",
          "link",
          "local",
          "name",
          "national",
          "phone",
          "pricing",
          "shareCircle",
          "shareCC",
          "specialty1",
          "specialty2",
          "state"
          ]


  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters, confirm)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    onFilter: (value, record) => {
      console.log('dataIndex:', dataIndex, 'col:', searchedColumn, ' value:', value)
      if (searchedColumn === 'all') {
        if (searchedColumn === '') return true;
        // console.log('record:', JSON.stringify(record, null, 1))

        for (var idx in global_search_props /* props */) {
          let found = false
          let prop = global_search_props[idx]
          let prop_val = record[prop] ? record[prop]?.toString().toLowerCase() : ""
          switch (dataIndex) {
            case "sourcedBy":
              prop_val = record[dataIndex]?.firstName.toLowerCase().includes(value.toLowerCase())
              break
            case "category":
              prop_val =  KB_CATEGORY.Strings[record[dataIndex]].toLowerCase().includes(value.toLowerCase())
              break
            case "specialty1":
              prop_val =  KB_SPECIALTY.Strings[record[dataIndex]].toLowerCase().includes(value.toLowerCase())
              break
            case "keywords":
              found = record[dataIndex].some(element => {
                return element.toLowerCase() === value.toLowerCase();
              });
              break
            default:
              // return record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
          }
          if (found || prop_val.includes(value.toLowerCase()) ) {
              // console.log('found! ', value)
              return true
          }
        }
        return false

        // let prop_vals = Object.keys(record).map(prop => (prop in props) ? record[prop].toString().toLowerCase(): "").join(' ') 
      }

      if (value == null) return true;

      switch (dataIndex) {
        case "sourcedBy":
          return record[dataIndex]?.firstName.toLowerCase().includes(value.toLowerCase())
        case "category":
          return KB_CATEGORY.Strings[record[dataIndex]].toLowerCase().includes(value.toLowerCase())
        case "specialty1":
          return KB_SPECIALTY.Strings[record[dataIndex]].toLowerCase().includes(value.toLowerCase())
        case "keywords":
          return record[dataIndex].some(element => {
            return element.toLowerCase() === value.toLowerCase();
          });
        default:
          // console.log('record searchval dataindex value:', value, dataIndex, record[dataIndex])
          return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase())
      }
    },
    render: (text) => text,
  });

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 300,
      ellipsis: true,
      filteredValue: [searchText],
      ...getColumnSearchProps('name'),
      render: (text, record, index) =>
        <Button type="link" className="text-start p-0 m-0" block onClick={() => onClickName(index)/*setCurrentKnowledge(index)*/}>
          {text}
        </Button>,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      width: 150,
      ...getColumnSearchProps('category'),
      render: (category) => (
        <>
          {KB_CATEGORY.Strings[category]}
        </>
      )
    },
    {
      title: 'Specialty 1',
      dataIndex: 'specialty1',
      width: 150,
      ...getColumnSearchProps('specialty1'),
      render: (specialty1) => (
        <>
          {KB_SPECIALTY.Strings[specialty1]}
        </>
      )
    },
    {
      title: 'Shared by',
      dataIndex: 'sourcedBy',
      width: 150,
      ...getColumnSearchProps('sourcedBy'),
      render: (sourcedBy) => (
        <>
          {`${_.get(sourcedBy, "firstName")} ${_.get(sourcedBy, "lastName")}`}
        </>
      )
    },
    {
      title: 'Keywords',
      dataIndex: 'keywords',
      width: 150,
      ...getColumnSearchProps('keywords'),
      render: (keywords) => (
        <>
          {keywords.map((keyword) => (
            <Tag color="blue" key={keyword}>
              {keyword}
            </Tag>
          ))}
        </>
      )
    },
    {
      title: 'Action',
      dataIndex: 'operation',
      key: 'operation',
      width: 100,
      fixed: 'right',
      render: (text, row) => {

        let menu = []
        if (row.__permission?.update) {
          menu.push(
            {
              key: 'edit',
              label: 'Edit',
            },
          )
        } else {
          menu.push(
            {
              key: 'edit',
              label: 'Edit',
              disabled: true,
            },
          )
        }
        if (row.__permission?.share) {
          menu.push(
            {
              key: 'share',
              label: 'Share',
            }
          )
        } else {
          menu.push(
            {
              key: 'share',
              label: 'Share',
              disabled: true,
            }
          )
        }
        if (row.__permission?.delete) {
          menu.push(
            {
              key: 'delete',
              label: 'Delete',
            },
          )
        } else {
          menu.push(
            {
              key: 'delete',
              label: 'Delete',
              disabled: true,
            },
          )
        }

        return (
          <Dropdown
            overlay={
              <Menu
                items={menu}
                onClick={
                  (e) => {
                    switch (e.key) {
                      case "edit":
                        setEditModalSetting((prevState) => ({
                          ...prevState,
                          isModalVisible: true,
                          selectedKnowledge: [row],
                          canToggleCC: !row.createdByCC || canToggleCC,
                          initialValues: _.pick(row, [
                            "address",
                            "category",
                            "description",
                            "email",
                            "keywords",
                            "link",
                            "local",
                            "name",
                            "national",
                            "phone",
                            "pricing",
                            "shareCircle",
                            "shareCC",
                            "specialty1",
                            "specialty2",
                            "state",
                          ]),
                          inCCKB: inCCKB
                        }))
                        break
                      case "share":
                        setShareModalSetting((prevState) => ({
                          ...prevState,
                          isModalVisible: true,
                          selectedKnowledge: [row],
                          canToggleCC: !row.createdByCC || canToggleCC,
                          shareCC: row.shareCC,
                          shareCircle: inCCKB ? undefined : row.shareCircle,
                          inCCKB: inCCKB
                        }))
                        break
                      case "delete":
                        setDeleteModalSetting((prevState) => ({
                          ...prevState,
                          isModalVisible: true,
                          selectedKnowledge: [row],
                        }))
                        break
                      default:
                        break
                    }
                  }
                }
              />
            }
          >
            <a>
              Action <MoreOutlined />
            </a>
          </Dropdown>
        )
      }
    }
  ];

  const rowSelection = {
    selectedRowKeys: _.isArray(selectedKnowledge) ? selectedKnowledge.map((item) => item._id) : [],
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: !inCCKB && (_.isNil(record.__permission) || !record.__permission.update),
      name: record.name,
    }),
  };


  return (
    <Spin
      spinning={isLoading}
    >
      <Table
        columns={columns}
        dataSource={data}
        rowKey="_id"
        bordered
        rowSelection={{ ...rowSelection }}
        scroll={{ x: 1000 }}
        pagination={{onChange:onPage}}
      />
    </Spin>
  )
}

const KnowledgeBaseShareModal = ({
  setting = {
    selectedKnowledge: [],
    isModalVisible: false,
    shareCircle: false,
    shareCC: false,
    canToggleCC: true,
    inCCKB: false,
  },
  isSubmitting = false,
  handleCancel = () => { },
  submitForm = () => { },
  form = undefined,
}) => {

  const onFinish = (values) => {
    submitForm(setting.selectedKnowledge, values);
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <Modal
      title={"Change Sharing settings"}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      onOk={form && form.submit}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={form && form.submit}
        >
          Save
        </Button>
      ]}
    >
      <Form
        className="shareKnowledgeForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
        initialValues={{
          shareCC: setting.shareCC,
          shareCircle: setting.shareCircle,
        }}
      >
        <label
          className="text-primary-purple"
          style={{ fontSize: "1rem" }}
        >
          Set the selected {_.isArray(setting.selectedKnowledge) && setting.selectedKnowledge.length} knowledge to
        </label>
        {
          !_.isNil(setting.shareCircle) &&
          <Form.Item
            name="shareCircle"
            valuePropName="checked"
          >
            <Checkbox
            >
              Share with circle
            </Checkbox>
          </Form.Item>
        }
        {
          (setting.inCCKB || (_.isArray(setting.selectedKnowledge) && setting.selectedKnowledge.length > 0 && setting.selectedKnowledge.every((item) => {
            return item.createdByCC === false || _.isNil(item.createdByCC)
          }))) &&
          <Form.Item
            name="shareCC"
            valuePropName="checked"
          >
            <Checkbox
              disabled={setting.canToggleCC === false}
            >
              Share with care concierge
            </Checkbox>
          </Form.Item>
        }
      </Form>
    </Modal >
  )
}


const KnowledgeBaseCopyModal = ({
  setting = {
    selectedKnowledge: [],
    isModalVisible: false,
    circles: []
  },
  isSubmitting = false,
  handleCancel = () => { },
  submitForm = () => { },
  form = undefined,
}) => {

  const onFinish = (values) => {
    submitForm(setting.selectedKnowledge, values);
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <Modal
      title={"Copy settings"}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      onOk={form && form.submit}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={form && form.submit}
        >
          Save
        </Button>
      ]}
    >
      <Form
        className="shareKnowledgeForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        <label
          className="text-primary-purple"
          style={{ fontSize: "1rem" }}
        >
          Copy the selected {_.isArray(setting.selectedKnowledge) && setting.selectedKnowledge.length} knowledge to
        </label>
        <Form.Item
          name="shareCircle"
          className="mt-4"
          rules={[
            {
              required: true,
              message: 'Please select circle to share!',
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Select the destination circles"
          >
            {_.isArray(setting.circles) && setting.circles.map((item) =>
              <Select.Option
                key={item._id}
              >
                {`${item.firstName} ${item.lastName}`}
              </Select.Option>
            )
            }
          </Select>
        </Form.Item>
      </Form>
    </Modal >
  )
}

const KnowledgeBaseModal = ({
  setting = {
    isModalVisible: false,
    initialValues: {
      national: true,
      shareCircle: false,
      shareCC: false,
    },
    inCCKB: false,
    selectedKnowledge: [],
    canToggleCC: false,
    canShare: false,
  },
  title = "Create new knowledge",
  isSubmitting = false,
  handleCancel = () => { },
  submitForm = () => { },
  form = undefined,
}) => {

  const [showLocalInfo, setShowLocalInfo] = useState(false);

  const onFinish = (values) => {
    if (_.isArray(setting.selectedKnowledge) && setting.selectedKnowledge.length > 0) {
      submitForm(setting.selectedKnowledge, values);
    } else {
      submitForm(values);
    }
    form.resetFields();
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const onFormChange = ({ national }) => {
    if (_.isBoolean(national)) {
      setShowLocalInfo(!national);
    }
  };

  return (
    <Modal
      title={title}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      onOk={form && form.submit}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={form && form.submit}
        >
          Send
        </Button>
      ]}
    >
      <Form
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
        onValuesChange={onFormChange}
        initialValues={setting.initialValues}
      >
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Name</label>}
          required
          rules={[
            { required: true, message: 'Please input the name of the knowledge' },
            {
              max: 300,
              message: 'Name cannot over 300 characters.'
            }]}
          name="name"
        >
          <Input type="text" placeholder="Name" />
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Description</label>}
          required
          rules={[
            { required: true, message: 'Please input the description of the knowledge' },
            {
              max: 500,
              message: 'Description cannot over 500 characters.'
            }]
          }
          name="description"
        >
          <Input.TextArea />
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Email</label>}
          rules={[
            {
              type: 'email',
              message: 'The input is not valid E-mail!',
            },
            {
              max: 50,
              message: 'Email cannot over 50 characters.'
            }
          ]}
          name="email"
        >
          <Input type="email" placeholder="abc@example.com" />
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Phone</label>}
          rules={[
            {
              max: 50,
              message: 'Phone cannot over 50 characters.'
            },
            // {
            //   pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
            //   message: 'Please check the format of phone number'
            // },
          ]}
          name="phone"
        >
          <Input type="text" placeholder="555-555-5555" />
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Is National</label>}
          name="national"
          required
          rules={[
            {
              required: true, message: 'Please indicate whether the knowledge is national or not'
            }]
          }
        >
          <Radio.Group>
            <Radio value={true}> Yes </Radio>
            <Radio value={false}> No </Radio>
          </Radio.Group>
        </Form.Item>
        {
          showLocalInfo &&
          <Form.Item
            label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>State</label>}
            name="state"
            rules={[
              {
                max: 50,
                message: 'State cannot over 50 characters.'
              }]
            }
          >
            <Select
              allowClear
              showSearch
            >
              {
                US_STATES.map(
                  (value, index) =>
                    <Select.Option key={value} value={value}>
                      {value}
                    </Select.Option>
                )
              }
            </Select>
          </Form.Item>
        }
        {showLocalInfo &&
          <Form.Item
            label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Local</label>}
            name="local"
            rules={[
              {
                max: 100,
                message: 'Local cannot over 100 characters.'
              }]
            }
          >
            <Input type="text" placeholder="Local City" />
          </Form.Item>
        }
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Address</label>}
          name="address"
          rules={[
            {
              max: 300,
              message: 'Address cannot over 300 characters.'
            }]
          }
        >
          <Input type="text" placeholder="Address" />
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Category</label>}
          name="category"
          required
          rules={[
            { required: true, message: 'Please select a category' }
          ]}
        >
          <Select
            showSearch
          >
            {
              KB_CATEGORY.Values.map((value) =>
                <Select.Option key={value} value={value}>
                  {KB_CATEGORY.Strings[value]}
                </Select.Option>
              )
            }
          </Select>
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Specialty 1</label>}
          name="specialty1"
          required
          rules={[
            { required: true, message: 'Please select a specialty' }
          ]}
        >
          <Select
            showSearch
          >
            {
              KB_SPECIALTY.Values.map((value) =>
                <Select.Option key={value} value={value}>
                  {KB_SPECIALTY.Strings[value]}
                </Select.Option>
              )
            }
          </Select>
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Specialty 2</label>}
          name="specialty2"
        >
          <Input type="text" placeholder="Specialty 2" />
        </Form.Item>

        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Pricing</label>}
          name="pricing"
          rules={[
            {
              max: 100,
              message: 'Pricing cannot over 100 characters.'
            }]
          }
        >
          <Input type="text" placeholder="Pricing" />
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Link</label>}
          name="link"
          rules={[
            {
              max: 2048,
              message: 'Link cannot over 2048 characters.'
            }]
          }
        >
          <Input type="text" placeholder="Link" />
        </Form.Item>
        <Form.Item
          label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>Keywords</label>}
          name="keywords"
          required
          extra="Press Enter after typing a word to create a tag"
          rules={[
            { required: true, message: 'Please input at least one keyword' },
            {
              max: 10,
              type: "array",
              message: 'Cannot use over 10 keywords'
            }]
          }
        >
          <Select
            mode="tags"
            size={'default'}
            showSearch={false}
            open={false}
            tokenSeparators={[',']}
          >
          </Select>
        </Form.Item>
        {
          (!_.isNil(setting.initialValues?.shareCircle) && !setting.inCCKB) &&
          <Form.Item
            name="shareCircle"
            valuePropName="checked"
          >
            <Checkbox
              disabled={setting.canShare === false}
            >
              {
                <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
                  Share with circle
                </label>
              }
            </Checkbox>
          </Form.Item>
        }
        <Form.Item
          name="shareCC"
          valuePropName="checked"

        >
          <Checkbox
            disabled={setting.canShare === false || setting.canToggleCC === false}
          >
            {
              <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
                Share with care concierge
              </label>
            }
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal >
  )
}

const KnowledgeBaseDeleteModal = ({
  setting = {
    selectedKnowledge: [],
    isModalVisible: false,
  },
  isSubmitting = false,
  handleCancel = () => { },
  submit = () => { },
}) => {

  const onFinish = () => {
    submit(setting.selectedKnowledge);
  };

  return (
    <Modal
      title={"Confirmation"}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      onOk={onFinish}
      footer={[
        <Button
          key="back"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="delete"
          type="danger"
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={onFinish}
        >
          Delete
        </Button>
      ]}
    >
      <p>Are you sure you want to delete the selected knowledge?</p>
    </Modal >
  )
}

const KnowledgeBaseDetail = ({
  knowledgeData = {},
}) => {

  return (
    <Descriptions bordered>
      <Descriptions.Item label="Name" span={3}>
        {knowledgeData.name}
      </Descriptions.Item>
      <Descriptions.Item label="Description" span={3}>
        {knowledgeData.description}
      </Descriptions.Item>
      <Descriptions.Item label="Email" span={1.5}>
        {knowledgeData.email}
      </Descriptions.Item>
      <Descriptions.Item label="Phone" span={1.5}>
        {knowledgeData.phone}
      </Descriptions.Item>
      <Descriptions.Item label="Is National" span={3}>
        {knowledgeData.national ? "Yes" : "No"}
      </Descriptions.Item>
      <Descriptions.Item label="State" span={1.5}>
        {knowledgeData.state}
      </Descriptions.Item>
      <Descriptions.Item label="Local" span={1.5}>
        {knowledgeData.local}
      </Descriptions.Item>
      <Descriptions.Item label="Address" span={3}>
        {knowledgeData.address}
      </Descriptions.Item>
      <Descriptions.Item label="Category" span={3}>
        {knowledgeData.category && KB_CATEGORY.Strings[knowledgeData.category]}
      </Descriptions.Item>
      <Descriptions.Item label="Specialty 1" span={1.5}>
        {knowledgeData.specialty1 && KB_SPECIALTY.Strings[knowledgeData.specialty1]}
      </Descriptions.Item>
      <Descriptions.Item label="Specialty 2" span={1.5}>
        {knowledgeData.specialty2}
      </Descriptions.Item>
      <Descriptions.Item label="Pricing" span={1.5}>
        {knowledgeData.pricing}
      </Descriptions.Item>
      <Descriptions.Item label="Link" span={1.5}>
        {knowledgeData.link}
      </Descriptions.Item>
      <Descriptions.Item label="Keywords" span={3}>
        {knowledgeData.keywords && knowledgeData.keywords.map((keyword) => (
          <Tag color="blue" key={keyword}>
            {keyword}
          </Tag>
        ))}
      </Descriptions.Item>
      <Descriptions.Item label="Sharing" span={3}>
        {
          <ul className="list-disc list-inside pl-0">
            {
              knowledgeData.shareCircle &&
              <li>Circle</li>
            }
            {
              knowledgeData.shareCC &&
              <li>Care Concierge</li>
            }
          </ul>
        }
      </Descriptions.Item>
      <Descriptions.Item label="Shared By" span={3}>
        {`${_.get(knowledgeData.sourcedBy, "firstName")} ${_.get(knowledgeData.sourcedBy, "lastName")}`}
      </Descriptions.Item>
    </Descriptions>
  )
}

const CircleSelectionTable = ({
  data = [],
  isLoading = false,
  setCurrentCircle = () => { },
  includeCC = false,
}) => {

  const columns = [
    {
      title: 'Circle Id',
      dataIndex: '_id',
      width: 150,
      render: (text, record) =>
        <Button type="link" className="text-start p-0 m-0" block onClick={() => setCurrentCircle(record)}>
          {text}
        </Button>,
    },
    {
      title: 'Incare Name',
      key: 'incareName',
      width: 150,
      render: (text, record) =>
        `${record.firstName} ${record.lastName}`,
    },
    {
      title: 'Primary Caregiver',
      key: 'primaryCaregiver',
      width: 150,
      render: (text, record) => {
        let name = ""
        if (record.primary?.length > 0) {
          name = `${record.primary[0].firstName} ${record.primary[0].lastName}`
        }
        return name;
      }
    },
    {
      title: 'Email',
      key: 'email',
      width: 150,
      render: (text, record) => {
        let email = ""
        if (record.primary?.length > 0) {
          email = `${record.primary[0].email}`
        }
        return email;
      }
    },
    {
      title: 'CC',
      dataIndex: 'ccFirstName',
      width: 150,
    },
  ].filter((col) => {
    if (col.title === "CC" && !includeCC) {
      return false
    } else {
      return true;
    }
  })

  return (
    <Spin
      spinning={isLoading}
    >
      <Table
        columns={columns}
        dataSource={data}
        rowKey="_id"
        bordered
        scroll={{ x: 1000 }}
      />
    </Spin>
  )
}

const CCKnowledgeBase = ({ setPage, cg, circleId = "", circles = [] }) => {

  const [createModalSetting, setCreateModalSetting] = useState({});
  const [editModalsetting, setEditModalSetting] = useState({});
  const [shareModalsetting, setShareModalSetting] = useState({});
  const [deleteModalsetting, setDeleteModalSetting] = useState({});
  const [copyModalsetting, setCopyModalSetting] = useState({});
  const mclAuth = useMclAuth()
  const [knowledgeData, setKnowledgeData] = useState([]);
  const [currentKnowledge, setCurrentKnowledge] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [selectedKnowledge, setSelectedKnowledge] = useState([]);
  const [createForm] = Form.useForm();
  const [editForm] = Form.useForm();
  const [shareForm] = Form.useForm();
  const [copyForm] = Form.useForm();

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');

  const createKnowledge = useCallback(async (values) => {
    if (_.isEmpty(circleId)) {
      return;
    }
    const data = _.pick(values, [
      "address",
      "category",
      "description",
      "email",
      "keywords",
      "link",
      "local",
      "name",
      "national",
      "phone",
      "pricing",
      "shareCircle",
      "shareCC",
      "specialty1",
      "specialty2",
      "state",
    ])

    if (_.isEmpty(circleId)) {
      openNotification({
        message: "Error",
        status: "500",
        title: "No circle found"
      });
      return;
    }

    data["shareCircle"] = false;
    data["circleId"] = circleId;
    data["createdByCC"] = true;
    setSubmitting(true);
    let url = circleId === "care_concierge" ? Url.KNOWLEDGE_BASE.concat('/cc') : Url.KNOWLEDGE_BASE.concat('/')
    mclAuth
      .post(url, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          openNotification({
            message: "Succeed!",
            status: "success",
            title: "Successfully created a new knowledge",
          });
          setCreateModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }))
          setKnowledgeData([...knowledgeData, res.data])
        } else {
          openNotification({
            message: "Sorry",
            status: res.status ? res.status : "500",
            title: res.response.data,
          });
        }
        setSubmitting(false);
      })
      .catch((error) => {
        openNotification({
          message: "Sorry",
          status: error.status ? error.status : "500",
          title: error.response.data
        });
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [circleId, mclAuth, createForm, knowledgeData])

  const editKnowledge = useCallback(async (targetKnowledge, values, onFinished = () => { }) => {
    if (_.isEmpty(circleId)) {
      return;
    }
    try {
      setSubmitting(true);
      let updateResults = []
      let url = circleId === "care_concierge" ? Url.KNOWLEDGE_BASE.concat('/cc/knowledge/') : Url.KNOWLEDGE_BASE.concat('/knowledge/')
      for (let i in targetKnowledge) {
        let res = await mclAuth.put(url.concat(targetKnowledge[i]._id),
          {
            circleId: targetKnowledge[i].circleId,
            ...values
          }
          , {
            headers: { "Content-Type": "application/json" },
          })
        if (res.status && res.status === 200) {
          updateResults.push(res.data)
        }
      }

      // Update new knowledge data
      openNotification({
        message: "Succeed!",
        status: "success",
        title: "Successfully update the selected knowledge",
      });

      // Start to compare and update
      let newKnowledgeArr = [];

      for (let index in knowledgeData) {
        let item = knowledgeData[index];
        let newItem = updateResults.find(newItem => newItem._id === item._id);
        newKnowledgeArr.push(newItem ? { ...item, ...newItem } : item);
      }

      onFinished();
      setKnowledgeData(newKnowledgeArr);
      setSubmitting(false);
    }
    catch (err) {
      console.error(err)
      openNotification({
        message: "Error!",
        status: "500",
        title: "Failed to update the selected knowledge",
      });
      setSubmitting(false);
    }
  }, [circleId, mclAuth, knowledgeData])

  const deleteKnowledge = useCallback(async (targetKnowledge, onFinished = () => { }) => {
    if (_.isEmpty(circleId)) {
      return;
    }
    try {
      setSubmitting(true);
      let updateResults = []
      let url = circleId === "care_concierge" ? Url.KNOWLEDGE_BASE.concat('/cc/knowledge/') : Url.KNOWLEDGE_BASE.concat('/knowledge/', circleId, "/")
      for (let i in targetKnowledge) {
        let res = await mclAuth.delete(url.concat(targetKnowledge[i]._id)
          , {
            headers: { "Content-Type": "application/json" },
          })
        if (res.status && res.status === 200) {
          updateResults.push(res.data)
        }
      }

      // Update new knowledge data
      openNotification({
        message: "Succeed!",
        status: "success",
        title: "Successfully delete the selected knowledge",
      });

      // Start to compare and update, remove item from data if it is successfully deleted
      let newKnowledgeArr = knowledgeData.filter(item => {
        const found = updateResults.find(newItem => newItem._id === item._id);
        return _.isNil(found);
      });

      setKnowledgeData(newKnowledgeArr);
      setSelectedKnowledge([]);
      setCurrentKnowledge(undefined);
      onFinished();
      setSubmitting(false);
    }
    catch (err) {
      console.error(err)
      openNotification({
        message: "Error!",
        status: "500",
        title: "Failed to delete the selected knowledge ",
      });
      setSubmitting(false);
    }
  }, [circleId, mclAuth, knowledgeData])

  const copyKnowledge = useCallback(async (knowledge, circles) => {
    if (_.isEmpty(circles)) {
      return;
    }

    // setSubmitting(true);

    let url = Url.KNOWLEDGE_BASE.concat('/copy')

    let requestData = {
      knowledge: knowledge,
      circles: circles,
    }
    mclAuth
      .post(url, requestData, {
        headers: { "Content-Type": "application/json" },
      })
      .then((res) => {
        if (res.status && res.status === 200) {
          openNotification({
            message: "Succeed!",
            status: "success",
            title: "Successfully created a new knowledge",
          });
        } else {
          openNotification({
            message: "Sorry",
            status: res.status ? res.status : "500",
            title: res.response.data,
          });
        }
        setCopyModalSetting((prevState) => ({
          ...prevState,
          isModalVisible: false,
        }))
        setSubmitting(false);
        setSelectedKnowledge([]);
      })
      .catch((error) => {
        openNotification({
          message: "Sorry",
          status: error.status ? error.status : "500",
          title: error.response.data
        });
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
      });
  }, [mclAuth, knowledgeData])

  useEffect(() => {
    // Get knoweledge of the circle
    if (!_.isEmpty(circleId)) {
      setIsLoading(true)
      let url = circleId === "care_concierge" ? Url.KNOWLEDGE_BASE.concat('/cc') : Url.KNOWLEDGE_BASE.concat('/cc/', circleId)
      mclAuth.get(url)
        .then(res => {
          setKnowledgeData(res.data)
          setIsLoading(false)
        })
        .catch(error => {
          openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to get knowledge data" })
          setIsLoading(false)
        })
    }
  }, [circleId, mclAuth])

  useEffect(() => {
    if (!_.isEmpty(shareModalsetting) && !_.isNil(shareForm)) {
      shareForm.resetFields();
    }
  }, [shareForm, shareModalsetting])

  useEffect(() => {
    if (!_.isEmpty(editModalsetting) && !_.isNil(editForm)) {
      editForm.resetFields();
    }
  }, [editForm, editModalsetting])

  useEffect(() => {
    if (!_.isEmpty(createModalSetting) && !_.isNil(createForm)) {
      createForm.resetFields();
    }
  }, [createForm, createModalSetting])

  return (
    <>
      {/* Create */}
      <KnowledgeBaseModal
        title="Create new knowledge"
        setting={createModalSetting}
        handleCancel={() => setCreateModalSetting((prevState) => ({
          ...prevState,
          isModalVisible: false,
        }))}
        submitForm={createKnowledge}
        isSubmitting={isSubmitting}
        form={createForm}
      />
      {/* Edit */}
      <KnowledgeBaseModal
        title="Edit knowledge"
        setting={editModalsetting}
        handleCancel={() => setEditModalSetting((prevState) => ({
          ...prevState,
          isModalVisible: false,
        }))}
        submitForm={(target, value) => {
          editKnowledge(target, value, setEditModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
            canToggleCC: circleId === "care_concierge",
            initialValues: {
              national: !_.isNil(value.national) ? value.national : true,
              shareCircle: !_.isNil(value.shareCircle) ? value.shareCircle : false,
              shareCC: !_.isNil(value.shareCC) ? value.shareCC : false,
            },
          })))
        }}
        isSubmitting={isSubmitting}
        form={editForm}
      />
      {
        circleId === "care_concierge" &&
        <KnowledgeBaseCopyModal
          setting={copyModalsetting}
          handleCancel={() => {
            setCopyModalSetting((prevState) => ({
              ...prevState,
              isModalVisible: false,
            }))
            copyForm.resetFields();
          }}
          submitForm={(value, target) => {
            if (_.isEmpty(value) || _.isEmpty(target.shareCircle)) {
              setCopyModalSetting((prevState) => ({
                ...prevState,
                isModalVisible: false,
              }))
              openNotification({
                message: "Sorry",
                status: "500",
                title: "Knowledge or circle cannot be empty"
              });
              return;
            }
            copyKnowledge(value, target.shareCircle)
            // editKnowledge(target, value, setShareModalSetting((prevState) => ({
            //   ...prevState,
            //   isModalVisible: false,
            //   shareCircle: !_.isNil(value.shareCircle) ? value.shareCircle : false,
            //   shareCC: !_.isNil(value.shareCC) ? value.shareCC : false,
            // })))
          }}
          isSubmitting={isSubmitting}
          form={copyForm}
        />
      }
      <KnowledgeBaseShareModal
        setting={shareModalsetting}
        handleCancel={() => {
          setShareModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }))
          shareForm.resetFields();
        }}
        submitForm={(target, value) => {
          editKnowledge(target, value, setShareModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
            shareCircle: !_.isNil(value.shareCircle) ? value.shareCircle : false,
            shareCC: !_.isNil(value.shareCC) ? value.shareCC : false,
          })))
        }}
        isSubmitting={isSubmitting}
        form={shareForm}
      />
      <KnowledgeBaseDeleteModal
        setting={deleteModalsetting}
        handleCancel={() => {
          setDeleteModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          }))
        }}
        submit={(target) => {
          deleteKnowledge(target, setDeleteModalSetting((prevState) => ({
            ...prevState,
            isModalVisible: false,
          })));
        }}
        isSubmitting={isSubmitting}
      />
      {
        _.isNil(currentKnowledge) ?
          <Card
            className='mt-4'
            title={
              <Button
                key="back"
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  setPage({})
                }}
              >
              </Button>}
            extra={[
                <Input.Search
                  allowClear
                  onSearch={(searchText) => {

                  console.log('Search triggered!!!!')
                  // confirm();
                  setSearchText(searchText);
                  setSearchedColumn('all');

                    // this.setState({
                    //   dataSource: data.filter(person => person.name.includes(nameSearch))
                    // })
                  }}
                  style={{ width: 400 }}
                  className="mr-4"
                />,   
              <Button
                key="delete"
                icon={<DeleteOutlined />}
                disabled={_.isArray(selectedKnowledge) && selectedKnowledge.length > 0 && !selectedKnowledge.every((item) => {
                  return item.__permission?.delete === true
                })}
                onClick={() => {
                  if (_.isArray(selectedKnowledge) && selectedKnowledge.length > 0) {
                    setDeleteModalSetting((prevState) => ({
                      ...prevState,
                      isModalVisible: true,
                      selectedKnowledge: selectedKnowledge,
                    }))
                  } else {
                    message.warning('Please select a knowledge', 5);
                  }
                }}
                className="mr-4"
              >
                Delete
              </Button>,
              <Button
                key="copy"
                icon={<CopyOutlined />}
                onClick={() => {
                  if (_.isArray(selectedKnowledge) && selectedKnowledge.length > 0) {
                    setCopyModalSetting((prevState) => ({
                      ...prevState,
                      isModalVisible: true,
                      selectedKnowledge: selectedKnowledge,
                      circles: circles,
                    }))
                  } else {
                    message.warning('Please select a knowledge', 5);
                  }
                }}
                className="mr-4"
              >
                Copy
              </Button>
              ,
              < Button
                key="share"
                icon={< ShareAltOutlined />}
                disabled={_.isArray(selectedKnowledge) && selectedKnowledge.length > 0 && !selectedKnowledge.every((item) => {
                  return item.__permission?.share === true
                })}
                onClick={() => {
                  if (_.isArray(selectedKnowledge) && selectedKnowledge.length > 0) {
                    setShareModalSetting((prevState) => ({
                      ...prevState,
                      isModalVisible: true,
                      selectedKnowledge: selectedKnowledge,
                      canToggleCC: circleId === "care_concierge",
                      shareCC: false,
                      shareCircle: circleId === "care_concierge" ? undefined : false,
                      inCCKB: circleId === "care_concierge"
                    }))
                  } else {
                    message.warning('Please select a knowledge', 5);
                  }
                }}
                className="mr-4"
              >
                Share
              </Button>,
              <Button
                key="create-new"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setCreateModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                    canToggleCC: circleId === "care_concierge",
                    initialValues: {
                      national: true,
                      shareCC: circleId === "care_concierge" ? /* false*/ true : true,
                      shareCircle: circleId === "care_concierge" ? undefined : false,
                    },
                    inCCKB: circleId === "care_concierge"
                  }))
                }}
              >
                Create new
              </Button>
            ].filter((button) => {
              if (button.key === "delete") {
                if (selectedKnowledge && selectedKnowledge.length > 0) {
                  return true;
                } else {
                  return false
                }
              }
              else if (button.key === "copy") {
                if (circleId === "care_concierge") {
                  return true;
                } else {
                  return false
                }
              }
              else {
                return true;
              }
            })}
          >
            <Row>
              <Col xs={24}>
                <KnowledgeTable
                  data={knowledgeData}
                  showDetail={false}
                  isLoading={isLoading}
                  inCCKB={circleId === "care_concierge"}
                  canToggleCC={circleId === "care_concierge"}
                  selectedKnowledge={selectedKnowledge}
                  setCurrentKnowledge={setCurrentKnowledge}
                  setSelectedRows={setSelectedKnowledge}
                  setEditModalSetting={setEditModalSetting}
                  setShareModalSetting={setShareModalSetting}
                  setDeleteModalSetting={setDeleteModalSetting}

                  searchText={searchText}
                  setSearchText={setSearchText}
                  searchedColumn={searchedColumn}
                  setSearchedColumn={setSearchedColumn}
                />
              </Col>
            </Row>
          </Card >
          :
          <Card
            className='mt-4'
            title={
              <Button
                key="back"
                type="text"
                icon={<ArrowLeftOutlined />}
                onClick={() => {
                  setCurrentKnowledge(undefined)
                }}
              >
              </Button>}
            extra={[
              <Button
                key="delete"
                icon={<DeleteOutlined />}
                disabled={_.isNil(knowledgeData[currentKnowledge].__permission) || !knowledgeData[currentKnowledge].__permission.delete}
                onClick={() => {
                  setDeleteModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                    selectedKnowledge: [knowledgeData[currentKnowledge]],
                  }))
                }}
                className="mr-4"
              >
                Delete
              </Button>,
              <Button
                key="edit"
                type="primary"
                icon={<EditOutlined />}
                disabled={_.isNil(knowledgeData[currentKnowledge].__permission) || !knowledgeData[currentKnowledge].__permission.update}
                onClick={() => {
                  setEditModalSetting((prevState) => ({
                    ...prevState,
                    isModalVisible: true,
                    selectedKnowledge: [knowledgeData[currentKnowledge]],
                    canToggleCC: circleId === "care_concierge",
                    initialValues: _.pick(
                      _.isArray(knowledgeData) &&
                        knowledgeData.length > currentKnowledge ? knowledgeData[currentKnowledge] : {}, [
                      "address",
                      "category",
                      "description",
                      "email",
                      "keywords",
                      "link",
                      "local",
                      "name",
                      "national",
                      "phone",
                      "pricing",
                      "shareCircle",
                      "shareCC",
                      "specialty1",
                      "specialty2",
                      "state",
                    ]),
                    inCCKB: circleId === "care_concierge"
                  }))
                }}
              >
                Edit
              </Button>
            ]}
          >
            <Row>
              <Col xs={24}>
                <KnowledgeBaseDetail
                  knowledgeData={!_.isNil(currentKnowledge) && knowledgeData[currentKnowledge]}
                />
              </Col>
            </Row>
          </Card >
      }
    </>
  );
};

export {
  KnowledgeTable,
  KnowledgeBaseShareModal,
  KnowledgeBaseCopyModal,
  KnowledgeBaseModal,
  KnowledgeBaseDeleteModal,
  KnowledgeBaseDetail,
  CircleSelectionTable,
  CCKnowledgeBase,
}