import { createContext, useState } from "react";

const CaregiverContext = createContext({});

const CaregiverProvider = ({ children }) => {
    const [pageContent, setPageContent] = useState(0);
    // const [giverCare, setGiverCare] = useState({});
    // const [adultCare, setAdultCare] = useState({});
    const [cg, setCg] = useState({});
    const [currentCircle, setCurrentCircle] = useState(0)
    const [panelCurrent, setPanelCurrent] = useState(1)
    const [panelRcptCurrent, setPanelRcptCurrent] = useState(1)
    const [planSelf, setPlanSelf] = useState(undefined)
    const [planRcpt, setPlanRcpt] = useState(undefined)
    // const [planRcpt, setPlanRcpt] = useState(
    //     {
    //         'current': 1,
    //         'c1': {
    //             'firstName': '',
    //             'lastName': '',
    //             'birthDate': '',
    //             'addr_street': '',
    //             'addr_street2': '',
    //             'addr_city': '',
    //             'addr_state': '',
    //             'addr_postal': '',
    //             'addr_country': 'USA',
    //             'phone': '',
    //             'primary_language': '',
    //             'formal_titles': '',
    //             'pronouns': '',
    //             'partner': 0,
    //             'partnerFirst': '',
    //             'partnerLast': '',
    //             'partnerOther': '',
    //             'independent': 0,
    //             'independentOther': '',
    //             'work': 0,
    //             'workOther': '',
    //             'situation': '',
    //         },
    //         'c2': {
    //             'birthCert': '',
    //             'marriageCert': '',
    //             'deathCert': '',
    //             'divorceDoc': '',
    //             'militaryBranch': '',
    //             'militaryIdVa': '',
    //             'militaryVetDoc': 0,
    //             'militaryDates': '',
    //             'militaryDoc': '',
    //             'driverState': '',
    //             'driverLicense': '',
    //             'driverDate': '',
    //             'passportNumber': '',
    //             'passportDate': '',
    //             'donorInstruct': '',

    //             'contact': 0,
    //             'contactAddress': '',
    //             'contactOther': '',

    //             'contactEmergency': [],
    //             'detailsPersonal': '',

    //         },
    //         'c3': {
    //             'propertyHome': [],
    //             'propertyOwn': 0,
    //             'propertyDetails': '',
    //             'rentDetails': '',

    //             'utilPowerName': '',
    //             'utilPowerNumber': '',
    //             'utilGasName': '',
    //             'utilGasNumber': '',
    //             'utilCableName': '',
    //             'utilCableNumber': '',
    //             'utilWaterName': '',
    //             'utilWaterNumber': '',
    //             'utilReceive': 0,
    //             'utilPerson': '',
    //             'utilPlan': '',

    //             'phoneTeleName': '',
    //             'phoneTeleNumber': '',
    //             'phoneMobileName': '',
    //             'phoneMobileNumber': '',
    //             'phoneReceive': 0,
    //             'phonePerson': '',
    //             'phonePlan': '',

    //             'homeInsureAgent': '',
    //             'homeOwnerPolicy': '',
    //             'homeQuakePolicy': '',
    //             'homeFloodPolicy': '',
    //             'homeFirePolicy': '',
    //             'homeCost': '',

    //             'personalPolicy': '',
    //             'personalPremium': '',

    //             'garbageDay': '',
    //             'housekeepName': '',
    //             'housekeepNumber': '',
    //             'housekeepFrequency': '',
    //             'housekeepCost': '',
    //             'gardenerName': '',
    //             'gardenerNumber': '',
    //             'gardenerFrequency': '',
    //             'gardenerCost': '',
    //             'homeConcern': '',

    //         },
    //         'c4': {
    //             'pets': [],
    //             'vetName': '',
    //             'vetLocation': '',
    //             'vetNumber': '',
    //             'petInsurance': '',
    //             'petInstruct': '',
    //         },
    //         'c5': {
    //             'drive': 0,
    //             'driveCircumstance': '',
    //             'vehicles': [],
    //             'transports': [],
    //         },
    //         'c6': {
    //             'communicate': [false, false, false, false, false, false],
    //             'communicateOther': '',
    //             'converse': [false, false, false, false, false, false],
    //             'converseOther': '',
    //         },
    //         'c7': {
    //             'insureName': '',
    //             'insureNumber': '',
    //             'insurePolicy': '',
    //             'medicalGroup': '',
    //             'medicalNumber': '',

    //             'medicareNumber': '',
    //             'medicareSavings': '',

    //             'medicaidNumber': '',

    //             'credentials': 0,
    //             'credentialsText': '',

    //             'plans': [],

    //             'ss': '',
    //             'survivor': '',
    //             'disability': '',
    //             'employerPostRetire': '',
    //             'other': '',

    //             'physicianName': '',
    //             'physicianPhone': '',
    //             'physicianEmail': '',

    //             'specialists': [],

    //             'conditions': [],

    //             'integratives': [],

    //             'hospitalclinics': [],

    //             'hipaa': 0,
    //             'hipaaInstruct': '',

    //             'sustain': 0,
    //             'sustainInstruct': '',

    //             'directive': 0,
    //             'directiveInstruct': '',
    //             'directiveHelp': 0,

    //             'dnr': 0,
    //             'dnrInstruct': '',

    //             'responsibleName': '',
    //             'responsibleContact': '',
    //             'responsibleDelivery': 0,
    //             'responsibleOther': '',


    //         },
    //         'c8': {
    //             'medicines': [],

    //             'pharmacyName': '',
    //             'pharmacyAddress': '',
    //             'pharmacyPhone': '',
    //             'pharmacyHours': '',
    //             'alternateName': '',
    //             'alternateAddress': '',
    //             'alternatePhone': '',
    //             'alternateHours': '',

    //             'manage': 0,
    //             'manageName': '',
    //             'manageOther': '',

    //             'medicineInstruct': '',

    //         },
    //         'c9': {
    //             'personals': [],
    //             'personalInstruct': '',
    //         },
    //         'c10': {
    //             'equipment': [false, false, false, false, false, false, false, false],
    //             'ownership': 0,
    //             'acquire': 0,
    //             'acquireOther': '',
    //             'dmeInstruct': '',
    //         },
    //         'c11': {
    //             'activities': '',
    //             'communities': [],
    //             'online': [],

    //             'spiritual': 0,
    //             'spiritualOther': '',

    //             'therapist': 0,
    //             'therapistOther': '',

    //             'activityFuture': '',
    //             'tools': '',
    //         },
    //         'c12': {
    //             'goals': [false, false, false, false, false, false, false,
    //                 false, false, false, false, false, false, false],
    //             'goalsOther': '',
    //             'healthStatus': '',
    //             'communityServices': '',
    //             'activities': [false, false, false, false, false,
    //                 false, false, false, false],
    //             'activitiesOther': '',
    //             'challenges': [false, false, false, false, false],
    //             'challengesOther': '',
    //             'services': [false, false, false, false],
    //             'servicesOther': '',
    //             'dietaryNeeds': '',
    //         },
    //         'c13': {
    //             'lawyerName': '',
    //             'lawyerPhone': '',
    //             'lawyerEmail': '',
    //             'lawyerInstruct': '',

    //             'documents': [false, false, false, false,
    //                 false, false, false],
    //             'documentsInstruct': '',

    //             'topics': [false, false, false,
    //                 false, false],
    //             'topicsOther': '',

    //             'medicalAttyName': '',
    //             'medicalAttyPhone': '',
    //             'medicalAttyEmail': '',
    //             'medicalAltName': '',
    //             'medicalAltPhone': '',
    //             'medicalAltEmail': '',

    //             'financeAttyName': '',
    //             'financeAttyPhone': '',
    //             'financeAttyEmail': '',
    //             'financeAltName': '',
    //             'financeAltPhone': '',
    //             'financeAltEmail': '',

    //             'locationOther': '',
    //             'locationInstruct': '',

    //             'legalInstruct': '',

    //         },
    //         'c14': {
    //             'primaryInstitution': '',
    //             'primaryAccounts': '',
    //             'primaryAccessName': '',
    //             'primaryAccessPhone': '',
    //             'primaryAccessEmail': '',
    //             'primaryInstruct': '',

    //             'other1Institution': '',
    //             'other1Accounts': '',
    //             'other1AccessName': '',
    //             'other1AccessPhone': '',
    //             'other1AccessEmail': '',
    //             'other1Instruct': '',

    //             'other2Institution': '',
    //             'other2Accounts': '',
    //             'other2AccessName': '',
    //             'other2AccessPhone': '',
    //             'other2AccessEmail': '',
    //             'other2Instruct': '',

    //             'retire1Institution': '',
    //             'retire1Accounts': '',
    //             'retire1AccessName': '',
    //             'retire1AccessPhone': '',
    //             'retire1AccessEmail': '',
    //             'retire1Instruct': '',

    //             'retire2Institution': '',
    //             'retire2Accounts': '',
    //             'retire2AccessName': '',
    //             'retire2AccessPhone': '',
    //             'retire2AccessEmail': '',
    //             'retire2Instruct': '',

    //             'advisor': '',
    //             'advisorName': '',
    //             'advisorPhone': '',
    //             'advisorEmail': '',

    //             'life': '',
    //             'lifeInstitution': '',
    //             'lifePhone': '',
    //             'lifePolicy': '',
    //             'lifeInstruct': '',

    //             'disability': '',
    //             'disabilityInstitution': '',
    //             'disabilityPhone': '',
    //             'disabilityPolicy': '',
    //             'disabilityInstruct': '',

    //             'annuity': '',
    //             'annuityInstitution': '',
    //             'annuityPhone': '',
    //             'annuityPolicy': '',
    //             'annuityInstruct': '',

    //             'trust': '',
    //             'trustName': '',
    //             'trustContact': '',
    //             'trustInstruct': '',

    //             'ssOnline': '',
    //             'ssNumber': '',
    //             'ssInstruct': '',

    //             'ssdisability': '',
    //             'ssdisabilityOther': '',

    //             'rental': '',
    //             'rentalOther': '',

    //             'recordsDetail': '',
    //             'recordsContact': '',
    //             'recordsKept': '',

    //             'accountantName': '',
    //             'accountantPhone': '',
    //             'accountantEmail': '',
    //             'accountantRecords': '',

    //             'carepayConcerns': '',

    //             'careteamInstruct': '',

    //         },
    //         'c15': {
    //             'spoken1Name': '',
    //             'spoken1Role': '',
    //             'spoken2Name': '',
    //             'spoken2Role': '',
    //             'spokenInstruct': '',

    //             'culturalInstruct': '',

    //             'worryDetails': '',
    //             'worryInstruct': '',

    //             'concernDetails': '',
    //             'concernInstruct': '',

    //             'wishesDetails': '',

    //             'messageDetails': '',
    //             'messageList': '',

    //             'formats': [false, false, false, false],
    //             'formatsOther': '',
    //             'formatHelp': 0,

    //             'nothappenDetails': '',

    //             'frightenDetails': '',

    //             'eolCare': [false, false, false, false, false, false],
    //             'eolCareOther': '',

    //             'surroundingDetails': '',
    //         },
    //         'c16': {
    //             'ritual': [false, false, false, false],
    //             'ritualOther': '',

    //             'notifyOrgs': '',
    //             'notifyPeople': '',

    //             'personUsps': '',
    //             'personIrs': '',
    //             'personSsa': '',
    //             'personDmv': '',
    //             'personOther': '',

    //             'delivery': 0,
    //             'deliveryInstruct': '',

    //             'personFileCert': '',

    //             'final': [false, false, false, false, false, false, false, false],
    //             'finalOther': '',

    //             'funeral': [false, false, false, false, false, false],
    //             'funeralOther': '',

    //             'obituary': 0,

    //             'vendorName': '',
    //             'vendorAddress': '',
    //             'vendorPhone': '',
    //             'vendorInstruct': '',

    //             'paymentInstruct': '',

    //             'dispose': 0,
    //             'disposeInstruct': '',

    //             'socmed': 0,
    //             'socmedOther': '',

    //         },
    //     });


    return (
        <CaregiverContext.Provider value={
            {
                pageContent, setPageContent,
                // giverCare, setGiverCare,
                // adultCare, setAdultCare,
                cg, setCg,
                currentCircle, setCurrentCircle,
                planRcpt, setPlanRcpt,
                planSelf, setPlanSelf,
                panelCurrent, setPanelCurrent,
                panelRcptCurrent, setPanelRcptCurrent
            }
        }>
            {children}
        </CaregiverContext.Provider>
    )
}

export { CaregiverProvider };
export default CaregiverContext
