import React, { useState, useEffect, useContext, useCallback } from "react";
import _ from "lodash";
import { message, Row, Modal } from "antd";
import { v4 as uuidv4 } from "uuid";
import useMclAuth from "../hooks/useMclAuth";
import MessagingSidePanel from "./MessagingSidePanel";
import {
  DEFAULT_MESSAGE_USER_ICON,
  DEFAULT_MESSAGE_GROUP_ICON,
  MESSAGE_SUPPORT_ICON,
  MESSAGE_CONCIERGE_ICON,
  MESSAGE_CHAT_TYPE,
  Url,
  Roles,
} from "../constants/Global";
import { getChatName } from "./Various";
import MessagingSection from "./MessagingSection";
import MessagingRightPanel from "./MessagingRightPanel";
import MessagingContactModal from "./MessagingContactModal";
import moment from "moment";
import SocketContext from "../context/SocketContext";
import { Button } from "antd";
import { PlusOutlined, WarningOutlined } from "@ant-design/icons";

const MessagingV2 = ({ cg, portal }) => {
  const mclAuth = useMclAuth();
  const socket = useContext(SocketContext);
  const [isLoadingChats, setIsLoadingChats] = useState(true);
  const [selectedChatListId, setSelectedChatListId] = useState(false);
  const [chatListItems, setChatListItems] = useState([]);
  const [isRightPanelOpen, setRightPanelOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(socket?.connected);
  const [isContactModalOpen, setContactModalOpen] = useState(false);
  const [isCreatingDirectChat, setCreatingDirectChat] = useState(false);

  const processChatData = (chatData, currentUser) => {
    let result = [];
    // Make the following fields for the chat
    // {String} name: name of the chat
    // {Component} icon: icon for the chat, if no icon to use, choose the default from global constant
    // {Bool} isNew: has new messages that haven't been read by currentUser,
    // {Bool} showGroupName: need to show circle name or not, currently not showing circle name when in group chat,
    // {String} members.name: name of the user
    for (let i in chatData) {
      chatData[i]["name"] = getChatName(
        chatData[i],
        chatData[i].type,
        currentUser,
        portal
      );

      // Process icon
      // As we don't have it right now, just use the default
      // IMO, I think it should be added in the circle collection in the future
      // Like an "avatar" or "icon" field which is a string url point to the image location
      switch (chatData[i]["type"]) {
        case MESSAGE_CHAT_TYPE.GROUP:
          chatData[i]["icon"] = DEFAULT_MESSAGE_GROUP_ICON;

          break;
        case MESSAGE_CHAT_TYPE.DIRECT:
          chatData[i]["icon"] = DEFAULT_MESSAGE_USER_ICON;
          break;
        case MESSAGE_CHAT_TYPE.MCL.SUPPORT:
          chatData[i]["icon"] = MESSAGE_SUPPORT_ICON;
          break;
        case MESSAGE_CHAT_TYPE.MCL.CONCIERGE:
          chatData[i]["icon"] = MESSAGE_CONCIERGE_ICON;
          break;
        default:
          chatData[i]["icon"] = WarningOutlined;
          console.log("chat type value is not valid, please check");
          break;
      }

      // Process is new
      chatData[i]["isNew"] = false;
      // Check the latest message and see whether user has read it by "receipt" field
      let messageLength = _.isArray(chatData[i]["messages"])
        ? chatData[i]["messages"].length
        : 0;
      let latestMessage =
        messageLength > 0
          ? chatData[i]["messages"][messageLength - 1]
          : undefined;
      // We judge the message is new to the user only when the latest message is not sent by the current user
      if (
        !_.isNil(latestMessage) &&
        latestMessage.sender?._id !== currentUser._id
      ) {
        // Start checking receipt
        // If receipt is empty, then it is new
        // Else check every element in receipt
        if (_.isEmpty(latestMessage.receipt)) {
          chatData[i]["isNew"] = true;
        } else {
          chatData[i]["isNew"] = !_.includes(
            latestMessage.receipt,
            currentUser._id
          );
        }
      }
      // Process show group name field
      // Right now, only show group name when the chat is direct
      chatData[i]["showGroupName"] =
        chatData[i]["type"] === MESSAGE_CHAT_TYPE.DIRECT ? true : false;

      // Will have chat owner field when it is support or concierge
      chatData[i]["chatOwner"] = chatData[i]["user"];
      chatData[i]["user"] = currentUser;
    }

    return chatData;
  };
  const appendOrUpdateMessage = (data) => {
    let chatItemIndex = chatListItems.findIndex(
      (element) => element._id === data.chatId
    );
    let chatItem = { ...chatListItems[chatItemIndex] };

    let newMessages = [...chatItem.messages];
    let foundIndex = newMessages.findIndex(
      (x) => x._id === data.placeholderId || x._id === data._id
    );
    // One case: Local message is already there, so find it and update it
    // Else: append it directly
    if (foundIndex !== -1) {
      newMessages[foundIndex] = _.without(data.message, ["placeholderId"]);
    } else {
      newMessages.push(data.message);
    }

    chatItem.messages = newMessages;
    chatItem.isNew = false;

    let newChatLists = [
      chatItem,
      ...chatListItems.filter((item) => item._id !== chatItem._id),
    ];
    setChatListItems(newChatLists);
    // setChatListItems((prevChatListItems) =>
    //   prevChatListItems.map((item) => {
    //     if (item._id === data.chatId) {
    //       let newMessages = [...item.messages];
    //       let foundIndex = newMessages.findIndex(
    //         (x) => x._id === data.placeholderId || x._id === data._id
    //       );
    //       // One case: Local message is already there, so find it and update it
    //       // Else: append it directly
    //       if (foundIndex !== -1) {
    //         newMessages[foundIndex] = _.without(data.message, [
    //           "placeholderId",
    //         ]);
    //       } else {
    //         newMessages.push(data.message);
    //       }
    //       item.messages = newMessages;
    //       item.isNew = false;
    //       return item;
    //     } else {
    //       return item;
    //     }
    //   })
    // );
  };
  const readMessage = (chatId, messageId, userId) => {
    setChatListItems((prevChatListItems) =>
      prevChatListItems.map((item) => {
        if (item._id === chatId) {
          let newMessages = [...item.messages];
          let foundIndex = newMessages.findIndex((x) => x._id === messageId);
          if (foundIndex !== -1) {
            if (_.isArray(newMessages[foundIndex].receipt)) {
              newMessages[foundIndex].receipt.push(userId);
            } else {
              newMessages[foundIndex].receipt = [userId];
            }
          }
          item.messages = newMessages;
          item.isNew = false;
          return item;
        } else {
          return item;
        }
      })
    );
  };

  const joinChats = () => {
    socket?.emit("chat:join", { portal: portal }, (response) => {
      if (response.status === "ok") {
        console.log("Join chats");
      }
    });
  };

  useEffect(() => {
    if (!_.isEmpty(cg) && !_.isNil(portal)) {
      switch (portal) {
        case Roles.CAREGIVER:
          mclAuth
            .get(Url.MESSAGES.concat("/chat/caregiver"))
            .then((res) => {
              // console.log("response", res.data);
              let chatData = processChatData(
                res.data,
                _.pick(cg, ["firstName", "lastName", "_id"])
              );
              setChatListItems(chatData);
              joinChats();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setIsLoadingChats(false);
            });
          break;
        case Roles.CONCIERGE:
          mclAuth
            .get(Url.MESSAGES.concat("/chat/concierge"))
            .then((res) => {
              // console.log("response", res.data);
              let chatData = processChatData(
                res.data,
                _.pick(cg, ["firstName", "lastName", "_id"])
              );
              setChatListItems(chatData);
              joinChats();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setIsLoadingChats(false);
            });
          break;
        case Roles.SUPER_CONCIERGE:
        case Roles.SUPER_ADMIN:
          mclAuth
            .get(Url.MESSAGES.concat("/chat/super-concierge"))
            .then((res) => {
              // console.log("response", res.data);
              let chatData = processChatData(
                res.data,
                _.pick(cg, ["firstName", "lastName", "_id"])
              );
              setChatListItems(chatData);
              joinChats();
            })
            .catch((error) => {
              console.log(error);
            })
            .finally(() => {
              setIsLoadingChats(false);
            });
          break;
        default:
          console.log("Roles prop not found in messaging v2");
      }
    }
  }, [cg, portal]);

  useEffect(() => {
    // Check new chat every 1 minute
    const interval = setInterval(() => {
      joinChats();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [portal]);

  useEffect(() => {
    if (!_.isEmpty(cg)) {
      // TODO: Add feature for receiving new chat that is not yet exist in the user end
      // Eg: Other user start a new direct chat with current user.
      const chatReceiveHandler = (data) => {
        // console.log("receive data", data);
        if (_.isEmpty(data.chatId) || _.isEmpty(data.message)) {
          return;
        }
        let chatType = undefined;
        let chatItemIndex = chatListItems.findIndex(
          (element) => element._id === data.chatId
        );
        // setChatListItems((prevChatListItems) =>
        //   prevChatListItems.map((item) => {
        //     if (item._id === data.chatId) {
        //       chatType = item.type;
        //       let newMessages = [...item.messages, data.message];
        //       item.messages = newMessages;
        //       item.isNew = item._id === selectedChatListId ? false : true;
        //       return item;
        //     } else {
        //       return item;
        //     }
        //   })
        // );
        // This is a new chat
        if (chatItemIndex === -1) {
          mclAuth
            .get(Url.MESSAGES.concat("/direct/").concat(data.chatId))
            .then((res) => {
              // console.log("response", res.data);
              let chatData = processChatData(
                [res.data],
                _.pick(cg, ["firstName", "lastName", "_id"])
              );
              setChatListItems((prevChatData) => {
                return [...chatData, ...prevChatData];
              });
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          let chatItem = { ...chatListItems[chatItemIndex] };
          chatType = chatItem.type;
          let newMessages = [...chatItem.messages, data.message];
          chatItem.messages = newMessages;
          chatItem.isNew = chatItem._id === selectedChatListId ? false : true;
          let newChatLists = [
            chatItem,
            ...chatListItems.filter((item) => item._id !== chatItem._id),
          ];
          setChatListItems(newChatLists);
        }
        // Send read event to the server;
        if (
          data.chatId === selectedChatListId &&
          data.message.sender._id != cg._id &&
          !_.isEmpty(chatType)
        ) {
          socket.emit(
            "chat:read",
            {
              user: cg._id,
              chatId: data.chatId,
              messageId: data.message._id,
              type: chatType,
            },
            (response) => {
              if (response.status === "ok") {
                readMessage(selectedChatListId, data.message._id, cg._id);
              } else {
                // Failed
                console.log(response);
              }
            }
          );
        }
      };
      // console.log("subscribe chat:receive");
      socket?.on("chat:receive", chatReceiveHandler);
    }
    return () => {
      // console.log("unsubscribe chat:receive");
      socket?.off("chat:receive");
    };
  }, [chatListItems, selectedChatListId, cg]);

  useEffect(() => {
    // Send read event
    if (!_.isEmpty(selectedChatListId)) {
      let chatItem = chatListItems.find(
        (element) => element._id === selectedChatListId
      );
      let lastMessage = !_.isEmpty(chatItem?.messages)
        ? chatItem.messages[chatItem.messages.length - 1]
        : undefined;
      if (!_.isEmpty(lastMessage) && lastMessage.sender._id != cg._id) {
        socket.emit(
          "chat:read",
          {
            user: cg._id,
            chatId: selectedChatListId,
            messageId: lastMessage._id,
            type: chatItem.type,
          },
          (response) => {
            if (response.status === "ok") {
              readMessage(selectedChatListId, lastMessage._id, cg._id);
            } else {
              // Failed
              console.log(response);
            }
          }
        );
      }
    }
  }, [selectedChatListId]);

  const selectListItem = (chat) => {
    if (!_.isNil(chat)) {
      setSelectedChatListId(chat._id);
    }
  };

  const sendMessage = (message) => {
    let chatItem = chatListItems.find(
      (element) => element._id === selectedChatListId
    );
    let newMessageItem = {
      _id: uuidv4(),
      sender: chatItem.user,
      senderName: chatItem.user?.name,
      txt: message,
      sentAt: new Date(),
      receipt: [],
    };

    let messageData = {
      chatId: selectedChatListId,
      message: newMessageItem,
      user: cg._id,
      type: chatItem.type,
      portal: portal,
    };

    socket.emit("chat:send", messageData, (response) => {
      // console.log(response);
      if (response.status === "ok") {
        appendOrUpdateMessage(response.data);
      }
    });
  };

  const toggleRightPanel = () => {
    setRightPanelOpen(!isRightPanelOpen);
  };

  const startDirectChat = (contact) => {
    if (_.get(contact, "_id", false) && _.get(contact, "circle._id", false)) {
      let requestData = {
        userB: contact._id,
        circle: contact.circle._id,
      };
      // Search local chat first
      // console.log(chatListItems);
      let localChat = chatListItems.find((item) => {
        if (
          item.type === "direct" &&
          ((item.userA._id === cg._id && item.userB._id === contact._id) ||
            (item.userB._id === cg._id && item.userA._id === contact._id))
        ) {
          return item;
        }
      });
      if (localChat) {
        setSelectedChatListId(localChat._id);
        setContactModalOpen(false);
        return;
      }
      // If no local chat exist, then send the request to server to create a new direct chat
      setCreatingDirectChat(true);
      mclAuth
        .post(Url.MESSAGES.concat("/direct"), requestData)
        .then((res) => {
          // console.log("response", res.data);
          let chatData = processChatData(
            [res.data],
            _.pick(cg, ["firstName", "lastName", "_id"])
          );
          setChatListItems((prevChatData) => {
            return [...prevChatData, ...chatData];
          });
          joinChats();
          setContactModalOpen(false);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setCreatingDirectChat(false);
        });
    }
  };

  const isSupportChat = (chatId) => {
    if (!chatListItems) {
      return false;
    }
    let chatItem = chatListItems.find((element) => element._id === chatId);
    if (chatItem && chatItem.type === MESSAGE_CHAT_TYPE.MCL.SUPPORT) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <>
      <MessagingContactModal
        chatListItems={chatListItems}
        open={isContactModalOpen}
        handleOk={startDirectChat}
        confirmLoading={isCreatingDirectChat}
        handleCancel={() => {
          setContactModalOpen(false);
        }}
      />
      <Row className="h-full overflow-y-hidden" wrap={false}>
        <MessagingSidePanel
          isLoading={isLoadingChats}
          selectListItem={selectListItem}
          selectedChatListId={selectedChatListId}
          directChatListItems={chatListItems.filter(
            (chat) => chat.type === MESSAGE_CHAT_TYPE.DIRECT
          )}
          groupChatListItems={chatListItems.filter(
            (chat) => chat.type === MESSAGE_CHAT_TYPE.GROUP
          )}
          mclChatListItems={chatListItems.filter((chat) =>
            Object.values(MESSAGE_CHAT_TYPE.MCL).some((el) => el === chat.type)
          )}
          showContactModal={() => {
            setContactModalOpen(true);
          }}
          showDirectChat={portal === Roles.CAREGIVER}
        />
        <MessagingSection
          chatListItems={chatListItems}
          selectedChatListId={selectedChatListId}
          appendMessage={sendMessage}
          showExpandMenu={!isSupportChat(selectedChatListId)}
          toggleRightPanel={toggleRightPanel}
        />
        {selectedChatListId && isRightPanelOpen && (
          <MessagingRightPanel
            chatListItems={chatListItems}
            selectedChatListId={selectedChatListId}
            toggleRightPanel={toggleRightPanel}
          />
        )}
      </Row>
    </>
  );
};

export default MessagingV2;
