import React from "react";
import { Col, Row, Form, Button, Input, Modal } from "antd";
import { SendOutlined } from "@ant-design/icons";
import "./ContactForm.css";
import GAText from "./GAText";

const FeedbackFormModal = ({
  setting = {
    title: "Feedback form",
    destination: "",
    isModalVisible: false,
    showFirstName: true,
    showLastName: true,
    showEmail: true,
    showPhone: true,
  },
  isSubmitting = false,
  submitForm = () => {},
  handleCancel = () => {},
}) => {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    submitForm(values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <Modal
      title={setting.title}
      visible={setting.isModalVisible}
      onCancel={handleCancel}
      onOk={form.submit}
      footer={[
        <Button
          key="back"
          shape="round"
          onClick={handleCancel}
          loading={isSubmitting}
          disabled={isSubmitting}
        >
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          shape="round"
          icon={<SendOutlined />}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={form.submit}
        >
          Send
        </Button>,
      ]}
    >
      <Form
        className="faqForm"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
        form={form}
      >
        {setting.showFirstName && (
          <Form.Item
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                First Name
              </label>
            }
            required
            rules={[
              { required: true, message: "Please input your first name" },
            ]}
            name="firstName"
          >
            <Input type="text" placeholder="First Name" />
          </Form.Item>
        )}

        {setting.showLastName && (
          <Form.Item
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                Last Name
              </label>
            }
            required
            rules={[{ required: true, message: "Please input your last name" }]}
            name="lastName"
          >
            <Input type="text" placeholder="Last Name" />
          </Form.Item>
        )}
        {setting.showEmail && (
          <Form.Item
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                Email
              </label>
            }
            required
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
            name="email"
          >
            <Input type="email" placeholder="user@example.com" />
          </Form.Item>
        )}
        {setting.showPhone && (
          <Form.Item
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                Phone (+1)
              </label>
            }
            rules={[
              {
                pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                message: "Please check the format of phone number",
              },
            ]}
            name="phone"
          >
            <Input type="text" placeholder="555-555-5555" />
          </Form.Item>
        )}

        <Form.Item
          label={
            <label className="text-primary-purple" style={{ fontSize: "1rem" }}>
              Message <br />
            </label>
          }
          required
          rules={[
            {
              max: 300,
              message: "Message length cannot over 300 characters.",
            },
            {
              required: true,
              message: "Please input your feedback.",
            },
          ]}
          name="message"
          className="mb-3"
        >
          <Input.TextArea rows={5} showCount maxLength={300} />
        </Form.Item>
      </Form>
      <Row>
        <Col xs={24}>
          <a
            href="/privacy-policy"
            target="_blank"
            className="text-primary-purple text-sm"
          >
            Click here to view our privacy policy
          </a>
        </Col>
      </Row>
      <GAText></GAText>
    </Modal>
  );
};

export default FeedbackFormModal;
