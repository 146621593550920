import React from "react";
import ReactDOM from "react-dom/client";
//import './index.css';
import App from "./App";
// import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from "react-router-dom";
// import { AuthProvider } from './context/AuthProvider';
import { ScrollToTop } from "./components/Various";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <GoogleReCaptchaProvider
        reCaptchaKey="6LdNMdcgAAAAAI01VHKGphWZYjVD4IbGruvJ6w0B"
        scriptProps={{
            async: false, // optional, default to false,
            defer: false, // optional, default to false
            appendTo: "head", // optional, default to "head", can be "head" or "body",
            nonce: undefined, // optional, default undefined
        }}
    >
        <Router>
            {/* Scroll to top when the navigating to other pages */}
            <ScrollToTop />
            <App />
        </Router>
    </GoogleReCaptchaProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// <React.StrictMode>
//  <AuthProvider> */ }
//  </AuthProvider> */ }
//   </React.StrictMode >
