import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

import {Helmet} from "react-helmet"; 

import { Navbar } from "../../components/Navbar";
import {
  Hero,
  WhoAreCaregivers,
  Jumbotron,
  Platform,
  PlatformFor,
  WhyMCL,
  SectionBanner,
  Herov2,
  Benefits,
  CaseStudies,
  Saying,
  InTouch
} from "../../components/HomeComponents";
import Footer from "../../components/Footer";
import HomeHero from "../../assets/images/home-hero.png";

//import { Link } from "react-router-dom";

export const Homev2 = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const modal = searchParams.get('modal');
  const contact = modal === 'contact' ? true : false;
  const schedule = modal === 'schedule' ? true : false;
  const partner = modal === 'partner' ? true : false;

  // console.log('!@!@:', modal, contact, schedule);

  return (
    <main>
      <Helmet>
          <meta charSet="utf-8" />
          <title>MyCareLinq: Family Caregiver Resources</title>
          <meta name="description" content="Inadequate, under resourced family caregiving support resulting in financial hardship, emotional & physical burnout. My CareLinq solves it with a multi-member family app to centralize important health, medical, legal, financial, social, and after-life care instructions and documents. Help create custom care plans, collaboration, effective caregiving management." />
          <link rel="canonical" href="https://mycarelinq.com" />
      </Helmet>

      <Navbar signin={true} navigate={navigate} />
      <section
        id="home"
      >
        <Herov2 />
      </section>
      {/* <section className="overflow-hidden">
        <SectionBanner message="Create an account to get started on a better path to caregiving." />
      </section> */}
      <section className="overflow-hidden">
        {/* <SectionBanner message="My CareLinq is a collaborative care, support, and resource management platform. We also offer affordable services to help get urgent tasks done. Sign up for free." /> */}
        <SectionBanner message="My CareLinq offers a full-service Family Caregiving Solution for employees who are caring for loved ones." />
      </section>
      <section className="overflow-hidden">
        <Benefits />
      </section>
      <section>
        <CaseStudies />
      </section>
      <section id="testimonials" c>
        <Saying/>
      </section>
      <section>
        <InTouch />
      </section>
      {/* <section className="overflow-hidden">
        <SectionBanner message="We are on a mission to change the family caregiving model through personalized support and curated resources that you control from an online dashboard." />
      </section>
      <section id="testimonials" className="overflow-hidden">
        <Jumbotron />
      </section> */}
      {/* <section className="overflow-hidden">
        <SectionBanner message="To those of you who need care, and those that give it, we hear your struggles and we are here to improve the caregiving and planning experience." />
      </section>
      <section id="platform-whyMCL" className="overflow-hidden">
        <WhyMCL
          getStartedFunc={() => {
            navigate("/survey");
          }}
        />
      </section> */}
      {/* <Footer showSchedule={schedule} showPartner={partner}/> */}
      <Footer/>
    </main>
  );
};
