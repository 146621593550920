import React from "react";
import { Avatar } from "antd";

const ChatAvatarIcon = ({ size = 32, Icon, iconStyle, avatarStyle }) => {
  return (
    <Avatar size={size} icon={<Icon style={iconStyle} />} style={avatarStyle} />
  );
};

export default ChatAvatarIcon;
