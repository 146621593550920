import React, { useRef, useLayoutEffect } from 'react';
import moment from 'moment';
import { Image, Card, Input, Row, Col, Space, Form } from 'antd';
import { Link } from "react-router-dom";
import { ClockCircleOutlined } from "@ant-design/icons";

import {
    AUTHOR_IMAGE_PLACEHOLDER,
    Url
} from "../constants/Global";
import _ from "lodash";


const VideoDetail = ({ video }) => {

    // const containerRef = useRef();
    // useLayoutEffect(() => {
    //     if (containerRef.current && containerRef.current.firstElementChild && containerRef.current.firstElementChild.classList) {
    //         containerRef.current.firstElementChild.classList.add('library-embed-video');
    //     }
    // });

    return (
        <>
            <div className="bg-white shadow-lg rounded-lg lg:p-8 pb-12 mb-8">
                <div className="px-4 lg:px-0 text-justify">
                    {/* <div
                        ref={containerRef}
                        dangerouslySetInnerHTML={{
                            __html: video.content
                        }}
                    /> */}

                    <iframe
                        className='library-embed-video'
                        src={video.embedUrl}
                        title={video.title}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                    >
                    </iframe>
                    <div className="flex items-center mt-2 w-full">
                        <div className="flex items-center justify-center mr-8">
                            <Image
                                alt={""}
                                height={30}
                                width={30}
                                className="align-middle rounded-full"
                                src={(_.isNil(video.author) || _.isEmpty(video.author.photo)) ? AUTHOR_IMAGE_PLACEHOLDER : video.author.photo}
                            />
                            <p className="inline-flex items-center align-middle text-gray-700 ml-2 font-medium text-lg mt-0 mb-0">{video.author?.name}</p>
                        </div>
                        {!_.isNil(video.duration) &&
                            <div className="flex items-center justify-center font-medium text-gray-700">
                                <ClockCircleOutlined className="text-[30px] text-primary-purple mr-2" />
                                <span className="align-middle inline-flex items-center">{video.duration}</span>
                            </div>
                        }
                    </div>
                    <h1 className="mb-2 text-h1 text-primary-purple text-start">{video.title}</h1>
                    <p className='text-primary-purple text-base'>
                        {video.caption}
                    </p>
                </div>
            </div>
        </>
    );
};

export default VideoDetail;