import React from 'react';
import moment from 'moment';
import { Image, Card, Input, Row, Col, Space, Form } from 'antd';
import { Link } from "react-router-dom";
import {
    CalendarOutlined
} from '@ant-design/icons';
import {
    ARTICLE_IMAGE_PLACEHOLDER,
    AUTHOR_IMAGE_PLACEHOLDER,
    Url
} from "../constants/Global";
import ReactMarkdown from 'react-markdown'
import DownloadArticle from "./DownloadArticle";
import _ from "lodash";

import { getDefaultLibraryImage, getLibraryImage } from "./Various";


const ArticleDetail = ({ article }) => {
    let defaultImageLink = getDefaultLibraryImage(article.categories);

    return (
        <>
            <div className="bg-white shadow-lg shadow-black/40 rounded-lg lg:p-8 pb-12 mb-8">
                <div className="relative overflow-hidden shadow-md">
                    <img src={_.isEmpty(article.featuredImage) ? defaultImageLink : getLibraryImage(article.featuredImage, "article")} alt="" className="object-top h-full w-full object-cover shadow-lg rounded-t-lg lg:rounded-lg" />
                </div>
                {
                    !_.isNil(article.imageCaption) &&
                    <p className='text-primary-purple text-xs text-center mt-2'>
                        {article.imageCaption?.text}
                        {" "} <a href={article.imageCaption?.url} target="_blank" rel="noopener noreferrer">
                            {article.imageCaption?.author}
                        </a>
                    </p>
                }
                <div className="px-4 lg:px-0 text-justify mt-6">
                    <div className="flex items-center mb-8 w-full">
                        <div className="flex items-center justify-center mr-8">
                            <Image
                                alt={""}
                                height={30}
                                width={30}
                                className="align-middle rounded-full"
                                src={(_.isNil(article.author) || _.isEmpty(article.author.photo)) ? AUTHOR_IMAGE_PLACEHOLDER : article.author.photo}
                            />
                            <p className="inline-flex items-center align-middle text-gray-700 ml-2 font-medium text-lg mt-0 mb-0">{article.author?.name}</p>
                        </div>
                        <div className="flex items-center justify-center font-medium text-gray-700">
                            <CalendarOutlined className="text-[30px] text-primary-purple mr-2" />
                            <span className="align-middle inline-flex items-center">{moment(article.date).format('MMM DD, YYYY')}</span>
                        </div>
                    </div>
                    <h1 className="mb-8 text-h1 text-primary-purple text-start">{article.title}</h1>
                    <ReactMarkdown
                        className={`
                                    prose-h4:text-primary-purple prose-h4:text-h4 
                                    prose-h6:text-primary-purple prose-h6:text-h6 prose-h6:font-bold 
                                    prose-p:text-primary-purple prose-p:text-base
                                    prose-em:text-primary-purple prose-em:text-base
                                    `
                        }
                        children={article.content}
                    />
                    {
                        (article.downloadOnly) && (
                            <DownloadArticle
                                article={article}
                            />
                        )
                    }
                </div>
            </div>
        </>
    );
};

export default ArticleDetail;