import React, { useEffect, useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router";

import {
  Avatar,
  Badge,
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Radio,
  Row,
  Select,
  Skeleton,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  CloudUploadOutlined,
  EditOutlined,
  LogoutOutlined,
  PhoneOutlined,
  RiseOutlined,
  UserOutlined,
} from "@ant-design/icons";

import {
  Care,
  CareTypeToColor,
  CareTypeToStr,
  Roles,
  RoleToRoute,
  RoleToStr,
  Subscription,
  SubscriptionToColor,
  SubscriptionToStr,
  Url,
  UsState,
} from "../constants/Global";
import { v4 as uuidv4 } from "uuid";

import moment from "moment";

import useCg from "../hooks/useCg";
import useAuth from "../hooks/useAuth";
import useMclAuth from "../hooks/useMclAuth";
import CircleCarePlanSynopsis from "../pages/caregiver/CircleCarePlan";

import _, { extendWith } from "lodash";
const { Option } = Select;

const Divider = ({ props, children }) => {
  return (
    <div
      style={{ marginTop: "8px", marginLeft: "0.6rem" }}
    >
      <span
        style={{
          fontSize: "0.6rem",
          margin: "1rem 0 0 0",
          borderWidth: 0,
        }}
      >
        {children}
      </span>
    </div>
  );
};

const MyProfile = ({
  cg,
  setCg,
  isLoading,
  showComponent,
  setPageContent,
  openProfileDrawer,
  setOpenProfileDrawer,
  setCurrentCircle,
  role,
  subscription,
  myCircles,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [subID, setSubID] = useState("");
  const [cusID, setCusID] = useState("");
  const [expiration, setExpiration] = useState("");
  const [current1stName, setCurrent1stName] = useState(cg.firstName);
  const [currentLastName, setCurrentLastName] = useState(cg.lastName);
  const [currentYyyyMm, setCurrentYyyyMm] = useState(cg.birthYyyyMm);
  const [currentPhone, setCurrentPhone] = useState(cg.phone);
  const [currentAddr1, setCurrentAddr1] = useState(cg.addr1);
  const [currentAddr2, setCurrentAddr2] = useState(cg.addr2);
  const [currentCity, setCurrentCity] = useState(cg.city);
  const [currentUsState, setCurrentUsState] = useState(cg.state);
  const [currentZipCode, setCurrentZipCode] = useState(cg.zipcode);
  const [openProfileEditor, setOpenProfileEditor] = useState(false);
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [profileForm] = Form.useForm();

  useEffect(() => {
    mclAuth.get(Url.PAYMENT.concat("/subExpiration")).then((res) => {
      if (res) {
        // console.log(res.data);
        setExpiration(
          res.data.subExpiration == undefined || res.data.subExpiration === 0
            ? ""
            : "Expiration date: " +
                new Intl.DateTimeFormat("en-US").format(
                  res.data.subExpiration * 1000
                )
        );
      }
    });
  }, []);

  if (isLoading) {
    console.log("isLoading!!!");
    return <Skeleton active />;
  }

  const isMultiRolesAcct = (cg) => {
    // console.log("***isMultiRolesAcct ~ cg:\n", cg);
    if (isLoading || _.isEmpty(cg) || cg.roles.length < 2) {
      return false;
    }
    return true;
  };
  const onSwitchRole = ({ target: { value } }) => {
    const intValue = parseInt(value);
    const switchRole = Number.isInteger(intValue) ? intValue : 999;
    const destin = RoleToRoute(switchRole);

    if (destin !== "" && location.pathname !== destin) {
      navigate(destin);
    }
  };
  const createSwitchRolesButtons = (cg, role, location) => {
    const sortedRoles = cg.roles;
    // console.log("~~~~~~sortedRoles:", sortedRoles);
    // console.log("~~~~~~role:", role);
    return (
      <Radio.Group
        defaultValue={role.toString()}
        onChange={onSwitchRole}
        buttonStyle="solid"
        size="small"
      >
        {sortedRoles
          .sort((a, b) => a - b)
          .map((curRole, index) => {
            // console.log("~~~~~~curRole:", curRole);
            return (
              <Radio.Button key={uuidv4()} value={curRole.toString()}>
                {RoleToStr(curRole)}
              </Radio.Button>
            );
          })}
      </Radio.Group>
    );
  };

  const onCloseProfileDrawer = () => {
    // console.log("Enter onCloseProfileDrawer()");
    setOpenProfileDrawer(false);
  };
  const showProfileEditor = () => {
    // console.log("Enter showProfileEditor()");
    setOpenProfileEditor(true);
  };
  const onCloseProfileEditor = () => {
    // console.log("Enter onCloseProfileEditor()");
    setOpenProfileEditor(false);
  };
  const doProfileUpgrade = () => {
    // console.log("***Enter doProfileUpgrade()");
    navigate("/pricing");
  };
  const signOut = () => {
    // console.log("***Enter signOut()");
    setAuth({});
    localStorage.clear();
    navigate("/");
  };

  const getPrimaryCircles = (cg) => {
    const primaryCircles = new Array();
    if (role !== Roles.CAREGIVER) {
      return primaryCircles;
    }
    if (isLoading || _.isEmpty(cg)) {
      console.log(
        "******getPrimaryCircles(cg) - cg is empty or circle is loading"
      );
      return primaryCircles;
    }
    // console.log("~~~~~~myCircles length:", myCircles.length);
    myCircles.map((circle, idx) => {
      // if (idx > 1) {
      //   console.log(
      //     "***getPrimaryCircles current idx=" +
      //       idx +
      //       " circle._id: " +
      //       circle._id
      //   );
      //   console.log(">>>>>>MyProfile getPrimaryCircles circle:\n", circle);
      //   console.log(">>>>>>MyProfile getPrimaryCircles primary:\n", primary);
      // }
      if (circle.primary) {
        for (let i = 0; i < circle.primary.length; i++) {
          if (circle.primary[i]._id === cg._id) {
            primaryCircles.push(circle);
          }
        }
      }
    });
    // console.log(
    //   "***MyProfile getPrimaryCircles primaryCircles",
    //   primaryCircles
    // );
    return primaryCircles;
  };
  const getSubscription = (cg) => {
    if (role !== Roles.CAREGIVER) {
      return 999;
    }
    if (isLoading || _.isEmpty(cg)) {
      console.log("******getSubscription(cg) - cg is empty or being loaded!");
      return 999;
    }
    // console.log("***getSubscription\ncg:", cg);
    const primaryCircles = getPrimaryCircles(cg);
    // return only the subscription tier for the first primary circle
    // even if the caregiver is the primary for multiple circles
    return primaryCircles.length > 0
      ? primaryCircles[0].subscription
      : Subscription.FREE;
    // if (isPrimary(cg)) {
    //   return cg.circles[currentCircle].subscription;
    // }
  };
  const getPrimaryCareType = (cg) => {
    if (isLoading || _.isEmpty(cg)) {
      console.log("******getPrimaryCareType(cg) - cg is empty or being loaded");
      return null;
    }
    const primaryCircles = getPrimaryCircles(cg);
    // console.log("***getPrimaryCareType primaryCircles:", primaryCircles);
    // return only the care type for the first primary circle
    // even if the caregiver is the primary for multiple circles
    if (primaryCircles.length > 0) {
      return primaryCircles[0].care;
    }
    return null;
  };

  const OnChangeBirthYearMonth = (date, dateStr) => {
    console.log("+++++>date:", date);
    console.log("+++++>dateStr:", dateStr);
    // setCurrentYyyyMm(dateStr);
    // profileForm.setFieldsValue({ birthYyyyMm: dateStr });
    profileForm.setFieldsValue({ birthYyyyMm: moment(dateStr, "YYYY-MM") });
  };

  const populateStateOptions = () => {
    const usStates = Object.keys(UsState);
    return (
      <>
        {usStates.map((state, idx) => {
          // console.log("+++++>UsState[state]:", UsState[state]);
          return (
            <Option key={idx} value={UsState[state].name}>
              {state}
            </Option>
          );
        })}
        ;
      </>
    );
  };
  const get2LetterStateByName = (stateName) => {
    return Object.keys(UsState).find((key) => UsState[key].name === stateName);
  };
  const onChangeState = (state) => {
    // console.log("+++++>State selected:", get2LetterStateByName(stateName));
    // setCurrentUsState(get2LetterStateByName(stateName));
    profileForm.setFieldsValue({
      state: get2LetterStateByName(state),
    });
  };
  const onChangeZipCode = (e) => {
    // console.log("+++++>ZipCodeStr:", e.target.value);
    const zipCode = parseInt(e.target.value);
    // setCurrentZipCode(zipCodeStr);
    profileForm.setFieldsValue({ zipCode: e.target.value });
    // Need to handle special cases for Mississippi (Warren) and U.S. Virgin Island
    if (zipCode === 71233) {
      // setCurrentZipCode("71233");
      // setCurrentUsState("MS");
      profileForm.setFieldsValue({ state: "MS", zipCode: "71233" });
    } else {
      if (zipCode >= UsState["VI"].zipMin && zipCode <= UsState["VI"].zipMax) {
        // setCurrentZipCode(zipCodeStr);
        // setCurrentUsState("VI");
        profileForm.setFieldsValue({ state: "VI", zipCode: e.target.value });
      } else {
        Object.keys(UsState).map((state, idx) => {
          if (
            zipCode >= UsState[state].zipMin &&
            zipCode <= UsState[state].zipMax
          ) {
            // setCurrentZipCode(zipCodeStr);
            // setCurrentUsState(state);
            profileForm.setFieldsValue({
              state: state,
              zipCode: e.target.value,
            });
          }
        });
      }
    }
  };
  const prefillProfileForm = (cg) => {
    let initialValues = {};
    if (_.isEmpty(cg)) {
      return initialValues;
    }
    if (!_.isNil(cg.firstName)) {
      initialValues.firstName = cg.firstName;
    }
    if (!_.isNil(cg.lastName)) {
      initialValues.lastName = cg.lastName;
    }
    if (!_.isNil(cg.birthYyyyMm) && cg.birthYyyyMm !== "") {
      // const dateFormat = "YYYY-MM";
      // const birthYyyyMm = moment(cg.birthYyyyMm, dateFormat);
      // initialValues.birthYyyyMm = cg.birthYyyyMm;
      // console.log(
      //   "***initialValues.birthYyyyMm cg.birthYyyyMm:",
      //   cg.birthYyyyMm
      // );
      initialValues.birthYyyyMm = moment(cg.birthYyyyMm, "YYYY-MM");
    }
    if (!_.isNil(cg.phone)) {
      initialValues.phone = cg.phone;
    }
    if (!_.isNil(cg.addr1)) {
      initialValues.addr1 = cg.addr1;
    }
    if (!_.isNil(cg.addr2)) {
      initialValues.addr2 = cg.addr2;
    }
    if (!_.isNil(cg.city)) {
      initialValues.city = cg.city;
    }
    if (!_.isNil(cg.state)) {
      initialValues.state = cg.state;
    }
    if (!_.isNil(cg.zipCode)) {
      initialValues.zipCode = cg.zipCode;
    }
    return initialValues;
  };
  const onProfileFinish = (values) => {
    // console.log("Profile Updated:", values);
    let newCg = { ...cg, ...values };
    // console.log(
    //   "newCg.birthYyyyMm:",
    //   moment(newCg.birthYyyyMm).format("YYYY-MM")
    // );
    mclAuth
      .post(Url.CAREGIVERS.concat("/profile"), {
        firstName: newCg.firstName,
        lastName: newCg.lastName,
        birthYyyyMm: moment(newCg.birthYyyyMm).format("YYYY-MM"),
        phone: newCg.phone,
        addr1: newCg.addr1,
        addr2: newCg.addr2,
        city: newCg.city,
        state: newCg.state,
        zipCode: newCg.zipCode,
      })
      .then((res) => {
        // console.log("User profile saved");
        profileForm.resetFields();
        setCg(newCg);
        setOpenProfileEditor(false);
      })
      .catch((error) => {
        console.log(error.message);
      });
  };
  const onProfileFinishFailed = (errorInfo) => {
    console.log("Profile Update Failed:", errorInfo);
  };
  const onSaveProfile = () => {
    // console.log("+++++>profileForm:\n", profileForm);
    profileForm.submit();
    // console.log("+++++>\tcurrent1stName:", current1stName);
    // console.log("+++++>\tcurrentLastName:", currentLastName);
    // console.log("+++++>\tcurrentYyyyMm:", currentYyyyMm);
    // console.log("+++++>\tphone:", currentPhone);
    // console.log("+++++>\tcurrentAddr1:", currentAddr1);
    // console.log("+++++>\tcurrentAddr2:", currentAddr2);
    // console.log("+++++>\tcurrentCity:", currentCity);
    // console.log("+++++>\tcurrentUsState:", currentUsState);
    // console.log("+++++>\tcurrentZipCode:", currentZipCode);
  };

  const findInCircles = (circleIdx) => {
    for (let i = 0; i < cg.circles.length; i++) {
      if (cg.circles[i]._id === myCircles[circleIdx]._id) {
        // console.log("+-+-+ found in circle! index=", i);
        return i;
      }
    }
    // for (let i = 0; i < myCircles.length; i++) {
    //   if (myCircles[i]._id === cg.circles[circleIdx]._id) {
    //     console.log("+++++ found in circle! index=",i);
    //     return i;
    //   }
    // }
    console.log("++++++ not found mycircle index=!", circleIdx);
    return circleIdx;
  };

  const setCurrentMyCircle = (circleIdx) => {
    setCurrentCircle(findInCircles(circleIdx));
  };

  return (
    <Drawer
      title="Account Profile"
      placement="right"
      width="24rem"
      onClose={onCloseProfileDrawer}
      visible={openProfileDrawer}
      // extra={
      //   <Space>
      //     <Button
      //       icon={<CloseCircleFilled style={{ color: "#3c3066d0" }} />}
      //       onClick={onCloseProfileDrawer}
      //     >
      //       Close
      //     </Button>
      //   </Space>
      // }
      id="client-profile-drawer"
    >
      {isMultiRolesAcct(cg) ? (
        <>
          <Divider
            orientation="left"
            plain
            style={{
              color: "#eef2f6",
              fontSize: "0.6rem",
              margin: 0,
              borderWidth: 0,
            }}
          >
            ACCOUNT ROLE
          </Divider>
          <div>{createSwitchRolesButtons(cg, role, location)}</div>
        </>
      ) : (
        <></>
      )}
      <Divider
        orientation="left"
        plain
        style={{
          color: "#eef2f6",
          fontSize: "0.6rem",
          margin: "1rem 0 0 0",
          borderWidth: 0,
        }}
        className="profile-divider"
      >
        PROFILE
      </Divider>
      <div className="flex flex-row grow gap-1 items-center bg-white text-[#10082d] rounded-md px-2 py-1">
        <Avatar
          style={{
            backgroundColor: SubscriptionToColor(getSubscription(cg)),
          }}
          icon={<UserOutlined />}
        />
        {_.isEmpty(cg) ? (
          <Spin />
        ) : role === Roles.CAREGIVER ? (
          // <Badge.Ribbon
          //   text={CareTypeToStr(getPrimaryCareType(cg))}
          //   title="Type of Account"
          //   color={CareTypeToColor(getPrimaryCareType(cg))}
          //   style={{ top: "-1.25rem", fontSize: "0.6rem" }}
          // >
          <>
            {cg.firstName} {cg.lastName}
          </>
        ) : // </Badge.Ribbon>
        role === Roles.SUPER_CONCIERGE ? (
          <Badge.Ribbon
            text="Super CC"
            title="Login Role"
            color="#e0551bc0"
            style={{ top: "-1.25rem", fontSize: "0.6rem" }}
          >
            {cg.firstName} {cg.lastName}
          </Badge.Ribbon>
        ) : role === Roles.CONCIERGE ? (
          <Badge.Ribbon
            text="Care Concierge"
            title="Login Role"
            color="#217671c0"
            style={{ top: "-1.25rem", fontSize: "0.6rem" }}
          >
            {cg.firstName} {cg.lastName}
          </Badge.Ribbon>
        ) : (
          <Badge.Ribbon
            text={"Unknown Role:" + role}
            title="Unknown Role"
            color="#c80b29c0"
            style={{ top: "-1.25rem", fontSize: "0.6rem" }}
          >
            {cg.firstName} {cg.lastName}
          </Badge.Ribbon>
        )}
        <Tooltip title="Edit Profile">
          <Button
            type="text"
            shape="round"
            size="small"
            icon={<EditOutlined style={{ color: "white" }} />}
            onClick={showProfileEditor}
            className="ml-auto text-white items-center gap-x-1 bg-[#3c3066c0] hover:bg-[#3c3066a0] hover:text-white focus:bg-[#3c306680] focus:text-white"
          >
            Edit
          </Button>
        </Tooltip>
      </div>
      <Divider
        orientation="left"
        plain
        style={{ color: "#eef2f6", fontSize: "0.6rem", margin: "1rem 0 0 0" }}
        className="profile-divider"
      >
        USERNAME
      </Divider>
      <div className="flex flex-row grow gap-1 items-center bg-white text-[#10082d] rounded-md px-2 py-1">
        {cg.email}
      </div>

      <Drawer
        title="Edit Profile"
        width="24rem"
        onClose={onCloseProfileEditor}
        visible={openProfileEditor}
        extra={
          <Space>
            {/* <Button onClick={onCloseProfileEditor}>Cancel</Button> */}
            <Button
              type="primary"
              shape="round"
              size="small"
              onClick={onSaveProfile}
              icon={<CloudUploadOutlined />}
            >
              Save
            </Button>
            {/* <Button onClick={submitUpdatedProfile}>Submit</Button> */}
          </Space>
        }
        id="client-profile-editor"
      >
        <Row className="flex flex-row grow justify-between items-center text-[1rem] p-2">
          Username
        </Row>
        <div className="text-[1rem] text-[#3c3066] rounded-md bg-white px-2">
          {_.isEmpty(cg) || !cg.email ? <Spin /> : cg.email}
        </div>
        <p className="text-[0.75rem] text-[#3c3066] px-2">
          Account created on{" "}
          {!_.isEmpty(cg)
            ? cg.createdAt.substring(5, 10) + "-" + cg.createdAt.substring(0, 4)
            : null}
        </p>
        <Form
          form={profileForm}
          layout="vertical"
          onFinish={onProfileFinish}
          onFinishFailed={onProfileFinishFailed}
          initialValues={prefillProfileForm(cg)}
        >
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="firstName"
                label="First Name"
                rules={[
                  {
                    required: true,
                    message: "Your first name here",
                  },
                  { max: 50, message: "First name max 50 char" },
                ]}
              >
                <Input
                  placeholder={cg.firstName}
                  onChange={(e) => {
                    // console.log("+++++> firstName:", e.target.value);
                    // setCurrent1stName(firstName);
                    profileForm.setFieldsValue({ firstName: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="lastName"
                label="Last Name"
                rules={[
                  {
                    required: true,
                    message: "Your last name here",
                  },
                  { max: 50, message: "Last name max 50 char" },
                ]}
              >
                <Input
                  placeholder={cg.lastName}
                  onChange={(e) => {
                    // console.log("+++++> lastName:", e.target.value);
                    // setCurrentLastName(lastName);
                    profileForm.setFieldsValue({ lastName: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="birthYyyyMm"
                label="Birth Year &amp; Month"
                rules={[
                  {
                    required: true,
                    message: "Your birth year and month",
                  },
                ]}
              >
                <DatePicker
                  onChange={OnChangeBirthYearMonth}
                  picker="month"
                  placeholder="YYYY-MM"
                  className="flex grow"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phone"
                label="Phone #"
                rules={[
                  {
                    required: false,
                    message: "Your phone number",
                  },
                  {
                    pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                    message: "Phone number format invalid",
                  },
                ]}
              >
                <Input
                  placeholder={cg.phone}
                  onChange={(e) => {
                    // console.log("+++++> phone:", e.target.value);
                    // setCurrentPhone(phone);
                    profileForm.setFieldsValue({ phone: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="addr1"
                label="Address Line 1"
                rules={[
                  {
                    required: false,
                    message: "Your address line 1",
                  },
                  { max: 100, message: "Address line max 100 char" },
                ]}
              >
                <Input
                  placeholder="Address line 1"
                  onChange={(e) => {
                    // console.log("+++++> addr1:", e.target.value);
                    // setCurrentAddr1(addr1);
                    profileForm.setFieldsValue({ addr1: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8}>
            <Col span={24}>
              <Form.Item
                name="addr2"
                label="Address Line 2"
                rules={[
                  {
                    required: false,
                    message: "Your address line 2",
                  },
                ]}
              >
                <Input
                  placeholder="Address line 2"
                  onChange={(e) => {
                    // console.log("+++++> addr2:", e.target.value);
                    // setCurrentAddr2(addr2);
                    profileForm.setFieldsValue({ addr2: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={8} className="text-[0.75rem]">
            <Col span={13}>
              <Form.Item
                name="city"
                label="City"
                rules={[
                  {
                    required: true,
                    message: "The city where you reside",
                  },
                  { max: 50, message: "Address line max 100 char" },
                ]}
              >
                <Input
                  placeholder="City"
                  onChange={(e) => {
                    // console.log("+++++> city:", e.target.value);
                    // setCurrentCity(city);
                    profileForm.setFieldsValue({ city: e.target.value });
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={5}>
              <Form.Item
                name="state"
                label="State"
                rules={[
                  {
                    required: true,
                    message: "The state where you reside",
                  },
                ]}
              >
                <Select value={cg.state} onChange={onChangeState}>
                  {populateStateOptions(cg)}
                </Select>
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="zipCode"
                label="Zip code"
                rules={[
                  { required: true, message: "Your zip code" },
                  {
                    pattern: /^\d{5}$/,
                    message: "Zip code must be 5 digits",
                  },
                ]}
              >
                <Input placeholder="99999" onChange={onChangeZipCode} />
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={8}>
            <Col span={12}>
              <Form.Item
                name="country"
                label="Country"
                rules={[
                  {
                    required: true,
                    message: "The country where you live in",
                  },
                ]}
              >
                <Input placeholder="USA" />
              </Form.Item>
            </Col>
          </Row> */}
        </Form>
      </Drawer>
      {role === Roles.CAREGIVER ? (
        <>
          <Divider
            orientation="left"
            plain
            style={{
              color: "#eef2f6",
              fontSize: "0.6rem",
              margin: "1rem 0 0 0",
            }}
            className="profile-divider"
          >
            SUBSCRIPTION PLAN
          </Divider>
          <div className="flex flex-row grow items-center gap-1 bg-white text-[#10082d] rounded-md px-2 py-1">
            <p
              className="flex flex-row ml-1 my-1 mr-auto items-center gap-x-1"
              style={{ fontColor: SubscriptionToColor(getSubscription(cg)) }}
            >
              {SubscriptionToStr(getSubscription(cg))} Plan
            </p>
            {/* <Tooltip title="Upgrade Plan"> */}
            <Tooltip title="Please Ask Min for upgrade details">
              <Button
                type="text"
                shape="round"
                size="small"
                icon={<RiseOutlined style={{ color: "white" }} />}
                // onClick={doProfileUpgrade}
                className="ml-auto text-white items-center gap-x-1 bg-[#4d66ffc0] hover:bg-[#4d66ffa0] hover:text-white focus:bg-[#4d66ff80] focus:text-white"
              >
                Upgrade?
              </Button>
            </Tooltip>
          </div>
        </>
      ) : (
        <></>
      )}
      {role === Roles.CAREGIVER ? (
        <>
          <Divider
            orientation="left"
            plain
            style={{
              color: "#eef2f6",
              fontSize: "0.6rem",
              margin: "1rem 0 0 0",
            }}
            className="profile-divider"
          >
            CARES PLAN FOR
          </Divider>
          <CircleCarePlanSynopsis
            setPage={setPageContent}
            cg={cg}
            showComponent={showComponent}
            setCurrentCircle={setCurrentMyCircle}
            setOpenProfileDrawer={setOpenProfileDrawer}
            myCircles={myCircles}
          />
        </>
      ) : (
        <></>
      )}
      <Divider plain style={{ color: "#eef2f6" }} className="profile-divider" />
      <div className="grid place-items-end">
        <Button
          type="link"
          shape="round"
          size="small"
          icon={<LogoutOutlined />}
          onClick={signOut}
          className="text-[#10082d] items-center gap-x-1 bg-[#eef2f600] hover:bg-[#10082da0] hover:text-white focus:bg-[#10082d80] focus:text-white"
        >
          Sign Out
        </Button>
      </div>
    </Drawer>
  );
};

export default MyProfile;
