import React, { useEffect, useState } from "react"
import { Row, Col, Card, Breadcrumb, Space, Empty, Spin, Modal, List, Tabs } from 'antd';
import { LineChart, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend, Line } from "recharts";
import useMclAuth from '../../hooks/useMclAuth';
import useAuth from '../../hooks/useAuth';
import { Url } from '../../constants/Global';
import moment from "moment";
import {
  openNotification
} from "../../components/Various";
import _ from "lodash";
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const ReportCard = ({ title, number, prevNumber, onClick = () => { }, size = [24, 12, 6] }) => {
  return (
    <Col xs={size[0]} md={size[1]} lg={size[2]}>
      <Card
        className="mt-4"
        title={title}
        hoverable
        onClick={onClick}
      >
        <h1 className="text-5xl text-primary-purple text-center">{number}</h1>
        {
          _.isNumber(prevNumber) &&
          <Row className="text-4xl text-center w-full">
            <Col xs={24}>
              <Space>
                <span className="text-4xl text-center">
                  {number - prevNumber >= 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />}
                </span>
                <h2 className={"mb-0 text-4xl text-center " + (number - prevNumber >= 0 ? "text-green-600" : "text-red-600")}>{number - prevNumber}</h2>
              </Space>
            </Col>
          </Row>
        }
      </Card>
    </Col >
  )
}

const WeeklyReport = ({ data = [], emailData = {}, setModalData = () => { } }) => {

  const XAxisFormat = (tickItem) => {
    // If using moment.js
    return moment(tickItem).format('MMM Do YY')
  }

  return (
    <>
      {
        _.isEmpty(data)
          ?
          <Row>
            <Col xs={24}>
              <Empty />
            </Col>
          </Row>
          :
          <Card bordered={false} >
            <Row gutter={[16, 16]}>
              <ReportCard
                title={"Active User (Weekly)"}
                number={!_.isEmpty(data) && data[data.length - 1]["weeklyActiveUser"]}
                prevNumber={!_.isEmpty(data) && data.length > 1 && data[data.length - 2]["weeklyActiveUser"]}
                onClick={() => {
                  setModalData({
                    title: "Active User (Weekly)",
                    isOpen: true,
                    data: !_.isEmpty(emailData) ? emailData["weeklyActiveUser"] : []
                  });
                }}
              />
              <ReportCard
                title={"Active User (Monthly)"}
                number={!_.isEmpty(data) && data[data.length - 1]["monthlyActiveUser"]}
                prevNumber={!_.isEmpty(data) && data.length > 1 && data[data.length - 2]["monthlyActiveUser"]}
                onClick={() => {
                  setModalData({
                    title: "Active User (Monthly)",
                    isOpen: true,
                    data: !_.isEmpty(emailData) ? emailData["monthlyActiveUser"] : []
                  });
                }}
              />
              <ReportCard
                title={"New User (Weekly)"}
                number={!_.isEmpty(data) && data[data.length - 1]["weeklyNewUser"]}
                prevNumber={!_.isEmpty(data) && data.length > 1 && data[data.length - 2]["weeklyNewUser"]}
                onClick={() => {
                  setModalData({
                    title: "New User (Weekly)",
                    isOpen: true,
                    data: !_.isEmpty(emailData) ? emailData["weeklyNewUser"] : []
                  });
                }}
              />
              <ReportCard
                title={"New User (Monthly)"}
                number={!_.isEmpty(data) && data[data.length - 1]["monthlyNewUser"]}
                prevNumber={!_.isEmpty(data) && data.length > 1 && data[data.length - 2]["monthlyNewUser"]}
                onClick={() => {
                  setModalData({
                    title: "New User (Monthly)",
                    isOpen: true,
                    data: !_.isEmpty(emailData) ? emailData["monthlyNewUser"] : []
                  });
                }}
              />
            </Row>
            <Row className="mt-4 mb-16" gutter={[16, 16]}>
              <Col xs={12}>
                <Card title="Active User Chart">
                  <ResponsiveContainer width={"100%"} height={400}>
                    <LineChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="createdAt" tickFormatter={XAxisFormat} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="monthlyActiveUser" stroke="#58508d" />
                      <Line type="monotone" dataKey="weeklyActiveUser" stroke="#bc5090" />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
              <Col xs={12}>
                <Card title="User Growth Chart">
                  <ResponsiveContainer width={"100%"} height={400}>
                    <LineChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="createdAt" tickFormatter={XAxisFormat} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="monthlyNewUser" stroke="#003f5c" />
                      <Line type="monotone" dataKey="weeklyNewUser" stroke="#ff6361" />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>
            <Row className="mt-4 mb-16" gutter={[16, 16]}>
              <Col xs={24}>
                <Card title="Total User Chart">
                  <ResponsiveContainer width={"100%"} height={400}>
                    <LineChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="createdAt" tickFormatter={XAxisFormat} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="totalUserCount" stroke="#ffa600" />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>
          </Card>
      }
    </>
  )
}

const DailyReport = ({ data = [], emailData = {}, setModalData = () => { } }) => {

  const XAxisFormat = (tickItem) => {
    // If using moment.js
    return moment(tickItem).format('MMM Do YY')
  }

  return (
    <>
      {
        _.isEmpty(data)
          ?
          <Row>
            <Col xs={24}>
              <Empty />
            </Col>
          </Row>
          :
          <Card bordered={false} >
            <Row gutter={[16, 16]}>
              <ReportCard
                title={"Active User (Daily)"}
                number={!_.isEmpty(data) && data[data.length - 1]["dailyActiveUser"]}
                prevNumber={!_.isEmpty(data) && data.length > 1 && data[data.length - 2]["dailyActiveUser"]}
                onClick={() => {
                  setModalData({
                    title: "Active User (Daily)",
                    isOpen: true,
                    data: !_.isEmpty(emailData) ? emailData["dailyActiveUser"] : []
                  });
                }}
                size={[24, 12, 12]}
              />
              <ReportCard
                title={"New User (Daily)"}
                number={!_.isEmpty(data) && data[data.length - 1]["dailyNewUser"]}
                prevNumber={!_.isEmpty(data) && data.length > 1 && data[data.length - 2]["dailyNewUser"]}
                onClick={() => {
                  setModalData({
                    title: "New User (Daily)",
                    isOpen: true,
                    data: !_.isEmpty(emailData) ? emailData["dailyNewUser"] : []
                  });
                }}
                size={[24, 12, 12]}
              />
            </Row>
            <Row className="mt-4 mb-16" gutter={[16, 16]}>
              <Col xs={24}>
                <Card title="Total User Chart">
                  <ResponsiveContainer width={"100%"} height={400}>
                    <LineChart data={data}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="createdAt" tickFormatter={XAxisFormat} />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line type="monotone" dataKey="totalUserCount" stroke="#ffa600" />
                    </LineChart>
                  </ResponsiveContainer>
                </Card>
              </Col>
            </Row>
          </Card>
      }
    </>
  )
}

const Reports = ({ setPage }) => {

  const mclAuth = useMclAuth()
  const { auth } = useAuth()

  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState('')
  const [data, setData] = useState([])
  const [emailData, setEmailData] = useState({})

  const [dailyData, setDailyData] = useState([])
  const [dailyEmailData, setDailyEmailData] = useState({})

  const [modalData, setModalData] = useState({
    title: "title",
    isOpen: false,
    data: [],
  })

  useEffect(() => {
    setIsLoading(true)
    mclAuth.get(Url.REPORT.concat('/seasonal-reports'))
      .then(res => {
        let data = res.data;
        setData(data.reports.reverse())
        setEmailData(data.detail)
        setIsLoading(false)
      })
      .catch(error => {
        openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to get weekly report data" })
        setError(error.message)
        setIsLoading(false)
      })
    mclAuth.get(Url.REPORT.concat('/daily-reports'))
      .then(res => {
        let data = res.data;
        setDailyData(data.reports.reverse())
        setDailyEmailData(data.detail)
        setIsLoading(false)
      })
      .catch(error => {
        openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to get daily report data" })
        setError(error.message)
        setIsLoading(false)
      })
  }, [auth.id, mclAuth])

  const homeBreadCrumbOnClick = (e) => {
    e.preventDefault();
    setPage("0")
  };

  const clientBreadCrumbOnClick = (e) => {
    e.preventDefault();
  };

  const handleClose = () => {
    setModalData((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  return (
    <div>
      <Modal
        title={modalData.title}
        visible={modalData.isOpen}
        onOk={handleClose}
        onCancel={handleClose}
      >
        {
          <List
            size="small"
            dataSource={modalData.data}
            renderItem={item => <List.Item>{item.email}</List.Item>}
          />
        }
      </Modal>
      <Row>
        <Col xs={24}>
          <Breadcrumb separator=">" className="text-h6">
            <Breadcrumb.Item>
              <a href="#" onClick={homeBreadCrumbOnClick}>Home</a>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <a href="#" onClick={clientBreadCrumbOnClick}>Reports</a>
            </Breadcrumb.Item>
          </Breadcrumb>
          <p className="mt-2">Date: {!_.isEmpty(data) && moment(data[data.length - 1]["createdAt"]).format("MM/DD/YYYY")}</p>
        </Col>
      </Row>
      <Row>
        <Col xs={24}>
          <Spin spinning={isLoading} size="large">
            <Tabs
              defaultActiveKey="1"
              size="large"
            >
              <Tabs.TabPane tab="Weekly Report" key="1">
                <WeeklyReport
                  data={data}
                  emailData={emailData}
                  setModalData={setModalData}
                />
              </Tabs.TabPane>
              <Tabs.TabPane tab="Daily Report" key="2">
                <DailyReport
                  data={dailyData}
                  emailData={dailyEmailData}
                  setModalData={setModalData}
                />,
              </Tabs.TabPane>
            </Tabs>
          </Spin>
        </Col>
      </Row>
    </div >
  )
}

export default Reports