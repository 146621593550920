import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router";

import { Row, Col, Button, Tooltip, Badge, Empty, Modal } from "antd";
import {
  BellOutlined,
  CommentOutlined,
  EnvironmentOutlined,
  MailOutlined,
  PhoneOutlined,
  PlusCircleFilled,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";

import {
  Url,
  Care,
  SubscriptionToStr,
  RoleToStr,
  Roles,
  RoleToRoute,
  CareTypeToColor,
  CareTypeToStr,
} from "../../constants/Global";
import _ from "lodash";
import useMclAuth from "../../hooks/useMclAuth";
import Circle from "./Circle";
import { isLabelWithInternallyDisabledControl } from "@testing-library/user-event/dist/utils";
import { openNotification } from "../../components/Various";

const DashCircles = ({
  style = {},
  setPage,
  cg,
  showComponent,
  currentCircle,
  setCurrentCircle,
  myCircles,
  setMyCircles,
  isLoading,
}) => {
  const [cancelCircleId, setCancelCircleId] = useState("");
  const [expiration, setExpiration] = useState("");

  const mclAuth = useMclAuth();
  const createNewPlan = () => {
    alert("Feature is coming soon. Thank you for your interest.");
    console.log("******TODO: Feature coming soon.");
  };

  if (_.isNil(currentCircle)) {
    console.log("currentCircle undefined; set to 0");
    currentCircle = 0;
  }

  const findInCircles = (circleIdx) => {
    for (let i = 0; i < cg.circles.length; i++) {
      if (cg.circles[i]._id === myCircles[circleIdx]._id) {
        // console.log("+-+-+ found in circle! index=", i);
        return i;
      }
    }
    // for (let i = 0; i < myCircles.length; i++) {
    //   if (myCircles[i]._id === cg.circles[circleIdx]._id) {
    //     console.log("+++++ found in circle! index=",i);
    //     return i;
    //   }
    // }
    console.log("++++++ not found mycircle index=!", circleIdx);
    return circleIdx;
  };

  const setCurrentMyCircle = (circleIdx) => {
    setCurrentCircle(findInCircles(circleIdx));
  };

  const handleClickCancelSub = (circleId) => {
    mclAuth.get(Url.PAYMENT.concat("/subExpiration/", circleId)).then((res) => {
      if (res) {
        res.data.subExpiration === 0 && res.data.subscription !== 0
          ? setCancelCircleId(circleId)
          : openNotification({
              message: "Cancel Subscription",
              status: "info",
              title: "Free plan subscription",
              subTitle: "You are already in the free plan.",
            });
      }
    });
  };

  const handleOkCancelSub = () => {
    mclAuth
      .get(Url.PAYMENT.concat("/cancelStripeSubs/", cancelCircleId))
      .then((res) => {
        openNotification({
          message: "Cancel Subscription",
          status: "success",
          title: "Subscription cancelled",
          subTitle: res.data?.text,
        });
        //setIsModalVisible(false);
        // set expiration date
        mclAuth.get(Url.PAYMENT.concat("/subExpiration")).then((res) => {
          if (res) {
            setMyCircles((prevMyCircles) => prevMyCircles.map((circle) => {
              if (circle._id === cancelCircleId) {
                circle.subExpiration = res.data.subExpiration;
              }
              return circle;
            }))
          }
        }).finally(() => {
          setCancelCircleId("");
        });
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const handleNoCancelSub = () => {
    //setIsModalVisible(false);
    setCancelCircleId("");
  };

  // console.log("********DashCircles myCircles.length", myCircles.length);
  return (
    <div className="p-6">
      <Modal
        title="Cancel Subscription"
        visible={!_.isEmpty(cancelCircleId)}
        onOk={handleOkCancelSub}
        onCancel={handleNoCancelSub}
      >
        <p>
          Are you sure to cancel your subscription? Your subscription will stop
          at the end of this charging period.
        </p>
      </Modal>
      <Row className="flex flex-row text-[#10082d] m-0 gap-x-2 items-center">
        <h3 className="pt-1">Your Care Plans</h3>
        <Tooltip title="Create New Care Plan">
          <Button
            type="text"
            shape="round"
            icon={<PlusCircleFilled style={{ color: "#10082d" }} />}
            onClick={() => createNewPlan()}
            className="text-[0.75rem] items-center bg-[#3c3066]hover:bg-[#3c3066a0] focus:bg-[#3c306680]"
          >
            Create a New Plan
          </Button>
        </Tooltip>
      </Row>
      {myCircles.length === 0 ? (
        <Row>
          <Empty
            description={
              <span>
                Welcome! It is empty here. If you signed up through the
                invitation link, you will need to wait for the inviter to
                approve you.
              </span>
            }
          ></Empty>
        </Row>
      ) : (
        <Row>
          {myCircles.map((circle, idx) => {
            return (
              <Col key={uuidv4()} xs={24} xl={12} className="p-2">
                <Circle
                  index={idx}
                  setPage={setPage}
                  cg={cg}
                  circle={circle}
                  showComponent={showComponent}
                  currentCircle={currentCircle}
                  setCurrentCircle={setCurrentMyCircle}
                  isLoading={isLoading}
                  handleClickCancelSub={handleClickCancelSub}
                />
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
};

export default DashCircles;
