import React, { useState, useEffect } from "react";
import {
  Avatar,
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Empty,
  Space,
} from "antd";
import moment from "moment";
import _ from "lodash";

import { BellOutlined, RightOutlined } from "@ant-design/icons";
import SidePanelDivider from "./SidePanelDivider";
import { getChatName } from "./Various";

const NotificationItem = ({
  children,
  hasRead = false,
  cssStateProperties = {
    selectedBackgroundColor: "bg-new-primary-purple/5",
    selectedIconBackground:
      "linear-gradient(146.51deg, #6D56BE 12.17%, #3C3066 100.04%)",
    selectedIconColor: "#EBEBEB",
    nameTextColor: "#0C0720",
    nameFontWeight: "700",
    newMessageFontWeight: "700",
  },
  setSelected = () => {},
}) => {
  return (
    <div
      className={`
          flex
          flex-row
          items-center
          p-0
          w-full
          h-[56px]
          rounded-lg
          cursor-pointer
          hover:bg-new-primary-purple/5
          `}
      style={{
        border: "1px solid rgba(60, 48, 102, 0.1)",
      }}
      onClick={setSelected}
    >
      {hasRead && (
        <div
          className="
                  ml-3
                  shrink-0
                  w-[10px]
                  h-[10px]
                  bg-[#6DCDEE]
                  rounded-lg
                "
        ></div>
      )}
      {children}
      <span
        className="flex items-center justify-center mr-2 w-[24px] h-[24px]"
        style={{ background: "rgba(60, 48, 102, 0.1)", borderRadius: "8px" }}
      >
        <RightOutlined
          style={{
            fontSize: "12px",
          }}
        />
      </span>
    </div>
  );
};

const NotificationMessageItem = ({
  chatName,
  hasRead = false,
  onClick = () => {},
}) => {
  let cssStateProperties = {
    selectedBackgroundColor: "bg-new-primary-purple/5",
    selectedIconBackground:
      "linear-gradient(146.51deg, #6D56BE 12.17%, #3C3066 100.04%)",
    selectedIconColor: "#EBEBEB",
    nameTextColor: "#0C0720",
    nameFontWeight: "700",
    newMessageFontWeight: "700",
  };

  return (
    <NotificationItem hasRead={hasRead} onClick={onClick}>
      <div
        className="
              flex
              flex-row
              grow
              items-center
              px-2
              pr-2
              pl-3
              gap-[15px]
              w-full
          "
      >
        {/* Info */}
        <div
          className="
            flex
            grow
            shrink
            basis-auto
            min-w-[0]
            items-center
          "
        >
          <div
            className="
              flex
              grow
              flex-col
              items-start
              p-0
              basis-auto
              min-w-[0]
              gap-1
            "
          >
            <div
              className="
                  flex
                  p-0
                  mb-0
                  items-start
                  text-xs
                  w-full
              "
            >
              <span
                className="truncate text-new-primary-purple
              "
                style={{
                  fontWeight: "500",
                }}
              >
                {"New Message"}
              </span>
            </div>
            {/* sender info*/}
            <div
              className="
                  flex
                  p-0
                  gap-2
                  leading-5
                  mb-0
                  items-start
                  text-sm
                  w-full
              "
            >
              <span
                className="truncate text-text-color"
                style={{
                  fontWeight: "500",
                }}
              >
                {"from: "}
              </span>
              <span
                className="truncate text-new-primary-purple"
                style={{
                  fontWeight: "700",
                }}
              >
                {chatName}
              </span>
            </div>
          </div>
        </div>
      </div>
    </NotificationItem>
  );
};

const NotificationContainer = ({ datetime = "2023/1/4", children }) => {
  let dateFormatter = (datetime) => {
    const notificationTime = moment(datetime);
    const now = moment();
    const dayDiff = now.diff(notificationTime, "days");
    if (dayDiff == 0) {
      return `Today`;
    } else {
      return moment(notificationTime).format("MM.DD.YYYY");
    }
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-center">
        <SidePanelDivider title={dateFormatter(datetime)} />
      </div>
      {children}
    </div>
  );
};

const NotificationDrawer = ({
  cg,
  portal,
  notifications,
  openDrawer,
  onCloseDrawer,
}) => {
  const [notificationItems, setNotificationItems] = useState({});

  const createNotificationItem = (notification) => {
    let notificationUIItem = {
      ...notification,
    };

    // If is new message notification
    if (notificationUIItem.notificationType === "MESSAGE") {
      notificationUIItem.chatName = getChatName(
        notificationUIItem.chat,
        notificationUIItem.type,
        cg,
        portal
      );
    }

    return notificationUIItem;
  };
  const initializeNotificationItems = (notifications) => {
    if (_.isEmpty(notifications)) {
      return {};
    }

    let notificationsByDate = {};
    // Example:
    // {
    //   "01.04.2022": [...notificationItem],
    //   "01.05.2022": [...notificationItem],
    // }
    for (let i = 0; i < notifications.length; i++) {
      let currentNotification = notifications[i];
      if (_.isNil(currentNotification.chat)) {
        continue;
      }
      let notificationItem = createNotificationItem(currentNotification);
      let dateKey = moment(notificationItem.updatedAt).format("MM.DD.YYYY");
      if (dateKey in notificationsByDate) {
        notificationsByDate[dateKey].push(notificationItem);
      } else {
        notificationsByDate[dateKey] = [notificationItem];
      }
    }
    return notificationsByDate;
  };

  useEffect(() => {
    if (_.isArray(notifications)) {
      setNotificationItems(initializeNotificationItems(notifications));
    }
  }, [notifications]);

  return (
    <Drawer
      id="notification-drawer"
      title={
        <>
          <BellOutlined style={{ color: "black", fontSize: "24px" }} />
          <span className="pl-2 text-2xl leading-5 font-bold">
            Notifications
          </span>
        </>
      }
      placement="right"
      width="24rem"
      onClose={onCloseDrawer}
      visible={openDrawer}
      closable={false}
    >
      <Space direction="vertical" className="w-full">
        {_.isEmpty(notificationItems) ? (
          <Empty description="No notification" />
        ) : (
          Object.keys(notificationItems)
            .sort(function (b, a) {
              return moment(a).toDate() - moment(b).toDate();
            })
            .map((dateKey) => {
              return (
                <NotificationContainer key={dateKey} datetime={dateKey}>
                  {notificationItems[dateKey].map((item) => (
                    <NotificationMessageItem key={item._id} {...item} />
                  ))}
                </NotificationContainer>
              );
            })
        )}
      </Space>
    </Drawer>
  );
};

export default NotificationDrawer;
