import React, { useEffect, useState } from "react";
import { useNavigate} from "react-router";
import {
  Routes,
  Route,
  Link,
  useLocation,
  useSearchParams
} from "react-router-dom";


import {Helmet} from "react-helmet"; 

import { Col, Row, Space, Empty, Avatar, Card } from "antd";
import { CheckCircleTwoTone, CheckCircleFilled, 
        UserOutlined, VideoCameraOutlined,
        CalendarOutlined, GlobalOutlined
} from "@ant-design/icons";

import _ from "lodash";

import { Navbar } from "../../components/Navbar";
import { PurpleDivider } from "../../components/Various";
// import ArticleWidget from "../../components/ArticleWidget";
// import ArticleCategories from "../../components/ArticleCategories";
// import ArticleDetail from "../../components/ArticleDetail";
// import Author from "../../components/Author";
// import AdjacentLibraryResource from "../../components/AdjacentLibraryResource";
// import { DEMO_ARTICLES, Url } from "../../constants/Global";
import Footer from "../../components/Footer";
import { mclOpen } from "../../api/Mcl";
import {
  Url,
} from "../../constants/Global";


import Min from "../../assets/images/team/MinPomper_CEO.png";

export const CalendlyRoutes = () => {
  return (
      <Routes>
        <Route exact path="/freeconsult" element={<Calendly eventType='free' />} />
        {/* <Route exact path="/:INVITATION_ID" element={<SurveyInvited />} /> */}
        {/* <Route exact path="/:INVITATION_ID" element={<Survey />} />
        <Route exact path="/referral" element={<SurveyProvider />} />
        <Route path="/b2b/:B2B_KEY" element={<Survey />} /> */}
      </Routes>
  );
};

export const Calendly = ({eventType}) => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

// https://mycareling.com/calendly/freeconsult?assigned_to=Min%20Pomper&event_type_uuid=70eb3bc0-c47b-4931-bcb6-afe86385e3fa&event_type_name=Free%20Care%20Consultation%20with%20Min&event_start_time=2024-09-26T07%3A00%3A00%2B08%3A00&event_end_time=2024-09-26T08%3A00%3A00%2B08%3A00&invitee_uuid=68d42879-4e68-47df-87f1-7dd60d32c8ce&invitee_full_name=Technology%20Test&invitee_email=technology%40mycarelinq.com&text_reminder_number=%2B1%20661-977-6698&answer_1=test
    const assignedTo = searchParams.get('assigned_to');
    const eventTypeName = searchParams.get('event_type_name');
    const eventStartTime = searchParams.get('event_start_time');
    const eventEndTime = searchParams.get('event_end_time');
    const eventInviteeFullName = searchParams.get('invitee_full_name');
    const eventInviteeEmail = searchParams.get('invitee_email');
    const eventTextReminderNumber = searchParams.get('text_reminder_number');
    const answer1 = searchParams.get('answer_1');    
    const tmStart = new Date(eventStartTime);
    const tmEnd = new Date(eventEndTime);
    
    var optDate = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    var optTime = { hourCycle: "h12", hour: "numeric", minute: "2-digit"};

    function getTimezoneName(dt) {
      const short = dt.toLocaleDateString(undefined);
      const full = dt.toLocaleDateString(undefined, { timeZoneName: 'long' });
    
      // Trying to remove date from the string in a locale-agnostic way
      const shortIndex = full.indexOf(short);
      if (shortIndex >= 0) {
        const trimmed = full.substring(0, shortIndex) + full.substring(shortIndex + short.length);
        
        // by this time `trimmed` should be the timezone's name with some punctuation -
        // trim it from both sides
        return trimmed.replace(/^[\s,.\-:;]+|[\s,.\-:;]+$/g, '');
    
      } else {
        // in some magic case when short representation of date is not present in the long one, just return the long one as a fallback, since it should contain the timezone's name
        return full;
      }
    }
    

    const saveConfirm = async () => {
      const confirmData = {
        "assignedTo": assignedTo,
        "eventTypeName": eventTypeName,
        "eventStartTime": eventStartTime,
        "eventEndTime": eventEndTime,
        "eventInviteeFullName": eventInviteeFullName,
        "eventInviteeEmail": eventInviteeEmail,
        "eventTextReminderNumber": eventTextReminderNumber,
        "answer1": answer1,
      }

      mclOpen
        .post(Url.CONFIRM.concat("/freeconsult"), confirmData, {
          headers: { "Content-Type": "application/json" },
          // params: {
          //   token: recaptchaToken,
          // },
        })
        .then((res) => {
          console.log('freeconsult save sucessful!')
        })
        .catch((error) => {
          console.log('free consult save error!', error);
        })
    }


    useEffect(() => {
      saveConfirm();  
    }, []);
  
    return (
        <main>
            {/* <Helmet> */}
                <meta charSet="utf-8" />
                {/* <title>My CareLinq - Caregiver Resources</title> */}
                {/* <meta name="description" content="My CareLinq caregiver resources." /> */}
                {/* <link rel="canonical" href="https://mycarelinq.com/article" /> */}
            {/* </Helmet> */}
            <Navbar signin={true} navigate={navigate} />
            <PurpleDivider />
            <Row className="px-3 mt-20">
              <Col md={24} className="text-center">
                <Avatar
                  className="cursor-pointer"
                  src={Min}
                  size={80}
                  // onClick={() => {
                  //   avatarOnClick(value);
                  // }}
                />
              </Col>
            </Row>
            <Row className="px-3">
              <Col md={24} className="text-center">   
                <h2><CheckCircleTwoTone twoToneColor="#52c41a" style={{ fontSize: '1.25em'}}/>
                {/* <CheckCircleTwoTone style={{ fontSize: '1.5em', color: '#38761d' }} /> */}
                &nbsp;
                You are scheduled</h2>
              </Col>
            </Row>
            <Row className="px-3">
              <Col md={24} className="text-center">
                <h4>A calendar invitation has been sent to your email address.</h4>
              </Col>
            </Row>
            <Row className="px-3 mb-20">
              <Col md={7}/>
              <Col md={12} className="text-center">
                <Card
                  bodyStyle={{padding: "10px"}}
                  style={{
                    width: 525,
                    margin: "10px",
                    borderRadius: "10px",
                    overflow: "hidden"
                              }}
                >
                  <Row className="px-3">
                    <Col><h2>{eventTypeName}</h2></Col>
                  </Row>
                  <Row className="px-3">
                    <Col><h3>
                    <UserOutlined style={{ fontSize: '1em'}}/>
                      &nbsp;
                      {assignedTo}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="px-3">
                    <Col><h3>
                    <CalendarOutlined style={{ fontSize: '1em'}}/>
                      &nbsp;
                      {/* {tmStart.getTime()}, {tmStart.getHours()}, {tmStart.getDate()}<br/> */}
                      {tmStart.toLocaleTimeString(undefined, optTime)}
                      &nbsp;-&nbsp;
                      {tmEnd.toLocaleTimeString(undefined, optTime)}
                      ,&nbsp; 
                      {tmStart.toLocaleDateString(undefined, optDate)}<br/>
                      
                      </h3>
                    </Col>
                  </Row>
                  <Row className="px-3">
                    <Col><h3>
                    <GlobalOutlined style={{ fontSize: '1em'}}/>
                      &nbsp;
                      {getTimezoneName(tmStart)}
                      </h3>
                    </Col>
                  </Row>
                   <Row className="px-3">
                    <Col><h3>
                    <VideoCameraOutlined style={{ fontSize: '1em'}}/>
                      &nbsp;
                      Web conferencing details to follow.
                      </h3>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <Footer />
        </main>
    )
}

// https://calendly.com/mycarelinq/free-care-consultation
// +1 661-977-6698
// http://localhost:3000/calendly/freeconsult?assigned_to=Min%20Pomper&event_type_uuid=70eb3bc0-c47b-4931-bcb6-afe86385e3fa&event_type_name=Free%20Care%20Consultation%20with%20Min&event_start_time=2024-09-26T07%3A00%3A00%2B08%3A00&event_end_time=2024-09-26T08%3A00%3A00%2B08%3A00&invitee_uuid=68d42879-4e68-47df-87f1-7dd60d32c8ce&invitee_full_name=Technology%20Test&invitee_email=technology%40mycarelinq.com&text_reminder_number=%2B1%20661-977-6698&answer_1=test

