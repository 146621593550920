import React, { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { io } from "socket.io-client";
import CaregiverContext, {
  CaregiverProvider,
} from "../../context/CaregiverProvider";
import "antd/dist/antd.css";
import {
  Avatar,
  Badge,
  Button,
  Col,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Input,
  Layout,
  Menu,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
} from "antd";
import {
  AppstoreOutlined,
  AlertOutlined,
  BellOutlined,
  BookOutlined,
  CloseCircleFilled,
  CloudUploadOutlined,
  EditOutlined,
  HeartOutlined,
  IdcardOutlined,
  LogoutOutlined,
  MailOutlined,
  MenuUnfoldOutlined,
  MessageOutlined,
  PoweroffOutlined,
  ReadOutlined,
  RightCircleFilled,
  RiseOutlined,
  TeamOutlined,
  UserOutlined,
} from "@ant-design/icons";

import logo from "../../assets/images/logos/mcl-logo-color.svg";
import logoText from "../../assets/images/logos/mcl-logo-white-horizontal-text-only-new.png";

import {
  Care,
  CareType,
  CareTypeToColor,
  CareTypeToStr,
  IsAdmin,
  Roles,
  Subscription,
  SubscriptionToColor,
  SubscriptionToStr,
  Url,
} from "../../constants/Global";
import useAuth from "../../hooks/useAuth";
import useCg from "../../hooks/useCg";
import useMclAuth from "../../hooks/useMclAuth";
import "../../components/Navbar.css";
import "./MyHome.css";
// import Profile from "./Profile";
import DashCircles from "./DashCircles";
import MessagingV2 from "../../components/MessagingV2";
import CareCircleTeam from "./CareCircleTeam";

import NavHeadItemsAfterLogin from "../../components/NavHeadItemsAfterLogin";
// import ProfileRibbon from "../../components/ProfileRibbon";
import CareTeam from "./CareTeam";
import ContactMCL from "./ContactMCL";
import KnowledgeBase from "./KnowledgeBase";
import GiverCare, { initialPlan as initialGiverCare } from "./GiverCare";
import GiverPlan, { initialPlan as initialGiverPlan } from "./GiverPlan";
import AdultCare, { initialPlan as initialAdultCare } from "./AdultCare";
import autoprefixer from "autoprefixer";
import _ from "lodash";
import { RiCloseCircleFill } from "react-icons/ri";
import SocketContext from "../../context/SocketContext";
import NotificationDrawer from "../../components/NotificationDrawer";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

// meta pixel integration
import MetaPixel from "../../api/metaPixel";

const { Header, Sider, Content, Footer } = Layout;

const MyHome = () => {
  const { auth, setAuth } = useAuth();
  const mclAuth = useMclAuth();
  const navigate = useNavigate();
  const [socket, setSocket] = useState(undefined);
  const {
    pageContent,
    setPageContent,
    cg,
    setCg,
    setPanelCurrent,
    currentCircle,
    setCurrentCircle,
    setPlanSelf,
    setPlanRcpt,
    setPanelRcptCurrent,
  } = useCg();

  const NONE_SECONDARY = "none";

  // myCircles: Array of [array of the user's circles]
  // [{user circle 0}, {user circle 1}, {user circle 2}, ...]
  const [myCircles, setMyCircles] = useState([]);
  const [secondary, setSecondary] = useState({});
  // members: Object of [array of invited members in each of the user's circle]
  // {[{cm00}, {cm01}, ...], [{cm10}, {cm11}, ...], [{cm20}, {cm21}, ...], ...}
  //  <-  user circle 0  ->  <-  user circle 1  ->  <-  user circle 2  ->
  const [members, setMembers] = useState({});
  const [pendings, setPendings] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  // const [dashVisible, setDashVisible] = useState(true);
  const [collapsed, setCollapsed] = useState(true);
  const [isAlert, setIsAlert] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openProfileEditor, setOpenProfileEditor] = useState(false);
  const [openNotificationDrawer, setOpenNotificationDrawer] = useState(false);
  const [currentComponent, setCurrentComponent] = useState(undefined);
  const showNotificationDrawer = () => {
    setOpenNotificationDrawer(true);
  };
  const onCloseNotificationDrawer = () => {
    setOpenNotificationDrawer(false);
  };

  window.onpopstate = () => {
    navigate("/caregiver/dashboard");
  };

  // const isPrimary = (circle) => {
  //   console.log("*~*~*~*isPrimary circle:\n", circle);
  //   if (!circle.primary) {
  //     return false;
  //   }
  //   for (let i = 0; i < circle.primary.length; i++) {
  //     if (circle.primary[i]._id === auth?.id) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  // const isMember = (cg) => {
  //   return cg.circles[currentCircle].members.includes(auth?.id);
  // };

  const retrieveCurrentCircle = () => {
    return currentCircle;
  };

  // const getPrimaryCircles = (cg) => {
  //   const primaryCircles = new Array();
  //   if (isLoading || _.isEmpty(cg)) {
  //     console.log(
  //       "******getPrimaryCircles(cg) - cg is empty or circles are still being loaded!!!!!!"
  //     );
  //     return primaryCircles;
  //   }
  //   myCircles.map((circle, idx) => {
  //     console.log(
  //       "***getPrimaryCircles current idx=" + idx + " circle._id: " + circle._id
  //     );
  //     if (IsAdmin(cg._id, circle)) {
  //       primaryCircles.push(circle);
  //     }
  //   });
  //   console.log("***getPrimaryCircles primaryCircles", primaryCircles);
  //   return primaryCircles;
  // };
  const INVALID_SUBSCRIPTION = 9999;
  const getSubscription = (cg) => {
    if (isLoading || _.isEmpty(cg) || myCircles.length < 1) {
      console.log(
        "*+*+*getSubscription(cg) - cg is empty or circles are still being retrieved"
      );
      return INVALID_SUBSCRIPTION;
    }
    // console.log("+=+=+getSubscription cg:\n", cg);
    for (let i = 0; i < myCircles.length; i++) {
      for (let j = 0; j < myCircles[i].primary.length; j++) {
        // console.log("+=+=+getSubscription myCircles[i]:\n", myCircles[i]);
        if (myCircles[i].primary[j]._id === cg._id) {
          return myCircles[i].subscription;
        }
      }
    }
    return INVALID_SUBSCRIPTION;
  };
  // const getPrimaryCareType = (cg) => {
  //   if (_.isEmpty(cg)) {
  //     console.log("******getPrimaryCareType(cg) - cg is empty!!!!!!");
  //     return null;
  //   }
  //   const primaryCircles = getPrimaryCircles(cg);
  //   // console.log("***getPrimaryCareType primaryCircles:", primaryCircles);
  //   // return only the care type for the first primary circle
  //   // even if the caregiver is the primary for multiple circles
  //   if (primaryCircles.length > 0) {
  //     return primaryCircles[0].care;
  //   }
  //   return null;
  // };

  const doPlanSelf = (cg) => {
    const initialPlan =
      cg.care === Care.Care.INCARE ? initialGiverCare : initialAdultCare;
    // console.log('planSelf:', cg.planSelf)
    const currentPlan = !cg?.planSelf ? initialPlan : JSON.parse(cg.planSelf);
    setPlanSelf(currentPlan);
    setPanelCurrent(currentPlan.current);
  };

  const doPlanRcpt = (cg) => {
    // console.log('planSelf:', cg.planRcpt)
    const currentPlan = !cg?.circles[currentCircle]?.planRcpt
      ? initialGiverPlan
      : JSON.parse(cg?.circles[currentCircle]?.planRcpt);
    setPlanRcpt(currentPlan);
    setPanelRcptCurrent(currentPlan.current);
  };

  const initializeSocket = (token) => {
    const socket = io(process.env.REACT_APP_MCL_STATIC, {
      auth: {
        token: token,
      },
    });
    setSocket(socket);
  };

  useEffect(() => {
    // console.log("load caregiver plans");
    setIsLoading(true);
    mclAuth
      .get(Url.CAREGIVERS.concat("/", auth?.id))
      .then((res) => {
        setCg(res.data);
        doPlanSelf(res.data);
        doPlanRcpt(res.data);
        //setCurrentCircle(0);
        setIsLoading(false);
        // console.log("response", res.data);
      })
      .catch((error) => {
        setError(error.message);
        setIsLoading(false);
      });
  }, [
    /*auth.id, mclAuth*/
    currentCircle,
  ]);

  useEffect(() => {
    console.log("set current circle to 0");
    setCurrentCircle(0);
    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (!_.isEmpty(cg)) {
      cg.circles.map((circle, idx) => {
        // console.log(">>>>>>MyHome idx:", idx);
        // console.log(">>>>>>MyHome circle:", circle);
        setIsLoading(true);
        mclAuth
          .get(Url.CIRCLES.concat("/", circle._id, "/careteam"))
          .then((res) => {
            // console.log(res.data);
            let circleIsNew = true;
            res.data.createdAt = circle.createdAt;
            for (let i = 0; i < myCircles.length; i++) {
              if (res.data._id === myCircles[i]._id) {
                circleIsNew = false;
                break;
              }
            }
            if (circleIsNew) {
              setMyCircles((oldCircles) => [...oldCircles, res.data]);
              console.log(
                ">>>>>>MyHome circle._id=" + circle._id + " idx=" + idx
              );
            }
            setIsLoading(false);
            // console.log('MyHome Circles response", res.data);
          })
          .catch((error) => {
            setError(error.message);
            setIsLoading(false);
            console.log("******MyHome care circles load error:", error.message);
          });
      });
    }

    // const fetchNotification = async () => {
    //   mclAuth
    //     .get(Url.NOTIFICATIONS.concat("/caregiver"))
    //     .then((res) => {
    //       setNotifications([
    //         ...res.data?.msg.map((item) => {
    //           return { ...item, notificationType: "MESSAGE" };
    //         }),
    //       ]);
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // };
    // fetchNotification();
  }, [cg]);

  // useEffect(() => {
  //   console.log(">>>>>>MyHome myCircles:\n", myCircles);
  // }, [myCircles]);

  useEffect(() => {
    if (auth?.accessToken) {
      console.log("initializeSocket");
      initializeSocket(auth?.accessToken);
    }
  }, [auth]);

  const planCircle = (currentCircle) => {
    // console.log(
    //   "***" +
    //     planCircle.name +
    //     " cg._id: " +
    //     cg._id +
    //     " currentCircle: " +
    //     currentCircle
    // );
    return myCircles[currentCircle] || null;
  };

  const showComponent = (key) => {
    setCurrentComponent(key);
    // setOpenProfileDrawer(false);
    // setDashVisible(false);
    // console.log("***showComponent key=" + key);
    setPageContent(key);
  };

  const components = {
    // dashboard: {
    //   label: "Dashboard",
    //   icon: <AppstoreOutlined />,
    //   element: (
    //     <DashCircles
    //       setPage={setPageContent}
    //       cg={cg}
    //       showComponent={showComponent}
    //       setCurrentCircle={setCurrentCircle}
    //     />
    //   ),
    // },
    // plan: {
    //   label: "Care Plan",
    //   icon: <HeartOutlined />,
    //   element: (
    //     <GiverPlan
    //       setPage={setPageContent}
    //       cg={cg}
    //       circle={planCircle(cg, currentCircle)}
    //     />
    //   ),
    // },
    plan: {
      label: "Care Plan",
      hide: false,
      icon: <HeartOutlined />,
      element: (
        <DashCircles
          setPage={setPageContent}
          cg={cg}
          showComponent={showComponent}
          currentCircle={currentCircle}
          setCurrentCircle={setCurrentCircle}
          myCircles={myCircles}
          isLoading={isLoading}
          setMyCircles={setMyCircles}
        />
      ),
    },
    team: {
      hide: myCircles.length < 1,
      label: "Care Circle Team",
      icon: <TeamOutlined />,
      element: (
        <CareCircleTeam
          cg={cg}
          currentCircle={currentCircle}
          myCircles={myCircles}
          showComponent={showComponent}
        />
      ),
    },
    chat: {
      hide: myCircles.length < 1,
      label: "Chat with Team",
      icon: <MessageOutlined />,
      element: <MessagingV2 cg={cg} portal={Roles.CAREGIVER} />,
    },
    kb: {
      hide: myCircles.length < 1,
      label: "Resource Library",
      icon: <ReadOutlined />,
      element: (
        <KnowledgeBase
          cg={cg}
          circleId={
            myCircles.length > currentCircle ? myCircles[currentCircle]._id : ""
          }
          circleName={
            myCircles.length > currentCircle
              ? [
                  myCircles[currentCircle].firstName,
                  myCircles[currentCircle].lastName,
                ]
              : ["", ""]
          }
        />
      ),
    },
    // chat: {
    //   label: "Chat",
    //   menu: "side",
    //   icon: <MessageOutlined />,
    //   hide: true,
    //   element: (
    //     <Messaging
    //       cg={cg}
    //       currentCircle={currentCircle}
    //       msgStatus={msgStatus}
    //       setMsgStatus={setMsgStatus}
    //       hasCcMsg={hasCcMsg}
    //       setHasCcMsg={setHasCcMsg}
    //       hasCircleMsg={hasCircleMsg}
    //       setHasCircleMsg={setHasCircleMsg}
    //       hasSupportMsg={hasSupportMsg}
    //       setHasSupportMsg={setHasSupportMsg}
    //     />
    //   ),
    // },
    journal: _.isEmpty(cg) ? (
      <Skeleton active />
    ) : cg.care === Care.Care.INCARE ? (
      {
        hide: false,
        label: "My Private Journal",
        icon: <BookOutlined />,
        element: <GiverCare setPage={setPageContent} />,
      }
    ) : cg.care === Care.Care.SELFCARE ? (
      {
        hide: false,
        label: "My Private Journal",
        icon: <BookOutlined />,
        element: <AdultCare setPage={setPageContent} />,
      }
    ) : (
      {
        hide: true,
        label: "Default",
        icon: <BookOutlined />,
        element: <Skeleton active />,
      }
    ),
    alert: {
      hide: true,
      label: "Alerts",
      icon: <BellOutlined />,
      element: <Spin />,
    },
    contact: {
      hide: true,
      label: "Contact Us",
      icon: <MailOutlined />,
      element: <ContactMCL setPage={() => setPageContent("plan")} />,
    },
    planDetails: {
      hide: true,
      label: "Care Plan Details",
      icon: <HeartOutlined />,
      element: _.isEmpty(cg) ? (
        <Skeleton active />
      ) : (
        <GiverPlan
          setPage={setPageContent}
          circle={
            planCircle(currentCircle)
            /* cg.circles[currentCircle] */
          } /*care={cg.circles[currentCircle].care} plan={cg.circles[currentCircle].subscription}*/
        />
      ),
    },
    default: {
      hide: true,
      menu: "none",
      label: "Default",
      element: <Skeleton active />,
    },
  };

  const componentsKeys = Object.keys(components);
  const siderItemsKeys = new Array();
  componentsKeys.map((key) => {
    // console.log("***componentsKey:\n", componentsKeys);
    if (!components[key]?.hide) {
      // console.log("***siderItemsKeys keeps key=" + key);
      siderItemsKeys.push(key);
    }
  });
  // console.log("***siderItemsKeys:\n", siderItemsKeys);

  const siderItems = siderItemsKeys.map((key, index) => {
    // console.log("***siderItems key=" + key + " index=" + index);
    return {
      // index: index.toString(),
      key: key,
      icon: components[key].icon,
      label: components[key].label,
    };
  });

  const showContent = (key) => {
    //return (pageSelect === 0) ? <Dash setPage={setPageContent} /> : contentDesc[pageSelect];
    // console.log(menuItems[pageSelect]['element']);
    // console.log(
    //   "***" +
    //     showContent.name +
    //     " key is null? " +
    //     (key == null) +
    //     " key/pageContent: " +
    //     key +
    //     "/" +
    //     pageContent
    // );
    // return components[key === 0 ? "default" : key].element;
    if (key === 0) {
      // setPageContent("dashboard");
      key = "plan";
    }
    return components[
      componentsKeys.indexOf(key) < 0 ? "default" : key
      // key > siderItemsKeys.length ? "default" : siderItemsKeys[key]
    ].element;
  };

  const onClickSiderItem = ({ item, key, keyPath, domEvent }) => {
    // console.log(
    //   "***" +
    //     onClickSiderItem.name +
    //     " click key: " +
    //     key +
    //     " kpath: " +
    //     keyPath
    // );
    // setPageContent(siderItemsKeys[key]);
    showComponent(key);
  };

  const contactMcl = () => {
    //setCurrentCircle(index)
    setPageContent("contact");
  };

  // const onChangeBirthYearMonth = (date, dateStr) => {
  //   console.log(
  //     "TODO: ***" +
  //       onChangeBirthYearMonth.name +
  //       "  birth year and month is " +
  //       date +
  //       " dateStr is " +
  //       dateStr
  //   );
  // };

  if (isLoading || _.isEmpty(cg)) {
    console.log("MyHome isLoading", isLoading);
  }
  return (
    <SocketContext.Provider value={socket}>
      <Layout
        className="mydash-layout"
        style={{ minHeight: "100vh", minHeight: "100dvh" }}
      >
        <MetaPixel />
        <Header className="mydash-acct-header nav-color py-0 px-2 flex flex-row">
          {isLoading || _.isEmpty(cg) ? (
            <Spin />
          ) : (
            <NavHeadItemsAfterLogin
              cg={cg}
              setCg={setCg}
              isLoading={isLoading}
              role={Roles.CAREGIVER}
              subscription={getSubscription(cg)}
              showComponent={showComponent}
              setPageContent={setPageContent}
              setCurrentCircle={setCurrentCircle}
              myCircles={myCircles}
            />
          )}
        </Header>
        {/* minHeight: "calc(100vh - 66px)" */}
        <Layout
          style={{
            minHeight: "calc(100vh - 66px)",
            minHeight: "calc(100dvh - 66px)",
          }}
        >
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            // onCollapse={(value) => setCollapsed(value)}
            trigger={
              collapsed ? <MenuUnfoldOutlined /> : <MenuUnfoldOutlined />
            }
            id="client-dash-sider"
          >
            <Menu
              theme="light"
              mode="inline"
              onClick={onClickSiderItem}
              multiple={false}
              defaultSelectedKeys={["plan"]}
              // selectedKeys={[pageContent.toString()]}
              selectedKeys={siderItemsKeys.indexOf(pageContent).toString()}
              items={siderItems}
              style={{
                background: "white",
                height: "100%",
                borderRight: 0,
              }}
            />
          </Sider>
          <Layout>
            <Content
              className="site-layout-background"
              style={{
                /*margin: '24px 16px',*/
                // padding: 24,
                minHeight: 400,
                marginTop: 0,
                marginBottom: currentComponent !== "chat" ? "2rem" : 0,
                // overflow: "auto",
                /*height: '100dvh'*/
              }}
            >
              {isLoading || _.isEmpty(cg) ? (
                <Skeleton active />
              ) : (
                showContent(pageContent)
              )}
            </Content>
            {currentComponent !== "chat" && (
              <Footer className="block fixed w-full bottom-0 text-[0.75rem] text-center font-thin items-center content-center text-primary-purple h-[2rem] p-1.5">
                Copyright &copy; 2021, 2023{" "}
                <a href="https://mycarelinq.com">
                  <strong>My CareLinq, Inc.</strong>
                </a>{" "}
                All Rights Reserved.
              </Footer>
            )}
          </Layout>
        </Layout>
      </Layout>
      <NotificationDrawer
        cg={cg}
        portal={Roles.CAREGIVER}
        notifications={notifications}
        openDrawer={openNotificationDrawer}
        onCloseDrawer={onCloseNotificationDrawer}
      />
    </SocketContext.Provider>
  );
};
export default MyHome;

export const Caregiver = () => {
  return (
    <CaregiverProvider>
      <Routes>
        <Route exact path="/" element={<MyHome />} />
      </Routes>
    </CaregiverProvider>
  );
};
