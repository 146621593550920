import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router";

import useMclAuth from "../../hooks/useMclAuth";
import { mclOpen } from "../../api/Mcl";

import { Navbar } from "../../components/Navbar";
import Footer from "../../components/Footer";
import { PurpleDivider } from "../../components/Various";
import { Col, Row } from "antd";
import { Url } from "../../constants/Global";

function Verify() {
  const [message, setMessage] = useState("Validating...");
  const navigate = useNavigate();
  const { VALIDATION_ID } = useParams();

  const mclAuth = useMclAuth();

  useEffect(() => {
    mclOpen
      .get(Url.CAREGIVERS.concat("/validation/" + VALIDATION_ID))
      .then((res) => {
        if (res.data) {
          setMessage(res.data);
        }
      })
      .catch((error) => {
        console.log(error.message);
      });
  }, []);

  return (
    <main>
      <Navbar signin={true} navigate={navigate} />
      <section className="bg-[url('assets/images/about-us/header-bg.png')] min-h-[2rem] max-h-[12rem] bg-center bg-no-repeat bg-cover overflow-hidden flex">
        <div className="lg:container lg:mx-auto px-4 flex flex-col justify-center whitespace-nowrap">
          <Row>
            <Col xs={24}>
              <h1
                className="text-[4rem] text-white"
                style={{ textShadow: "3px 3px 7px rgb(0 0 0 / 65%)" }}
              >
                Email Validation
              </h1>
            </Col>
          </Row>
        </div>
      </section>
      <PurpleDivider />
      <div className="xs:container lg:mx-auto px-4 flex flex-col justify-center">
        <Row className="mx-5 my-12">
          <Col xs={24}>
            <p className="m-0 md:px-24 text-center text-2xl leading-normal">
              {message}
            </p>
          </Col>
        </Row>
      </div>
      <Footer />
    </main>
  );
}

export default Verify;
