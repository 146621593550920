import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router";
import { Col, Row, notification, Result, Form, Input, Button, Spin } from "antd";
import { Url, FormDestination } from "../../constants/Global";
import _ from "lodash";
import { mclAuth, mclOpen } from "../../api/Mcl";
import useCg from "../../hooks/useCg";
import {
    MailTwoTone,
    MessageTwoTone,
    RightCircleTwoTone,
} from "@ant-design/icons";
import { Collapse, Layout } from "antd";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const openNotification = ({ message, status, title, subTitle, onClose = () => { } }) => {
    // console.log(message, title, status, subTitle)
    notification.open({
        message: message,
        description: <Result status={status} title={title} subTitle={subTitle} />,
        placement: "top",
        duration: 4,
        onClose: onClose
    });
};


const ContactMCL = ({ setPage }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const { cg } = useCg();
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }

        const token = await executeRecaptcha("ContactMyCareLinq");
        // Send this token to the server in the following request
        return token;
    }, [executeRecaptcha]);

    const [isSubmitting, setSubmitting] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const submitForm = useCallback(async (formValues) => {
        let data = formValues;
        data["firstName"] = cg["firstName"];
        data["lastName"] = cg["lastName"];
        data["email"] = cg["email"];
        data["phone"] = undefined;
        data["destination"] = "questions";
        data["fromQuestion"] = "ContactMyCareLinq";
        setSubmitting(true);
        let recaptchaToken = await handleReCaptchaVerify();
        if (!_.isEmpty(recaptchaToken)) {
            // Get the recaptcha token, submit it with the data to the backend
            mclAuth
                .post(Url.FORMS.concat("/", "givercare"), data, {
                    headers: { "Content-Type": "application/json" },
                    params: {
                        token: recaptchaToken,
                    },
                })
                .then((res) => {
                    if (res.status && res.status === 200) {
                        openNotification({
                            message: "Inquiry submitted",
                            status: "success",
                            title: "Thank you " + data.firstName + "!",
                            subTitle: "We will respond as soon as we can.",
                            onClose: () => {
                                setPage();
                            }
                        });
                        setHasSubmitted(true);
                    } else {
                        openNotification({
                            message: "Sorry",
                            status: res.status ? res.status : "500",
                            title: res.response.data,
                        });
                    }
                    setSubmitting(false);
                })
                .catch((error) => {
                    openNotification({
                        message: "Sorry",
                        status: error.status ? error.status : "500",
                        title: error.response.data
                    });
                    setSubmitting(false);
                })
                .finally(() => {
                    setSubmitting(false);
                });
        } else {
            openNotification({
                message: "Sorry",
                status: "500",
                title: "Sorry, please try again later",
                subTitle: "Failed to get Recaptcha token",
            });
            setSubmitting(false);
        }
    }, [cg]);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        submitForm(values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <div className="p-4">
            <h1
                style={{
                    fontSize: "x-large",
                    fontWeight: "bold",
                    color: "#30225f",
                }}
            >
                Contact MyCareLinq
            </h1>
            <h1
                style={{
                    fontSize: "regular",
                    fontWeight: "normal",
                    color: "#30225f",
                }}
            >
                Send us a message or give us a call at (408) 214-2416
            </h1>

            <Spin spinning={isSubmitting}>
                <Row className="mt-10">
                    <Col xs={24} lg={16} xl={12}>
                        <Form
                            className="faqForm"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            layout="vertical"
                            form={form}
                        >
                            <Form.Item
                                label={<label className="text-primary-purple" style={{ fontSize: "1rem" }}>
                                    Message <br />
                                </label>}
                                required
                                rules={[{
                                    max: 300,
                                    message: 'Message length cannot over 300 characters.'
                                },
                                {
                                    required: true,
                                    message: 'Please input your feedback.'
                                }
                                ]}
                                name="message"
                                className="mb-3"
                            >
                                <Input.TextArea rows={5} showCount maxLength={300} />
                            </Form.Item>
                            <Button type="primary" htmlType="submit" className="footer-msg-button" loading={isSubmitting} disabled={isSubmitting || hasSubmitted}>
                                Send
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </Spin>
        </div>
    );
};

export default ContactMCL