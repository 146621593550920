
import React, { useState, useEffect, useCallback } from 'react'
import { Row, Col, Card, Button, Table, Tag, Breadcrumb, notification, Result, Modal, message, Radio, Space } from 'antd';
import _ from "lodash";
import useMclAuth from '../../hooks/useMclAuth';
import useAuth from '../../hooks/useAuth';
import { EditOutlined } from '@ant-design/icons';
import { Url, SubscriptionToColor, SubscriptionToStr, Roles, Subscription } from '../../constants/Global';
import { resolveOnChange } from 'antd/lib/input/Input';
import { AssignModal } from "../../components/SConciergeComponents"

const openNotification = ({ message, status, title, subTitle }) => {
    // console.log(message, title, status, subTitle)
    notification.open({
        message: message,
        description:
            <Result
                status={status}
                title={title}
                subTitle={subTitle}
            /* extra={<Button type="primary">Back Home</Button>}*/
            />
    });
};

const ClientsTable = ({ data, setSelected }) => {
    const renderTier = (subscription) => {
        return (
            <Tag color={SubscriptionToColor(subscription)}>
                {SubscriptionToStr(subscription).toUpperCase()}
            </Tag>
        )
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelected(selectedRowKeys);
        },
        getCheckboxProps: (record) => ({
        }),
    };

    const columns = [
        {
            title: 'Type',
            key: 'subscription',
            dataIndex: 'subscription',
            render: (subscription) => (
                <>
                    {renderTier(subscription)}
                </>
            ),
            filters:
                Object.entries(Subscription).map((item) => {
                    return ({
                        text: SubscriptionToStr(item[1]),
                        value: item[1],
                    })
                })
            ,
            onFilter: (value, record) => record.subscription === value,
        },
        {
            title: "Primary Caregiver",
            key: "primaryCaregiver",
            render: (text, record) => {
              let name = "Name Error";
              if (record.primaryCare?.length > 0) {
                name = `${record.primaryCare[0].firstName} ${record.primaryCare[0].lastName}`;
              }
              return (name
              );
            },
          },
          {
            title: "Care Recipient",
            dataIndex: "name",
            key: "name",
            render: (text, { id }, index) => text,
          },
          {
            title: "Email",
            key: "email",
            render: (text, record) => {
                let email = "Email Error";
                if (record.primaryCare?.length > 0) {
                    email = `${record.primaryCare[0].email}`;
                }
                return (email);
              },
          },
    ];
    return (
        <Table
            rowSelection={{
                type: "checkbox",
                ...rowSelection,
            }}
            columns={columns}
            dataSource={data}
        />
    )
}


const ClientLeads = ({ setPage }) => {

    const mclAuth = useMclAuth()
    const { auth } = useAuth()

    const [cg, setCg] = useState([])
    const [clients, setClients] = useState([])
    const [ccs, setCCs] = useState([])
    const [selectedClients, setSelectedClients] = useState([])
    const [assignedCCId, setAssignedCCId] = useState(null);
    const [isLoading, setIsLoading] = useState(true)
    const [error, setError] = useState('')

    const [isModalVisible, setIsModalVisible] = useState(false);

    const radioOnChange = (e) => {
        setAssignedCCId(e.target.value);
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = useCallback(() => {
        if (_.isEmpty(assignedCCId) || _.isEmpty(selectedClients)) {
            openNotification({ message: "Data not valid", status: "400", title: "400", subTitle: "Not selected concierge or clients yet" })
            return;
        }
        let data = {}
        data.assignTo = assignedCCId;
        // data.clientIds = selectedClients;
        data.circleIds = selectedClients;
        mclAuth.post(Url.SUPER_CONCIERGES.concat("/", auth?.id, "/assignCC"), data)
            .then(res => {
                // Update new client data
                if (res.status && res.status === 200) {
                    openNotification({
                        message: "Update Succeed",
                        status: "success",
                        title: "Update Succeed",
                        subTitle: "Successfully assign clients to care concierge"
                    })
                }
                if (_.isArray(res.data)) {
                    const clientData = [...res.data.map((client) => {
                        return {
                            key: client._id,
                            id: client._id,
                            name: client.firstName + " " + client.lastName,
                            firstName: client.firstName,
                            lastName: client.lastName,
                            primaryCare: client.primary ? client.primary : [],
                            subscription: _.isNil(client.subscription) ? 0 : client.subscription,
                        }
                    })];
                    setClients(clientData);
                }
                setIsLoading(false)
            })
            .catch(error => {
                openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to update cc" })
                setError(error.message)
                setIsLoading(false)
            })

        setIsModalVisible(false);
    }, [assignedCCId, selectedClients]);

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        // Get clients
        mclAuth.get(Url.SUPER_CONCIERGES.concat('/', auth?.id))
            .then(res => {
                if (_.isArray(res.data)) {
                    const clientData = [...res.data.map((client) => {
                        return {
                            key: client._id,
                            id: client._id,
                            name: client.firstName + " " + client.lastName,
                            firstName: client.firstName,
                            lastName: client.lastName,
                            primaryCare: client.primary ? client.primary : [],
                            subscription: _.isNil(client.subscription) ? 0 : client.subscription,
                        }
                    })];
                    setClients(clientData);
                }
                setIsLoading(false)
            })
            .catch(error => {
                openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to get care concierge clients" })
                setError(error.message)
                setIsLoading(false)
            })
        // Get cc
        mclAuth.get(Url.SUPER_CONCIERGES.concat('/', auth?.id, '/concierges'))
            .then(res => {
                setCg(res.data)
                setIsLoading(false)
            })
            .catch(error => {
                openNotification({ message: error.message, status: "500", title: "500", subTitle: "Failed to get care concierge data" })
                setError(error.message)
                setIsLoading(false)
            })
    }, [auth.id, mclAuth])

    useEffect(() => {
        if (cg) {
            // Include super cc it self
            if (_.includes(cg.roles, Roles.CONCIERGE)) {
                let selfObject = _.pick(cg, ["_id", "firstName", "lastName", "roles"])
                setCCs([...cg.concierges, selfObject]);
            }
        }
    }, [cg])

    const homeBreadCrumbOnClick = (e) => {
        e.preventDefault();
        setPage("0")
    };

    const clientBreadCrumbOnClick = (e) => {
        e.preventDefault();
    };

    return (
        <div>
            <AssignModal
                titleText={"Assign"}
                isModalVisible={isModalVisible}
                handleOk={handleOk}
                handleCancel={handleCancel}
                clientNumber={_.isArray(selectedClients) ? selectedClients.length : 0}
                cc={ccs}
                radioOnChange={radioOnChange}
                value={assignedCCId}
            />
            <Row>
                <Col xs={24}>
                    <Breadcrumb separator=">" className="text-h6">
                        <Breadcrumb.Item>
                            <a href="#" onClick={homeBreadCrumbOnClick}>Home</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            <a href="#" onClick={clientBreadCrumbOnClick}>Client Leads</a>
                        </Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            {
                <Card
                    className="mt-4"
                    extra={
                        <Button
                            type="primary"
                            icon={<EditOutlined />}
                            onClick={() => {
                                if (_.isArray(selectedClients) && selectedClients.length > 0) {
                                    showModal();
                                } else {
                                    message.warning('Please select a client', 5);
                                }
                            }}
                        >
                            Assign Client
                        </Button>
                    }
                >
                    <ClientsTable
                        data={clients}
                        setSelected={setSelectedClients}
                    />
                </Card>
            }
        </div>
    )
}

export default ClientLeads;