import React, { useEffect } from "react";
import { useNavigate } from "react-router";

function CancelPayment() {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.removeItem("accessToken");

    setTimeout(function () {
      //window.location.replace("/pricing");
      navigate("/pricing");
    }, 3000);
  });

  return (
    <main>
      <p className="w-fill p-8 text-center text-lg">
        Order canceled -- return to Pricing page.
      </p>
    </main>
  );
}

export default CancelPayment;
