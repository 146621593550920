import React from "react";
import moment from "moment";
import { Divider } from "antd";

const MessageBoxDivider = ({ datetime }) => {
  let dateFormatter = (datetime) => {
    const messageSentTime = moment(datetime);
    const now = moment();
    const dayDiff = now.diff(messageSentTime, "days");
    if (dayDiff == 0) {
      return `Today, ${moment(messageSentTime).format("MM.DD.YYYY")}`;
    } else {
      return moment(messageSentTime).format("MM.DD.YYYY");
    }
  };

  return (
    <Divider
      plain
      className="
        grow-0
        shrink
        min-w-[0px]
        w-auto
        basis-[373px]
        text-center
        items-start
        m-0
        p-2.5
      "
      style={{
        fontSize: "12px",
        lineHeight: "20px",
        borderTopColor: "#C8CFDA",
        color: "rgba(0, 0, 0, 0.6)",
      }}
    >
      {dateFormatter(datetime)}
    </Divider>
  );
};

export default MessageBoxDivider;