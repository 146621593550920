import React from "react";
import moment, { duration } from "moment";
import { Col, Row, Space, Typography, Divider, Image } from "antd";
import { Link } from "react-router-dom";
import { ClockCircleOutlined } from "@ant-design/icons";
import {
  AUTHOR_IMAGE_PLACEHOLDER,
  VIDEO_ROUTE
} from "../constants/Global";
import { getDefaultLibraryImage, getLibraryImage } from "./Various";

import _ from "lodash";
import { faBox } from "@fortawesome/free-solid-svg-icons";

const VideoCard = ({ video, navigate }) => {

  let defaultImageLink = getDefaultLibraryImage(video.categories);

  return (
    <div
      className="bg-white shadow-lg shadow-black/40 rounded-lg p-1 md:p-3 lg:p-6 pb-12 mb-8 h-full cursor-pointer transition duration-700 "
      onClick={() => {
        if (!_.isEmpty(video.url)) {
          navigate(`${VIDEO_ROUTE}${video.url}`);
        }
      }}>
      <div className="relative w-full overflow-hidden shadow-md mb-6">
        <img
          src={_.isEmpty(video.featuredImage) ? defaultImageLink : getLibraryImage(video.featuredImage, "video")}
          alt=""
          className="object-top w-full object-cover shadow-lg rounded-t-lg lg:rounded-lg"
        />
      </div>
      {/* Text */}
      <div
        className="p-2"
      >
        <div
          className="block mb-4"
        >
          <h1
            className="text-primary-purple max-h-[4.4rem] text-xl font-semibold text-center"
            style={{
              display: "-webkit-box",
              overflow: "hidden",
              WebkitLineClamp: "2",
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              whiteSpace: "normal",
            }}
          >
            {video.title}
          </h1>
        </div>
        <div className="block xl:flex text-center items-center justify-center mb-8 w-full">
          <div className="flex items-center justify-center mb-4 xl:mb-0 w-full xl:w-auto mr-8">
            <Image
              alt={""}
              height={30}
              width={30}
              className="align-middle rounded-full"
              src={(_.isNil(video.author) || _.isEmpty(video.author.photo)) ? AUTHOR_IMAGE_PLACEHOLDER : video.author.photo}
            />
            <p className="inline align-middle text-gray-700 ml-2 font-medium text-lg mt-0 mb-0">
              {video.author?.name}
            </p>
          </div>
          {
            !_.isNil(video.duration) &&
            <div className="flex items-center justify-center font-medium text-gray-700">
              <ClockCircleOutlined className="text-[30px] text-primary-purple mr-2" />
              <span className="align-middle inline-flex items-center">
                {video.duration}
              </span>
            </div>
          }
        </div>
      </div>
    </div >
  )
};

export default VideoCard;