const GAText = () => {
  return (
    <div className="text-slate-400">
      This site is protected by reCAPTCHA and the Google
      <a className="text-blue-400" href="https://policies.google.com/privacy"> Privacy Policy</a> and
      <a className="text-blue-400" href="https://policies.google.com/terms"> Terms of Service</a> apply.
    </div>
  );
};

export default GAText;
