import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { notification, Result } from "antd";
import _ from "lodash";
import {
  ARTICLE_IMAGE_PLACEHOLDER,
  Url,
  LIBRARY_IMAGES_FOLDER,
  MESSAGE_CHAT_TYPE,
  Roles,
} from "../constants/Global";

export function Text({ className, style, children }) {
  return (
    <span className={className} style={style}>
      {children}
    </span>
  );
}

export function Image({ className, style, src, alt }) {
  return <img className={className} style={style} src={src} alt={alt} />;
}

export const Button = ({ className, style, children, onClick }) => {
  return (
    <button onClick={onClick} className={className} style={style}>
      {children}
    </button>
  );
};

export const PurpleDivider = () => {
  return <div className="w-full h-2 bg-navy" />;
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: "auto", // <-- The scrolling happens in a single jump
    });
  }, [pathname]);

  return null;
};

export const openNotification = ({
  message,
  status,
  title,
  subTitle = "",
  duration = 3,
  onClose = () => {},
}) => {
  // console.log(message, title, status, subTitle)
  notification.open({
    message: message,
    description: <Result status={status} title={title} subTitle={subTitle} />,
    placement: "top",
    duration: duration,
    onClose: onClose,
  });
};

export const getDefaultLibraryImage = (categories) => {
  return _.isArray(categories) && categories.length > 0
    ? `${Url.STATIC_IMAGES}/default/${categories[0].urlPath}.jpg`
    : ARTICLE_IMAGE_PLACEHOLDER;
};

export const getLibraryImage = (imageLink, type) => {
  return _.isString(imageLink)
    ? `${Url.STATIC_IMAGES}/${LIBRARY_IMAGES_FOLDER[type]}/${imageLink}`
    : "";
};

export const getChatName = (chat, chatType, currentUser, portal) => {
  let result = "";
  if (_.isNil(chat)) {
    return "Error";
  }
  if (chatType === MESSAGE_CHAT_TYPE.GROUP) {
    if (_.isEmpty(chat?.circle)) {
      console.log(
        "Circle shouldn't be empty, some error happened, please contact the support"
      );
      return "Error";
    }
    // Process name field
    result += !_.isEmpty(chat.circle?.firstName)
      ? chat.circle.firstName + " "
      : "";
    result += !_.isEmpty(chat.circle?.lastName) ? chat.circle?.lastName : "";
    result = `${result} - Care Team`;
  } else if (chatType === MESSAGE_CHAT_TYPE.DIRECT) {
    if (_.get(chat, "userA._id", "ua") === _.get(currentUser, "_id")) {
      // The other user Id is userB
      result = _.get(chat, "userB.firstName", "FirstNameNotFound");
    } else if (_.get(chat, "userB._id", "ua") === _.get(currentUser, "_id")) {
      // The other user Id is userA
      result = _.get(chat, "userA.firstName", "FirstNameNotFound");
    }
  } else if (chatType === MESSAGE_CHAT_TYPE.MCL.SUPPORT) {
    // If is client
    if (portal === Roles.CAREGIVER) {
      result = "Support";
    } else {
      // Process chat name field to client's name
      result += !_.isEmpty(chat.user?.firstName)
        ? chat.user.firstName + " "
        : "";
      result += !_.isEmpty(chat.user?.lastName) ? chat.user?.lastName : "";
      result = `${result} - Support`;
    }
  } else if (chatType === MESSAGE_CHAT_TYPE.MCL.CONCIERGE) {
    if (_.isEmpty(chat.circle)) {
      console.log(
        "Circle shouldn't be empty, some error happened, please contact the support"
      );
      return "Error";
    }
    // Process name field
    // result += !_.isEmpty(chat.user.firstName) ? chat.user.firstName + " " : "";
    // result += !_.isEmpty(chat.user.lastName) ? chat.user.lastName : "";
    // result = `${result} - Care Concierge`;
    result = `Message My Care Concierge`;
  }
  return result;
};
