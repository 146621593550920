import React, { useEffect, useState, useContext } from "react";
import { Route, Routes, useNavigate } from "react-router";
import { io } from "socket.io-client";
import _ from "lodash";

import "antd/dist/antd.css";
import "../../components/Navbar.css";
import "./CCDashboard.css";

import moment from "moment";

import {
  // Badge,
  // Button,
  // Col,
  Layout,
  Menu,
  // Row,
  Skeleton,
  Spin,
  // Tooltip,
} from "antd";
import {
  MenuUnfoldOutlined,
  //MenuFoldOutlined,
  MenuOutlined,
  UserOutlined,
  //VideoCameraOutlined,
  //UploadOutlined,
  DashboardOutlined,
  SnippetsOutlined,
  DiffOutlined,
  CalendarOutlined,
  TeamOutlined,
  DatabaseOutlined,
  ContactsOutlined,
  BellOutlined,
  MessageOutlined,
  CommentOutlined,
  HomeOutlined,
  ToolOutlined,
  CiCircleFilled,
} from "@ant-design/icons";

// import logoMcl from "../../assets/images/mcl-logo-new.svg";

// import { Care } from '../../../../caregiver-api/util/constants';
import { Care, Url, Roles } from "../../constants/Global";

import CaregiverContext, {
  CaregiverProvider,
} from "../../context/CaregiverProvider";
import useMclAuth from "../../hooks/useMclAuth";
import useAuth from "../../hooks/useAuth";
import NavHeadItemsAfterLogin from "../../components/NavHeadItemsAfterLogin";

import Dashboard from "./Dashboard";
import MyClient from "./MyClient";

// import Profile from "../../components/Profile";
import CCKBOverview from "./CCKBOverview";
import { openNotification } from "../../components/Various";
import SocketContext from "../../context/SocketContext";
import MessagingV2 from "../../components/MessagingV2";

const { Header, Content, Footer, Sider } = Layout;

const OLDEST_DATE = "1970-01-01";

const Home = () => {
  const mclAuth = useMclAuth();
  const { auth } = useAuth();
  const [cg, setCg] = useState({});
  const [isMsg, setIsMsg] = useState(false);
  const [isLoadingCg, setIsLoadingCg] = useState(false);
  const [msgStatus, setMsgStatus] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [socket, setSocket] = useState(undefined);
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);

  useEffect(() => {
    mclAuth
      .get(Url.CONCIERGES.concat("/", auth?.id))
      .then((res) => {
        setCg(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        openNotification({
          message: error.message,
          status: "500",
          title: "500",
          subTitle: "Failed to get care concierge clients",
        });
      });
  }, [auth.id, mclAuth]);

  const checkMsgStatus = () => {
    let status = cg?.msgStatus || {
      concierge: {},
      circle: {},
      support: "",
    };
    console.log("initial msg status:", JSON.stringify(status));

    setMsgStatus(status);
    return status;
  };

  const checkMsgNew = (status) => {
    const msgNew = cg.relationships.map((r) => ({
      hasNew: moment(r?.msgLast?.sentAt || OLDEST_DATE).isAfter(
        status.concierge?.[r._id] || OLDEST_DATE
      ),
    }));

    // check if there's any new message(s)
    let allOld = msgNew.every(function (c) {
      return !c.hasNew;
    });

    setIsMsg(!allOld);
  };

  useEffect(() => {
    if (!isLoading) {
      let status = checkMsgStatus();
      checkMsgNew(status);
    }
  }, [cg]);

  const initializeSocket = (token) => {
    const socket = io(process.env.REACT_APP_MCL_STATIC, {
      auth: {
        token: token,
      },
    });
    setSocket(socket);
  };

  useEffect(() => {
    console.log("set current circle to 0");
    return () => {
      socket?.disconnect();
    };
  }, []);

  useEffect(() => {
    if (auth?.accessToken) {
      console.log("initializeSocket");
      initializeSocket(auth?.accessToken);
    }
  }, [auth]);

  const navigate = useNavigate();
  const { pageContent, setPageContent } = useContext(CaregiverContext);
  // const [giverCare, setGiverCare] = useContext(CaregiverContext);

  //console.log('auth:', JSON.stringify(auth))

  //const [pageContent, setPageContent] = useState(0);
  const [collapsed, setCollapsed] = useState(true);

  const getLabel = (idx) => {
    return pageDetails[idx].label;
  };

  window.onpopstate = () => {
    navigate("/concierge/dashboard");
  };

  const pageDetails = {
    home: {
      label: "My Dashboard",
      icon: <HomeOutlined />,
      element: <Dashboard setPage={setPageContent} getLabel={getLabel} />,
    },
    myClients: {
      label: "My Clients",
      icon: <TeamOutlined />,
      element: <MyClient setPage={setPageContent} />,
    },
    referrals: {
      label: "Referrals",
      icon: <ContactsOutlined />,
      element: <p>Referrals</p>,
    },
    action: {
      label: "Action Items",
      icon: <SnippetsOutlined />,
      element: <p>Action Items</p>,
    },
    // {
    //   label: "Templates/Tools",
    //   icon: <ToolOutlined />,
    //   element: <p>Contact MyCareLinq</p>,
    // },
    blog: {
      label: "Blog Manager",
      icon: <ToolOutlined />,
      element: <p>Library Content Management</p>,
    },
    kb: {
      label: "Knowledge Base",
      icon: <DatabaseOutlined />,
      element: <CCKBOverview cg={cg} />,
      // <p>Still Working</p>
    },
    calendar: {
      label: "Calendar",
      icon: <CalendarOutlined />,
      element: <p>Calendar</p>,
    },
    chat: {
      label: "Messaging",
      icon: <MessageOutlined />,
      element: <MessagingV2 cg={cg} portal={Roles.CONCIERGE} />,
    },
  };

  const pageDetailsKeys = Object.keys(pageDetails);
  const menuItemsKeys = new Array();
  pageDetailsKeys.map((key) => {
    if (!pageDetails[key]?.hide) {
      menuItemsKeys.push(key);
    }
  });
  const menuItems = menuItemsKeys.map((item, index) => {
    if (!item?.hide) {
      return {
        key: index.toString(),
        label: pageDetails[item].label,
        icon: pageDetails[item].icon,
      };
    }
  });

  const showProfileDrawer = () => {
    // console.log("Enter showProfileDrawer()");
    setOpenProfileDrawer(true);
  };

  const showContent = (pageSelect) => {
    //return (pageSelect === 0) ? <Dash setPage={setPageContent} /> : contentDesc[pageSelect];
    // console.log(menuItems[pageSelect]['element']);
    // console.log(
    //   "***" +
    //     showContent.name +
    //     " pageSelect is null? " +
    //     (pageSelect == null) +
    //     " pageSelect: " +
    //     pageSelect
    // );
    if (pageSelect == null || pageSelect === 0) {
      pageSelect = "home";
    }
    return pageDetails[pageSelect].element;
  };

  const onClickMenuItem = ({ item, key, keyPath, selectedKeys, domEvent }) => {
    //console.log('click key: ' + key + ' kpath: ' + keyPath);
    setPageContent(menuItemsKeys[key]);
  };

  return (
    <SocketContext.Provider value={socket}>
      <Layout className="ccdash-layout">
        <Header className="ccdash-acct-header py-0 px-2 flex flex-row">
          {isLoadingCg || _.isEmpty(cg) ? (
            <Spin />
          ) : (
            <NavHeadItemsAfterLogin
              cg={cg}
              role={Roles.CONCIERGE}
              showProfileDrawer={showProfileDrawer}
              isMsg={isMsg}
              showContent={showContent}
              setPageContent={setPageContent}
            />
          )}
        </Header>
        <Layout
          style={{
            minHeight: "clac(100hv -66px)",
            minHeight: "calc(100dvh - 66px)",
          }}
        >
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={() => setCollapsed(!collapsed)}
            trigger={
              collapsed ? <MenuUnfoldOutlined /> : <MenuUnfoldOutlined />
            }
            style={{ background: "white" }}
            id="ccdash-sider"
          >
            <Menu
              theme="light"
              mode="inline" /* inline, vertical, horizontal */
              onClick={onClickMenuItem}
              multiple={false}
              defaultSelectedKeys={["home"]}
              selectedKeys={menuItemsKeys.indexOf(pageContent).toString()}
              items={menuItems}
              style={{
                color: "#10082d",
                backgroundColor: "white",
                height: "100%",
                borderRight: 0,
              }}
            />
          </Sider>
          <Layout>
            <Content
              className="site-layout-background"
              style={{
                /*margin: '24px 16px',*/
                padding:
                  !_.isEmpty(pageDetails) &&
                  pageDetails[pageContent]?.label === "Messaging"
                    ? 0
                    : 24,
                minHeight: "calc(100vh - 66px)",
                /*height: '100vh'*/
              }}
            >
              {isLoadingCg || _.isEmpty(cg) ? (
                <Skeleton active />
              ) : (
                showContent(pageContent)
              )}
              {/* Content */}
            </Content>
            {pageContent !== "chat" && (
              <Footer className="block fixed w-full bottom-0 text-[0.75rem] text-center font-thin text-primary-purple">
                Copyright &copy; 2021, 2023{" "}
                <a href="https://mycarelinq.com">
                  <strong>My CareLinq, Inc.</strong>
                </a>{" "}
                All Rights Reserved.
              </Footer>
            )}
          </Layout>
        </Layout>
      </Layout>
    </SocketContext.Provider>
  );
};

const Concierge = () => {
  return (
    <CaregiverProvider>
      <Routes>
        <Route exact path="/" element={<Home />} />
      </Routes>
    </CaregiverProvider>
  );
};

export default Concierge;
