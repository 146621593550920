import React, { useState, useEffect } from "react";
// import { Route, Routes, useNavigate } from "react-router";
import { Link } from "react-router-dom";
// import { io } from "socket.io-client";
// import CaregiverContext, {
//   CaregiverProvider,
// } from "../../context/CaregiverProvider";
import "antd/dist/antd.css";
import {
  // Avatar,
  Badge,
  Button,
  Col,
  Layout,
  Spin,
  Tooltip,
} from "antd";
import {
  AlertOutlined,
  CommentOutlined,
  MailOutlined,
  UserOutlined,
} from "@ant-design/icons";

import logo from "../assets/images/logos/mcl-logo-color.svg";
import cclogo from "../assets/images/logos/mcl-logo-white.svg";
import logoText from "../assets/images/logos/mcl-logo-white-horizontal-text-only-new.png";

// import logoB2b from "../assets/images/b2b/b2b-hands.png";

import {
  // Care,
  // CareType,
  // CareTypeToColor,
  // CareTypeToStr,
  Roles,
  // Subscription,
  SubscriptionToColor,
  SubscriptionToStr,
  // Url,
} from "../constants/Global";
import useAuth from "../hooks/useAuth";
import useCg from "../hooks/useCg";
import useMclAuth from "../hooks/useMclAuth";
import "./Navbar.css";
// import "./MyHome.css";
// import Profile from "./Profile";
// import DashCircles from "./DashCircles";
// import MyProfile from "./MyProfile";
import MessagingV2 from "./MessagingV2";
import ProfileRibbon from "./ProfileRibbon";
import MyProfile from "./MyProfile";
// import CareTeam from "./CareTeam";
// import ContactMCL from "./ContactMCL";
// import KnowledgeBase from "./KnowledgeBase";
// import GiverCare, { initialPlan as initialGiverCare } from "./GiverCare";
// import GiverPlan, { initialPlan as initialGiverPlan } from "./GiverPlan";
// import AdultCare, { initialPlan as initialAdultCare } from "./AdultCare";
import autoprefixer from "autoprefixer";
import _ from "lodash";
import { RiCloseCircleFill } from "react-icons/ri";
import SocketContext from "../context/SocketContext";
import NotificationDrawer from "./NotificationDrawer";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import B2bLogo from "./B2bLogo";

const { Header, Sider, Content, Footer } = Layout;

const NavHeadItemsAfterLogin = ({
  cg,
  setCg,
  isLoading,
  role,
  subscription,
  isMsg,
  showComponent,
  showContent,
  setPageContent,
  setCurrentCircle,
  myCircles,
}) => {
  const [openProfileDrawer, setOpenProfileDrawer] = useState(false);
  const { auth } = useAuth();

  const onClickNavChat = () => {
    // console.log("***onClickNavChat...");
    setPageContent("chat");
  };
  const showProfileDrawer = () => {
    // console.log("***Enter showProfileDrawer()");
    setOpenProfileDrawer(true);
  };

  return (
    <>
      <Col span={8}>
        <span className="navbar-brand">
          <Link
            to="/"
            onClick={() => {
              window.scrollTo({
                left: 0,
                top: 0,
                behavior: "auto",
              });
            }}
          >
            <img
              className="nav-logo inline-block"
              src={role === Roles.CAREGIVER ? logo : cclogo}
              // maxHeight="30px"
              width="auto"
              alt="MCL Icon"
            />
            <img
              className="nav-logo-text inline-block"
              src={logoText}
              // maxHeight="20px"
              width="auto"
              // width="134"
              alt="My CareLinq"
            />
            <B2bLogo b2b={auth?.b2b} />
          </Link>
        </span>
      </Col>
      <Col
        span={16}
        className="flex flex-row justify-end items-center text-white gap-x-1.25"
      >
        {/* <Tooltip title="Alerts">
      <Button
        type="text"
        shape="circle"
        onClick={showNotificationDrawer}
        className="hover:bg-[#cccccc80] focus:bg-[#ffffff80]"
      >
        {isAlert ? (
          <Badge dot>
            <BellOutlined style={{ color: "white" }} />
          </Badge>
        ) : (
          <BellOutlined style={{ color: "white" }} />
        )}
      </Button>
    </Tooltip> */}
        {role === Roles.CAREGIVER ? (
          <Tooltip title="Contact Us">
            <Button
              type="text"
              shape="round"
              icon={<MailOutlined style={{ color: "white" }} />}
              onClick={() => showComponent("contact")}
              className="text-white items-center gap-x-1 hover:bg-[#cccccc80] hover:text-white focus:bg-[#ffffff80] focus:text-white"
            >
              Contact Us
            </Button>
          </Tooltip>
        ) : (
          <></>
        )}
        {role === Roles.CAREGIVER ? (
          <Tooltip title="Messages">
            <Button
              type="text"
              shape="circle"
              size="large"
              // onClick={() => setPageContent("chat")}
              onClick={onClickNavChat}
              className="text-white items-center gap-x-1 hover:bg-[#cccccc80] hover:text-white focus:bg-[#ffffff80] focus:text-white"
            >
              {isMsg ? (
                <Badge dot>
                  <CommentOutlined />
                </Badge>
              ) : (
                <CommentOutlined />
              )}
            </Button>
          </Tooltip>
        ) : (
          <></>
        )}
        <div className="flex flex-row min-w-fit">
          <Tooltip title="Click to View Profile" placement="leftBottom">
            <Button
              type="text"
              shape="round"
              icon={<UserOutlined style={{ color: "white" }} />}
              onClick={showProfileDrawer}
              className="flex flex-row min-w-fit text-white items-center gap-x-1 hover:bg-[#cccccc80] hover:text-white focus:bg-[#ffffff80] focus:text-white"
            >
              {isLoading || _.isEmpty(cg) ? (
                <Spin />
              ) : role !== Roles.CAREGIVER ? (
                <ProfileRibbon cg={cg} role={role} />
              ) : _.isEmpty(myCircles) || myCircles.length < 1 ? (
                <Spin />
              ) : (
                <ProfileRibbon
                  cg={cg}
                  role={role}
                  subscription={subscription}
                />
              )}
            </Button>
          </Tooltip>
        </div>
      </Col>
      <MyProfile
        cg={cg}
        setCg={setCg}
        isLoading={isLoading}
        showComponent={showComponent}
        setPageContent={setPageContent}
        openProfileDrawer={openProfileDrawer}
        setOpenProfileDrawer={setOpenProfileDrawer}
        setCurrentCircle={setCurrentCircle}
        role={role}
        subscription={subscription}
        myCircles={myCircles}
        id="client-profile-drawer"
      />
    </>
  );
};

export default NavHeadItemsAfterLogin;
