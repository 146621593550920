import React from "react";
import MessageBoxDivider from "./MessageBoxDivider";

const MessageContainer = ({ datetime = "2023/1/4", children }) => {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex justify-center">
        <MessageBoxDivider datetime={datetime} />
      </div>
      {children}
    </div>
  );
};

export default MessageContainer;