import React, { useEffect, useState, useCallback } from "react";
import { Card, Input, Form, Modal, Row, Col, Spin } from "antd";
import GetStartedButton from "./GetStartedButton";
import GAText from "./GAText";
import { MailOutlined } from "@ant-design/icons";
import ReactGA from "react-ga4";
import { mclOpen } from "../api/Mcl";
import _ from "lodash";


import { Url } from "../constants/Global";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { openNotification } from "./Various";

const info = (articleTitle, email) => {
  Modal.success({
    width: "50%",
    title: "Thank you for your interest",
    content: (
      <div>
        <p>We will send a download link for </p>
        <strong>{articleTitle}</strong>
        <br />
        <br />
        <p>
          {" "}
          to <strong>{email}</strong>.
        </p>
        <p>If you do not receive it, please contact help@mycarelinq.com</p>
      </div>
    ),
    onOk() { },
  });
};


const DownloadArticle = ({ article }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("articleDownloadForm");
    // Send this token to the server in the following request
    return token;
  }, [executeRecaptcha]);

  const submitForm = async (data) => {
    if (submitting) {
      return;
    }
    let requestData = _.pick(data, ["_id", "firstName", "lastName", "email", "phone"])
    setSubmitting(true);
    ReactGA.event({
      category: "article",
      action: "submit_form",
      label: "download_form",
    });
    let recaptchaToken = await handleReCaptchaVerify();
    if (!_.isEmpty(recaptchaToken)) {
      // Get the recaptcha token, submit it with the data to the backend
      mclOpen
        .post(Url.ARTICLES.concat("/", "download-request"), requestData, {
          headers: { "Content-Type": "application/json" },
          params: {
            token: recaptchaToken,
          },
        })
        .then((res) => {
          if (res.status && res.status === 200) {
            info(article.title, requestData.email)
          }
          form.resetFields();
          setSubmitting(false);
        })
        .catch((error) => {
          openNotification({
            message: "Sorry...Download article request submission encountered a server error",
            status: error.response.status ? error.response.status + "" : "500",
            title: error.message,
          });
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      openNotification({
        message: "Sorry",
        status: "500",
        title: "Sorry, please try again later",
        subTitle: "Download article form failed to get Recaptcha token",
      });
      setSubmitting(false);
    }
  };

  const onFinish = (values) => {
    //info(article.title, values.email);
    submitForm({
      title: article.title,
      _id: article._id,
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      phone: values.phone
    })
  };

  return (
    <Spin spinning={submitting}>
      <Card
        title={<p className="text-lg m-0">Please enter your email below.</p>}
        bordered={true}
        headStyle={{ backgroundColor: "rgba(129, 89, 245, 0.25)" }}
        bodyStyle={{ backgroundColor: "rgba(129, 89, 245, 0.1)" }}
      >
        <Form
          className="downloadForm"
          onFinish={onFinish}
          form={form}
        >
          <Form.Item
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                First Name
              </label>
            }
            required
            rules={[
              { required: true, message: "Please input your first name" },
            ]}
            name="firstName"
          >
            <Input type="text" placeholder="First Name" />
          </Form.Item>
          <Form.Item
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                Last Name
              </label>
            }
            required
            rules={[
              { required: true, message: "Please input your last name" },
            ]}
            name="lastName"
          >
            <Input type="text" placeholder="Last Name" />
          </Form.Item>
          <Form.Item
            required
            rules={[
              {
                type: "email",
                message: "Invalid email address",
              },
              {
                required: true,
                message: "Please input your email",
              },
            ]}
            name="email"
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                Email
              </label>
            }
          >
            <Input
              type="email"
              placeholder="Enter your email address"
            />
          </Form.Item>
          <Form.Item
            label={
              <label
                className="text-primary-purple"
                style={{ fontSize: "1rem" }}
              >
                Phone (+1)
              </label>
            }
            rules={[
              {
                pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/,
                message: "Please check the format of phone number",
              },
            ]}
            name="phone"
          >
            <Input type="text" placeholder="555-555-5555" />
          </Form.Item>
          <GAText></GAText>
          <Form.Item label="" className="mt-12">
            <GetStartedButton className="px-4 py-1" type="submit" disabled={submitting}>
              Submit
            </GetStartedButton>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};

export default DownloadArticle;
